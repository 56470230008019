/**
 * @generated SignedSource<<20457f811c102c6120b2e83710fb6d4c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SendContractDialog_fragment$data = {
  readonly amendedTo: {
    readonly id: string;
  } | null | undefined;
  readonly amendments: ReadonlyArray<{
    readonly id: string;
  } | null | undefined>;
  readonly docusignStatus: string | null | undefined;
  readonly relatedDocs: ReadonlyArray<{
    readonly id: string;
  } | null | undefined>;
  readonly title: string | null | undefined;
  readonly uuid: string;
  readonly " $fragmentType": "SendContractDialog_fragment";
};
export type SendContractDialog_fragment$key = {
  readonly " $data"?: SendContractDialog_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SendContractDialog_fragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SendContractDialog_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uuid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "docusignStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ContractType",
      "kind": "LinkedField",
      "name": "amendedTo",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RelatedDocType",
      "kind": "LinkedField",
      "name": "relatedDocs",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ContractType",
      "kind": "LinkedField",
      "name": "amendments",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "ContractType",
  "abstractKey": null
};
})();

(node as any).hash = "73176d9d1f98cccf86841c9d961dc710";

export default node;
