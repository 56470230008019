/**
 * @generated SignedSource<<3464165d8f5dd9a2a2d5d79088888362>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useContractAttachments_contractFragment$data = {
  readonly relatedDocs: ReadonlyArray<{
    readonly internalContract: {
      readonly uuid: string;
    } | null | undefined;
    readonly uuid: string;
  } | null | undefined>;
  readonly " $fragmentType": "useContractAttachments_contractFragment";
};
export type useContractAttachments_contractFragment$key = {
  readonly " $data"?: useContractAttachments_contractFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useContractAttachments_contractFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useContractAttachments_contractFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "RelatedDocType",
      "kind": "LinkedField",
      "name": "relatedDocs",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ContractType",
          "kind": "LinkedField",
          "name": "internalContract",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ContractType",
  "abstractKey": null
};
})();

(node as any).hash = "ed204494e6c72f1b36fb5285dcd9da37";

export default node;
