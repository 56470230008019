import zonedTimeToUtc from "date-fns-tz/zonedTimeToUtc";

/**
 * Converts a given date to the organization's time zone.
 *
 * @param {Date} date - The date to be converted.
 * @param {string} org_tz_name - The name of the organization's time zone. This can be captured from the OrganizationType.tz_name graphql field. If not provided, it defaults to "US/Pacific" like we do on the backend.
 * @return {Date} - The converted date in UTC.
 */
export function toOrganizationDate(date, org_tz_name) {
  return zonedTimeToUtc(
    date,
    org_tz_name ?? "US/Pacific", // we default to Pacific in our backend
  );
}
