import { graphql, useMutation } from "react-relay";
import type {
  useDeleteComment_DeleteCommentMutation,
  useDeleteComment_DeleteCommentMutation$data,
} from "./__generated__/useDeleteComment_DeleteCommentMutation.graphql";

const DELETE_COMMENT_MUTATION = graphql`
  mutation useDeleteComment_DeleteCommentMutation(
    $commentUuid: String!
    $connections: [ID!]!
  ) {
    deleteComment(input: { commentUuid: $commentUuid }) {
      __typename
      ... on DeleteCommentSuccessResultType {
        commentId @deleteEdge(connections: $connections) @deleteRecord
      }
      ... on CommentNotFoundErrorType {
        debugMessage
      }
      ... on UnauthorizedError {
        debugMessage
      }
    }
  }
`;

export function useDeleteComment() {
  const [commitDeleteCommentMutation, isDeleteCommentMutationInProgress] =
    useMutation<useDeleteComment_DeleteCommentMutation>(
      DELETE_COMMENT_MUTATION,
    );

  function handleDeleteCommentMutation(
    commentId: string,
    commentUuid: string,
    connections?: string[],
    handlers?: {
      onSuccess?: (
        response: useDeleteComment_DeleteCommentMutation$data,
      ) => void;
      onResultError?: (
        response: useDeleteComment_DeleteCommentMutation$data,
      ) => void;
      onError?: (error: Error) => void;
    },
  ) {
    const optimisticResponse: useDeleteComment_DeleteCommentMutation$data = {
      deleteComment: {
        __typename: "DeleteCommentSuccessResultType",
        commentId,
      },
    };
    commitDeleteCommentMutation({
      variables: {
        commentUuid,
        connections: connections ?? [],
      },
      optimisticResponse,
      onCompleted: (response) => {
        if (
          response.deleteComment?.__typename !==
          "DeleteCommentSuccessResultType"
        ) {
          handlers?.onResultError?.(response);
          return;
        }

        handlers?.onSuccess?.(response);
        return;
      },

      onError: (error) => {
        console.error(
          "Unexpected error pinning comment comment. error: ",
          error,
        );

        handlers?.onError?.(error);
      },
    });
  }

  return {
    isDeleteCommentMutationInProgress,
    handleDeleteCommentMutation,
  };
}
