/**
 * @generated SignedSource<<0be3165dc42c420b66de7e1942775ddd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SendContractDialog_CaneclDocusignMutation$variables = {
  uuid: string;
};
export type SendContractDialog_CaneclDocusignMutation$data = {
  readonly cancelDocusignContract: {
    readonly contract: {
      readonly docusignStatus: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type SendContractDialog_CaneclDocusignMutation = {
  response: SendContractDialog_CaneclDocusignMutation$data;
  variables: SendContractDialog_CaneclDocusignMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "uuid"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "uuid",
        "variableName": "uuid"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docusignStatus",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SendContractDialog_CaneclDocusignMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CancelDocusignContractPayload",
        "kind": "LinkedField",
        "name": "cancelDocusignContract",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractType",
            "kind": "LinkedField",
            "name": "contract",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SendContractDialog_CaneclDocusignMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CancelDocusignContractPayload",
        "kind": "LinkedField",
        "name": "cancelDocusignContract",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractType",
            "kind": "LinkedField",
            "name": "contract",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e55f5e953ca6f4af432a0dbf281d5ebb",
    "id": null,
    "metadata": {},
    "name": "SendContractDialog_CaneclDocusignMutation",
    "operationKind": "mutation",
    "text": "mutation SendContractDialog_CaneclDocusignMutation(\n  $uuid: String!\n) {\n  cancelDocusignContract(input: {uuid: $uuid}) {\n    contract {\n      docusignStatus\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5819acdf327698ff8a3267c42e2e047a";

export default node;
