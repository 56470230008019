import React from "react";
import { Link } from "react-router";

export const RouterLink = React.forwardRef(
  ({ href, ...props }: { href: string }, ref: any) => {
    if (
      href?.startsWith("http://") ||
      href?.startsWith("https://") ||
      href?.startsWith("mailto:")
    ) {
      // ugh, react router doesn't do absolute links!
      return <a ref={ref} href={href} {...props} />;
    }
    // Map href (MUI) -> to (react-router)
    return <Link ref={ref} to={href || ""} {...props} />;
  },
);
