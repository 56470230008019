/**
 * @generated SignedSource<<e6dac793b6705478a17ceb2c871ef5a5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FormSelect_accountFragment$data = {
  readonly isAcmanager: boolean;
  readonly isAdmin: boolean;
  readonly organization: {
    readonly defaultForm: {
      readonly id: string;
    } | null | undefined;
    readonly forms: ReadonlyArray<{
      readonly id: string;
      readonly name: string | null | undefined;
    } | null | undefined> | null | undefined;
    readonly requiredFieldsEnabled: boolean | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "FormSelect_accountFragment";
};
export type FormSelect_accountFragment$key = {
  readonly " $data"?: FormSelect_accountFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"FormSelect_accountFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FormSelect_accountFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationType",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FormType",
          "kind": "LinkedField",
          "name": "defaultForm",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "FormType",
          "kind": "LinkedField",
          "name": "forms",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "requiredFieldsEnabled",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isAdmin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isAcmanager",
      "storageKey": null
    }
  ],
  "type": "AccountType",
  "abstractKey": null
};
})();

(node as any).hash = "2486954738dfc36d03f367c011b7a7f6";

export default node;
