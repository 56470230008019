import { graphql, readInlineData } from "react-relay";
import {
  getTemplateVariableType,
  getTemplateVariables,
  isFormField,
  type TemplateVariable,
  getIsTemplateVariableRequired,
} from "./templateVariableHelpers";
import React from "react";
import {
  getContractFieldValueById,
  getFieldInfo,
} from "../../core/contracts/fieldHelpers";
import invariant from "~/utils/invariant";
import type { useTemplateFieldValues_contractInlineFragment$key } from "./__generated__/useTemplateFieldValues_contractInlineFragment.graphql";
import type { useTemplateFieldValues_templateInlineFragment$key } from "./__generated__/useTemplateFieldValues_templateInlineFragment.graphql";
import type { useTemplateFieldValues_builtinFieldInlineFragment$key } from "./__generated__/useTemplateFieldValues_builtinFieldInlineFragment.graphql";
import type { useTemplateFieldValues_organizationInlineFragment$key } from "./__generated__/useTemplateFieldValues_organizationInlineFragment.graphql";
import _pick from "lodash/pick";
import { TemplateContainsMissingFieldError } from "./errors";

export type FieldValue = {
  currencySymbol?: string | null | undefined;
  value?: any;
  valueList?: any;
  valueDate?: any;
  valueDatePart?: any;
  field: { id: string };
};

export type FieldValuesState = {
  [templateVariableId: string]: FieldValue;
};

type UseFieldValuesInitialData = {
  builtinFieldFragRefs: ReadonlyArray<useTemplateFieldValues_builtinFieldInlineFragment$key>;
  contractFragRef?: useTemplateFieldValues_contractInlineFragment$key;
  templateFragRef: useTemplateFieldValues_templateInlineFragment$key;
  organizationFragRef: useTemplateFieldValues_organizationInlineFragment$key;
};

export function useFieldValues(initialData?: UseFieldValuesInitialData) {
  const [fieldValues, setFieldValues] = React.useState<FieldValuesState>(
    getInitialFieldValues(initialData),
  );

  const handleFieldValueChange = (templateVariableId: string, value: any) => {
    setFieldValues((fieldValues) => ({
      ...fieldValues,
      [templateVariableId]: value,
    }));
  };

  const resetFieldValues = () => {
    setFieldValues({});
  };

  function getInitialFieldValues(initialData?: UseFieldValuesInitialData) {
    if (!initialData) {
      return {} as FieldValuesState;
    }

    try {
      return getFieldValues(initialData);
    } catch (e) {
      if (e instanceof TemplateContainsMissingFieldError) {
        e.readableMessage =
          "There was an error loading your template. It contains custom fields that are now missing from your organization. Please contact your organization administrator and have them remove the missing fields from the template.";
      }

      throw e;
    }
  }

  /**
   * Initialize the field values state from the template's default values. Optionally, provide a contract fragment to initialize the values based on the fields in the contract.
   *
   */
  function getFieldValues(fragRefs: {
    builtinFieldFragRefs: ReadonlyArray<useTemplateFieldValues_builtinFieldInlineFragment$key>;
    contractFragRef?: useTemplateFieldValues_contractInlineFragment$key;
    templateFragRef: useTemplateFieldValues_templateInlineFragment$key;
    organizationFragRef: useTemplateFieldValues_organizationInlineFragment$key;
  }) {
    const template = readInlineData(
      TEMPLATE_INLINE_FRAGMENT,
      fragRefs.templateFragRef,
    );
    const organization = readInlineData(
      ORGANIZATION_INLINE_FRAGMENT,
      fragRefs.organizationFragRef,
    );
    const builtinFields = fragRefs.builtinFieldFragRefs.flatMap((fragRef) => [
      readInlineData(BUILTIN_FIELD_INLINE_FRAGMENT, fragRef),
    ]);

    const { templateVariables } = getTemplateVariables(template);

    invariant(organization, "Expected organization to be set.");

    let fieldValues: FieldValuesState = {};
    templateVariables.forEach((templateVariable) => {
      if (!isFormField(templateVariable)) {
        return;
      }

      const fieldInfo = getFieldInfo(templateVariable.field.id, {
        builtinFields,
        organization,
      });

      if (!fieldInfo) {
        console.error(
          `Expected field info to exist. fieldId: ${templateVariable.field.id}`,
        );
        throw new TemplateContainsMissingFieldError("Missing field info.");
      }

      let defaultValue = null;
      if (templateVariable.elementType === "hidden") {
        defaultValue = templateVariable.field.value ?? null;
      }

      if (fragRefs.contractFragRef) {
        const contract = readInlineData(
          CONTRACT_INLINE_FRAGMENT,
          fragRefs.contractFragRef,
        );
        let existingFieldValue = getContractFieldValueById(
          templateVariable.field.id,
          {
            contract,
            builtinFields,
            organization,
          },
          defaultValue,
        );

        if (
          existingFieldValue?.type == "custom" &&
          existingFieldValue?.valueDate
        ) {
          existingFieldValue = {
            ...existingFieldValue,
            valueDate: existingFieldValue.valueDate?.date,
          };
        }
        fieldValues[templateVariable.id] = {
          ...existingFieldValue,
          field: { id: templateVariable.field.id },
        };

        return;
      }

      fieldValues[templateVariable.id] = {
        [fieldInfo.valuePropKey]: defaultValue ?? null,
        field: { id: templateVariable.field.id },
      };
    });

    return fieldValues;
  }

  function initialize(fragRefs: {
    builtinFieldFragRefs: ReadonlyArray<useTemplateFieldValues_builtinFieldInlineFragment$key>;
    contractFragRef?: useTemplateFieldValues_contractInlineFragment$key;
    templateFragRef: useTemplateFieldValues_templateInlineFragment$key;
    organizationFragRef: useTemplateFieldValues_organizationInlineFragment$key;
  }) {
    const fieldValues = getInitialFieldValues(fragRefs);
    setFieldValues(fieldValues);
  }

  const getHasValueForField = (fieldValue: FieldValue) => {
    const hasValue =
      fieldValue.value ||
      fieldValue.valueDate ||
      fieldValue.valueDatePart ||
      fieldValue.valueList;
    return hasValue !== undefined && hasValue !== null;
  };

  let getIsMissingRequiredFieldValues = (fragRefs: {
    builtinFields: ReadonlyArray<useTemplateFieldValues_builtinFieldInlineFragment$key>;
    template: useTemplateFieldValues_templateInlineFragment$key;
    organization: useTemplateFieldValues_organizationInlineFragment$key;
  }) => {
    const template = readInlineData(
      TEMPLATE_INLINE_FRAGMENT,
      fragRefs.template,
    );
    const builtinFields = fragRefs.builtinFields.flatMap((fragRef) => [
      readInlineData(BUILTIN_FIELD_INLINE_FRAGMENT, fragRef),
    ]);

    const organization = readInlineData(
      ORGANIZATION_INLINE_FRAGMENT,
      fragRefs.organization,
    );

    const { templateVariables } = getTemplateVariables(template);

    let isMissingRequiredFields = false;
    for (const templateVariable of templateVariables) {
      if (!isFormField(templateVariable)) {
        continue;
      }

      const fieldValue = fieldValues[templateVariable.id];
      const hasValue = getHasValueForField(fieldValue);

      const fieldInfo = getFieldInfo(templateVariable.field.id, {
        builtinFields,
        organization,
      });

      if (
        getIsTemplateVariableRequired(templateVariable, fieldInfo) &&
        !hasValue
      ) {
        isMissingRequiredFields = true;
        break;
      }
    }

    return isMissingRequiredFields;
  };

  const getUpdatedFieldValues = (fragRefs: {
    template: useTemplateFieldValues_templateInlineFragment$key;
    builtinFields: ReadonlyArray<useTemplateFieldValues_builtinFieldInlineFragment$key>;
    organization: useTemplateFieldValues_organizationInlineFragment$key;
  }) => {
    const template = readInlineData(
      TEMPLATE_INLINE_FRAGMENT,
      fragRefs.template,
    );
    const builtinFields = fragRefs.builtinFields.flatMap((fragRef) => [
      readInlineData(BUILTIN_FIELD_INLINE_FRAGMENT, fragRef),
    ]);
    const organization = readInlineData(
      ORGANIZATION_INLINE_FRAGMENT,
      fragRefs.organization,
    );

    const updatedFieldValues = Object.entries(fieldValues).flatMap(
      ([fieldId, fieldValue]) => {
        if (!fieldValue) {
          return [];
        }

        const templateVariable = getTemplateVariables(
          template,
        ).templateVariables.find(
          (variable) => variable.field.id === fieldValue.field.id,
        );
        if (!templateVariable) {
          console.error(
            "Could not find template variable for field: ",
            fieldId,
          );
          return [];
        }
        const fieldInfo = getFieldInfo(templateVariable.field.id, {
          builtinFields,
          organization,
        });

        const isRequired = getIsTemplateVariableRequired(
          templateVariable,
          fieldInfo,
        );
        const hasValue = getHasValueForField(fieldValue);
        if (isRequired && !hasValue) {
          throw new Error(
            `Missing required value for field. fieldId: ${fieldId}`,
          );
        }

        if (!hasValue) {
          return [];
        }

        const input = {
          fieldId: fieldValue.field.id,
          ..._pick(fieldValue, [
            "currencySymbol",
            "value",
            "valueList",
            "valueDate",
            "valueDatePart",
          ]),
        };
        return [input];
      },
    );
    return updatedFieldValues;
  };

  return {
    fieldValues,
    getUpdatedFieldValues,
    getIsMissingRequiredFieldValues,
    getHasValueForField,
    initializeFieldValuesForTemplate: initialize,
    resetFieldValues,
    handleFieldValueChange,
  };
}

const BUILTIN_FIELD_INLINE_FRAGMENT = graphql`
  fragment useTemplateFieldValues_builtinFieldInlineFragment on BuiltinFieldType
  @inline {
    ...fieldHelpers_builtinFieldInlineFragment
  }
`;

const ORGANIZATION_INLINE_FRAGMENT = graphql`
  fragment useTemplateFieldValues_organizationInlineFragment on OrganizationType
  @inline {
    ...fieldHelpers_organizationInlineFragment
  }
`;

const CONTRACT_INLINE_FRAGMENT = graphql`
  fragment useTemplateFieldValues_contractInlineFragment on ContractType
  @inline {
    ...fieldHelpers_contractInlineFragment
  }
`;

const TEMPLATE_INLINE_FRAGMENT = graphql`
  fragment useTemplateFieldValues_templateInlineFragment on TemplateType
  @inline {
    ...templateVariableHelpers_templateFragment
  }
`;
