/**
 * @generated SignedSource<<e4eaf41760787fefbb796caa4a30dc8f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContractForms_EffectiveDateFragment$data = {
  readonly date: any | null | undefined;
  readonly id: string;
  readonly label: string;
  readonly protected: boolean | null | undefined;
  readonly uuid: string;
  readonly " $fragmentType": "ContractForms_EffectiveDateFragment";
};
export type ContractForms_EffectiveDateFragment$key = {
  readonly " $data"?: ContractForms_EffectiveDateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContractForms_EffectiveDateFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContractForms_EffectiveDateFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uuid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "protected",
      "storageKey": null
    }
  ],
  "type": "ContractDateType",
  "abstractKey": null
};

(node as any).hash = "b700c0187edcfc287e0ba0f59c535070";

export default node;
