/**
 * @generated SignedSource<<551295a90511ae5bacbfdebd73fcfbe5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContractActiveStatusChip_isActiveMutation$variables = {
  isactive?: boolean | null | undefined;
  uuid: string;
};
export type ContractActiveStatusChip_isActiveMutation$data = {
  readonly updateContract: {
    readonly contract: {
      readonly " $fragmentSpreads": FragmentRefs<"ContractActiveStatusChip_contractFragment">;
    } | null | undefined;
  } | null | undefined;
};
export type ContractActiveStatusChip_isActiveMutation$rawResponse = {
  readonly updateContract: {
    readonly contract: {
      readonly id: string;
      readonly isactive: boolean;
      readonly uuid: string;
    } | null | undefined;
  } | null | undefined;
};
export type ContractActiveStatusChip_isActiveMutation = {
  rawResponse: ContractActiveStatusChip_isActiveMutation$rawResponse;
  response: ContractActiveStatusChip_isActiveMutation$data;
  variables: ContractActiveStatusChip_isActiveMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isactive"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "uuid"
},
v2 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "isactive",
        "variableName": "isactive"
      },
      {
        "kind": "Variable",
        "name": "uuid",
        "variableName": "uuid"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ContractActiveStatusChip_isActiveMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "UpdateContractPayload",
        "kind": "LinkedField",
        "name": "updateContract",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractType",
            "kind": "LinkedField",
            "name": "contract",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ContractActiveStatusChip_contractFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ContractActiveStatusChip_isActiveMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "UpdateContractPayload",
        "kind": "LinkedField",
        "name": "updateContract",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractType",
            "kind": "LinkedField",
            "name": "contract",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "uuid",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isactive",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "24940d57526045a8ad46066aa2433fe4",
    "id": null,
    "metadata": {},
    "name": "ContractActiveStatusChip_isActiveMutation",
    "operationKind": "mutation",
    "text": "mutation ContractActiveStatusChip_isActiveMutation(\n  $uuid: String!\n  $isactive: Boolean\n) {\n  updateContract(input: {uuid: $uuid, isactive: $isactive}) {\n    contract {\n      ...ContractActiveStatusChip_contractFragment\n      id\n    }\n  }\n}\n\nfragment ContractActiveStatusChip_contractFragment on ContractType {\n  id\n  uuid\n  isactive\n}\n"
  }
};
})();

(node as any).hash = "45c1831c79dbe3e07f6d0e2e857cf331";

export default node;
