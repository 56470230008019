import { Stack } from "@mui/material";
import React, { Suspense } from "react";
import { graphql, loadQuery, usePreloadedQuery } from "react-relay";
import { useLoaderData } from "react-router";
import environment from "~/RelayEnvironment";

export async function clientLoader(args: any) {
  const queryRef = loadQuery(environment, relatedDocuments_Query, {
    uuid: args.params.uuid,
  });
  return { queryRef };
}

export function RelatedDocuments(props: any) {
  const { queryRef } = useLoaderData<typeof clientLoader>();
  const queryData: any = usePreloadedQuery(relatedDocuments_Query, queryRef);

  return (
    <Stack>
      <div>RelatedDocuments</div>
    </Stack>
  );
}

export function RelatedDocumentsWithFallback() {
  return (
    <Suspense fallback={<div>Loading</div>}>
      <RelatedDocuments />
    </Suspense>
  );
}

const relatedDocuments_Query = graphql`
  query relatedDocuments_Query($uuid: String!) {
    contract(uuid: $uuid) {
      uuid
    }
  }
`;
