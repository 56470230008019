import React from "react";
import Grid from "~/components/GridV2OrV1";
import { graphql, useFragment, usePaginationFragment } from "react-relay";
import type { CommentList_contractFragment$key } from "./__generated__/CommentList_contractFragment.graphql";
import Button from "~/components/Button";
import UserComment from "./UserComment/UserComment";
import { getUniqueAvatarColorsForCommentAuthors } from "./Comment/avatarColors";
import type { CommentList_commentEdgesFragment$data } from "./__generated__/CommentList_commentEdgesFragment.graphql";
import type { CommentList_currentUserAccountFragment$key } from "./__generated__/CommentList_currentUserAccountFragment.graphql";

const COMMENT_EDGE_FRAGMENT = graphql`
  fragment CommentList_commentEdgesFragment on CommentTypeEdge
  @relay(plural: true) {
    node @required(action: THROW) {
      id
      uuid
      author @required(action: THROW) {
        uuid
      }
      pinnedAt
    }
    ...UserComment_commentEdgeFragment
  }
`;

const CONTRACT_FRAGMENT = graphql`
  fragment CommentList_contractFragment on ContractType
  @refetchable(queryName: "CommentListPaginationQuery")
  @argumentDefinitions(
    after: { type: "String" }
    before: { type: "String" }
    first: { type: "Int", defaultValue: 10 }
    last: { type: "Int" }
  ) {
    comments(after: $after, before: $before, first: $first, last: $last)
      @connection(key: "CommentList_contractFragment_comments")
      @required(action: THROW) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        ...CommentList_commentEdgesFragment
      }
    }
  }
`;

const CURRENT_USER_ACCOUNT_FRAGMENT = graphql`
  fragment CommentList_currentUserAccountFragment on AccountType {
    uuid
    ...UserComment_currentUserAccountFragment
  }
`;

type CommentListProps = {
  contract: CommentList_contractFragment$key;
  currentUser: CommentList_currentUserAccountFragment$key;
  focusedCommentNodeId: string | undefined;
  focusComment: (
    commentNodeId: string,
    scrollOptions?: ScrollIntoViewOptions,
  ) => void;
  setCommentRef: (node: HTMLElement | null, commentNodeId: string) => void;
};

export function CommentList(props: CommentListProps) {
  const { data, hasNext, loadNext } = usePaginationFragment(
    CONTRACT_FRAGMENT,
    props.contract,
  );

  if (data == null) {
    return null;
  }

  // Typescript was marking edges type as `unknown`.
  // TODO:Revisit when [this issue](https://github.com/facebook/relay/issues/4505) is resolved.
  const commentEdges = useFragment(
    COMMENT_EDGE_FRAGMENT,
    data.comments.edges,
  ) as CommentList_commentEdgesFragment$data;

  const currentUser = useFragment(
    CURRENT_USER_ACCOUNT_FRAGMENT,
    props.currentUser,
  );
  const avatarColors = getUniqueAvatarColorsForComments(
    commentEdges,
    currentUser.uuid,
  );

  return (
    <Grid container xs={12} sx={{ maxHeight: 400, overflow: "auto" }}>
      <Grid container xs={12}>
        <>
          {commentEdges.map((edge, idx) => {
            if (!edge) {
              // todo: add placeholder comment
              return null;
            }
            const commentAuthorUuid = edge.node.author.uuid;
            const avatarColor = avatarColors[commentAuthorUuid] ?? "#71C6B1";
            const isFocused = props.focusedCommentNodeId === edge.node.id;
            const isLastComment = !hasNext && idx === commentEdges.length - 1;

            return (
              <Grid
                container
                xs={12}
                key={edge.node.id}
                ref={(el) => props.setCommentRef(el, edge.node.id)}
              >
                <UserComment
                  avatarColor={avatarColor}
                  commentEdge={edge}
                  contractId={data.id}
                  currentUser={currentUser}
                  isFocused={isFocused}
                  focusComment={(scrollOptions: ScrollIntoViewOptions = {}) =>
                    props.focusComment(edge.node.id, scrollOptions)
                  }
                  showCommentConnectorLine={!isLastComment}
                />
              </Grid>
            );
          })}
        </>
      </Grid>
      {hasNext && (
        <Grid xs={12}>
          <Button
            label="load more"
            onClick={() => loadNext(10)}
            data-testid="comment-list-load-more-button"
          />
        </Grid>
      )}
    </Grid>
  );
}

function getUniqueAvatarColorsForComments(
  commentEdges: CommentList_commentEdgesFragment$data,
  currentUserUuid: string,
) {
  const comments = commentEdges.map((e) => e.node);
  return getUniqueAvatarColorsForCommentAuthors(comments, currentUserUuid);
}
