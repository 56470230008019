/**
 * @generated SignedSource<<04bc50849ccab3f1b863582064cdda5c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserComment_commentFragment$data = {
  readonly author: {
    readonly email: string;
  };
  readonly dateCreated: string;
  readonly hasContentEditRevisions: boolean;
  readonly id: string;
  readonly pinnedAt: string | null | undefined;
  readonly uuid: string;
  readonly " $fragmentSpreads": FragmentRefs<"UserCommentActionsMenu_commentFragment" | "UserCommentContent_commentFragment">;
  readonly " $fragmentType": "UserComment_commentFragment";
};
export type UserComment_commentFragment$key = {
  readonly " $data"?: UserComment_commentFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserComment_commentFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserComment_commentFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uuid",
      "storageKey": null
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": "AccountType",
        "kind": "LinkedField",
        "name": "author",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      "action": "THROW",
      "path": "author"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dateCreated",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pinnedAt",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserCommentActionsMenu_commentFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserCommentContent_commentFragment"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasContentEditRevisions",
      "storageKey": null
    }
  ],
  "type": "CommentType",
  "abstractKey": null
};

(node as any).hash = "9a13e6ecda871b13978e48fabfc5a586";

export default node;
