/**
 * @generated SignedSource<<a7e55fbd860742fa7e8d5e70b6446153>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type RelatedDocs_selectionQuery$variables = {
  contractUuid: string;
};
export type RelatedDocs_selectionQuery$data = {
  readonly contract: {
    readonly amendedTo: {
      readonly title: string | null | undefined;
      readonly uuid: string;
    } | null | undefined;
    readonly amendments: ReadonlyArray<{
      readonly title: string | null | undefined;
      readonly uuid: string;
    } | null | undefined>;
    readonly relatedDocs: ReadonlyArray<{
      readonly internalContract: {
        readonly title: string | null | undefined;
        readonly uuid: string;
      } | null | undefined;
      readonly title: string | null | undefined;
      readonly uuid: string;
    } | null | undefined>;
    readonly title: string | null | undefined;
  } | null | undefined;
};
export type RelatedDocs_selectionQuery = {
  response: RelatedDocs_selectionQuery$data;
  variables: RelatedDocs_selectionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "contractUuid"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "uuid",
    "variableName": "contractUuid"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v4 = [
  (v3/*: any*/),
  (v2/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = [
  (v3/*: any*/),
  (v2/*: any*/),
  (v5/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RelatedDocs_selectionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ContractType",
        "kind": "LinkedField",
        "name": "contract",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractType",
            "kind": "LinkedField",
            "name": "amendedTo",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "RelatedDocType",
            "kind": "LinkedField",
            "name": "relatedDocs",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ContractType",
                "kind": "LinkedField",
                "name": "internalContract",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractType",
            "kind": "LinkedField",
            "name": "amendments",
            "plural": true,
            "selections": (v4/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RelatedDocs_selectionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ContractType",
        "kind": "LinkedField",
        "name": "contract",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractType",
            "kind": "LinkedField",
            "name": "amendedTo",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "RelatedDocType",
            "kind": "LinkedField",
            "name": "relatedDocs",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ContractType",
                "kind": "LinkedField",
                "name": "internalContract",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractType",
            "kind": "LinkedField",
            "name": "amendments",
            "plural": true,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fdb9453ed4103e24c185e97df139eed2",
    "id": null,
    "metadata": {},
    "name": "RelatedDocs_selectionQuery",
    "operationKind": "query",
    "text": "query RelatedDocs_selectionQuery(\n  $contractUuid: String!\n) {\n  contract(uuid: $contractUuid) {\n    title\n    amendedTo {\n      uuid\n      title\n      id\n    }\n    relatedDocs {\n      uuid\n      title\n      internalContract {\n        uuid\n        title\n        id\n      }\n      id\n    }\n    amendments {\n      uuid\n      title\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "e5faccfbb7f16e891503cc5b0a2f530b";

export default node;
