/**
 * @generated SignedSource<<8b477343e977841ffaae812aa2683028>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type fieldHelpers_organizationInlineFragment$data = {
  readonly customFields: ReadonlyArray<{
    readonly choices: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    } | null | undefined> | null | undefined;
    readonly fieldType: string;
    readonly id: string;
    readonly name: string;
  }> | null | undefined;
  readonly dateFormat: string | null | undefined;
  readonly orgContractTypes: ReadonlyArray<{
    readonly customName: string | null | undefined;
    readonly id: string;
    readonly isActive: boolean | null | undefined;
    readonly name: string;
  } | null | undefined> | null | undefined;
  readonly tags: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "fieldHelpers_organizationInlineFragment";
};
export type fieldHelpers_organizationInlineFragment$key = {
  readonly " $data"?: fieldHelpers_organizationInlineFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"fieldHelpers_organizationInlineFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "fieldHelpers_organizationInlineFragment"
};

(node as any).hash = "42718e4e970448b649f62c22c2f8c583";

export default node;
