import React from "react";
import Grid from "~/components/GridV2OrV1";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AccordionPaper, AccordionPaperSummary } from "~/components/Paper";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import {
  CommentsSectionContentWithErrorBoundary,
  type CommentsSectionContentProps,
} from "./CommentsSectionContent";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import { GridV2OrV1Context } from "~/components/GridV2OrV1";
import type { SxProps, Theme } from "@mui/material";

type CommentsSectionProps = CommentsSectionContentProps & {
  sx?: SxProps<Theme>;
};

export function CommentsSection(props: CommentsSectionProps) {
  const [isExpanded, setIsExpanded] = React.useState(true);

  return (
    <GridV2OrV1Context.Provider
      value={{
        version: "2",
        showWarnings: true,
      }}
    >
      <AccordionPaper
        expanded={isExpanded}
        onChange={() => setIsExpanded(!isExpanded)}
        data-testid="comments-section"
        sx={{ width: "100%", ...props.sx }}
      >
        <AccordionPaperSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5">
            <ChatOutlinedIcon /> Comments
          </Typography>
        </AccordionPaperSummary>
        <AccordionDetails>
          <CommentsSectionContentWithErrorBoundary {...props} />
        </AccordionDetails>
      </AccordionPaper>
    </GridV2OrV1Context.Provider>
  );
}
