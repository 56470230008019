import { Box, Stack } from "@mui/material";
import React from "react";
import { graphql, useFragment } from "react-relay";
import { useDeferredLayoutQuery } from "./useDeferredLayoutQuery";
import type { Summary_contractFragment$key } from "./__generated__/Summary_contractFragment.graphql";
import { ContractActiveStatusChip } from "./ContractActiveStatusChip";
import { ContractExecutionStatusChip } from "./ContractExecutionStatusChip";

type SummaryProps = {
  contractFragRef: Summary_contractFragment$key;
};

function Summary(props: SummaryProps) {
  const contract = useFragment(Summary_contractFragment, props.contractFragRef);
  return (
    <Box
      sx={{
        backgroundColor: "#F7F8FB",
        marginTop: 2,
        borderRadius: "4px",
        paddingX: 6,
        paddingY: 2,
        minHeight: "150px",
      }}
    >
      <Stack direction="row" spacing={1}>
        <ContractActiveStatusChip contractFragRef={contract} />
        <ContractExecutionStatusChip contractFragRef={contract} />
      </Stack>
    </Box>
  );
}

type SummaryContainerProps = {};

export function SummaryContainer(props: SummaryContainerProps) {
  const queryData = useDeferredLayoutQuery();
  return <Summary contractFragRef={queryData.contract} />;
}

const Summary_contractFragment = graphql`
  fragment Summary_contractFragment on ContractType {
    id
    uuid
    isactive
    legal {
      id
      executionStatus
    }
    ...ContractActiveStatusChip_contractFragment
    ...ContractExecutionStatusChip_contractFragment
  }
`;
