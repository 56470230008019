import Typography from "@mui/material/Typography";
import React from "react";

type TemplateVariableHeaderFieldProps = {
  value: string;
};

export function TemplateVariableHeaderField(
  props: TemplateVariableHeaderFieldProps,
) {
  return <Typography variant="h6">{props.value}</Typography>;
}
