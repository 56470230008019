import React from "react";

export default function LeftDialogActions({ children }) {
  return (
    <>
      {children}
      <div style={{ flex: "1 0 0" }} />
    </>
  );
}
