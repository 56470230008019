import React from "react";
import { SvgIcon, type SvgIconProps } from "@mui/material";

export function EsignIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        stroke="currentColor"
      >
        <path
          d="M22.4778 17L20.1701 15.132C20.0938 15.0645 19.9979 15.0194 19.8944 15.0024C19.7909 14.9854 19.6843 14.9973 19.5879 15.0365C19.4916 15.0758 19.4097 15.1407 19.3526 15.2231C19.2955 15.3055 19.2656 15.4018 19.2667 15.5V16C19.2667 16.2652 19.1539 16.5196 18.9532 16.7071C18.7524 16.8947 18.4802 17 18.1963 17H16.0556C15.7717 17 15.4994 16.8947 15.2987 16.7071C15.098 16.5196 14.9852 16.2652 14.9852 16C14.9852 13.455 10.7133 12.03 5.88705 12C5.17735 12 4.49671 12.2634 3.99488 12.7322C3.49305 13.2011 3.21112 13.837 3.21112 14.5C3.21112 15.1631 3.49305 15.7989 3.99488 16.2678C4.49671 16.7366 5.17735 17 5.88705 17C10.3323 17 10.966 5.70502 11.9967 3.50002C12.1708 3.12781 12.4394 2.80085 12.7792 2.54743C13.119 2.294 13.5198 2.12175 13.9469 2.04557C14.3739 1.9694 14.8144 1.99159 15.2303 2.11024C15.6461 2.22888 16.0247 2.4404 16.3334 2.7265C16.642 3.01259 16.8714 3.36464 17.0017 3.75218C17.1319 4.13973 17.1592 4.55109 17.081 4.95067C17.0028 5.35026 16.8216 5.72603 16.5531 6.04547C16.2845 6.36492 15.9367 6.61841 15.5396 6.78402"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M3.21112 21H22.4778"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </svg>
    </SvgIcon>
  );
}
