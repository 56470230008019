import React from "react";
import { DropzoneArea as MuiDropzoneArea } from "mui-file-dropzone";
import Box from "@mui/material/Box";
import { useSnackbar } from "~/components/Snackbar";
import acceptedFiles from "~/AcceptedFileTypes";

export default function DropzoneArea({
  maxFileSize = 300000000,
  sx = {},
  ...props
}) {
  const { openSnackbar } = useSnackbar();

  const handleDropRejected = (rejectedFiles) => {
    for (var file of rejectedFiles) {
      if (file.size > maxFileSize) {
        openSnackbar(
          `There was an error uploading ${file.name}. File size is too large. Max file size is ${maxFileSize / 1000000} MB.`,
        );
        return;
      } else if (!acceptedFiles.includes(file.type)) {
        openSnackbar(
          `There was an error uploading ${file.name}. File type is not supported.`,
        );
        return; // we can only alert on one file at a time
      }
    }
  };

  return (
    <Box
      sx={{
        "& .CS-DropzoneArea-dropzone": {
          minHeight: "225px",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          paddingLeft: "25px",
          paddingRight: "25px",
          "& .MuiTypography-root": { fontSize: 16, fontWeight: 300 },
          ...sx,
        },
      }}
    >
      <MuiDropzoneArea
        dropzoneClass="CS-DropzoneArea-dropzone"
        maxFileSize={maxFileSize}
        disableRejectionFeedback={true}
        onDropRejected={handleDropRejected}
        {...props}
      />
    </Box>
  );
}
