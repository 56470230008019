/**
 * @generated SignedSource<<539e1ae91ae1c6d3c5afe5eccffc2407>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommentsSectionContent_contractFragment$data = {
  readonly id: string;
  readonly uuid: string;
  readonly " $fragmentSpreads": FragmentRefs<"CommentList_contractFragment" | "CommentsSectionContent_commentsFragment">;
  readonly " $fragmentType": "CommentsSectionContent_contractFragment";
};
export type CommentsSectionContent_contractFragment$key = {
  readonly " $data"?: CommentsSectionContent_contractFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommentsSectionContent_contractFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "commentsFirst"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": 10,
      "kind": "LocalArgument",
      "name": "commentsFirst"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommentsSectionContent_contractFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uuid",
      "storageKey": null
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "CommentList_contractFragment"
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "CommentsSectionContent_commentsFragment"
    }
  ],
  "type": "ContractType",
  "abstractKey": null
};
})();

(node as any).hash = "06db04c048df9a486534003bbd1f2d30";

export default node;
