/**
 * @generated SignedSource<<8e932ffaa70914982ba01746c344933e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RelatedDocs_Query$variables = {
  contractUuid: string;
};
export type RelatedDocs_Query$data = {
  readonly contract: {
    readonly " $fragmentSpreads": FragmentRefs<"RelatedDocs_fragment">;
  } | null | undefined;
};
export type RelatedDocs_Query = {
  response: RelatedDocs_Query$data;
  variables: RelatedDocs_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "contractUuid"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "uuid",
    "variableName": "contractUuid"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isarchived",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "ContractPartyType",
  "kind": "LinkedField",
  "name": "counterparties",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PartyType",
      "kind": "LinkedField",
      "name": "party",
      "plural": false,
      "selections": [
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "companyName",
          "storageKey": null
        },
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    (v2/*: any*/)
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "ContractDateType",
  "kind": "LinkedField",
  "name": "effectiveDate",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    },
    (v2/*: any*/)
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dateCreated",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RelatedDocs_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ContractType",
        "kind": "LinkedField",
        "name": "contract",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "RelatedDocs_fragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RelatedDocs_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ContractType",
        "kind": "LinkedField",
        "name": "contract",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "amendedAndRestated",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractType",
            "kind": "LinkedField",
            "name": "amendedTo",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v3/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "amendedToNamed",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "RelatedDocType",
            "kind": "LinkedField",
            "name": "relatedDocs",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v3/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "filename",
                "storageKey": null
              },
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "relationType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ContractType",
                "kind": "LinkedField",
                "name": "internalContract",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v4/*: any*/),
                  (v3/*: any*/),
                  (v5/*: any*/),
                  (v7/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isGeneratedRedlineDocument",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractType",
            "kind": "LinkedField",
            "name": "amendments",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v3/*: any*/),
              (v5/*: any*/),
              (v8/*: any*/),
              (v7/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ContractType",
                "kind": "LinkedField",
                "name": "amendments",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v3/*: any*/),
                  (v5/*: any*/),
                  (v8/*: any*/),
                  (v7/*: any*/),
                  (v6/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8490c17acb5c7ebb216c2b1af75c1709",
    "id": null,
    "metadata": {},
    "name": "RelatedDocs_Query",
    "operationKind": "query",
    "text": "query RelatedDocs_Query(\n  $contractUuid: String!\n) {\n  contract(uuid: $contractUuid) {\n    ...RelatedDocs_fragment\n    id\n  }\n}\n\nfragment RelatedDocTitle_fragment on RelatedDocType {\n  id\n  uuid\n  title\n}\n\nfragment RelatedDocs_fragment on ContractType {\n  id\n  title\n  amendedAndRestated\n  amendedTo {\n    uuid\n    title\n    isarchived\n    counterparties {\n      uuid\n      label\n      party {\n        uuid\n        companyName\n        id\n      }\n      id\n    }\n    effectiveDate {\n      date\n      id\n    }\n    id\n  }\n  amendedToNamed\n  relatedDocs {\n    uuid\n    title\n    ...RelatedDocTitle_fragment\n    filename\n    dateCreated\n    relationType\n    internalContract {\n      id\n      uuid\n      title\n      isarchived\n      effectiveDate {\n        date\n        id\n      }\n      counterparties {\n        uuid\n        label\n        party {\n          uuid\n          companyName\n          id\n        }\n        id\n      }\n    }\n    isGeneratedRedlineDocument\n    id\n  }\n  amendments {\n    uuid\n    title\n    isarchived\n    dateCreated\n    effectiveDate {\n      date\n      id\n    }\n    counterparties {\n      uuid\n      label\n      party {\n        uuid\n        companyName\n        id\n      }\n      id\n    }\n    amendments {\n      uuid\n      title\n      isarchived\n      dateCreated\n      effectiveDate {\n        date\n        id\n      }\n      counterparties {\n        uuid\n        label\n        party {\n          uuid\n          companyName\n          id\n        }\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "7ee4039ffe58e7e9706643d659a21c55";

export default node;
