import React from "react";
import "@fontsource/open-sans";
import { Button as MuiButton } from "@mui/material";
import { valueIfString } from "~/utils/global";

export default function Button(allProps) {
  const {
    primary,
    variant,
    disabled,
    size = "medium",
    label,
    color,
    children,
    "aria-label": ariaLabel,
    sx,
    ...props
  } = allProps;
  return (
    <MuiButton
      variant={variant || (primary || disabled ? "contained" : "outlined")}
      disableElevation
      disableRipple
      disableFocusRipple
      color={color || (primary ? "primary" : "secondary")}
      disabled={disabled}
      aria-label={ariaLabel || valueIfString(label)}
      sx={(theme) => ({
        textTransform: "none",
        fontFamily: "Open Sans",
        fontStyle: "normal",
        fontWeight: "bold",
        letterSpacing: 0,
        fontSize: { large: 18, medium: 16, small: 16, tiny: 14 }[size],
        lineHeight: {
          large: "25px",
          medium: "22px",
          small: "22px",
          tiny: "19px",
        }[size],
        "&:hover": {
          opacity: disabled ? "100%" : "60%",
        },
        "&:focus": {
          opacity: disabled ? "100%" : "60%",
        },
        "&:active": {
          opacity: disabled ? "100%" : "80%",
        },
        "&.MuiButton-contained": {
          borderWidth: 2,
          borderStyle: "solid",
          borderColor: disabled
            ? "#d0d2d4"
            : color
              ? theme.palette[color]?.main || color
              : "#0c4eb5",
          backgroundColor: disabled
            ? "#d0d2d4"
            : color
              ? theme.palette[color]?.main || color
              : "#0c4eb5",
          padding: {
            large: "16px 32px",
            medium: "12px 24px",
            small: "8px 16px",
            tiny: "4px 8px",
          }[size],
          "&:hover": {
            borderWidth: 2,
            borderStyle: "solid",
            borderColor: disabled
              ? "#d0d2d4"
              : color
                ? theme.palette[color]?.main || color
                : "#0c4eb5",
            backgroundColor: disabled
              ? "#d0d2d4"
              : color
                ? theme.palette[color]?.main || color
                : "#0c4eb5",
          },
        },
        "&.MuiButton-outlined": {
          color: disabled
            ? "#d0d2d4"
            : color
              ? theme.palette[color]?.main || color
              : "#0c4eb5",
          borderWidth: 2,
          borderStyle: "solid",
          borderColor: disabled
            ? "#d0d2d4"
            : color
              ? theme.palette[color]?.main || color
              : "#0c4eb5",
          padding: {
            large: "16px 32px",
            medium: "12px 24px",
            small: "8px 16px",
            tiny: "4px 8px",
          }[size],
          "&:hover": {
            borderWidth: 2,
            borderStyle: "solid",
            borderColor: ({ disabled, color }) =>
              disabled
                ? "#d0d2d4"
                : color
                  ? theme.palette[color]?.main || color
                  : "#0c4eb5",
          },
        },
        "&.MuiButton-text": {
          color: disabled
            ? "#d0d2d4"
            : color
              ? theme.palette[color]?.main || color
              : "#0c4eb5",
          padding: "0px 0px",
          minWidth: 0,
          "&:focus": {
            color: color ? theme.palette[color]?.main || color : "#0c4eb5",
          },
          "&:disabled": {
            color: "#d0d2d4",
            pointerEvents: "initial",
          },
        },
        ...sx,
      })}
      {...props}
    >
      {label}
      {children}
    </MuiButton>
  );
}
