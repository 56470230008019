/**
 * @generated SignedSource<<94e42555b1b033d0d35343440e49cdc3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type fieldHelpers_builtinFieldInlineFragment$data = {
  readonly choices: ReadonlyArray<{
    readonly id?: string;
    readonly name?: string;
  } | null | undefined> | null | undefined;
  readonly fieldType: string;
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "fieldHelpers_builtinFieldInlineFragment";
};
export type fieldHelpers_builtinFieldInlineFragment$key = {
  readonly " $data"?: fieldHelpers_builtinFieldInlineFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"fieldHelpers_builtinFieldInlineFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "fieldHelpers_builtinFieldInlineFragment"
};

(node as any).hash = "fa958ad9419eb9c35409abd5a2b8db77";

export default node;
