/**
 * @generated SignedSource<<461a3836401a23f1a36837d7a88f8abd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateContractFromTemplateDialogContent_rootQueryFragment$data = {
  readonly account: {
    readonly organization: {
      readonly templates: ReadonlyArray<{
        readonly id: string;
        readonly uuid: string;
        readonly " $fragmentSpreads": FragmentRefs<"TemplateFields_templateFragment" | "templateVariableHelpers_templateFragment" | "useTemplateFieldValues_templateInlineFragment">;
      }> | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"TemplateFields_organizationFragment" | "UpdateContractFromTemplateDialogTemplatesDropdown_organizationFragment" | "fieldHelpers_organizationInlineFragment" | "useTemplateFieldValues_organizationInlineFragment">;
    };
  };
  readonly builtinFields: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"TemplateFields_builtinFieldsFragment" | "fieldHelpers_builtinFieldInlineFragment" | "useTemplateFieldValues_builtinFieldInlineFragment">;
  }>;
  readonly " $fragmentType": "UpdateContractFromTemplateDialogContent_rootQueryFragment";
};
export type UpdateContractFromTemplateDialogContent_rootQueryFragment$key = {
  readonly " $data"?: UpdateContractFromTemplateDialogContent_rootQueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UpdateContractFromTemplateDialogContent_rootQueryFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "kind": "InlineDataFragmentSpread",
  "name": "templateVariableHelpers_templateFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TemplateVariableType",
      "kind": "LinkedField",
      "name": "variables",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "field",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "elementType",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "args": null,
  "argumentDefinitions": ([]/*: any*/)
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  (v0/*: any*/),
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fieldType",
  "storageKey": null
},
v5 = {
  "kind": "InlineDataFragmentSpread",
  "name": "fieldHelpers_organizationInlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dateFormat",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TagType",
      "kind": "LinkedField",
      "name": "tags",
      "plural": true,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrgContractTypeType",
      "kind": "LinkedField",
      "name": "orgContractTypes",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isActive",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CustomFieldType",
      "kind": "LinkedField",
      "name": "customFields",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/),
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ValueListItemType",
          "kind": "LinkedField",
          "name": "choices",
          "plural": true,
          "selections": (v3/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "args": null,
  "argumentDefinitions": ([]/*: any*/)
},
v6 = {
  "kind": "InlineDataFragmentSpread",
  "name": "fieldHelpers_builtinFieldInlineFragment",
  "selections": [
    (v0/*: any*/),
    (v2/*: any*/),
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "choices",
      "plural": true,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": (v3/*: any*/),
          "type": "StringChoiceType",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "args": null,
  "argumentDefinitions": ([]/*: any*/)
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpdateContractFromTemplateDialogContent_rootQueryFragment",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": "AccountType",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "alias": null,
              "args": null,
              "concreteType": "OrganizationType",
              "kind": "LinkedField",
              "name": "organization",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TemplateType",
                  "kind": "LinkedField",
                  "name": "templates",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "uuid",
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "TemplateFields_templateFragment"
                    },
                    {
                      "kind": "InlineDataFragmentSpread",
                      "name": "useTemplateFieldValues_templateInlineFragment",
                      "selections": [
                        (v1/*: any*/)
                      ],
                      "args": null,
                      "argumentDefinitions": []
                    },
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "TemplateFields_organizationFragment"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "UpdateContractFromTemplateDialogTemplatesDropdown_organizationFragment"
                },
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "useTemplateFieldValues_organizationInlineFragment",
                  "selections": [
                    (v5/*: any*/)
                  ],
                  "args": null,
                  "argumentDefinitions": []
                },
                (v5/*: any*/)
              ],
              "storageKey": null
            },
            "action": "THROW",
            "path": "account.organization"
          }
        ],
        "storageKey": null
      },
      "action": "THROW",
      "path": "account"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": "BuiltinFieldType",
        "kind": "LinkedField",
        "name": "builtinFields",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TemplateFields_builtinFieldsFragment"
          },
          {
            "kind": "InlineDataFragmentSpread",
            "name": "useTemplateFieldValues_builtinFieldInlineFragment",
            "selections": [
              (v6/*: any*/)
            ],
            "args": null,
            "argumentDefinitions": []
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      },
      "action": "THROW",
      "path": "builtinFields"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "e9d9c23dfb733f7b8a7cf5291936ed04";

export default node;
