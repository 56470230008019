import { graphql, useFragment, useMutation } from "react-relay";
import { resortCommentEdges } from "../relayStoreHelpers";
import type {
  useCommentPinnedAt_UpdateCommentPinnedAtMutation,
  useCommentPinnedAt_UpdateCommentPinnedAtMutation$data,
} from "./__generated__/useCommentPinnedAt_UpdateCommentPinnedAtMutation.graphql";
import type { useCommentPinnedAt_commentFragment$key } from "./__generated__/useCommentPinnedAt_commentFragment.graphql";

const COMMENT_FRAGMENT = graphql`
  fragment useCommentPinnedAt_commentFragment on CommentType {
    id
    uuid
    isAuthor
    pinnedAt
  }
`;

const UPDATE_COMMENT_PINNED_AT_MUTATION = graphql`
  mutation useCommentPinnedAt_UpdateCommentPinnedAtMutation(
    $commentUuid: String!
    $action: UpdateCommentPinnedAtActionType!
  ) {
    updateCommentPinnedAt(
      input: { commentUuid: $commentUuid, action: $action }
    ) {
      __typename
      ... on UpdateCommentPinnedAtSuccessResultType {
        comment {
          id
          ...UserComment_commentFragment
        }
      }
      ... on CommentNotFoundErrorType {
        debugMessage
      }
      ... on UnauthorizedError {
        debugMessage
      }
    }
  }
`;

export function useCommentPinnedAtActions(
  contractId: string,
  commentFragRef: useCommentPinnedAt_commentFragment$key,
  handlers?: {
    onSuccess?: (
      action: "UNPIN" | "PIN",
      response: useCommentPinnedAt_UpdateCommentPinnedAtMutation$data,
    ) => void;
    onResultError?: (
      action: "UNPIN" | "PIN",
      response: useCommentPinnedAt_UpdateCommentPinnedAtMutation$data,
    ) => void;
    onError?: (action: "UNPIN" | "PIN", error: Error) => void;
  },
) {
  const comment = useFragment(COMMENT_FRAGMENT, commentFragRef);
  const [
    commitUpdateCommentPinnedAtMutation,
    isUpdateCommentPinnedAtMutationInProgress,
  ] = useMutation<useCommentPinnedAt_UpdateCommentPinnedAtMutation>(
    UPDATE_COMMENT_PINNED_AT_MUTATION,
  );

  function handleCommentPinnedAtToggle() {
    const action = comment.pinnedAt ? "UNPIN" : "PIN";
    commitUpdateCommentPinnedAtMutation({
      variables: {
        commentUuid: comment.uuid,
        action,
      },
      updater: (store) => {
        // move newly pinned comment to top of comments list
        resortCommentEdges(store, contractId);
      },
      onCompleted: (response) => {
        if (
          response.updateCommentPinnedAt?.__typename !==
          "UpdateCommentPinnedAtSuccessResultType"
        ) {
          handlers?.onResultError?.(action, response);
          return;
        }

        handlers?.onSuccess?.(action, response);
        return;
      },

      onError: (error) => {
        console.error(
          "Unexpected error pinning comment comment. error: ",
          error,
        );

        handlers?.onError?.(action, error);
      },
    });
  }

  return {
    isUpdateCommentPinnedAtMutationInProgress,
    handleCommentPinnedAtToggle,
  };
}
