import { Chip } from "@mui/material";
import React from "react";
import { graphql, useFragment } from "react-relay";
import useAsyncMutation from "~/utils/UseAsyncMutation";
import type { ContractActiveStatusChip_contractFragment$key } from "./__generated__/ContractActiveStatusChip_contractFragment.graphql";
import { type ContractActiveStatusChip_isActiveMutation } from "./__generated__/ContractActiveStatusChip_isActiveMutation.graphql";

type ContractActiveStatusChipProps = {
  contractFragRef: ContractActiveStatusChip_contractFragment$key;
};

export function ContractActiveStatusChip(props: ContractActiveStatusChipProps) {
  const contract = useFragment(
    ContractActiveStatusChip_contractFragment,
    props.contractFragRef,
  );
  const [commitIsActive] =
    useAsyncMutation<ContractActiveStatusChip_isActiveMutation>(
      ContractActiveStatusChip_isActiveMutation,
    );

  async function handleContractActiveToggle() {
    commitIsActive({
      variables: {
        uuid: contract.uuid,
        isactive: !contract.isactive,
      },
      optimisticResponse: {
        updateContract: {
          contract: {
            id: contract.id,
            uuid: contract.uuid,
            isactive: !contract.isactive,
          },
        },
      },
    });
  }

  return (
    <Chip
      label={contract.isactive ? "Active" : "Inactive"}
      onClick={handleContractActiveToggle}
      sx={[
        {
          borderRadius: "8px",
          width: "80px",
        },
        contract.isactive && {
          backgroundColor: "rgba(26, 156, 5, 1)",
          border: "0.5px solid rgba(26, 156, 5, 1)",
          color: "#fff",
          "&:hover": {
            backgroundColor: "rgba(26, 156, 5, 0.7)",
          },
        },
        !contract.isactive && {
          backgroundColor: "rgba(217, 217, 217, 1)",
          color: "rgba(102, 108, 121, 1)",
          border: "0.5px solid rgba(102, 108, 121, 1)",
          "&:hover": {
            backgroundColor: "rgba(217, 217, 217, 0.7)",
          },
        },
      ]}
    />
  );
}

const ContractActiveStatusChip_contractFragment = graphql`
  fragment ContractActiveStatusChip_contractFragment on ContractType {
    id
    uuid
    isactive
  }
`;

const ContractActiveStatusChip_isActiveMutation = graphql`
  mutation ContractActiveStatusChip_isActiveMutation(
    $uuid: String!
    $isactive: Boolean
  ) @raw_response_type {
    updateContract(input: { uuid: $uuid, isactive: $isactive }) {
      contract {
        ...ContractActiveStatusChip_contractFragment
      }
    }
  }
`;
