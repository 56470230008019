import React, { useMemo } from "react";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Placeholder from "@tiptap/extension-placeholder";
import Text from "@tiptap/extension-text";
import Link from "@tiptap/extension-link";
import Mention from "@tiptap/extension-mention";
import CharacterCount from "@tiptap/extension-character-count";
import { getMentionSuggestionOptions } from "./mentionSuggestionOptions";
import { graphql } from "relay-runtime";
import { useFragment } from "react-relay";
import type { useExtensions_organizationFragment$key } from "./__generated__/useExtensions_organizationFragment.graphql";
import { TruncateContentExtension } from "./TruncateContentExtension";

const ORGANIZATION_FRAGMENT = graphql`
  fragment useExtensions_organizationFragment on OrganizationType {
    ...mentionSuggestionOptions_organizationFragment
  }
`;

// if you change this, you need to change the html sanitization on the backend in comment.py
const baseExtensions = [
  Document,
  Paragraph,
  Text,
  // Avoids text added after pasting a link into the editor from expanding the link's text. See this issue for more details on configuration: https://github.com/ueberdosis/tiptap/issues/2571#issuecomment-1712057913
  Link.extend({ inclusive: false }).configure({
    autolink: true,
    HTMLAttributes: { rel: "noopener noreferrer nofollow", target: null },
  }),
];

export const CHARACTER_COUNT_LIMIT = 2000;

export function useExtensions(
  currentUserUuid: string,
  organizationFragRef: useExtensions_organizationFragment$key,
  placeholder?: string,
) {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragRef);
  // if you change this, you need to change the html sanitization on the backend in comment.py
  const extensions = [
    ...baseExtensions,
    Placeholder.configure({
      placeholder,
    }),
    Mention.configure({
      suggestion: getMentionSuggestionOptions(currentUserUuid, organization),
    }),
    CharacterCount.configure({
      limit: CHARACTER_COUNT_LIMIT,
    }),
  ];

  return useMemo(() => extensions, [placeholder]);
}

export function useReadOnlyExtensions() {
  let extensions: any = [...baseExtensions, Mention];

  // due to potential complexity with this extension, we allow disabling extension at runtime in case of issues
  if (!waffle.switch_is_active("disable_truncate_content_extension")) {
    extensions.push(
      TruncateContentExtension.configure({
        truncateLength: 500,
      }),
    );
  }
  return useMemo(() => extensions, []);
}
