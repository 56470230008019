/**
 * @generated SignedSource<<dddca39dbb5f62d93d68a36dafa8ad61>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ValueSetAutocomplete_Query$variables = {
  name: string;
  skip: boolean;
};
export type ValueSetAutocomplete_Query$data = {
  readonly valueSet?: ReadonlyArray<string | null | undefined> | null | undefined;
};
export type ValueSetAutocomplete_Query = {
  response: ValueSetAutocomplete_Query$data;
  variables: ValueSetAutocomplete_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "name"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "condition": "skip",
    "kind": "Condition",
    "passingValue": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "name",
            "variableName": "name"
          }
        ],
        "kind": "ScalarField",
        "name": "valueSet",
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ValueSetAutocomplete_Query",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ValueSetAutocomplete_Query",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7a3899b6fe6853ee3f17ace50b4cf674",
    "id": null,
    "metadata": {},
    "name": "ValueSetAutocomplete_Query",
    "operationKind": "query",
    "text": "query ValueSetAutocomplete_Query(\n  $name: String!\n  $skip: Boolean!\n) {\n  valueSet(name: $name) @skip(if: $skip)\n}\n"
  }
};
})();

(node as any).hash = "7e36857491831b8249407440222be223";

export default node;
