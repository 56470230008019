import Grid from "@mui/material/Grid";
import { SimpleFormField } from "~/components/FormFields";
import LabeledItem from "~/components/LabeledItem";
import React from "react";
import { graphql, useFragment } from "react-relay";
import { z } from "zod";
import type { TemplateVariableSchema } from "./templateVariableSchema";
import type { TemplateVariableRegularField_organizationFragment$key } from "./__generated__/TemplateVariableRegularField_organizationFragment.graphql";
import { getFieldInfo } from "../../core/contracts/fieldHelpers";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import * as Sentry from "@sentry/react";
import { Typography } from "@mui/material";
import { getIsTemplateVariableRequired } from "./templateVariableHelpers";
import { TemplateContainsMissingFieldError } from "./errors";

type GetFieldValue<ValueType = unknown> = (
  currentValue: ValueType,
) => ValueType | ValueType;

type RegularTemplateVariable = Extract<
  z.infer<typeof TemplateVariableSchema>,
  { elementType: "regular-metadata" | "regular-document" }
>;

type TemplateVariableRegularFieldProps<ValueType = any> = {
  fieldInfo: ReturnType<typeof getFieldInfo>;
  templateVariable: RegularTemplateVariable;
  organizationFragRef: TemplateVariableRegularField_organizationFragment$key;
  value: ValueType;
  onChange: (value: ValueType) => void;
};

export function TemplateVariableRegularField(
  props: TemplateVariableRegularFieldProps,
) {
  const { fieldInfo, templateVariable } = props;
  const organization = useFragment(
    ORGANIZATION_FRAGMENT,
    props.organizationFragRef,
  );

  if (!fieldInfo) {
    console.error(
      "Could not find field info. fieldId:",
      props.templateVariable.field.id,
    );
    return null;
  }

  let required = getIsTemplateVariableRequired(templateVariable, fieldInfo);

  // shrink form field for date and booleans to improve UX
  const isNotMaxWidth =
    fieldInfo.fieldType === "d" ||
    fieldInfo.fieldType === "b" ||
    fieldInfo.fieldType === "c";

  return (
    <Grid item xs={12} key={fieldInfo.id}>
      <Box sx={{ maxWidth: isNotMaxWidth ? "fit-content" : "100%" }}>
        <Sentry.ErrorBoundary
          fallback={() => (
            <Stack direction="column" spacing={2}>
              {/** @ts-expect-error */}
              <LabeledItem label={fieldInfo.name} required={required}>
                <Typography variant="body1" color="error" paddingY={2}>
                  Something went wrong while loading this field.
                </Typography>
              </LabeledItem>
            </Stack>
          )}
        >
          <SimpleFormField
            label={fieldInfo.name}
            name={fieldInfo.name}
            field={fieldInfo}
            fieldValue={props.value}
            setFieldValue={(getFieldValue: GetFieldValue) => {
              const fieldValue =
                typeof getFieldValue === "function"
                  ? getFieldValue(props.value)
                  : getFieldValue;

              props.onChange(fieldValue);
            }}
            dateFormat={organization.dateFormat}
            required={required}
            noDebounce={true}
            data-testid={fieldInfo.id}
          />
        </Sentry.ErrorBoundary>
      </Box>
    </Grid>
  );
}

const ORGANIZATION_FRAGMENT = graphql`
  fragment TemplateVariableRegularField_organizationFragment on OrganizationType {
    dateFormat
    ...fieldHelpers_organizationInlineFragment
  }
`;
