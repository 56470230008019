/**
 * @generated SignedSource<<59734c8afeef1b7c64e9e43037fb77be>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PDFViewer_updateContractPartyMutation$variables = {
  companyName: string;
  contractUuid: string;
  label: string;
  partyUuid: string;
};
export type PDFViewer_updateContractPartyMutation$data = {
  readonly updateContractParty: {
    readonly contract: {
      readonly counterparties: ReadonlyArray<{
        readonly party: {
          readonly companyName: string | null | undefined;
          readonly uuid: string;
        } | null | undefined;
        readonly uuid: string;
      } | null | undefined>;
      readonly mycompanies: ReadonlyArray<{
        readonly label: string | null | undefined;
        readonly party: {
          readonly companyName: string | null | undefined;
          readonly uuid: string;
        } | null | undefined;
        readonly uuid: string;
      } | null | undefined>;
      readonly uuid: string;
    } | null | undefined;
  } | null | undefined;
};
export type PDFViewer_updateContractPartyMutation = {
  response: PDFViewer_updateContractPartyMutation$data;
  variables: PDFViewer_updateContractPartyMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyName"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contractUuid"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "label"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "partyUuid"
},
v4 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "contractuuid",
            "variableName": "contractUuid"
          },
          {
            "kind": "Variable",
            "name": "label",
            "variableName": "label"
          },
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "companyName",
                "variableName": "companyName"
              }
            ],
            "kind": "ObjectValue",
            "name": "party"
          },
          {
            "kind": "Variable",
            "name": "uuid",
            "variableName": "partyUuid"
          }
        ],
        "kind": "ObjectValue",
        "name": "contractparty"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "PartyType",
  "kind": "LinkedField",
  "name": "party",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    (v6/*: any*/)
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "PartyType",
  "kind": "LinkedField",
  "name": "party",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    (v6/*: any*/),
    (v9/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PDFViewer_updateContractPartyMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "UpdateContractPartyPayload",
        "kind": "LinkedField",
        "name": "updateContractParty",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractType",
            "kind": "LinkedField",
            "name": "contract",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ContractPartyType",
                "kind": "LinkedField",
                "name": "counterparties",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ContractPartyType",
                "kind": "LinkedField",
                "name": "mycompanies",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v8/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "PDFViewer_updateContractPartyMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "UpdateContractPartyPayload",
        "kind": "LinkedField",
        "name": "updateContractParty",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractType",
            "kind": "LinkedField",
            "name": "contract",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ContractPartyType",
                "kind": "LinkedField",
                "name": "counterparties",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v10/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ContractPartyType",
                "kind": "LinkedField",
                "name": "mycompanies",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v8/*: any*/),
                  (v10/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              (v9/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "174015e10276736d3984570b44b343b2",
    "id": null,
    "metadata": {},
    "name": "PDFViewer_updateContractPartyMutation",
    "operationKind": "mutation",
    "text": "mutation PDFViewer_updateContractPartyMutation(\n  $label: String!\n  $companyName: String!\n  $contractUuid: String!\n  $partyUuid: String!\n) {\n  updateContractParty(input: {contractparty: {uuid: $partyUuid, contractuuid: $contractUuid, label: $label, party: {companyName: $companyName}}}) {\n    contract {\n      uuid\n      counterparties {\n        uuid\n        party {\n          uuid\n          companyName\n          id\n        }\n        id\n      }\n      mycompanies {\n        uuid\n        label\n        party {\n          uuid\n          companyName\n          id\n        }\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "44f2fdc4383890ac6f608e67e6c9df55";

export default node;
