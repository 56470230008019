import React from "react";
import { graphql, useFragment } from "react-relay";
import {
  CommentTextEditor,
  CommentTextEditorReadOnly,
  type CommentTextEditorContent,
  CharacterCount,
} from "../CommentTextEditor/CommentTextEditor";
import type { UserCommentContent_currentUserAccountFragment$key } from "./__generated__/UserCommentContent_currentUserAccountFragment.graphql";
import type { UserCommentContent_commentFragment$key } from "./__generated__/UserCommentContent_commentFragment.graphql";
import Stack from "@mui/material/Stack";
import Button from "~/components/Button";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "~/components/Snackbar";
import { useEditCommentContent } from "./useEditCommentContent";
import SaveIcon from "@mui/icons-material/Save";
import Alert from "@mui/material/Alert";
import { parseMentions } from "../commentValidationHelpers";
import { useSpinDelay } from "spin-delay";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";

const COMMENT_FRAGMENT = graphql`
  fragment UserCommentContent_commentFragment on CommentType {
    id
    uuid
    htmlContent
  }
`;

const CURRENT_USER_ACCOUNT_FRAGMENT = graphql`
  fragment UserCommentContent_currentUserAccountFragment on AccountType {
    uuid
    organization @required(action: THROW) {
      ...CommentTextEditor_organizationFragment
    }
  }
`;

type UserCommentContentProps = {
  comment: UserCommentContent_commentFragment$key;
  currentUser: UserCommentContent_currentUserAccountFragment$key;
  isEditingContent: boolean;
  setIsEditingContent: (isEditingContent: boolean) => void;
};

export function UserCommentContent(props: UserCommentContentProps) {
  const comment = useFragment<UserCommentContent_commentFragment$key>(
    COMMENT_FRAGMENT,
    props.comment,
  );
  const currentUser = useFragment(
    CURRENT_USER_ACCOUNT_FRAGMENT,
    props.currentUser,
  );
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [warningMessage, setWarningMessage] = React.useState<string | null>(
    null,
  );
  const { openSnackbarWithProps, closeSnackbar } = useSnackbar();
  const { handleEditCommentContent, isEditCommentContentMutationInFlight } =
    useEditCommentContent();

  const isEditCommentContentInProgress = useSpinDelay(
    isEditCommentContentMutationInFlight,
    {
      delay: 500,
      minDuration: 200,
    },
  );

  const handleCommentContentSubmit = (content: CommentTextEditorContent) => {
    setWarningMessage(null);
    setErrorMessage(null);

    const parseMentionsResult = parseMentions(content);
    if (!parseMentionsResult.isValid) {
      // NOTE: this can still trigger if they type @bob@org.com and bob is a member of the organization but they didn't select the mention using the tiptap mention extension. We ignore that extensive check at this time because it's tough to explain to the user. Customers should hopefully figure that out themselves by re-typing.
      const emails = parseMentionsResult.invalidMentionEmails;

      setErrorMessage(
        `Could not create comment. You @mentioned the following email that is not a member of your organization. Only organization members can be mentioned in comments. Email: ${emails[0]}`,
      );
      return;
    }

    const updatedContent = {
      htmlContent: content.htmlContent,
      jsonContent: JSON.stringify(content.jsonContent),
      textContent: content.textContent,
    };

    const onUnexpectedError = () => {
      setErrorMessage(
        "Could not edit comment. Please try again shortly. If the problem persists after reloading the page, please contact support.",
      );
    };

    handleEditCommentContent(comment.uuid, updatedContent, {
      onValidationResultError: (errorsInfo, result) => {
        const {
          accountUnauthorizedValidationErrorEmails,
          hasAccountUnauthorizedValidationErrors,
          otherValidationErrors,
          hasOtherValidationErrors,
        } = errorsInfo;

        if (hasAccountUnauthorizedValidationErrors) {
          setErrorMessage(
            `Could not edit comment. The following mentioned users do not have access to the contract: ${accountUnauthorizedValidationErrorEmails.join(
              ", ",
            )}.`,
          );
          return;
        }

        if (hasOtherValidationErrors) {
          console.error(
            `Unexpected errors encountered when creating comment. errors: `,
            otherValidationErrors,
          );
          setErrorMessage(
            "Could not edit comment. Validation failed. Please try again.",
          );
          return;
        }
      },
      onSuccess: () => {
        openSnackbarWithProps({
          children: (
            <Alert onClick={closeSnackbar} severity="success">
              Successfully edited comment.
            </Alert>
          ),
        });
        props.setIsEditingContent(false);
      },
      onUnexpectedResultError: onUnexpectedError,
      onError: onUnexpectedError,
    });
  };

  const handleCancelEditContent = () => {
    setWarningMessage(null);
    setErrorMessage(null);
    props.setIsEditingContent(false);
  };

  return (
    <Stack direction="column">
      {props.isEditingContent ? (
        <>
          <CommentTextEditor
            content={comment.htmlContent}
            editable={true}
            currentUserUuid={currentUser.uuid}
            organizationFragRef={currentUser.organization}
            onSubmit={handleCommentContentSubmit}
            renderFooter={({ handleSubmit, editor }) => (
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{
                  borderTopStyle: "solid",
                  borderTopWidth: 1,
                  borderTopColor: (theme) => theme.palette.divider,
                  py: 1,
                  px: 1.5,
                }}
                alignItems="center"
              >
                <Typography variant="body2">
                  {isEditCommentContentMutationInFlight && "Saving..."}
                </Typography>
                <Stack direction={"row"} spacing={1}>
                  <CharacterCount editor={editor} />
                  <Button
                    label="Cancel"
                    onClick={handleCancelEditContent}
                    sx={{ width: "fit-content" }}
                    size="tiny"
                    variant="outlined"
                    data-testid="user-comment-edit-content-cancel-button"
                  />
                  <LoadingButton
                    size="small"
                    onClick={handleSubmit}
                    sx={{
                      width: "fit-content",
                      fontWeight: "bold",
                      textTransform: "none",
                    }}
                    variant="contained"
                    data-testid="user-comment-edit-content-save-button"
                    disabled={isEditCommentContentMutationInFlight}
                    loading={isEditCommentContentInProgress}
                    loadingPosition="end"
                    endIcon={<SaveIcon />}
                  >
                    Save
                  </LoadingButton>
                </Stack>
              </Stack>
            )}
          />
          {errorMessage && (
            <Typography
              color="error"
              sx={{ my: 2 }}
              data-testid="user-comment-content-error-message"
            >
              {errorMessage}
            </Typography>
          )}
          {warningMessage && (
            <Typography color="warning" sx={{ my: 2 }}>
              {warningMessage}
            </Typography>
          )}
        </>
      ) : (
        <CommentTextEditorReadOnly content={comment.htmlContent} />
      )}
    </Stack>
  );
}
