/**
 * @generated SignedSource<<ddb880c69f08bbe825f15185742d8286>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FormSelect_formFragment$data = ReadonlyArray<{
  readonly id: string;
  readonly name: string | null | undefined;
  readonly requiredFields: ReadonlyArray<{
    readonly __typename: "BuiltinFieldType";
    readonly fieldType: string;
    readonly id: string;
    readonly name: string;
  } | {
    readonly __typename: "CustomFieldType";
    readonly fieldType: string;
    readonly id: string;
    readonly name: string;
  } | {
    readonly __typename: "FieldSetType";
    readonly id: string;
    readonly name: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined> | null | undefined;
  readonly sections: ReadonlyArray<{
    readonly fields: ReadonlyArray<{
      readonly __typename: "BuiltinFieldType";
      readonly choices: ReadonlyArray<{
        readonly id?: string;
        readonly name?: string;
      } | null | undefined> | null | undefined;
      readonly fieldType: string;
      readonly id: string;
      readonly name: string;
    } | {
      readonly __typename: "CustomFieldType";
      readonly choices: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
      } | null | undefined> | null | undefined;
      readonly fieldType: string;
      readonly id: string;
      readonly name: string;
    } | {
      readonly __typename: "FieldSetType";
      readonly id: string;
      readonly name: string;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    } | null | undefined> | null | undefined;
    readonly name: string;
  } | null | undefined> | null | undefined;
  readonly uuid: string;
  readonly " $fragmentType": "FormSelect_formFragment";
}>;
export type FormSelect_formFragment$key = ReadonlyArray<{
  readonly " $data"?: FormSelect_formFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"FormSelect_formFragment">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fieldType",
  "storageKey": null
},
v4 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v5 = {
  "kind": "InlineFragment",
  "selections": (v4/*: any*/),
  "type": "FieldSetType",
  "abstractKey": null
},
v6 = [
  (v0/*: any*/),
  (v1/*: any*/),
  (v3/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "FormSelect_formFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uuid",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "FormSectionType",
      "kind": "LinkedField",
      "name": "sections",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "fields",
          "plural": true,
          "selections": [
            (v2/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ValueListItemType",
                  "kind": "LinkedField",
                  "name": "choices",
                  "plural": true,
                  "selections": (v4/*: any*/),
                  "storageKey": null
                }
              ],
              "type": "CustomFieldType",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "choices",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": (v4/*: any*/),
                      "type": "StringChoiceType",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "BuiltinFieldType",
              "abstractKey": null
            },
            (v5/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "requiredFields",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": (v6/*: any*/),
          "type": "CustomFieldType",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v6/*: any*/),
          "type": "BuiltinFieldType",
          "abstractKey": null
        },
        (v5/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "FormType",
  "abstractKey": null
};
})();

(node as any).hash = "4a268b328b488641a4e2e06c82040721";

export default node;
