import React from "react";
import {
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  type SxProps,
  type Theme,
} from "@mui/material";
import { CONTRACT_PAGE_ACTIONS } from "./actions";
import { Link, useLocation } from "react-router";

type ContractPageBottomNavigationProps = {
  sx?: SxProps<Theme>;
};

export function ContractPageBottomNavigation(
  props: ContractPageBottomNavigationProps,
) {
  const location = useLocation();
  const currentPath = location.pathname.split("/").pop() || "properties";

  return (
    <Paper
      sx={[
        {
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1000,
        },
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      ]}
      elevation={3}
    >
      <BottomNavigation value={currentPath} showLabels={false}>
        {CONTRACT_PAGE_ACTIONS.map((action) => (
          <BottomNavigationAction
            key={action.name}
            value={action.routePath}
            icon={action.icon}
            component={Link}
            to={action.routePath}
            sx={{
              minWidth: "auto",
              padding: "6px 12px",
              "& .MuiBottomNavigationAction-label": {
                display: "none",
              },
            }}
          />
        ))}
      </BottomNavigation>
    </Paper>
  );
}
