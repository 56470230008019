import React from "react";
import { SvgIcon, type SvgIconProps } from "@mui/material";

export function PropertiesIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
      >
        <path
          d="M16.0555 3V21"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.337 3H5.35186C4.16956 3 3.21112 3.89543 3.21112 5V19C3.21112 20.1046 4.16956 21 5.35186 21H20.337C21.5193 21 22.4778 20.1046 22.4778 19V5C22.4778 3.89543 21.5193 3 20.337 3Z"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.4778 9H3.21112"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.4778 15H3.21112"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
