import React, { useState, useCallback } from "react";
import { graphql, useFragment } from "react-relay";
import useAsyncMutation from "~/utils/UseAsyncMutation";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import LinearProgress from "@mui/material/LinearProgress";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "~/components/Button";
import { TextField } from "~/components/Field";
import { useFeatures } from "~/features";

export default function UpdateContractDialog({
  account,
  contract,
  open,
  handleClose,
  setPermErrorDialog,
  onCommit,
  ...props
}) {
  const features = useFeatures();
  const { canDelete } = useFragment(
    graphql`
      fragment UpdateContractDialog_accountFragment on AccountType {
        canDelete
      }
    `,
    account,
  );
  const {
    uuid,
    originurl: { url: startUrl },
  } = useFragment(
    graphql`
      fragment UpdateContractDialog_fragment on ContractType {
        uuid
        originurl {
          url
        }
      }
    `,
    contract,
  );
  const [updating, setUpdating] = useState(false);
  const [prevDocAction, setPrevDocAction] = useState("attach");
  const [url, setUrl] = useState(startUrl);

  const [commitReplaceContract] = useAsyncMutation(graphql`
    mutation UpdateContractDialog_Mutation(
      $uuid: String!
      $url: String!
      $moveToRelated: Boolean!
      $generateRedline: Boolean!
    ) {
      replaceContract(
        input: {
          uuid: $uuid
          url: $url
          moveToRelated: $moveToRelated
          generateRedline: $generateRedline
        }
      ) {
        message
      }
    }
  `);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleUrl = async () => {
    setUpdating(true);
    try {
      const moveToRelated =
        prevDocAction == "attach" || prevDocAction == "redline";
      const generateRedline = prevDocAction == "redline";
      if (generateRedline && !features.redlines) {
        setPermErrorDialog();
        return;
      }

      const r = await commitReplaceContract({
        variables: { uuid, url, moveToRelated, generateRedline },
      });
      handleClose();
      window.location.reload();
    } catch (e) {
      onCommit(e.message);
    } finally {
      setUpdating(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" style={{ textAlign: "center" }}>
        {updating ? "Updating" : "Update Contract"}
      </DialogTitle>
      {updating ? (
        <DialogContent>
          <LinearProgress />
        </DialogContent>
      ) : (
        <>
          <DialogContent dividers>
            <TextField
              label="Update from URL"
              placeholder="Add a URL"
              value={url}
              onChange={({ target: { value } }) => setUrl(value)}
            />
            <Grid
              container
              direction="column"
              spacing={2}
              style={{ marginTop: 16 }}
            >
              <Grid item>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    What would you like to do with the previous document?
                  </FormLabel>
                  <Box ml={4} mt={2}>
                    <RadioGroup
                      aria-label="action"
                      name="action"
                      value={prevDocAction}
                      onChange={(e) => setPrevDocAction(e.target.value)}
                    >
                      {canDelete ? (
                        <FormControlLabel
                          value="delete"
                          control={<Radio />}
                          label="Delete document"
                        />
                      ) : null}
                      <FormControlLabel
                        value="attach"
                        control={<Radio />}
                        label="Save as Attachment"
                      />
                      <FormControlLabel
                        value="redline"
                        control={<Radio />}
                        label="Save as Attachment and Generate Redline"
                      />
                    </RadioGroup>
                  </Box>
                </FormControl>
              </Grid>
              <Grid item>
                <div>
                  This does not change any of the dates, reminders or other data
                  entered about this document.
                </div>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button size="small" onClick={handleClose}>
              Cancel
            </Button>
            <Button size="small" onClick={handleUrl} primary disabled={!url}>
              Update
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
