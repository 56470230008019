import { graphql, useMutation } from "react-relay";
import type {
  CommentContentInputType,
  useEditCommentContent_EditCommentContentMutation,
  useEditCommentContent_EditCommentContentMutation$data,
} from "./__generated__/useEditCommentContent_EditCommentContentMutation.graphql";

export type EditCommentContentSuccessResultType = Extract<
  NonNullable<
    useEditCommentContent_EditCommentContentMutation$data["editCommentContent"]
  >,
  { __typename: "EditCommentContentSuccessResultType" }
>;

export type EditCommentContentUnexpectedErrorResultType = Extract<
  NonNullable<
    useEditCommentContent_EditCommentContentMutation$data["editCommentContent"]
  >,
  { __typename: "CommentNotFoundErrorType" | "%other" }
>;

export type EditCommentContentValidationErrorResultType = Extract<
  NonNullable<
    useEditCommentContent_EditCommentContentMutation$data["editCommentContent"]
  >,
  { __typename: "EditCommentContentValidationResultType" }
>;

const EDIT_COMMENT_CONTENT_MUTATION = graphql`
  mutation useEditCommentContent_EditCommentContentMutation(
    $commentUuid: String!
    $updatedContent: CommentContentInputType!
  ) {
    editCommentContent(
      input: { commentUuid: $commentUuid, updatedContent: $updatedContent }
    ) {
      __typename
      ... on EditCommentContentSuccessResultType {
        commentEdge {
          node {
            hasContentEditRevisions
            uuid
            htmlContent
            jsonContent
            textContent
          }
        }
        notificationErrors {
          __typename
          ... on CommentNotificationAccountUnauthorizedErrorType {
            debugMessage
            accountUuid
          }
          ... on CommentNotificationUnexpectedErrorType {
            debugMessage
            accountUuid
          }
        }
      }
      ... on CommentNotFoundErrorType {
        debugMessage
      }
      ... on EditCommentContentValidationResultType {
        errors {
          __typename
          ... on CommentMentionAccountUnauthorizedValidationErrorType {
            debugMessage
            accountUuid
            accountUnauthorizedEmail: mentionedEmail
          }
          ... on ValidationError {
            debugMessage
          }
        }
      }
    }
  }
`;

export function useEditCommentContent() {
  const [
    commitEditCommentContentMutation,
    isEditCommentContentMutationInFlight,
  ] = useMutation<useEditCommentContent_EditCommentContentMutation>(
    EDIT_COMMENT_CONTENT_MUTATION,
  );

  function handleEditCommentContent(
    commentUuid: string,
    updatedContent: CommentContentInputType,
    handlers?: {
      onSuccess?: (result: EditCommentContentSuccessResultType) => void;
      onValidationResultError?: (
        errorsInfo: ReturnType<typeof getValidationResultErrors>,
        result: EditCommentContentValidationErrorResultType,
      ) => void;
      onUnexpectedResultError?: (
        result: EditCommentContentUnexpectedErrorResultType,
      ) => void;
      onError?: (error: Error) => void;
    },
  ) {
    commitEditCommentContentMutation({
      variables: {
        commentUuid,
        updatedContent,
      },
      onCompleted: (response) => {
        const result = response.editCommentContent;

        if (!result) {
          return;
        }

        if (result.__typename === "EditCommentContentSuccessResultType") {
          handlers?.onSuccess?.(result);
        } else if (result.__typename === "%other") {
          console.error(
            `Unhandled result type when editing comment content. result: `,
            result,
          );
          handlers?.onUnexpectedResultError?.(result);
        } else if (result.__typename === "CommentNotFoundErrorType") {
          console.error(
            `Unexpected error encountered when editing comment content. error_debugMessage: ${result.debugMessage}`,
          );
          handlers?.onUnexpectedResultError?.(result);
        } else if (
          result.__typename === "EditCommentContentValidationResultType"
        ) {
          const errorsInfo = getValidationResultErrors(result);
          handlers?.onValidationResultError?.(errorsInfo, result);
        }
      },
      onError: (error) => {
        console.error(
          "Unexpected error pinning comment comment. error: ",
          error,
        );

        handlers?.onError?.(error);
      },
    });
  }

  return {
    isEditCommentContentMutationInFlight,
    handleEditCommentContent,
  };
}

function getValidationResultErrors(
  result: EditCommentContentValidationErrorResultType,
) {
  let validationErrors = result.errors;

  const accountUnauthorizedValidationErrors = validationErrors.flatMap(
    (error) =>
      error.__typename ===
      "CommentMentionAccountUnauthorizedValidationErrorType"
        ? [error]
        : [],
  );

  const accountUnauthorizedValidationErrorEmails =
    accountUnauthorizedValidationErrors.map(
      (error) => error.accountUnauthorizedEmail,
    );

  const hasAccountUnauthorizedValidationErrors =
    accountUnauthorizedValidationErrorEmails.length > 0;

  const otherValidationErrors = validationErrors.flatMap((error) =>
    error.__typename === "ValidationError" ? [error] : [],
  );
  const hasOtherValidationErrors = otherValidationErrors.length > 0;

  return {
    accountUnauthorizedValidationErrors,
    accountUnauthorizedValidationErrorEmails,
    hasAccountUnauthorizedValidationErrors,
    otherValidationErrors,
    hasOtherValidationErrors,
  };
}
