import React, { Suspense } from "react";
import {
  createRoutesFromElements,
  Route,
  Outlet,
  Navigate,
  useParams,
} from "react-router";
import * as Sentry from "@sentry/react";
import Link from "@mui/material/Link";
import BaselineThemed from "./BaselineThemed";
import { ChunkLoadErrorBoundary } from "./components/ChunkLoadErrorBoundary";
import { default as Main } from "./ReactMain";
import * as contractV4 from "./contractV4/routes";

const Login = React.lazy(() => import("~/authentication/components/Login"));
const IpNotWhitelisted = React.lazy(
  () => import("~/authentication/components/IpNotWhitelisted"),
);
const DashboardPage = React.lazy(
  () => import("~/dashboard/components/DashboardPage"),
);

const ContractsListPage = React.lazy(
  () => import("~/contracts/components/ContractsListPage"),
);
const ResetPassword = React.lazy(
  () => import("~/authentication/components/ResetPassword"),
);
const ForgotPassword = React.lazy(
  () => import("~/authentication/components/ForgotPassword"),
);
const AcceptInvitePage = React.lazy(
  () => import("~/authentication/components/AcceptInvitePage"),
);
const ContractDetailPage = React.lazy(
  () => import("~/contracts/components/ContractDetailPage"),
);
const ExportRequestPage = React.lazy(
  () => import("~/contracts/components/ExportRequestPage"),
);
const SettingsPage = React.lazy(
  () => import("~/settings/components/SettingsPage"),
);
const ViewerWindow = React.lazy(
  () => import("~/contracts/components/ViewerWindow"),
);
const DocusignRedirect = React.lazy(
  () => import("~/contracts/components/DocusignRedirect"),
);
const ContractSummaryPage = React.lazy(
  () => import("~/components/ContractSummary"),
);
const HelloErrorsPage = React.lazy(
  () => import("~/components/HelloErrorsPage"),
);
const HelloWorldPage = React.lazy(() => import("~/components/HelloWorldPage"));
const IntakePage = React.lazy(() => import("~/components/Intake"));
const TemplatePage = React.lazy(() => import("~/components/Template"));
const TemplatePageV2 = React.lazy(() => import("~/components/TemplatePageV2"));

function RedirectContracts() {
  const { uuid } = useParams();
  return <Navigate to={`/v3/contracts/${uuid}`} replace />;
}

function InvalidPasswordTokenMessage() {
  return (
    <div style={{ paddingTop: "20px", height: "calc(100vh - 72px)" }}>
      <center>
        <p>The token you have received has expired or is invalid.</p>
        <p>
          Please go <Link href="/user/forgot_password/">HERE</Link> to request
          another password reset.
        </p>
      </center>
    </div>
  );
}

function BaselineThemedLayout({ sx = {} }) {
  return (
    <BaselineThemed sx={sx}>
      <Outlet />
    </BaselineThemed>
  );
}

function MainLayout() {
  return (
    <Sentry.ErrorBoundary fallback={({ error }) => <div>{error.message}</div>}>
      <ChunkLoadErrorBoundary>
        <Suspense fallback={<div />}>
          <Outlet />
        </Suspense>
      </ChunkLoadErrorBoundary>
    </Sentry.ErrorBoundary>
  );
}

function LoginLayout() {
  return (
    <div className="login-container" id="lgct" style={{ marginTop: "92px" }}>
      <Outlet />
    </div>
  );
}

function FlaggedTemplatePage() {
  return waffle.flag_is_active("templates_page_v2_enabled") ? (
    <TemplatePageV2 />
  ) : (
    <TemplatePage />
  );
}

export default createRoutesFromElements(
  <Route Component={MainLayout} HydrateFallback={React.Fragment}>
    <Route Component={Main}>
      <Route path="contracts/:uuid" Component={RedirectContracts} />
      <Route path="dashboard/:uuid" Component={RedirectContracts} />
      <Route path="onboarding/:uuid">
        <Route path="*" Component={React.Fragment} />
      </Route>

      <Route Component={BaselineThemedLayout}>
        <Route path="contracts" Component={ContractsListPage} />
        <Route
          path="user/export_request/:uuid/"
          Component={ExportRequestPage}
        />
        <Route path="user/accept_invite/" Component={AcceptInvitePage} />
        <Route path="dashboard" Component={DashboardPage} />
        <Route
          path="v3/attch/:uuid/document"
          element={<ViewerWindow attachment />}
        />
        <Route
          path="v3/contracts/:uuid/summary"
          Component={ContractSummaryPage}
        />
        <Route path="v3/contracts/:uuid/document" Component={ViewerWindow} />
        <Route path="v3/contracts/:uuid" Component={ContractDetailPage} />
        <Route path="docusign" Component={DocusignRedirect} />
        <Route path="settings/:section?" Component={SettingsPage} />
        <Route path="settingsv2/:section?" Component={SettingsPage} />
        <Route path="intake/:uuid?/:token?" Component={IntakePage} />
        <Route path="template/:uuid?/:token?" Component={FlaggedTemplatePage} />
        <Route path="templatev1/:uuid?/:token?" Component={TemplatePage} />
        <Route path="templatev2/:uuid?/:token?" Component={TemplatePageV2} />
        <Route path="user/forgot_password/" Component={ForgotPassword} />
        <Route
          path="user/invalid_password_token/"
          Component={InvalidPasswordTokenMessage}
        />
      </Route>
      <Route
        element={
          <BaselineThemedLayout
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          />
        }
      >
        {contractV4.routes}
      </Route>

      <Route path="user/reset_password/:token" Component={ResetPassword} />
      <Route
        path="user/welcome_set_password/:token"
        Component={ResetPassword}
      />
      <Route path="user/invited_user/:token" Component={ResetPassword} />
      <Route path="hello_errors" Component={HelloErrorsPage} />
      <Route path="hello_world" Component={HelloWorldPage} />
      <Route path="empty" Component={React.Fragment} />

      <Route Component={LoginLayout}>
        <Route path="register" Component={Login} />
        <Route path="login" Component={Login} />
        <Route path="ip_notwhitelisted" Component={IpNotWhitelisted} />
        <Route path="*" Component={Login} />
      </Route>
    </Route>
  </Route>,
);
