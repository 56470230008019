/**
 * @generated SignedSource<<25a6d802bed990c67260aee91848d30c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Parties_ContractPartiesFragment$data = {
  readonly counterparties: ReadonlyArray<{
    readonly label: string | null | undefined;
    readonly party: {
      readonly companyName: string | null | undefined;
      readonly uuid: string;
    } | null | undefined;
    readonly uuid: string;
    readonly " $fragmentSpreads": FragmentRefs<"PartyDialog_CTPartyfragment">;
  } | null | undefined>;
  readonly mycompanies: ReadonlyArray<{
    readonly label: string | null | undefined;
    readonly party: {
      readonly companyName: string | null | undefined;
      readonly uuid: string;
    } | null | undefined;
    readonly uuid: string;
    readonly " $fragmentSpreads": FragmentRefs<"PartyDialog_CTPartyfragment">;
  } | null | undefined>;
  readonly uuid: string;
  readonly " $fragmentType": "Parties_ContractPartiesFragment";
};
export type Parties_ContractPartiesFragment$key = {
  readonly " $data"?: Parties_ContractPartiesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"Parties_ContractPartiesFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "label",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PartyType",
    "kind": "LinkedField",
    "name": "party",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "companyName",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "PartyDialog_CTPartyfragment"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Parties_ContractPartiesFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ContractPartyType",
      "kind": "LinkedField",
      "name": "mycompanies",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ContractPartyType",
      "kind": "LinkedField",
      "name": "counterparties",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    }
  ],
  "type": "ContractType",
  "abstractKey": null
};
})();

(node as any).hash = "f0e2e69f5553730fb62c119fd6c916c4";

export default node;
