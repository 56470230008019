/**
 * @generated SignedSource<<034db2919b817af7390d194b393e6e74>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RelatedDocs_fragment$data = {
  readonly amendedAndRestated: boolean;
  readonly amendedTo: {
    readonly counterparties: ReadonlyArray<{
      readonly label: string | null | undefined;
      readonly party: {
        readonly companyName: string | null | undefined;
        readonly uuid: string;
      } | null | undefined;
      readonly uuid: string;
    } | null | undefined>;
    readonly effectiveDate: {
      readonly date: any | null | undefined;
    } | null | undefined;
    readonly isarchived: boolean;
    readonly title: string | null | undefined;
    readonly uuid: string;
  } | null | undefined;
  readonly amendedToNamed: string | null | undefined;
  readonly amendments: ReadonlyArray<{
    readonly amendments: ReadonlyArray<{
      readonly counterparties: ReadonlyArray<{
        readonly label: string | null | undefined;
        readonly party: {
          readonly companyName: string | null | undefined;
          readonly uuid: string;
        } | null | undefined;
        readonly uuid: string;
      } | null | undefined>;
      readonly dateCreated: string;
      readonly effectiveDate: {
        readonly date: any | null | undefined;
      } | null | undefined;
      readonly isarchived: boolean;
      readonly title: string | null | undefined;
      readonly uuid: string;
    } | null | undefined>;
    readonly counterparties: ReadonlyArray<{
      readonly label: string | null | undefined;
      readonly party: {
        readonly companyName: string | null | undefined;
        readonly uuid: string;
      } | null | undefined;
      readonly uuid: string;
    } | null | undefined>;
    readonly dateCreated: string;
    readonly effectiveDate: {
      readonly date: any | null | undefined;
    } | null | undefined;
    readonly isarchived: boolean;
    readonly title: string | null | undefined;
    readonly uuid: string;
  } | null | undefined>;
  readonly id: string;
  readonly relatedDocs: ReadonlyArray<{
    readonly dateCreated: string;
    readonly filename: string;
    readonly internalContract: {
      readonly counterparties: ReadonlyArray<{
        readonly label: string | null | undefined;
        readonly party: {
          readonly companyName: string | null | undefined;
          readonly uuid: string;
        } | null | undefined;
        readonly uuid: string;
      } | null | undefined>;
      readonly effectiveDate: {
        readonly date: any | null | undefined;
      } | null | undefined;
      readonly id: string;
      readonly isarchived: boolean;
      readonly title: string | null | undefined;
      readonly uuid: string;
    } | null | undefined;
    readonly isGeneratedRedlineDocument: boolean | null | undefined;
    readonly relationType: string;
    readonly title: string | null | undefined;
    readonly uuid: string;
    readonly " $fragmentSpreads": FragmentRefs<"RelatedDocTitle_fragment">;
  } | null | undefined>;
  readonly title: string | null | undefined;
  readonly " $fragmentType": "RelatedDocs_fragment";
};
export type RelatedDocs_fragment$key = {
  readonly " $data"?: RelatedDocs_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"RelatedDocs_fragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isarchived",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "ContractPartyType",
  "kind": "LinkedField",
  "name": "counterparties",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PartyType",
      "kind": "LinkedField",
      "name": "party",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "companyName",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "ContractDateType",
  "kind": "LinkedField",
  "name": "effectiveDate",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dateCreated",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RelatedDocs_fragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amendedAndRestated",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ContractType",
      "kind": "LinkedField",
      "name": "amendedTo",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        (v1/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amendedToNamed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RelatedDocType",
      "kind": "LinkedField",
      "name": "relatedDocs",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        (v1/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "RelatedDocTitle_fragment"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "filename",
          "storageKey": null
        },
        (v6/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "relationType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ContractType",
          "kind": "LinkedField",
          "name": "internalContract",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v2/*: any*/),
            (v1/*: any*/),
            (v3/*: any*/),
            (v5/*: any*/),
            (v4/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isGeneratedRedlineDocument",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ContractType",
      "kind": "LinkedField",
      "name": "amendments",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        (v1/*: any*/),
        (v3/*: any*/),
        (v6/*: any*/),
        (v5/*: any*/),
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ContractType",
          "kind": "LinkedField",
          "name": "amendments",
          "plural": true,
          "selections": [
            (v2/*: any*/),
            (v1/*: any*/),
            (v3/*: any*/),
            (v6/*: any*/),
            (v5/*: any*/),
            (v4/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ContractType",
  "abstractKey": null
};
})();

(node as any).hash = "05502e3db8fac4f9a92f665e85e421e8";

export default node;
