import React, { useState, useCallback, useEffect, Suspense } from "react";
import useAsyncMutation from "~/utils/UseAsyncMutation";
import { commitLocalUpdate, graphql } from "react-relay";
import {
  useFragment,
  useLazyLoadQuery,
  useRelayEnvironment,
} from "react-relay";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import classNames from "classnames";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import { InputLabel, TextField } from "~/components/Field";
import Button from "~/components/Button";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import RelatedDocs_fragment from "./RelatedDocs";
import { track } from "~/analytics/events";

const WrappingInputLabel = styled(InputLabel)({
  height: "initial !important",
  width: "initial !important",
  whiteSpace: "normal",
  overflowWrap: "anywhere",
  wordBreak: "break-word",
});
const GroupInputLabel = styled(WrappingInputLabel)({
  fontWeight: "normal",
});

// Fetches and renders the query, given the fetch options
function ContractSearch({
  refetch,
  queryArgs,
  loadalldocs,
  handleChange,
  relateduuids,
  dateFormat,
}) {
  const { relatedcontracts } = useLazyLoadQuery(
    graphql`
      query RelateInternalDialog_Query(
        $contractUUID: String!
        $SearchParams: String!
        $LoadAll: Boolean!
      ) {
        relatedcontracts(
          ctUuid: $contractUUID
          searchParams: $SearchParams
          loadall: $LoadAll
        ) {
          id
          uuid
          title
          counterparty
          effectiveDate {
            date
          }
        }
      }
    `,
    queryArgs.variables,
    queryArgs.options,
    {
      notifyOnNetworkStatusChange: true,
    },
  );

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        id="relate-search-results"
      >
        {!relatedcontracts?.length ? (
          <p style={{ textAlign: "center", marginTop: "14px" }}>
            No documents found.
          </p>
        ) : (
          relatedcontracts?.map((ct) => (
            <Grid item key={ct.uuid} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    disableRipple
                    id="testchecks"
                    checked={relateduuids.includes(ct.uuid)}
                    onChange={(e) => handleChange(e, ct.uuid)}
                    size="small"
                  />
                }
                label={
                  <GroupInputLabel>
                    {ct.title}
                    {ct.counterparty ? " - ".concat(ct.counterparty) : null}
                    {ct.effectiveDate?.date
                      ? " - ".concat(
                          format_date_string(ct.effectiveDate.date, dateFormat),
                        )
                      : ""}
                  </GroupInputLabel>
                }
              />
            </Grid>
          ))
        )}
      </Grid>
      {!queryArgs.variables.LoadAll ? (
        <Grid item key="kall" xs={12} style={{ textAlign: "center" }}>
          <Button size="small" primary onClick={loadalldocs}>
            Show All
          </Button>
        </Grid>
      ) : (
        ""
      )}
    </>
  );
}

export default function RelateInternalDialog({
  openFor,
  setOpenFor,
  contractUuid,
  dateFormat,
  onCommit,
  ...props
}) {
  const environment = useRelayEnvironment();
  const relateddocs = [openFor || null];
  const [searchparams, setsearchParams] = useState(null);
  const [loadAll, setloadAll] = useState(false);
  const [relateduuids, setRelatedUuids] = useState([]);

  useEffect(
    () =>
      setRelatedUuids(
        relateddocs[0]?.map((c) => c?.internalContract?.uuid) || [],
      ),
    [openFor],
  );

  const [queryArgs, setQueryArgs] = useState({
    options: { fetchKey: 0 },
    variables: {
      contractUUID: contractUuid,
      SearchParams: "",
      LoadAll: false,
    },
  });

  const refetch = useCallback(
    () => {
      // Trigger a re-render of useLazyLoadQuery with new variables,
      // *and* an updated fetchKey.
      // The new fetchKey will ensure that the query is fully
      // re-evaluated and refetched.
      setQueryArgs((prev) => ({
        options: {
          fetchKey: (prev?.fetchKey ?? 0) + 1,
        },
        variables: {
          contractUUID: contractUuid,
          SearchParams: String(searchparams),
          LoadAll: Boolean(loadAll),
        },
      }));
    },
    [
      /* ... */
    ],
  );

  async function handleSave() {
    handleClose();
    try {
      await commitRelatedInternal({
        variables: {
          contractuuid: contractUuid,
          relateddocs: relateduuids,
        },
      });
      track("Contract Linked Document Added");
      commitLocalUpdate(environment, (store) => store.invalidateStore());

      onCommit();
    } catch (e) {
      onCommit(e.message);
    }
  }

  function handleClose() {
    setOpenFor(null);
  }

  function handleChange(event, value) {
    if (!value || value.trim() == "") {
      return;
    }
    if (event.target.checked) {
      setRelatedUuids((relateduuids) => [...relateduuids, value]);
    } else {
      setRelatedUuids(relateduuids.filter((item) => item !== value));
    }
  }

  function searchRelated(getall) {
    setQueryArgs((prev) => ({
      options: {
        fetchKey: (prev?.fetchKey ?? 0) + 1,
      },
      variables: {
        contractUUID: contractUuid,
        SearchParams: String(searchparams),
        LoadAll: getall || false,
      },
    }));
  }

  function load_all_docs() {
    setsearchParams("");
    setloadAll(true);
    searchRelated(true);
  }

  const [commitRelatedInternal] = useAsyncMutation(graphql`
    mutation RelateInternalDialog_updateRelatedMutation(
      $contractuuid: String!
      $relateddocs: [String]
    ) {
      updateRelatedInternalDocs(
        input: { contractUuid: $contractuuid, relateddocs: $relateddocs }
      ) {
        contract {
          ...RelatedDocs_fragment
        }
      }
    }
  `);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      height="983px"
      open={Boolean(openFor)}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Select Related</DialogTitle>

      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={3}
        style={{ paddingLeft: 14 }}
      >
        <Grid item xs={10}>
          <TextField
            name="searchtext"
            id="searchtext"
            value={searchparams || ""}
            onChange={({ target: { value } }) => setsearchParams(value)}
            onKeyDown={(e) => {
              if (e.keyCode == 13) {
                searchRelated();
              }
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="text"
            size="medium"
            label={<SearchIcon />}
            onClick={() => searchRelated()}
          />
        </Grid>
      </Grid>
      <DialogContent style={{ height: "683px" }}>
        <React.Suspense
          fallback={
            <Box style={{ textAlign: "center", width: "100%" }}>
              <CircularProgress style={{ marginTop: "20px" }} />
            </Box>
          }
        >
          <ContractSearch
            refetch={refetch}
            queryArgs={queryArgs}
            loadalldocs={load_all_docs}
            handleChange={handleChange}
            relateduuids={relateduuids}
            dateFormat={dateFormat}
          />
        </React.Suspense>
      </DialogContent>
      <DialogActions>
        <Button size="small" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          size="small"
          disabled={!relateddocs}
          onClick={handleSave}
          primary
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
