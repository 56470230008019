/**
 * @generated SignedSource<<74109c70ce3799ed5daa453ca6821c9f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Notes_notesMutation$variables = {
  notes?: string | null | undefined;
  uuid: string;
};
export type Notes_notesMutation$data = {
  readonly updateContract: {
    readonly contract: {
      readonly id: string;
      readonly notes: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type Notes_notesMutation = {
  response: Notes_notesMutation$data;
  variables: Notes_notesMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "notes"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "uuid"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "notes",
            "variableName": "notes"
          },
          {
            "kind": "Variable",
            "name": "uuid",
            "variableName": "uuid"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "UpdateContractPayload",
    "kind": "LinkedField",
    "name": "updateContract",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ContractType",
        "kind": "LinkedField",
        "name": "contract",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notes",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "Notes_notesMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "Notes_notesMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "cf6a8d89661fad2a3141472819fb8823",
    "id": null,
    "metadata": {},
    "name": "Notes_notesMutation",
    "operationKind": "mutation",
    "text": "mutation Notes_notesMutation(\n  $uuid: String!\n  $notes: String\n) {\n  updateContract(input: {uuid: $uuid, notes: $notes}) {\n    contract {\n      id\n      notes\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0c530ae8b60aea01f6c29aee4e3705b3";

export default node;
