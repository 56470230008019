import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import AddBoxIcon from "@mui/icons-material/AddBox";
import ClearIcon from "@mui/icons-material/Clear";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import Edit from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import MuiInputLabel from "@mui/material/InputLabel";
import { styled } from "@mui/material/styles";
import Button from "~/components/Button";
import { TextField, InputLabel } from "~/components/Field";
import CurrencyField from "~/components/CurrencyField";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Paper from "~/components/Paper";
import VisibleVertScroll from "./VisibleVertScroll";
import { BaseEditableText } from "./EditableText";
import { parseNumber } from "./CurrencyField";

function formatNumber(number) {
  // double equals here to cover undefined as well
  if (number == null) {
    return "0";
  }
  const formatter = new Intl.NumberFormat();

  return formatter.format(number);
}

const GroupBox = ({ children, ...props }) => (
  <Box
    display="flex"
    flexDirection="row"
    justifyContent="flex-start"
    mx={-4}
    {...props}
  >
    {children}
  </Box>
);

const CustomLabel = styled(MuiInputLabel)({
  position: "static",
  left: 0,
  top: 0,

  fontFamily: "Open Sans",
  fontStyle: "normal",
  fontWeight: "bold",
  fontSize: "14px",
  lineHeight: "19px",

  whiteSpace: "nowrap",

  color: "#02162A",

  flex: "none",
  order: 1,
  flexGrow: 0,
  marginTop: 4,
  marginBottom: 0,
  marginLeft: 0,
  marginRight: 0,
  paddingTop: 0,
  paddingBottom: 0,

  overflow: "hidden",
  textOverflow: "ellipsis",
});

function HoverableClearIcon({ handleClick, val }) {
  const [hovered, setHovered] = useState(false);

  return (
    <ClearIcon
      color={hovered ? "error" : "disabled"}
      onClick={() => handleClick(val.sortindex)}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    />
  );
}

const fieldWidth = { xs: 103, sm: 130, md: 190, lg: 248 };

const LineItems = ({
  newName,
  newValue,
  valueList,
  editEnabled,
  shortSymbol,
  setNew,
  editItem,
  removeLineItem,
  modal,
  disabled,
}) => {
  return (
    <Grid container item xs={12} alignItems="center" justifyContent="center">
      <VisibleVertScroll
        maxHeight={modal ? "50vh" : "67px"}
        style={{
          overflowX: modal ? "scroll" : "hidden",
          width: "100%",
        }}
      >
        <Grid container item xs={12}>
          <Grid container item xs={modal ? 11 : 12} spacing={6}>
            <Grid
              container
              item
              xs={modal ? 11 : 12}
              sm={modal ? 7 : 12}
              spacing={2}
            >
              <Grid item xs={modal ? 6 : 7}>
                <InputLabel>Line Items</InputLabel>
              </Grid>
              <Grid item xs={modal ? 6 : 5}>
                <InputLabel>Amount</InputLabel>
              </Grid>
            </Grid>
            {modal && window.innerWidth > 600 && (
              <Grid container item xs={12} sm={5} spacing={2}>
                <Grid item xs={4}>
                  <InputLabel>Date</InputLabel>
                </Grid>
                <Grid item xs={8}>
                  <InputLabel>Author</InputLabel>
                </Grid>
              </Grid>
            )}
          </Grid>
          {modal && (
            <Grid
              container
              item
              xs={12}
              alignItems="flex-start"
              style={{ marginBottom: 16 }}
            >
              <Grid container item xs={11} sm={7}>
                <Grid
                  container
                  item
                  xs={11}
                  spacing={6}
                  style={{ marginRight: 2 }}
                >
                  <Grid container item xs={12} spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        value={newName}
                        placeholder="Line Item"
                        onChange={(e) => setNew.setNewName(e.target.value)}
                        disabled={disabled}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        value={newValue}
                        placeholder={`0`}
                        onChange={({ target: { value } }) => {
                          if (!/^[\d.,]*$/.test(value)) {
                            return;
                          }
                          setNew.setNewValue(value);
                        }}
                        disabled={disabled}
                        onKeyDown={(ev) => {
                          if (ev.keyCode == 13) {
                            setNew.addLineItem(false);
                            ev.preventDefault();
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container item xs={1}>
                  <Button
                    variant="text"
                    size="tiny"
                    onClick={() => {
                      setNew.addLineItem(false);
                    }}
                    disabled={disabled}
                    label={
                      <AddBoxIcon color={disabled ? "disabled" : "primary"} />
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          {valueList
            .filter((val) => val.sortindex != 0)
            .sort((a, b) => (a.sortindex < b.sortindex ? 1 : -1))
            .map((val, i) =>
              editEnabled ? (
                <Grid
                  container
                  item
                  xs={12}
                  key={val.sortindex}
                  style={{ paddingBottom: 8 }}
                >
                  <Grid
                    container
                    item
                    xs={11}
                    style={{ paddingHorizontal: 12 }}
                  >
                    <Grid container item xs={12} sm={7} spacing={2}>
                      <Grid item xs={6}>
                        <BaseEditableText
                          variant="body2"
                          value={val.name}
                          onChange={(value) => {
                            if (val.sortindex) {
                              editItem.editItemName(val.sortindex, value);
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <BaseEditableText
                          variant="body2"
                          value={val.pairedValue}
                          onChange={(value) => {
                            if (val.sortindex) {
                              editItem.editItemValue(val.sortindex, value);
                            }
                          }}
                        />
                      </Grid>
                    </Grid>
                    {modal && (
                      <Grid container item xs={12} sm={5} spacing={2}>
                        <Grid container item xs={4} alignItems="center">
                          <Typography variant="body2">
                            {val.itemDate || "None"}
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          item
                          wrap="wrap"
                          xs={8}
                          alignItems="center"
                        >
                          <Typography variant="body2">
                            {val.author || "None"}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                  <Grid container item xs={1} alignItems="center">
                    <HoverableClearIcon
                      handleClick={removeLineItem}
                      val={val}
                    />
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  key={val.sortindex}
                  container
                  item
                  xs={11}
                  style={{ marginBottom: 8 }}
                >
                  <Grid item xs={7}>
                    <Tooltip title={val.name}>
                      <Typography noWrap variant="body2">
                        {val.name}
                      </Typography>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography variant="body2">
                      {shortSymbol}
                      {formatNumber(
                        (parseFloat(val.pairedValue) || 0).toFixed(2),
                      )}
                    </Typography>
                  </Grid>
                  {modal && (
                    <Box width={fieldWidth} ml={6} my={2}>
                      <Typography variant="body2">
                        {val.itemDate || "None"}
                      </Typography>
                    </Box>
                  )}
                  {modal && (
                    <Box width={fieldWidth} ml={6} my={2}>
                      <Typography variant="body2">
                        {val.author || "None"}
                      </Typography>
                    </Box>
                  )}
                </Grid>
              ),
            )}
        </Grid>
      </VisibleVertScroll>
    </Grid>
  );
};

export default function LedgerField({
  label,
  symbol,
  valueList,
  onChange,
  userEmail,
  disabled,
  displayOnly,
}) {
  const [cookies, setCookie, removeCookie] = useCookies(["currency_selected"]);
  const [editEnabled, setEditEnabled] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [ledgerObject, setLedgerObject] = useState({
    symbol: symbol || cookies.currency_selected,
    valueList: valueList || [],
  });
  const [newName, setNewName] = useState("");
  const [newValue, setNewValue] = useState("");

  useEffect(
    () =>
      setLedgerObject({
        ...ledgerObject,
        symbol: symbol || cookies.currency_selected,
      }),
    [cookies],
  );

  function calcTTD() {
    if (!ledgerObject.valueList) {
      return 0;
    }
    var tmpttd = 0;
    ledgerObject.valueList.forEach((val) => {
      if (val.name != "Contracted Amount:") {
        tmpttd += parseNumber(val.pairedValue) || 0;
      }
    });

    return tmpttd;
  }

  function calcRem() {
    return (parseFloat(getContractedAmount()) || 0) - calcTTD();
  }

  function getContractedAmount() {
    return ledgerObject?.valueList?.filter(
      (x) => x.name == "Contracted Amount:",
    )[0]?.pairedValue;
  }

  function getDate() {
    var today = new Date();
    var datestring =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

    return datestring;
  }

  function editContractedAmount(newAmt) {
    setLedgerObject({
      ...ledgerObject,
      valueList: [
        {
          name: "Contracted Amount:",
          pairedValue: newAmt,
          sortindex: 0,
          author: userEmail,
          itemDate: getDate(),
        },
        ...ledgerObject.valueList.filter(
          (x) => x.name != "Contracted Amount:" && x.sortindex > 0,
        ),
      ],
    });
  }

  function addLineItem(save) {
    if (ledgerObject.valueList.length == 0) {
      var datestring = getDate();
      var newCA = {
        name: "Contracted Amount:",
        pairedValue: "0",
        sortindex: 0,
        author: userEmail,
        itemDate: datestring,
      };
      var newEntry = {
        name: newName,
        pairedValue: newValue,
        sortindex: ledgerObject.valueList.length + 1,
        author: userEmail,
        itemDate: datestring,
      };
      var toAdd = [newCA, newEntry];

      setLedgerObject({
        ...ledgerObject,
        valueList: [...ledgerObject.valueList, ...toAdd],
      });
    } else {
      var datestring = getDate();
      var newEntry = {
        name: newName,
        pairedValue: newValue,
        sortindex: ledgerObject.valueList.length,
        author: userEmail,
        itemDate: datestring,
      };

      setLedgerObject({
        ...ledgerObject,
        valueList: [...ledgerObject.valueList, newEntry],
      });
    }

    if (save) {
      onChange(ledgerObject.symbol, [...ledgerObject.valueList, newEntry]);
    }

    setNewName("");
    setNewValue("");
  }

  function editItemName(si, newName) {
    var i = ledgerObject.valueList.findIndex((item) => item.sortindex == si);

    var newVList = [...ledgerObject.valueList];
    var newItem = {
      ...ledgerObject.valueList[i],
      name: newName,
      author: userEmail,
    };
    newVList[i] = newItem;
    setLedgerObject({ ...ledgerObject, valueList: newVList });
  }

  function editItemValue(si, newValue) {
    var i = ledgerObject.valueList.findIndex((item) => item.sortindex == si);

    const parsedValue = parseNumber(newValue);
    var newVList = [...ledgerObject.valueList];
    var newItem = {
      ...ledgerObject.valueList[i],
      pairedValue: parsedValue,
      author: userEmail,
    };
    newVList[i] = newItem;
    setLedgerObject({ ...ledgerObject, valueList: newVList });
  }

  function removeLineItem(si) {
    var newVList = ledgerObject.valueList.filter(
      (item) => item.sortindex != si,
    );

    setLedgerObject({ ...ledgerObject, valueList: newVList });
  }

  function saveObject() {
    onChange(ledgerObject.symbol, ledgerObject.valueList);
    setEditEnabled(false);
    setModalOpen(false);
  }

  function cancelEdit() {
    setLedgerObject({
      symbol: symbol || cookies.currency_selected,
      valueList: valueList || [],
    });
    setModalOpen(false);
    setEditEnabled(false);
  }

  function handleCloseModal(event, reason) {
    if (reason == "backdropClick") {
      // force the user to explicitly save or cancel
      return;
    }
    setModalOpen(false);
  }

  const shortSymbol = (
    ledgerObject.symbol ||
    cookies.currency_selected ||
    "USD ($)"
  )
    .split("(")[1]
    .slice(0, -1);

  return (
    <>
      <Modal open={modalOpen} onClose={handleCloseModal}>
        <Paper
          style={{
            margin: "auto",
            marginTop: "100px",
            maxWidth: "1200px",
          }}
        >
          <GroupBox mx={0} justifyContent="space-between">
            <Box>{label}</Box>
          </GroupBox>
          <Grid container item xs={12}>
            <Grid container item xs={12} sm={3} style={{ paddingRight: 12 }}>
              {modalOpen ? (
                <CurrencyField
                  label="Contracted Amount"
                  value={getContractedAmount() || ""}
                  symbol={ledgerObject.symbol}
                  onValueChange={editContractedAmount}
                  onCurrencyChange={(symbol) =>
                    setLedgerObject({ ...ledgerObject, symbol })
                  }
                />
              ) : (
                <>
                  <InputLabel>Contracted Amount</InputLabel>
                  <Typography variant="body2">
                    {shortSymbol}
                    {formatNumber(
                      (parseFloat(getContractedAmount()) || 0).toFixed(2),
                    )}
                  </Typography>
                </>
              )}
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={3}
              direction="column-reverse"
              justifyContent="flex-end"
              style={{ marginRight: 24 }}
            >
              <InputLabel> Total To-Date</InputLabel>
              <Typography variant="body2">
                {shortSymbol}
                {formatNumber(calcTTD().toFixed(2))}
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={3}
              direction="column-reverse"
              justifyContent="flex-end"
            >
              <Typography variant="body2">
                {shortSymbol}
                {formatNumber(calcRem().toFixed(2))}
              </Typography>
              <InputLabel> Remainder</InputLabel>
            </Grid>
          </Grid>
          <LineItems
            newName={newName}
            newValue={newValue}
            shortSymbol={shortSymbol}
            valueList={ledgerObject.valueList || []}
            editEnabled={modalOpen}
            setNew={{ setNewName, setNewValue, addLineItem }}
            editItem={{ editItemName, editItemValue }}
            removeLineItem={removeLineItem}
            modal={true}
            disabled={disabled}
          />
          <GroupBox mt={2} justifyContent="flex-end">
            <Box mx={2}>
              <Button variant="outlined" size="small" onClick={cancelEdit}>
                Cancel
              </Button>
            </Box>
            <Box mx={2}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => {
                  if (newName && newValue) {
                    addLineItem(true);
                    setModalOpen(false);
                  } else {
                    saveObject();
                  }
                }}
              >
                Save
              </Button>
            </Box>
          </GroupBox>
        </Paper>
      </Modal>
      <GroupBox mx={0} justifyContent="space-between">
        <Box width={332}>{label}</Box>
        <Box alignSelf="center">
          {displayOnly ? null : (
            <IconButton
              disableRipple
              disableFocusRipple
              onClick={() => setModalOpen(true)}
              size="small"
              disabled={disabled}
            >
              <ZoomOutMapIcon
                style={{ width: 20, height: 20, color: "#000000" }}
              />
            </IconButton>
          )}
        </Box>
      </GroupBox>
      <Grid container item xs={11} wrap="wrap">
        <Grid
          container
          item
          xs={12}
          // sm={5}
          direction="column-reverse"
          justifyContent="flex-end"
        >
          {editEnabled ? (
            <CurrencyField
              label="Contracted Amount"
              value={getContractedAmount()}
              symbol={ledgerObject.symbol}
              onValueChange={editContractedAmount}
              onCurrencyChange={(symbol) =>
                setLedgerObject({ ...ledgerObject, symbol })
              }
            />
          ) : (
            <>
              <CustomLabel> Contracted Amount</CustomLabel>
              <Typography variant="body2">
                {shortSymbol}
                {formatNumber(
                  (parseFloat(getContractedAmount()) || 0).toFixed(2),
                )}
              </Typography>
            </>
          )}
        </Grid>
        <Grid
          container
          item
          xs={12}
          // sm={4}
          direction="column-reverse"
          justifyContent="flex-end"
          style={{ paddingRight: 3 }}
        >
          <CustomLabel>Total To-Date</CustomLabel>
          <Typography variant="body2">
            {shortSymbol}
            {formatNumber(calcTTD().toFixed(2))}
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          // sm={3}
          direction="column-reverse"
          justifyContent="flex-end"
        >
          <CustomLabel>Remainder</CustomLabel>
          <Typography variant="body2">
            {shortSymbol}
            {formatNumber(calcRem().toFixed(2))}
          </Typography>
        </Grid>
      </Grid>
      {editEnabled && (
        <GroupBox mt={2} justifyContent="flex-end">
          <Box mx={2}>
            <Button variant="outlined" size="small" onClick={cancelEdit}>
              Cancel
            </Button>
          </Box>
          <Box mx={2}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={saveObject}
            >
              Save
            </Button>
          </Box>
        </GroupBox>
      )}
    </>
  );
}
