/**
 * @generated SignedSource<<c7c4ca9d1fc8c1c9f5ffc5d334dc6b24>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserCommentRevisionsMenu_contentEditRevisionFragment$data = {
  readonly actor: {
    readonly email: string;
  };
  readonly dateCreated: string;
  readonly uuid: string;
  readonly " $fragmentType": "UserCommentRevisionsMenu_contentEditRevisionFragment";
};
export type UserCommentRevisionsMenu_contentEditRevisionFragment$key = {
  readonly " $data"?: UserCommentRevisionsMenu_contentEditRevisionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserCommentRevisionsMenu_contentEditRevisionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserCommentRevisionsMenu_contentEditRevisionFragment",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": "AccountType",
        "kind": "LinkedField",
        "name": "actor",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      "action": "THROW",
      "path": "actor"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dateCreated",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uuid",
      "storageKey": null
    }
  ],
  "type": "CommentContentEditRevisionType",
  "abstractKey": null
};

(node as any).hash = "a5c1bf7d1ddc81d44c41c6d9ac88d2cf";

export default node;
