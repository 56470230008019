/**
 * @generated SignedSource<<8da0ae328f5a718bdd2113972c0be22d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommentInputType = {
  content: CommentContentInputType;
};
export type CommentContentInputType = {
  htmlContent: string;
  jsonContent: string;
  textContent: string;
};
export type CommentComposer_CreateContractCommentMutation$variables = {
  comment: CommentInputType;
  connections: ReadonlyArray<string>;
  contractUuid: string;
};
export type CommentComposer_CreateContractCommentMutation$data = {
  readonly createComment: {
    readonly __typename: "ContractNotFoundErrorType";
    readonly debugMessage: string | null | undefined;
  } | {
    readonly __typename: "CreateCommentSuccessResultType";
    readonly commentEdge: {
      readonly node: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"UserComment_commentFragment">;
      } | null | undefined;
    };
    readonly notificationErrors: ReadonlyArray<{
      readonly __typename: "CommentNotificationAccountUnauthorizedErrorType";
      readonly debugMessage: string | null | undefined;
    } | {
      readonly __typename: "CommentNotificationUnexpectedErrorType";
      readonly debugMessage: string | null | undefined;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    }> | null | undefined;
  } | {
    readonly __typename: "CreateCommentValidationResultType";
    readonly errors: ReadonlyArray<{
      readonly __typename: "CommentMentionAccountUnauthorizedValidationErrorType";
      readonly accountUnauthorizedEmail: string;
      readonly debugMessage: string | null | undefined;
    } | {
      readonly __typename: "ValidationError";
      readonly debugMessage: string | null | undefined;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    }>;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined;
};
export type CommentComposer_CreateContractCommentMutation = {
  response: CommentComposer_CreateContractCommentMutation$data;
  variables: CommentComposer_CreateContractCommentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "comment"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contractUuid"
},
v3 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "comment",
        "variableName": "comment"
      },
      {
        "kind": "Variable",
        "name": "contractUuid",
        "variableName": "contractUuid"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "debugMessage",
  "storageKey": null
},
v7 = [
  (v6/*: any*/)
],
v8 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "notificationErrors",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": (v7/*: any*/),
      "type": "CommentNotificationAccountUnauthorizedErrorType",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v7/*: any*/),
      "type": "CommentNotificationUnexpectedErrorType",
      "abstractKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "kind": "InlineFragment",
  "selections": (v7/*: any*/),
  "type": "ContractNotFoundErrorType",
  "abstractKey": null
},
v10 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "errors",
      "plural": true,
      "selections": [
        (v4/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            (v6/*: any*/),
            {
              "alias": "accountUnauthorizedEmail",
              "args": null,
              "kind": "ScalarField",
              "name": "mentionedEmail",
              "storageKey": null
            }
          ],
          "type": "CommentMentionAccountUnauthorizedValidationErrorType",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v7/*: any*/),
          "type": "ValidationError",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CreateCommentValidationResultType",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CommentComposer_CreateContractCommentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "createComment",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CommentTypeEdge",
                "kind": "LinkedField",
                "name": "commentEdge",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CommentType",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "UserComment_commentFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "type": "CreateCommentSuccessResultType",
            "abstractKey": null
          },
          (v9/*: any*/),
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "CommentComposer_CreateContractCommentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "createComment",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CommentTypeEdge",
                "kind": "LinkedField",
                "name": "commentEdge",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CommentType",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "uuid",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AccountType",
                        "kind": "LinkedField",
                        "name": "author",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "email",
                            "storageKey": null
                          },
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "dateCreated",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "pinnedAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isAuthor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "htmlContent",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasContentEditRevisions",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "prependEdge",
                "key": "",
                "kind": "LinkedHandle",
                "name": "commentEdge",
                "handleArgs": [
                  {
                    "kind": "Variable",
                    "name": "connections",
                    "variableName": "connections"
                  }
                ]
              },
              (v8/*: any*/)
            ],
            "type": "CreateCommentSuccessResultType",
            "abstractKey": null
          },
          (v9/*: any*/),
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fe7526fa5b05c696ed287d6221b5e2e2",
    "id": null,
    "metadata": {},
    "name": "CommentComposer_CreateContractCommentMutation",
    "operationKind": "mutation",
    "text": "mutation CommentComposer_CreateContractCommentMutation(\n  $contractUuid: String!\n  $comment: CommentInputType!\n) {\n  createComment(input: {contractUuid: $contractUuid, comment: $comment}) {\n    __typename\n    ... on CreateCommentSuccessResultType {\n      commentEdge {\n        node {\n          id\n          ...UserComment_commentFragment\n        }\n      }\n      notificationErrors {\n        __typename\n        ... on CommentNotificationAccountUnauthorizedErrorType {\n          debugMessage\n        }\n        ... on CommentNotificationUnexpectedErrorType {\n          debugMessage\n        }\n      }\n    }\n    ... on ContractNotFoundErrorType {\n      debugMessage\n    }\n    ... on CreateCommentValidationResultType {\n      errors {\n        __typename\n        ... on CommentMentionAccountUnauthorizedValidationErrorType {\n          debugMessage\n          accountUnauthorizedEmail: mentionedEmail\n        }\n        ... on ValidationError {\n          debugMessage\n        }\n      }\n    }\n  }\n}\n\nfragment UserCommentActionsMenu_commentFragment on CommentType {\n  id\n  uuid\n  isAuthor\n  pinnedAt\n  ...useCommentPinnedAt_commentFragment\n}\n\nfragment UserCommentContent_commentFragment on CommentType {\n  id\n  uuid\n  htmlContent\n}\n\nfragment UserComment_commentFragment on CommentType {\n  id\n  uuid\n  author {\n    email\n    id\n  }\n  dateCreated\n  pinnedAt\n  ...UserCommentActionsMenu_commentFragment\n  ...UserCommentContent_commentFragment\n  hasContentEditRevisions\n}\n\nfragment useCommentPinnedAt_commentFragment on CommentType {\n  id\n  uuid\n  isAuthor\n  pinnedAt\n}\n"
  }
};
})();

(node as any).hash = "7ebd31459ccdf5a009aa09c521ab386b";

export default node;
