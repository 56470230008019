/**
 * @generated SignedSource<<232b8933498390dc253b3b83eb030f40>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EditDateDialog_RemoveContractDateMutation$variables = {
  contractUuid: string;
  dateUuid: string;
};
export type EditDateDialog_RemoveContractDateMutation$data = {
  readonly removeContractDate: {
    readonly contract: {
      readonly dates: ReadonlyArray<{
        readonly uuid: string;
      } | null | undefined>;
      readonly uuid: string;
    } | null | undefined;
  } | null | undefined;
};
export type EditDateDialog_RemoveContractDateMutation = {
  response: EditDateDialog_RemoveContractDateMutation$data;
  variables: EditDateDialog_RemoveContractDateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "contractUuid"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "dateUuid"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "contractUuid",
        "variableName": "contractUuid"
      },
      {
        "kind": "Variable",
        "name": "dateUuid",
        "variableName": "dateUuid"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditDateDialog_RemoveContractDateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemoveContractDatePayload",
        "kind": "LinkedField",
        "name": "removeContractDate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractType",
            "kind": "LinkedField",
            "name": "contract",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ContractDateType",
                "kind": "LinkedField",
                "name": "dates",
                "plural": true,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditDateDialog_RemoveContractDateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemoveContractDatePayload",
        "kind": "LinkedField",
        "name": "removeContractDate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractType",
            "kind": "LinkedField",
            "name": "contract",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ContractDateType",
                "kind": "LinkedField",
                "name": "dates",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fce6a50661c0cfbca2f91107e0a505b1",
    "id": null,
    "metadata": {},
    "name": "EditDateDialog_RemoveContractDateMutation",
    "operationKind": "mutation",
    "text": "mutation EditDateDialog_RemoveContractDateMutation(\n  $contractUuid: String!\n  $dateUuid: String!\n) {\n  removeContractDate(input: {contractUuid: $contractUuid, dateUuid: $dateUuid}) {\n    contract {\n      uuid\n      dates {\n        uuid\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "52aa5f0ae5e3b241f3ae75ec95c21807";

export default node;
