/**
 * @generated SignedSource<<799e471cd8cf8606418441ef4606fa24>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FormSelect_formMutation$variables = {
  contractUuid: string;
  form?: string | null | undefined;
};
export type FormSelect_formMutation$data = {
  readonly updateContract: {
    readonly contract: {
      readonly currentFormMissingRequiredFields: boolean | null | undefined;
      readonly form: {
        readonly " $fragmentSpreads": FragmentRefs<"FormSelect_formFragment">;
      } | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"FormSelect_contractFragment">;
    } | null | undefined;
  } | null | undefined;
};
export type FormSelect_formMutation = {
  response: FormSelect_formMutation$data;
  variables: FormSelect_formMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "contractUuid"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "form"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "form",
        "variableName": "form"
      },
      {
        "kind": "Variable",
        "name": "uuid",
        "variableName": "contractUuid"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currentFormMissingRequiredFields",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fieldType",
  "storageKey": null
},
v8 = [
  (v3/*: any*/),
  (v5/*: any*/)
],
v9 = [
  (v3/*: any*/)
],
v10 = {
  "kind": "InlineFragment",
  "selections": (v9/*: any*/),
  "type": "Node",
  "abstractKey": "__isNode"
},
v11 = {
  "kind": "InlineFragment",
  "selections": (v8/*: any*/),
  "type": "FieldSetType",
  "abstractKey": null
},
v12 = [
  (v3/*: any*/),
  (v5/*: any*/),
  (v7/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FormSelect_formMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateContractPayload",
        "kind": "LinkedField",
        "name": "updateContract",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractType",
            "kind": "LinkedField",
            "name": "contract",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "FormType",
                "kind": "LinkedField",
                "name": "form",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "FormSelect_formFragment"
                  }
                ],
                "storageKey": null
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "FormSelect_contractFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FormSelect_formMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateContractPayload",
        "kind": "LinkedField",
        "name": "updateContract",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractType",
            "kind": "LinkedField",
            "name": "contract",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "FormType",
                "kind": "LinkedField",
                "name": "form",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FormSectionType",
                    "kind": "LinkedField",
                    "name": "sections",
                    "plural": true,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "fields",
                        "plural": true,
                        "selections": [
                          (v6/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v3/*: any*/),
                              (v5/*: any*/),
                              (v7/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ValueListItemType",
                                "kind": "LinkedField",
                                "name": "choices",
                                "plural": true,
                                "selections": (v8/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "type": "CustomFieldType",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v3/*: any*/),
                              (v5/*: any*/),
                              (v7/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "choices",
                                "plural": true,
                                "selections": [
                                  (v6/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": (v8/*: any*/),
                                    "type": "StringChoiceType",
                                    "abstractKey": null
                                  },
                                  (v10/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "BuiltinFieldType",
                            "abstractKey": null
                          },
                          (v11/*: any*/),
                          (v10/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "requiredFields",
                    "plural": true,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": (v12/*: any*/),
                        "type": "CustomFieldType",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v12/*: any*/),
                        "type": "BuiltinFieldType",
                        "abstractKey": null
                      },
                      (v11/*: any*/),
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "FormType",
                "kind": "LinkedField",
                "name": "requiredForms",
                "plural": true,
                "selections": (v9/*: any*/),
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "05d377d886fa2fdbdf9917c10e8ac3ed",
    "id": null,
    "metadata": {},
    "name": "FormSelect_formMutation",
    "operationKind": "mutation",
    "text": "mutation FormSelect_formMutation(\n  $contractUuid: String!\n  $form: ID\n) {\n  updateContract(input: {uuid: $contractUuid, form: $form}) {\n    contract {\n      currentFormMissingRequiredFields\n      form {\n        ...FormSelect_formFragment\n        id\n      }\n      ...FormSelect_contractFragment\n      id\n    }\n  }\n}\n\nfragment FormSelect_contractFragment on ContractType {\n  uuid\n  currentFormMissingRequiredFields\n  requiredForms {\n    id\n  }\n}\n\nfragment FormSelect_formFragment on FormType {\n  id\n  uuid\n  name\n  sections {\n    name\n    fields {\n      __typename\n      ... on CustomFieldType {\n        id\n        name\n        fieldType\n        choices {\n          id\n          name\n        }\n      }\n      ... on BuiltinFieldType {\n        id\n        name\n        fieldType\n        choices {\n          __typename\n          ... on StringChoiceType {\n            id\n            name\n          }\n          ... on Node {\n            __isNode: __typename\n            id\n          }\n        }\n      }\n      ... on FieldSetType {\n        id\n        name\n      }\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n    }\n  }\n  requiredFields {\n    __typename\n    ... on CustomFieldType {\n      id\n      name\n      fieldType\n    }\n    ... on BuiltinFieldType {\n      id\n      name\n      fieldType\n    }\n    ... on FieldSetType {\n      id\n      name\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "eb999c8eaad15e0ed50d0339775ed051";

export default node;
