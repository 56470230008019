import * as React from "react";
import { graphql, useFragment, useQueryLoader } from "react-relay";
import PinOutlinedIcon from "@mui/icons-material/PinOutlined";
import format from "date-fns/format";
import { Comment } from "../Comment/Comment";
import { CommentSecondaryHeader } from "../Comment/CommentSecondaryHeader";
import { CommentContent } from "../Comment/CommentContent";
import type { UserComment_commentFragment$key } from "./__generated__/UserComment_commentFragment.graphql";
import type { UserComment_commentEdgeFragment$key } from "./__generated__/UserComment_commentEdgeFragment.graphql";
import { CommentHeader } from "../Comment/CommentHeader";
import { CommentAvatar } from "../Comment/CommentAvatar";
import { UserCommentActionsMenu } from "./UserCommentActionsMenu/UserCommentActionsMenu";
import type { UserComment_currentUserAccountFragment$key } from "./__generated__/UserComment_currentUserAccountFragment.graphql";
import { PinnedCommentIcon } from "./PinnedCommentIcon";
import type { UserAvatarColor } from "../Comment/avatarColors";
import Typography from "@mui/material/Typography";
import { withErrorBoundary } from "@sentry/react";
import Stack from "@mui/material/Stack";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import Button from "@mui/material/Button";
import {
  USER_COMMENT_REVISIONS_DIALOG_COMMENT_QUERY,
  UserCommentRevisionsDialog,
} from "./UserCommentRevisionsDialog";
import { UserCommentContent } from "./UserCommentContent";
import {
  UserCommentRevisionsMenu,
  USER_COMMENT_REVISIONS_MENU_COMMENT_QUERY,
} from "./UserCommentRevisionsMenu";
import type { UserCommentRevisionsMenu_commentQuery } from "./__generated__/UserCommentRevisionsMenu_commentQuery.graphql";
import type { UserCommentRevisionsDialog_commentQuery } from "./__generated__/UserCommentRevisionsDialog_commentQuery.graphql";

const COMMENT_FRAGMENT = graphql`
  fragment UserComment_commentFragment on CommentType {
    id
    uuid
    author @required(action: THROW) {
      email
    }
    dateCreated
    pinnedAt
    ...UserCommentActionsMenu_commentFragment
    ...UserCommentContent_commentFragment
    hasContentEditRevisions
  }
`;

const COMMENT_EDGE_FRAGMENT = graphql`
  fragment UserComment_commentEdgeFragment on CommentTypeEdge {
    node @required(action: THROW) {
      ...UserComment_commentFragment
    }
  }
`;

const CURRENT_USER_ACCOUNT_FRAGMENT = graphql`
  fragment UserComment_currentUserAccountFragment on AccountType {
    uuid
    ...UserCommentActionsMenu_currentUserAccountFragment
    ...UserCommentContent_currentUserAccountFragment
  }
`;

type UserCommentProps = {
  avatarColor: UserAvatarColor;
  commentEdge: UserComment_commentEdgeFragment$key;
  contractId: string;
  currentUser: UserComment_currentUserAccountFragment$key;
  isFocused?: boolean;
  /* Whether the line beneath a comment's avatar shoul be shown or not. */
  showCommentConnectorLine: boolean;
  focusComment: (scrollOptions: ScrollIntoViewOptions) => void;
};

export function UserComment(props: UserCommentProps) {
  const commentEdge = useFragment(COMMENT_EDGE_FRAGMENT, props.commentEdge);
  let comment = useFragment<UserComment_commentFragment$key>(
    COMMENT_FRAGMENT,
    commentEdge.node,
  );
  const currentUser = useFragment(
    CURRENT_USER_ACCOUNT_FRAGMENT,
    props.currentUser,
  );

  const [isEditingContent, setIsEditingContent] = React.useState(false);
  const [selectedRevisionsDialogUuid, setSelectedRevisionsDialogUuid] =
    React.useState<string | null>(null);

  const [
    loadUserCommentRevisionsMenuCommentQueryReference,
    loadUserCommentRevisionsMenuCommentQuery,
    disposeUserCommentRevisionsMenuCommentQuery,
  ] = useQueryLoader<UserCommentRevisionsMenu_commentQuery>(
    USER_COMMENT_REVISIONS_MENU_COMMENT_QUERY,
  );

  const [
    loadUserCommentRevisionsDialogCommentQueryReference,
    loadUserCommentRevisionsDialogCommentQuery,
    disposeUserCommentRevisionsDialogCommentQuery,
  ] = useQueryLoader<UserCommentRevisionsDialog_commentQuery>(
    USER_COMMENT_REVISIONS_DIALOG_COMMENT_QUERY,
  );

  const [revisionsButtonAnchorEl, setRevisionsButtonAnchorEl] =
    React.useState<null | HTMLElement>(null);

  function handleCommentPinnedAtToggleSuccess(action: "PIN" | "UNPIN") {
    if (action === "PIN") {
      props.focusComment({ block: "center", inline: "nearest" });
    }
  }

  function handleRevisionsSelectClick(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) {
    setRevisionsButtonAnchorEl(event.currentTarget);
    loadUserCommentRevisionsMenuCommentQuery(
      { commentUuid: comment.uuid },
      // easiest way to avoid stale data if a user just edited a comment
      { fetchPolicy: "network-only" },
    );
  }

  function handleRevisionClick(revisionUuid: string) {
    setRevisionsButtonAnchorEl(null);
    setSelectedRevisionsDialogUuid(revisionUuid);
    loadUserCommentRevisionsDialogCommentQuery({ commentUuid: comment.uuid });
  }

  return (
    <Comment
      data-testid="user-comment"
      isFocused={props.isFocused}
      showCommentConnectorLine={props.showCommentConnectorLine}
    >
      <CommentAvatar color={props.avatarColor}>
        {comment.author.email[0].toUpperCase()}
      </CommentAvatar>
      <CommentHeader
        startIcons={comment.pinnedAt && <PinnedCommentIcon />}
        endIcons={
          <UserCommentActionsMenu
            comment={comment}
            contractId={props.contractId}
            currentUser={currentUser}
            onEditContentClick={() => setIsEditingContent(true)}
            onCommentPinnedAtToggleSuccess={handleCommentPinnedAtToggleSuccess}
          />
        }
        title={comment.author.email}
      />
      <CommentSecondaryHeader>
        <Stack direction="row" sx={{ mt: -2, alignItems: "center" }}>
          <Typography sx={{ fontWeight: 400 }}>
            {format(new Date(comment.dateCreated), "MMM d, h:mm a")}
          </Typography>
          {comment.hasContentEditRevisions && (
            <>
              <Button
                onClick={handleRevisionsSelectClick}
                endIcon={
                  <ArrowDropDownRoundedIcon
                    fontSize="small"
                    sx={{ ml: -0.5 }}
                  />
                }
                sx={{
                  p: 0.1,
                  color: "heavy",
                  fontWeight: 600,
                  ml: 1,
                  "& svg": {
                    ml: -0.6,
                    width: 18,
                    height: 18,
                  },
                  "& .MuiButton-endIcon": {
                    ml: 0,
                  },
                }}
              >
                EDITED
              </Button>
              {loadUserCommentRevisionsMenuCommentQueryReference && (
                <UserCommentRevisionsMenu
                  anchorEl={revisionsButtonAnchorEl}
                  queryRef={loadUserCommentRevisionsMenuCommentQueryReference}
                  onClose={() => setRevisionsButtonAnchorEl(null)}
                  onRevisionClick={handleRevisionClick}
                />
              )}
              {loadUserCommentRevisionsDialogCommentQueryReference &&
                selectedRevisionsDialogUuid && (
                  <UserCommentRevisionsDialog
                    initialActiveRevisionUuid={selectedRevisionsDialogUuid}
                    open={selectedRevisionsDialogUuid !== null}
                    queryRef={
                      loadUserCommentRevisionsDialogCommentQueryReference
                    }
                    onClose={() => setSelectedRevisionsDialogUuid(null)}
                  />
                )}
            </>
          )}
        </Stack>
      </CommentSecondaryHeader>
      <CommentContent>
        <UserCommentContent
          comment={comment}
          currentUser={currentUser}
          isEditingContent={isEditingContent}
          setIsEditingContent={setIsEditingContent}
        />
      </CommentContent>
    </Comment>
  );
}

export function UserCommentErrorFallback() {
  return (
    <Comment data-testid="user-comment" isFocused={false}>
      <CommentAvatar color={{ backgroundColor: "red", textColor: "white" }}>
        {""}
      </CommentAvatar>
      <CommentHeader
        startIcons={null}
        endIcons={null}
        title={"Error rendering comment"}
      />
      <CommentContent>
        <Typography variant="body2">
          Something went wrong. Please reload reload the page and try again. If
          the problem persists, please contact <a>support@contractsafe.com</a>.
        </Typography>
      </CommentContent>
    </Comment>
  );
}

export const UserCommentWithErrorBoundary = withErrorBoundary(UserComment, {
  fallback: UserCommentErrorFallback,
});

export default UserCommentWithErrorBoundary;
