import { graphql, readInlineData } from "relay-runtime";
import getIntakeCoreFields from "../../IntakeCoreFields";
import { captureException } from "@sentry/react";
import type { fieldHelpers_builtinFieldInlineFragment$key } from "./__generated__/fieldHelpers_builtinFieldInlineFragment.graphql";
import { format, parseISO } from "date-fns";
import type {
  fieldHelpers_contractInlineFragment$key,
  fieldHelpers_contractInlineFragment$data,
} from "./__generated__/fieldHelpers_contractInlineFragment.graphql";
import type {
  fieldHelpers_organizationInlineFragment$key,
  fieldHelpers_organizationInlineFragment$data,
} from "./__generated__/fieldHelpers_organizationInlineFragment.graphql";

export type FieldInfo = ReturnType<typeof getFieldInfo>;

export function getValueProp(field: {
  fieldType: string;
  id: string;
}): "valueDate" | "valueList" | "value" {
  switch (field.fieldType) {
    case "d":
      return "valueDate";
    case "n":
    case "l":
      return "valueList";
    case "m":
      if (field.id.startsWith("builtin:")) {
        return "value";
      }
      return "valueList";
    default:
      return "value";
  }
}

export function getFieldInfo(
  fieldId: string,
  info: {
    builtinFields: ReadonlyArray<fieldHelpers_builtinFieldInlineFragment$key>;
    organization: fieldHelpers_organizationInlineFragment$key;
  },
) {
  const { builtinFieldsInfo, coreFieldsInfo, customFieldsInfo } =
    getAllFieldsInfo(info);
  const allFieldsInfo = [
    ...builtinFieldsInfo,
    ...coreFieldsInfo,
    ...customFieldsInfo,
  ];

  const fieldInfo = allFieldsInfo.find((f) => f.id === fieldId);
  return fieldInfo;
}

export function getAllFieldsInfo(info: {
  builtinFields: ReadonlyArray<fieldHelpers_builtinFieldInlineFragment$key>;
  organization: fieldHelpers_organizationInlineFragment$key;
}) {
  const organization = readInlineData(ORGANIZATION_FRAGMENT, info.organization);

  const builtinFieldsInfo = info.builtinFields?.flatMap((fragRef) => {
    if (!fragRef) {
      return [];
    }

    const data = readInlineData(BUILTIN_FIELD_FRAGMENT, fragRef);
    return {
      ...data,
      valuePropKey: getValueProp(data),
      type: "builtin" as const,
    };
  });

  const customFieldsInfo =
    organization.customFields?.flatMap((fragRef) =>
      fragRef
        ? [
            {
              ...fragRef,
              valuePropKey: getValueProp(fragRef),
              type: "custom" as const,
            },
          ]
        : [],
    ) ?? [];

  const coreFieldsInfo = getIntakeCoreFields(
    organization.orgContractTypes,
    organization.tags,
  ).flatMap((fieldInfo) =>
    fieldInfo
      ? [
          {
            ...fieldInfo,
            valuePropKey: getValueProp(fieldInfo),
            type: "core" as const,
          },
        ]
      : [],
  );

  return {
    builtinFieldsInfo,
    coreFieldsInfo,
    customFieldsInfo,
  };
}

export function dateToOrganizationFormat(
  dateString: string,
  dateFormat: string,
) {
  return format(parseISO(dateString), dateFormat);
}

export function getContractFieldValueById(
  fieldId: string,
  info: {
    contract: fieldHelpers_contractInlineFragment$key;
    builtinFields: ReadonlyArray<fieldHelpers_builtinFieldInlineFragment$key>;
    organization: fieldHelpers_organizationInlineFragment$key;
  },
  defaultValue: any = null,
) {
  const contract = readInlineData(CONTRACT_FRAGMENT, info.contract);
  const organization = readInlineData(ORGANIZATION_FRAGMENT, info.organization);

  const fieldInfo = getFieldInfo(fieldId, {
    builtinFields: info.builtinFields,
    organization: info.organization,
  });

  if (!fieldInfo) {
    captureException("Expected field info to exist.");
    return;
  }

  switch (fieldInfo.type) {
    case "builtin": {
      const value = contract.builtinFieldValues.find(
        (builtInField) => builtInField?.field?.id === fieldId,
      );

      const isMissingValue = !value || !value.value || value.value === "";
      if (isMissingValue) {
        return {
          type: "builtin" as const,
          ...value,
          value: defaultValue,
        };
      }

      return {
        type: "builtin" as const,
        ...value,
      };
    }
    case "custom": {
      const value = contract.customFieldValues.find(
        (customField) => customField?.field?.id === fieldId,
      );

      const isMissingValue =
        !value || !value[fieldInfo.valuePropKey] || value?.value === "";
      if (isMissingValue) {
        return {
          type: "custom" as const,
          ...value,
          [fieldInfo.valuePropKey]: defaultValue,
        };
      }

      return {
        type: "custom" as const,
        ...value,
      };
    }
    case "core": {
      const value = getCoreContractFieldValue(fieldId, {
        contract,
        organization,
      });

      const isMissingValue =
        !value || !value[fieldInfo.valuePropKey] || value?.value === "";
      if (isMissingValue) {
        return {
          type: "core" as const,
          ...value,
          [fieldInfo.valuePropKey]: defaultValue,
        };
      }

      return {
        type: "core" as const,
        ...value,
      };
    }
    default:
      return fieldInfo satisfies never;
  }
}

/**
 * Gets the current value set for a core contract field.
 *
 */
function getCoreContractFieldValue(
  fieldId: string,
  info: {
    contract: fieldHelpers_contractInlineFragment$data;
    organization: fieldHelpers_organizationInlineFragment$data;
  },
) {
  const { contract, organization } = info;
  const coreFieldsInfo = getIntakeCoreFields(
    organization.orgContractTypes,
    organization.tags,
  );

  const coreFieldInfo = coreFieldsInfo.find(
    (fieldInfo) => fieldInfo?.id === fieldId,
  );

  if (!coreFieldInfo) {
    return;
  }

  switch (coreFieldInfo.id) {
    case "core:contractType":
      return {
        valueDate: contract.customContractType?.id || contract.contractType,
      };
    case "core:counterparty": {
      const counterparties = contract.counterparties ?? [];
      const companyName = counterparties[0]?.party?.companyName;
      return {
        value: companyName ?? null,
      };
    }
    case "core:effectiveDate":
      return {
        valueDate: contract.effectiveDate?.date,
      };
    case "core:terminationDate":
      return {
        valueDate: contract.terminationDate?.date,
      };
    case "core:notes":
      return {
        value: contract.notes,
      };
    case "core:mtags":
      return {
        valueList: contract.tags,
      };
    case "core:executionStatus":
      return {
        valueList: [{ id: contract.legal?.executionStatus }],
      };
    case "core:myCompany": {
      const companies = contract.mycompanies ?? [];
      const company = companies[0];
      return {
        value: company?.party?.companyName,
      };
    }
    default:
      return coreFieldInfo satisfies never;
  }
}

export function getIsFieldValueSet(
  fieldValue: {
    value?: any;
    valueDate?: any;
    valueDatePart?: any;
    valueList?: any;
  },
  valuePropKey: "value" | "valueDate" | "valueDatePart" | "valueList",
) {
  return valuePropKey in fieldValue;
}

const BUILTIN_FIELD_FRAGMENT = graphql`
  fragment fieldHelpers_builtinFieldInlineFragment on BuiltinFieldType @inline {
    id
    name
    fieldType
    choices {
      ... on StringChoiceType {
        id
        name
      }
    }
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment fieldHelpers_organizationInlineFragment on OrganizationType @inline {
    dateFormat
    tags {
      id
      name
    }
    orgContractTypes {
      id
      name
      customName
      isActive
    }
    customFields {
      id
      name
      fieldType
      choices {
        ... on ValueListItemType {
          id
          name
        }
      }
    }
  }
`;

const CONTRACT_FRAGMENT = graphql`
  fragment fieldHelpers_contractInlineFragment on ContractType @inline {
    id
    builtinFieldValues {
      field {
        id
      }
      id
      value
      currencySymbol
    }
    contractType
    counterparties {
      uuid
      label
      party {
        uuid
        companyName
      }
    }
    customContractType {
      id
    }
    customFieldValues {
      field {
        id
      }
      id
      value
      valueList {
        id
        name
        pairedValue
        author
        itemDate
        sortindex
      }
      valueDate {
        id
        uuid
        label
        date
        repeat
        repeatEveryNum
        recurSpan
        recurType
        recursUntil
        numberOfOccurences
        enableReminder
        trackCompletion
        protected
        completions(first: 1) {
          edges {
            node {
              completedOn
              completedBy {
                email
                id
              }
              id
              __typename
            }
            cursor
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
        reminder {
          id
          remindOnType
          remindOn
          repeatEvery
          addCustomMessage
          customMessage
          recipients
          isactive
          attachDocument
        }
      }
      currencySymbol
    }
    effectiveDate {
      id
      date
    }
    mycompanies {
      party {
        companyName
      }
    }
    notes
    tags {
      id
    }
    terminationDate {
      id
      date
    }
    legal {
      id
      executionStatus
    }
  }
`;
