import { Set } from "immutable";
import React, { useState, Suspense } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import { Autocomplete } from "~/components/Field";
import DropDownLoader from "~/components/DropDownLoader";
import localObjStore from "~/LocalObjStore";

function BaseFieldValueSetAutocomplete({ fieldId, defaultOptions, ...props }) {
  const {
    fieldValueSet: { values },
  } = useLazyLoadQuery(
    graphql`
      query ValueSetAutocomplete_fieldValueSetQuery($id: String!) {
        fieldValueSet(id: $id) {
          id
          values
        }
      }
    `,
    {
      id: `FieldValueSet:${fieldId}`,
    },
  );

  const options = values || defaultOptions || [];

  return <Autocomplete options={options} {...props} />;
}

export function FieldValueSetAutocomplete({ fieldId, ...props }) {
  const [open, setOpen] = useState(props.open || false);
  const onOpen = () => {
    setOpen(true);
    if (props.onOpen) {
      props.onOpen();
    }
  };
  const onClose = () => {
    setOpen(false);
    if (props.onClose) {
      props.onClose();
    }
  };

  return (
    <Suspense
      fallback={
        <Autocomplete
          loading
          loadingText={<DropDownLoader />}
          {...props}
          open={open}
          onOpen={onOpen}
          onClose={onClose}
          options={[]}
        />
      }
    >
      <BaseFieldValueSetAutocomplete
        fieldId={fieldId}
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        {...props}
      />
    </Suspense>
  );
}

function BaseValueSetAutocomplete({
  valueSetName,
  defaultOptions,
  onChange,
  ...props
}) {
  const cachedValueSet = localObjStore.get(valueSetName);
  const { valueSet } = useLazyLoadQuery(
    graphql`
      query ValueSetAutocomplete_Query($name: String!, $skip: Boolean!) {
        valueSet(name: $name) @skip(if: $skip)
      }
    `,
    { name: valueSetName, skip: !!cachedValueSet },
  );
  const options = valueSet?.length
    ? valueSet
    : cachedValueSet?.length
      ? cachedValueSet
      : defaultOptions;

  function handleChange(e, v) {
    onChange(e, v);
    localObjStore.set(
      valueSetName,
      Set(options).union(v).sort(friendlySort).toArray(),
    );
  }

  return (
    <Autocomplete
      autoSelect
      onChange={handleChange}
      options={options}
      {...props}
    />
  );
}

export default function ValueSetAutocomplete(props) {
  const [open, setOpen] = useState(props.open || false);
  const onOpen = () => {
    setOpen(true);
    if (props.onOpen) {
      props.onOpen();
    }
  };
  const onClose = () => {
    setOpen(false);
    if (props.onClose) {
      props.onClose();
    }
  };

  return (
    <Suspense
      fallback={
        <Autocomplete
          loading
          loadingText={<DropDownLoader />}
          open={open}
          onOpen={onOpen}
          onClose={onClose}
          options={[]}
        />
      }
    >
      <BaseValueSetAutocomplete
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        {...props}
      />
    </Suspense>
  );
}
