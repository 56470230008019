import React, {
  useEffect,
  useState,
  useContext,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useCookies } from "react-cookie";
import { isValid, parseISO, format } from "date-fns";
import {
  graphql,
  useFragment,
  usePreloadedQuery,
  useLazyLoadQuery,
} from "react-relay";
import useAsyncMutation from "~/utils/UseAsyncMutation";
import {
  groupFields,
  getFieldList,
  CONTRACT_FORMS_MAIN_QUERY,
} from "./ContractForms";
import { AUTORENEW_MAP } from "~/utils/terminationDate";
import { v4 as uuid4 } from "uuid";
import ViewContext from "~/ViewContext";

// on first load
//    create annotations for each ai value
//    save annotations on backend
//    if user creates additional annotations, save those as well
// on subsequent loads
//    if pspdfkit remembers those annotations
//        do nothing
//    if pspdfkit forgets those annotations exist (delete manually to test this)
//        recreate those annotations from pspdfInstantJson

const ANNOTATE_TEXTAREA_COLS = "40";
const ANNOTATE_TEXTAREA_ROWS = "5";

const ourToolbar = [
  {
    type: "sidebar-thumbnails",
  },
  {
    type: "layout-config",
  },
  {
    type: "search",
  },
  {
    type: "pager",
  },
  {
    type: "spacer",
  },
  {
    type: "pan",
  },
  {
    type: "zoom-out",
  },
  {
    type: "zoom-in",
  },
  {
    type: "zoom-mode",
  },
  {
    type: "spacer",
  },
  {
    type: "print",
  },
  {
    type: "export-pdf",
  },
];

function stripFragmentProps(x) {
  if (!x) {
    return x;
  }
  const {
    __fragmentOwner,
    __fragments,
    __id,
    __isWithinUnmatchedTypeRefinement,
    ...r
  } = x;
  return r;
}

function createAnnotationJSONHighlight(
  term,
  rect,
  fieldName,
  normalizedText,
  pageIndex,
  fieldId,
) {
  return {
    boundingBox: new PSPDFKit.Geometry.Rect({
      left: rect.left,
      top: rect.top,
      width: rect.width,
      height: rect.height,
    }),
    rects: PSPDFKit.Immutable.List([
      new PSPDFKit.Geometry.Rect({
        left: rect.left,
        top: rect.top,
        width: rect.width,
        height: rect.height,
      }),
    ]),
    color: new PSPDFKit.Color({ r: 148, g: 193, b: 255 }),
    opacity: 1,
    pageIndex: pageIndex,
    customData: {
      text: normalizedText || term,
      fieldId: fieldId,
    },
    subject: fieldName,
  };
}

async function customSearch(key, aiValues, instance) {
  // We would get an error if we called `instance.search` with a term of
  // 2 characters or less.
  if (aiValues[key].text.length <= 2) {
    return PSPDFKit.Immutable.List([]);
  }

  // Let's take the results from the default search as the foundation.
  const results = await instance.search(aiValues[key].text);

  if (results.toJS().length > 0) {
    const { rectsOnPage, pageIndex } = results.toJS().pop();
    return {
      rectsOnPage,
      term: aiValues[key].text,
      fieldName: key,
      fieldId: aiValues[key].fieldid,
      normalizedText: aiValues[key].normalized_text,
      pageIndex,
    };
  }

  return null;
}

const PDFViewer = forwardRef(
  (
    { account, contract, attchuuid, mainQueryRef, openSnackbar, ...props },
    ref,
  ) => {
    const [view] = useContext(ViewContext);
    const { invitedRole, readOnly, organization } = useFragment(
      graphql`
        fragment PDFViewer_accountFragment on AccountType {
          invitedRole
          readOnly
          organization {
            annotationsEnabled
          }
        }
      `,
      account,
    ) || { invitedRole: "None", readOnly: true };

    if (!!mainQueryRef) {
      const { defaultFormSections } = usePreloadedQuery(
        CONTRACT_FORMS_MAIN_QUERY,
        mainQueryRef,
      );
    }

    const {
      uuid,
      filename,
      filenameOcr,
      counterparties,
      mycompanies,
      effectiveDate,
      terminationDate,
      totalValue,
      currencySymbol,
      aiValues,
      form,
      dates,
      builtinFieldValues,
      customFieldValues,
      annotationsJson,
    } = useFragment(
      graphql`
        fragment PDFViewer_fragment on ContractType {
          uuid
          counterparties {
            uuid
            label
            party {
              uuid
              companyName
            }
          }
          mycompanies {
            uuid
            label
            party {
              uuid
              companyName
            }
          }
          effectiveDate {
            id
            uuid
            label
            date
          }
          terminationDate {
            id
            uuid
            label
            date
            renewEvery
            renewDate
          }
          totalValue
          currencySymbol
          form {
            ...FormSelect_formFragment @relay(mask: false)
          }
          dates {
            ...EditDateDialog_dateFragment @relay(mask: false)
          }
          builtinFieldValues {
            field {
              id
            }
            ...ContractForms_fieldValueFragment
          }
          customFieldValues {
            field {
              id
            }
            ...ContractForms_fieldValueFragment
          }
          filename
          filenameOcr
          aiValues
          annotationsJson
        }
      `,
      contract,
    ) || { uuid: "None", filenameOcr: "" };

    const { currencies } = useLazyLoadQuery(graphql`
      query PDFViewer_CurrencyFieldQuery {
        currencies
      }
    `);

    const fieldValueByFieldId = attchuuid
      ? null
      : Object.fromEntries(
          [...builtinFieldValues, ...customFieldValues].map((x) => [
            x.field?.id,
            x,
          ]),
        );

    const [commitParty] = useAsyncMutation(graphql`
      mutation PDFViewer_updateContractPartyMutation(
        $label: String!
        $companyName: String!
        $contractUuid: String!
        $partyUuid: String!
      ) {
        updateContractParty(
          input: {
            contractparty: {
              uuid: $partyUuid
              contractuuid: $contractUuid
              label: $label
              party: { companyName: $companyName }
            }
          }
        ) {
          contract {
            uuid
            counterparties {
              uuid
              party {
                uuid
                companyName
              }
            }
            mycompanies {
              uuid
              label
              party {
                uuid
                companyName
              }
            }
          }
        }
      }
    `);

    const [commitCreateParty] = useAsyncMutation(graphql`
      mutation PDFViewer_createContractPartyMutation(
        $contractUuid: String!
        $label: String!
        $companyName: String!
      ) {
        createContractParty(
          input: {
            contractuuid: $contractUuid
            label: $label
            companyName: $companyName
          }
        ) {
          contract {
            ...Parties_ContractPartiesFragment
          }
        }
      }
    `);

    const [addCommit] = useAsyncMutation(graphql`
      mutation PDFViewer_fieldValueAddMutation(
        $contractUuid: String!
        $fieldId: ID!
        $value: String
        $valueList: [ValueListItemInput]
        $valueDate: Date
        $currencySymbol: String
      ) {
        updateFieldValue(
          input: {
            contractUuid: $contractUuid
            fieldValue: {
              fieldId: $fieldId
              value: $value
              valueList: $valueList
              valueDate: $valueDate
              currencySymbol: $currencySymbol
            }
          }
        ) {
          fieldValue {
            ...ContractForms_fieldValueFragment
          }
          fieldValueSet {
            id
            values
          }
          contract {
            id
            currentFormMissingRequiredFields
            customFieldValues {
              ...ContractForms_fieldValueFragment
            }
          }
        }
      }
    `);

    const [updateCommit] = useAsyncMutation(graphql`
      mutation PDFViewer_fieldValueUpdateMutation(
        $contractUuid: String!
        $fieldId: ID!
        $value: String
        $valueList: [ValueListItemInput]
        $valueDate: Date
        $currencySymbol: String
      ) {
        updateFieldValue(
          input: {
            contractUuid: $contractUuid
            fieldValue: {
              fieldId: $fieldId
              value: $value
              valueList: $valueList
              valueDate: $valueDate
              currencySymbol: $currencySymbol
            }
          }
        ) {
          fieldValue {
            ...ContractForms_fieldValueFragment
          }
          fieldValueSet {
            id
            values
          }
          contract {
            id
            currentFormMissingRequiredFields
          }
        }
      }
    `);

    const [updateDate] = useAsyncMutation(graphql`
      mutation PDFViewer_SetContractDateMutation(
        $contractUuid: String!
        $date: ContractDateInput!
      ) {
        updateContractDate(
          input: { contractUuid: $contractUuid, date: $date }
        ) {
          date {
            ...EditDateDialog_dateFragment
          }
        }
      }
    `);

    const [updateAutoRenew] = useAsyncMutation(graphql`
      mutation PDFViewer_SetTerminationDateMutation(
        $contractUuid: String!
        $date: ContractDateInput!
      ) {
        updateContractDate(
          input: { contractUuid: $contractUuid, date: $date }
        ) {
          date {
            ...TerminationDateAndReminder_ContractDateFragment
          }
          contract {
            id
            currentFormMissingRequiredFields
          }
        }
      }
    `);

    const [updateTotalValue] = useAsyncMutation(graphql`
      mutation PDFViewer_SetContractValueMutation(
        $uuid: String!
        $currencySymbol: String!
        $totalValue: Float
      ) {
        updateContract(
          input: {
            uuid: $uuid
            currencySymbol: $currencySymbol
            totalValue: $totalValue
          }
        ) {
          contract {
            id
            totalValue
            currencySymbol
          }
        }
      }
    `);

    const [updateJsonCommit] = useAsyncMutation(graphql`
      mutation PDFViewer_updateJsonMutation(
        $uuid: String!
        $annotationsJson: String!
      ) {
        updateContract(
          input: { uuid: $uuid, annotationsJson: $annotationsJson }
        ) {
          contract {
            ...PDFViewer_fragment
          }
        }
      }
    `);

    const [cookies, setCookie] = useCookies(["prefers_original"]);
    const [error, setError] = useState(null);
    const [docLoaded, setDocLoaded] = useState(false);
    const hasOcr = !!filenameOcr;
    let initialShouldShowOcr = true;
    if (!hasOcr) {
      initialShouldShowOcr = false;
    } else if (hasOcr && cookies.prefers_original === true) {
      initialShouldShowOcr = false;
    }

    const [ocrDoc, setOcrDoc] = useState(initialShouldShowOcr);
    const [pspdfkitInstance, setPspdfkitInstance] = useState(null);

    useImperativeHandle(ref, () => ({
      getPspdfkitInstance: () => {
        return pspdfkitInstance;
      },
    }));

    const ocr_toggle = {
      type: "custom",
      id: "ocr_toggle",
      title: ocrDoc ? "Searchable" : "Original",
      // tooltip not currently displaying. Perhaps a bug in Nutrient? Leaving it in here in case the functionality is fixed.
      tooltip: ocrDoc ? "Switch to Original" : "Switch to Searchable",
      onPress: (event) => {
        window.PSPDFKit.unload("#pspdfkit");
        const newOcrState = !ocrDoc;
        setOcrDoc(newOcrState);
        setCookie("prefers_original", !newOcrState);
      },
    };

    const popout = {
      type: "custom",
      id: "popout",
      icon: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="black" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather feather-external-link"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg>',
      onPress: () => {
        window.open(
          window.location.pathname + "/document",
          "_blank",
          "location=yes,height=650,width=600,scrollbars=yes,status=yes",
        );
      },
    };

    function calcCanDownload() {
      try {
        if (readOnly) {
          return invitedRole == "rd_only";
        } else {
          return true;
        }
      } catch (e) {
        return true;
      }
    }

    async function handleJsonUpdate(ij) {
      let variables = {
        uuid: contract.uuid,
        annotationsJson: JSON.stringify(ij) || "[{}]",
      };
      try {
        await updateJsonCommit({ variables });
      } catch (e) {
        openSnackbar(e.message);
      }
    }

    async function handleAutoRenewChange(field, change) {
      let variables = {
        contractUuid: contract.uuid,
        date: {
          uuid: field.id,
          autoRenew: true,
          renewEvery: change.renewEvery || "MO",
        },
      };

      if (change.renewDate) {
        variables.date.renewDate = graphqlDate(change.renewDate);
      }

      updateAutoRenew({ variables });
    }

    async function handleChange(field, change, currency, valueList) {
      if (field.type == "party") {
        let party = [...mycompanies, ...counterparties].find(
          (p) => p.uuid == field.id,
        );
        let variables = {};
        if (!party) {
          variables = {
            contractUuid: contract.uuid,
            label: field.label,
            companyName: change,
          };
        } else {
          variables = {
            contractUuid: contract.uuid,
            label: party.label,
            contractparty: party,
            companyName: change,
            partyUuid: party.uuid,
          };
        }
        try {
          if (!party) {
            await commitCreateParty({ variables });
          } else {
            await commitParty({ variables });
          }
          openSnackbar();
        } catch (e) {
          openSnackbar(e.message);
        }
      } else if (field.type == "date") {
        // effective and termination
        // contract specific dates
        // custom date fields
        let variables = {
          contractUuid: contract.uuid,
          date: { uuid: field.id, date: change },
        };
        updateDate({ variables });
      } else if (field.type == "currency") {
        const variables = {
          uuid: contract.uuid,
          totalValue: change,
          currencySymbol: currency,
        };
        await updateTotalValue({ variables });
      } else {
        let variables;
        // builtin dropdown fields don't use a valuelist
        if (field.type == "m" && field.id.startsWith("builtin:")) {
          variables = {
            contractUuid: contract.uuid,
            fieldId: field.id,
            value: valueList[0]?.id,
            valueList: null,
          };
        } else {
          variables = {
            contractUuid: contract.uuid,
            fieldId: field.id,
            value: change,
            valueList: valueList,
          };
        }

        if (field.type == "d") variables.valueDate = change;
        if (field.type == "c") variables.currencySymbol = currency;
        let fieldValue = fieldValueByFieldId[field.id] || {
          value: field.defaultValue,
        };
        if (field.type == "b") {
          openSnackbar();
          return;
        }
        try {
          if (fieldValue?.id) {
            await updateCommit({
              variables,
            });
            openSnackbar();
          } else {
            await addCommit({ variables });
            openSnackbar();
          }
        } catch (e) {
          openSnackbar(e.message);
        }
      }
    }

    useEffect(() => {
      let cancelled = false;

      const loadPDF = async (jwt, sparam) => {
        try {
          window.PSPDFKit.unload("#pspdfkit");
        } catch {
          // nothing to unload
        }

        const items = ourToolbar.filter((item) => {
          if (item.type == "print" || item.type == "export-pdf") {
            return calcCanDownload();
          } else if (item.type == "search") {
            return ocrDoc || !filenameOcr;
          }
          return true;
        });

        if (filenameOcr) {
          items.push(ocr_toggle);
        }

        if (!window.location.href.endsWith("/document")) {
          items.push(popout);
        }

        if (!window.PSPDFKit.Options.DISABLE_KEYBOARD_SHORTCUTS) {
          window.PSPDFKit.Options.DISABLE_KEYBOARD_SHORTCUTS = true;
        }

        let config = {
          container: "#pspdfkit",
          documentId: ocrDoc ? uuid + "-ocr" : uuid,
          authPayload: { jwt },
          instant: false,
          toolbarItems: items,
          editableAnnotationTypes: [],
          styleSheets: ["/static/stylesheets/PSPDFKit.css"],
          serverUrl: pspdfserverurl,
          printOptions: {
            quality: PSPDFKit.PrintQuality.HIGH,
          },
        };

        if (organization.annotationsEnabled) {
          config = {
            ...config,
            annotationTooltipCallback: getAnnotationPopup,
            initialViewState: new PSPDFKit.ViewState({
              enableAnnotationToolbar: false,
            }),
            editableAnnotationTypes: [PSPDFKit.Annotations.HighlightAnnotation],
          };

          const instance = await window.PSPDFKit.load(config);

          const permissions = await instance.getDocumentPermissions();

          setPspdfkitInstance(instance);
          try {
            if (sparam) {
              sparam = sparam;
              instance.startUISearch(sparam);
            }
          } catch (error) {
            console.error(error.message);
          }

          // register text selection and annotation creation/update listeners
          let last_selected = "";
          instance.addEventListener(
            "textSelection.change",
            async (textSelection) => {
              if (
                textSelection &&
                !readOnly &&
                permissions?.annotationsAndForms
              ) {
                last_selected = await textSelection.getText();
                textSelection.getSelectedRectsPerPage().then((rectsPerPage) => {
                  rectsPerPage.map(({ pageIndex, rects }) => {
                    // We need to create one annotation per page.
                    const annotation =
                      new PSPDFKit.Annotations.HighlightAnnotation({
                        id: uuid4(),
                        pageIndex,
                        boundingBox: PSPDFKit.Geometry.Rect.union(rects),
                        rects,
                      });
                    instance.create(annotation);
                  });
                });
                await instance.save();
              }
            },
          );

          instance.addEventListener(
            "annotationSelection.change",
            (annotation) => {
              if (annotation) {
                setTimeout(() => {
                  var mainMenuDiv = instance.contentDocument.querySelector(
                    "#main-menu-" + annotation.id,
                  );
                  if (
                    !!mainMenuDiv &&
                    !mainMenuDiv.classList.contains("hidden")
                  ) {
                    mainMenuDiv.focus();
                  }
                }, 200);
              }
            },
          );

          instance.addEventListener(
            "annotations.create",
            (createdAnnotations) => {
              const updatedAnnotation = createdAnnotations.get(0);
              const currentCustomData = updatedAnnotation.get("customData", {});
              const toUpdate = updatedAnnotation.set("customData", {
                ...currentCustomData,
                text: last_selected,
              });
              last_selected && instance.update(toUpdate);

              setTimeout(
                () => instance.setSelectedAnnotations(updatedAnnotation.id),
                200,
              );
            },
          );

          // useful for debugging
          // instance.addEventListener("annotations.update", (annotation) => {
          //   console.log(annotation.toJS().pop());
          // });

          const sections = form?.sections || defaultFormSections;

          // // determine what fields are available for the user to link to based on what
          // // fields are available on the page and exclude already linked fields.
          let linkableFields = getFieldList(
            sections,
            mycompanies,
            counterparties,
            effectiveDate,
            terminationDate,
            dates,
          );

          let linkedAnnotationNames =
            JSON.parse(annotationsJson) instanceof Array
              ? JSON.parse(annotationsJson).map(
                  (annotation) => annotation.subject,
                )
              : [];

          // should turn this into a map built with one run through
          const dateFields = linkableFields
            .filter((field) => field.type == "d" || field.type == "date")
            .map((field) => field.label);

          const currencyFields = linkableFields
            .filter((field) => field.type == "c" || field.type == "currency")
            .map((field) => field.label);

          const mcFields = linkableFields
            .filter((field) => field.type == "n")
            .map((field) => field.label);

          const ddFields = linkableFields
            .filter((field) => field.type == "m" || field.type == "rt")
            .map((field) => field.label);

          let linkedFields = linkableFields.filter((field) => {
            return linkedAnnotationNames.includes(field.label);
          });
          linkableFields = linkableFields.filter((field) => {
            return !linkedAnnotationNames.includes(field.label);
          });

          // cache of popup DOM elements to reuse the existing ones in case
          // the user is selecting the same annotation again after the first time.
          let popups = {};
          function getAnnotationPopup(annotation) {
            let container;
            if (
              !popups[annotation.id] ||
              popups[annotation.id].children.length === 0
            ) {
              // There's a bug on IE 11 where the cached div can be empty
              // so if it doesn't contain any children we recreate the container
              // as per pspdfkit support's recommendation
              container = document.createElement("div");
              container.className = "annotation-popup";

              // build the DOM tree for ui shown to user when they click an annotation
              // hide and show elements in response to user clicks through registering
              // event listeners

              const xButtonContainer = document.createElement("div");
              xButtonContainer.className = "x-button-container";
              const xButton = document.createElement("div");
              xButton.className = "x-button";
              const xButtonLabel = document.createElement("div");
              xButtonLabel.innerText = "x";
              xButton.appendChild(xButtonLabel);
              xButtonContainer.appendChild(xButton);

              //// BEGIN Container showing two buttons: "Link to Field" and "Annotate"
              const linkToFieldButton = document.createElement("button");
              linkToFieldButton.className = "link-or-annotate-button";
              const linkToFieldButtonLabel = document.createElement("div");
              linkToFieldButtonLabel.innerText = "Link to Field";
              linkToFieldButtonLabel.classList.add("link-or-annotate-label");
              const linkToFieldButtonImg = document.createElement("img");
              linkToFieldButtonImg.src = `/static/Icons/Link.svg`;
              linkToFieldButtonImg.setAttribute("alt", "Expand");
              linkToFieldButton.appendChild(linkToFieldButtonImg);
              linkToFieldButton.appendChild(linkToFieldButtonLabel);

              const annotateButton = document.createElement("button");
              annotateButton.className = "link-or-annotate-button";
              const annotateButtonLabel = document.createElement("div");
              annotateButtonLabel.innerText = "Comment";
              annotateButtonLabel.classList.add("link-or-annotate-label");
              const annotateButtonImg = document.createElement("img");
              annotateButtonImg.src = `/static/Icons/Annotate.svg`;
              annotateButtonImg.setAttribute("alt", "Expand");
              annotateButton.appendChild(annotateButtonImg);
              annotateButton.appendChild(annotateButtonLabel);

              const removeButton = document.createElement("button");
              removeButton.className = "link-or-annotate-button";
              const removeButtonLabel = document.createElement("div");
              removeButtonLabel.innerText = "Remove Highlight";
              removeButtonLabel.classList.add("link-or-annotate-label");
              const removeButtonImg = document.createElement("img");
              removeButtonImg.src = `/static/Icons/Delete.svg`;
              removeButtonImg.setAttribute("alt", "Expand");
              removeButtonImg.className = "cancel-button-img";
              removeButton.appendChild(removeButtonImg);
              removeButton.appendChild(removeButtonLabel);

              const copyButton = document.createElement("button");
              copyButton.className = "link-or-annotate-button";
              const copyButtonLabel = document.createElement("div");
              copyButtonLabel.innerText = "Copy";
              copyButtonLabel.classList.add("link-or-annotate-label");
              const copyButtonImg = document.createElement("img");
              copyButtonImg.src = `/static/Icons/Copy.svg`;
              copyButtonImg.setAttribute("alt", "Expand");
              copyButtonImg.className = "cancel-button-img";
              copyButton.appendChild(copyButtonImg);
              copyButton.appendChild(copyButtonLabel);

              const mainOptions = document.createElement("div");
              const mainMenu = document.createElement("div");
              mainMenu.className = "main-menu";
              mainMenu.setAttribute("tabindex", "0");
              mainMenu.setAttribute("id", "main-menu-" + annotation.id);
              mainOptions.appendChild(linkToFieldButton);
              mainOptions.appendChild(annotateButton);
              mainOptions.appendChild(copyButton);
              mainOptions.appendChild(removeButton);
              mainMenu.appendChild(mainOptions);

              if (readOnly) {
                linkToFieldButton.setAttribute("disabled", "");
                annotateButton.setAttribute("disabled", "");
                removeButton.setAttribute("disabled", "");
                copyButton.setAttribute("disabled", "");
              }
              // mainMenu.appendChild(cancelButton);
              //// END Container showing two buttons: "Link to Field" and "Annotate"

              //// BEGIN "Linking Container", which displays the dialog that a user is
              // is presented with when they choose to "Link to Field"
              // This container starts off hidden and contains:
              //    field select dropdown
              //    data input field
              //    Back button to go back to the previous dialog
              //    Update button to link to field
              const linkingContainer = document.createElement("div");
              linkingContainer.classList.add("linking-container");
              linkingContainer.classList.add("hidden");

              const fieldDropdown = createSelectField(
                linkableFields,
                annotation.subject,
              );
              fieldDropdown.className = "field-dropdown";

              const linkingCustomInput = document.createElement("input");

              const linkingField = linkableFields.find(
                (field) =>
                  field.label == linkableFields[fieldDropdown.value].label,
              );

              const linkingMCInput =
                linkingField?.type == "n"
                  ? createMCField(linkingField.choices, [])
                  : document.createElement("div");
              linkingMCInput.className = "mc-field";
              linkingMCInput.classList.add("hidden");

              const linkingDropdownInput =
                linkingField?.type == "m" || linkingField?.type == "rt"
                  ? createSelectField(linkingField.choices, "")
                  : createSelectField([], "");
              linkingDropdownInput.className = "field";
              linkingDropdownInput.classList.add("hidden");

              const linkingCurrencyDropdown = createSelectField(
                currencies,
                currencySymbol,
              );
              linkingCurrencyDropdown.className = "field";
              linkingCurrencyDropdown.classList.add("hidden");

              if (dateFields.includes(annotation.subject)) {
                linkingCustomInput.setAttribute("type", "date");
              } else if (
                currencyFields.includes(
                  linkableFields[fieldDropdown.value].label,
                )
              ) {
                linkingCustomInput.setAttribute("type", "number");
                linkingCurrencyDropdown.classList.toggle("hidden");
              } else if (
                mcFields.includes(linkableFields[fieldDropdown.value].label)
              ) {
                linkingCustomInput.classList.add("hidden");
                linkingMCInput.classList.remove("hidden");
              } else if (
                ddFields.includes(linkableFields[fieldDropdown.value].label)
              ) {
                // renewal term is a dropdown field, but if SPC is selected, expose a date selector as well
                if (linkingField?.type != "rt") {
                  linkingCustomInput.classList.add("hidden");
                } else {
                  if (
                    linkingField.choices[linkingDropdownInput.value].id != "SPC"
                  ) {
                    linkingCustomInput.classList.add("hidden");
                  }
                }
                linkingDropdownInput.classList.remove("hidden");
              }
              linkingCustomInput.setAttribute(
                "value",
                annotation.customData?.text || "",
              );
              linkingCustomInput.className = "field";

              const actionContainer = document.createElement("div");
              actionContainer.className = "action-container";

              const linkBtn = document.createElement("button");
              linkBtn.innerHTML = "Link";
              linkBtn.className = "update-btn";
              const backBtn = document.createElement("button");
              backBtn.innerHTML = "Back";
              backBtn.className = "back-btn";

              actionContainer.appendChild(backBtn);
              actionContainer.appendChild(linkBtn);

              linkingContainer.appendChild(fieldDropdown);
              linkingContainer.appendChild(linkingDropdownInput);
              linkingContainer.appendChild(linkingCurrencyDropdown);
              linkingContainer.appendChild(linkingMCInput);
              linkingContainer.appendChild(linkingCustomInput);
              linkingContainer.appendChild(actionContainer);
              //// END "Linking Container"

              //// BEGIN Comment Container that will allow users to enter multiline
              // text annotations
              const annotateContainer = document.createElement("div");
              annotateContainer.classList.add("linking-container");
              annotateContainer.classList.add("hidden");

              const annotateHeader = document.createElement("div");
              annotateHeader.className = "link-or-annotate-header";
              const annotateHeaderLabel = document.createElement("div");
              annotateHeaderLabel.innerText = "Comment";
              annotateHeaderLabel.classList.add(
                "link-or-annotate-header-label",
              );
              const annotateHeaderImg = document.createElement("img");
              annotateHeaderImg.src = `/static/Icons/Annotate.svg`;
              annotateHeaderImg.setAttribute("alt", "Expand");
              annotateHeader.appendChild(annotateHeaderImg);
              annotateHeader.appendChild(annotateHeaderLabel);

              const annotateTextarea = document.createElement("textarea");
              annotateTextarea.cols = ANNOTATE_TEXTAREA_COLS;
              annotateTextarea.rows = ANNOTATE_TEXTAREA_ROWS;
              annotateTextarea.className = "annotate-textarea";
              annotateTextarea.value =
                annotation.customData?.annotationText || "";

              const annotateActionContainer = document.createElement("div");
              annotateActionContainer.className = "annotate-action-container";

              const annotateSaveBtn = document.createElement("button");
              annotateSaveBtn.innerHTML = "Save";
              annotateSaveBtn.className = "update-btn";
              const annotateDeleteBtn = document.createElement("button");
              annotateDeleteBtn.innerHTML = "Delete";
              annotateDeleteBtn.className = "unlink-btn";

              annotateActionContainer.appendChild(annotateDeleteBtn);
              annotateActionContainer.appendChild(annotateSaveBtn);

              annotateContainer.appendChild(annotateHeader);
              annotateContainer.appendChild(annotateTextarea);
              annotateContainer.appendChild(annotateActionContainer);

              if (readOnly) {
                annotateTextarea.setAttribute("disabled", "");
                annotateSaveBtn.setAttribute("disabled", "");
                annotateDeleteBtn.setAttribute("disabled", "");
              }
              //// END Annotate Container

              //// BEGIN "Linked Container" the menu that displays when a link has been
              // made with a field. This dialog allows the user to unlink a field or make
              // further data updates
              const linkedContainer = document.createElement("div");
              linkedContainer.classList.add("linking-container");
              linkedContainer.classList.add("hidden");

              if (annotation.subject) {
                linkedContainer.classList.toggle("hidden");
                mainMenu.classList.toggle("hidden");
              }

              if (annotation.customData?.annotationText) {
                annotateContainer.classList.toggle("hidden");
                mainMenu.classList.toggle("hidden");
              }

              // instead of field dropdown. display the field name
              const linkedFieldName = document.createElement("p");
              linkedFieldName.innerHTML =
                annotation.subject || linkableFields[fieldDropdown.value].label;
              linkedFieldName.className = "field-name";

              const linkedCustomInput = document.createElement("input");

              const linkedField = linkedFields.find(
                (field) => field.label == annotation.subject,
              );
              const linkedMCInput =
                linkedField?.type == "n"
                  ? createMCField(
                      linkedField.choices,
                      annotation.customData.valueList,
                    )
                  : document.createElement("div");
              linkedMCInput.className = "mc-field";
              linkedMCInput.classList.add("hidden");

              const linkedDropdownInput =
                linkedField?.type == "m" || linkedField?.type == "rt"
                  ? createSelectField(
                      linkedField.choices,
                      linkedField?.type == "m"
                        ? annotation.customData?.valueList?.length > 0
                          ? annotation.customData.valueList[0].name
                          : ""
                        : AUTORENEW_MAP.find(
                            (art) =>
                              annotation?.customData?.text.toUpperCase() ==
                                art.id || terminationDate.renewEvery == art.id,
                          ).label,
                    )
                  : createSelectField([], "");
              linkedDropdownInput.className = "field";
              linkedDropdownInput.classList.add("hidden");

              const linkedCurrencyDropdown = createSelectField(
                currencies,
                currencySymbol,
              );
              linkedCurrencyDropdown.className = "field";
              linkedCurrencyDropdown.classList.add("hidden");

              linkedCustomInput.className = "field";

              if (dateFields.includes(annotation.subject)) {
                linkedCustomInput.setAttribute("type", "date");
              } else if (currencyFields.includes(annotation.subject)) {
                linkedCustomInput.setAttribute("type", "number");
                linkedCurrencyDropdown.classList.toggle("hidden");
              } else if (mcFields.includes(annotation.subject)) {
                linkedCustomInput.classList.add("hidden");
                linkedMCInput.classList.remove("hidden");
              } else if (ddFields.includes(annotation.subject)) {
                // renewal term is a dropdown field, but if SPC is selected, expose a date selector as well
                if (linkedField?.type != "rt") {
                  linkedCustomInput.classList.add("hidden");
                } else {
                  if (
                    linkedField.choices[linkedDropdownInput.value].id != "SPC"
                  ) {
                    linkedCustomInput.classList.add("hidden");
                  }
                }
                linkedDropdownInput.classList.remove("hidden");
                linkedCustomInput.setAttribute("type", "date");
              }

              linkedCustomInput.setAttribute(
                "value",
                linkedField?.type == "rt"
                  ? annotation.customData?.renewDate ||
                      terminationDate?.renewDate
                  : annotation.customData?.text || "",
              );

              const linkedActionContainer = document.createElement("div");
              linkedActionContainer.className = "action-container";

              const updateBtn = document.createElement("button");
              updateBtn.innerHTML = "Update";
              updateBtn.className = "update-btn";
              updateBtn.setAttribute("disabled", "");
              const unlinkBtn = document.createElement("button");
              unlinkBtn.innerHTML = "Unlink";
              unlinkBtn.className = "unlink-btn";

              linkedActionContainer.appendChild(unlinkBtn);
              linkedActionContainer.appendChild(updateBtn);

              // disable for readonly
              if (readOnly) {
                linkedDropdownInput.setAttribute("disabled", "");
                linkedCurrencyDropdown.setAttribute("disabled", "");
                linkedMCInput.setAttribute("disabled", "");
                linkedCustomInput.setAttribute("disabled", "");
                unlinkBtn.setAttribute("disabled", "");
                updateBtn.setAttribute("disabled", "");
              }

              linkedContainer.appendChild(linkedFieldName);
              linkedContainer.appendChild(linkedDropdownInput);
              linkedContainer.appendChild(linkedCurrencyDropdown);
              linkedContainer.appendChild(linkedMCInput);
              linkedContainer.appendChild(linkedCustomInput);
              linkedContainer.appendChild(linkedActionContainer);
              // END "Linked Container"

              //// Listeners

              // Listeners to enable and disable Update button depending on input

              linkingCustomInput.addEventListener("keydown", (event) => {
                if (
                  currencyFields.includes(
                    linkableFields[fieldDropdown.value].label,
                  ) &&
                  ["e", "E", "+", "-"].includes(event.key)
                ) {
                  event.preventDefault();
                }
              });

              linkedCustomInput.addEventListener("keydown", (event) => {
                if (
                  currencyFields.includes(linkedFieldName.innerHTML) &&
                  ["e", "E", "+", "-"].includes(event.key)
                ) {
                  event.preventDefault();
                }
              });

              linkingCustomInput.addEventListener("input", (event) => {
                let field = linkableFields[fieldDropdown.value];
                if (
                  field.type == "rt" &&
                  field.choices[linkingDropdownInput.value].id == "SPC"
                ) {
                  if (isValid(parseISO(event.target.value))) {
                    linkBtn.removeAttribute("disabled");
                  } else {
                    linkBtn.setAttribute("disabled", "");
                  }
                }
                if (
                  currencyFields.includes(
                    linkableFields[fieldDropdown.value].label,
                  )
                ) {
                  try {
                    parseFloat(event.target.value).toFixed(2);
                    linkBtn.removeAttribute("disabled");
                  } catch (e) {
                    linkBtn.setAttribute("disabled", "");
                  }
                }
              });

              linkingCustomInput.addEventListener("blur", (event) => {
                if (
                  currencyFields.includes(
                    linkableFields[fieldDropdown.value].label,
                  )
                ) {
                  try {
                    event.target.value = parseFloat(event.target.value).toFixed(
                      2,
                    );
                    linkBtn.removeAttribute("disabled");
                  } catch (e) {
                    linkBtn.setAttribute("disabled", "");
                  }
                }
              });

              linkedCustomInput.addEventListener("blur", (event) => {
                if (currencyFields.includes(linkedFieldName.innerHTML)) {
                  try {
                    event.target.value = parseFloat(event.target.value).toFixed(
                      2,
                    );
                    if (event.target.value == annotation.customData.text) {
                      updateBtn.setAttribute("disabled", "");
                    }
                  } catch (e) {
                    updateBtn.setAttribute("disabled", "");
                  }
                }
              });

              linkedCustomInput.addEventListener("input", () => {
                let field = linkedFields.find(
                  (field) => field.label === linkedFieldName.innerHTML,
                );

                // renewal term
                if (
                  field.type == "rt" &&
                  !linkedDropdownInput.classList.contains("hidden")
                ) {
                  // check if changed as always, but also disable update if date is invalid
                  if (
                    isValid(parseISO(linkedCustomInput.value)) &&
                    linkedCustomInput.value != annotation.customData?.renewDate
                  ) {
                    updateBtn.removeAttribute("disabled");
                  } else {
                    updateBtn.setAttribute("disabled", "");
                  }
                  // all other fields
                } else {
                  if (linkedCustomInput.value == annotation.customData.text) {
                    if (
                      currencies[linkedCurrencyDropdown.value] ==
                      annotation.customData.currency
                    ) {
                      updateBtn.setAttribute("disabled", "");
                    }
                  } else {
                    updateBtn.removeAttribute("disabled");
                  }
                }
              });

              linkingDropdownInput.addEventListener("change", (e) => {
                let field = linkableFields[fieldDropdown.value];
                if (field.type == "rt") {
                  if (field.choices[e.target.value].id == "SPC") {
                    linkingCustomInput.classList.remove("hidden");
                    linkingCustomInput.setAttribute("type", "date");
                    if (isValid(parseISO(linkingCustomInput.value))) {
                      linkBtn.removeAttribute("disabled");
                    } else {
                      linkBtn.setAttribute("disabled", "");
                    }
                  } else {
                    linkBtn.removeAttribute("disabled");
                    linkingCustomInput.classList.add("hidden");
                  }
                }
              });

              linkedDropdownInput.addEventListener("change", (e) => {
                let field = linkedFields.find(
                  (field) => field.label === linkedFieldName.innerHTML,
                );
                if (
                  field.type == "m" &&
                  annotation.customData.valueList[0].id ==
                    field.choices[e.target.value].id &&
                  !linkedDropdownInput.classList.contains("hidden")
                ) {
                  updateBtn.setAttribute("disabled", "");
                } else if (
                  field.type == "rt" &&
                  !linkedDropdownInput.classList.contains("hidden")
                ) {
                  // if dropdown switches to SPC, disable update if date is invalid/blank/unchanged
                  if (field.choices[e.target.value].id == "SPC") {
                    linkedCustomInput.classList.remove("hidden");
                    linkedCustomInput.setAttribute("type", "date");
                    if (
                      isValid(parseISO(linkedCustomInput.value)) &&
                      linkedCustomInput.value !=
                        annotation.customData?.renewDate
                    ) {
                      updateBtn.removeAttribute("disabled");
                    } else {
                      updateBtn.setAttribute("disabled", "");
                    }
                  } else {
                    linkedCustomInput.classList.add("hidden");
                    if (
                      annotation.customData.text.toUpperCase() ==
                      field.choices[e.target.value].id
                    ) {
                      updateBtn.setAttribute("disabled", "");
                    } else {
                      updateBtn.removeAttribute("disabled");
                    }
                  }
                } else {
                  updateBtn.removeAttribute("disabled");
                }
              });

              linkedCurrencyDropdown.addEventListener("change", (e) => {
                if (
                  !linkedCurrencyDropdown.classList.contains("hidden") &&
                  currencies[e.target.value] ==
                    annotation.customData.currency &&
                  annotation.customData.text == linkedCustomInput.value
                ) {
                  updateBtn.setAttribute("disabled", "");
                } else {
                  updateBtn.removeAttribute("disabled");
                }
              });

              linkedMCInput.addEventListener("change", (e) => {
                let valueList = Array.from(linkedMCInput.childNodes)
                  .filter((checkboxDiv) => checkboxDiv.childNodes[0].checked)
                  .map((checkboxDiv) => {
                    return { id: checkboxDiv.childNodes[0].id };
                  });
                if (
                  JSON.stringify(annotation.customData.valueList) ==
                  JSON.stringify(valueList)
                ) {
                  updateBtn.setAttribute("disabled", "");
                } else {
                  updateBtn.removeAttribute("disabled");
                }
              });

              // Field select dropdown listener in "Linking Container"
              fieldDropdown.addEventListener("change", (e) => {
                let { type, choices } = linkableFields[e.target.value];
                if (["t", "party", "a", "b"].includes(type)) {
                  linkingCustomInput.setAttribute("type", "text");
                  linkedCustomInput.setAttribute("type", "text");
                } else if (type == "d" || type == "date") {
                  linkingCustomInput.setAttribute("type", "date");
                  linkedCustomInput.setAttribute("type", "date");
                } else if (type == "c" || type == "currency") {
                  linkingCustomInput.setAttribute("type", "number");
                  linkedCustomInput.setAttribute("type", "number");
                }

                if (type == "c" || type == "currency") {
                  linkingCurrencyDropdown.classList.remove("hidden");
                  linkedCurrencyDropdown.classList.remove("hidden");
                } else {
                  linkingCurrencyDropdown.classList.add("hidden");
                  linkedCurrencyDropdown.classList.add("hidden");
                }

                if (type == "n") {
                  linkingCustomInput.classList.add("hidden");
                  linkingMCInput.classList.remove("hidden");
                  updateMCField(linkingMCInput, choices, []);
                  linkingDropdownInput.classList.add("hidden");
                } else if (type == "m") {
                  linkingCustomInput.classList.add("hidden");
                  linkingDropdownInput.classList.remove("hidden");
                  updateSelectField(linkingDropdownInput, choices);
                  linkingMCInput.classList.add("hidden");
                } else {
                  linkingCustomInput.classList.remove("hidden");
                  linkingMCInput.classList.add("hidden");
                  linkingDropdownInput.classList.add("hidden");
                }

                if (type == "rt") {
                  linkingDropdownInput.classList.remove("hidden");
                  linkingCustomInput.classList.add("hidden");
                  updateSelectField(linkingDropdownInput, AUTORENEW_MAP);
                }

                linkedFieldName.innerHTML =
                  annotation.subject ||
                  linkableFields[fieldDropdown.value].label;
              });

              // listener for back button. when clicked, hide the linking container
              // and unhide linkToFieldButton and annotateButton, the "main menu"
              backBtn.addEventListener("click", () => {
                mainMenu.classList.toggle("hidden");
                linkingContainer.classList.toggle("hidden");
              });

              // listener for annotate delete button. when clicked, hide the annotate
              // container and unhide linkToFieldButton and annotateButton, the "main menu"
              annotateDeleteBtn.addEventListener("click", async () => {
                mainMenu.classList.toggle("hidden");
                annotateContainer.classList.toggle("hidden");

                instance.setSelectedAnnotations(null);
                await instance.delete(annotation.id);
                // avoid race condition
                // if someone can free me from this and explain to me why this happens
                // even though we await the update() and save() calls you would be my hero
                setTimeout(async () => {
                  const newIJ = await instance.exportInstantJSON();
                  await handleJsonUpdate(newIJ.annotations);
                }, 250);
              });

              // listener for annotate Save button. on click: sync changes to server.
              // close container
              annotateSaveBtn.addEventListener("click", async () => {
                let toUpdate = annotation.set("customData", {
                  annotationText: annotateTextarea.value,
                });

                await instance.update(toUpdate);
                await instance.save();

                const newIJ = await instance.exportInstantJSON();
                await handleJsonUpdate(newIJ.annotations);

                instance.setSelectedAnnotations(null);
              });

              // listener for Link button. on click: sync changes to server.
              // hide linkingContainer, unhide linkedContainer
              linkBtn.addEventListener("click", async () => {
                let field = linkableFields[fieldDropdown.value];
                let valueList = null;
                if (field.type == "n") {
                  valueList = Array.from(linkingMCInput.childNodes)
                    .filter((checkboxDiv) => checkboxDiv.childNodes[0].checked)
                    .map((checkboxDiv) => {
                      return { id: checkboxDiv.childNodes[0].id };
                    });
                  updateMCField(linkedMCInput, field.choices, valueList);
                  linkedCustomInput.classList.add("hidden");
                  linkedMCInput.classList.remove("hidden");
                } else if (field.type == "m") {
                  valueList = [field.choices[linkingDropdownInput.value]];
                  linkingDropdownInput.classList.add("hidden");
                  updateSelectField(linkedDropdownInput, field.choices);
                  linkedDropdownInput.classList.remove("hidden");
                  linkedCustomInput.classList.add("hidden");
                } else if (field.type == "c" || field.type == "currency") {
                  linkedCurrencyDropdown.classList.remove("hidden");
                  linkedCustomInput.setAttribute("type", "number");
                } else if (field.type == "rt") {
                  linkedDropdownInput.classList.remove("hidden");
                  updateSelectField(linkedDropdownInput, field.choices);
                  if (field.choices[linkingDropdownInput.value].id == "SPC") {
                    linkedCustomInput.setAttribute("type", "date");
                  } else {
                    linkedCustomInput.classList.add("hidden");
                  }
                }

                let toUpdate = annotation.set("customData", {
                  text:
                    field.type == "rt"
                      ? field.choices[
                          linkingDropdownInput.value
                        ].id.toLowerCase()
                      : linkingCustomInput.value,
                  currency:
                    linkingCurrencyDropdown.options[
                      linkingCurrencyDropdown.value
                    ].innerHTML,
                  fieldId:
                    field.type == "rt" ? "autorenew" + field.id : field.id,
                  valueList: valueList,
                  renewDate:
                    field.type == "rt" &&
                    field.choices[linkingDropdownInput.value].id == "SPC"
                      ? linkedCustomInput.value
                      : null,
                });
                linkedCustomInput.value = linkingCustomInput.value;
                linkedCurrencyDropdown.value = linkingCurrencyDropdown.value;
                linkedDropdownInput.value = linkingDropdownInput.value;
                toUpdate = toUpdate.set("subject", field.label);
                await instance.update(toUpdate);
                await instance.save();

                if (field.type == "rt") {
                  let change = {
                    renewEvery: field.choices[linkingDropdownInput.value].id,
                    renewDate:
                      field.choices[linkingDropdownInput.value].id == "SPC"
                        ? parseISO(linkingCustomInput.value)
                        : null,
                  };
                  await handleAutoRenewChange(field, change);
                } else {
                  await handleChange(
                    field,
                    linkingCustomInput.value,
                    linkingCurrencyDropdown.options[
                      linkingCurrencyDropdown.value
                    ].innerHTML,
                    valueList,
                  );
                }

                // there seems to be another race condition when linking to checkbox fields.
                setTimeout(async () => {
                  const newIJ = await instance.exportInstantJSON();
                  await handleJsonUpdate(newIJ.annotations);

                  // update these values so the cached version is updated when changes are made and the
                  // popup is reopened
                  annotation.customData.text = linkedCustomInput.value;
                  annotation.customData.valueList = valueList;
                  annotation.customData.currency =
                    linkedCurrencyDropdown.options[
                      linkedCurrencyDropdown.value
                    ].innerHTML;
                  if (field.type == "rt") {
                    annotation.customData.renewDate =
                      field.choices[linkingDropdownInput.value].id == "SPC"
                        ? linkingCustomInput.value
                        : null;
                  }
                }, 250);

                linkableFields = linkableFields.filter(
                  (linkableField) => linkableField.label !== field.label,
                );
                linkedFields.push(field);
                updateSelectField(fieldDropdown, linkableFields);
                instance.setSelectedAnnotations(null);
                linkingContainer.classList.toggle("hidden");
                linkedContainer.classList.toggle("hidden");
              });

              // listener for Update button. on click: sync changes to server.
              updateBtn.addEventListener("click", async () => {
                let field = linkedFields.find(
                  (field) => field.label === linkedFieldName.innerHTML,
                );
                let valueList = null;
                if (field.type == "n") {
                  valueList = Array.from(linkedMCInput.childNodes)
                    .filter((checkboxDiv) => checkboxDiv.childNodes[0].checked)
                    .map((checkboxDiv) => {
                      return { id: checkboxDiv.childNodes[0].id };
                    });
                  updateMCField(linkedMCInput, field.choices, valueList);
                } else if (field.type == "m") {
                  valueList = [field.choices[linkedDropdownInput.value]];
                  // updateSelectField(linkedDropdownInput, field.choices);
                }
                let toUpdate = annotation.set("customData", {
                  text:
                    field.type == "rt"
                      ? field.choices[
                          linkedDropdownInput.value
                        ].id.toLowerCase()
                      : linkedCustomInput.value,
                  fieldId:
                    field.type == "rt" ? "autorenew" + field.id : field.id,
                  currency:
                    linkedCurrencyDropdown.options[linkedCurrencyDropdown.value]
                      .innerHTML,
                  valueList: valueList,
                  renewDate:
                    field.type == "rt" &&
                    field.choices[linkedDropdownInput.value].id == "SPC"
                      ? linkedCustomInput.value
                      : null,
                });
                toUpdate = toUpdate.set("subject", field.label);
                await instance.update(toUpdate);
                await instance.save();

                if (field.type == "rt") {
                  let change = {
                    renewEvery: field.choices[linkedDropdownInput.value].id,
                    renewDate:
                      field.choices[linkedDropdownInput.value].id == "SPC"
                        ? parseISO(linkedCustomInput.value)
                        : null,
                  };
                  await handleAutoRenewChange(field, change);
                } else {
                  await handleChange(
                    field,
                    linkedCustomInput.value,
                    linkedCurrencyDropdown.options[linkedCurrencyDropdown.value]
                      .innerHTML,
                    valueList,
                  );
                }

                // avoid race condition
                // for some reason it only happened on unlink but I'm adding it here as well
                setTimeout(async () => {
                  const newIJ = await instance.exportInstantJSON();
                  await handleJsonUpdate(newIJ.annotations);
                  updateBtn.setAttribute("disabled", "");

                  // update these values so the cached version is updated when changes are made and the
                  // popup is reopened
                  annotation.customData.text = linkedCustomInput.value;
                  annotation.customData.valueList = valueList;
                  annotation.customData.currency =
                    linkedCurrencyDropdown.options[
                      linkedCurrencyDropdown.value
                    ].innerHTML;
                  if (field.type == "rt") {
                    annotation.customData.renewDate =
                      field.choices[linkedDropdownInput.value].id == "SPC"
                        ? linkedCustomInput.value
                        : null;
                  }
                }, 250);

                instance.setSelectedAnnotations(null);
              });

              // listener to exit the popup
              xButton.addEventListener("click", async () => {
                instance.setSelectedAnnotations(null);
              });

              // listener for the copy button which serves as a pseudo delete button too
              copyButton.addEventListener("click", async () => {
                instance.setSelectedAnnotations(null);
                await instance.delete(annotation.id);
                // avoid race condition
                // if someone can free me from this and explain to me why this happens
                // even though we await the update() and save() calls you would be my hero
                setTimeout(async () => {
                  const newIJ = await instance.exportInstantJSON();
                  await handleJsonUpdate(newIJ.annotations);
                }, 250);

                navigator.clipboard.writeText(annotation.customData.text);
              });

              // listener for the remove button which deletes an unlinked annotation
              removeButton.addEventListener("click", async () => {
                instance.setSelectedAnnotations(null);
                await instance.delete(annotation.id);
                // avoid race condition
                // if someone can free me from this and explain to me why this happens
                // even though we await the update() and save() calls you would be my hero
                setTimeout(async () => {
                  const newIJ = await instance.exportInstantJSON();
                  await handleJsonUpdate(newIJ.annotations);
                }, 250);
              });

              // listener for Unlink button. on click: sync changes to server.
              unlinkBtn.addEventListener("click", async () => {
                let field = linkedFields.find(
                  (field) => field.label === linkedFieldName.innerHTML,
                );
                instance.setSelectedAnnotations(null);
                await instance.delete(annotation.id);
                // avoid race condition
                // if someone can free me from this and explain to me why this happens
                // even though we await the update() and save() calls you would be my hero
                setTimeout(async () => {
                  const newIJ = await instance.exportInstantJSON();
                  await handleJsonUpdate(newIJ.annotations);
                }, 250);

                linkableFields = [...linkableFields, field].sort((a, b) =>
                  a.label > b.label ? 1 : b.label > a.label ? -1 : 0,
                );
                linkedFields = linkedFields.filter(
                  (linkedField) => linkedField?.label !== field?.label,
                );
                updateSelectField(fieldDropdown, linkableFields);
                linkingContainer.classList.toggle("hidden");
                linkedContainer.classList.toggle("hidden");
              });

              linkToFieldButton.addEventListener("click", () => {
                mainMenu.classList.toggle("hidden");
                linkingContainer.classList.toggle("hidden");
              });

              annotateButton.addEventListener("click", () => {
                mainMenu.classList.toggle("hidden");
                annotateContainer.classList.toggle("hidden");
              });

              mainMenu.addEventListener("keydown", async (event) => {
                if (event.key === "Escape") {
                  await instance.delete(annotation.id);
                }
              });
              //// END listeners

              /// append all containers defined about to the parent container
              container.appendChild(xButtonContainer);
              container.appendChild(mainMenu);
              container.appendChild(annotateContainer);
              container.appendChild(linkingContainer);
              container.appendChild(linkedContainer);

              popups[annotation.id] = container;
            }

            container = popups[annotation.id];

            return [
              {
                type: "custom",
                id: "annotation-tooltip",
                className: "annotation-tooltip-container",
                node: container,
              },
            ];
          }

          // Generator of select elements given an array of options.
          // For simple cases you can just specify string values on the array.
          // Otherwise add { label, value } objects as entries.
          function createSelectField(options = [], currentVal) {
            const select = document.createElement("select");

            select.className = "annotation-select";

            const optionsHTML = options
              .filter((option) => !!option)
              .map((option, i) => {
                const optionValue = option.label || option.name || option;
                const areValuesEqual = Array.isArray(optionValue)
                  ? JSON.stringify(optionValue) === JSON.stringify(currentVal)
                  : currentVal === optionValue;

                return `<option value=${i} ${
                  areValuesEqual ? "selected" : ""
                }>${optionValue}</option>`;
              })
              .join("");

            select.innerHTML = optionsHTML;
            return select;
          }

          function updateSelectField(select, options) {
            const optionsHTML = options
              .filter((option) => !!option)
              .map((option, i) => {
                const optionValue = option.label || option.name || option;
                return `<option value=${i} ${
                  i == 0 ? "selected" : ""
                }>${optionValue}</option>`;
              })
              .join("");

            select.innerHTML = optionsHTML;
          }

          function createMCField(options = [], currentVal = []) {
            const mcFieldContainer = document.createElement("div");

            // select.className = "annotation-select";

            const optionsHTML = options
              .map((option, i) => {
                return `<div class='mc-field-choice'><input type='checkbox' ${
                  readOnly ? "disabled" : ""
                } id=${option.id} ${
                  currentVal.map((op) => op.id).includes(option.id)
                    ? "checked"
                    : ""
                }><label for=${option.id}>${option.name}</label></div>`;
              })
              .join("");

            mcFieldContainer.innerHTML = optionsHTML;
            return mcFieldContainer;
          }

          function updateMCField(mcField, options, currentVal) {
            const optionsHTML = options
              .map((option, i) => {
                return `<div class='mc-field-choice'><input type='checkbox' id=${
                  option.id
                } ${
                  currentVal.map((op) => op.id).includes(option.id)
                    ? "checked"
                    : ""
                }><label for=${option.id}>${option.name}</label></div>`;
              })
              .join("");

            mcField.innerHTML = optionsHTML;
          }

          // export current instant json
          const currentIJ = await instance.exportInstantJSON();

          const currentAnnotations = currentIJ.annotations
            ? currentIJ.annotations
                .filter((anno) => anno.type != "pspdfkit/link")
                .map((anno) => {
                  return {
                    id: anno.id,
                    name: anno.name,
                    updatedAt: anno.updatedAt,
                  };
                })
            : [];

          // call to clear annotations in case I need a fresh start.
          // await instance.delete(currentAnnotations.map((anno) => anno.id));

          // if there are no annotations in the viewer currently, 1 of 2 things is true
          // 1. the document is new or does not have ai results
          // 2. pspdfkit forgot about our annotations.
          // option 2 is unlikely to happen, but I don't trust it so I'm going to have a backup in place
          // If option 1: create annotations from ai results if there are any
          // else do nothing and wait for user to create some.
          // If option 2: create annotations basde on previously saved annotations json for that contract
          if (!currentAnnotations?.length) {
            // process accepted ai results and create corresponding highlight annotations
            const aiValsObj = JSON.parse(aiValues);
            const rects = await Promise.all(
              Object.keys(aiValsObj)
                .filter((key) => aiValsObj[key] != "")
                .map(async (key) => {
                  return await customSearch(key, aiValsObj, instance);
                }),
            );

            const newAnnotations = rects
              .filter((rect) => {
                return !!rect;
              })
              .map((rect, i) => {
                return createAnnotationJSONHighlight(
                  rect.term,
                  rect.rectsOnPage[0],
                  rect.fieldName,
                  rect.normalizedText,
                  rect.pageIndex,
                  rect.fieldId,
                );
              });

            const annotations = newAnnotations.map((annotationJSON) => {
              return new PSPDFKit.Annotations.HighlightAnnotation(
                annotationJSON,
              );
            });

            await instance.create(annotations);
            await instance.save();

            // instance.exportInstantJson has proven to be unreliable when called here
            // so building array of annotations manually.
            let annos = [];
            let pageIdx = 0;
            while (pageIdx >= 0) {
              try {
                let annosOnPage = await instance.getAnnotations(pageIdx);
                let toAdd = annosOnPage
                  .toJS()
                  .filter((an) => an.customData !== null);
                annos.push(...toAdd);
                pageIdx = pageIdx + 1;
              } catch {
                pageIdx = -1;
              }
            }

            // recalculate dropdown of available fields after ai results are processed
            let annoSubjs = annos.map((annotation) => annotation.subject);
            linkedFields = linkableFields.filter((field) =>
              annoSubjs.includes(field.label),
            );
            linkableFields = linkableFields.filter(
              (field) => !annoSubjs.includes(field.label),
            );

            // This check was necessary when instance.exportInstantJson was failing regularly.
            // this current method is reliable, but I'm leaving the check in there for extra
            // security
            if (annos.length == annotations.length) {
              await handleJsonUpdate(annos);
            } else {
              console.log(
                "annotations list corrupted, not saving. make a change in viewer to retry",
              );
            }
          }
        } else {
          const instance = await window.PSPDFKit.load(config);

          try {
            if (sparam) {
              sparam = sparam;
              instance.startUISearch(sparam);
            }
          } catch (error) {
            console.error(error.message);
          }
        }
      };

      const loadAttch = async (jwt) => {
        if (!window.PSPDFKit.Options.DISABLE_KEYBOARD_SHORTCUTS) {
          window.PSPDFKit.Options.DISABLE_KEYBOARD_SHORTCUTS = true;
        }
        await window.PSPDFKit.load({
          container: "#pspdfkit",
          documentId: attchuuid + "-attch",
          authPayload: { jwt },
          instant: false,
          editableAnnotationTypes: [],
          styleSheets: ["/static/stylesheets/PSPDFKit.css"],
          serverUrl: pspdfserverurl,
        });
      };

      const getJWT = async () => {
        for (let i = 0; i < 20; i++) {
          if (cancelled) return;

          const searchParam = decodeURIComponent(
            view?.search_params?.search_terms &&
              typeof view?.search_params?.search_terms === "string"
              ? view.search_params.search_terms.replaceAll("%22", "")
              : "",
          );

          const url = attchuuid
            ? `/api/v1/document_access/${attchuuid}?ocr=false&attch=1`
            : `/api/v1/document_access/${uuid}?ocr=${ocrDoc}&sparam=${searchParam}`;

          const response = await fetch(url, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          });
          if (cancelled) return;
          if (response.ok) {
            setDocLoaded(true);
            const resp = await response.json();
            if (!resp.jwt) {
              setError("nofile");
              return;
            }
            if (attchuuid) {
              await loadAttch(resp.jwt);
            } else {
              await loadPDF(resp.jwt, searchParam);
            }
            return;
          }
        }

        setError("error");
      };

      let timeout = 10;
      const eventuallyGetJWT = () => {
        if (window.PSPDFKit) {
          getJWT();
        } else {
          console.log("PSPDKit not ready, will retry in", timeout);
          setTimeout(eventuallyGetJWT, timeout);
          if (timeout < 1000) {
            timeout *= 10;
          }
        }
      };
      eventuallyGetJWT();

      return () => {
        cancelled = true;
      };
    }, [ocrDoc, form, aiValues, contract, view]);

    return (
      <div
        data-testid="contract-viewer"
        data-filename={filename}
        id="pspdfkit"
        style={{
          width: "100%",
          height: "100vh",
          position: "sticky",
          top: "80px",
        }}
      >
        {error == "error" && (
          <div>
            Our robots are working hard to analyze your contract and make it
            fully searchable. It should be viewable here soon. Please try
            refreshing the page in a few minutes and reach out to our support
            team at{" "}
            <span style={{ color: "#0074AB" }}>Support@ContractSafe.com</span>{" "}
            if we can help.
          </div>
        )}
        {error == "nofile" && (
          <div>
            This contract was uploaded without a file. Please reach out to our
            support team at{" "}
            <span style={{ color: "#0074AB" }}>Support@ContractSafe.com</span>{" "}
            if this is an error.
          </div>
        )}
      </div>
    );
  },
);
export default React.memo(PDFViewer);
