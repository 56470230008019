import React, { useEffect } from "react";
import { pspdfkitLicenses } from "~/components/data/pspdfkitLicenses";
import { getCsrfToken } from "~/utils/auth";
type TemplateDocumentPreviewProps = {
  previewDocumentUrl: string;
  onError: (error: unknown) => void;
};

export function TemplateDocumentPreview(props: TemplateDocumentPreviewProps) {
  useEffect(() => {
    loadPDF(props.previewDocumentUrl);

    return () => {};
  }, [props.previewDocumentUrl]);

  const loadPDF = async (previewDocumentUrl: string) => {
    try {
      (window as any).PSPDFKit.unload("#pspdfkit");
    } catch {
      // nothing to unload
    }

    if (!(window as any).PSPDFKit.Options.DISABLE_KEYBOARD_SHORTCUTS) {
      (window as any).PSPDFKit.Options.DISABLE_KEYBOARD_SHORTCUTS = true;
    }

    // @ts-expect-error
    const licenseKey = pspdfkitLicenses[window.location.hostname];
    let config = {
      container: "#pspdfkit-template-document-preview-root",
      document: previewDocumentUrl,
      licenseKey,
      editableAnnotationTypes: [],
      styleSheets: ["/static/stylesheets/PSPDFKit.css"],
      serverUrl: (window as any).pspdfserverurl,
    };
    const instance = await (window as any).PSPDFKit.load(config).then(
      (instance: any) => {
        URL.revokeObjectURL(previewDocumentUrl);
      },
    );
  };

  return (
    <div
      id="pspdfkit-template-document-preview-root"
      data-testid="template-document-preview-root"
      style={{
        width: "100%",
        height: "75vh",
        top: "80px",
      }}
    ></div>
  );
}

export function useTemplateDocumentPreviewMutation() {
  const [isInFlight, setIsInFlight] = React.useState<boolean>(false);

  const handleCreatePreview = async (
    templateId: string,
    fieldValues: {
      [templateVariableId: string]: any;
    },
    handlers: {
      onSuccess?: (url: string) => void;
      onError: (error: any) => void;
      onUnexpectedError?: (error: unknown) => void;
    },
  ) => {
    try {
      setIsInFlight(true);

      const body = {
        templateId,
        fieldValues: Object.entries(fieldValues).map(
          ([fieldId, fieldValue]) => {
            return { fieldId, ...fieldValue };
          },
        ),
      };

      const response = await fetch("/api/v1/template_preview", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": getCsrfToken(),
        },
        body: JSON.stringify(body),
      });

      if (response.ok) {
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        return handlers.onSuccess?.(url);
      } else {
        const { error } = await response.json();
        return handlers.onError(error);
      }
    } catch (error) {
      return handlers.onUnexpectedError?.(error);
    } finally {
      setIsInFlight(false);
    }
  };
  return {
    isCreatePreviewInFlight: isInFlight,
    handleCreatePreview,
  };
}
