/**
 * @generated SignedSource<<7e226d03c32c3d1590238d3950340acf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContractViewerEmptyStateV2_organizationFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AddContractButton_organizationFragment">;
  readonly " $fragmentType": "ContractViewerEmptyStateV2_organizationFragment";
};
export type ContractViewerEmptyStateV2_organizationFragment$key = {
  readonly " $data"?: ContractViewerEmptyStateV2_organizationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContractViewerEmptyStateV2_organizationFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContractViewerEmptyStateV2_organizationFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AddContractButton_organizationFragment"
    }
  ],
  "type": "OrganizationType",
  "abstractKey": null
};

(node as any).hash = "64c74447d4e86cad2f10cde5c1f79771";

export default node;
