/**
 * @generated SignedSource<<f2803e23c82b58f46c06e1a285c8ebeb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AddContractFileViaLinkErrorReasonType = "INVALID_URL" | "RETRIEVE_FILE_FROM_URL_ERROR" | "%future added value";
export type useAddContractFileViaLinkMutation_addContractFileViaLinkMutation$variables = {
  contractUuid: string;
  url: string;
};
export type useAddContractFileViaLinkMutation_addContractFileViaLinkMutation$data = {
  readonly addContractFileViaLink: {
    readonly __typename: "AddContractFileViaLinkErrorType";
    readonly debugMessage: string | null | undefined;
    readonly path: ReadonlyArray<string | null | undefined> | null | undefined;
    readonly reasonCode: AddContractFileViaLinkErrorReasonType;
  } | {
    readonly __typename: "AddContractFileViaLinkSuccessResultType";
    readonly contract: {
      readonly filename: string;
      readonly id: string;
      readonly uuid: string;
    };
  } | {
    readonly __typename: "ContractNotFoundErrorType";
    readonly debugMessage: string | null | undefined;
  } | {
    readonly __typename: "UnauthorizedError";
    readonly debugMessage: string | null | undefined;
    readonly userMessage: string | null | undefined;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type useAddContractFileViaLinkMutation_addContractFileViaLinkMutation = {
  response: useAddContractFileViaLinkMutation_addContractFileViaLinkMutation$data;
  variables: useAddContractFileViaLinkMutation_addContractFileViaLinkMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "contractUuid"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "url"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "debugMessage",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "contractUuid",
            "variableName": "contractUuid"
          },
          {
            "kind": "Variable",
            "name": "url",
            "variableName": "url"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "addContractFileViaLink",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractType",
            "kind": "LinkedField",
            "name": "contract",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "uuid",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "filename",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "type": "AddContractFileViaLinkSuccessResultType",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "path",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "reasonCode",
            "storageKey": null
          }
        ],
        "type": "AddContractFileViaLinkErrorType",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          (v1/*: any*/)
        ],
        "type": "ContractNotFoundErrorType",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "userMessage",
            "storageKey": null
          }
        ],
        "type": "UnauthorizedError",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useAddContractFileViaLinkMutation_addContractFileViaLinkMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useAddContractFileViaLinkMutation_addContractFileViaLinkMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "b2f9b1267fafac12636956e3e042761a",
    "id": null,
    "metadata": {},
    "name": "useAddContractFileViaLinkMutation_addContractFileViaLinkMutation",
    "operationKind": "mutation",
    "text": "mutation useAddContractFileViaLinkMutation_addContractFileViaLinkMutation(\n  $contractUuid: String!\n  $url: String!\n) {\n  addContractFileViaLink(input: {contractUuid: $contractUuid, url: $url}) {\n    __typename\n    ... on AddContractFileViaLinkSuccessResultType {\n      contract {\n        id\n        uuid\n        filename\n      }\n    }\n    ... on AddContractFileViaLinkErrorType {\n      debugMessage\n      path\n      reasonCode\n    }\n    ... on ContractNotFoundErrorType {\n      debugMessage\n    }\n    ... on UnauthorizedError {\n      debugMessage\n      userMessage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "16ec7a89d3725071c57cf95e0134f2c5";

export default node;
