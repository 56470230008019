/**
 * @generated SignedSource<<683aa40ae2c7a839f6a38f2020c43a5d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useCommentPinnedAt_commentFragment$data = {
  readonly id: string;
  readonly isAuthor: boolean;
  readonly pinnedAt: string | null | undefined;
  readonly uuid: string;
  readonly " $fragmentType": "useCommentPinnedAt_commentFragment";
};
export type useCommentPinnedAt_commentFragment$key = {
  readonly " $data"?: useCommentPinnedAt_commentFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useCommentPinnedAt_commentFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useCommentPinnedAt_commentFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uuid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isAuthor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pinnedAt",
      "storageKey": null
    }
  ],
  "type": "CommentType",
  "abstractKey": null
};

(node as any).hash = "bd6e61c574f9de1a2646bdf786bfb23d";

export default node;
