/**
 * @generated SignedSource<<4e93b29171a85ee4985556e5d041d31f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type useDeleteComment_DeleteCommentMutation$variables = {
  commentUuid: string;
  connections: ReadonlyArray<string>;
};
export type useDeleteComment_DeleteCommentMutation$data = {
  readonly deleteComment: {
    readonly __typename: "CommentNotFoundErrorType";
    readonly debugMessage: string | null | undefined;
  } | {
    readonly __typename: "DeleteCommentSuccessResultType";
    readonly commentId: string;
  } | {
    readonly __typename: "UnauthorizedError";
    readonly debugMessage: string | null | undefined;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined;
};
export type useDeleteComment_DeleteCommentMutation = {
  response: useDeleteComment_DeleteCommentMutation$data;
  variables: useDeleteComment_DeleteCommentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "commentUuid"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "connections"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "commentUuid",
        "variableName": "commentUuid"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "commentId",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "debugMessage",
    "storageKey": null
  }
],
v5 = {
  "kind": "InlineFragment",
  "selections": (v4/*: any*/),
  "type": "CommentNotFoundErrorType",
  "abstractKey": null
},
v6 = {
  "kind": "InlineFragment",
  "selections": (v4/*: any*/),
  "type": "UnauthorizedError",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useDeleteComment_DeleteCommentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "deleteComment",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/)
            ],
            "type": "DeleteCommentSuccessResultType",
            "abstractKey": null
          },
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useDeleteComment_DeleteCommentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "deleteComment",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "deleteEdge",
                "key": "",
                "kind": "ScalarHandle",
                "name": "commentId",
                "handleArgs": [
                  {
                    "kind": "Variable",
                    "name": "connections",
                    "variableName": "connections"
                  }
                ]
              }
            ],
            "type": "DeleteCommentSuccessResultType",
            "abstractKey": null
          },
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3266713945adb65f83f086a2533b04d7",
    "id": null,
    "metadata": {},
    "name": "useDeleteComment_DeleteCommentMutation",
    "operationKind": "mutation",
    "text": "mutation useDeleteComment_DeleteCommentMutation(\n  $commentUuid: String!\n) {\n  deleteComment(input: {commentUuid: $commentUuid}) {\n    __typename\n    ... on DeleteCommentSuccessResultType {\n      commentId\n    }\n    ... on CommentNotFoundErrorType {\n      debugMessage\n    }\n    ... on UnauthorizedError {\n      debugMessage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2af515e30f9126614560aeaa00c09c74";

export default node;
