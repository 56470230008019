import React from "react";
import { SvgIcon, type SvgIconProps } from "@mui/material";

export function RelatedDocsIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        stroke="currentColor"
      >
        <path
          d="M22.4778 7H19.2667C18.6989 7 18.1544 6.78929 17.7529 6.41421C17.3515 6.03914 17.1259 5.53043 17.1259 5V2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M22.4778 6V12.5C22.4778 13.3 21.7285 14 20.8722 14H13.3796C12.5233 14 11.774 13.3 11.774 12.5V3.5C11.774 2.7 12.5233 2 13.3796 2H18.1963L22.4778 6Z"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M7.49261 8V16.8C7.49261 17.1 7.70669 17.4 7.92076 17.6C8.13484 17.8 8.45595 18 8.77706 18H16.0556"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M3.21112 12V20.8C3.21112 21.1 3.42519 21.4 3.63927 21.6C3.85334 21.8 4.17445 22 4.49556 22H11.7741"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </svg>
    </SvgIcon>
  );
}
