/**
 * @generated SignedSource<<a1722acf854a8ddaf95f9c4927a0dae9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserCommentRevisionsDialog_commentFragment$data = {
  readonly __typename: string;
  readonly author?: {
    readonly email: string;
    readonly uuid: string;
  };
  readonly contentEditRevisions?: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"UserCommentRevisionsDialog_contentEditRevisionFragment">;
  }> | null | undefined;
  readonly dateCreated?: string;
  readonly debugMessage?: string | null | undefined;
  readonly " $fragmentType": "UserCommentRevisionsDialog_commentFragment";
};
export type UserCommentRevisionsDialog_commentFragment$key = {
  readonly " $data"?: UserCommentRevisionsDialog_commentFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserCommentRevisionsDialog_commentFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserCommentRevisionsDialog_commentFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "kind": "RequiredField",
          "field": {
            "alias": null,
            "args": null,
            "concreteType": "AccountType",
            "kind": "LinkedField",
            "name": "author",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "uuid",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          "action": "THROW",
          "path": "author"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CommentContentEditRevisionType",
          "kind": "LinkedField",
          "name": "contentEditRevisions",
          "plural": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "UserCommentRevisionsDialog_contentEditRevisionFragment"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dateCreated",
          "storageKey": null
        }
      ],
      "type": "CommentType",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "debugMessage",
          "storageKey": null
        }
      ],
      "type": "ResultError",
      "abstractKey": "__isResultError"
    }
  ],
  "type": "CommentResultType",
  "abstractKey": "__isCommentResultType"
};

(node as any).hash = "94c034baef726917f1c7c16b38b12015";

export default node;
