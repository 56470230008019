import { graphql } from "relay-runtime";
import { usePreloadedQuery, type PreloadedQuery } from "react-relay";
import type { ContractPageHeader_contractQuery as TContractPageHeader_contractQuery } from "./__generated__/ContractPageHeader_contractQuery.graphql";
import { ContractTitle, ContractTitleSkeleton } from "./ContractTitle";
import {
  ContractFolderPath,
  ContractFolderPathSkeleton,
} from "./ContractFolderPath";
import { withDelayedSuspenseFallback } from "~/utils/withSuspenseFallback";
import invariant from "~/utils/invariant";
import { Box, Paper, Stack } from "@mui/material";
import { ContractPageHeaderActionsContainerWithSuspenseFallback } from "./ContractPageHeaderActions";
import React from "react";
import { SummaryContainer } from "./Summary";

type ContractPageHeaderProps = {
  contractPageHeaderQueryRef: PreloadedQuery<
    TContractPageHeader_contractQuery,
    {}
  >;
  contractUuid: string;
};

export function ContractPageHeader(props: ContractPageHeaderProps) {
  const queryData = usePreloadedQuery(
    ContractPageHeader_contractQuery,
    props.contractPageHeaderQueryRef,
  );
  invariant(queryData.contract);
  const contract = queryData.contract;

  const [isSummaryOpen, setIsSummaryOpen] = React.useState(false);

  return (
    <Stack
      sx={{
        containerType: "inline-size",
        borderBottom: "1px solid #E1E1E1",
        backgroundColor: "#FFF",
      }}
    >
      <Stack
        direction="row"
        sx={{ justifyContent: "space-between", paddingX: 6, paddingTop: 4 }}
      >
        <ContractFolderPath path={["Sales Agreements", "Oregon"]} />
      </Stack>
      <Stack
        sx={{
          display: "flex",
          "@container (min-width: 700px)": {
            display: "none",
          },
          alignItems: "center",
          paddingX: 6,
        }}
      >
        <ContractTitle title={contract?.title ?? ""} />
        <ContractPageHeaderActionsContainerWithSuspenseFallback
          isSummaryOpen={isSummaryOpen}
          toggleSummary={() => setIsSummaryOpen((value) => !value)}
        />
      </Stack>
      <Box
        sx={[
          {
            containerType: "inline-size",
            justifyContent: "space-between",
            gridTemplateColumns: "minmax(300px, 1fr) auto auto",
            display: "none",
            "@container (min-width: 700px)": {
              display: "grid",
            },
            alignItems: "center",
            paddingX: 6,
          },
        ]}
      >
        <ContractTitle title={contract?.title ?? ""} />
        <ContractPageHeaderActionsContainerWithSuspenseFallback
          isSummaryOpen={isSummaryOpen}
          toggleSummary={() => setIsSummaryOpen((value) => !value)}
        />
      </Box>
      {isSummaryOpen && <SummaryContainer />}
    </Stack>
  );
}

function ContractPageHeaderSkeleton() {
  // TODO: implement
  return <></>;
}

export const ContractPageHeaderWithFallback = withDelayedSuspenseFallback(
  ContractPageHeader,
  <ContractPageHeaderSkeleton />,
);

export const ContractPageHeader_contractQuery = graphql`
  query ContractPageHeader_contractQuery($uuid: String!) {
    contract(uuid: $uuid) {
      title
    }
  }
`;

export type { TContractPageHeader_contractQuery };
