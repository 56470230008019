import React from "react";
import { UpdateContractFromTemplateDialogHeader } from "./UpdateContractFromTemplateDialogHeader";
import { useStepper } from "~/utils/useStepper";
import DialogContent from "@mui/material/DialogContent";
import {
  Box,
  Button,
  DialogActions,
  Grid,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { UpdateContractFromTemplateDialogTemplatesDropdown } from "./UpdateContractFromTemplateDialogTemplatesDropdown";
import { graphql, useFragment, type PreloadedQuery } from "react-relay";
import { TemplateFields } from "~/components/Templates/TemplateFields";
import type { UpdateContractFromTemplateDialogContent_rootQueryFragment$key } from "./__generated__/UpdateContractFromTemplateDialogContent_rootQueryFragment.graphql";
import type { UpdateContractFromTemplateDialogContent_contractFragment$key } from "./__generated__/UpdateContractFromTemplateDialogContent_contractFragment.graphql";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  TemplateDocumentPreview,
  useTemplateDocumentPreviewMutation,
} from "~/components/Templates/TemplateDocumentPreview";
import { useSpinDelay } from "spin-delay";
import PreviewIcon from "@mui/icons-material/Preview";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useUpdateContractFromTemplateMutation } from "./useUpdateContractFromTemplateMutation";
import type { FieldValueInput } from "./__generated__/useUpdateContractFromTemplateMutation_updateContractFromTemplateMutation.graphql";
import _pick from "lodash/pick";
import {
  getIsTemplateVariableRequired,
  getTemplateVariables,
} from "~/components/Templates/templateVariableHelpers";
import { UpdateContractFromTemplateDialogActions } from "./UpdateContractFromTemplateDialogActions";
import { useFieldValues } from "~/components/Templates/useTemplateFieldValues";
import { filterNullish } from "~/utils/array";
import { getFieldInfo } from "~/core/contracts/fieldHelpers";

const STEPS = ["TEMPLATE_SELECT", "FORM", "TEMPLATE_DOCUMENT_PREVIEW"] as const;

type UpdateContractFromTemplateDialogContentProps = {
  mode: "CREATE_FILE" | "REPLACE_FILE";
  contractFragRef: UpdateContractFromTemplateDialogContent_contractFragment$key;
  rootQueryFragRef: UpdateContractFromTemplateDialogContent_rootQueryFragment$key;
  handleClose: () => void;
};

export function UpdateContractFromTemplateDialogContent(
  props: UpdateContractFromTemplateDialogContentProps,
) {
  const [selectedTemplateId, setSelectedTemplateId] = React.useState<
    string | null
  >(null);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const rootData = useFragment(ROOT_QUERY_FRAGMENT, props.rootQueryFragRef);
  const contract = useFragment(CONTRACT_FRAGMENT, props.contractFragRef);
  const builtinFields = filterNullish(rootData.builtinFields ?? []);

  const stepper = useStepper(STEPS);

  const {
    fieldValues,
    getUpdatedFieldValues,
    getIsMissingRequiredFieldValues,
    resetFieldValues,
    initializeFieldValuesForTemplate,
    handleFieldValueChange,
    getHasValueForField,
  } = useFieldValues();

  const [previewDocumentUrl, setPreviewDocumentUrl] = React.useState<
    string | null
  >();

  const { handleCreatePreview, isCreatePreviewInFlight } =
    useTemplateDocumentPreviewMutation();

  const {
    handleUpdateContractFromTemplateMutation,
    isUpdateContractFromTemplateMutationInProgress,
  } = useUpdateContractFromTemplateMutation();

  const isCreatePreviewInProgress = useSpinDelay(isCreatePreviewInFlight, {
    delay: 500,
    minDuration: 200,
  });

  const isUploadInProgress = useSpinDelay(
    isUpdateContractFromTemplateMutationInProgress,
    { delay: 500, minDuration: 200 },
  );

  const handlePreviewButtonClick = () => {
    if (!selectedTemplateId) {
      throw new Error("Expected template to be selected");
    }

    const { documentVariables } = getTemplateVariables(template!);

    let previewFieldValues: Record<string, any> = {};

    documentVariables.forEach((templateVariable) => {
      const value = fieldValues[templateVariable.id];
      if (!value) {
        return;
      }

      previewFieldValues[templateVariable.field.id] = value;
    });

    handleCreatePreview(selectedTemplateId, previewFieldValues, {
      onSuccess: (url) => {
        setPreviewDocumentUrl(url);
        setErrorMessage(null);
        stepper.handleNext();
      },
      // TODO: add error handling
      onError: (error) => {
        console.error("Unexpected error previewing template. error: ", error);
        setErrorMessage(
          "There was an error generating your document preview. Please check your template form and try again. If the problem persists, please contact support@contractsafe.com.",
        );
      },
      onUnexpectedError: (error) => {
        console.error("Unexpected error previewing template. error: ", error);
        setErrorMessage(
          "There was an unexpected error generating your document preview. Please check your template form and try again. If the problem persists, please contact support@contractsafe.com.",
        );
      },
    });
  };

  const resetFormState = () => {
    setSelectedTemplateId(null);
    resetFieldValues();
    setErrorMessage(null);
    stepper.handleReset();
  };

  const handleCancel = () => {
    props.handleClose();
    resetFormState();
  };

  const handleTemplateSelect = (templateId: string) => {
    const template = (organization.templates ?? []).find(
      (template) => template.id === templateId,
    );

    if (!template) {
      throw new Error("Expected template to exist.");
    }

    setSelectedTemplateId(templateId);
    initializeFieldValuesForTemplate({
      contractFragRef: contract,
      templateFragRef: template,
      builtinFieldFragRefs: builtinFields,
      organizationFragRef: organization,
    });
    stepper.handleStepChange("FORM");
  };

  const handleUploadButtonClick = () => {
    try {
      if (!template) {
        throw new Error("Expected template to exist.");
      }

      const updatedFieldValues = getUpdatedFieldValues({
        builtinFields,
        organization,
        template,
      });

      handleUpdateContractFromTemplateMutation(
        {
          contractUuid: contract.uuid,
          templateUuid: template.uuid,
          updatedFieldValues,
        },
        {
          onSuccess: () => {
            props.handleClose();
            resetFormState();
          },
          onResultError: (error) => {
            console.error(
              "Unexpected error uploading contract. error: ",
              error,
            );
            setErrorMessage(
              "There was an unexpected error uploading your contract. Please try again. If the problem persists, please contact support@contractsafe.com.",
            );
          },
          onError: (error) => {
            console.error(
              "Unexpected error uploading contract. error: ",
              error,
            );
            setErrorMessage(
              "There was an unexpected error uploading your contract. Please try again. If the problem persists, please contact support@contractsafe.com.",
            );
          },
        },
      );
    } catch (error) {
      console.error("Unexpected error uploading contract. error: ", error);
      setErrorMessage(
        "There was an unexpected error uploading your contract. Please check your contract form and template then try again. If the problem persists, please contact support@contractsafe.com.",
      );
    }
  };

  const organization = rootData.account.organization;

  const template = (organization.templates ?? []).find(
    (template) => template.id === selectedTemplateId,
  );

  if (stepper.activeStep === "TEMPLATE_SELECT") {
    return (
      <>
        <UpdateContractFromTemplateDialogHeader mode={props.mode} />
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              maxHeight: "300px",
            }}
          >
            <UpdateContractFromTemplateDialogTemplatesDropdown
              organizationFragRef={organization}
              onChange={handleTemplateSelect}
              selectedTemplateId={selectedTemplateId}
            />
          </Box>
        </DialogContent>
      </>
    );
  }

  if (stepper.activeStep === "FORM") {
    if (!template) {
      console.warn(
        "Could not find template. selectedTemplateId: ",
        selectedTemplateId,
      );
      return (
        <Typography variant="body2">An unexpected error occurred.</Typography>
      );
    }

    const isMissingRequiredFieldValues = getIsMissingRequiredFieldValues({
      builtinFields,
      template,
      organization,
    });

    const disablePreviewButton =
      selectedTemplateId === null ||
      isCreatePreviewInFlight ||
      isMissingRequiredFieldValues;

    return (
      <>
        <UpdateContractFromTemplateDialogHeader
          mode={props.mode}
          endComponent={
            <UpdateContractFromTemplateDialogTemplatesDropdown
              organizationFragRef={organization}
              onChange={handleTemplateSelect}
              selectedTemplateId={selectedTemplateId}
            />
          }
        />
        <DialogContent
          sx={{
            paddingX: 8,
            paddingTop: 4,
            height: "100%",
          }}
        >
          <Grid item xs={12}>
            <TemplateFields
              organizationFragRef={organization}
              builtinFieldsFragRef={builtinFields}
              fieldValues={fieldValues}
              templateFragRef={template}
              handleFieldValueChange={handleFieldValueChange}
            />
          </Grid>
        </DialogContent>
        <UpdateContractFromTemplateDialogActions errorMessage={errorMessage}>
          <Button onClick={handleCancel}>Cancel</Button>
          <LoadingButton
            disabled={disablePreviewButton}
            onClick={handlePreviewButtonClick}
            size="medium"
            type="submit"
            loading={isCreatePreviewInProgress}
            loadingPosition="start"
            startIcon={<PreviewIcon />}
            variant="contained"
            data-testid="add-contract-file-from-template-dialog-preview-button"
          >
            Preview Document
          </LoadingButton>
        </UpdateContractFromTemplateDialogActions>
      </>
    );
  }

  if (
    stepper.activeStep === "TEMPLATE_DOCUMENT_PREVIEW" &&
    previewDocumentUrl
  ) {
    return (
      <>
        <UpdateContractFromTemplateDialogHeader
          mode={props.mode}
          endComponent={
            <UpdateContractFromTemplateDialogTemplatesDropdown
              organizationFragRef={organization}
              onChange={handleTemplateSelect}
              selectedTemplateId={selectedTemplateId}
            />
          }
        />
        <DialogContent
          sx={{
            paddingX: 8,
            paddingTop: 4,
            height: "100%",
          }}
        >
          <Grid item xs={12}>
            <TemplateDocumentPreview
              previewDocumentUrl={previewDocumentUrl}
              onError={() =>
                setErrorMessage(
                  "Error previewing document. Please try again later. If the problem persists after reloading the page, please contact support.",
                )
              }
            />
          </Grid>
        </DialogContent>
        <UpdateContractFromTemplateDialogActions errorMessage={errorMessage}>
          <Button onClick={stepper.handleBack}>Back to form</Button>
          <LoadingButton
            onClick={handleUploadButtonClick}
            size="medium"
            type="submit"
            loading={isUploadInProgress}
            loadingPosition="start"
            startIcon={<CloudUploadIcon />}
            variant="contained"
            data-testid="add-contract-file-from-template-dialog-upload-button"
          >
            Submit
          </LoadingButton>
        </UpdateContractFromTemplateDialogActions>
      </>
    );
  }
  return null;
}

type UpdateContractFromTemplateDialogContentSkeletonProps = {
  mode: "CREATE_FILE" | "REPLACE_FILE";
};

export function UpdateContractFromTemplateDialogContentSkeleton(
  props: UpdateContractFromTemplateDialogContentSkeletonProps,
) {
  return (
    <>
      <UpdateContractFromTemplateDialogHeader mode={props.mode} />
      <DialogContent
        sx={{
          paddingX: 8,
          paddingTop: 4,
          height: "100%",
        }}
      >
        <Skeleton
          variant="rectangular"
          width="100%"
          height="100%"
          animation="wave"
        />
      </DialogContent>
      <DialogActions></DialogActions>
    </>
  );
}

const ROOT_QUERY_FRAGMENT = graphql`
  fragment UpdateContractFromTemplateDialogContent_rootQueryFragment on Query {
    account @required(action: THROW) {
      organization @required(action: THROW) {
        templates {
          id
          uuid
          ...TemplateFields_templateFragment
          ...useTemplateFieldValues_templateInlineFragment
          ...templateVariableHelpers_templateFragment
        }

        ...TemplateFields_organizationFragment
        ...UpdateContractFromTemplateDialogTemplatesDropdown_organizationFragment
        ...useTemplateFieldValues_organizationInlineFragment
        ...fieldHelpers_organizationInlineFragment
      }
    }
    builtinFields @required(action: THROW) {
      ...TemplateFields_builtinFieldsFragment
      ...useTemplateFieldValues_builtinFieldInlineFragment
      ...fieldHelpers_builtinFieldInlineFragment
    }
  }
`;

const CONTRACT_FRAGMENT = graphql`
  fragment UpdateContractFromTemplateDialogContent_contractFragment on ContractType {
    uuid
    ...useTemplateFieldValues_contractInlineFragment
  }
`;
