import React from "react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";

export function TerminationDateTooltip(props) {
  const { hasTerminationDatePassed } = props;

  if (!hasTerminationDatePassed) {
    return null;
  }

  return (
    <Tooltip title="Please select a termination date in the future to enable autorenew and reminders.">
      <IconButton size="tiny" color="warning" sx={{ padding: 0 }}>
        <InfoIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
}
