import { useFragment, graphql } from "react-relay";
import { useFeatures, type Features } from "~/features";

import type {
  useContractPageHeaderActionsPermissions_accountFragment$data,
  useContractPageHeaderActionsPermissions_accountFragment$key,
} from "./__generated__/useContractPageHeaderActionsPermissions_accountFragment.graphql";
import type {
  useContractPageHeaderActionsPermissions_organizationFragment$data,
  useContractPageHeaderActionsPermissions_organizationFragment$key,
} from "./__generated__/useContractPageHeaderActionsPermissions_organizationFragment.graphql";
import type {
  useContractPageHeaderActionsPermissions_contractFragment$data,
  useContractPageHeaderActionsPermissions_contractFragment$key,
} from "./__generated__/useContractPageHeaderActionsPermissions_contractFragment.graphql";

export type PermissionResult = {
  isAuthorized: boolean;
  authorizationMessage?: string;
};

export type ContractPageHeaderHeaderActionsPermissions = {
  sendContract: PermissionResult;
  downloadContractFile: PermissionResult;
  downloadContractFileWord: PermissionResult;
  deleteContract: PermissionResult;
  redline: PermissionResult;
  duplicate: PermissionResult;
  archive: PermissionResult;
  replaceContractFile: PermissionResult;
  replaceContractFileViaUrl: PermissionResult;
  uploadContractFile: PermissionResult;
  updateContractFromTemplate: PermissionResult;
};

type PermissionsData = {
  account: useContractPageHeaderActionsPermissions_accountFragment$data;
  organization: useContractPageHeaderActionsPermissions_organizationFragment$data;
  features: Pick<Features, "redlines" | "archive">;
};

export function useContractPageHeaderActionsPermissions(props: {
  accountFragRef: useContractPageHeaderActionsPermissions_accountFragment$key;
  contractFragRef: useContractPageHeaderActionsPermissions_contractFragment$key;
  organizationFragRef: useContractPageHeaderActionsPermissions_organizationFragment$key;
}): ContractPageHeaderHeaderActionsPermissions {
  const account = useFragment(
    useContractPageHeaderActionsPermissions_accountFragment,
    props.accountFragRef,
  );
  const contract = useFragment(
    useContractPageHeaderActionsPermissions_contractFragment,
    props.contractFragRef,
  );

  const organization = useFragment(
    useContractPageHeaderActionsPermissions_organizationFragment,
    props.organizationFragRef,
  );

  const features = useFeatures();

  const permissionsData = {
    account,
    organization,
    features: {
      redlines: features.redlines,
      archive: features.archive,
    },
  };

  return {
    sendContract: getSendContractFeatureState(permissionsData),
    downloadContractFile: getDownloadContractFileFeatureState(permissionsData),
    downloadContractFileWord:
      getDownloadContractFileFeatureState(permissionsData),
    redline: getRedlineFeatureState(permissionsData),
    duplicate: getDuplicateFeatureState(permissionsData),
    deleteContract: getDeleteFeatureState(permissionsData),
    archive: getArchiveFeatureState(permissionsData),
    replaceContractFile: getUploadOrReplaceContractFileFeatureState(
      permissionsData,
      contract,
    ),
    replaceContractFileViaUrl: getUploadOrReplaceContractFileFeatureState(
      permissionsData,
      contract,
    ),
    uploadContractFile: getUploadOrReplaceContractFileFeatureState(
      permissionsData,
      contract,
    ),
    updateContractFromTemplate:
      getUpdateContractFromTemplateFeatureState(permissionsData),
  };
}

function getSendContractFeatureState(
  permissionsData: PermissionsData,
): PermissionResult {
  const { roleInfo } = permissionsData.account;

  if (!roleInfo.readOnly) {
    return { isAuthorized: true };
  }

  return {
    isAuthorized: false,
    authorizationMessage: "You do not have permission to send contracts.",
  };
}

function getDownloadContractFileFeatureState(
  permissionsData: PermissionsData,
): PermissionResult {
  const { roleInfo } = permissionsData.account;

  // read-only, download ok is allowed
  if (!roleInfo.readOnly || roleInfo.invitedRole == "rd_only") {
    return { isAuthorized: true };
  }

  return {
    isAuthorized: false,
    authorizationMessage: "You do not have permission to download contracts.",
  };
}

function getRedlineFeatureState(
  permissionsData: PermissionsData,
): PermissionResult {
  const { features } = permissionsData;
  const { roleInfo } = permissionsData.account;

  if (roleInfo.readOnly) {
    return {
      isAuthorized: false,
      authorizationMessage: "You do not have permission to redline contracts.",
    };
  }

  if (!features.redlines) {
    return {
      isAuthorized: false,
      authorizationMessage: "Upgrade your plan to use this feature.",
    };
  }

  return { isAuthorized: true };
}

function getDuplicateFeatureState(
  permissionsData: PermissionsData,
): PermissionResult {
  const { roleInfo } = permissionsData.account;

  if (roleInfo.readOnly) {
    return {
      isAuthorized: false,
      authorizationMessage:
        "You do not have permission to duplicate contracts.",
    };
  }

  return { isAuthorized: true };
}

function getArchiveFeatureState(
  permissionsData: PermissionsData,
): PermissionResult {
  const { roleInfo } = permissionsData.account;
  const { features } = permissionsData;

  if (roleInfo.readOnly) {
    return {
      isAuthorized: false,
      authorizationMessage: "You do not have permission to archive contracts.",
    };
  }

  if (!features.archive) {
    return {
      isAuthorized: false,
      authorizationMessage: "Upgrade your plan to use this feature.",
    };
  }

  return { isAuthorized: true };
}

function getUploadOrReplaceContractFileFeatureState(
  permissionsData: PermissionsData,
  contract: useContractPageHeaderActionsPermissions_contractFragment$data,
): PermissionResult {
  const { roleInfo } = permissionsData.account;

  if (roleInfo.readOnly) {
    return {
      isAuthorized: false,
      authorizationMessage: "You do not have permission to upload contracts.",
    };
  }

  return { isAuthorized: true };
}

function getDeleteFeatureState(
  permissionsData: PermissionsData,
): PermissionResult {
  const { roleInfo } = permissionsData.account;

  if (!roleInfo.canDelete) {
    return {
      isAuthorized: false,
      authorizationMessage: "You do not have permission to delete contracts.",
    };
  }

  return { isAuthorized: true };
}

function getUpdateContractFromTemplateFeatureState(
  permissionsData: PermissionsData,
): PermissionResult {
  const { roleInfo } = permissionsData.account;
  const { organization } = permissionsData;

  if (roleInfo.readOnly) {
    return {
      isAuthorized: false,
      authorizationMessage: "You do not have permission to use templates.",
    };
  }

  if (!organization.termsTemplatesEnabled) {
    return {
      isAuthorized: false,
      authorizationMessage: "Upgrade your plan to use this feature.",
    };
  }

  if (!organization.termsTemplatesActive) {
    return {
      isAuthorized: false,
      authorizationMessage:
        "Templates must be enabled in your organization's settings to use this feature.",
    };
  }

  return { isAuthorized: true };
}

export const useContractPageHeaderActionsPermissions_accountFragment = graphql`
  fragment useContractPageHeaderActionsPermissions_accountFragment on AccountType
  @argumentDefinitions(contractUuid: { type: "String" }) {
    id
    roleInfo(forcontract: $contractUuid) @required(action: THROW) {
      invitedRole
      canDelete
      isAdmin
      isAcmanager
      readOnly
    }
  }
`;

export const useContractPageHeaderActionsPermissions_contractFragment = graphql`
  fragment useContractPageHeaderActionsPermissions_contractFragment on ContractType {
    id
    filename
    originurl {
      url
    }
  }
`;

export const useContractPageHeaderActionsPermissions_organizationFragment = graphql`
  fragment useContractPageHeaderActionsPermissions_organizationFragment on OrganizationType {
    id
    termsTemplatesActive
    termsTemplatesEnabled
  }
`;
