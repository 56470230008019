import React from "react";
import {
  ConnectionHandler,
  graphql,
  useFragment,
  useMutation,
} from "react-relay";

import Grid from "~/components/GridV2OrV1";
import type { CommentComposer_accountFragment$key } from "./__generated__/CommentComposer_accountFragment.graphql";
import { CURRENT_USER_AVATAR_COLOR } from "./Comment/avatarColors";
import type {
  CommentComposer_CreateContractCommentMutation,
  CommentComposer_CreateContractCommentMutation$data,
} from "./__generated__/CommentComposer_CreateContractCommentMutation.graphql";
import { IconButton, Link, Stack, Tooltip, Typography } from "@mui/material";
import invariant from "~/utils/invariant";
import type { RecordSourceSelectorProxy } from "relay-runtime";
import { resortCommentEdges } from "./relayStoreHelpers";
import {
  CharacterCount,
  CommentTextEditor,
  type CommentTextEditorContent,
  type Editor,
} from "./CommentTextEditor/CommentTextEditor";
import { CommentAvatar } from "./Comment/CommentAvatar";
import Button from "~/components/Button";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { CommentsHelpTooltip } from "./CommentsHelpTooltip";
import { track } from "~/analytics/events";
import { parseMentions } from "./commentValidationHelpers";
import { useSpinDelay } from "spin-delay";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";
import * as Sentry from "@sentry/react";

const ACCOUNT_FRAGMENT = graphql`
  fragment CommentComposer_accountFragment on AccountType {
    uuid
    email
    organization {
      accounts {
        isActive
        uuid
        email
      }
      ...CommentTextEditor_organizationFragment
    }
  }
`;

const CREATE_CONTRACT_COMMENT_MUTATION = graphql`
  mutation CommentComposer_CreateContractCommentMutation(
    $contractUuid: String!
    $comment: CommentInputType!
    $connections: [ID!]!
  ) {
    createComment(input: { contractUuid: $contractUuid, comment: $comment }) {
      __typename
      ... on CreateCommentSuccessResultType {
        commentEdge @prependEdge(connections: $connections) {
          node {
            id
            ...UserComment_commentFragment
          }
        }
        notificationErrors {
          __typename
          ... on CommentNotificationAccountUnauthorizedErrorType {
            debugMessage
          }
          ... on CommentNotificationUnexpectedErrorType {
            debugMessage
          }
        }
      }
      ... on ContractNotFoundErrorType {
        debugMessage
      }
      ... on CreateCommentValidationResultType {
        errors {
          __typename
          ... on CommentMentionAccountUnauthorizedValidationErrorType {
            debugMessage
            accountUnauthorizedEmail: mentionedEmail
          }
          ... on ValidationError {
            debugMessage
          }
        }
      }
    }
  }
`;

export type CommentComposerProps = {
  currentUserFragRef: CommentComposer_accountFragment$key;
  contractId: string;
  contractUuid: string;
  onCommentCreateSuccess?: (commentNodeId: string) => void;
};

export function isOfTypename<
  Union extends { __typename: string },
  SpecificType extends Union["__typename"],
>(val: SpecificType) {
  return (obj: Union): obj is Extract<Union, { type: SpecificType }> =>
    obj.__typename === val;
}

export function CommentComposer(props: CommentComposerProps) {
  const currentUser = useFragment(ACCOUNT_FRAGMENT, props.currentUserFragRef);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [warningMessage, setWarningMessage] = React.useState<string | null>(
    null,
  );

  // TODO: switch this to useAsyncMutation once it's typed
  const [
    commitCreateContractCommentMutation,
    isCreateContractCommentMutationInFlight,
  ] = useMutation<CommentComposer_CreateContractCommentMutation>(
    CREATE_CONTRACT_COMMENT_MUTATION,
  );

  const isCreateCommentInProgress = useSpinDelay(
    isCreateContractCommentMutationInFlight,
    {
      delay: 500,
      minDuration: 200,
    },
  );

  function handleCreateComment(
    content: CommentTextEditorContent,
    editor: Editor,
  ) {
    Sentry.metrics.increment("commenting.create_comment_flow", 1, {
      tags: { step: "create_comment_submit_button_clicked" },
    });

    if (editor.isEmpty) {
      setErrorMessage("Please enter a comment.");
      return;
    }

    const parseMentionsResult = parseMentions(content);
    if (!parseMentionsResult.isValid) {
      // NOTE: this can still trigger if they type @bob@org.com and bob is a member of the organization but they didn't select the mention using the tiptap mention extension. We ignore that extensive check at this time because it's tough to explain to the user. Customers should hopefully figure that out themselves by re-typing.
      const emails = parseMentionsResult.invalidMentionEmails;

      setErrorMessage(
        `Could not create comment. You @mentioned the following email that is not a member of your organization. Only organization members can be mentioned in comments. Email: ${emails[0]}`,
      );
      return;
    }

    track("Comments Section Comment Submitted");

    const connectionID = ConnectionHandler.getConnectionID(
      props.contractId,
      "CommentList_contractFragment_comments",
    );

    commitCreateContractCommentMutation({
      variables: {
        contractUuid: props.contractUuid,
        comment: {
          content: {
            htmlContent: content.htmlContent,
            jsonContent: JSON.stringify(content.jsonContent),
            textContent: content.textContent,
          },
        },
        connections: [connectionID],
      },
      updater: (store, payload) => {
        resortCommentEdges(store, props.contractId);
      },
      onCompleted: (response) => {
        setWarningMessage(null);
        setErrorMessage(null);

        const result = response.createComment;

        if (!result) {
          return;
        }

        if (result.__typename === "%other") {
          console.error(
            `Unhandled result type when creating comment. result: `,
            result,
          );
          setErrorMessage("We encountered an error. Please try again later.");
          return;
        }

        if (result.__typename === "ContractNotFoundErrorType") {
          console.error(
            `Unexpected errors encountered when creating comment. error_debugMessage: ${result.debugMessage}`,
          );
          setErrorMessage(
            "Could not create comment. Please try again shortly. If the problem persists after reloading the page, please contact support.",
          );
          return;
        }

        if (result.__typename === "CreateCommentValidationResultType") {
          const {
            accountUnauthorizedValidationErrorEmails,
            hasAccountUnauthorizedValidationErrors,
            otherValidationErrors,
            hasOtherValidationErrors,
          } = getValidationResultErrors(result);

          if (hasAccountUnauthorizedValidationErrors) {
            setErrorMessage(
              `Could not create comment. The following mentioned users do not have access to the contract: ${accountUnauthorizedValidationErrorEmails.join(
                ", ",
              )}.`,
            );
            return;
          }

          if (hasOtherValidationErrors) {
            console.error(
              `Unexpected errors encountered when creating comment. errors: `,
              otherValidationErrors,
            );
            setErrorMessage(
              "Could not create comment. Validation failed. Please try again.",
            );
            return;
          }

          return;
        }

        if (!result.commentEdge.node) {
          console.error(
            `Unexpected errors encountered when creating comment. No commentEdge node exists.`,
          );
          setErrorMessage(
            "There was an error creating the comment. Please reload the page and try again.",
          );
          return;
        }

        const { commentNotificationErrors, hasCommentNotificationErrors } =
          getCommentNotificationErrors(result);

        if (hasCommentNotificationErrors) {
          setWarningMessage(
            `Comment successfully created. However, there was an issue sending notifications.`,
          );
        }

        Sentry.metrics.increment("commenting.create_comment_flow", 1, {
          tags: { step: "comment_created" },
        });

        track("Comments Section Comment Created");

        editor.commands.clearContent();

        if (props.onCommentCreateSuccess) {
          props.onCommentCreateSuccess(result.commentEdge.node.id);
        }

        return;
      },

      onError: (error) => {
        console.error(
          "Unexpected errors encountered when creating comment. errors: ",
          error,
        );
        setErrorMessage(
          "Error creating comment. If the problem persists after reloading the page and trying again, please contact support.",
        );
      },
    });
  }

  const mentionsData =
    currentUser.organization?.accounts
      ?.filter((account) => account !== null)
      .map((account) => ({
        id: account!.uuid,
        display: account!.email,
      })) ?? [];

  return (
    <Grid container rowSpacing={1} xs={12} data-testid="comment-composer-root">
      <Grid container xs={12}>
        <Grid xs="auto" sx={{ pr: "8px" }}>
          <CommentAvatar color={CURRENT_USER_AVATAR_COLOR}>
            {currentUser.email[0].toUpperCase()}
          </CommentAvatar>
        </Grid>
        <Grid xs sx={{ pl: 2 }}>
          <Stack>
            <CommentTextEditor
              content={null}
              editable={true}
              currentUserUuid={currentUser.uuid}
              placeholder={`Type here to add a comment. Type "@" to mention other users from your organization.`}
              organizationFragRef={currentUser.organization!}
              renderFooter={({ handleSubmit, editor }) => (
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    borderTopStyle: "solid",
                    borderTopWidth: 1,
                    borderTopColor: (theme) => theme.palette.divider,
                    py: 1,
                    px: 1.5,
                  }}
                >
                  <CommentsHelpTooltip />
                  <Stack direction="row" spacing={2} alignItems="center">
                    <CharacterCount editor={editor} />
                    <LoadingButton
                      size="small"
                      onClick={handleSubmit}
                      sx={{
                        width: "fit-content",
                        fontWeight: "bold",
                        textTransform: "none",
                      }}
                      variant="contained"
                      data-testid="comment-composer-submit-button"
                      loading={isCreateCommentInProgress}
                      loadingPosition="end"
                      endIcon={<SendIcon />}
                    >
                      Submit
                    </LoadingButton>
                  </Stack>
                </Stack>
              )}
              onSubmit={handleCreateComment}
            />
            {errorMessage && (
              <Typography
                color="error"
                sx={{ my: 2 }}
                data-testid="comment-composer-error-message"
              >
                {errorMessage}
              </Typography>
            )}
            {warningMessage && (
              <Typography color="warning" sx={{ my: 2 }}>
                {warningMessage}
              </Typography>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
}

type CreateCommentValidationResultType = Extract<
  NonNullable<
    CommentComposer_CreateContractCommentMutation$data["createComment"]
  >,
  { __typename: "CreateCommentValidationResultType" }
>;
function getValidationResultErrors(result: CreateCommentValidationResultType) {
  let validationErrors = result.errors;

  const accountUnauthorizedValidationErrors = validationErrors.flatMap(
    (error) =>
      error.__typename ===
      "CommentMentionAccountUnauthorizedValidationErrorType"
        ? [error]
        : [],
  );

  const accountUnauthorizedValidationErrorEmails =
    accountUnauthorizedValidationErrors.map(
      (error) => error.accountUnauthorizedEmail,
    );

  const hasAccountUnauthorizedValidationErrors =
    accountUnauthorizedValidationErrorEmails.length > 0;

  const otherValidationErrors = validationErrors.flatMap((error) =>
    error.__typename === "ValidationError" ? [error] : [],
  );
  const hasOtherValidationErrors = otherValidationErrors.length > 0;

  return {
    accountUnauthorizedValidationErrors,
    accountUnauthorizedValidationErrorEmails,
    hasAccountUnauthorizedValidationErrors,
    otherValidationErrors,
    hasOtherValidationErrors,
  };
}

type CreateCommentSuccessResultType = Extract<
  NonNullable<
    CommentComposer_CreateContractCommentMutation$data["createComment"]
  >,
  { __typename: "CreateCommentSuccessResultType" }
>;

function getCommentNotificationErrors(result: CreateCommentSuccessResultType) {
  let commentNotificationErrors = (result.notificationErrors ?? []).flatMap(
    (error) => {
      switch (error.__typename) {
        // we intentionally ignore errors returned for notifications. See CS-3140
        case "CommentNotificationAccountUnauthorizedErrorType":
        case "CommentNotificationUnexpectedErrorType":
          console.debug(
            "Error sending comment notifications: ",
            error.debugMessage,
          );
          return [];
        case "%other":
          console.error(
            `Unexpected errors encountered when creating comment. error__typename: ${error.__typename}`,
          );
          return [];
        default:
          return [];
      }
    },
  );
  const hasCommentNotificationErrors = commentNotificationErrors.length > 0;
  return {
    commentNotificationErrors,
    hasCommentNotificationErrors,
  };
}
