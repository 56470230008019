import React from "react";
import {
  Alert,
  CircularProgress,
  Stack,
  type SxProps,
  type Theme,
} from "@mui/material";
import ArrowOutwardOutlinedIcon from "@mui/icons-material/ArrowOutwardOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import { Link } from "react-router";
import {
  ContractPageHeaderActionButton,
  ContractPageHeaderActionButtonSkeleton,
} from "./ContractPageHeaderActionButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SendContractDialog from "~/components/SendContractDialog";
import { SummaryButton, SummaryButtonSkeleton } from "./SummaryButton";
import { withDelayedSuspenseFallback } from "~/utils/withSuspenseFallback";
import { useContractPageHeaderActions } from "./useContractPageHeaderActions";
import { graphql } from "relay-runtime";
import { useDeferredLayoutQuery } from "./useDeferredLayoutQuery";
import UpdateContractDialog from "~/components/UpdateContractDialog";
import RedlineDialog from "~/components/RedlineDialog";
import ReplaceContractDialog from "~/components/ReplaceContractDialog";
import { UploadContractFileDialog } from "~/components/UploadContractFileDialog";
import { ArchiveDialog } from "../components/ArchiveDialog";
import { DeleteDialog } from "../components/DeleteDialog";
import { DuplicateDialog } from "../components/DuplicateDialog";
import { useFragment, useQueryLoader } from "react-relay";
import { useSnackbar } from "~/components/Snackbar";
import { useContractPageHeaderActionsPermissions } from "./useContractPageHeaderActionsPermissions";
import type { ContractPageHeaderActions_accountFragment$key } from "./__generated__/ContractPageHeaderActions_accountFragment.graphql";
import type { ContractPageHeaderActions_contractFragment$key } from "./__generated__/ContractPageHeaderActions_contractFragment.graphql";
import type { ContractPageHeaderActions_organizationFragment$key } from "./__generated__/ContractPageHeaderActions_organizationFragment.graphql";
import { ContractPageMoreMenu } from "./ContractPageMoreMenu";
import { useContractFileState } from "../hooks/useContractFileState";
import { useContractAttachments } from "../hooks/useContractAttachments";
import invariant from "~/utils/invariant";
import {
  UPDATE_CONTRACT_FROM_TEMPLATE_DIALOG_QUERY,
  UpdateContractFromTemplateDialog,
  type TUpdateContractFromTemplateDialog_Query,
} from "~/components/UpdateContractFromTemplateDialog/UpdateContractFromTemplateDialog";
import type { ContractFormsContract_Query } from "~/contracts/queries/__generated__/ContractFormsContract_Query.graphql";
import { CONTRACT_FORMS_CONTRACT_QUERY } from "~/utils/globalQueries";

export type ContractPageHeaderActionsProps = {
  isSummaryOpen: boolean;
  accountFragRef: ContractPageHeaderActions_accountFragment$key;
  contractFragRef: ContractPageHeaderActions_contractFragment$key;
  organizationFragRef: ContractPageHeaderActions_organizationFragment$key;
  sx?: SxProps<Theme>;
  toggleSummary: () => void;
};

export function ContractPageHeaderActions(
  props: ContractPageHeaderActionsProps,
) {
  const { openSnackbar, openSnackbarWithProps, closeSnackbar } = useSnackbar();
  const account = useFragment(
    ContractPageHeaderActions_accountFragment,
    props.accountFragRef,
  );
  const contract = useFragment(
    ContractPageHeaderActions_contractFragment,
    props.contractFragRef,
  );
  const organization = useFragment(
    ContractPageHeaderActions_organizationFragment,
    props.organizationFragRef,
  );

  const { hasAttachments } = useContractAttachments(contract);
  const fileState = useContractFileState(contract);
  const permissions = useContractPageHeaderActionsPermissions({
    accountFragRef: account,
    contractFragRef: contract,
    organizationFragRef: organization,
  });

  const actions = useContractPageHeaderActions();

  const [moreMenuAnchorEl, setMoreMenuAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const [sendContractDialogOpen, setSendContractDialogOpen] =
    React.useState(false);
  const [redlineDialogOpen, setRedlineDialogOpen] = React.useState(false);
  const [duplicateDialogOpen, setDuplicateDialogOpen] = React.useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [archiveDialogOpen, setArchiveDialogOpen] = React.useState(false);
  const [uploadDialogOpen, setUploadDialogOpen] = React.useState(false);
  const [updateContractDialogOpen, setUpdateContractDialogOpen] =
    React.useState(false);
  const [replaceContractFileDialogOpen, setReplaceContractFileDialogOpen] =
    React.useState(false);
  const [
    updateContractFromTemplateDialogOpen,
    setUpdateContractFromTemplateDialogOpen,
  ] = React.useState(false);
  const [
    updateContractFromTemplateDialogQueryRef,
    loadUpdateContractFromTemplateDialogQuery,
  ] = useQueryLoader<TUpdateContractFromTemplateDialog_Query>(
    UPDATE_CONTRACT_FROM_TEMPLATE_DIALOG_QUERY,
  );
  const [contractFormsContractQueryRef, loadContractFormsContractQuery] =
    useQueryLoader<ContractFormsContract_Query>(CONTRACT_FORMS_CONTRACT_QUERY);

  function handleSendContract() {
    setSendContractDialogOpen(true);
  }

  async function handleDownloadContractFile() {
    invariant(contract.fileDownloadName);

    openSnackbarWithProps({
      children: (
        <Alert severity="info" onClick={closeSnackbar}>
          <Stack direction="row" alignItems="center">
            Your document is being prepared. It will download automatically when
            complete to the Downloads folder on your computer.
            <CircularProgress
              size="small"
              sx={{ width: 20, height: 20, marginLeft: 2 }}
            />
          </Stack>
        </Alert>
      ),
      autoHideDuration: null,
    });

    const result = await actions.commitDownloadContractFile({
      contractUuid: contract.uuid,
      fileDownloadName: contract.fileDownloadName,
    });
    switch (result.state) {
      case "SUCCESS": {
        openSnackbarWithProps({
          children: (
            <Alert severity="success" onClick={closeSnackbar}>
              Contract download started
            </Alert>
          ),
        });
        break;
      }
      case "DOWNLOAD_ERROR": {
        const error = result.error;
        openSnackbarWithProps({
          children: (
            <Alert severity="error" onClick={closeSnackbar}>
              {error instanceof Error &&
              error.message === "Contract file download name is required"
                ? "Contract file name is missing"
                : "Failed to download contract"}
            </Alert>
          ),
        });
        break;
      }
      case "ADD_AUDIT_ENTRY_ERROR": {
        openSnackbarWithProps({
          children: (
            <Alert severity="error" onClick={closeSnackbar}>
              Failed to record download in audit log
            </Alert>
          ),
        });
        break;
      }
      default:
        return result satisfies never;
    }
  }

  async function handleDownloadContractFileWord() {
    invariant(contract.fileDownloadName);

    openSnackbarWithProps({
      children: (
        <Alert severity="info" onClick={closeSnackbar}>
          Your document is being converted. It will download automatically when
          complete to the Downloads folder on your computer.
          <CircularProgress />
        </Alert>
      ),
    });

    const result = await actions.commitDownloadContractFileWord({
      contractUuid: contract.uuid,
      fileDownloadName: contract.fileDownloadName,
      filename: contract.fileDownloadName,
    });
    switch (result.state) {
      case "SUCCESS": {
        openSnackbarWithProps({
          children: (
            <Alert severity="success" onClick={closeSnackbar}>
              Contract download started
            </Alert>
          ),
        });
        break;
      }
      case "DOWNLOAD_ERROR": {
        const error = result.error;
        openSnackbarWithProps({
          children: (
            <Alert severity="error" onClick={closeSnackbar}>
              {error instanceof Error &&
              error.message === "Contract file download name is required"
                ? "Contract file name is missing"
                : "Failed to download contract"}
            </Alert>
          ),
        });
        break;
      }
      case "ADD_AUDIT_ENTRY_ERROR": {
        openSnackbarWithProps({
          children: (
            <Alert severity="error" onClick={closeSnackbar}>
              Failed to record download in audit log
            </Alert>
          ),
        });
        break;
      }
      default:
        return result satisfies never;
    }
  }

  function handleRedline() {
    setRedlineDialogOpen(true);
  }

  function handleDuplicate() {
    setDuplicateDialogOpen(true);
  }

  function handleDelete() {
    setDeleteDialogOpen(true);
  }

  function handleArchive() {
    setArchiveDialogOpen(true);
  }

  function handleReplaceContractFile() {
    setReplaceContractFileDialogOpen(true);
  }

  function handleReplaceContractFileViaUrl() {
    setUpdateContractDialogOpen(true);
  }

  function handleUploadContractFile() {
    setUploadDialogOpen(true);
  }

  function handleUpdateContractFromTemplate() {
    loadUpdateContractFromTemplateDialogQuery({});
    loadContractFormsContractQuery({
      contractUuid: contract.uuid,
    });
    setUpdateContractFromTemplateDialogOpen(true);
  }

  function handleMoreMenuOpen(event: React.MouseEvent<HTMLButtonElement>) {
    setMoreMenuAnchorEl(event.currentTarget);
  }

  function handleMoreMenuClose() {
    setMoreMenuAnchorEl(null);
  }

  return (
    <>
      <Stack direction="row" sx={props.sx}>
        <SummaryButton
          isOpen={props.isSummaryOpen}
          onClick={props.toggleSummary}
          sx={{ marginLeft: 5 }}
        />
        <Link to={`document`} target="_blank">
          <ContractPageHeaderActionButton
            aria-label="open contract file"
            icon={<ArrowOutwardOutlinedIcon />}
          />
        </Link>

        <ContractPageHeaderActionButton
          aria-label="send contract"
          icon={<SendOutlinedIcon />}
          sx={{
            display: "none",
            "@container (min-width: 250px)": { display: "block" },
          }}
          onClick={handleSendContract}
          disabled={!permissions.sendContract.isAuthorized}
          tooltipTitle={permissions.sendContract.authorizationMessage}
        />

        <ContractPageHeaderActionButton
          key="more-button"
          size="small"
          aria-label="more actions"
          id="more-actions-button"
          icon={<MoreHorizIcon />}
          onClick={handleMoreMenuOpen}
          aria-controls="more-menu"
          aria-haspopup="true"
          aria-expanded={Boolean(moreMenuAnchorEl)}
        />
        <ContractPageMoreMenu
          anchorEl={moreMenuAnchorEl}
          onClose={handleMoreMenuClose}
          actionHandlers={{
            handleDownloadContractFile,
            handleDownloadContractFileWord,
            handleRedline,
            handleDuplicate,
            handleDelete,
            handleArchive,
            handleUploadContractFile,
            handleReplaceContractFile,
            handleReplaceContractFileViaUrl,
            handleUpdateContractFromTemplate,
          }}
          fileState={fileState}
          permissions={permissions}
        />
      </Stack>

      <SendContractDialog
        open={sendContractDialogOpen}
        setOpen={setSendContractDialogOpen}
        account={account}
        contract={contract}
        openSnackbar={openSnackbar}
      />

      <RedlineDialog
        contract={contract}
        open={redlineDialogOpen}
        onClose={() => setRedlineDialogOpen(false)}
        dateFormat={organization.dateFormat}
      />

      <DuplicateDialog
        contractUuid={contract.uuid}
        open={duplicateDialogOpen}
        handleClose={() => setDuplicateDialogOpen(false)}
        hasAttachments={hasAttachments}
      />

      <DeleteDialog
        contractUuid={contract.uuid}
        open={deleteDialogOpen}
        handleClose={() => setDeleteDialogOpen(false)}
      />

      <ArchiveDialog
        contractUuid={contract.uuid}
        open={archiveDialogOpen}
        handleClose={() => setArchiveDialogOpen(false)}
      />

      <UploadContractFileDialog
        contractUuid={contract.uuid}
        open={uploadDialogOpen}
        handleClose={() => setUploadDialogOpen(false)}
      />

      {updateContractDialogOpen && (
        <UpdateContractDialog
          account={account}
          contract={contract}
          open={updateContractDialogOpen}
          handleClose={() => setUpdateContractDialogOpen(false)}
          setPermErrorDialog={() =>
            openSnackbarWithProps({
              children: (
                <Alert severity="error" onClick={closeSnackbar}>
                  You do not have permission to generate redlines
                </Alert>
              ),
            })
          }
          onCommit={(message: string) =>
            openSnackbarWithProps({
              children: (
                <Alert severity="success" onClick={closeSnackbar}>
                  {message}
                </Alert>
              ),
            })
          }
        />
      )}

      <ReplaceContractDialog
        account={account}
        contractUuid={contract.uuid}
        open={replaceContractFileDialogOpen}
        handleClose={() => setReplaceContractFileDialogOpen(false)}
        setPermErrorDialog={() =>
          openSnackbarWithProps({
            children: (
              <Alert severity="error" onClick={closeSnackbar}>
                You do not have permission to generate redlines
              </Alert>
            ),
          })
        }
      />

      <UpdateContractFromTemplateDialog
        mode={fileState === "NO_FILE" ? "CREATE_FILE" : "REPLACE_FILE"}
        contractFormsContractQueryRef={contractFormsContractQueryRef}
        queryRef={updateContractFromTemplateDialogQueryRef}
        open={updateContractFromTemplateDialogOpen}
        handleClose={() => setUpdateContractFromTemplateDialogOpen(false)}
      />
    </>
  );
}

type ContractPageHeaderActionsContainerProps = Omit<
  ContractPageHeaderActionsProps,
  "accountFragRef" | "contractFragRef" | "organizationFragRef"
>;

export function ContractPageHeaderActionsContainer(
  props: ContractPageHeaderActionsContainerProps,
) {
  const queryData = useDeferredLayoutQuery();

  return (
    <ContractPageHeaderActions
      accountFragRef={queryData.account}
      contractFragRef={queryData.contract}
      organizationFragRef={queryData.organization}
      {...props}
    />
  );
}

type ContractPageHeaderActionsSkeletonProps = Pick<
  ContractPageHeaderActionsProps,
  "sx"
>;

function ContractPageHeaderActionsSkeleton(
  props: ContractPageHeaderActionsSkeletonProps,
) {
  return (
    <Stack
      direction="row"
      sx={[
        { minWidth: 0 },
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      ]}
    >
      <SummaryButtonSkeleton sx={{ marginLeft: 5 }} />
      <ContractPageHeaderActionButtonSkeleton />
      <ContractPageHeaderActionButtonSkeleton
        sx={{
          display: "none",
          "@container (min-width: 250px)": { display: "block" },
        }}
      />

      <ContractPageHeaderActionButtonSkeleton />
    </Stack>
  );
}

export const ContractPageHeaderActionsContainerWithSuspenseFallback =
  withDelayedSuspenseFallback(
    ContractPageHeaderActionsContainer,
    <ContractPageHeaderActionsSkeleton />,
  );

const ContractPageHeaderActions_accountFragment = graphql`
  fragment ContractPageHeaderActions_accountFragment on AccountType
  @argumentDefinitions(contractUuid: { type: "String" }) {
    ...SendContractDialog_accountFragment
    ...ReplaceContractDialog_accountFragment
    ...UpdateContractDialog_accountFragment
    ...useContractPageHeaderActionsPermissions_accountFragment
      @arguments(contractUuid: $contractUuid)
  }
`;

const ContractPageHeaderActions_contractFragment = graphql`
  fragment ContractPageHeaderActions_contractFragment on ContractType {
    uuid
    id
    filename
    fileDownloadName
    title
    originurl {
      url
    }
    relatedDocs {
      uuid
      internalContract {
        uuid
      }
    }
    ...useContractAttachments_contractFragment
    ...useContractFileState_contractFragment
    ...SendContractDialog_fragment
    ...RedlineDialog_fragment
    ...UpdateContractFromTemplateDialogContent_contractFragment
    ...UpdateContractDialog_fragment

    ...useContractPageHeaderActionsPermissions_contractFragment
  }
`;

const ContractPageHeaderActions_organizationFragment = graphql`
  fragment ContractPageHeaderActions_organizationFragment on OrganizationType {
    dateFormat
    ...useContractPageHeaderActionsPermissions_organizationFragment
  }
`;
