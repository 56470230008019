import React from "react";
import Box from "@mui/material/Box";

export default function VisibleVertScroll({ maxHeight, children, ...props }) {
  const scrollbar = {
    "&::-webkit-scrollbar": {
      "-webkit-appearance": "none",
      backgroundColor: "#FAFAFA",
      borderRadius: 10,
      width: 15,
    },
    "&::-webkit-scrollbar-track:vertical": {
      borderLeft: "1px solid #E7E7E7",
      borderRight: "1px solid #E7E7E7",
    },
    "&::-webkit-scrollbar-track:horizontal": {
      borderTop: "1px solid #E7E7E7",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundClip: "padding-box",
      backgroundColor: "#C1C1C1",
      borderRadius: "9px 8px 8px 9px",
      borderColor: "transparent",
      borderStyle: "solid",
      borderWidth: "3px 3px 3px 4px",
      boxShadow: "0 0 1px rgba(255, 255, 255, 0.5)",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
  };
  return (
    <Box
      sx={{
        overflowY: "auto",
        maxHeight,
        ...(navigator.platform.startsWith("Mac") ? scrollbar : null),
      }}
      {...props}
    >
      {children}
    </Box>
  );
}
