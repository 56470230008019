/**
 * @generated SignedSource<<c031321598f0f548f158d4c30ce6a2e9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type RelateInternalDialog_Query$variables = {
  LoadAll: boolean;
  SearchParams: string;
  contractUUID: string;
};
export type RelateInternalDialog_Query$data = {
  readonly relatedcontracts: ReadonlyArray<{
    readonly counterparty: string | null | undefined;
    readonly effectiveDate: {
      readonly date: any | null | undefined;
    } | null | undefined;
    readonly id: string;
    readonly title: string | null | undefined;
    readonly uuid: string;
  } | null | undefined> | null | undefined;
};
export type RelateInternalDialog_Query = {
  response: RelateInternalDialog_Query$data;
  variables: RelateInternalDialog_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "LoadAll"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "SearchParams"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contractUUID"
},
v3 = [
  {
    "kind": "Variable",
    "name": "ctUuid",
    "variableName": "contractUUID"
  },
  {
    "kind": "Variable",
    "name": "loadall",
    "variableName": "LoadAll"
  },
  {
    "kind": "Variable",
    "name": "searchParams",
    "variableName": "SearchParams"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "counterparty",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "RelateInternalDialog_Query",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "ContractType",
        "kind": "LinkedField",
        "name": "relatedcontracts",
        "plural": true,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractDateType",
            "kind": "LinkedField",
            "name": "effectiveDate",
            "plural": false,
            "selections": [
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "RelateInternalDialog_Query",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "ContractType",
        "kind": "LinkedField",
        "name": "relatedcontracts",
        "plural": true,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractDateType",
            "kind": "LinkedField",
            "name": "effectiveDate",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "597bc202bb52341570daab18a675480f",
    "id": null,
    "metadata": {},
    "name": "RelateInternalDialog_Query",
    "operationKind": "query",
    "text": "query RelateInternalDialog_Query(\n  $contractUUID: String!\n  $SearchParams: String!\n  $LoadAll: Boolean!\n) {\n  relatedcontracts(ctUuid: $contractUUID, searchParams: $SearchParams, loadall: $LoadAll) {\n    id\n    uuid\n    title\n    counterparty\n    effectiveDate {\n      date\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0ef1a0b882ddf018c4d429ada45b6c74";

export default node;
