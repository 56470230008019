import MenuItem, { type MenuItemProps } from "@mui/material/MenuItem";
import Select, {
  type SelectChangeEvent,
  type SelectProps,
} from "@mui/material/Select";
import React from "react";
import { graphql, useFragment } from "react-relay";
import type { TemplatesDropdown_organizationFragment$key } from "./__generated__/TemplatesDropdown_organizationFragment.graphql";
import type { SxProps } from "@mui/material/styles";
import type Theme from "../../Theme";

type TemplatesDropdownProps = {
  selectedTemplateId: string | null;
  onChange: (templateId: string) => void;
  organizationFragRef: TemplatesDropdown_organizationFragment$key;
  sx?: SxProps<typeof Theme>;
  SelectProps?: Partial<SelectProps<string>>;
  MenuItemProps?: Partial<MenuItemProps>;
};

export function TemplatesDropdown(props: TemplatesDropdownProps) {
  const { SelectProps, MenuItemProps } = props;
  const organization = useFragment(
    ORGANIZATION_FRAGMENT,
    props.organizationFragRef,
  );
  const templates = organization.templates ?? [];
  const handleChange = (event: SelectChangeEvent<string>) => {
    props.onChange(event.target.value);
  };

  return (
    <Select
      value={props.selectedTemplateId ?? "placeholder"}
      onChange={handleChange}
      sx={[
        {
          "& .MuiInputBase-input": {
            fontSize: "14px",
          },
        },
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      ]}
      {...SelectProps}
    >
      <MenuItem value="placeholder" disabled {...MenuItemProps}>
        Select a Template
      </MenuItem>
      {[...templates]
        // @ts-expect-error friendlySort is a global
        .sort((a, b) => friendlySort(a.name, b.name))
        .map((template) => (
          <MenuItem
            key={template.id}
            value={template.id}
            sx={{ fontSize: 14 }}
            {...MenuItemProps}
          >
            {template.name}
          </MenuItem>
        ))}
    </Select>
  );
}

type TemplatesDropdownLoadingPlaceholderProps = {
  sx?: SxProps<typeof Theme>;
};

export function TemplatesDropdownLoadingPlaceholder(
  props: TemplatesDropdownLoadingPlaceholderProps,
) {
  return (
    <Select
      value={"placeholder"}
      sx={[
        {
          "& .MuiInputBase-input": {
            fontSize: "14px",
          },
        },
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      ]}
    >
      <MenuItem value="placeholder" disabled>
        Select a Template
      </MenuItem>
    </Select>
  );
}

const ORGANIZATION_FRAGMENT = graphql`
  fragment TemplatesDropdown_organizationFragment on OrganizationType {
    templates {
      id
      name
    }
  }
`;
