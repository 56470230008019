import React from "react";
import { useSpinDelay } from "spin-delay";

type DelayedFallbackProps = {
  /**
   * The fallback content to display while the spinner is displayed.
   */
  fallback: React.ReactNode;
  /**
   * The delay in milliseconds before the spinner is displayed.
   * @default 500
   */
  delay?: number;
  /**
   * The minimum duration in milliseconds the spinner is displayed.
   * @default 200
   */
  minDuration?: number;
};

/**
 * A component that displays a fallback while a spinner is displayed. 
 * 
 * This is best used for a Suspense fallback to avoid flickering and a poor UX.
 * 
 * You'll likely want to use this in conjunction with the `withDelayedSuspenseFallback` function.
 * 
 * @example
 * 
 export function ContractPageHeaderWithFallback(props: ContractPageHeaderProps) {
   return (
     <Suspense
       fallback={<DelayedFallback fallback={<ContractPageHeaderSkeleton />} />}
     >
       <ContractPageHeader {...props} />
     </Suspense>
   );
 }
 * 
 * @returns The DelayedFallback component.
 */
export function DelayedFallback(props: DelayedFallbackProps) {
  const showSpinner = useSpinDelay(true, {
    delay: props.delay ?? 500,
    minDuration: props.minDuration ?? 200,
  });

  if (showSpinner) {
    return props.fallback;
  }
  return null;
}
