/**
 * @generated SignedSource<<056d405a9b3411178b7445b0987f6293>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FormSelect_addFormMutation$variables = {
  dupeFormId?: string | null | undefined;
  name: string;
};
export type FormSelect_addFormMutation$data = {
  readonly addForm: {
    readonly form: {
      readonly id: string;
    } | null | undefined;
    readonly organization: {
      readonly forms: ReadonlyArray<{
        readonly " $fragmentSpreads": FragmentRefs<"FormSelect_formFragment">;
      } | null | undefined> | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type FormSelect_addFormMutation = {
  response: FormSelect_addFormMutation$data;
  variables: FormSelect_addFormMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dupeFormId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v2 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "dupeFormId",
        "variableName": "dupeFormId"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "FormType",
  "kind": "LinkedField",
  "name": "form",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fieldType",
  "storageKey": null
},
v9 = [
  (v3/*: any*/),
  (v6/*: any*/)
],
v10 = {
  "kind": "InlineFragment",
  "selections": (v4/*: any*/),
  "type": "Node",
  "abstractKey": "__isNode"
},
v11 = {
  "kind": "InlineFragment",
  "selections": (v9/*: any*/),
  "type": "FieldSetType",
  "abstractKey": null
},
v12 = [
  (v3/*: any*/),
  (v6/*: any*/),
  (v8/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "FormSelect_addFormMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "AddFormPayload",
        "kind": "LinkedField",
        "name": "addForm",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationType",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "FormType",
                "kind": "LinkedField",
                "name": "forms",
                "plural": true,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "FormSelect_formFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "FormSelect_addFormMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "AddFormPayload",
        "kind": "LinkedField",
        "name": "addForm",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationType",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "FormType",
                "kind": "LinkedField",
                "name": "forms",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "uuid",
                    "storageKey": null
                  },
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FormSectionType",
                    "kind": "LinkedField",
                    "name": "sections",
                    "plural": true,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "fields",
                        "plural": true,
                        "selections": [
                          (v7/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v3/*: any*/),
                              (v6/*: any*/),
                              (v8/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ValueListItemType",
                                "kind": "LinkedField",
                                "name": "choices",
                                "plural": true,
                                "selections": (v9/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "type": "CustomFieldType",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v3/*: any*/),
                              (v6/*: any*/),
                              (v8/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "choices",
                                "plural": true,
                                "selections": [
                                  (v7/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": (v9/*: any*/),
                                    "type": "StringChoiceType",
                                    "abstractKey": null
                                  },
                                  (v10/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "BuiltinFieldType",
                            "abstractKey": null
                          },
                          (v11/*: any*/),
                          (v10/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "requiredFields",
                    "plural": true,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": (v12/*: any*/),
                        "type": "CustomFieldType",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v12/*: any*/),
                        "type": "BuiltinFieldType",
                        "abstractKey": null
                      },
                      (v11/*: any*/),
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f9a548a639500badef6ebb49f641a299",
    "id": null,
    "metadata": {},
    "name": "FormSelect_addFormMutation",
    "operationKind": "mutation",
    "text": "mutation FormSelect_addFormMutation(\n  $name: String!\n  $dupeFormId: ID\n) {\n  addForm(input: {name: $name, dupeFormId: $dupeFormId}) {\n    form {\n      id\n    }\n    organization {\n      forms {\n        ...FormSelect_formFragment\n        id\n      }\n      id\n    }\n  }\n}\n\nfragment FormSelect_formFragment on FormType {\n  id\n  uuid\n  name\n  sections {\n    name\n    fields {\n      __typename\n      ... on CustomFieldType {\n        id\n        name\n        fieldType\n        choices {\n          id\n          name\n        }\n      }\n      ... on BuiltinFieldType {\n        id\n        name\n        fieldType\n        choices {\n          __typename\n          ... on StringChoiceType {\n            id\n            name\n          }\n          ... on Node {\n            __isNode: __typename\n            id\n          }\n        }\n      }\n      ... on FieldSetType {\n        id\n        name\n      }\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n    }\n  }\n  requiredFields {\n    __typename\n    ... on CustomFieldType {\n      id\n      name\n      fieldType\n    }\n    ... on BuiltinFieldType {\n      id\n      name\n      fieldType\n    }\n    ... on FieldSetType {\n      id\n      name\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "19cc8218dddbb0b25ec547a586b44208";

export default node;
