export function calcCustomizedContractTypes(orgContractTypes: any) {
  return orgContractTypes
    .map((ctype: any) => {
      return {
        id: ctype.id,
        name: ctype.customName || ctype.name,
        isActive: ctype.isActive ?? true,
      };
    })
    .filter((ctype: any) => {
      return ctype.isActive ?? true;
    })
    .sort((a: any, b: any) => (a.name > b.name ? 1 : -1));
}

export function getContractTypeField(orgContractTypes: any) {
  if (!!orgContractTypes) {
    return {
      id: "core:contractType",
      name: "Contract Type",
      fieldType: "m",
      choices: calcCustomizedContractTypes(orgContractTypes),
    } as const;
  }
}
