import React, { Suspense } from "react";
import { TemplatesDropdownLoadingPlaceholder } from "~/components/Templates/TemplatesDropdown";
import { TemplatesDropdown } from "~/components/Templates/TemplatesDropdown";
import { graphql } from "relay-runtime";
import { useFragment } from "react-relay";
import type { UpdateContractFromTemplateDialogTemplatesDropdown_organizationFragment$key } from "./__generated__/UpdateContractFromTemplateDialogTemplatesDropdown_organizationFragment.graphql";

type LoadedUpdateContractFromTemplateDialogTemplatesDropdownProps = {
  organizationFragRef: UpdateContractFromTemplateDialogTemplatesDropdown_organizationFragment$key;
  selectedTemplateId: string | null;
  onChange: (templateId: string) => void;
};

export function LoadedUpdateContractFromTemplateDialogTemplatesDropdown(
  props: LoadedUpdateContractFromTemplateDialogTemplatesDropdownProps,
) {
  const { organizationFragRef, ...otherProps } = props;
  const organization = useFragment(
    ORGANIZATION_FRAGMENT,
    props.organizationFragRef,
  );

  return (
    <TemplatesDropdown
      organizationFragRef={organization}
      sx={{ "& .MuiSelect-select": { paddingY: 2 } }}
      {...otherProps}
    />
  );
}

type UpdateContractFromTemplateDialogTemplatesDropdownProps = {
  organizationFragRef: UpdateContractFromTemplateDialogTemplatesDropdown_organizationFragment$key;
  selectedTemplateId: string | null;
  onChange: (templateId: string) => void;
};

export function UpdateContractFromTemplateDialogTemplatesDropdown(
  props: UpdateContractFromTemplateDialogTemplatesDropdownProps,
) {
  return (
    <Suspense
      fallback={
        <TemplatesDropdownLoadingPlaceholder
          sx={{ "& .MuiSelect-select": { paddingY: 2 } }}
        />
      }
    >
      <LoadedUpdateContractFromTemplateDialogTemplatesDropdown {...props} />
    </Suspense>
  );
}

const ORGANIZATION_FRAGMENT = graphql`
  fragment UpdateContractFromTemplateDialogTemplatesDropdown_organizationFragment on OrganizationType {
    ...TemplatesDropdown_organizationFragment
  }
`;
