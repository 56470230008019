/**
 * @generated SignedSource<<74ee5662a88f741e489abc666986b66d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ContractsContractDateRecurSpanChoices = "A_" | "DAY" | "MO" | "NVR" | "SPC" | "YR" | "%future added value";
export type ContractsContractDateRecurTypeChoices = "A_" | "A_1" | "A_2" | "A_3" | "%future added value";
export type ContractsContractDateRenewEveryChoices = "MO" | "NVR" | "QTR" | "SMA" | "SPC" | "YR" | "YR10" | "YR2" | "YR3" | "YR4" | "YR5" | "YR7" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PDFViewer_fragment$data = {
  readonly aiValues: any | null | undefined;
  readonly annotationsJson: any | null | undefined;
  readonly builtinFieldValues: ReadonlyArray<{
    readonly field: {
      readonly id: string;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"ContractForms_fieldValueFragment">;
  } | null | undefined>;
  readonly counterparties: ReadonlyArray<{
    readonly label: string | null | undefined;
    readonly party: {
      readonly companyName: string | null | undefined;
      readonly uuid: string;
    } | null | undefined;
    readonly uuid: string;
  } | null | undefined>;
  readonly currencySymbol: string | null | undefined;
  readonly customFieldValues: ReadonlyArray<{
    readonly field: {
      readonly id: string;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"ContractForms_fieldValueFragment">;
  } | null | undefined>;
  readonly dates: ReadonlyArray<{
    readonly completions: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly completedBy: {
            readonly email: string;
          } | null | undefined;
          readonly completedOn: string;
        } | null | undefined;
      } | null | undefined>;
    };
    readonly date: any | null | undefined;
    readonly enableReminder: boolean;
    readonly id: string;
    readonly label: string;
    readonly numberOfOccurences: number;
    readonly protected: boolean | null | undefined;
    readonly recurSpan: ContractsContractDateRecurSpanChoices;
    readonly recurType: ContractsContractDateRecurTypeChoices;
    readonly recursUntil: any | null | undefined;
    readonly reminder: {
      readonly addCustomMessage: boolean;
      readonly attachDocument: boolean;
      readonly customMessage: string | null | undefined;
      readonly id: string;
      readonly includeRelatedDocuments: boolean;
      readonly isactive: boolean | null | undefined;
      readonly recipients: ReadonlyArray<string | null | undefined> | null | undefined;
      readonly remindOn: any | null | undefined;
      readonly remindOnType: string | null | undefined;
      readonly repeatEvery: string;
      readonly " $fragmentSpreads": FragmentRefs<"EditDateDialog_reminderFragment">;
    } | null | undefined;
    readonly repeat: boolean;
    readonly repeatEveryNum: number | null | undefined;
    readonly trackCompletion: boolean;
    readonly uuid: string;
  } | null | undefined>;
  readonly effectiveDate: {
    readonly date: any | null | undefined;
    readonly id: string;
    readonly label: string;
    readonly uuid: string;
  } | null | undefined;
  readonly filename: string;
  readonly filenameOcr: string | null | undefined;
  readonly form: {
    readonly id: string;
    readonly name: string | null | undefined;
    readonly requiredFields: ReadonlyArray<{
      readonly __typename: "BuiltinFieldType";
      readonly fieldType: string;
      readonly id: string;
      readonly name: string;
    } | {
      readonly __typename: "CustomFieldType";
      readonly fieldType: string;
      readonly id: string;
      readonly name: string;
    } | {
      readonly __typename: "FieldSetType";
      readonly id: string;
      readonly name: string;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    } | null | undefined> | null | undefined;
    readonly sections: ReadonlyArray<{
      readonly fields: ReadonlyArray<{
        readonly __typename: "BuiltinFieldType";
        readonly choices: ReadonlyArray<{
          readonly id?: string;
          readonly name?: string;
        } | null | undefined> | null | undefined;
        readonly fieldType: string;
        readonly id: string;
        readonly name: string;
      } | {
        readonly __typename: "CustomFieldType";
        readonly choices: ReadonlyArray<{
          readonly id: string;
          readonly name: string;
        } | null | undefined> | null | undefined;
        readonly fieldType: string;
        readonly id: string;
        readonly name: string;
      } | {
        readonly __typename: "FieldSetType";
        readonly id: string;
        readonly name: string;
      } | {
        // This will never be '%other', but we need some
        // value in case none of the concrete values match.
        readonly __typename: "%other";
      } | null | undefined> | null | undefined;
      readonly name: string;
    } | null | undefined> | null | undefined;
    readonly uuid: string;
  } | null | undefined;
  readonly mycompanies: ReadonlyArray<{
    readonly label: string | null | undefined;
    readonly party: {
      readonly companyName: string | null | undefined;
      readonly uuid: string;
    } | null | undefined;
    readonly uuid: string;
  } | null | undefined>;
  readonly terminationDate: {
    readonly date: any | null | undefined;
    readonly id: string;
    readonly label: string;
    readonly renewDate: any | null | undefined;
    readonly renewEvery: ContractsContractDateRenewEveryChoices | null | undefined;
    readonly uuid: string;
  } | null | undefined;
  readonly totalValue: any | null | undefined;
  readonly uuid: string;
  readonly " $fragmentType": "PDFViewer_fragment";
};
export type PDFViewer_fragment$key = {
  readonly " $data"?: PDFViewer_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PDFViewer_fragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "PartyType",
    "kind": "LinkedField",
    "name": "party",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "companyName",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fieldType",
  "storageKey": null
},
v8 = [
  (v3/*: any*/),
  (v5/*: any*/)
],
v9 = {
  "kind": "InlineFragment",
  "selections": (v8/*: any*/),
  "type": "FieldSetType",
  "abstractKey": null
},
v10 = [
  (v3/*: any*/),
  (v5/*: any*/),
  (v7/*: any*/)
],
v11 = [
  (v3/*: any*/)
],
v12 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "ContractForms_fieldValueFragment"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PDFViewer_fragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ContractPartyType",
      "kind": "LinkedField",
      "name": "counterparties",
      "plural": true,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ContractPartyType",
      "kind": "LinkedField",
      "name": "mycompanies",
      "plural": true,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ContractDateType",
      "kind": "LinkedField",
      "name": "effectiveDate",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        (v0/*: any*/),
        (v1/*: any*/),
        (v4/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ContractDateType",
      "kind": "LinkedField",
      "name": "terminationDate",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        (v0/*: any*/),
        (v1/*: any*/),
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "renewEvery",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "renewDate",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalValue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currencySymbol",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FormType",
      "kind": "LinkedField",
      "name": "form",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        (v0/*: any*/),
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "FormSectionType",
          "kind": "LinkedField",
          "name": "sections",
          "plural": true,
          "selections": [
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "fields",
              "plural": true,
              "selections": [
                (v6/*: any*/),
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v3/*: any*/),
                    (v5/*: any*/),
                    (v7/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ValueListItemType",
                      "kind": "LinkedField",
                      "name": "choices",
                      "plural": true,
                      "selections": (v8/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "type": "CustomFieldType",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v3/*: any*/),
                    (v5/*: any*/),
                    (v7/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "choices",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "InlineFragment",
                          "selections": (v8/*: any*/),
                          "type": "StringChoiceType",
                          "abstractKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "BuiltinFieldType",
                  "abstractKey": null
                },
                (v9/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "requiredFields",
          "plural": true,
          "selections": [
            (v6/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": (v10/*: any*/),
              "type": "CustomFieldType",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": (v10/*: any*/),
              "type": "BuiltinFieldType",
              "abstractKey": null
            },
            (v9/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ContractDateType",
      "kind": "LinkedField",
      "name": "dates",
      "plural": true,
      "selections": [
        (v3/*: any*/),
        (v0/*: any*/),
        (v1/*: any*/),
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "repeat",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "repeatEveryNum",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "recurSpan",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "recurType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "recursUntil",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numberOfOccurences",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "enableReminder",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "trackCompletion",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "protected",
          "storageKey": null
        },
        {
          "alias": "completions",
          "args": null,
          "concreteType": "DateCompletionTypeConnection",
          "kind": "LinkedField",
          "name": "__dateFragment_completions_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "DateCompletionTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "DateCompletionType",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "completedOn",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AccountType",
                      "kind": "LinkedField",
                      "name": "completedBy",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "email",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    (v6/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ReminderType",
          "kind": "LinkedField",
          "name": "reminder",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "EditDateDialog_reminderFragment"
            },
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "remindOnType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "remindOn",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "repeatEvery",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "addCustomMessage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "customMessage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "recipients",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isactive",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "attachDocument",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "includeRelatedDocuments",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BuiltinFieldValueType",
      "kind": "LinkedField",
      "name": "builtinFieldValues",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BuiltinFieldType",
          "kind": "LinkedField",
          "name": "field",
          "plural": false,
          "selections": (v11/*: any*/),
          "storageKey": null
        },
        (v12/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CustomFieldValueType",
      "kind": "LinkedField",
      "name": "customFieldValues",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CustomFieldType",
          "kind": "LinkedField",
          "name": "field",
          "plural": false,
          "selections": (v11/*: any*/),
          "storageKey": null
        },
        (v12/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "filename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "filenameOcr",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "aiValues",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "annotationsJson",
      "storageKey": null
    }
  ],
  "type": "ContractType",
  "abstractKey": null
};
})();

(node as any).hash = "2763da801dceb2b4a6d69afc0aa9c8ed";

export default node;
