import React, { createContext, useContext, type ForwardedRef } from "react";
import {
  default as Grid1,
  type GridProps as Grid1Props,
} from "@mui/material/Grid";
import {
  default as Grid2,
  type Grid2Props,
} from "@mui/material/Unstable_Grid2";

type GridVersion = "2" | "1";
/**
 * Refer to documentation of `Grid`
 */
export const GridV2OrV1Context = createContext<{
  version: GridVersion;
  showWarnings?: boolean;
}>({
  version: "1",
  showWarnings: true,
});

export type GridProps = Grid1Props | Grid2Props;

/**
 * This is a wrapper around @mui/material/Grid OR @mui/material/Unstable_Grid2.
 *
 * To use, wrap a part of the component tree in Grid2. Try to find larger chunks of the UI
 * to convert. For example, do one section of the contract page at a time. See
 * [here](https://github.com/mui/material-ui/pull/32746#issuecomment-2021917591) for more
 * details on that.
 *
 * The purpose is to ease our migration to Grid2 that will be released in MUI v6. See
 * [here](https://mui.com/material-ui/migration/migration-grid-v2/) for more details on that
 * migration.
 *
 * @example
 * import { GridV2OrV1Context } from "~/components/GridV2OrV1";
 * import Grid from "~/components/GridV2OrV1";
 *
 * // [GRID_V2_MIGRATION] when you enable this flag permanently, enable warnings and resolve
 * const useGridV2 = waffle.flag_is_active("comments_use_grid_v2");
 * const showGridV2Warnings = false;
 * <GridV2OrV1Context.Provider
 *    value={{
 *      version: useGridV2 ? "2" : "1",
 *      showWarnings: showGridV2Warnings,
 *     }}
 *   >
 *  <Grid>{children}</Grid>
 * </GridV2OrV1Context.Provider>
 *
 * @param props The grid props for the version you wish to use
 */
export const GridV2OrV1 = React.forwardRef(function GridV2OrV1(
  props: GridProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const ctx = useContext(GridV2OrV1Context);
  const { version, showWarnings = true } = ctx;

  if (version === "2") {
    // @ts-expect-error
    const { item, zeroMinWidth, ...v2Props } = props;
    if (item && showWarnings) {
      console.warn(
        `[Warning] Attempting to use a deprecated field on MUI Grid2: item`,
      );
    }

    if (zeroMinWidth && showWarnings) {
      console.warn(
        `[Warning] Attempting to use a deprecated field on MUI Grid2: zeroMinWidth`,
      );
    }

    return <Grid2 ref={ref} {...v2Props} />;
  }

  return <Grid1 ref={ref} {...props} />;
});

export default GridV2OrV1;
