/**
 * @generated SignedSource<<1b844b12d91a6530e5c83bbbb005edb6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FormSectionInput = {
  fields?: ReadonlyArray<string | null | undefined> | null | undefined;
  name: string;
};
export type EditFormDialog_Mutation$variables = {
  id?: string | null | undefined;
  name?: string | null | undefined;
  requiredFields?: ReadonlyArray<string | null | undefined> | null | undefined;
  sections?: ReadonlyArray<FormSectionInput | null | undefined> | null | undefined;
};
export type EditFormDialog_Mutation$data = {
  readonly updateForm: {
    readonly form: {
      readonly " $fragmentSpreads": FragmentRefs<"FormSelect_formFragment">;
    } | null | undefined;
    readonly organization: {
      readonly defaultForm: {
        readonly " $fragmentSpreads": FragmentRefs<"FormSelect_formFragment">;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type EditFormDialog_Mutation = {
  response: EditFormDialog_Mutation$data;
  variables: EditFormDialog_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "requiredFields"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sections"
},
v4 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      },
      {
        "kind": "Variable",
        "name": "requiredFields",
        "variableName": "requiredFields"
      },
      {
        "kind": "Variable",
        "name": "sections",
        "variableName": "sections"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v5 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "FormSelect_formFragment"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fieldType",
  "storageKey": null
},
v10 = [
  (v6/*: any*/),
  (v7/*: any*/)
],
v11 = {
  "kind": "InlineFragment",
  "selections": [
    (v6/*: any*/)
  ],
  "type": "Node",
  "abstractKey": "__isNode"
},
v12 = {
  "kind": "InlineFragment",
  "selections": (v10/*: any*/),
  "type": "FieldSetType",
  "abstractKey": null
},
v13 = [
  (v6/*: any*/),
  (v7/*: any*/),
  (v9/*: any*/)
],
v14 = [
  (v6/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "uuid",
    "storageKey": null
  },
  (v7/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "FormSectionType",
    "kind": "LinkedField",
    "name": "sections",
    "plural": true,
    "selections": [
      (v7/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "fields",
        "plural": true,
        "selections": [
          (v8/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ValueListItemType",
                "kind": "LinkedField",
                "name": "choices",
                "plural": true,
                "selections": (v10/*: any*/),
                "storageKey": null
              }
            ],
            "type": "CustomFieldType",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "choices",
                "plural": true,
                "selections": [
                  (v8/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": (v10/*: any*/),
                    "type": "StringChoiceType",
                    "abstractKey": null
                  },
                  (v11/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "BuiltinFieldType",
            "abstractKey": null
          },
          (v12/*: any*/),
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": null,
    "kind": "LinkedField",
    "name": "requiredFields",
    "plural": true,
    "selections": [
      (v8/*: any*/),
      {
        "kind": "InlineFragment",
        "selections": (v13/*: any*/),
        "type": "CustomFieldType",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": (v13/*: any*/),
        "type": "BuiltinFieldType",
        "abstractKey": null
      },
      (v12/*: any*/),
      (v11/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EditFormDialog_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "UpdateFormPayload",
        "kind": "LinkedField",
        "name": "updateForm",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FormType",
            "kind": "LinkedField",
            "name": "form",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationType",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "FormType",
                "kind": "LinkedField",
                "name": "defaultForm",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "EditFormDialog_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "UpdateFormPayload",
        "kind": "LinkedField",
        "name": "updateForm",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FormType",
            "kind": "LinkedField",
            "name": "form",
            "plural": false,
            "selections": (v14/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationType",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "FormType",
                "kind": "LinkedField",
                "name": "defaultForm",
                "plural": false,
                "selections": (v14/*: any*/),
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dc2e4e2703e844224a57f89c84944922",
    "id": null,
    "metadata": {},
    "name": "EditFormDialog_Mutation",
    "operationKind": "mutation",
    "text": "mutation EditFormDialog_Mutation(\n  $id: ID\n  $name: String\n  $sections: [FormSectionInput]\n  $requiredFields: [String]\n) {\n  updateForm(input: {id: $id, name: $name, sections: $sections, requiredFields: $requiredFields}) {\n    form {\n      ...FormSelect_formFragment\n      id\n    }\n    organization {\n      defaultForm {\n        ...FormSelect_formFragment\n        id\n      }\n      id\n    }\n  }\n}\n\nfragment FormSelect_formFragment on FormType {\n  id\n  uuid\n  name\n  sections {\n    name\n    fields {\n      __typename\n      ... on CustomFieldType {\n        id\n        name\n        fieldType\n        choices {\n          id\n          name\n        }\n      }\n      ... on BuiltinFieldType {\n        id\n        name\n        fieldType\n        choices {\n          __typename\n          ... on StringChoiceType {\n            id\n            name\n          }\n          ... on Node {\n            __isNode: __typename\n            id\n          }\n        }\n      }\n      ... on FieldSetType {\n        id\n        name\n      }\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n    }\n  }\n  requiredFields {\n    __typename\n    ... on CustomFieldType {\n      id\n      name\n      fieldType\n    }\n    ... on BuiltinFieldType {\n      id\n      name\n      fieldType\n    }\n    ... on FieldSetType {\n      id\n      name\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fe784c2623f62e94486a14ed74a6da64";

export default node;
