import React from "react";
import { useLocation } from "react-router";

/**
 * Retrieves comments related data from the window location.
 *
 * We support a `focusedCommentUuid` search parameter in the URL to focus on a
 * specific comment when loading the contract detail page This hook is used to
 * determine whether that param is present and whether we should load all
 * comments.
 */
export function useCommentsRouteLoader() {
  const location = useLocation();
  const focusedCommentUuid = new URLSearchParams(location.search).get(
    "focusedCommentUuid",
  );

  return {
    focusedCommentUuid,
    shouldLoadAllComments: focusedCommentUuid !== null,
  };
}
