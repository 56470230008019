/**
 * @generated SignedSource<<217cda65a1f62232eb7f304bf76ed4b9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type relatedDocuments_Query$variables = {
  uuid: string;
};
export type relatedDocuments_Query$data = {
  readonly contract: {
    readonly uuid: string;
  } | null | undefined;
};
export type relatedDocuments_Query = {
  response: relatedDocuments_Query$data;
  variables: relatedDocuments_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "uuid"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "uuid",
    "variableName": "uuid"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "relatedDocuments_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ContractType",
        "kind": "LinkedField",
        "name": "contract",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "relatedDocuments_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ContractType",
        "kind": "LinkedField",
        "name": "contract",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f89223dadb7470516518434ec953dec1",
    "id": null,
    "metadata": {},
    "name": "relatedDocuments_Query",
    "operationKind": "query",
    "text": "query relatedDocuments_Query(\n  $uuid: String!\n) {\n  contract(uuid: $uuid) {\n    uuid\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "0dc8c40a14e0164d39b4d0aa7350017d";

export default node;
