import React from "react";
import { graphql, useMutation } from "react-relay";
import type {
  useAddContractFileViaLinkMutation_addContractFileViaLinkMutation,
  useAddContractFileViaLinkMutation_addContractFileViaLinkMutation$data,
} from "./__generated__/useAddContractFileViaLinkMutation_addContractFileViaLinkMutation.graphql";
import { captureException } from "@sentry/react";

import type { GraphQLResultData } from "~/utils/types";

const ADD_CONTRACT_FILE_VIA_LINK_MUTATION = graphql`
  mutation useAddContractFileViaLinkMutation_addContractFileViaLinkMutation(
    $contractUuid: String!
    $url: String!
  ) {
    addContractFileViaLink(input: { contractUuid: $contractUuid, url: $url }) {
      __typename
      ... on AddContractFileViaLinkSuccessResultType {
        contract {
          id
          uuid
          filename
        }
      }
      ... on AddContractFileViaLinkErrorType {
        debugMessage
        path
        reasonCode
      }
      ... on ContractNotFoundErrorType {
        debugMessage
      }
      ... on UnauthorizedError {
        debugMessage
        userMessage
      }
    }
  }
`;

export function useAddContractFileViaLinkMutation() {
  const [
    commitAddContractFileViaLinkMutation,
    isAddContractFileViaLinkMutationInFlight,
  ] =
    useMutation<useAddContractFileViaLinkMutation_addContractFileViaLinkMutation>(
      ADD_CONTRACT_FILE_VIA_LINK_MUTATION,
    );

  function handleSubmit(
    contractUuid: string,
    url: string,
    handlers?: {
      onResultError?: (
        result: GraphQLResultData<
          useAddContractFileViaLinkMutation_addContractFileViaLinkMutation$data["addContractFileViaLink"],
          "AddContractFileViaLinkErrorType"
        >,
      ) => void;
      onSuccess?: (
        response: useAddContractFileViaLinkMutation_addContractFileViaLinkMutation$data,
      ) => void;
      onError?: (error: Error | unknown) => void;
    },
  ) {
    try {
      commitAddContractFileViaLinkMutation({
        variables: {
          contractUuid,
          url: url,
        },
        onCompleted: (response) => {
          const resultType = response.addContractFileViaLink?.__typename;

          switch (resultType) {
            case "AddContractFileViaLinkSuccessResultType":
              console.info("Successfully added contract file via link.");
              handlers?.onSuccess?.(response);
              return;
            case "AddContractFileViaLinkErrorType":
              handlers?.onResultError?.(response.addContractFileViaLink);
              return;
            case "ContractNotFoundErrorType":
            case "%other":
            case "UnauthorizedError":
              console.error(
                "Unexpected GraphQL response. response: ",
                response,
              );
              throw new Error("AddContractFileViaLinkError");
            default:
              return resultType satisfies never;
          }
        },
        onError: (error) => {
          throw new Error("AddContractFileViaLinkError", { cause: error });
        },
      });
    } catch (error) {
      console.error(
        "Unexpected error adding contract file via link. error: ",
        error,
      );
      captureException(error);
      handlers?.onError?.(error);
    }
  }

  return {
    handleSubmit,
    isAddContractFileViaLinkMutationInFlight,
  };
}
