import React, { Suspense, useState, useEffect, useRef } from "react";
import { useParams, useLocation } from "react-router";
import { ErrorBoundary } from "react-error-boundary";
import { useLazyLoadQuery } from "react-relay";
import { useCookies } from "react-cookie";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import PDFViewer from "../../components/PDFViewer";

function PageContent() {
  const { uuid } = useParams();
  const { contract, account } = useLazyLoadQuery(
    graphql`
      query ViewerWindow_Query($uuid: String!) {
        contract(uuid: $uuid) {
          filenameOcr
          ...ContractFragment_fragment @relay(mask: false)
        }
        account {
          invitedRole
          canDelete
          isAdmin
          isAcmanager
          readOnly
          organization {
            notesOverViewer
          }
          ...PDFViewer_accountFragment
        }
      }
    `,
    { uuid },
  );

  useEffect(() => {
    async function prerenderDocument() {
      var ocrDoc = contract.filenameOcr ? true : false;
      const response = await fetch(
        `/api/v1/document_access/${contract.uuid}?ocr=${ocrDoc}&prerender=true`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
    }
    prerenderDocument();
  }, []);

  return <PDFViewer contract={contract} account={account} />;
}

function PageContentAttch() {
  const { uuid } = useParams();
  return <PDFViewer attchuuid={uuid} />;
}

function ErrorHandler({ children }) {
  const location = useLocation();
  const [cookies, setCookie, removeCookie] = useCookies([]);

  return (
    <ErrorBoundary
      FallbackComponent={({ error }) => {
        if (error.message == "Not Logged In") {
          window.location.href = "/login?next=" + location.pathname;
        }
        return (
          <div>
            <Grid container>
              <Grid item xs={12}>
                {error.message}
              </Grid>
            </Grid>
          </div>
        );
      }}
      onError={(err) => console.log("Error:", err, err?.graphqlErrors)}
    >
      <Suspense fallback={<CircularProgress disableShrink />}>
        {children}
      </Suspense>
    </ErrorBoundary>
  );
}

export default function ViewerWindow({ attachment = false }) {
  return (
    <Grid
      container
      item
      align="center"
      justifyContent="center"
      alignItems="center"
    >
      <ErrorHandler>
        {attachment ? <PageContentAttch /> : <PageContent />}
      </ErrorHandler>
    </Grid>
  );
}
