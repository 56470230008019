export function isNonNullable<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

export type Maybe<T> = null | undefined | T;

export type GraphQLResultData<MutationData, __typename> = Extract<
  NonNullable<MutationData>,
  { __typename: __typename }
>;

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;
