export function stripFragmentProps(x) {
  if (!x) {
    return x;
  }
  const {
    __fragmentOwner,
    __fragments,
    __id,
    __isWithinUnmatchedTypeRefinement,
    ...r
  } = x;
  return r;
}
