/**
 * @generated SignedSource<<2b14d56cc4cd8081ee3bfb09996b8db8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserCommentContent_currentUserAccountFragment$data = {
  readonly organization: {
    readonly " $fragmentSpreads": FragmentRefs<"CommentTextEditor_organizationFragment">;
  };
  readonly uuid: string;
  readonly " $fragmentType": "UserCommentContent_currentUserAccountFragment";
};
export type UserCommentContent_currentUserAccountFragment$key = {
  readonly " $data"?: UserCommentContent_currentUserAccountFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserCommentContent_currentUserAccountFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserCommentContent_currentUserAccountFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uuid",
      "storageKey": null
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": "OrganizationType",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CommentTextEditor_organizationFragment"
          }
        ],
        "storageKey": null
      },
      "action": "THROW",
      "path": "organization"
    }
  ],
  "type": "AccountType",
  "abstractKey": null
};

(node as any).hash = "0d55b410f2965c80aebcdbcb617e3da7";

export default node;
