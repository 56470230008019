import React, { useCallback, useEffect, useState } from "react";
import useAsyncMutation from "~/utils/UseAsyncMutation";
import { parseISO, format as formatDate } from "date-fns";
import Notifications from "@mui/icons-material/Notifications";
import NotificationsOff from "@mui/icons-material/NotificationsOff";
import LinkIcon from "@mui/icons-material/Link";
import Replay from "@mui/icons-material/Replay";
import { DateField } from "~/components/Field";
import EditDateDialog from "~/components/EditDateDialog";
import Button from "~/components/Button";
import SubInputCheckbox from "~/components/SubInputCheckbox";
import useDebouncedCommit from "~/utils/UseDebouncedCommit";
import TruncateWithTooltip from "~/components/TruncateWithTooltip";
import { InputLabel } from "~/components/Field";
import { debounce, noop } from "lodash";

export default function DateAndReminder({
  contractUuid,
  date,
  setDate,
  isInFlight,
  noReminders,
  format,
  userEmail,
  onCommit,
  dateType,
  field,
  required,
  error,
  disabled,
  annotations,
  onJumpClick,
  children,
  ...props
}) {
  const [reminderOpenFor, setReminderOpenFor] = useState(null);
  const [enablingReminder, setEnablingReminder] = useState(false);

  const setDateFunc = setDate || noop;

  const debouncedCommit = useCallback(debounce(setDateFunc, DEBOUNCE_DELAY), [
    setDateFunc,
  ]);
  const [dateValue, setDateValue] = useState(parseNullableDate(date.date));

  useEffect(() => {
    setDateValue(parseNullableDate(date.date));
  }, [date.date]);

  const [commitMarkComplete] = useAsyncMutation(graphql`
    mutation DateAndReminder_MarkCompleteMutation($dateUuid: String!) {
      markContractDateComplete(input: { dateUuid: $dateUuid }) {
        date {
          ...EditDateDialog_dateFragment
        }
      }
    }
  `);

  const [commitUnmarkComplete] = useAsyncMutation(graphql`
    mutation DateAndReminder_UnmarkCompleteMutation($dateUuid: String!) {
      unmarkContractDateComplete(input: { dateUuid: $dateUuid }) {
        date {
          ...EditDateDialog_dateFragment
        }
      }
    }
  `);

  const completion = date.completions?.edges[0]?.node;
  function handleChangeCompletion() {
    const variables = { dateUuid: date.uuid };
    if (!completion) {
      commitMarkComplete({ variables });
    } else {
      commitUnmarkComplete({ variables });
    }
  }

  const annotation =
    !!annotations && Object.keys(annotations).length > 0
      ? annotations.filter(
          (anno) =>
            anno.customData?.fieldId &&
            (anno.customData?.fieldId === date.uuid ||
              anno.customData?.fieldId === field?.id),
        )
      : [];

  function defaultLabelComponent(props) {
    return (
      <TruncateWithTooltip
        component={InputLabel}
        required={required}
        error={error}
        {...props}
      />
    );
  }

  return (
    <>
      <DateField
        label={props.label || date.label}
        labelComponent={props.labelComponent || defaultLabelComponent}
        format={format}
        dateid={date.id}
        name={props.name || ""}
        icons={
          date.uuid ? (
            <>
              {annotation?.length > 0 && (
                <Button
                  variant="text"
                  size="tiny"
                  onClick={() => onJumpClick(annotation[0])}
                  label={<LinkIcon fontSize="small" color="primary" />}
                />
              )}
              {date.date && date.repeat ? (
                <Button
                  variant="text"
                  size="tiny"
                  onClick={() => {
                    setEnablingReminder(false);
                    setReminderOpenFor(date);
                  }}
                  label={
                    <Replay
                      fontSize="small"
                      color={disabled ? "disabled" : "secondary"}
                    />
                  }
                  disabled={disabled}
                />
              ) : null}
              {noReminders ? null : (
                <Button
                  variant="text"
                  size="tiny"
                  onClick={() => {
                    if (date.enableReminder) {
                      setEnablingReminder(false);
                    } else {
                      setEnablingReminder(true);
                    }
                    setReminderOpenFor(date);
                  }}
                  data-testid="dateAndReminderReminderButton"
                  label={
                    date.date && date.enableReminder ? (
                      <Notifications
                        fontSize="small"
                        color={disabled ? "disabled" : "secondary"}
                        data-testid={
                          disabled
                            ? "dateAndReminderReminderNotificationsDisabledIcon"
                            : "dateAndReminderReminderNotificationsEnabledIcon"
                        }
                      />
                    ) : (
                      <NotificationsOff
                        fontSize="small"
                        color="disabled"
                        data-testid="dateAndReminderReminderNotificationsOffIcon"
                      />
                    )
                  }
                  disabled={disabled}
                />
              )}
            </>
          ) : null
        }
        value={dateValue}
        onChange={(d) => {
          const newDate = {
            uuid: date.uuid,
            date: graphqlDate(d),
          };
          setDateValue(d);
          if (d != "Invalid Date") {
            debouncedCommit(newDate);
          }
        }}
        disabled={disabled}
        onBlur={debouncedCommit.flush}
        onClose={debouncedCommit.flush}
        {...props}
      />
      {children}
      {date.trackCompletion && !date.repeat ? (
        <SubInputCheckbox
          checked={!!completion}
          onChange={handleChangeCompletion}
          onClick={handleChangeCompletion}
          label="Completed"
          title={
            completion
              ? `${completion.completedBy.email} - ${formatDate(
                  parseISO(completion.completedOn),
                  format,
                )}`
              : null
          }
        />
      ) : null}
      {reminderOpenFor ? (
        <EditDateDialog
          openFor={reminderOpenFor}
          setOpenFor={setReminderOpenFor}
          enablingReminder={enablingReminder}
          onCommit={onCommit}
          contractUuid={contractUuid}
          userEmail={userEmail}
          dateFormat={format}
          dateType={dateType}
          dnr={props?.name == "deadline_to_nonrenew"}
          dnrAutoRenew={props.dnrAutoRenew}
        />
      ) : null}
    </>
  );
}
