import React from "react";
import { graphql } from "relay-runtime";
import useAsyncMutation from "~/utils/UseAsyncMutation";
import { track } from "~/analytics/events";
import ConfirmDialog from "~/components/ConfirmDialog";
import type { DeleteDialog_DeleteMutation } from "./__generated__/DeleteDialog_DeleteMutation.graphql";

type DeleteDialogProps = {
  contractUuid: string;
  open: boolean;
  handleClose: () => void;
};

export function DeleteDialog(props: DeleteDialogProps) {
  const { contractUuid, open, handleClose } = props;
  const [commitDelete] = useAsyncMutation<DeleteDialog_DeleteMutation>(graphql`
    mutation DeleteDialog_DeleteMutation($uuid: String!) {
      deleteContract(input: { uuid: $uuid }) {
        clientMutationId
      }
    }
  `);

  async function handleConfirm() {
    try {
      await commitDelete({ variables: { uuid: contractUuid } });
      track("Contract Deleted");
      window.location.href = "/contracts";
    } catch (error) {
      console.error("Failed to delete contract:", error);
      throw error;
    }
  }

  return (
    <ConfirmDialog
      title="Delete Contract?"
      open={open}
      onClose={handleClose}
      onConfirm={handleConfirm}
      noText="Cancel"
      yesText="Delete"
    >
      Are you sure you want to delete this contract? Deleted contracts cannot be
      restored.
    </ConfirmDialog>
  );
}
