import { List, Set } from "immutable";
import React, { useState, useEffect, useRef, Suspense } from "react";
import {
  graphql,
  useFragment,
  useLazyLoadQuery,
  useRefetchableFragment,
} from "react-relay";
import useAsyncMutation from "~/utils/UseAsyncMutation";
import LinearProgress from "@mui/material/LinearProgress";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ToggleButton from "@mui/material/ToggleButton";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import SortableTree from "react-sortable-tree";
import MaterialTheme from "react-sortable-tree-theme-material-ui";
import Clear from "@mui/icons-material/Clear";
import DragIndicator from "@mui/icons-material/DragIndicator";
import { TextField } from "~/components/Field";
import Button from "~/components/Button";
import FormSelect, { FORM_FRAGMENT } from "./FormSelect";
import ConfirmDialog from "./ConfirmDialog";
import LeftDialogActions from "./LeftDialogActions";
import SearchField from "~/components/SearchField";
import LabeledItem, {
  InputLabel,
  WrappingInputLabel,
  GroupInputLabel,
} from "~/components/LabeledItem";
import { useFeatures } from "~/features";
import "react-sortable-tree/style.css";

function RenameFormDialog({ open, handleClose, handleRename, ...props }) {
  const [formName, setFormName] = useState(props.formName);
  useEffect(() => setFormName(props.formName), [props.formName]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Rename Form</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={1} alignItems="center" style={{ width: 325 }}>
          <Grid item xs={8}>
            <TextField
              value={formName}
              onChange={({ target: { value } }) => setFormName(value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button size="small" onClick={handleClose}>
          Cancel
        </Button>
        <Button primary size="small" onClick={() => handleRename(formName)}>
          Rename
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function DeleteFormDialog({ form, open, handleClose }) {
  const [commitDelete] = useAsyncMutation(graphql`
    mutation EditFormDialog_deleteFormMutation($id: ID!) {
      deleteForm(input: { id: $id }) {
        organization {
          forms {
            ...FormSelect_formFragment
          }
        }
      }
    }
  `);

  async function handleConfirm() {
    handleClose(true);
    await commitDelete({
      variables: { id: form.id },
    });
  }

  return (
    <ConfirmDialog
      title="Delete Form?"
      open={open}
      onClose={() => handleClose(false)}
      onConfirm={handleConfirm}
      noText="Cancel"
      yesText="Delete"
    >
      Are you sure you want to delete the "{form?.name}" form? Deleted forms
      cannot be restored.
    </ConfirmDialog>
  );
}

export function LoadedEditFormDialogContents({
  open,
  handleClose,
  onCommit = () => null,
  currentFormId,
  refetchOnOpen,
  formSelectInTitle,
  ...props
}) {
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [renameOpen, setRenameOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [formId, setFormId] = useState(currentFormId ?? null);
  useEffect(() => setFormId(currentFormId), [currentFormId, open]);

  const { account, fieldSets, builtinFields, defaultFormSections } =
    useLazyLoadQuery(graphql`
      query EditFormDialog_Query {
        account {
          ...EditFormDialog_accountFragment
          ...FormSelect_accountFragment
        }
        fieldSets {
          id
          name
        }
        builtinFields {
          id
          name
          fieldType
        }
        defaultFormSections {
          ...FormSelect_formSectionFragment @relay(mask: false)
        }
      }
    `);

  useEffect(() => {
    if (refetchOnOpen) {
      refetch({}, { fetchPolicy: "network-only" });
    }
  }, [open]);

  const [
    {
      isAdmin,
      isAcmanager,
      organization: { customFields, defaultForm, forms, requiredFieldsEnabled },
    },
    refetch,
  ] = useRefetchableFragment(
    graphql`
      fragment EditFormDialog_accountFragment on AccountType
      @refetchable(queryName: "EditFormDialogRefetchQuery") {
        isAdmin
        isAcmanager
        organization {
          customFields {
            id
            name
            fieldType
          }
          defaultForm {
            ...FormSelect_formFragment @relay(mask: false)
          }
          forms {
            ...FormSelect_formFragment
          }
          requiredFieldsEnabled
        }
      }
    `,
    account,
  );

  const formsById = Object.fromEntries(
    useFragment(FORM_FRAGMENT, forms).map((x) => [x.id, x]),
  );
  useEffect(() => {
    if (!Object.keys(formsById).includes(formId)) {
      setFormId(null);
    }
  }, [formsById]);
  const form = formsById[formId] || defaultForm;
  const [requiredFields, setRequiredFields] = useState(
    Set(form?.requiredFields.map((x) => x.id)),
  );
  useEffect(() => {
    setRequiredFields(Set(form?.requiredFields.map((x) => x.id)));
  }, [formId]);

  const [commitForm] = useAsyncMutation(graphql`
    mutation EditFormDialog_Mutation(
      $id: ID
      $name: String
      $sections: [FormSectionInput]
      $requiredFields: [String]
    ) {
      updateForm(
        input: {
          id: $id
          name: $name
          sections: $sections
          requiredFields: $requiredFields
        }
      ) {
        form {
          ...FormSelect_formFragment
        }
        organization {
          defaultForm {
            ...FormSelect_formFragment
          }
        }
      }
    }
  `);

  function buildTree() {
    return (form?.sections || defaultFormSections).map((section, i) => ({
      id: i,
      section,
      expanded: true,
      children:
        section.fields
          ?.filter((f) => {
            return f?.id;
          })
          .map((field) => ({
            id: field.id,
            field,
          })) || [],
    }));
  }
  const [tree, setTree] = useState(buildTree());

  useEffect(() => {
    setTree(buildTree());
  }, [form]);

  const features = useFeatures();

  const usedFields = new Set(
    tree?.map((node) => (node.children || []).map((c) => c.field.id)).flat(),
  );
  const unusedFields = [
    ...List(
      [...fieldSets, ...builtinFields, ...customFields].filter(
        (x) =>
          !usedFields.has(x.id) &&
          x.name.toLowerCase().includes(search.toLowerCase()),
      ),
    ).sort((a, b) => friendlySort(a.name, b.name)),
  ];

  async function handleSaveForm() {
    if (handleClose) {
      handleClose();
    }

    // this prevents keeping required fields that aren't included in the form
    const usedRequiredFields = requiredFields.intersect(usedFields);

    try {
      await commitForm({
        variables: {
          id: formId,
          sections: tree.map((x) => ({
            ...x.section,
            fields: x.children?.map((child) => child.field.id),
          })),
          requiredFields: [...usedRequiredFields],
        },
      });
      onCommit("Form saved.");
    } catch (e) {
      console.log(e);
      onCommit(e.message);
    }
  }

  async function handleRenameForm(formName) {
    try {
      await commitForm({
        variables: {
          id: formId,
          name: formName,
        },
      });
    } catch (e) {
      console.log(e);
      onCommit(e.message);
    }
  }

  function removeFromTree(node) {
    const newTree = tree
      .filter((x) => x != node)
      .map((x) => ({ ...x, children: x.children?.filter((c) => c != node) }));
    setTree(newTree);
  }

  function handleCanDrop({ node, prevParent, nextParent }) {
    if (node.section && !nextParent) {
      // sections belong at the top level
      return true;
    }
    if (node.field && nextParent?.section) {
      // fields can go directly under sections
      return true;
    }
    // all other situations are wrong
    return false;
  }

  function handleRenameSection(node, value) {
    setTree(
      tree.map((x) =>
        x === node
          ? {
              ...node,
              section: {
                ...node.section,
                name: value,
              },
            }
          : x,
      ),
    );
  }

  if (!open) {
    return null;
  }

  return (
    <DndProvider backend={HTML5Backend}>
      {formSelectInTitle ? (
        <DialogTitle id="form-dialog-title">
          <Grid container spacing={2} alignItems="center">
            <Grid item>Edit Form</Grid>
            <Grid item>
              <FormSelect
                allowAdd={features.customContractFields}
                account={account}
                value={formId}
                onChange={setFormId}
              />
            </Grid>
          </Grid>
        </DialogTitle>
      ) : null}
      <DialogContent dividers>
        {!formSelectInTitle ? (
          <Grid
            container
            spacing={2}
            alignItems="center"
            sx={{ marginBottom: 2 }}
          >
            <Grid item>
              <InputLabel>Form</InputLabel>
            </Grid>
            <Grid item>
              <FormSelect
                allowAdd={features.customContractFields}
                account={account}
                value={formId}
                onChange={setFormId}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
        ) : null}
        {form?.name?.toLowerCase() == "all fields"
          ? "This preset form cannot be edited. Please select a different form."
          : null}
        <Grid
          container
          spacing={2}
          style={
            form?.name?.toLowerCase() == "all fields"
              ? { display: "none" }
              : null
          }
        >
          <Grid item xs={6} style={{ height: 400, overflow: "auto" }}>
            <SortableTree
              generateNodeProps={({ node, path }) => ({
                buttons: [
                  ...(node.section ||
                  node.field.id == "fieldset:contractDates" ||
                  ["b", "l"].includes(node.field.fieldType) ||
                  !requiredFieldsEnabled
                    ? []
                    : [
                        <ToggleButton
                          value="checked"
                          selected={requiredFields.has(node.field.id)}
                          onChange={() =>
                            !requiredFields.has(node.field.id)
                              ? setRequiredFields(
                                  requiredFields.add(node.field.id),
                                )
                              : setRequiredFields(
                                  requiredFields.delete(node.field.id),
                                )
                          }
                          size="small"
                          sx={{
                            paddingTop: 0,
                            paddingBottom: 0,
                            textTransform: "none",
                          }}
                        >
                          Req
                        </ToggleButton>,
                      ]),
                  <Button variant="text" onClick={() => removeFromTree(node)}>
                    <Clear />
                  </Button>,
                ],
                title: node.section ? (
                  <TextField
                    value={node.section.name}
                    onChange={(e) => handleRenameSection(node, e.target.value)}
                  />
                ) : (
                  <InputLabel
                    required={requiredFields.has(node.field.id)}
                    error={requiredFields.has(node.field.id)}
                  >
                    {node.field.name}
                  </InputLabel>
                ),
              })}
              isVirtualized={false}
              treeData={tree}
              getNodeKey={({ node }) => node.id}
              onChange={setTree}
              canDrop={handleCanDrop}
              canNodeHaveChildren={(node) => node.section?.fields}
              dndType="field"
            />
            <Button
              variant="text"
              size="tiny"
              label="Add Section"
              onClick={() =>
                setTree([
                  ...tree,
                  {
                    id: tree.length,
                    section: { name: "", fields: [] },
                    expanded: true,
                    children: [],
                  },
                ])
              }
            />
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={4}>
              <Grid item xs={1}>
                <Divider orientation="vertical" />
              </Grid>
              <Grid item xs={11} style={{ height: 400, overflow: "auto" }}>
                <SearchField search={search} setSearch={setSearch} />
                <SortableTree
                  generateNodeProps={({ node, path }) => ({
                    icons: <DragIndicator />,
                    title: <span>{node.field.name}</span>,
                  })}
                  isVirtualized={false}
                  treeData={unusedFields.map((field) => ({
                    id: field.id,
                    field,
                  }))}
                  onChange={() => null}
                  theme={MaterialTheme}
                  canNodeHaveChildren={() => false}
                  dndType="field"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {(isAdmin || isAcmanager) &&
        form?.id != defaultForm?.id &&
        form?.name?.toLowerCase() != "all fields" ? (
          <LeftDialogActions>
            <Button size="small" onClick={() => setDeleteOpen(true)}>
              Delete Form
            </Button>
            <Button size="small" onClick={() => setRenameOpen(true)}>
              Rename Form
            </Button>
          </LeftDialogActions>
        ) : null}
        {handleClose ? (
          <Button size="small" onClick={handleClose}>
            Cancel
          </Button>
        ) : null}
        <Button size="small" onClick={handleSaveForm} primary>
          Save Form
        </Button>
      </DialogActions>
      <DeleteFormDialog
        form={form}
        open={deleteOpen}
        handleClose={(closeParent) => {
          setDeleteOpen(false);
          if (closeParent && handleClose) {
            handleClose();
          }
        }}
      />
      <RenameFormDialog
        formName={form?.name}
        open={renameOpen}
        handleRename={(v) => {
          handleRenameForm(v);
          setRenameOpen(false);
        }}
        handleClose={() => setRenameOpen(false)}
      />
    </DndProvider>
  );
}

function LoadedEditFormDialog({ open, handleClose, ...props }) {
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <LoadedEditFormDialogContents
        open={open}
        handleClose={handleClose}
        formSelectInTitle
        {...props}
      />
    </Dialog>
  );
}

export default function EditFormDialog({ open, ...props }) {
  return open ? <LoadedEditFormDialog open={!!open} {...props} /> : null;
}
