import React from "react";
import { useEditor, type EditorOptions, type Editor } from "@tiptap/react";
import { useEffect, useRef } from "react";
import type { Except, SetRequired } from "type-fest";
import {
  RichTextContent,
  RichTextEditorProvider,
  type RichTextContentProps,
} from "mui-tiptap";

export type RichTextReadOnlyEditorProps = SetRequired<
  Partial<Except<EditorOptions, "editable">>,
  "extensions"
>;

export type RichTextReadOnlyProps = {
  editor: Editor;
  RichTextContentProps?: RichTextContentProps;
};

export function RichTextReadOnly(props: RichTextReadOnlyProps) {
  return (
    <RichTextEditorProvider editor={props.editor}>
      <RichTextContent {...props.RichTextContentProps} />
    </RichTextEditorProvider>
  );
}

export function useReadOnlyEditor(editorProps: RichTextReadOnlyEditorProps) {
  const editor = useEditor({
    ...editorProps,
    editable: false,
  });

  // Update content if/when it changes
  const previousContent = useRef(editorProps.content);
  useEffect(() => {
    if (
      !editor ||
      editor.isDestroyed ||
      editorProps.content === undefined ||
      editorProps.content === previousContent.current
    ) {
      return;
    }
    // We use queueMicrotask to avoid any flushSync console errors as
    // mentioned here
    // https://github.com/ueberdosis/tiptap/issues/3764#issuecomment-1546854730
    queueMicrotask(() => {
      // Validate that editorProps.content isn't undefined again to appease TS
      if (editorProps.content !== undefined) {
        editor.commands.setContent(editorProps.content);
      }
    });
  }, [editorProps.content, editor]);

  useEffect(() => {
    previousContent.current = editorProps.content;
  }, [editorProps.content]);

  return editor;
}
