/**
 * @generated SignedSource<<05e4e5b86b59bbf2fd288e7820ae6250>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommentList_currentUserAccountFragment$data = {
  readonly uuid: string;
  readonly " $fragmentSpreads": FragmentRefs<"UserComment_currentUserAccountFragment">;
  readonly " $fragmentType": "CommentList_currentUserAccountFragment";
};
export type CommentList_currentUserAccountFragment$key = {
  readonly " $data"?: CommentList_currentUserAccountFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommentList_currentUserAccountFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommentList_currentUserAccountFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uuid",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserComment_currentUserAccountFragment"
    }
  ],
  "type": "AccountType",
  "abstractKey": null
};

(node as any).hash = "f2b342bc48f7c26d583327747a28acd8";

export default node;
