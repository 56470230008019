import React, { useState, useCallback, useEffect } from "react";
import { graphql, useFragment } from "react-relay";
import useAsyncMutation from "~/utils/UseAsyncMutation";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { AccordionPaper, AccordionPaperSummary } from "~/components/Paper";
import { TextField } from "~/components/Field";
import useDebouncedCommit from "~/utils/UseDebouncedCommit";
import type { Notes_fragment$key } from "./__generated__/Notes_fragment.graphql";
import type { SxProps, Theme } from "@mui/material";

type NotesProps = {
  contract: Notes_fragment$key;
  onCommit: (error?: string) => void;
  notesOverViewer?: boolean;
  disabled?: boolean;
  sx?: SxProps<Theme>;
};

export default function Notes({
  contract: contractFragRef,
  onCommit,
  notesOverViewer,
  disabled,
  sx,
  ...props
}: NotesProps) {
  const {
    id: contractId,
    uuid,
    notes,
  } = useFragment(NOTES_FRAGMENT, contractFragRef);

  const [expanded, setExpanded] = useState(!!notes);
  useEffect(() => setExpanded(!!notes), [uuid]);

  const [commit, isInFlight] = useAsyncMutation(graphql`
    mutation Notes_notesMutation($uuid: String!, $notes: String) {
      updateContract(input: { uuid: $uuid, notes: $notes }) {
        contract {
          id
          notes
        }
      }
    }
  `);

  const commitCallback = useCallback(
    async (...args: any[]) => {
      await commit(...args);
      onCommit();
    },
    [onCommit],
  );

  const [value, setValue, debouncedCommit, flushDebouncedCommit] =
    useDebouncedCommit(commitCallback, isInFlight, notes, [uuid]);

  const [commitExpand] = useAsyncMutation(graphql`
    mutation Notes_viewState_Mutation($uuid: String!, $value: Boolean) {
      updateViewState(
        input: { uuid: $uuid, statename: "expandnote", stateboolvalue: $value }
      ) {
        contract {
          detailViewState {
            expandnote
          }
        }
      }
    }
  `);

  const handleExpanded = useCallback(
    async (value: boolean) => {
      setExpanded(value);
      await commitExpand({ variables: { uuid, value } });
    },
    [uuid],
  );

  function handleChange({ target: { value } }: any) {
    setValue(value);
    debouncedCommit({
      variables: { uuid, notes: value },
      optimisticResponse: {
        updateContract: { contract: { id: contractId, notes: value } },
      },
    });
  }

  return (
    <AccordionPaper
      expanded={expanded}
      sx={{ height: expanded ? "fit-content" : "auto", width: "100%", ...sx }}
      onChange={() => handleExpanded(!expanded)}
      data-testid="notes-section"
    >
      <AccordionPaperSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container item spacing={6} xs={12}>
          <Grid item>
            <Typography variant="h5">Notes</Typography>
          </Grid>
          {/*
          <Grid item>
            <TextField
              placeholder="Search"
              style={{ position: "relative", top: -5 }}
            />
          </Grid>*/}
        </Grid>
      </AccordionPaperSummary>
      <AccordionDetails>
        <Grid container spacing={8}>
          <Grid item xs={12} style={{ marginTop: 12 }}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <TextField
              multiline
              minRows={8}
              maxRows={notesOverViewer ? 8 : 32}
              placeholder="Add notes"
              value={value}
              onChange={handleChange}
              onBlur={flushDebouncedCommit}
              disabled={disabled}
              data-testid="notes-text-input"
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </AccordionPaper>
  );
}

export const NOTES_FRAGMENT = graphql`
  fragment Notes_fragment on ContractType {
    id
    uuid
    notes
  }
`;
