/**
 * @generated SignedSource<<390487c57ce15f7e66b75368863a82a3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SendContractDialog_accountFragment$data = {
  readonly canSendToDocusign: boolean;
  readonly email: string;
  readonly " $fragmentType": "SendContractDialog_accountFragment";
};
export type SendContractDialog_accountFragment$key = {
  readonly " $data"?: SendContractDialog_accountFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SendContractDialog_accountFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SendContractDialog_accountFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canSendToDocusign",
      "storageKey": null
    }
  ],
  "type": "AccountType",
  "abstractKey": null
};

(node as any).hash = "3a09039e464348f583ea3cf8c1492d80";

export default node;
