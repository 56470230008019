/**
 * @generated SignedSource<<e8b21642da50ae5cd9f226d27dae627a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useContractPageHeaderActionsPermissions_accountFragment$data = {
  readonly id: string;
  readonly roleInfo: {
    readonly canDelete: boolean;
    readonly invitedRole: string | null | undefined;
    readonly isAcmanager: boolean;
    readonly isAdmin: boolean;
    readonly readOnly: boolean;
  };
  readonly " $fragmentType": "useContractPageHeaderActionsPermissions_accountFragment";
};
export type useContractPageHeaderActionsPermissions_accountFragment$key = {
  readonly " $data"?: useContractPageHeaderActionsPermissions_accountFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useContractPageHeaderActionsPermissions_accountFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "contractUuid"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "useContractPageHeaderActionsPermissions_accountFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "forcontract",
            "variableName": "contractUuid"
          }
        ],
        "concreteType": "RoleInfoType",
        "kind": "LinkedField",
        "name": "roleInfo",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "invitedRole",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canDelete",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isAdmin",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isAcmanager",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "readOnly",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      "action": "THROW",
      "path": "roleInfo"
    }
  ],
  "type": "AccountType",
  "abstractKey": null
};

(node as any).hash = "83e57b4a29b072004fe89c43301e2cb7";

export default node;
