import React from "react";
import SvgIcon, { type SvgIconProps } from "@mui/material/SvgIcon";

export function PinnedCommentIcon(props: SvgIconProps) {
  return (
    <SvgIcon data-testid="pinned-comment-icon">
      <path
        d="M16.4321 4.07835C15.8068 3.45303 14.7477 3.65701 14.3993 4.46984L12.6399 8.57507C11.1944 8.14679 9.56525 8.50284 8.42364 9.64446L7.0671 11.001C6.7742 11.2939 6.7742 11.7688 7.0671 12.0617L9.20887 14.2034L3.46967 19.9426C3.17678 20.2355 3.17678 20.7104 3.46967 21.0033C3.76256 21.2962 4.23744 21.2962 4.53033 21.0033L10.2695 15.2641L12.4114 17.4059C12.7042 17.6988 13.1791 17.6988 13.472 17.4059L14.8285 16.0494C15.9702 14.9078 16.3262 13.2786 15.8979 11.8331L20.0032 10.0737C20.816 9.72533 21.02 8.66619 20.3946 8.04087L16.4321 4.07835Z"
        fill="#2454DE"
      />
    </SvgIcon>
  );
}
