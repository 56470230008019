import React from "react";
import Box from "@mui/material/Box";

export const boxMargin = 3;

export function FieldBox(allProps) {
  const { width, children, ...props } = allProps;
  return (
    // breakInside prevents fields being split up (sometimes in the middle
    // of a line of text!) when printing to PDF, e.g. for ContractSummary.
    <Box
      width={
        width || {
          xs: 126,
          tp: 181,
          ph: 370,
          sm: 215,
          md: 250,
          lg: 370,
          lg2: 200,
          lg3: 215,
          xl: 250,
        }
      }
      m={boxMargin}
      style={{ breakInside: "avoid", ...props.style }}
      {...props}
    >
      {children}
    </Box>
  );
}

export function GroupBox({ children, ...props }) {
  return (
    <Box
      display="flex"
      flexDirection="row"
      flexWrap="wrap"
      justifyContent="flex-start"
      mx={-boxMargin}
      {...props}
    >
      {children}
    </Box>
  );
}
