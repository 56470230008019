/**
 * @generated SignedSource<<e3612b90641e27654642147ca5b206f2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type PDFViewer_CurrencyFieldQuery$variables = Record<PropertyKey, never>;
export type PDFViewer_CurrencyFieldQuery$data = {
  readonly currencies: ReadonlyArray<string | null | undefined> | null | undefined;
};
export type PDFViewer_CurrencyFieldQuery = {
  response: PDFViewer_CurrencyFieldQuery$data;
  variables: PDFViewer_CurrencyFieldQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currencies",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PDFViewer_CurrencyFieldQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PDFViewer_CurrencyFieldQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "9d6e75080ea666fd790aded0321eab6e",
    "id": null,
    "metadata": {},
    "name": "PDFViewer_CurrencyFieldQuery",
    "operationKind": "query",
    "text": "query PDFViewer_CurrencyFieldQuery {\n  currencies\n}\n"
  }
};
})();

(node as any).hash = "9070038a63913ba61fc8cd5bae94aedc";

export default node;
