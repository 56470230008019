import React from "react";
import Typography from "@mui/material/Typography";

type TemplateVariableTextFieldProps = {
  value: string;
};

export function TemplateVariableTextField(
  props: TemplateVariableTextFieldProps,
) {
  return (
    <Typography variant="body2" sx={{ whiteSpace: "pre-line", mt: 3 }}>
      {props.value}
    </Typography>
  );
}
