/**
 * @generated SignedSource<<9233211a1f7f25c592bc494c5b0ad7ea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContractsContractDateRecurSpanChoices = "A_" | "DAY" | "MO" | "NVR" | "SPC" | "YR" | "%future added value";
export type ContractsContractDateRecurTypeChoices = "A_" | "A_1" | "A_2" | "A_3" | "%future added value";
export type ContractsContractDateRenewEveryChoices = "MO" | "NVR" | "QTR" | "SMA" | "SPC" | "YR" | "YR10" | "YR2" | "YR3" | "YR4" | "YR5" | "YR7" | "%future added value";
export type ContractDateInput = {
  autoRenew?: boolean | null | undefined;
  date?: any | null | undefined;
  enableReminder?: boolean | null | undefined;
  label?: string | null | undefined;
  numberOfOccurences?: number | null | undefined;
  recurSpan?: ContractsContractDateRecurSpanChoices | null | undefined;
  recurType?: ContractsContractDateRecurTypeChoices | null | undefined;
  recursUntil?: any | null | undefined;
  reminder?: ReminderInput | null | undefined;
  renewDate?: any | null | undefined;
  renewEvery?: ContractsContractDateRenewEveryChoices | null | undefined;
  repeat?: boolean | null | undefined;
  repeatEveryNum?: number | null | undefined;
  trackCompletion?: boolean | null | undefined;
  uuid?: string | null | undefined;
};
export type ReminderInput = {
  addCustomMessage: boolean;
  attachDocument: boolean;
  customMessage?: string | null | undefined;
  delete?: boolean | null | undefined;
  includeRelatedDocuments?: boolean | null | undefined;
  keepExistingRecipients?: boolean | null | undefined;
  recipients?: ReadonlyArray<string | null | undefined> | null | undefined;
  remindOn?: any | null | undefined;
  remindOnType?: string | null | undefined;
  repeatEvery?: string | null | undefined;
};
export type ContractForms_SetDeadLineDateMutation$variables = {
  contractUuid: string;
  date: ContractDateInput;
};
export type ContractForms_SetDeadLineDateMutation$data = {
  readonly updateContractDate: {
    readonly contract: {
      readonly currentFormMissingRequiredFields: boolean | null | undefined;
      readonly id: string;
    } | null | undefined;
    readonly date: {
      readonly " $fragmentSpreads": FragmentRefs<"ContractForms_DeadlineToNonrenewFragment">;
    } | null | undefined;
  } | null | undefined;
};
export type ContractForms_SetDeadLineDateMutation = {
  response: ContractForms_SetDeadLineDateMutation$data;
  variables: ContractForms_SetDeadLineDateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "contractUuid"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "date"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "contractUuid",
        "variableName": "contractUuid"
      },
      {
        "kind": "Variable",
        "name": "date",
        "variableName": "date"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "ContractType",
  "kind": "LinkedField",
  "name": "contract",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currentFormMissingRequiredFields",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ContractForms_SetDeadLineDateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateContractDatePayload",
        "kind": "LinkedField",
        "name": "updateContractDate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractDateType",
            "kind": "LinkedField",
            "name": "date",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ContractForms_DeadlineToNonrenewFragment"
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ContractForms_SetDeadLineDateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateContractDatePayload",
        "kind": "LinkedField",
        "name": "updateContractDate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractDateType",
            "kind": "LinkedField",
            "name": "date",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "uuid",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "label",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "date",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "autoRenew",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "enableReminder",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "protected",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ReminderType",
                "kind": "LinkedField",
                "name": "reminder",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "remindOnType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "remindOn",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "repeatEvery",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "addCustomMessage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "customMessage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "recipients",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isactive",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "attachDocument",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "includeRelatedDocuments",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9f2e54d537fac3def3796cd0c778df28",
    "id": null,
    "metadata": {},
    "name": "ContractForms_SetDeadLineDateMutation",
    "operationKind": "mutation",
    "text": "mutation ContractForms_SetDeadLineDateMutation(\n  $contractUuid: String!\n  $date: ContractDateInput!\n) {\n  updateContractDate(input: {contractUuid: $contractUuid, date: $date}) {\n    date {\n      ...ContractForms_DeadlineToNonrenewFragment\n      id\n    }\n    contract {\n      id\n      currentFormMissingRequiredFields\n    }\n  }\n}\n\nfragment ContractForms_DeadlineToNonrenewFragment on ContractDateType {\n  id\n  uuid\n  label\n  date\n  autoRenew\n  enableReminder\n  protected\n  reminder {\n    ...EditDateDialog_reminderFragment\n    id\n  }\n}\n\nfragment EditDateDialog_reminderFragment on ReminderType {\n  id\n  remindOnType\n  remindOn\n  repeatEvery\n  addCustomMessage\n  customMessage\n  recipients\n  isactive\n  attachDocument\n  includeRelatedDocuments\n}\n"
  }
};
})();

(node as any).hash = "5ebc6fb556288c3138520d2ced1f5058";

export default node;
