/**
 * @generated SignedSource<<f064f2f04501fe83fbc2fcecb67fa2fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditDateDialog_contractFragment$data = {
  readonly customFieldValues: ReadonlyArray<{
    readonly valueDate: {
      readonly " $fragmentSpreads": FragmentRefs<"EditDateDialog_dateFragment">;
    } | null | undefined;
  } | null | undefined>;
  readonly dates: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"EditDateDialog_dateFragment">;
  } | null | undefined>;
  readonly deadlineToNonrenew: {
    readonly " $fragmentSpreads": FragmentRefs<"EditDateDialog_dateFragment">;
  } | null | undefined;
  readonly effectiveDate: {
    readonly " $fragmentSpreads": FragmentRefs<"EditDateDialog_dateFragment">;
  } | null | undefined;
  readonly terminationDate: {
    readonly " $fragmentSpreads": FragmentRefs<"EditDateDialog_dateFragment">;
  } | null | undefined;
  readonly uuid: string;
  readonly " $fragmentType": "EditDateDialog_contractFragment";
};
export type EditDateDialog_contractFragment$key = {
  readonly " $data"?: EditDateDialog_contractFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditDateDialog_contractFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "EditDateDialog_dateFragment"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditDateDialog_contractFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uuid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ContractDateType",
      "kind": "LinkedField",
      "name": "effectiveDate",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ContractDateType",
      "kind": "LinkedField",
      "name": "terminationDate",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ContractDateType",
      "kind": "LinkedField",
      "name": "deadlineToNonrenew",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CustomFieldValueType",
      "kind": "LinkedField",
      "name": "customFieldValues",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ContractDateType",
          "kind": "LinkedField",
          "name": "valueDate",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ContractDateType",
      "kind": "LinkedField",
      "name": "dates",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "ContractType",
  "abstractKey": null
};
})();

(node as any).hash = "077dcc69db91b8d4a69fdc7df347ba8a";

export default node;
