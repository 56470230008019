export default function alertTypeToColor(alertType) {
  switch (alertType) {
    case "default":
      return "primary";
    case "primary":
      return "primary";
    case "success":
      return "primary";
    case "info":
      return "primary";
    case "warning":
      return "warning";
    case "danger":
      return "error";
    case "v3":
      return "primary";
    default:
      return undefined;
  }
}
