/*
 * Helper functions for working with the Relay store
 *
 * Helpful resources:
 * - https://yarokuk.com/relay-store
 *
 */

import type { Primitive } from "relay-runtime/lib/store/RelayStoreTypes";

export type NonNullablePrimitive = NonNullable<Primitive>;

/**
 * Checks if the given value is a non-nullable primitive that is not an array.
 *
 * @example
 * const aPinnedAt = a.getLinkedRecord("node")?.getValue("pinnedAt");
 * nonNullablePrimitiveInvariant<string>(aPinnedAt)
 *
 * @param value - The value to be checked.
 * @param message - The error message to be thrown if the value is not a non-nullable primitive.
 * @param capture - Indicates whether the error should be captured by our error reporting service (not implemented yet).
 * @throws Throws an error if the value is not a non-nullable primitive that is not an array.
 */
export function nonNullablePrimitiveInvariant<T extends NonNullablePrimitive>(
  value: Primitive | Primitive[],
  message?: string,
  capture?: boolean,
): asserts value is T {
  // TODO: log to Sentry when we have that implemented on the frontend if capture is true
  if (Array.isArray(value)) {
    console.error(
      `Invariant violation. Expected given value to not be an array. value: ${value}`,
    );
    throw new Error(message);
  }

  if (value === null || value === undefined) {
    console.error(
      `Invariant violation. Expected given value to be truthy. value: ${value}`,
    );
    throw new Error(message);
  }
}
