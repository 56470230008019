/**
 * @generated SignedSource<<437d5a7f69d3d8603d8f0c9a0e7c2ac5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserCommentActionsMenu_currentUserAccountFragment$data = {
  readonly role: string | null | undefined;
  readonly " $fragmentType": "UserCommentActionsMenu_currentUserAccountFragment";
};
export type UserCommentActionsMenu_currentUserAccountFragment$key = {
  readonly " $data"?: UserCommentActionsMenu_currentUserAccountFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserCommentActionsMenu_currentUserAccountFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserCommentActionsMenu_currentUserAccountFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "role",
      "storageKey": null
    }
  ],
  "type": "AccountType",
  "abstractKey": null
};

(node as any).hash = "705221f6b6de77d37e29debb9890ed7d";

export default node;
