import AbcIcon from "@mui/icons-material/Abc";
import React from "react";
import { AIssistantIcon } from "~/components/Icons/AIAssistantIcon";
import { ApproveIcon } from "~/components/Icons/ApproveIcon";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import { EsignIcon } from "~/components/Icons/EsignIcon";
import { PropertiesIcon } from "~/components/Icons/PropertiesIcon";
import { RelatedDocsIcon } from "~/components/Icons/RelatedDocsIcon";

type ContractPageAction = {
  icon: React.ReactNode;
  name: string;
  routePath: string;
};

export const CONTRACT_PAGE_ACTIONS: ReadonlyArray<ContractPageAction> = [
  {
    icon: <PropertiesIcon />,
    name: "Properties & Contract",
    routePath: "properties",
  },
  {
    icon: <ChatOutlinedIcon />,
    name: "Comments & Notes",
    routePath: "comments",
  },
  {
    icon: <RelatedDocsIcon />,
    name: "Related Documents",
    routePath: "related-documents",
  },
  { icon: <AIssistantIcon />, name: "[AI]ssistant", routePath: "aissistant" },
  { icon: <ApproveIcon />, name: "Approve", routePath: "approve" },
  { icon: <EsignIcon />, name: "eSign", routePath: "esign" },
];
