/**
 * @generated SignedSource<<e56726daeeffc1aff0011318f5ccf128>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateContractDialog_Mutation$variables = {
  generateRedline: boolean;
  moveToRelated: boolean;
  url: string;
  uuid: string;
};
export type UpdateContractDialog_Mutation$data = {
  readonly replaceContract: {
    readonly message: string | null | undefined;
  } | null | undefined;
};
export type UpdateContractDialog_Mutation = {
  response: UpdateContractDialog_Mutation$data;
  variables: UpdateContractDialog_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "generateRedline"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "moveToRelated"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "url"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "uuid"
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "generateRedline",
            "variableName": "generateRedline"
          },
          {
            "kind": "Variable",
            "name": "moveToRelated",
            "variableName": "moveToRelated"
          },
          {
            "kind": "Variable",
            "name": "url",
            "variableName": "url"
          },
          {
            "kind": "Variable",
            "name": "uuid",
            "variableName": "uuid"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "ReplaceContractPayload",
    "kind": "LinkedField",
    "name": "replaceContract",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateContractDialog_Mutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "UpdateContractDialog_Mutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "e09859db8ea7584b9f764533d4cb5ed0",
    "id": null,
    "metadata": {},
    "name": "UpdateContractDialog_Mutation",
    "operationKind": "mutation",
    "text": "mutation UpdateContractDialog_Mutation(\n  $uuid: String!\n  $url: String!\n  $moveToRelated: Boolean!\n  $generateRedline: Boolean!\n) {\n  replaceContract(input: {uuid: $uuid, url: $url, moveToRelated: $moveToRelated, generateRedline: $generateRedline}) {\n    message\n  }\n}\n"
  }
};
})();

(node as any).hash = "228988f2e7fc7a2cd5c58ce18e0670da";

export default node;
