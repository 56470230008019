import { fetchQuery, graphql } from "react-relay";
import environment from "~/RelayEnvironment";
import useAsyncMutation from "~/utils/UseAsyncMutation";
import type { useContractDownload_DocxDownloadQuery } from "./__generated__/useContractDownload_DocxDownloadQuery.graphql";

export function useContractDownload() {
  const [commitAddAuditEntry] = useAsyncMutation(graphql`
    mutation useContractDownload_AddAuditEntryMutation(
      $contractUuid: String!
      $objectType: String!
      $fieldName: String!
      $oldValue: String!
      $newValue: String!
    ) {
      addAuditEntry(
        input: {
          contractUuid: $contractUuid
          objectType: $objectType
          fieldName: $fieldName
          oldValue: $oldValue
          newValue: $newValue
        }
      ) {
        clientMutationId
      }
    }
  `);

  async function addDownloadAuditEntry({
    contractUuid,
  }: {
    contractUuid: string;
  }) {
    try {
      const result = await commitAddAuditEntry({
        variables: {
          contractUuid,
          objectType: "Download",
          fieldName: "Contract downloaded",
          oldValue: "",
          newValue: "",
        },
      });

      return { state: "SUCCESS" } as const;
    } catch (error) {
      return { state: "ADD_AUDIT_ENTRY_ERROR", error } as const;
    }
  }

  async function commitDownload(args: {
    contractUuid: string;
    fileDownloadName: string;
  }) {
    try {
      const addAuditEntryResult = await addDownloadAuditEntry({
        contractUuid: args.contractUuid,
      });
      if (addAuditEntryResult.state !== "SUCCESS") {
        return addAuditEntryResult;
      }

      const ctURL =
        "/api/v1/download_filestream/" +
        args.contractUuid +
        "/?docfname=" +
        encodeURIComponent(args.fileDownloadName);
      const a = document.createElement("a");
      a.href = ctURL;
      a.download = ctURL.split("/").slice(-1)[0];
      a.click();
      return { state: "SUCCESS" } as const;
    } catch (error) {
      return { state: "DOWNLOAD_ERROR", error } as const;
    }
  }

  async function commitDocxDownload(args: {
    filename: string;
    contractUuid: string;
    fileDownloadName: string;
  }) {
    try {
      if (args.filename.endsWith(".docx") || args.filename.endsWith(".doc")) {
        return await commitDownload({
          contractUuid: args.contractUuid,
          fileDownloadName: args.fileDownloadName,
        });
      }

      const data = await fetchQuery<useContractDownload_DocxDownloadQuery>(
        environment,
        graphql`
          query useContractDownload_DocxDownloadQuery($uuid: String!) {
            docxUrl(uuid: $uuid)
          }
        `,
        { uuid: args.contractUuid },
      ).toPromise();

      if (!data?.docxUrl) {
        throw new Error("Failed to convert and download document");
      }

      const { docxUrl } = data;

      const addAuditEntryResult = await addDownloadAuditEntry({
        contractUuid: args.contractUuid,
      });
      if (addAuditEntryResult.state !== "SUCCESS") {
        return addAuditEntryResult;
      }

      const a = document.createElement("a");
      a.href = docxUrl;
      a.download = docxUrl.split("/").slice(-1)[0];
      a.click();
      return { state: "SUCCESS" } as const;
    } catch (error) {
      return { state: "DOWNLOAD_ERROR", error } as const;
    }
  }

  return {
    commitDownload,
    commitDocxDownload,
  };
}
