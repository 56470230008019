import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import React from "react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

type CommentsHelpTooltipProps = {};

export function CommentsHelpTooltip(props: CommentsHelpTooltipProps) {
  return (
    <Tooltip
      title={<ToolTipContent />}
      arrow
      componentsProps={{
        arrow: {
          sx: {
            color: "background.default",
            "&::before": {
              backgroundColor: "background.default",
              boxShadow: 1,
            },
          },
        },
        tooltip: {
          sx: {
            backgroundColor: "background.default",
            boxShadow: 4,
            p: 2,
          },
        },
      }}
      placement="right"
      enterTouchDelay={0}
    >
      <IconButton size="small">
        <HelpOutlineIcon />
      </IconButton>
    </Tooltip>
  );
}

function ToolTipContent() {
  return (
    <Stack spacing={2}>
      <Typography variant="body1">Comments</Typography>
      <Typography variant="body2">
        Comments help you communicate with other members of your organization
        with access to this contract.
      </Typography>
      {/* TODO: Add link to docs */}
      <Link
        href="https://www.contractsafe.com/support/commenting-feature"
        underline="none"
        target="_blank"
      >
        Learn more
      </Link>
    </Stack>
  );
}
