import React, { Suspense, useRef } from "react";
import { graphql } from "relay-runtime";
import type { PDFViewerArea_contractFragment$key } from "./__generated__/PDFViewerArea_contractFragment.graphql";
import { useFragment, useQueryLoader } from "react-relay";
import PDFViewer from "~/components/PDFViewer";
import {
  ContractViewerEmptyStateV2,
  useContractViewerEmptyStateV2,
} from "~/components/ContractViewerEmptyStateV2/ContractViewerEmptyStateV2";
import { AddContractFileViaLinkDialog } from "~/components/ContractViewerEmptyStateV2/AddContractFileViaLinkDialog";
import { ContractViewerLoadingSkeleton } from "~/components/ContractViewerSkeleton";
import {
  UPDATE_CONTRACT_FROM_TEMPLATE_DIALOG_QUERY,
  UpdateContractFromTemplateDialog,
} from "~/components/UpdateContractFromTemplateDialog/UpdateContractFromTemplateDialog";
import { UploadContractFileDialog } from "~/components/UploadContractFileDialog";
import type { PDFViewerArea_accountFragment$key } from "./__generated__/PDFViewerArea_accountFragment.graphql";
import type { UpdateContractFromTemplateDialog_Query } from "~/components/UpdateContractFromTemplateDialog/__generated__/UpdateContractFromTemplateDialog_Query.graphql";
import type { PDFViewerArea_organizationFragment$key } from "./__generated__/PDFViewerArea_organizationFragment.graphql";
import { useSnackbar } from "~/components/Snackbar";

type PDFViewerAreaProps = {
  accountFragRef: PDFViewerArea_accountFragment$key;
  contractFragRef: PDFViewerArea_contractFragment$key;
  organizationFragRef: PDFViewerArea_organizationFragment$key;
};

export function PDFViewerArea(props: PDFViewerAreaProps) {
  const pspdfkitRef = useRef(null);
  const { openSnackbar } = useSnackbar();
  const account = useFragment(
    PDFViewerArea_accountFragment,
    props.accountFragRef,
  );
  const contract = useFragment(
    PDFViewerArea_contractFragment,
    props.contractFragRef,
  );
  const organization = useFragment(
    PDFViewerArea_organizationFragment,
    props.organizationFragRef,
  );

  const contractViewerEmptyState = useContractViewerEmptyStateV2();
  const showContractFileEmptyState = !contract.filename;
  const [
    updateContractFromTemplateDialogQueryRef,
    loadUpdateContractFromTemplateDialogQuery,
  ] = useQueryLoader<UpdateContractFromTemplateDialog_Query>(
    UPDATE_CONTRACT_FROM_TEMPLATE_DIALOG_QUERY,
  );

  function handleContractViewerEmptyStateMethodSelect(
    method: "upload" | "link" | "template",
  ) {
    loadUpdateContractFromTemplateDialogQuery({});
    contractViewerEmptyState.handleMethodSelect(method);
  }

  function renderViewerEmptyStateDialogs() {
    return (
      <>
        <UploadContractFileDialog
          contractUuid={contract.uuid}
          open={contractViewerEmptyState.activeDialog === "upload"}
          handleClose={() => contractViewerEmptyState.setActiveDialog(null)}
        />
        <AddContractFileViaLinkDialog
          contractUuid={contract.uuid}
          open={contractViewerEmptyState.activeDialog === "link"}
          handleClose={() => contractViewerEmptyState.setActiveDialog(null)}
        />

        {/* TODO: Refactor component to only use fragments */}
        {/* <UpdateContractFromTemplateDialog
          mode="CREATE_FILE"
          contractFormsContractQueryRef={contractFormsContractQueryRef}
          queryRef={updateContractFromTemplateDialogQueryRef}
          open={contractViewerEmptyState.activeDialog === "template"}
          handleClose={() => contractViewerEmptyState.setActiveDialog(null)}
        /> */}
      </>
    );
  }

  if (showContractFileEmptyState) {
    return (
      <>
        <ContractViewerEmptyStateV2
          contractUuid={contract.uuid}
          organizationFragRef={organization}
          handleMethodSelect={handleContractViewerEmptyStateMethodSelect}
        />
        {renderViewerEmptyStateDialogs()}
      </>
    );
  }

  return (
    <Suspense fallback={<ContractViewerLoadingSkeleton />}>
      <PDFViewer
        // @ts-expect-error
        account={account}
        contract={contract}
        mainQueryRef={null}
        openSnackbar={openSnackbar}
        ref={pspdfkitRef}
      />
    </Suspense>
  );
}

const PDFViewerArea_accountFragment = graphql`
  fragment PDFViewerArea_accountFragment on AccountType {
    ...PDFViewer_accountFragment
  }
`;

const PDFViewerArea_contractFragment = graphql`
  fragment PDFViewerArea_contractFragment on ContractType {
    filename
    uuid
    ...PDFViewer_fragment
  }
`;

const PDFViewerArea_organizationFragment = graphql`
  fragment PDFViewerArea_organizationFragment on OrganizationType {
    ...ContractViewerEmptyStateV2_organizationFragment
  }
`;
