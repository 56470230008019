/**
 * @generated SignedSource<<798a76e6476e62e7c812592416ddcd24>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RedlineDialog_fragment$data = {
  readonly amendedTo: {
    readonly id: string;
    readonly title: string | null | undefined;
    readonly uuid: string;
  } | null | undefined;
  readonly amendments: ReadonlyArray<{
    readonly id: string;
    readonly title: string | null | undefined;
    readonly uuid: string;
  } | null | undefined>;
  readonly id: string;
  readonly relatedDocs: ReadonlyArray<{
    readonly dateCreated: string;
    readonly id: string;
    readonly internalContract: {
      readonly title: string | null | undefined;
      readonly uuid: string;
    } | null | undefined;
    readonly relationType: string;
    readonly title: string | null | undefined;
    readonly uuid: string;
  } | null | undefined>;
  readonly title: string | null | undefined;
  readonly uuid: string;
  readonly " $fragmentType": "RedlineDialog_fragment";
};
export type RedlineDialog_fragment$key = {
  readonly " $data"?: RedlineDialog_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"RedlineDialog_fragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RedlineDialog_fragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ContractType",
      "kind": "LinkedField",
      "name": "amendedTo",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        (v2/*: any*/),
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RelatedDocType",
      "kind": "LinkedField",
      "name": "relatedDocs",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dateCreated",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ContractType",
          "kind": "LinkedField",
          "name": "internalContract",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v2/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "relationType",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ContractType",
      "kind": "LinkedField",
      "name": "amendments",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        (v0/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "ContractType",
  "abstractKey": null
};
})();

(node as any).hash = "75a274284ae3a267bfe7fab4f705bf7f";

export default node;
