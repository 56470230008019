import React from "react";
import MuiInputLabel from "@mui/material/InputLabel";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";

export const InputLabel = styled(MuiInputLabel)({
  position: "static",
  left: 0,
  top: 0,

  fontFamily: "Open Sans",
  fontStyle: "normal",
  fontWeight: "bold",
  fontSize: "14px",
  lineHeight: "19px",

  whiteSpace: "nowrap",

  color: "#02162A",

  flex: "none",
  order: 1,
  flexGrow: 0,
  marginTop: 4,
  marginBottom: 4,
  marginLeft: 0,
  marginRight: 0,

  overflow: "hidden",
  textOverflow: "ellipsis",
});

export const WrappingInputLabel = styled(InputLabel)({
  height: "initial !important",
  width: "initial !important",
  whiteSpace: "normal",
  overflowWrap: "anywhere",
  wordBreak: "break-word",
});

export const GroupInputLabel = styled(WrappingInputLabel)({
  fontWeight: "normal",
  paddingTop: 0,
  paddingBottom: 0,
});

export default function LabeledItem({
  required,
  label,
  labelComponent,
  icons,
  children,
  style,
  containerSx,
  ...props
}) {
  const LabelComponent = labelComponent || InputLabel;
  return (
    <Box style={style} sx={containerSx}>
      {label || icons ? (
        <Grid
          container
          width="100%"
          justifyContent="space-between"
          wrap="nowrap"
          alignItems="center"
        >
          <Grid item style={{ flexGrow: 1, minWidth: 0 }}>
            {label ? (
              <LabelComponent>
                {required ? "*" : ""}
                {label}
              </LabelComponent>
            ) : null}
          </Grid>
          <Grid item xs="auto">
            <Box display="flex" flexWrap="nowrap">
              {icons}
            </Box>
          </Grid>
        </Grid>
      ) : null}
      {children}
    </Box>
  );
}
