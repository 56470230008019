/**
 * @generated SignedSource<<05633a4f004d9054214f075441e7d5e1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RelatedDocs_AddAuditEntryMutation$variables = {
  contractUuid: string;
  fieldName: string;
  newValue: string;
  objectType: string;
  oldValue: string;
};
export type RelatedDocs_AddAuditEntryMutation$data = {
  readonly addAuditEntry: {
    readonly clientMutationId: string | null | undefined;
  } | null | undefined;
};
export type RelatedDocs_AddAuditEntryMutation = {
  response: RelatedDocs_AddAuditEntryMutation$data;
  variables: RelatedDocs_AddAuditEntryMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contractUuid"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fieldName"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "newValue"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "objectType"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "oldValue"
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "contractUuid",
            "variableName": "contractUuid"
          },
          {
            "kind": "Variable",
            "name": "fieldName",
            "variableName": "fieldName"
          },
          {
            "kind": "Variable",
            "name": "newValue",
            "variableName": "newValue"
          },
          {
            "kind": "Variable",
            "name": "objectType",
            "variableName": "objectType"
          },
          {
            "kind": "Variable",
            "name": "oldValue",
            "variableName": "oldValue"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "AddAuditEntryPayload",
    "kind": "LinkedField",
    "name": "addAuditEntry",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "RelatedDocs_AddAuditEntryMutation",
    "selections": (v5/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "RelatedDocs_AddAuditEntryMutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "8b927b91dcfc24e5a010cf633fff4f72",
    "id": null,
    "metadata": {},
    "name": "RelatedDocs_AddAuditEntryMutation",
    "operationKind": "mutation",
    "text": "mutation RelatedDocs_AddAuditEntryMutation(\n  $contractUuid: String!\n  $objectType: String!\n  $fieldName: String!\n  $oldValue: String!\n  $newValue: String!\n) {\n  addAuditEntry(input: {contractUuid: $contractUuid, objectType: $objectType, fieldName: $fieldName, oldValue: $oldValue, newValue: $newValue}) {\n    clientMutationId\n  }\n}\n"
  }
};
})();

(node as any).hash = "ed939753978011b51cc651f4240cfa11";

export default node;
