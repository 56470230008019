import React from "react";
import SvgIcon, { type SvgIconProps } from "@mui/material/SvgIcon";

export function PinCommentIcon(props: SvgIconProps) {
  return (
    <SvgIcon>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7857 3.72493C15.9102 2.84948 14.4274 3.13505 13.9397 4.273L12.3472 7.98904C10.8481 7.69506 9.233 8.12812 8.07009 9.29103L6.36 11.0011C6.06711 11.294 6.06711 11.7689 6.36 12.0618L8.85531 14.5571L3.46967 19.9427C3.17678 20.2356 3.17678 20.7105 3.46967 21.0034C3.76256 21.2963 4.23744 21.2963 4.53033 21.0034L9.91597 15.6178L12.4114 18.1131C12.552 18.2538 12.7428 18.3328 12.9417 18.3328C13.1406 18.3328 13.3314 18.2538 13.472 18.1131L15.1821 16.4031C16.345 15.2401 16.7781 13.6251 16.4841 12.126L20.2001 10.5334C21.3381 10.0457 21.6236 8.56289 20.7482 7.68744L16.7857 3.72493ZM15.3185 4.86388C15.3881 4.70132 15.6 4.66052 15.725 4.78559L19.6875 8.7481C19.8126 8.87317 19.7718 9.085 19.6092 9.15467L15.2939 11.0041C14.9296 11.1602 14.7496 11.573 14.8832 11.9462C15.2942 13.0945 15.0389 14.425 14.1215 15.3424L12.9417 16.5222L7.95099 11.5315L9.13075 10.3517C10.0482 9.43428 11.3786 9.17891 12.5269 9.58991C12.9002 9.7235 13.3129 9.54358 13.469 9.17922L15.3185 4.86388Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
