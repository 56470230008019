import { Set } from "immutable";
import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
  Suspense,
} from "react";
import { fetchQuery } from "react-relay";
import {
  graphql,
  useFragment,
  useLazyLoadQuery,
  useRelayEnvironment,
} from "react-relay";
import useAsyncMutation from "~/utils/UseAsyncMutation";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Add from "@mui/icons-material/Add";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import LinkIcon from "@mui/icons-material/Link";
import HighlightOff from "@mui/icons-material/HighlightOff";
import { Autocomplete } from "~/components/Field";
import Button from "~/components/Button";
import ConfirmDialog from "~/components/ConfirmDialog";
import PartyDialog from "~/components/PartyDialog";
import { FieldBox, GroupBox } from "~/components/Boxes";
import { WrappingInputLabel } from "~/components/LabeledItem";
import OrgLocalStoreContext from "~/OrgLocalStore";
import _ from "lodash";
import { track } from "~/analytics/events";

const PARTY_DEBOUNCE_DELAY = 10000;

graphql`
  fragment Parties_AllPartiesFragment on PartyType {
    id
    uuid
    companyName
    typeofparty
  }
`;

const ADD_PARTY_MUTATION = graphql`
  mutation Parties_createContractPartyMutation(
    $contractUuid: String!
    $label: String!
    $companyName: String!
  ) {
    createContractParty(
      input: {
        contractuuid: $contractUuid
        label: $label
        companyName: $companyName
      }
    ) {
      contract {
        ...Parties_ContractPartiesFragment
      }
    }
  }
`;

export function uniqueCompanyNames(xs) {
  return [
    ...Set(xs.map((x) => x.companyName).filter((x) => x)).sort(friendlySort),
  ];
}

export function isPartyOfType(partyType) {
  return (x) => [partyType, "both"].includes(x.typeofparty);
}

function DeletePartyDialog({ contractUuid, ctparty, open, handleClose }) {
  const [commitDelete] = useAsyncMutation(graphql`
    mutation Parties_removeContractPartyMutation($cpuuid: String!) {
      removeContractParty(input: { uuid: $cpuuid }) {
        contract {
          uuid
          mycompanies {
            uuid
            label
            party {
              uuid
              companyName
            }
            ...PartyDialog_CTPartyfragment
          }
          counterparties {
            uuid
            label
            party {
              uuid
              companyName
            }
            ...PartyDialog_CTPartyfragment
          }
        }
      }
    }
  `);

  async function handleConfirm() {
    handleClose();
    await commitDelete({
      variables: { cpuuid: ctparty.uuid },
    });
  }

  return (
    <ConfirmDialog
      title="Delete Party?"
      open={open}
      onClose={handleClose}
      onConfirm={handleConfirm}
      noText="Cancel"
      yesText="Delete"
    >
      Are you sure you want to delete this party?
    </ConfirmDialog>
  );
}

export function useCachedParties() {
  const cacheKey = "parties";
  const environment = useRelayEnvironment();
  const orgLocalStore = useContext(OrgLocalStoreContext);
  const [cachedParties, setCachedParties] = useState(
    orgLocalStore.get(cacheKey),
  );

  const { parties: queryParties } = useLazyLoadQuery(
    graphql`
      query Parties_AllPartiesQuery($skip: Boolean!) {
        parties @skip(if: $skip) {
          ...Parties_AllPartiesFragment @relay(mask: false)
        }
      }
    `,
    {
      skip: !!cachedParties,
    },
  );

  useEffect(() => {
    if (queryParties?.length) {
      orgLocalStore.set(
        cacheKey,
        queryParties.map((x) => ({
          companyName: x.companyName,
          uuid: x.uuid,
          typeofparty: x.typeofparty,
        })),
      );
    }
  }, [queryParties]);

  function updateCachedParty(updatedParty) {
    // add the party to the cache, temporarily, so that it looks about right
    // while we reload parties from the server
    const cache = orgLocalStore.get(cacheKey);
    if (cache) {
      orgLocalStore.set(cacheKey, [
        ...cache.filter((x) => !(x.uuid == updatedParty.uuid)),
        {
          companyName: updatedParty.companyName,
          uuid: updatedParty.uuid,
          typeofparty: updatedParty.label
            ?.toLowerCase()
            .startsWith("counterparty")
            ? "counterparty"
            : "mycompany",
        },
      ]);
    }
    setCachedParties(orgLocalStore.get(cacheKey));
  }

  function refreshCachedParties() {
    // fetch updated parties in the background and update the cache; this is
    // not an elegant way to do this, but it works for now
    fetchQuery(
      environment,
      graphql`
        query Parties_AllPartiesRefreshQuery {
          parties {
            ...Parties_AllPartiesFragment @relay(mask: false)
          }
        }
      `,
      {},
    ).subscribe({
      next: (data) => {
        if (data?.parties?.length) {
          orgLocalStore.set(
            cacheKey,
            data.parties.map((x) => ({
              companyName: x.companyName,
              uuid: x.uuid,
              typeofparty: x.typeofparty,
            })),
          );
          setCachedParties(orgLocalStore.get(cacheKey));
        }
      },
    });
  }

  return [
    queryParties || cachedParties,
    updateCachedParty,
    refreshCachedParties,
  ];
}

export function StandalonePartySelect({
  label,
  value,
  partyType,
  onChange,
  required,
  disabled,
  ...props
}) {
  const result = useCachedParties();

  const options = uniqueCompanyNames(
    result[0].filter(isPartyOfType(partyType)),
  );

  return (
    <>
      <Autocomplete
        label={label}
        labelComponent={(props) => (
          <WrappingInputLabel
            required={required}
            error={required && !value}
            {...props}
          />
        )}
        value={value || ""}
        onInputChange={(_, value) => onChange(value)}
        onChange={(_, value) => onChange(value)}
        options={options}
        disabled={disabled}
        {...props}
      />
    </>
  );
}

export function PartySelect({
  contractUuid,
  ctparty,
  options,
  onCommit,
  required,
  disabled,
  loading,
  annotations,
  onJumpClick,
  ...props
}) {
  const [partyOpenFor, setPartyOpenFor] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [commitParty] = useAsyncMutation(graphql`
    mutation Parties_updateContractPartyMutation(
      $label: String!
      $companyName: String!
      $contractUuid: String!
      $partyUuid: String!
    ) {
      updateContractParty(
        input: {
          contractparty: {
            uuid: $partyUuid
            contractuuid: $contractUuid
            label: $label
            party: { companyName: $companyName }
          }
        }
      ) {
        contract {
          uuid
          currentFormMissingRequiredFields
          counterparties {
            uuid
            party {
              uuid
              companyName
            }
            ...PartyDialog_CTPartyfragment
          }
          mycompanies {
            uuid
            label
            party {
              uuid
              companyName
            }
            ...PartyDialog_CTPartyfragment
          }
        }
      }
    }
  `);
  const [commitCreateParty] = useAsyncMutation(ADD_PARTY_MUTATION);
  const newParty = ctparty.uuid == "newparty";

  const annotation =
    !!annotations && Object.keys(annotations).length > 0
      ? annotations.filter((anno) => anno.customData?.fieldId == ctparty.uuid)
      : [];

  const debouncedCommit = useCallback(
    _.debounce(async (variables) => {
      if (variables.label === "Counterparty") {
        track("Contract Counterparty Updated");
      }
      await commitParty({ variables });
      onCommit(null, variables);
    }, PARTY_DEBOUNCE_DELAY),
    [],
  );

  const debouncedCreate = useCallback(
    _.debounce(async (variables) => {
      await commitCreateParty({
        variables,
      });
      onCommit(null, variables);
    }, PARTY_DEBOUNCE_DELAY),
    [],
  );

  function handleChange(event, value, reason) {
    if (reason == "reset") {
      return;
    }
    if (newParty) {
      debouncedCreate({
        contractUuid,
        label: ctparty.label,
        companyName: value,
      });
    } else {
      debouncedCommit({
        contractUuid,
        partyUuid: ctparty.uuid,
        label: ctparty.label,
        companyName: value,
        contractparty: { ctparty },
      });
    }
  }

  return (
    <>
      <Autocomplete
        data-testid={
          "combo-box-" +
          ctparty.label.toString().toLowerCase().replace(/\s/g, "")
        }
        loading={loading}
        loadingText={
          <Box display="flex" alignItems="center">
            <CircularProgress
              color="inherit"
              size={20}
              style={{ marginRight: 5 }}
            />
            <Typography variant="body1">Loading...</Typography>
          </Box>
        }
        label={ctparty.label}
        labelComponent={(props) => (
          <WrappingInputLabel
            required={required}
            error={required && !ctparty.party.companyName}
            {...props}
          />
        )}
        value={ctparty.party.companyName || ""}
        icons={
          <>
            {annotation?.length > 0 && (
              <Button
                variant="text"
                size="tiny"
                onClick={() => onJumpClick(annotation[0])}
                label={<LinkIcon fontSize="small" color="primary" />}
              />
            )}
            <Button
              variant="text"
              size="tiny"
              onClick={() => setPartyOpenFor(ctparty)}
              disabled={newParty || ctparty.party.companyName == ""}
              label={
                <PermContactCalendarIcon
                  fontSize="small"
                  color={newParty ? "disabled" : "primary"}
                />
              }
              aria-label="Edit Contacts"
            />
            {["My Company", "Counterparty"].includes(ctparty.label) ? null : (
              <Button
                variant="text"
                size="tiny"
                onClick={() => setDeleteOpen(true)}
                label={<HighlightOff fontSize="small" />}
                disabled={disabled}
              />
            )}
          </>
        }
        onInputChange={handleChange}
        onChange={(...args) => {
          handleChange(...args);
          debouncedCreate.flush();
          debouncedCommit.flush();
        }}
        onBlur={() =>
          setTimeout(() => {
            debouncedCreate.flush();
            debouncedCommit.flush();
          }, 1000)
        }
        options={options}
        disabled={disabled}
        {...props}
      />
      <DeletePartyDialog
        contractUuid={contractUuid}
        ctparty={ctparty}
        open={deleteOpen}
        handleClose={() => setDeleteOpen(false)}
      />
      {partyOpenFor ? (
        <PartyDialog
          openFor={partyOpenFor}
          setOpenFor={setPartyOpenFor}
          disabled={disabled}
          onCommit={onCommit}
          contractUuid={contractUuid}
        />
      ) : null}
    </>
  );
}

export function PartyTypeSection({
  contractUuid,
  partyType,
  parties,
  options,
  onCommit,
  required,
  disabled,
  loading,
  annotations,
  onJumpClick,
}) {
  const [commitAddParty] = useAsyncMutation(ADD_PARTY_MUTATION);

  const nextPartyNum =
    Math.max(
      ...[1], // minumum value, in case parties is empty
      ...parties.map((p) => parseInt(p.label.replace(partyType, "")) || 1),
    ) + 1;

  function handleAddParty() {
    if (parties.length == 0) {
      // Add the first one, if it doesn't really exist. The UI is already
      // displaying it, and we've already calculated nextPartyNum as if it exists.
      commitAddParty({
        variables: {
          contractUuid,
          label: partyType,
          companyName: "",
        },
      });
    }

    commitAddParty({
      variables: {
        contractUuid,
        label: `${partyType} ${nextPartyNum}`,
        companyName: "",
      },
    });
  }

  return (
    <>
      {parties.length > 0 ? (
        parties.map((p, i, a) => (
          <FieldBox key={p.uuid} id={partyType + "-" + i}>
            <PartySelect
              contractUuid={contractUuid}
              ctparty={p}
              options={options}
              onCommit={onCommit}
              required={required && i == 0}
              disabled={disabled}
              loading={loading}
              onJumpClick={onJumpClick}
              annotations={annotations}
            />
            {i == a.length - 1 ? (
              <Button
                variant="text"
                size="tiny"
                startIcon={<Add />}
                label={"Add " + partyType}
                onClick={handleAddParty}
                disabled={disabled}
              />
            ) : null}
          </FieldBox>
        ))
      ) : (
        <FieldBox key={"npary"} id={partyType + "-0"}>
          <PartySelect
            contractUuid={contractUuid}
            ctparty={{
              uuid: "newparty",
              label: partyType,
              party: { companyName: "" },
            }}
            options={options}
            onCommit={onCommit}
            required={required}
            disabled={disabled}
            loading={loading}
          />
          <Button
            variant="text"
            size="tiny"
            startIcon={<Add />}
            label={"Add " + partyType}
            onClick={handleAddParty}
            disabled={disabled}
          />
        </FieldBox>
      )}
    </>
  );
}

function BaseMyCompaniesFieldset({
  contractUuid,
  mycompanies,
  allMyCompanies,
  onCommit,
  required,
  disabled,
  loading,
  annotations,
  onJumpClick,
}) {
  return (
    <GroupBox>
      <PartyTypeSection
        contractUuid={contractUuid}
        partyType="My Company"
        parties={mycompanies}
        options={allMyCompanies}
        onCommit={onCommit}
        required={required}
        disabled={disabled}
        loading={loading}
        annotations={annotations}
        onJumpClick={onJumpClick}
      />
    </GroupBox>
  );
}

function BaseCounterpartiesFieldset({
  contractUuid,
  counterparties,
  allCounterparties,
  onCommit,
  required,
  disabled,
  loading,
  annotations,
  onJumpClick,
}) {
  return (
    <GroupBox>
      <PartyTypeSection
        contractUuid={contractUuid}
        partyType="Counterparty"
        parties={counterparties}
        options={allCounterparties}
        onCommit={onCommit}
        required={required}
        disabled={disabled}
        loading={loading}
        annotations={annotations}
        onJumpClick={onJumpClick}
      />
    </GroupBox>
  );
}

function BasePartiesFieldset({
  contractUuid,
  mycompanies,
  counterparties,
  allMyCompanies,
  allCounterparties,
  onCommit,
  required,
  disabled,
  loading,
  annotations,
  onJumpClick,
}) {
  return (
    <>
      {mycompanies.length + counterparties.length <= 2 ? (
        <GroupBox>
          <PartyTypeSection
            contractUuid={contractUuid}
            partyType="My Company"
            parties={mycompanies}
            options={allMyCompanies}
            onCommit={onCommit}
            required={required}
            disabled={disabled}
            loading={loading}
            annotations={annotations}
            onJumpClick={onJumpClick}
          />
          <PartyTypeSection
            contractUuid={contractUuid}
            partyType="Counterparty"
            parties={counterparties}
            options={allCounterparties}
            onCommit={onCommit}
            required={required}
            disabled={disabled}
            loading={loading}
            annotations={annotations}
            onJumpClick={onJumpClick}
          />
        </GroupBox>
      ) : (
        <>
          <BaseMyCompaniesFieldset
            contractUuid={contractUuid}
            mycompanies={mycompanies}
            allMyCompanies={allMyCompanies}
            onCommit={onCommit}
            required={required}
            disabled={disabled}
            loading={loading}
            annotations={annotations}
            onJumpClick={onJumpClick}
          />
          <BaseCounterpartiesFieldset
            contractUuid={contractUuid}
            counterparties={counterparties}
            allCounterparties={allCounterparties}
            onCommit={onCommit}
            required={required}
            disabled={disabled}
            loading={loading}
            annotations={annotations}
            onJumpClick={onJumpClick}
          />
        </>
      )}
    </>
  );
}

function LoadedMyCompaniesFieldset({
  contractUuid,
  mycompanies,
  onCommit,
  required,
  disabled,
  annotations,
  onJumpClick,
}) {
  const orgLocalStore = useContext(OrgLocalStoreContext);
  const [parties, updateCachedParty, refreshCachedParties] = useCachedParties();

  const allMyCompanies = uniqueCompanyNames([
    ...mycompanies,
    ...parties.filter(isPartyOfType("mycompany")),
  ]);

  return (
    <BaseMyCompaniesFieldset
      contractUuid={contractUuid}
      mycompanies={mycompanies}
      allMyCompanies={allMyCompanies}
      onCommit={(message, party) => {
        if (party) {
          updateCachedParty(party);
        }

        onCommit(message);

        refreshCachedParties();
      }}
      required={required}
      disabled={disabled}
      annotations={annotations}
      onJumpClick={onJumpClick}
    />
  );
}

function LoadedCounterpartiesFieldset({
  contractUuid,
  counterparties,
  onCommit,
  required,
  disabled,
  annotations,
  onJumpClick,
}) {
  const orgLocalStore = useContext(OrgLocalStoreContext);
  const [parties, updateCachedParty, refreshCachedParties] = useCachedParties();

  const allCounterparties = uniqueCompanyNames([
    ...counterparties,
    ...parties.filter(isPartyOfType("counterparty")),
  ]);

  return (
    <BaseCounterpartiesFieldset
      contractUuid={contractUuid}
      counterparties={counterparties}
      allCounterparties={allCounterparties}
      onCommit={(message, party) => {
        if (party) {
          updateCachedParty(party);
        }

        onCommit(message);

        refreshCachedParties();
      }}
      required={required}
      disabled={disabled}
      annotations={annotations}
      onJumpClick={onJumpClick}
    />
  );
}

function LoadedPartiesFieldset({
  contractUuid,
  mycompanies,
  counterparties,
  onCommit,
  required,
  disabled,
  annotations,
  onJumpClick,
}) {
  const orgLocalStore = useContext(OrgLocalStoreContext);
  const [parties, updateCachedParty, refreshCachedParties] = useCachedParties();

  const allMyCompanies = uniqueCompanyNames([
    ...mycompanies,
    ...parties.filter(isPartyOfType("mycompany")),
  ]);

  const allCounterparties = uniqueCompanyNames([
    ...counterparties,
    ...parties.filter(isPartyOfType("counterparty")),
  ]);

  return (
    <BasePartiesFieldset
      contractUuid={contractUuid}
      mycompanies={mycompanies}
      counterparties={counterparties}
      allMyCompanies={allMyCompanies}
      allCounterparties={allCounterparties}
      onCommit={(message, party) => {
        if (party) {
          updateCachedParty(party);
        }

        onCommit(message);

        refreshCachedParties();
      }}
      required={required}
      disabled={disabled}
      annotations={annotations}
      onJumpClick={onJumpClick}
    />
  );
}

export const CONTRACT_MY_COMPANIES_FRAGMENT = graphql`
  fragment Parties_ContractMyCompaniesFragment on ContractType {
    uuid
    mycompanies {
      uuid
      label
      party {
        uuid
        companyName
      }
      ...PartyDialog_CTPartyfragment
    }
  }
`;

export const CONTRACT_COUNTERPARTIES_FRAGMENT = graphql`
  fragment Parties_ContractCounterpartiesFragment on ContractType {
    uuid
    counterparties {
      uuid
      label
      party {
        uuid
        companyName
      }
      ...PartyDialog_CTPartyfragment
    }
  }
`;

export const CONTRACT_PARTIES_FRAGMENT = graphql`
  fragment Parties_ContractPartiesFragment on ContractType {
    ...Parties_ContractMyCompaniesFragment @relay(mask: false)
    ...Parties_ContractCounterpartiesFragment @relay(mask: false)
  }
`;

export function MyCompaniesFieldset({
  contract,
  required,
  disabled,
  onCommit,
  annotations,
  onJumpClick,
}) {
  const { uuid, mycompanies } = useFragment(
    CONTRACT_MY_COMPANIES_FRAGMENT,
    contract,
  );

  return (
    <Suspense
      fallback={
        <BaseMyCompaniesFieldset
          loading
          contractUuid={uuid}
          mycompanies={mycompanies}
          allMyCompanies={[]}
          onCommit={(message, party) => onCommit(message)}
          required={required}
          disabled={disabled}
        />
      }
    >
      <LoadedMyCompaniesFieldset
        contractUuid={uuid}
        mycompanies={mycompanies}
        onCommit={onCommit}
        required={required}
        disabled={disabled}
        annotations={annotations}
        onJumpClick={onJumpClick}
      />
    </Suspense>
  );
}

export function CounterpartiesFieldset({
  contract,
  required,
  disabled,
  onCommit,
  annotations,
  onJumpClick,
}) {
  const { uuid, counterparties } = useFragment(
    CONTRACT_COUNTERPARTIES_FRAGMENT,
    contract,
  );

  return (
    <Suspense
      fallback={
        <BaseCounterpartiesFieldset
          loading
          contractUuid={uuid}
          counterparties={counterparties}
          allCounterparties={[]}
          onCommit={(message, party) => onCommit(message)}
          required={required}
          disabled={disabled}
        />
      }
    >
      <LoadedCounterpartiesFieldset
        contractUuid={uuid}
        counterparties={counterparties}
        onCommit={onCommit}
        required={required}
        disabled={disabled}
        annotations={annotations}
        onJumpClick={onJumpClick}
      />
    </Suspense>
  );
}

export function PartiesFieldset({
  contract,
  required,
  disabled,
  onCommit,
  annotations,
  onJumpClick,
}) {
  const { uuid, mycompanies, counterparties } = useFragment(
    CONTRACT_PARTIES_FRAGMENT,
    contract,
  );

  return (
    <Suspense
      fallback={
        <BasePartiesFieldset
          loading
          contractUuid={uuid}
          mycompanies={mycompanies}
          counterparties={counterparties}
          allMyCompanies={[]}
          allCounterparties={[]}
          onCommit={(message, party) => onCommit(message)}
          required={required}
          disabled={disabled}
        />
      }
    >
      <LoadedPartiesFieldset
        contractUuid={uuid}
        mycompanies={mycompanies}
        counterparties={counterparties}
        onCommit={onCommit}
        required={required}
        disabled={disabled}
        annotations={annotations}
        onJumpClick={onJumpClick}
      />
    </Suspense>
  );
}
