import React from "react";
import { graphql, useFragment } from "react-relay";
import useAsyncMutation from "~/utils/UseAsyncMutation";
import EditableText from "./EditableText";
import { styled } from "@mui/material/styles";

export default function RelatedDocTitle({
  contractUuid,
  relatedDoc,
  onCommit,
  ...props
}) {
  const { id, uuid, title } = useFragment(
    graphql`
      fragment RelatedDocTitle_fragment on RelatedDocType {
        id
        uuid
        title
      }
    `,
    relatedDoc,
  );
  const [commitTitle, isInFlight] = useAsyncMutation(graphql`
    mutation RelatedDocTitle_Mutation($uuid: String!, $title: String) {
      updateRelatedDoc(input: { uuid: $uuid, title: $title }) {
        relatedDoc {
          ...RelatedDocTitle_fragment
        }
      }
    }
  `);

  const commit = async (value) => {
    try {
      await commitTitle({
        variables: { uuid, title: value },
        optimisticResponse: {
          updateRelatedDoc: { relatedDoc: { id, uuid, title: value } },
        },
      });
      onCommit();
    } catch (e) {
      onCommit(e.message);
    }
  };

  return (
    <EditableText
      variant="body1"
      value={title}
      commit={commit}
      isInFlight={isInFlight}
      InputProps={{ sx: { whiteSpace: "normal" } }}
      {...props}
    />
  );
}
