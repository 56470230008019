import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { ConnectionHandler, graphql, useFragment } from "react-relay";
import type {
  UserCommentActionsMenu_commentFragment$data,
  UserCommentActionsMenu_commentFragment$key,
} from "./__generated__/UserCommentActionsMenu_commentFragment.graphql";
import { UnpinCommentIcon } from "./UnpinCommentIcon";
import type {
  UserCommentActionsMenu_currentUserAccountFragment$data,
  UserCommentActionsMenu_currentUserAccountFragment$key,
} from "./__generated__/UserCommentActionsMenu_currentUserAccountFragment.graphql";
import { useCommentPinnedAtActions } from "../useCommentPinnedAt";
import { useSnackbar } from "~/components/Snackbar";
import Alert from "@mui/material/Alert";
import { MenuIcon } from "./MenuIcon";
import { PinCommentIcon } from "./PinCommentIcon";
import LinkIcon from "@mui/icons-material/Link";
import Divider from "@mui/material/Divider";
import ConfirmDialog from "~/components/ConfirmDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDeleteComment } from "../useDeleteComment";
import EditIcon from "@mui/icons-material/Edit";
import { track } from "~/analytics/events";

const COMMENT_FRAGMENT = graphql`
  fragment UserCommentActionsMenu_commentFragment on CommentType {
    id
    uuid
    isAuthor
    pinnedAt
    ...useCommentPinnedAt_commentFragment
  }
`;

const CURRENT_USER_ACCOUNT_FRAGMENT = graphql`
  fragment UserCommentActionsMenu_currentUserAccountFragment on AccountType {
    role
  }
`;

type UserCommentActionsMenuProps = {
  comment: UserCommentActionsMenu_commentFragment$key;
  contractId: string;
  currentUser: UserCommentActionsMenu_currentUserAccountFragment$key;
  onCommentPinnedAtToggleSuccess?: (action: "PIN" | "UNPIN") => void;
  onEditContentClick: () => void;
};

export function UserCommentActionsMenu(props: UserCommentActionsMenuProps) {
  const { openSnackbarWithProps, closeSnackbar } = useSnackbar();
  const comment = useFragment(COMMENT_FRAGMENT, props.comment);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] =
    React.useState(false);
  const { handleCommentPinnedAtToggle } = useCommentPinnedAtActions(
    props.contractId,
    comment,
    {
      onSuccess: (action) => {
        const message =
          action === "PIN"
            ? "Successfully pinned comment."
            : "Successfully unpinned comment.";
        openSnackbarWithProps({
          children: (
            <Alert onClick={closeSnackbar} severity="success">
              {message}
            </Alert>
          ),
        });
        props.onCommentPinnedAtToggleSuccess?.(action);
      },
      onResultError: (action) => {
        const message =
          action === "PIN"
            ? "There was an unexpected error pinning the comment."
            : "There was an unexpected error unpinning the comment.";
        openSnackbarWithProps({
          children: <Alert severity="error"></Alert>,
        });
      },
      onError: (action) => {
        const message =
          action === "PIN"
            ? "There was an unexpected error pinning the comment."
            : "There was an unexpected error unpinning the comment.";
        openSnackbarWithProps({
          children: <Alert severity="error">{message}</Alert>,
        });
      },
    },
  );
  const { handleDeleteCommentMutation } = useDeleteComment();
  const open = Boolean(anchorEl);

  const currentUser = useFragment(
    CURRENT_USER_ACCOUNT_FRAGMENT,
    props.currentUser,
  );

  function handleClick(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  }
  function handleClose() {
    setAnchorEl(null);
  }

  async function handleCopyCommentLink() {
    track("Comments Section Copy Comment Button Clicked");

    handleClose();

    const newUrl = new URL(window.location.href);
    newUrl.searchParams.set("focusedCommentUuid", comment.uuid);
    try {
      await navigator.clipboard.writeText(newUrl.toString());
      openSnackbarWithProps({
        children: (
          <Alert onClick={closeSnackbar} severity="success">
            Copied comment link to clipboard
          </Alert>
        ),
      });
    } catch (err) {
      console.error("Could not copy comment link to clipboard: ", err);
    }
  }

  function handleDeleteComment() {
    track("Comments Section Delete Comment Button Clicked");

    setIsConfirmDeleteDialogOpen(false);

    const commentsListConnectionId = ConnectionHandler.getConnectionID(
      props.contractId,
      "CommentList_contractFragment_comments",
    );

    function onError() {
      openSnackbarWithProps({
        children: (
          <Alert onClick={closeSnackbar} severity="error">
            There was an unexpected error deleting the comment.
          </Alert>
        ),
      });
    }

    handleDeleteCommentMutation(
      comment.id,
      comment.uuid,
      [commentsListConnectionId],
      {
        // we use an optimistic updater so don't bother showing a success alert
        onSuccess: () => {
          console.debug(`Successfully deleted comment. uuid: ${comment.uuid}`);
        },
        // we don't currently expect any result errors
        onResultError: onError,
        onError,
      },
    );
  }

  const permissions = getPermissions(currentUser, comment);

  return (
    <React.Fragment>
      <Tooltip title="Comment Actions">
        <IconButton
          onClick={handleClick}
          size="small"
          aria-controls={open ? "comment-actions-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          data-testid="user-comment-actions-menu-icon-button"
        >
          <MenuIcon />
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        id="comment-actions-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {permissions.canEditPinnedAt && (
          <div>
            <MenuItem
              onClick={() => {
                if (comment.pinnedAt) {
                  track("Comments Section Unpin Comment Button Clicked");
                } else {
                  track("Comments Section Pin Comment Button Clicked");
                }

                handleCommentPinnedAtToggle();
              }}
              data-testid="user-comment-actions-menu-toggle-comment-is-pinned"
            >
              {comment.pinnedAt ? (
                <>
                  <ListItemIcon>
                    <UnpinCommentIcon />
                  </ListItemIcon>
                  Unpin Comment
                </>
              ) : (
                <>
                  <ListItemIcon>
                    <PinCommentIcon />
                  </ListItemIcon>
                  Pin to Top
                </>
              )}
            </MenuItem>
            <Divider variant="middle" />
          </div>
        )}
        <MenuItem
          onClick={handleCopyCommentLink}
          data-testid="user-comment-actions-menu-copy-comment-link"
        >
          <ListItemIcon>
            <LinkIcon />
          </ListItemIcon>
          Copy Link
        </MenuItem>
        {permissions.canEditContent && (
          <div>
            <Divider variant="middle" />
            <MenuItem
              onClick={() => props.onEditContentClick()}
              data-testid="user-comment-actions-menu-edit-content-button"
            >
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
              Edit comment
            </MenuItem>
          </div>
        )}
        {permissions.canDelete && (
          <div>
            <Divider variant="middle" />
            <MenuItem
              onClick={() => setIsConfirmDeleteDialogOpen(true)}
              data-testid="user-comment-actions-menu-delete-comment"
            >
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              Delete comment
            </MenuItem>
          </div>
        )}
      </Menu>
      <ConfirmDialog
        title="Delete Comment?"
        open={isConfirmDeleteDialogOpen}
        onClose={() => setIsConfirmDeleteDialogOpen(false)}
        onConfirm={handleDeleteComment}
        noText="Cancel"
        yesText="Delete"
      >
        Are you sure you want to delete this comment? Deleted comments cannot be
        restored.
      </ConfirmDialog>
    </React.Fragment>
  );
}

function getPermissions(
  currentUser: UserCommentActionsMenu_currentUserAccountFragment$data,
  comment: UserCommentActionsMenu_commentFragment$data,
) {
  const userRole = currentUser.role;
  const isAdmin =
    userRole === "pm_admin" || userRole === "ac_mngr" || userRole === "admin";

  return {
    canEditContent: isAdmin || comment.isAuthor,
    canEditPinnedAt: isAdmin || comment.isAuthor,
    canDelete: isAdmin || comment.isAuthor,
  };
}
