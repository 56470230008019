/**
 * @generated SignedSource<<2d1b776abc504a8c7346de1778d6ee5d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserCommentRevisionsMenu_commentQuery$variables = {
  commentUuid: string;
};
export type UserCommentRevisionsMenu_commentQuery$data = {
  readonly comment: {
    readonly " $fragmentSpreads": FragmentRefs<"UserCommentRevisionsMenu_commentFragment">;
  } | null | undefined;
};
export type UserCommentRevisionsMenu_commentQuery = {
  response: UserCommentRevisionsMenu_commentQuery$data;
  variables: UserCommentRevisionsMenu_commentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "commentUuid"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "uuid",
    "variableName": "commentUuid"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserCommentRevisionsMenu_commentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "comment",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserCommentRevisionsMenu_commentFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserCommentRevisionsMenu_commentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "comment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isCommentResultType"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CommentContentEditRevisionType",
                "kind": "LinkedField",
                "name": "contentEditRevisions",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AccountType",
                    "kind": "LinkedField",
                    "name": "actor",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "email",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "dateCreated",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "uuid",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "CommentType",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "debugMessage",
                "storageKey": null
              }
            ],
            "type": "ResultError",
            "abstractKey": "__isResultError"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/)
            ],
            "type": "Node",
            "abstractKey": "__isNode"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a0e72ba2ed18b717f41a0b975d8d5a36",
    "id": null,
    "metadata": {},
    "name": "UserCommentRevisionsMenu_commentQuery",
    "operationKind": "query",
    "text": "query UserCommentRevisionsMenu_commentQuery(\n  $commentUuid: String!\n) {\n  comment(uuid: $commentUuid) {\n    __typename\n    ...UserCommentRevisionsMenu_commentFragment\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment UserCommentRevisionsMenu_commentFragment on CommentResultType {\n  __isCommentResultType: __typename\n  __typename\n  ... on CommentType {\n    contentEditRevisions {\n      ...UserCommentRevisionsMenu_contentEditRevisionFragment\n    }\n  }\n  ... on ResultError {\n    __isResultError: __typename\n    debugMessage\n  }\n}\n\nfragment UserCommentRevisionsMenu_contentEditRevisionFragment on CommentContentEditRevisionType {\n  actor {\n    email\n    id\n  }\n  dateCreated\n  uuid\n}\n"
  }
};
})();

(node as any).hash = "8b043c527710b995b80827c753190921";

export default node;
