/**
 * @generated SignedSource<<5294be75901bcef864cb38c5891d68cb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContractPageHeaderActions_accountFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ReplaceContractDialog_accountFragment" | "SendContractDialog_accountFragment" | "UpdateContractDialog_accountFragment" | "useContractPageHeaderActionsPermissions_accountFragment">;
  readonly " $fragmentType": "ContractPageHeaderActions_accountFragment";
};
export type ContractPageHeaderActions_accountFragment$key = {
  readonly " $data"?: ContractPageHeaderActions_accountFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContractPageHeaderActions_accountFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "contractUuid"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContractPageHeaderActions_accountFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SendContractDialog_accountFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ReplaceContractDialog_accountFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UpdateContractDialog_accountFragment"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "contractUuid",
          "variableName": "contractUuid"
        }
      ],
      "kind": "FragmentSpread",
      "name": "useContractPageHeaderActionsPermissions_accountFragment"
    }
  ],
  "type": "AccountType",
  "abstractKey": null
};

(node as any).hash = "7dc8c518edd1ff432d6a6ec486bba2e1";

export default node;
