/**
 * @generated SignedSource<<91a0820530bcaf9b8b532a9906ff6946>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ContractsContractDateRenewEveryChoices = "MO" | "NVR" | "QTR" | "SMA" | "SPC" | "YR" | "YR10" | "YR2" | "YR3" | "YR4" | "YR5" | "YR7" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type TerminationDateAndReminder_ContractDateFragment$data = {
  readonly autoRenew: boolean;
  readonly date: any | null | undefined;
  readonly enableReminder: boolean;
  readonly id: string;
  readonly label: string;
  readonly reminder: {
    readonly addCustomMessage: boolean;
    readonly attachDocument: boolean;
    readonly customMessage: string | null | undefined;
    readonly id: string;
    readonly includeRelatedDocuments: boolean;
    readonly isactive: boolean | null | undefined;
    readonly recipients: ReadonlyArray<string | null | undefined> | null | undefined;
    readonly remindOn: any | null | undefined;
    readonly remindOnType: string | null | undefined;
    readonly repeatEvery: string;
    readonly " $fragmentSpreads": FragmentRefs<"EditDateDialog_reminderFragment">;
  } | null | undefined;
  readonly renewDate: any | null | undefined;
  readonly renewEvery: ContractsContractDateRenewEveryChoices | null | undefined;
  readonly repeat: boolean;
  readonly uuid: string;
  readonly " $fragmentType": "TerminationDateAndReminder_ContractDateFragment";
};
export type TerminationDateAndReminder_ContractDateFragment$key = {
  readonly " $data"?: TerminationDateAndReminder_ContractDateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"TerminationDateAndReminder_ContractDateFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TerminationDateAndReminder_ContractDateFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uuid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "autoRenew",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "renewEvery",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "renewDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "repeat",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enableReminder",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ReminderType",
      "kind": "LinkedField",
      "name": "reminder",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EditDateDialog_reminderFragment"
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "remindOnType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "remindOn",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "repeatEvery",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "addCustomMessage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customMessage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "recipients",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isactive",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "attachDocument",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "includeRelatedDocuments",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ContractDateType",
  "abstractKey": null
};
})();

(node as any).hash = "ca357fd86e5a675714d37715b0e13823";

export default node;
