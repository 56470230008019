import { useMutation } from "react-relay";
import type { GraphQLTaggedNode, UseMutationConfig } from "react-relay";
import type { MutationParameters } from "relay-runtime";

export default function useAsyncMutation<
  AsyncMutationParameters extends MutationParameters,
>(mutation: GraphQLTaggedNode) {
  const [commit, isInFlight] = useMutation<AsyncMutationParameters>(mutation);
  return [
    async (
      args: UseMutationConfig<AsyncMutationParameters> = { variables: {} },
    ) => {
      return await new Promise<AsyncMutationParameters["response"]>(
        (resolve, reject) => {
          commit({
            ...args,
            onCompleted: (response, errors) => {
              if (errors) {
                reject(errors[0]);
              }
              resolve(response);
            },
            onError: (error) => reject(error),
          });
        },
      );
    },
    isInFlight,
  ] as const;
}
