import React from "react";
import { useContractDownload } from "./useContractDownload";
import invariant from "~/utils/invariant";

export type ContractPageHeaderActions = ReturnType<
  typeof useContractPageHeaderActions
>;

export function useContractPageHeaderActions() {
  const { commitDownload, commitDocxDownload } = useContractDownload();

  async function commitDownloadContractFile(args: {
    contractUuid: string;
    fileDownloadName: string;
  }) {
    try {
      invariant(args.fileDownloadName);
      const result = await commitDownload({
        contractUuid: args.contractUuid,
        fileDownloadName: args.fileDownloadName,
      });
      return result;
    } catch (error) {
      return { state: "DOWNLOAD_ERROR", error } as const;
    }
  }

  async function commitDownloadContractFileWord(args: {
    contractUuid: string;
    fileDownloadName: string;
    filename: string;
  }) {
    invariant(args.fileDownloadName);
    const result = await commitDocxDownload({
      contractUuid: args.contractUuid,
      fileDownloadName: args.fileDownloadName,
      filename: args.filename,
    });
    return result;
  }

  return {
    commitDownloadContractFile,
    commitDownloadContractFileWord,
  };
}
