/**
 * @generated SignedSource<<15b7a9491d976b1ecb4fb234b045d2ca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useTemplateFieldValues_templateInlineFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"templateVariableHelpers_templateFragment">;
  readonly " $fragmentType": "useTemplateFieldValues_templateInlineFragment";
};
export type useTemplateFieldValues_templateInlineFragment$key = {
  readonly " $data"?: useTemplateFieldValues_templateInlineFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useTemplateFieldValues_templateInlineFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useTemplateFieldValues_templateInlineFragment"
};

(node as any).hash = "e4820c868e95005db7addf9c80e695bc";

export default node;
