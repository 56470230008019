/**
 * @generated SignedSource<<8af289a778654ecb17916bd3acaf2fb0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditFormDialog_accountFragment$data = {
  readonly id: string;
  readonly isAcmanager: boolean;
  readonly isAdmin: boolean;
  readonly organization: {
    readonly customFields: ReadonlyArray<{
      readonly fieldType: string;
      readonly id: string;
      readonly name: string;
    }> | null | undefined;
    readonly defaultForm: {
      readonly id: string;
      readonly name: string | null | undefined;
      readonly requiredFields: ReadonlyArray<{
        readonly __typename: "BuiltinFieldType";
        readonly fieldType: string;
        readonly id: string;
        readonly name: string;
      } | {
        readonly __typename: "CustomFieldType";
        readonly fieldType: string;
        readonly id: string;
        readonly name: string;
      } | {
        readonly __typename: "FieldSetType";
        readonly id: string;
        readonly name: string;
      } | {
        // This will never be '%other', but we need some
        // value in case none of the concrete values match.
        readonly __typename: "%other";
      } | null | undefined> | null | undefined;
      readonly sections: ReadonlyArray<{
        readonly fields: ReadonlyArray<{
          readonly __typename: "BuiltinFieldType";
          readonly choices: ReadonlyArray<{
            readonly id?: string;
            readonly name?: string;
          } | null | undefined> | null | undefined;
          readonly fieldType: string;
          readonly id: string;
          readonly name: string;
        } | {
          readonly __typename: "CustomFieldType";
          readonly choices: ReadonlyArray<{
            readonly id: string;
            readonly name: string;
          } | null | undefined> | null | undefined;
          readonly fieldType: string;
          readonly id: string;
          readonly name: string;
        } | {
          readonly __typename: "FieldSetType";
          readonly id: string;
          readonly name: string;
        } | {
          // This will never be '%other', but we need some
          // value in case none of the concrete values match.
          readonly __typename: "%other";
        } | null | undefined> | null | undefined;
        readonly name: string;
      } | null | undefined> | null | undefined;
      readonly uuid: string;
    } | null | undefined;
    readonly forms: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"FormSelect_formFragment">;
    } | null | undefined> | null | undefined;
    readonly requiredFieldsEnabled: boolean | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "EditFormDialog_accountFragment";
};
export type EditFormDialog_accountFragment$key = {
  readonly " $data"?: EditFormDialog_accountFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditFormDialog_accountFragment">;
};

import EditFormDialogRefetchQuery_graphql from './EditFormDialogRefetchQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fieldType",
  "storageKey": null
},
v3 = [
  (v0/*: any*/),
  (v1/*: any*/),
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v6 = {
  "kind": "InlineFragment",
  "selections": (v5/*: any*/),
  "type": "FieldSetType",
  "abstractKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": EditFormDialogRefetchQuery_graphql,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "EditFormDialog_accountFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isAdmin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isAcmanager",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationType",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CustomFieldType",
          "kind": "LinkedField",
          "name": "customFields",
          "plural": true,
          "selections": (v3/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "FormType",
          "kind": "LinkedField",
          "name": "defaultForm",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "uuid",
              "storageKey": null
            },
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "FormSectionType",
              "kind": "LinkedField",
              "name": "sections",
              "plural": true,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "fields",
                  "plural": true,
                  "selections": [
                    (v4/*: any*/),
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v0/*: any*/),
                        (v1/*: any*/),
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ValueListItemType",
                          "kind": "LinkedField",
                          "name": "choices",
                          "plural": true,
                          "selections": (v5/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "type": "CustomFieldType",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v0/*: any*/),
                        (v1/*: any*/),
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": null,
                          "kind": "LinkedField",
                          "name": "choices",
                          "plural": true,
                          "selections": [
                            {
                              "kind": "InlineFragment",
                              "selections": (v5/*: any*/),
                              "type": "StringChoiceType",
                              "abstractKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "BuiltinFieldType",
                      "abstractKey": null
                    },
                    (v6/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "requiredFields",
              "plural": true,
              "selections": [
                (v4/*: any*/),
                {
                  "kind": "InlineFragment",
                  "selections": (v3/*: any*/),
                  "type": "CustomFieldType",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": (v3/*: any*/),
                  "type": "BuiltinFieldType",
                  "abstractKey": null
                },
                (v6/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "FormType",
          "kind": "LinkedField",
          "name": "forms",
          "plural": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "FormSelect_formFragment"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "requiredFieldsEnabled",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v0/*: any*/)
  ],
  "type": "AccountType",
  "abstractKey": null
};
})();

(node as any).hash = "0f4af78e83d686302cd268884959a333";

export default node;
