/**
 * @generated SignedSource<<6eb4a69871fa148e1f36fbbac7d70e09>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DateAndReminder_UnmarkCompleteMutation$variables = {
  dateUuid: string;
};
export type DateAndReminder_UnmarkCompleteMutation$data = {
  readonly unmarkContractDateComplete: {
    readonly date: {
      readonly " $fragmentSpreads": FragmentRefs<"EditDateDialog_dateFragment">;
    } | null | undefined;
  } | null | undefined;
};
export type DateAndReminder_UnmarkCompleteMutation = {
  response: DateAndReminder_UnmarkCompleteMutation$data;
  variables: DateAndReminder_UnmarkCompleteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "dateUuid"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "dateUuid",
        "variableName": "dateUuid"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DateAndReminder_UnmarkCompleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UnmarkContractDateCompletePayload",
        "kind": "LinkedField",
        "name": "unmarkContractDateComplete",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractDateType",
            "kind": "LinkedField",
            "name": "date",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EditDateDialog_dateFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DateAndReminder_UnmarkCompleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UnmarkContractDateCompletePayload",
        "kind": "LinkedField",
        "name": "unmarkContractDateComplete",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractDateType",
            "kind": "LinkedField",
            "name": "date",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "uuid",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "label",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "date",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "repeat",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "repeatEveryNum",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "recurSpan",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "recurType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "recursUntil",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "numberOfOccurences",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "enableReminder",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "trackCompletion",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "protected",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "DateCompletionTypeConnection",
                "kind": "LinkedField",
                "name": "completions",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DateCompletionTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DateCompletionType",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "completedOn",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AccountType",
                            "kind": "LinkedField",
                            "name": "completedBy",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "email",
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "completions(first:1)"
              },
              {
                "alias": null,
                "args": (v3/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "dateFragment_completions",
                "kind": "LinkedHandle",
                "name": "completions"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ReminderType",
                "kind": "LinkedField",
                "name": "reminder",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "remindOnType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "remindOn",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "repeatEvery",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "addCustomMessage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "customMessage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "recipients",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isactive",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "attachDocument",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "includeRelatedDocuments",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "007132dbe401862ad35de0522b5d6af3",
    "id": null,
    "metadata": {},
    "name": "DateAndReminder_UnmarkCompleteMutation",
    "operationKind": "mutation",
    "text": "mutation DateAndReminder_UnmarkCompleteMutation(\n  $dateUuid: String!\n) {\n  unmarkContractDateComplete(input: {dateUuid: $dateUuid}) {\n    date {\n      ...EditDateDialog_dateFragment\n      id\n    }\n  }\n}\n\nfragment EditDateDialog_dateFragment on ContractDateType {\n  id\n  uuid\n  label\n  date\n  repeat\n  repeatEveryNum\n  recurSpan\n  recurType\n  recursUntil\n  numberOfOccurences\n  enableReminder\n  trackCompletion\n  protected\n  completions(first: 1) {\n    edges {\n      node {\n        completedOn\n        completedBy {\n          email\n          id\n        }\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  reminder {\n    ...EditDateDialog_reminderFragment\n    id\n    remindOnType\n    remindOn\n    repeatEvery\n    addCustomMessage\n    customMessage\n    recipients\n    isactive\n    attachDocument\n    includeRelatedDocuments\n  }\n}\n\nfragment EditDateDialog_reminderFragment on ReminderType {\n  id\n  remindOnType\n  remindOn\n  repeatEvery\n  addCustomMessage\n  customMessage\n  recipients\n  isactive\n  attachDocument\n  includeRelatedDocuments\n}\n"
  }
};
})();

(node as any).hash = "7c8974fcbe5f7a644b1f0b654e91d7a0";

export default node;
