import React, { Suspense } from "react";
import { ContractPageLayout } from "./layout/ContractPageLayout";
import { Outlet, useLoaderData, useParams } from "react-router";
import { graphql } from "relay-runtime";
import { loadQuery, usePreloadedQuery } from "react-relay";
import environment from "~/RelayEnvironment";
import {
  ContractPageHeader_contractQuery,
  type TContractPageHeader_contractQuery,
} from "./layout/ContractPageHeader";
import invariant from "~/utils/invariant";
import {
  DeferredLayoutQueryProvider,
  useDeferredLayoutQuery_rootQuery,
  type TuseDeferredLayoutQuery_rootQuery,
} from "./layout/useDeferredLayoutQuery";

export function clientLoader(args: any) {
  const contractPageHeaderQueryRef =
    loadQuery<TContractPageHeader_contractQuery>(
      environment,
      ContractPageHeader_contractQuery,
      {
        uuid: args.params.uuid,
      },
    );

  const useDeferredLayoutQuery_rootQueryRef =
    loadQuery<TuseDeferredLayoutQuery_rootQuery>(
      environment,
      useDeferredLayoutQuery_rootQuery,
      {
        contractUuid: args.params.uuid,
      },
    );

  return {
    contractPageHeaderQueryRef,
    useDeferredLayoutQuery_rootQueryRef,
  };
}

export function Layout() {
  const { contractPageHeaderQueryRef, useDeferredLayoutQuery_rootQueryRef } =
    useLoaderData<typeof clientLoader>();
  const { uuid: contractUuid } = useParams();
  invariant(contractUuid);

  return (
    <DeferredLayoutQueryProvider
      useDeferredLayoutQuery_rootQueryRef={useDeferredLayoutQuery_rootQueryRef}
    >
      <ContractPageLayout
        contractPageHeaderQueryRef={contractPageHeaderQueryRef}
        contractUuid={contractUuid}
      >
        <Outlet />
      </ContractPageLayout>
    </DeferredLayoutQueryProvider>
  );
}

export function LayoutWithFallback() {
  // we don't expect a long load time for the layout so avoid flashing a spinner
  return (
    <Suspense fallback={<div></div>}>
      <Layout />
    </Suspense>
  );
}
