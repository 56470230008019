/**
 * @generated SignedSource<<e658124959088cfd6c909e962938800c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditFormDialog_deleteFormMutation$variables = {
  id: string;
};
export type EditFormDialog_deleteFormMutation$data = {
  readonly deleteForm: {
    readonly organization: {
      readonly forms: ReadonlyArray<{
        readonly " $fragmentSpreads": FragmentRefs<"FormSelect_formFragment">;
      } | null | undefined> | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type EditFormDialog_deleteFormMutation = {
  response: EditFormDialog_deleteFormMutation$data;
  variables: EditFormDialog_deleteFormMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fieldType",
  "storageKey": null
},
v6 = [
  (v2/*: any*/),
  (v3/*: any*/)
],
v7 = {
  "kind": "InlineFragment",
  "selections": [
    (v2/*: any*/)
  ],
  "type": "Node",
  "abstractKey": "__isNode"
},
v8 = {
  "kind": "InlineFragment",
  "selections": (v6/*: any*/),
  "type": "FieldSetType",
  "abstractKey": null
},
v9 = [
  (v2/*: any*/),
  (v3/*: any*/),
  (v5/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditFormDialog_deleteFormMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteFormPayload",
        "kind": "LinkedField",
        "name": "deleteForm",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationType",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "FormType",
                "kind": "LinkedField",
                "name": "forms",
                "plural": true,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "FormSelect_formFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditFormDialog_deleteFormMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteFormPayload",
        "kind": "LinkedField",
        "name": "deleteForm",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationType",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "FormType",
                "kind": "LinkedField",
                "name": "forms",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "uuid",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FormSectionType",
                    "kind": "LinkedField",
                    "name": "sections",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "fields",
                        "plural": true,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v2/*: any*/),
                              (v3/*: any*/),
                              (v5/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ValueListItemType",
                                "kind": "LinkedField",
                                "name": "choices",
                                "plural": true,
                                "selections": (v6/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "type": "CustomFieldType",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v2/*: any*/),
                              (v3/*: any*/),
                              (v5/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "choices",
                                "plural": true,
                                "selections": [
                                  (v4/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": (v6/*: any*/),
                                    "type": "StringChoiceType",
                                    "abstractKey": null
                                  },
                                  (v7/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "BuiltinFieldType",
                            "abstractKey": null
                          },
                          (v8/*: any*/),
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "requiredFields",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": (v9/*: any*/),
                        "type": "CustomFieldType",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v9/*: any*/),
                        "type": "BuiltinFieldType",
                        "abstractKey": null
                      },
                      (v8/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "86f56110cf9a5e95ca12da86e10e6d5f",
    "id": null,
    "metadata": {},
    "name": "EditFormDialog_deleteFormMutation",
    "operationKind": "mutation",
    "text": "mutation EditFormDialog_deleteFormMutation(\n  $id: ID!\n) {\n  deleteForm(input: {id: $id}) {\n    organization {\n      forms {\n        ...FormSelect_formFragment\n        id\n      }\n      id\n    }\n  }\n}\n\nfragment FormSelect_formFragment on FormType {\n  id\n  uuid\n  name\n  sections {\n    name\n    fields {\n      __typename\n      ... on CustomFieldType {\n        id\n        name\n        fieldType\n        choices {\n          id\n          name\n        }\n      }\n      ... on BuiltinFieldType {\n        id\n        name\n        fieldType\n        choices {\n          __typename\n          ... on StringChoiceType {\n            id\n            name\n          }\n          ... on Node {\n            __isNode: __typename\n            id\n          }\n        }\n      }\n      ... on FieldSetType {\n        id\n        name\n      }\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n    }\n  }\n  requiredFields {\n    __typename\n    ... on CustomFieldType {\n      id\n      name\n      fieldType\n    }\n    ... on BuiltinFieldType {\n      id\n      name\n      fieldType\n    }\n    ... on FieldSetType {\n      id\n      name\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8c857c3c3147357edc34f840de430f83";

export default node;
