import React, { useState, useEffect } from "react";
import { graphql, useFragment } from "react-relay";
import useAsyncMutation from "~/utils/UseAsyncMutation";
import {
  parseISO,
  endOfTomorrow,
  isFuture,
  subDays,
  differenceInCalendarDays,
  isValid,
} from "date-fns";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import { ToggleButtonGroup, ToggleButton } from "~/components/ToggleButton";
import { InputLabel, TextField, DateField, Select } from "~/components/Field";
import ValueSetAutocomplete from "~/components/ValueSetAutocomplete";
import Button from "~/components/Button";
import Paper from "~/components/Paper";
import ConfirmDialog from "~/components/ConfirmDialog";
import LeftDialogActions from "~/components/LeftDialogActions";
import MessageDialog from "~/components/MessageDialog";
import { boxMargin, GroupBox } from "~/components/Boxes";
import { track } from "~/analytics/events";

const fieldWidth = 205;

const REMINDER_FRAGMENT = graphql`
  fragment EditDateDialog_reminderFragment on ReminderType {
    id
    remindOnType
    remindOn
    repeatEvery
    addCustomMessage
    customMessage
    recipients
    isactive
    attachDocument
    includeRelatedDocuments
  }
`;

const DATE_FRAGMENT = graphql`
  fragment EditDateDialog_dateFragment on ContractDateType {
    id
    uuid
    label
    date
    repeat
    repeatEveryNum
    recurSpan
    recurType
    recursUntil
    numberOfOccurences
    enableReminder
    trackCompletion
    protected
    completions(first: 1) @connection(key: "dateFragment_completions") {
      edges {
        node {
          completedOn
          completedBy {
            email
          }
        }
      }
    }
    reminder {
      ...EditDateDialog_reminderFragment
      ...EditDateDialog_reminderFragment @relay(mask: false)
    }
  }
`;

const CONTRACT_FRAGMENT = graphql`
  fragment EditDateDialog_contractFragment on ContractType {
    uuid
    effectiveDate {
      ...EditDateDialog_dateFragment
    }
    terminationDate {
      ...EditDateDialog_dateFragment
    }
    deadlineToNonrenew {
      ...EditDateDialog_dateFragment
    }
    customFieldValues {
      valueDate {
        ...EditDateDialog_dateFragment
      }
    }
    dates {
      ...EditDateDialog_dateFragment
    }
  }
`;

export function calcRepeatParams(repeatSettings) {
  return {
    ...repeatSettings,
    repeatEveryNum: repeatSettings.repeatEveryNum
      ? parseInt(repeatSettings.repeatEveryNum)
      : undefined,
    recursUntil: graphqlDate(repeatSettings.recursUntil),
    numberOfOccurences: repeatSettings.numberOfOccurences
      ? parseInt(repeatSettings.numberOfOccurences)
      : undefined,
  };
}

export function validateRepeatSettings(repeatSettings) {
  return (
    !repeatSettings.repeat ||
    (parseInt(repeatSettings.repeatEveryNum) > 0 &&
      (repeatSettings.recurType == "A_1" ||
        (repeatSettings.recurType == "A_2" &&
          isValid(repeatSettings.recursUntil)) ||
        (repeatSettings.recurType == "A_3" &&
          parseInt(repeatSettings.numberOfOccurences) > 0)))
  );
}

export function DateRepeatSettings({
  repeatSettings: {
    repeat,
    repeatEveryNum,
    recurSpan,
    recurType,
    numberOfOccurences,
    recursUntil,
  },
  setRepeatSettings,
  dateFormat,
}) {
  return (
    <>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={repeat}
              onChange={(e) =>
                setRepeatSettings((rs) => ({
                  ...rs,
                  repeatEveryNum: rs.repeatEveryNum || 1,
                  repeat: e.target.checked,
                }))
              }
            />
          }
          label="Repeats"
        />
      </Grid>
      {repeat ? (
        <Grid container item spacing={1} alignItems="center">
          <Grid item>every</Grid>
          <Grid item>
            <TextField
              size="small"
              value={repeatEveryNum}
              onChange={(e) =>
                setRepeatSettings((rs) => ({
                  ...rs,
                  repeatEveryNum: e.target.value,
                }))
              }
              style={{ width: 44 }}
            />
          </Grid>
          <Grid item>
            <Select
              size="small"
              value={recurSpan}
              onChange={(e) =>
                setRepeatSettings((rs) => ({
                  ...rs,
                  recurSpan: e.target.value,
                }))
              }
            >
              <MenuItem value="DAY">Days</MenuItem>
              <MenuItem value="MO">Months</MenuItem>
              <MenuItem value="YR">Years</MenuItem>
            </Select>
          </Grid>
          <Grid item>until</Grid>
          <Grid item>
            <Select
              size="small"
              value={recurType}
              onChange={({ target: { value } }) => {
                if (value == "A_3") {
                  setRepeatSettings((rs) => ({
                    ...rs,
                    numberOfOccurences: numberOfOccurences || 1,
                  }));
                }
                setRepeatSettings((rs) => ({ ...rs, recurType: value }));
              }}
            >
              <MenuItem value="A_1">Contract Terminates</MenuItem>
              <MenuItem value="A_2">Date</MenuItem>
              <MenuItem value="A_3">Number of Times</MenuItem>
            </Select>
          </Grid>
          {recurType == "A_2" ? (
            <>
              <Grid item>
                <DateField
                  format={dateFormat}
                  value={recursUntil}
                  onChange={(value) =>
                    setRepeatSettings((rs) => ({ ...rs, recursUntil: value }))
                  }
                />
              </Grid>
            </>
          ) : null}
          {recurType == "A_3" ? (
            <>
              <Grid item>
                <TextField
                  size="small"
                  value={numberOfOccurences}
                  onChange={(e) =>
                    setRepeatSettings((rs) => ({
                      ...rs,
                      numberOfOccurences: e.target.value,
                    }))
                  }
                  style={{ width: 44 }}
                />
              </Grid>
            </>
          ) : null}
        </Grid>
      ) : null}
    </>
  );
}

export function calcReminderParams(reminderSettings) {
  return {
    enableReminder: reminderSettings.enableReminder,
    trackCompletion: reminderSettings.trackCompletion,
    reminder: {
      repeatEvery: reminderSettings.repeatEvery,
      remindOnType:
        reminderSettings.reminderType == "specific"
          ? "spc"
          : reminderSettings.daysBefore,
      remindOn: graphqlDate(reminderSettings.remindOn),
      recipients: reminderSettings.recipients,
      keepExistingRecipients: reminderSettings.keepExistingRecipients,
      attachDocument: !!reminderSettings.attachDocument,
      addCustomMessage: !!reminderSettings.addCustomMessage,
      customMessage: reminderSettings.customMessage,
      includeRelatedDocuments:
        reminderSettings.includeRelatedDocuments ?? false,
    },
  };
}

export function validateReminderSettings(reminderSettings, dirty, date) {
  const daysBeforeInvalid = !(parseInt(reminderSettings.daysBefore) > 0);
  const daysBeforePassed =
    dirty && date && !isFuture(subDays(date, reminderSettings.daysBefore));
  const repeatEveryInvalid =
    reminderSettings.repeatEvery != "nvr" &&
    (!(parseInt(reminderSettings.repeatEvery) > 0) ||
      (reminderSettings.reminderType == "before" &&
        parseInt(reminderSettings.repeatEvery) >=
          parseInt(reminderSettings.daysBefore)) ||
      (reminderSettings.reminderType == "specific" &&
        date &&
        reminderSettings.repeatEvery >=
          differenceInCalendarDays(date, reminderSettings.remindOn)));

  const reminderValid =
    !reminderSettings.enableReminder ||
    ((!date || isFuture(date)) &&
      !repeatEveryInvalid &&
      reminderSettings.recipients.length > 0 &&
      ((reminderSettings.reminderType == "specific" &&
        isFuture(reminderSettings.remindOn)) ||
        (reminderSettings.reminderType == "before" &&
          !daysBeforeInvalid &&
          !daysBeforePassed)));
  return {
    daysBeforeInvalid,
    daysBeforePassed,
    repeatEveryInvalid,
    reminderValid,
  };
}

export function DateReminderSettings({
  reminderSettings: {
    enableReminder,
    repeatEvery,
    reminderType,
    daysBefore,
    remindOn,
    recipients,
    attachDocument,
    addCustomMessage,
    customMessage,
    trackCompletion,
    includeRelatedDocuments,
  },
  setReminderSettings,
  daysBeforeInvalid,
  daysBeforePassed,
  repeatEveryInvalid,
  canTrackCompletion,
  dateFormat,
}) {
  return (
    <>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={enableReminder}
              data-testid="editDateDialogEnableReminderCheckbox"
              onChange={(e) =>
                setReminderSettings((rs) => ({
                  ...rs,
                  enableReminder: e.target.checked,
                }))
              }
            />
          }
          label="Reminder"
          sx={{ zIndex: 1 }}
        />
      </Grid>
      {enableReminder ? (
        <Grid container item spacing={7} alignItems="flex-start">
          <Grid item xs={7}>
            <Grid container spacing={8}>
              <Grid item xs={12}>
                <Paper style={{ padding: 10 }}>
                  <Grid
                    container
                    spacing={4}
                    alignItems="flex-start"
                    justifyContent="flex-start"
                  >
                    <Grid item xs={12}>
                      <ToggleButtonGroup
                        exclusive
                        size="small"
                        value={reminderType}
                        onChange={(e, v) => {
                          setReminderSettings((rs) => ({
                            ...rs,
                            reminderType: v,
                          }));
                        }}
                        aria-label="date type"
                        sx={{ width: 230 }}
                      >
                        <ToggleButton
                          value="before"
                          aria-label="before event"
                          disabled={!enableReminder}
                          sx={{ width: "50%" }}
                        >
                          Before Event
                        </ToggleButton>
                        <ToggleButton
                          value="specific"
                          aria-label="specific date"
                          disabled={!enableReminder}
                          sx={{ width: "50%" }}
                        >
                          Specific Date
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Grid>
                    <Grid item xs={12}>
                      <div style={{ paddingLeft: 8 }}>
                        {reminderType == "specific" ? (
                          <DateField
                            format={dateFormat}
                            minDate={endOfTomorrow()}
                            minDateMessage="Future date required for reminders"
                            value={remindOn}
                            onChange={(v) =>
                              setReminderSettings((rs) => ({
                                ...rs,
                                remindOn: v,
                              }))
                            }
                            disabled={!enableReminder}
                            style={{ width: 200 }}
                          />
                        ) : (
                          <FormControl
                            error={daysBeforeInvalid || daysBeforePassed}
                            style={{ width: "100%" }}
                          >
                            <Grid container spacing={1} alignItems="center">
                              <Grid item>Send a reminder</Grid>
                              <Grid item>
                                <TextField
                                  size="small"
                                  value={daysBefore}
                                  onChange={(e) =>
                                    setReminderSettings((rs) => ({
                                      ...rs,
                                      daysBefore: e.target.value,
                                    }))
                                  }
                                  disabled={!enableReminder}
                                  data-testid="editDateDialogDaysBeforeInput"
                                  style={{ width: 44 }}
                                />
                              </Grid>
                              <Grid item>day(s) before the event.</Grid>
                            </Grid>
                            {daysBeforeInvalid ? (
                              !daysBefore ? (
                                <FormHelperText id="error-text">
                                  Required
                                </FormHelperText>
                              ) : (
                                <FormHelperText id="error-text">
                                  Invalid entry
                                </FormHelperText>
                              )
                            ) : daysBeforePassed ? (
                              <FormHelperText id="error-text">
                                Reminder date has passed
                              </FormHelperText>
                            ) : null}
                          </FormControl>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item>
                          <Checkbox
                            checked={repeatEvery != "nvr"}
                            onChange={(e) =>
                              setReminderSettings((rs) => ({
                                ...rs,
                                repeatEvery: e.target.checked ? "" : "nvr",
                              }))
                            }
                            disabled={!enableReminder}
                          />
                        </Grid>
                        <Grid item>Repeat the reminder</Grid>
                        {repeatEvery != "nvr" ? (
                          <>
                            <Grid item>every</Grid>
                            <Grid item>
                              <TextField
                                size="small"
                                value={repeatEvery}
                                onChange={(e) =>
                                  setReminderSettings((rs) => ({
                                    ...rs,
                                    repeatEvery: e.target.value,
                                  }))
                                }
                                disabled={!enableReminder}
                                style={{ width: 40 }}
                              />
                            </Grid>
                            <Grid item>day(s).</Grid>
                            {repeatEvery && repeatEveryInvalid ? (
                              <Grid item xs={12}>
                                <FormControl
                                  error={repeatEveryInvalid}
                                  style={{ width: "100%" }}
                                >
                                  <FormHelperText id="error-text">
                                    The repeat period has to be shorter than the
                                    alert period.
                                  </FormHelperText>
                                </FormControl>
                              </Grid>
                            ) : null}
                          </>
                        ) : null}
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <InputLabel style={{ display: "contents" }}>
                  Recipients
                </InputLabel>
                {!recipients?.length ? (
                  <FormControl
                    style={{ float: "right" }}
                    error={!recipients?.length}
                  >
                    <FormHelperText id="error-text">
                      * A valid recipient is required.
                    </FormHelperText>
                  </FormControl>
                ) : null}
                <ValueSetAutocomplete
                  multiple
                  value={recipients}
                  onChange={(e, v) =>
                    setReminderSettings((rs) => ({
                      ...rs,
                      recipients: v
                        // Trim the whitespace so that an email isn't
                        // considered invalid because of it. Gives the user
                        // some leeway when either selecting an invalid
                        // email that's only invalid because of whitespace
                        // or accidentally including a space.
                        .map((em) => em.trim())
                        .filter((em) => validateEmail(em) === true),
                    }))
                  }
                  valueSetName="remindersto"
                  disabled={!enableReminder}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={attachDocument}
                      onChange={(e) =>
                        setReminderSettings((rs) => ({
                          ...rs,
                          attachDocument: e.target.checked,
                        }))
                      }
                      disabled={!enableReminder}
                    />
                  }
                  label="Include This Contract"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={includeRelatedDocuments}
                      onChange={(e) =>
                        setReminderSettings((rs) => ({
                          ...rs,
                          includeRelatedDocuments: e.target.checked,
                        }))
                      }
                      disabled={!enableReminder}
                    />
                  }
                  label="Include Related Documents"
                />
              </Grid>
              {canTrackCompletion ? (
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={trackCompletion}
                        onChange={(e) =>
                          setReminderSettings((rs) => ({
                            ...rs,
                            trackCompletion: e.target.checked,
                          }))
                        }
                      />
                    }
                    label="Add Completion Tracking"
                  />
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={addCustomMessage}
                      onChange={(e) =>
                        setReminderSettings((rs) => ({
                          ...rs,
                          addCustomMessage: e.target.checked,
                        }))
                      }
                      disabled={!enableReminder}
                    />
                  }
                  label="Add Custom Message"
                />
              </Grid>
              {addCustomMessage ? (
                <Grid item xs={12}>
                  <TextField
                    multiline
                    minRows={7}
                    maxRows={7}
                    value={customMessage}
                    onChange={(e) =>
                      setReminderSettings((rs) => ({
                        ...rs,
                        customMessage: e.target.value,
                      }))
                    }
                    disabled={!enableReminder}
                  />
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
}

function DeleteDateDialog({ contractUuid, date, open, handleClose }) {
  const [commitDelete] = useAsyncMutation(graphql`
    mutation EditDateDialog_RemoveContractDateMutation(
      $contractUuid: String!
      $dateUuid: String!
    ) {
      removeContractDate(
        input: { contractUuid: $contractUuid, dateUuid: $dateUuid }
      ) {
        contract {
          uuid
          dates {
            uuid
          }
        }
      }
    }
  `);

  async function handleConfirm() {
    handleClose();
    await commitDelete({
      variables: { contractUuid, dateUuid: date.uuid },
    });
  }

  return (
    <ConfirmDialog
      title="Delete Contract Date?"
      open={open}
      onClose={handleClose}
      onConfirm={handleConfirm}
      noText="Cancel"
      yesText="Delete"
    >
      Are you sure you want to delete this contract date?
    </ConfirmDialog>
  );
}

export default function EditDateDialog({
  openFor,
  setOpenFor,
  onCommit,
  contractUuid,
  userEmail,
  dateFormat,
  dateType,
  enablingReminder,
  ...props
}) {
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [errorDialog, setErrorDialog] = useState(null);
  const reminder = useFragment(REMINDER_FRAGMENT, openFor.reminder) || {};

  const [date, setDate] = useState(
    openFor.date ? parseISO(openFor.date) : null,
  );
  const [reminderSettings, setReminderSettings] = useState({
    enableReminder: openFor.enableReminder || enablingReminder || false,
    repeatEvery: reminder.repeatEvery || "nvr",
    reminderType: reminder.remindOnType == "spc" ? "specific" : "before",
    daysBefore: reminder.remindOnType == "spc" ? "" : reminder.remindOnType,
    remindOn: reminder.remindOn ? parseISO(reminder.remindOn) : null,
    recipients: reminder.recipients || [userEmail],
    attachDocument: reminder.attachDocument || false,
    includeRelatedDocuments: reminder.includeRelatedDocuments || false,
    addCustomMessage: reminder.addCustomMessage || false,
    customMessage: reminder.customMessage,
    trackCompletion: openFor.trackCompletion || false,
  });

  const [dirty, setDirty] = useState(false);
  const [initDirty, setInitDirty] = useState(false);
  useEffect(() => {
    if (!initDirty) {
      setInitDirty(true);
    } else {
      setDirty(true);
    }
  }, [date, reminderSettings.daysBefore]);

  const [label, setLabel] = useState(openFor.label || "");
  const [repeatSettings, setRepeatSettings] = useState({
    repeat: openFor.repeat || false,
    repeatEveryNum: openFor.repeatEveryNum || null,
    recurSpan: openFor.recurSpan || "MO",
    recurType: openFor.recurType || "A_1",
    recursUntil: openFor.recursUntil ? parseISO(openFor.recursUntil) : null,
    numberOfOccurences: openFor.numberOfOccurences || null,
  });

  const [commitAddDate] = useAsyncMutation(graphql`
    mutation EditDateDialog_AddDateMutation(
      $contractUuid: String!
      $date: ContractDateInput!
    ) {
      addContractDate(input: { contractUuid: $contractUuid, date: $date }) {
        contract {
          ...EditDateDialog_contractFragment
        }
        date {
          ...EditDateDialog_dateFragment
        }
      }
    }
  `);

  const [commitEditDate] = useAsyncMutation(graphql`
    mutation EditDateDialog_EditDateMutation(
      $contractUuid: String!
      $date: ContractDateInput!
    ) {
      updateContractDate(input: { contractUuid: $contractUuid, date: $date }) {
        contract {
          ...EditDateDialog_contractFragment
        }
      }
    }
  `);

  function handleClose() {
    setOpenFor(null);
  }

  async function handleUpdate() {
    const dateParams = {
      uuid: openFor.uuid || undefined,
      label,
      date: graphqlDate(date),
      ...calcReminderParams(reminderSettings),
      ...(dateType != "standard" ? calcRepeatParams(repeatSettings) : null),
    };

    try {
      if (openFor.uuid) {
        await commitEditDate({
          variables: {
            contractUuid,
            date: dateParams,
          },
        });
        onCommit();
      } else {
        await commitAddDate({
          variables: {
            contractUuid,
            date: dateParams,
          },
        });
        onCommit();
      }
      if (openFor.enableReminder === false && reminderSettings.enableReminder) {
        track("Contract Date Reminder Enabled");
      }

      handleClose();
    } catch (e) {
      console.log(e);
      setErrorDialog(e.message);
    }
  }

  const {
    daysBeforeInvalid,
    daysBeforePassed,
    repeatEveryInvalid,
    reminderValid,
  } = validateReminderSettings(reminderSettings, dirty, date);

  const disabled =
    !isValid(date) ||
    !label?.trim() ||
    !reminderValid ||
    !validateRepeatSettings(repeatSettings);

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="md"
        open={!!openFor}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        data-testid="editDateDialog"
      >
        <DialogTitle id="form-dialog-title">
          {openFor.uuid ? "Edit" : "Add"} Date
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12}>
              <GroupBox>
                {dateType == "contract" && !openFor.protected ? (
                  <Box width={fieldWidth} mx={boxMargin}>
                    <TextField
                      autoFocus
                      label="Label"
                      value={label}
                      onChange={(e) => setLabel(e.target.value)}
                      id="date-label"
                    />
                  </Box>
                ) : null}
                <Box width={fieldWidth} mx={boxMargin}>
                  <DateField
                    label={
                      dateType == "contract" && !openFor.protected
                        ? "Date"
                        : label
                    }
                    format={dateFormat}
                    minDate={endOfTomorrow()}
                    minDateMessage="Future date required for reminders"
                    value={date}
                    onChange={setDate}
                  />
                </Box>
              </GroupBox>
            </Grid>
            {dateType != "standard" && !props?.dnr ? (
              <DateRepeatSettings
                repeatSettings={repeatSettings}
                setRepeatSettings={setRepeatSettings}
                dateFormat={dateFormat}
              />
            ) : props?.dnr && props.dnrAutoRenew ? (
              <FormHelperText id="dnr-text">
                The termination date is set up to autorenew. Accordingly, the
                deadline to nonrenew date will automatically update along with
                the termination date.
              </FormHelperText>
            ) : props?.dnr ? (
              <FormHelperText id="dnr-text">
                The termination date is not set to autorenew. Accordingly, the
                deadline to nonrenew will not autoupdate unless the termination
                date is set to autorenew.
              </FormHelperText>
            ) : null}
            <DateReminderSettings
              reminderSettings={reminderSettings}
              setReminderSettings={setReminderSettings}
              daysBeforeInvalid={daysBeforeInvalid}
              daysBeforePassed={daysBeforePassed}
              repeatEveryInvalid={repeatEveryInvalid}
              canTrackCompletion={
                dateType != "standard" && !repeatSettings.repeat && !props?.dnr
              }
              dateFormat={dateFormat}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          {dateType == "contract" && openFor.uuid && !openFor.protected ? (
            <LeftDialogActions>
              <Button size="small" onClick={() => setDeleteOpen(true)}>
                Delete Date
              </Button>
            </LeftDialogActions>
          ) : null}
          <Button size="small" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            size="small"
            onClick={handleUpdate}
            primary
            disabled={disabled}
            id="save-date-button"
          >
            {openFor.uuid ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>
      <DeleteDateDialog
        contractUuid={contractUuid}
        date={openFor}
        open={deleteOpen}
        handleClose={() => {
          setDeleteOpen(false);
          handleClose();
        }}
      />
      <MessageDialog
        open={!!errorDialog}
        title={"Error"}
        onClose={() => setErrorDialog(null)}
      >
        {errorDialog}
      </MessageDialog>
    </>
  );
}
