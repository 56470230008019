import React from "react";
import "@fontsource/open-sans";
import Chip from "@mui/material/Chip";
import Clear from "@mui/icons-material/Clear";
import Add from "@mui/icons-material/Add";

const normalLabelPadding = 6;

export default function Pill({
  add,
  color,
  onClick,
  onDelete,
  clickable,
  size,
  sx,
  ...props
}) {
  const hasLeadingIcon = !!props.icon;
  const hasIcon = onClick || onDelete;

  const deleteIcon = add ? (
    <Add style={{ marginRight: 0 }} />
  ) : (
    <Clear style={{ marginRight: 0, color: "#ffffff" }} />
  );
  return (
    <Chip
      color={color || "primary"}
      onClick={onClick}
      clickable={clickable}
      onDelete={onDelete || (add ? onClick : null)}
      sx={{
        padding: "4px 8px",

        height: { tiny: 17, large: 35 }[size] || 27,

        borderRadius: "16px",

        margin: "4px 0px",

        fontFamily: "Open Sans",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: size == "tiny" ? "12px" : "14px",
        lineHeight: "19px",
        "&:hover": {
          opacity: clickable ? "60%" : undefined,
        },
        "&:active": {
          opacity: clickable ? "80%" : undefined,
        },
        "& .MuiChip-label": {
          paddingLeft: `${normalLabelPadding * (size == "tiny" ? 0.5 : 1) + (hasLeadingIcon ? 6 : 0)}px`,
          paddingRight: `${normalLabelPadding * (size == "tiny" ? 0.5 : 1) + (hasIcon ? 6 : 0)}px`,
        },
        "& .MuiChip-deleteIcon": {
          width: 16,
          height: 16,
        },
        ...sx,
      }}
      {...{ deleteIcon, ...props }}
    />
  );
}
