/**
 * @generated SignedSource<<1edc9332527885232cd037e705b5ca2f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ContractsContractDateRecurSpanChoices = "A_" | "DAY" | "MO" | "NVR" | "SPC" | "YR" | "%future added value";
export type ContractsContractDateRecurTypeChoices = "A_" | "A_1" | "A_2" | "A_3" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ContractForms_fieldValueFragment$data = {
  readonly __typename: "BuiltinFieldValueType";
  readonly currencySymbol: string | null | undefined;
  readonly field: {
    readonly id: string;
  } | null | undefined;
  readonly id: string;
  readonly value: string | null | undefined;
  readonly " $fragmentType": "ContractForms_fieldValueFragment";
} | {
  readonly __typename: "CustomFieldValueType";
  readonly currencySymbol: string | null | undefined;
  readonly field: {
    readonly id: string;
  } | null | undefined;
  readonly id: string;
  readonly value: string | null | undefined;
  readonly valueDate: {
    readonly completions: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly completedBy: {
            readonly email: string;
          } | null | undefined;
          readonly completedOn: string;
        } | null | undefined;
      } | null | undefined>;
    };
    readonly date: any | null | undefined;
    readonly enableReminder: boolean;
    readonly id: string;
    readonly label: string;
    readonly numberOfOccurences: number;
    readonly protected: boolean | null | undefined;
    readonly recurSpan: ContractsContractDateRecurSpanChoices;
    readonly recurType: ContractsContractDateRecurTypeChoices;
    readonly recursUntil: any | null | undefined;
    readonly reminder: {
      readonly addCustomMessage: boolean;
      readonly attachDocument: boolean;
      readonly customMessage: string | null | undefined;
      readonly id: string;
      readonly includeRelatedDocuments: boolean;
      readonly isactive: boolean | null | undefined;
      readonly recipients: ReadonlyArray<string | null | undefined> | null | undefined;
      readonly remindOn: any | null | undefined;
      readonly remindOnType: string | null | undefined;
      readonly repeatEvery: string;
      readonly " $fragmentSpreads": FragmentRefs<"EditDateDialog_reminderFragment">;
    } | null | undefined;
    readonly repeat: boolean;
    readonly repeatEveryNum: number | null | undefined;
    readonly trackCompletion: boolean;
    readonly uuid: string;
  } | null | undefined;
  readonly valueList: ReadonlyArray<{
    readonly author: string | null | undefined;
    readonly id: string;
    readonly itemDate: any | null | undefined;
    readonly name: string;
    readonly pairedValue: string | null | undefined;
    readonly sortindex: number;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "ContractForms_fieldValueFragment";
} | {
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  readonly __typename: "%other";
  readonly " $fragmentType": "ContractForms_fieldValueFragment";
};
export type ContractForms_fieldValueFragment$key = {
  readonly " $data"?: ContractForms_fieldValueFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContractForms_fieldValueFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currencySymbol",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContractForms_fieldValueFragment",
  "selections": [
    (v0/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BuiltinFieldType",
          "kind": "LinkedField",
          "name": "field",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        (v1/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/)
      ],
      "type": "BuiltinFieldValueType",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CustomFieldType",
          "kind": "LinkedField",
          "name": "field",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        (v1/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ValueListItemType",
          "kind": "LinkedField",
          "name": "valueList",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "pairedValue",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "author",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "itemDate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sortindex",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ContractDateType",
          "kind": "LinkedField",
          "name": "valueDate",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "uuid",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "label",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "date",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "repeat",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "repeatEveryNum",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "recurSpan",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "recurType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "recursUntil",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "numberOfOccurences",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "enableReminder",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "trackCompletion",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "protected",
              "storageKey": null
            },
            {
              "alias": "completions",
              "args": null,
              "concreteType": "DateCompletionTypeConnection",
              "kind": "LinkedField",
              "name": "__dateFragment_completions_connection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "DateCompletionTypeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "DateCompletionType",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "completedOn",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "AccountType",
                          "kind": "LinkedField",
                          "name": "completedBy",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "email",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        (v0/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cursor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "endCursor",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasNextPage",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ReminderType",
              "kind": "LinkedField",
              "name": "reminder",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "EditDateDialog_reminderFragment"
                },
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "remindOnType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "remindOn",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "repeatEvery",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "addCustomMessage",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "customMessage",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "recipients",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isactive",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "attachDocument",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "includeRelatedDocuments",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v4/*: any*/)
      ],
      "type": "CustomFieldValueType",
      "abstractKey": null
    }
  ],
  "type": "FieldValueType",
  "abstractKey": "__isFieldValueType"
};
})();

(node as any).hash = "f1d00437d80471f19b51244eb1b25572";

export default node;
