import React from "react";
import DateAndReminder from "~/components/DateAndReminder";
import AutoRenew from "~/components/AutoRenew";
import { graphql, useFragment } from "react-relay";
import useAsyncMutation from "~/utils/UseAsyncMutation";
import { stripFragmentProps } from "../utils/stripFragmentProps";
import { TerminationDateTooltip } from "./TerminationDateTooltip";
import { TerminationDateAndReminderConfirmationDialog } from "~/components/TerminationDateAndReminderConfirmationDialog";
import {
  getHasTerminationDatePassed,
  getIsAutoRenewSet,
} from "../utils/terminationDate";

const TERMINATION_DATE_AND_REMINDER_CONTRACT_DATE_FRAGMENT = graphql`
  fragment TerminationDateAndReminder_ContractDateFragment on ContractDateType {
    id
    uuid
    label
    date
    autoRenew
    renewEvery
    renewDate
    repeat
    enableReminder
    reminder {
      ...EditDateDialog_reminderFragment
      ...EditDateDialog_reminderFragment @relay(mask: false)
    }
  }
`;

const TERMINATION_DATE_AND_REMINDER_ORGANIZATION_FRAGMENT = graphql`
  fragment TerminationDateAndReminder_OrganizationFragment on OrganizationType {
    tzName
    ...TerminationDateAndReminderConfirmationDialog_OrganizationFragment
  }
`;

const TERMINATION_DATE_AND_REMINDER_CONTRACT_FRAGMENT = graphql`
  fragment TerminationDateAndReminder_ContractFragment on ContractType {
    id
    uuid
    isactive
    terminationDate {
      ...TerminationDateAndReminder_ContractDateFragment
    }
  }
`;

export function TerminationDateAndReminder(props) {
  const {
    dateFormat,
    disabled,
    userEmail,
    required,
    annotations,
    setIsContractActive,
    onCommit,
    onJumpClick,
  } = props;

  const contract = useFragment(
    TERMINATION_DATE_AND_REMINDER_CONTRACT_FRAGMENT,
    props.contract,
  );

  const terminationDate = useFragment(
    TERMINATION_DATE_AND_REMINDER_CONTRACT_DATE_FRAGMENT,
    contract.terminationDate,
  );

  const organization = useFragment(
    TERMINATION_DATE_AND_REMINDER_ORGANIZATION_FRAGMENT,
    props.organization,
  );

  const [commitSetDate, isInFlight] = useAsyncMutation(graphql`
    mutation TerminationDateAndReminder_SetTerminationDateMutation(
      $contractUuid: String!
      $date: ContractDateInput!
    ) {
      updateContractDate(input: { contractUuid: $contractUuid, date: $date }) {
        date {
          ...TerminationDateAndReminder_ContractDateFragment
        }
        contract {
          id
          currentFormMissingRequiredFields
        }
      }
    }
  `);

  const [isConfirmationDialogOpen, setConfirmationDialogOpen] =
    React.useState(false);

  // temporary state for tracking the selected date before we commit changes
  const [selectedDate, setSelectedDate] = React.useState(null);

  function handleDateSelect(date) {
    const selectedDate = { ...terminationDate, ...date };

    const hasSelectedTerminationDatePassed = getHasTerminationDatePassed(
      selectedDate.date,
      organization.tzName,
    );

    // user may wish to change contract to active
    const mayChangeFromInactiveToActive =
      !contract.isactive && !hasSelectedTerminationDatePassed;
    if (hasSelectedTerminationDatePassed || mayChangeFromInactiveToActive) {
      setSelectedDate(selectedDate);
      setConfirmationDialogOpen(true);
    } else {
      setDate(selectedDate);
    }
  }

  async function setDate(date) {
    const hasSelectedTerminationDatePassed = getHasTerminationDatePassed(
      date.date,
      organization.tzName,
    );

    let reminderInput;
    if (terminationDate.reminder) {
      const deleteReminder =
        terminationDate.reminder && hasSelectedTerminationDatePassed;

      reminderInput = {
        delete: deleteReminder,
        remindOnType: terminationDate.reminder.remindOnType,
        remindOn: terminationDate.reminder.remindOn,
        repeatEvery: terminationDate.reminder.repeatEvery,
        recipients: terminationDate.reminder.recipients,
        attachDocument: terminationDate.reminder.attachDocument,
        addCustomMessage: terminationDate.reminder.addCustomMessage,
        customMessage: terminationDate.reminder.customMessage,
      };
    }

    const enableReminder = hasSelectedTerminationDatePassed
      ? false
      : contract.enableReminder;

    await commitSetDate({
      variables: {
        contractUuid: contract.uuid,
        date: {
          uuid: date.uuid,
          label: date.label,
          date: date.date,
          autoRenew: date.autoRenew,
          renewEvery: date.renewEvery,
          renewDate: date.renewDate,
          repeat: date.repeat,
          repeatEveryNum: date.repeatEveryNum,
          recurSpan: date.recurSpan,
          recurType: date.recurType,
          recursUntil: date.recursUntil,
          numberOfOccurences: date.numberOfOccurences,
          enableReminder: enableReminder,
          trackCompletion: date.trackCompletion,
          reminder: reminderInput,
        },
      },
    });
  }

  async function handleConfirmation(isContractActive) {
    await setDate(selectedDate);

    if (isContractActive !== contract.isactive) {
      setIsContractActive(isContractActive);
    }

    setSelectedDate(null);
    setConfirmationDialogOpen(false);
    onCommit();
  }

  function handleConfirmationCancel() {
    setSelectedDate(null);
    setConfirmationDialogOpen(false);
  }

  const hasTerminationDatePassed = getHasTerminationDatePassed(
    terminationDate.date,
    organization.tzName,
  );
  const isAutoRenewSet = getIsAutoRenewSet(
    terminationDate,
    organization.tzName,
  );
  const disableAutoRenew = props.disabled || hasTerminationDatePassed;

  return (
    <div data-testid="terminationDateAndReminderSection">
      <DateAndReminder
        contractUuid={contract.uuid}
        label={
          <TerminationDateLabel
            dateLabel={terminationDate.label}
            hasTerminationDatePassed={hasTerminationDatePassed}
          />
        }
        aria-label="Termination Date"
        format={dateFormat}
        userEmail={userEmail}
        dateType="standard"
        date={selectedDate ?? terminationDate}
        setDate={handleDateSelect}
        isInFlight={isInFlight}
        onCommit={onCommit}
        required={required}
        error={required && !terminationDate?.date}
        disabled={props.disabled}
        name="terminationDate"
        annotations={annotations}
        onJumpClick={onJumpClick}
        data-testid="terminationDateDateSelect"
      >
        <AutoRenew
          terminationDate={terminationDate}
          setDate={handleDateSelect}
          dateFormat={dateFormat}
          hasTerminationDatePassed={hasTerminationDatePassed}
          isAutoRenewSet={isAutoRenewSet}
          disabled={disableAutoRenew}
        />
      </DateAndReminder>
      <TerminationDateAndReminderConfirmationDialog
        selectedDate={selectedDate}
        organization={organization}
        isContractActive={contract.isactive}
        open={isConfirmationDialogOpen}
        onConfirmation={(isContractActive) =>
          handleConfirmation(isContractActive)
        }
        onCancel={handleConfirmationCancel}
      />
    </div>
  );
}

function TerminationDateLabel(props) {
  const { dateLabel, hasTerminationDatePassed } = props;
  return (
    <div>
      <span>{dateLabel}</span>
      <TerminationDateTooltip
        hasTerminationDatePassed={hasTerminationDatePassed}
      />
    </div>
  );
}
