import React from "react";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import { MenuItem, type MenuItemProps } from "@mui/material";

type ContractPageMenuItemWithTooltipProps = {
  onClick: () => void;
  tooltipTitle: string | null | undefined;
} & MenuItemProps;

export function ContractPageMenuItemWithTooltip(
  props: ContractPageMenuItemWithTooltipProps,
) {
  const { tooltipTitle, ...restProps } = props;
  if (!tooltipTitle) return <MenuItem {...restProps} />;

  return (
    <Tooltip title={tooltipTitle} enterDelay={500} placement="left">
      <Box>
        <MenuItem {...restProps} />
      </Box>
    </Tooltip>
  );
}
