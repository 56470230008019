import React, { useCallback, useEffect } from "react";
import Grid from "~/components/GridV2OrV1";
import { CommentList } from "./CommentList";
import { graphql, useFragment } from "react-relay";
import type { CommentsSectionContent_contractFragment$key } from "./__generated__/CommentsSectionContent_contractFragment.graphql";
import type { CommentsSectionContent_accountFragment$key } from "./__generated__/CommentsSectionContent_accountFragment.graphql";
import { CommentComposer } from "./CommentComposer";
import { useCommentsRouteLoader } from "./useCommentsRouteLoader";
import { useFocusableComments } from "./useFocusableComments";
import type { CommentsSectionContent_commentsFragment$data } from "./__generated__/CommentsSectionContent_commentsFragment.graphql";
import { withErrorBoundary } from "@sentry/react";
import Typography from "@mui/material/Typography";

const CURRENT_USER_ACCOUNT_FRAGMENT = graphql`
  fragment CommentsSectionContent_accountFragment on AccountType {
    uuid
    email
    ...CommentComposer_accountFragment
    ...CommentList_currentUserAccountFragment
  }
`;

const COMMENTS_FRAGMENT = graphql`
  fragment CommentsSectionContent_commentsFragment on ContractType
  @refetchable(queryName: "CommentsSectionContentPaginationQuery")
  @argumentDefinitions(
    after: { type: "String" }
    before: { type: "String" }
    first: { type: "Int", defaultValue: 10 }
    last: { type: "Int" }
  ) {
    comments(after: $after, before: $before, first: $first, last: $last) {
      edges {
        node @required(action: THROW) {
          id
          uuid
        }
      }
    }
  }
`;

const COMMENTS_SECTION_CONTRACT_FRAGMENT = graphql`
  fragment CommentsSectionContent_contractFragment on ContractType
  @argumentDefinitions(commentsFirst: { type: Int, defaultValue: 10 }) {
    id
    uuid
    ...CommentList_contractFragment @arguments(first: $commentsFirst)
    ...CommentsSectionContent_commentsFragment @arguments(first: $commentsFirst)
  }
`;

export type CommentsSectionContentProps = {
  currentUser: CommentsSectionContent_accountFragment$key;
  contract: CommentsSectionContent_contractFragment$key;
};

export function CommentsSectionContent(props: CommentsSectionContentProps) {
  const currentUser = useFragment(
    CURRENT_USER_ACCOUNT_FRAGMENT,
    props.currentUser,
  );
  const contract = useFragment(
    COMMENTS_SECTION_CONTRACT_FRAGMENT,
    props.contract,
  );
  // Typescript was marking edges type as `unknown`.
  // TODO: Revisit when [this issue](https://github.com/facebook/relay/issues/4505) is resolved.
  const commentsData = useFragment(
    COMMENTS_FRAGMENT,
    contract,
  ) as CommentsSectionContent_commentsFragment$data;

  const { focusedCommentUuid: routeFocusedCommentUuid } =
    useCommentsRouteLoader();
  const { focusedCommentNodeId, focusComment, setCommentRef } =
    useFocusableComments();
  // create memoized callback to avoid re-running our effect on every render
  const focusInitialComment = useCallback(focusComment, [
    routeFocusedCommentUuid,
  ]);
  const routeFocusedCommentId = commentsData.comments?.edges.find(
    (edge) => edge?.node.uuid === routeFocusedCommentUuid,
  )?.node.id;
  useEffect(() => {
    if (routeFocusedCommentId) {
      focusInitialComment(routeFocusedCommentId, {
        behavior: "instant",
        block: "center",
      });
    }
  }, [routeFocusedCommentId, focusInitialComment]);

  function handleCommentCreateSuccess(commentNodeId: string) {
    focusComment(commentNodeId, { behavior: "smooth", block: "center" });
  }

  return (
    <Grid container sx={{ mt: 6 }}>
      <Grid xs={12} sx={{ mb: 8 }}>
        <CommentComposer
          currentUserFragRef={currentUser}
          contractId={contract.id}
          contractUuid={contract.uuid}
          onCommentCreateSuccess={handleCommentCreateSuccess}
        />
      </Grid>
      <Grid xs={12}>
        <CommentList
          contract={contract}
          currentUser={currentUser}
          focusedCommentNodeId={focusedCommentNodeId}
          setCommentRef={setCommentRef}
          focusComment={focusComment}
        />
      </Grid>
    </Grid>
  );
}

type CommentsSectionErrorFallbackProps = {
  error: Error;
  componentStack: string;
  eventId: string;
  resetError(): void;
};

function CommentsSectionErrorFallback(
  props: CommentsSectionErrorFallbackProps,
) {
  return (
    <div role="alert">
      <Typography variant="body1">
        Something went wrong. <a onClick={props.resetError}>Click here</a> to
        reload the page and try again. If the problem persists, please contact{" "}
        <a>support@contractsafe.com</a>.
      </Typography>
    </div>
  );
}

export const CommentsSectionContentWithErrorBoundary = withErrorBoundary(
  CommentsSectionContent,
  {
    fallback: CommentsSectionErrorFallback,
  },
);

export default CommentsSectionContentWithErrorBoundary;
