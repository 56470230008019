import React, { useState, useEffect } from "react";
import "@fontsource/open-sans";
import { graphql, useLazyLoadQuery } from "react-relay";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import MuiTextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { TextField } from "~/components/Field";
import LabeledItem from "~/components/LabeledItem";

const formatter = new Intl.NumberFormat(undefined, {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});
const numerals = [
  ...new Intl.NumberFormat(undefined, { useGrouping: false }).format(
    9876543210,
  ),
].reverse();
const numeralIndex = Object.fromEntries(numerals.map((d, i) => [d, i]));

function getNumSeparators() {
  const nf = new Intl.NumberFormat();
  const parts = nf.formatToParts(12345.6);
  const groupChar = parts.find((d) => d.type == "group").value;
  const decimalChar = parts.find((d) => d.type == "decimal").value;
  return [groupChar, decimalChar];
}

const [groupChar, decimalChar] = getNumSeparators();

export function parseNumber(string) {
  const delocalized = string
    ?.trim()
    ?.replaceAll(groupChar, "")
    ?.replace(decimalChar, ".")
    ?.replace(
      new RegExp(`[${numerals.join("")}]`, "g"),
      (d) => numeralIndex[d],
    );
  if (delocalized) {
    return +delocalized;
  }
  return NaN;
}

function formatNumber(numStr) {
  if (!numStr) {
    return "";
  }

  const r = formatter.format(numStr);
  if (r == "NaN") {
    // never show the user NaN
    return null;
  }
  return r;
}

function StyledTextField({ select, SelectProps, InputProps, ...props }) {
  return (
    <MuiTextField
      {...props}
      select={select}
      SelectProps={{
        inputProps: {
          sx: {
            height: 37,
            lineHeight: "36px",
            paddingLeft: 0,
            paddingTop: 0,
            paddingBottom: 0,
            "&:focus": { background: "initial" },
            width: "100%",
            fontSize: 16,
            fontWeight: "normal",
          },
        },
        style: {
          color: "#676B72",
          backgroundColor: "#D0D2D4",
          boxSizing: "border-box",
          height: 37,
          lineHeight: "22px",
          paddingBottom: 9,
          paddingLeft: 8,
          paddingTop: 9,
          borderRadius: "4px 0px 0px 4px",
          width: "100%",
        },
        ...SelectProps,
      }}
      InputProps={InputProps}
    />
  );
}

export function CurrencySelect({ value, SelectProps, children, ...props }) {
  return (
    <LabeledItem {...props}>
      <StyledTextField
        {...props}
        SelectProps={{ value: value || "", ...SelectProps }}
        variant="outlined"
        select
      >
        {children}
      </StyledTextField>
    </LabeledItem>
  );
}

export default function CurrencyField({
  onValueChange,
  onCurrencyChange,
  value,
  symbol,
  disabled,
  formatDuringEdit,
  ...props
}) {
  const [rawValue, setRawValue] = useState(formatNumber(value) || value);
  useEffect(
    () => setRawValue(formatDuringEdit ? formatNumber(value) || value : value),
    [value],
  );

  const [isEditing, setIsEditing] = useState(false);

  const { currencies } = useLazyLoadQuery(graphql`
    query CurrencyField_Query {
      currencies
    }
  `);

  const startAdornment = (
    <InputAdornment position="start" style={{ marginRight: 0 }}>
      <CurrencySelect
        value={symbol}
        style={{
          height: 37,
          borderRadius: 0,
          borderTopLeftRadius: 4,
          borderBottomLeftRadius: 4,
          fontWeight: "normal",
        }}
        onChange={({ target: { value } }) => onCurrencyChange(value)}
        disabled={disabled}
      >
        {currencies.map((c) => {
          return (
            <MenuItem value={c} key={c}>
              {c}
            </MenuItem>
          );
        })}
      </CurrencySelect>
    </InputAdornment>
  );

  const invalid = !!rawValue && isNaN(parseNumber(rawValue));

  return (
    <>
      <div>
        {isEditing ? (
          <>
            <TextField
              value={rawValue ?? ""}
              type="text"
              onChange={({ target: { value } }) => {
                setRawValue(value);
                if (!value) {
                  onValueChange(null);
                } else if (!isNaN(parseNumber(value))) {
                  onValueChange(`${parseNumber(value)}`);
                }
              }}
              onBlur={() => setIsEditing(false)}
              InputProps={{
                style: { paddingLeft: 0 },
                startAdornment,
              }}
              disabled={disabled}
              {...props}
            />
          </>
        ) : (
          <TextField
            value={rawValue ?? ""}
            type="text"
            onFocus={() => setIsEditing(true)}
            InputProps={{
              style: { paddingLeft: 0 },
              startAdornment,
            }}
            disabled={disabled}
            {...props}
          />
        )}
      </div>
      <FormControl error={invalid} style={{ width: "100%" }}>
        <FormHelperText style={{ marginTop: 0 }}>
          {invalid ? "Invalid currency value" : null}
        </FormHelperText>
      </FormControl>
    </>
  );
}
