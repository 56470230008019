/**
 * @generated SignedSource<<ee52c9292887bea818223354c1fb846c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type templateVariableHelpers_templateFragment$data = {
  readonly variables: ReadonlyArray<{
    readonly elementType: string;
    readonly field: any | null | undefined;
    readonly id: string;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "templateVariableHelpers_templateFragment";
};
export type templateVariableHelpers_templateFragment$key = {
  readonly " $data"?: templateVariableHelpers_templateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"templateVariableHelpers_templateFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "templateVariableHelpers_templateFragment"
};

(node as any).hash = "a7f94f053a4a822f135c5d30a6a29363";

export default node;
