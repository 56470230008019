import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function DropDownLoader() {
  return (
    <Box display="flex" alignItems="center">
      <CircularProgress color="inherit" size={20} style={{ marginRight: 5 }} />
      <Typography variant="body1">Loading...</Typography>
    </Box>
  );
}
