import React from "react";
import { Breadcrumbs, Skeleton, Typography } from "@mui/material";

type ContractFolderPathProps = {
  path: ReadonlyArray<string>;
};

export function ContractFolderPath(props: ContractFolderPathProps) {
  return (
    <Breadcrumbs aria-label="breadcrumb">
      {props.path.map((item, index) => (
        <Typography sx={{ color: "text.primary" }} key={index}>
          {item}
        </Typography>
      ))}
    </Breadcrumbs>
  );
}

export function ContractFolderPathSkeleton() {
  return <Skeleton variant="text" sx={{ fontSize: "24px" }} />;
}
