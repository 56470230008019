import React from "react";
import { graphql } from "relay-runtime";
import useAsyncMutation from "~/utils/UseAsyncMutation";
import { track } from "~/analytics/events";
import ConfirmDialog from "~/components/ConfirmDialog";
import type { ArchiveDialog_ArchiveMutation } from "./__generated__/ArchiveDialog_ArchiveMutation.graphql";

type ArchiveDialogProps = {
  contractUuid: string;
  open: boolean;
  handleClose: () => void;
};

export function ArchiveDialog(props: ArchiveDialogProps) {
  const { contractUuid, open, handleClose } = props;

  const [commitArchive] = useAsyncMutation<ArchiveDialog_ArchiveMutation>(
    graphql`
      mutation ArchiveDialog_ArchiveMutation($uuid: String!) {
        archiveContract(input: { uuid: $uuid }) {
          clientMutationId
        }
      }
    `,
  );

  async function handleConfirm() {
    try {
      await commitArchive({ variables: { uuid: contractUuid } });
      track("Contract Archived");
      window.location.href = "/contracts";
    } catch (error) {
      console.error("Failed to archive contract:", error);
      throw error;
    }
  }

  return (
    <ConfirmDialog open={open} onClose={handleClose} onConfirm={handleConfirm}>
      Are you sure you want to archive this contract? Archived contracts will be
      removed from your Contract List and will not be included in your search
      results and reporting. Any date reminders will be terminated. And they
      will not be included in the bulk exporting of your contracts. You can find
      and restore archived contracts under Filters.
    </ConfirmDialog>
  );
}
