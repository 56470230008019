/**
 * @generated SignedSource<<db2eb567b8f2149f370154ace5cb37d2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserCommentActionsMenu_commentFragment$data = {
  readonly id: string;
  readonly isAuthor: boolean;
  readonly pinnedAt: string | null | undefined;
  readonly uuid: string;
  readonly " $fragmentSpreads": FragmentRefs<"useCommentPinnedAt_commentFragment">;
  readonly " $fragmentType": "UserCommentActionsMenu_commentFragment";
};
export type UserCommentActionsMenu_commentFragment$key = {
  readonly " $data"?: UserCommentActionsMenu_commentFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserCommentActionsMenu_commentFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserCommentActionsMenu_commentFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uuid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isAuthor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pinnedAt",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useCommentPinnedAt_commentFragment"
    }
  ],
  "type": "CommentType",
  "abstractKey": null
};

(node as any).hash = "c7076f55f64c82e949a738d137ffc145";

export default node;
