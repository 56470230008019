/**
 * @generated SignedSource<<f4ff8b8fc6a18c7ea27337d01284e35d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type useContractDownload_DocxDownloadQuery$variables = {
  uuid: string;
};
export type useContractDownload_DocxDownloadQuery$data = {
  readonly docxUrl: string | null | undefined;
};
export type useContractDownload_DocxDownloadQuery = {
  response: useContractDownload_DocxDownloadQuery$data;
  variables: useContractDownload_DocxDownloadQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "uuid"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "uuid",
        "variableName": "uuid"
      }
    ],
    "kind": "ScalarField",
    "name": "docxUrl",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useContractDownload_DocxDownloadQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useContractDownload_DocxDownloadQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "147c7797ea95f4ac27a89017b1759f6f",
    "id": null,
    "metadata": {},
    "name": "useContractDownload_DocxDownloadQuery",
    "operationKind": "query",
    "text": "query useContractDownload_DocxDownloadQuery(\n  $uuid: String!\n) {\n  docxUrl(uuid: $uuid)\n}\n"
  }
};
})();

(node as any).hash = "676e517abd6f705c9f51e5fb4fdfd774";

export default node;
