import React from "react";
import { SvgIcon, type SvgIconProps } from "@mui/material";

export function AIssistantIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        stroke="currentColor"
      >
        <path
          d="M10.6363 15.5C10.5407 15.1539 10.3477 14.8381 10.0771 14.5854C9.80664 14.3327 9.46859 14.1523 9.09817 14.063L2.53145 12.481C2.41941 12.4513 2.32081 12.3883 2.2506 12.3014C2.18038 12.2146 2.1424 12.1088 2.1424 12C2.1424 11.8912 2.18038 11.7854 2.2506 11.6986C2.32081 11.6118 2.41941 11.5487 2.53145 11.519L9.09817 9.93601C9.46846 9.84681 9.80642 9.66658 10.0769 9.41404C10.3474 9.16151 10.5406 8.84589 10.6363 8.50001L12.3296 2.36501C12.3611 2.25992 12.4285 2.16735 12.5216 2.1014C12.6146 2.03545 12.7282 1.99976 12.845 1.99976C12.9618 1.99976 13.0754 2.03545 13.1684 2.1014C13.2615 2.16735 13.3289 2.25992 13.3604 2.36501L15.0526 8.50001C15.1482 8.84608 15.3413 9.1619 15.6118 9.41462C15.8823 9.66734 16.2203 9.84773 16.5908 9.93701L23.1575 11.518C23.2704 11.5471 23.37 11.61 23.441 11.6971C23.5119 11.7841 23.5504 11.8906 23.5504 12C23.5504 12.1094 23.5119 12.2159 23.441 12.3029C23.37 12.39 23.2704 12.4529 23.1575 12.482L16.5908 14.063C16.2203 14.1523 15.8823 14.3327 15.6118 14.5854C15.3413 14.8381 15.1482 15.1539 15.0526 15.5L13.3593 21.635C13.3278 21.7401 13.2604 21.8327 13.1674 21.8986C13.0743 21.9646 12.9607 22.0003 12.8439 22.0003C12.7271 22.0003 12.6135 21.9646 12.5205 21.8986C12.4274 21.8327 12.36 21.7401 12.3285 21.635L10.6363 15.5Z"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M21.4074 3V7"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M23.5481 5H19.2667"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M4.28149 17V19"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M5.35186 18H3.21112"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </svg>
    </SvgIcon>
  );
}
