import React from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { type TemplateVariable } from "./templateVariableSchema";
import { TemplateVariableHeaderField } from "./TemplateVariableHeaderField";
import { TemplateVariableTextField } from "./TemplateVariableTextField";
import { TemplateVariableRegularField } from "./TemplateVariableRegularField";
import Skeleton from "@mui/material/Skeleton";
import { getFieldInfo } from "../../core/contracts/fieldHelpers";
import type { TemplateFields_templateFragment$key } from "./__generated__/TemplateFields_templateFragment.graphql";
import { getTemplateVariables } from "./templateVariableHelpers";
import type { FieldValue, FieldValuesState } from "./useTemplateFieldValues";
import type { TemplateFields_builtinFieldsFragment$key } from "./__generated__/TemplateFields_builtinFieldsFragment.graphql";
import type { TemplateFields_organizationFragment$key } from "./__generated__/TemplateFields_organizationFragment.graphql";

export type TemplateFieldsProps = {
  builtinFieldsFragRef: TemplateFields_builtinFieldsFragment$key;
  organizationFragRef: TemplateFields_organizationFragment$key;
  templateFragRef: TemplateFields_templateFragment$key;
  fieldValues: FieldValuesState;
  handleFieldValueChange: (
    templateVariableId: string,
    value: FieldValue,
  ) => void;
};

export function TemplateFields(props: TemplateFieldsProps) {
  const builtinFields = useFragment(
    BUILTIN_FIELDS_FRAGMENT,
    props.builtinFieldsFragRef,
  );
  const organization = useFragment(
    ORGANIZATION_FRAGMENT,
    props.organizationFragRef,
  );

  const template = useFragment(TEMPLATE_FRAGMENT, props.templateFragRef);
  const { documentVariables, metadataVariables } =
    getTemplateVariables(template);

  function renderTemplateVariableField(templateVariable: TemplateVariable) {
    switch (templateVariable.elementType) {
      case "header-document":
      case "header-metadata":
        return (
          <TemplateVariableHeaderField
            key={templateVariable.id}
            value={templateVariable.field.value ?? ""}
          />
        );
      case "text-document":
      case "text-metadata":
        return (
          <TemplateVariableTextField
            key={templateVariable.id}
            value={templateVariable.field.value ?? ""}
          />
        );
      case "regular-document":
      case "regular-metadata": {
        const fieldInfo = getFieldInfo(templateVariable.field.id, {
          builtinFields,
          organization,
        });

        const value = props.fieldValues[templateVariable.id];
        return (
          <TemplateVariableRegularField
            key={templateVariable.id}
            fieldInfo={fieldInfo}
            templateVariable={templateVariable}
            value={value}
            organizationFragRef={organization}
            onChange={(value) => {
              props.handleFieldValueChange(templateVariable.id, value);
            }}
          />
        );
      }
      case "hidden":
        console.warn(
          "Warning: hidden template fields are not supported at this time.",
        );
        return null;
      default:
        return templateVariable satisfies never;
    }
  }

  return (
    <Stack>
      <Grid item xs={12}>
        {documentVariables.length > 0 && (
          <>
            <Grid item xs={12} sx={{ marginLeft: 1, marginBottom: 4 }}>
              <Typography variant="h5">Document Fields</Typography>
              <Typography variant="body2" marginTop={2}>
                These fields will be filled in for the template document when
                generating the contract file.
              </Typography>
            </Grid>
            <Grid item xs={12} ml={1}>
              {documentVariables.map(renderTemplateVariableField)}
            </Grid>
          </>
        )}
        {metadataVariables.length > 0 && (
          <>
            <Divider sx={{ marginY: 6 }} />
            <Grid item xs={12} sx={{ marginLeft: 1, marginBottom: 4 }}>
              <Typography variant="h5">Contract Fields</Typography>
              <Typography variant="body2" marginTop={2}>
                Fill out these fields to update the contract.
              </Typography>
            </Grid>
            <Grid item xs={12} ml={1}>
              {metadataVariables.map(renderTemplateVariableField)}
            </Grid>
          </>
        )}
      </Grid>
    </Stack>
  );
}

export function TemplateFieldsSkeleton() {
  return <Skeleton variant="rectangular" height={300} />;
}

const BUILTIN_FIELDS_FRAGMENT = graphql`
  fragment TemplateFields_builtinFieldsFragment on BuiltinFieldType
  @relay(plural: true) {
    ...fieldHelpers_builtinFieldInlineFragment
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment TemplateFields_organizationFragment on OrganizationType {
    ...TemplateVariableRegularField_organizationFragment
    ...fieldHelpers_organizationInlineFragment
  }
`;

const TEMPLATE_FRAGMENT = graphql`
  fragment TemplateFields_templateFragment on TemplateType {
    ...templateVariableHelpers_templateFragment
  }
`;
