export const pspdfkitLicenses = {
  localhost:
    "uZChCxU5mO1sXG3Hcy1K9lTbLC3s_LacpBQuD1kC6lGzN9X4hoh5uV0r-S8WOlC-RmRqhkfujyKCAry_Bthlj2DS8HGPAmOmlnH7Xw0briC-QOTAtIoTNVCwI1KKXtrRAjzpksEeRlJzvfH8q77T3bE44BjRD8kvTWPY1HzzG-hrV20RyxcdhbMRaoB-C-ZROzGMcIDitnP2WecLxa43ciQqCEKID36QF2i4T2GpdzjJ7wAu31JOu5o5DxmmJIE9_87WY1G6PnARtUW3jR8-jTb3LhIyZB4392pun316UNWXMaE2SRBgv4juadGenBxC_BYkCA06e-lSl7rn-Y7AoYpdB9ez62UXS7Mhvr7vmqO3kmMMggGft7MUcf3O6m1BVGJY6FeVeNqPWO8Q-W4J5T7iL6CDMbYTW06Mrkwd1AR5XnEPwwaDjTkGpWaIET3b4l7nf8At7Rgk0rTIAHiLLMTGxySYvBskRDwRzRaBfvPEo9XwHFxG3Fz0U4vQHV2_kGGPE-kvteFHtMgopJ1DePHui3HAqJk8MsnM1T48xMqAIBgkS0-z68d9ohXo_Vi1GwoUChVJV23dhfDFOa5wIXQOh2x-ESkxyRe5QgSoSTlljRivaauWTkCY69m44wHVEhaslq4ofXs5IicES231FzJsCW5qCgCt8BVVnYIKC2-ih708h6Jl_QUBl0upRmZhX5Ap-OC9qzHqH8qYYS-xu6mMswblxmvONMJGvvNov0kxaMlto7e30j-0jSEE-RzyW6jVRGqipVHRXCXEhn3bMh8ORZZ4xO_FjYdgptDdx-s=",
  "dev-app.contractsafe.com":
    "FFsF0TqRLsg4VEI0NAW3wibpn3Ma7XGxfFYWKZR8hqDq0nYEc-q3RjBqu72FdAQXp-oH6E7piOMwlDBKOIKGgtSr8-kiJty9ExFOEWwcS-lpt8dUnLARDwYpLToaU7wSR_P8DbX-eiaIBOHMYJwqIYwJwpEmjQsRJdct1RWl4dqkYqr0NEunUBdq68sQd0uJW_hYCLyAZxCwqSkfB1iZnD9afG_M-5DAb1Aexb9PDo2d4W6rm2QqgJZlk-6TWVMcmag3Koi45ZwflInyWdw3zr5alX9Qd1KnuuJJxZYWvzaSsh_WsKeSJnkoglc_olk4W6q0Z2XYwQFNNhdeUzoVjTpC7Cg8KLmtlfxR2kVYp9SwGoSYcl-5i7-D_SZ2qlQ901AUjB6r_3CRhzBrLPTVUKPfVqEc-XDx3PTQ2kxpi6bzKaY0RqwwhZUtyUwKftnxxz8hmWqSltrrLWdF64fmbMOHoaAsRSeb1eMK70_-ny3Z3BBX7Eq3J07WidFLMcJscVXB0LdgBq_w8xTEm9Y7QG6GiE7CsM7f_v4R8Laj_Opxq4AVTIdeINYS32JfiRCOAvl5_2NgSaEwwzhKkeWzWJuRtfKJxJGsDAVzIHIfkvDv4Ices5wPGfw9R2LYcYxTYqd__F2BPjrsWxGefYZUtRSbws0BMGgcNqQKktACfTDXDSAWZQwTMXo99fym7tAO02lIJCecP5ytxSASHHFvrP0IVmM-kVRE5sDv_I_PFPa_7SytElJurf-jL3yJHj_LAw1Mbe7zX3CsXEsjSJbtl2A5-dJk0pgZ2rmTQxwdhgg=",
  "preprod.contractsafe.com":
    "MOB4DmbsYJH69qXHQQXjAEhW4p451xmgXTD3dcVs1NjQZ90KWarpsxEH4A3_ibGOF32K0OJ8b4aa-fjn0-0v3e-pSN2nvJy8kOkq4ujQ6SWLsPXklei0va7FPYqPUqRUldWhvMIckypmVNG0JC_Q_adh4nZkQTvNgNf2o4qKRWg4G1AGGOxv1dsYg3P-Se6x_Ism1IMWiq74kBHfD-vFCxVPw7PCmU8znOOXApiESXCAYwc9SEJTqaZ3CPy-QajAaScPMJIK-lafqxhZPui5kAv5QqosAh6VuSwbML5Bp51IgMfHngtHDAAqYnVavwoaQtQdn1z8BzDCvfXwEEwIjIdGAEK3Q1Ah9fGlVy6gzZvELec68ZjQ0KUzdPcD4xy6Ja8YPlswpDsZNVuQ4B6rfFVjbShA2eFicLn-COuxZJ1hhzZGP7gUmmDiFojdxBPhRm8uyURkN4v6BcTouvOrei09jdVBrBeuXRlX6u6UTfXhuOQimVLNv7zWkTPuZPblWGxJtu5C5FrGqGWGftfgoxsf2YbSY14RQyDy9mVY-iFfqLN2mRuKAd5RqMDz1yRFTpKcgnaU9Gja3gfzRUxu1yS7ml8W6nnNLIwiiae-UQKUQ9ezVz1PsBPYZ5KcjuJzBFvIs4l_ncgoF5dqHGqyiicruSDD6dHIQ9mu9v2vn94Iv7RADSnM3D1_EADy7RdY3OqsbWXdTdlIbLMdUKsx3OG77vdi6PD70Jvvz0aCYAtkfFe5bh4qMsaj11PyDodqIMThZ4SinqVdvORh97n7d0btXbDw8yTW_9TcMBA3H3k=",
  "app.contractsafe.com":
    "ZcoCK5b3MRYU-p0ednNCnSH9bcR5Smv_gTAG9CYoL5-4ndegOYv9sKiN54-EumFaFiwHasz8Q-Q6Xs2YYTSe1VasayNYVWWSPKFLQR7XXvPKd1JCxd5VV9G-vEZa11LemxVtWqABmEwmxsVeptxsUDyW5hkD9Uy1PCQHQeCBuvev4MBg8dguZ68Zry_4nd3ZSI93yJCGJZZxZYzdFDsgjROwEfOKQTJa-4GuDhLVf2yz1TyFJ58Dt4XEWKQ2Z8ygjxj_QIVXF3--U8ADlFSdMsDzncW1DbiHbDBroBX0Ng_FpTi5ejdDpUtQsfBnon25lTOi_zxlPJqUViAFdF0z26tatzmrUYmCiZNqZcGJCkGgc9ulY_en0YtpAY7QMiTrVsrl_k8cM9a2mRB0P9QStYQE-IgHEbYs0Zmx70RcZcVn6Gg8qSlFcmLbstuGK3YX9bg4ImWiQTRKG207mIw6gtG1J_T1bXTLhV5Dm_SX765Ll3j2w4e3feN3FbdljxzEFbTyQYizsTiZayskcjyDOA_e28_8_qZmxVDViiNOUVehf8wVxZqv5GwKXEdchF_uUR1nG50BIvzroVf_KpboU5zlEMOpRCzKE6geKILBZK99Bz6985exkqW_Kjk90QZGDz_MjjEIzSE0J0VpSYHFRBshrPX68Bjd03JnslBe3VR8GahIeA2byvBv485IpHVVNBYzvAaN8Ij4ODW1eXv1ZO7yo-WvFOEpd9s5wwXFOl7E4bygwxV1S7NspDRI-urMeAgBQV5sYvE5rkj-ZgTaL7WZVZ-SZqTsqB91wS4V3Eo=",
  "app.contractsafe.eu":
    "ywEJuqwj0FyVXntHIUQtKZa9pRXQ2rd3VGvMW3GpFSIhGO1nKPbk2bdY4QJiziNvi8y-n_crpYPREexIpHq3rtW3vpbyXWJjS4xlkh_rSMT4CXTxq0GMM-m0qWLDjNBM5tCOF_uJtz-XfmlB-2jTm-qfXxtj7_WmkxHpQfCmxVWx97TcFRnMDqWTDUGf4oZtEreU4_kgVFf6Z1MMbnCuwWCmzo0ZE29XORLV5W_VzehvXqPSYb2STfgz6YyhvpQwYtqz7022Cgu8zykVEFdsu2YXbOYlolR69Oh8OmltpxscMz71OjMxCz8qPaB-IK-YOKjwxjRbxrw9KBCPwWvPaJZhCwsYFU4lBKwJ_xe7oRFUr34kW3qpV1OcKpJD7zPdThF4qncwDdhyaKruwgd6wFyo_KQRx5DRkYGj-B9MRQDB0fbg9GVf5rGqErFHs3sQXRslNMKppNs0gwvpJhMwIafFSgw554iQ8Kny5DFVnCeZQXq3Kf6hRzDj8cUJ40lOVsadUBnsRt5VbrEDZFxsQCAz8jwsZsZkvFxUrZ4RshskRxNxQHWkSq8JAu-mVEQbJrDkSqMU2A9rDyyVj1_kmS7_gZBnc0kgl58RgpNhprRAzKKI_-DaJTkkpZVn6i8FCex4UWMAEQ9dcgz6q6i_kMX-PV-uNvI59RUTcl05hwnRtHOhKdRx31OvCNz4C_EDb2HBsbupR3Yx7pfsqnV8wzGwvQ1hYPQmqg5GDW1J7xNV0K01vJeO_E-p1v1RDicjc6TRFGtZwxN-jyTCuZhG5xh2SrEqaT-OPsXgbrCjrWk=",
  "app.contractsafe.ca":
    "gz0hktLePwuEatCKaykWFOHR9ArijQZXqBiedmNicMDBLbIX2IyoaKw9JjH933Of833gGqedL1WsjMh9NK5Zv0MpV0gb5kAR6pAnKapg77KHkjPism61AB1OnflviykISz2aiz4lzx5wmM2taxjXR9izgPdzMAxMGHk3Tdip9WnuZ4f--e9BCRoqdI08AREZr09ENsmIWCPXKAw1HGyu2wYfYfHNosVSZa-W2dChBAH73DZ9Af25KNHS3_lQ7jE83TD32vQg0bKPb3yhtKlJfcIrLL0HyW9Jl9oV4H_r9YdilkcD1YXmQltrtosySPY2yaLRuy4W8U7BvwXB1MczohXwboqyqkOHlEHUOUVNo7Lm9bEcZUPwhEj5Qzr7ejKPZD8wr9EbmnlRGxuZ6W0rItUaLTQHtHwcJ4moYjD6zKGwgbJKP4nVVz6rvvUTbHkUJVqEX-Milwf-y4s3j_RkYINA59CRvK4u13-0oO3lZJ8Oow2rWNBvVaQOkODFQkm6xqc_rBSquWDgMuFpvszrNz7EW_tgWT2ccetCVUs6NxGVGBDCxCgxAQ3aDR0MrefmiPvX5pxYZMnbPax7U7EnZICd6UkVs5XH7bGhQtRsaKH-Gulax9IIBXnS34GHN5FEy8A5le9yqrRefLs7IVHeUFl6uYU5Y8Su6YZmaw8bivli9elRoEuTfb4GCRiPIzosl66PKf6aVUmOa773Y0PWXZUMepgztOo5aqVEIRofOrlNCMPC2vs2eVeF5h1Klhg5zvCySHxDztyI3ZkY2K2Os-IxwLlBGI7GhHKpBFZ4tX4=",
  "au.contractsafe.com":
    "JgarHcIW8KO8z7UXbxuiK_esIHrL1HaIbWkPZv652EXHZv0CJxkYG7YKe7XPN68PGavYnjB5yvNcGM_A4ChGrySmiOcLjmdFNY_xPOOwMgb3psBgyeUsovE_GNL-BkpcwKkm4b8QW5u_zlTaoJNKCjb3vzur2lE7Ow7krXUhE_UAIkMXreAj-evzr3dGDllWepp19MZerkhFSJCdBBQSfkay2OQSrlp87wGUQXhB67CzUU65BAzLyW2sMDb-EjRtwydydPDWu50otXWKd2-cZPXtY8zI7dv28N7V9r17_ZnKXDEQZCwTHA7BUxvYU3JHvQkJ-69W_VlUPGCtZvtuI4P05H0Ub5x4vkCW0gLkDCYkdWcvc_qrfaXqlm7hX_hiPFlMLvTieLOVcw3-gJpt7Lr9LICpwihoT57S5bD-C_DfBvyS6RqF6FAKSrLDM7o3q388jxv2uULtec2MyMS2yNrdYpW_gHyHeKycX6Q41w3OOv05deSC5O0T3_rifX5OnghSatWrCuatqsTaJmTKtebO_RN8F_xW_WOmGdlVu6Dz-z0DHALjQYSg-p8D-6NLamDhtfGGze3Z_YTt5Nqqy3gLTUKYFAZ6grd_F89wDeeX_8hadbjqDHzczG2l6o6yaNNFrsA4FaObrUNV8oq9UcL5EKU9ae1uuxGflxw2O3GAb5bsDPomwYLaNDznaH3c3_mXticQFEZigemE7Q-4sZr7vsTcwcqRFAx4cDAVQ9k19-VaqMJl_eruPd9YjJidQi1MwtqLzzm56JnMrSQfnT0wb-uBUi3OJxwsoNesfHA=",
  "contractsafe.com":
    "tnmLVlfo_g51BS7XJIhtYDe2FhcPHl6hfOI5dsmi4-_hreInwOBLALv29v3y41rZVOWogRGTHZByHYUrvIkTeHw5CPoYRwaw8ucNy6z9LYWsoWIrVAM_EpLM-rX_xiRQehXssYueYvBXCqbwyUekSirjzik9osXyp67Y1mU0jPW2Yhz3t_LmRU_f1Pt0bIYKQwWlbU4cZLBXb5K2zgj7hp4h40e1xs-1SzP3ndLxUQj2F5XWV7NaHM3oaSpLMj1-QCFf_2NgC9bxtyLpxfKg6WpiU87A18FMOSJe9vA1iX2j4l73O_7MpWW1ghHIXqou4Z-wf72Xr4i7Pl9uh6mrw-jMefASubFVY-lxcglq9YO7cweL1tOQ5tcJEgYxKBkI7weXt-ptf3WAmqfVSmcBRPxnScZvciW1bgZPHbnnenL0HssigaXtdCuU_WGjv4oq4_O3Qx3ZkJ3kWQjzRIIH0Ks2BIauaN-XIQSNIFqxWelEcph_rJiz8stAqAzA43ID2stshRkv5N-xEKTPlpFJUOPtg3L5CEjGeLfxXw8Jqw7MAznxKzLXKOG198OyMJw2vy2WHmu5j2t9IC6aJ_HeY9-B5_ELS0VLpCQbUGXyO2SplT5A9lHtwTfvqI_HZ_SDHsHCy3j6Q7b0qdHbaORtzlSKzJu_D6eGZDXFAa1wTVflQtT-kYgNcObfkUuc03ufJCqHaDOX1mfOxQpwNKqL6YVU0y6z04WUauLuqY5QBm0TmunNr_yWjEwiWwAhOTgPyGq1DvUVnQkpFEZlyeAW7Q==",
};
