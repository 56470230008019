import React from "react";
import DescriptionIcon from "@mui/icons-material/Description";
import { ContractViewerSkeleton } from "../ContractViewerSkeleton";
import Box from "@mui/material/Box";
import { AddContractButton } from "./AddContractButton";
import { graphql, useFragment } from "react-relay";
import type { ContractViewerEmptyStateV2_organizationFragment$key } from "./__generated__/ContractViewerEmptyStateV2_organizationFragment.graphql";

type ContractViewerEmptyStateV2Props = {
  contractUuid: string;
  organizationFragRef: ContractViewerEmptyStateV2_organizationFragment$key;
  handleMethodSelect: (value: "upload" | "link" | "template") => void;
};

export function ContractViewerEmptyStateV2(
  props: ContractViewerEmptyStateV2Props,
) {
  const organization = useFragment(
    ORGANIZATION_FRAGMENT,
    props.organizationFragRef,
  );
  return (
    <ContractViewerSkeleton>
      <DescriptionIcon color="disabled" fontSize="large" />
      <Box sx={{ my: 3 }}>This contract was uploaded without a file.</Box>
      <AddContractButton
        contractUuid={props.contractUuid}
        organizationFragRef={organization}
        onSelect={props.handleMethodSelect}
      />
    </ContractViewerSkeleton>
  );
}

export function useContractViewerEmptyStateV2() {
  const [activeDialog, setActiveDialog] = React.useState<
    "upload" | "link" | "template" | null
  >(null);

  const handleMethodSelect = (value: "upload" | "link" | "template") => {
    setActiveDialog(value);
  };

  return {
    activeDialog,
    handleMethodSelect,
    setActiveDialog,
  };
}

const ORGANIZATION_FRAGMENT = graphql`
  fragment ContractViewerEmptyStateV2_organizationFragment on OrganizationType {
    ...AddContractButton_organizationFragment
  }
`;
