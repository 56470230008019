import { Stack } from "@mui/material";
import React, { Suspense } from "react";
import { graphql, loadQuery, usePreloadedQuery } from "react-relay";
import { useLoaderData } from "react-router";
import environment from "~/RelayEnvironment";

export async function clientLoader(args: any) {
  const queryRef = loadQuery(environment, properties_Query, {
    uuid: args.params.uuid,
  });
  return { queryRef };
}

export function Properties(props: any) {
  const { queryRef } = useLoaderData<typeof clientLoader>();
  const queryData: any = usePreloadedQuery(properties_Query, queryRef);

  return (
    <Stack>
      <div>Properties</div>
    </Stack>
  );
}

export function PropertiesWithFallback() {
  return (
    <Suspense fallback={<div>Loading</div>}>
      <Properties />
    </Suspense>
  );
}

const properties_Query = graphql`
  query properties_Query($uuid: String!) {
    contract(uuid: $uuid) {
      uuid
    }
  }
`;
