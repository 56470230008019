/**
 * @generated SignedSource<<4d9cf4734cd3c6415bc940c1d3e0b392>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RedlineDialog_prepareRedlineMutation$variables = {
  initialUuid: string;
  updateUuid: string;
};
export type RedlineDialog_prepareRedlineMutation$data = {
  readonly prepareRedline: {
    readonly taskId: string | null | undefined;
  } | null | undefined;
};
export type RedlineDialog_prepareRedlineMutation = {
  response: RedlineDialog_prepareRedlineMutation$data;
  variables: RedlineDialog_prepareRedlineMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "initialUuid"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "updateUuid"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "initialUuid",
            "variableName": "initialUuid"
          },
          {
            "kind": "Variable",
            "name": "updateUuid",
            "variableName": "updateUuid"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "PrepareRedlinePayload",
    "kind": "LinkedField",
    "name": "prepareRedline",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "taskId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RedlineDialog_prepareRedlineMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RedlineDialog_prepareRedlineMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "678ec57d7e0e72dc1e6cdda95d1afd74",
    "id": null,
    "metadata": {},
    "name": "RedlineDialog_prepareRedlineMutation",
    "operationKind": "mutation",
    "text": "mutation RedlineDialog_prepareRedlineMutation(\n  $initialUuid: String!\n  $updateUuid: String!\n) {\n  prepareRedline(input: {initialUuid: $initialUuid, updateUuid: $updateUuid}) {\n    taskId\n  }\n}\n"
  }
};
})();

(node as any).hash = "185bb10befac96d3e01e7e45411d49cd";

export default node;
