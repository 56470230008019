import React from "react";
import ReactDOM from "react-dom";
import { RouterProvider, createBrowserRouter } from "react-router";
import { RelayEnvironmentProvider } from "react-relay";
import { QueryClient, QueryClientProvider } from "react-query";
import * as Sentry from "@sentry/react";
import environment from "~/RelayEnvironment";
import { initializeSentry } from "./sentry";
import { AuthenticationProvider } from "./components/Authentication";
import routes from "./routes";

export const queryClient = new QueryClient();
const router = Sentry.wrapCreateBrowserRouter(createBrowserRouter)(routes);

export default function Root() {
  return (
    <AuthenticationProvider>
      <RelayEnvironmentProvider environment={environment}>
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />
        </QueryClientProvider>
      </RelayEnvironmentProvider>
    </AuthenticationProvider>
  );
}

export function mountReactRouter() {
  initializeSentry();

  function attemptMount(retries: number) {
    try {
      const target = document.getElementById("main");
      if (!target) {
        throw "retry";
      }
      ReactDOM.render(<Root />, target);
    } catch (e) {
      if (retries <= 3) {
        setTimeout(() => attemptMount(retries + 1), retries);
      }
    }
  }

  setTimeout(() => attemptMount(0));
}
