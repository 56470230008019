import React from "react";

export function useStepper<T>(steps: ReadonlyArray<T>) {
  const [activeStepIndex, setActiveStepIndex] = React.useState(0);

  const handleNext = () => {
    setActiveStepIndex((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStepIndex((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStepIndex(0);
  };

  const handleStepChange = (step: T) => {
    setActiveStepIndex(steps.indexOf(step));
  };

  return {
    activeStep: steps[activeStepIndex],
    activeStepIndex,
    steps,
    handleNext,
    handleBack,
    handleReset,
    handleStepChange,
  };
}
