import { commitMutation, graphql, useRelayEnvironment } from "react-relay";
import type { Environment } from "relay-runtime";
import { environment } from "~/RelayEnvironment";

interface TrackOptions {
  integrations?: Integrations;
}
interface Integrations {
  pendo?: boolean;
  contractSafe?: boolean;
  contractSafeOptions?: ContractSafeIntegrationOptions;
}

interface ContractSafeIntegrationOptions {
  relayEnvironment?: Environment;
}

/**
 * Track an analytics event. We currently use Pendo for analytics but that may change in the future. Events that contain sensitive data should call `trackSensitive` instead.
 *
 * @param eventName The name of the event to track. Event names should follow proper casing.
 * @param properties Additional metadata to track with the event. Keys should be snake cased and values should be strings or booleans to ensure compatibility with Pendo's group by feature.
 * @param context Context is a dictionary of extra information that provides useful context about a datapoint, for example the user’s ip address or locale. You should only use Context fields for their intended meaning. Keys should be snake cased and values should be strings or booleans to ensure compatibility with Pendo's group by feature.
 *
 * @example
 * track('Song Played', { name: 'What Do You Mean', artist_name: 'Justin Bieber'});
 */
type TrackFn = (
  eventName: string,
  properties?: Record<string, string | boolean>,
  context?: Record<string, string | boolean>,
  options?: TrackOptions,
) => void;

/**
 * Track an analytics event. See `useTrackEvents` for more details.
 */
export const track: TrackFn = (
  eventName: string,
  properties: Record<string, string | boolean> = {},
  context: Record<string, string | boolean> = {},
  options?: TrackOptions,
) => {
  const defaultIntegrations: Integrations = {
    pendo: true,
    contractSafe: false,
    contractSafeOptions: {},
  };
  const defaultOptions = {
    integrations: defaultIntegrations,
  };

  let mergedOptions: Required<TrackOptions> = defaultOptions;

  if (options) {
    mergedOptions = {
      ...defaultOptions,
      ...options,
      integrations: { ...defaultIntegrations, ...options.integrations },
    };
  }

  try {
    if ((window as any).pendo && mergedOptions.integrations.pendo) {
      // Context is not supported with Pendo yet so we add it to properties
      properties = { ...properties, ...context };

      (window as any).pendo?.track(eventName, properties);
    } else {
      console.debug(
        `Pendo not loaded. Event will be logged but not sent. eventName: "${eventName}" properties: ${JSON.stringify(
          properties,
        )}`,
      );
    }

    if (mergedOptions.integrations.contractSafe) {
      createContractSafeTrackEvent(
        eventName,
        properties,
        context,
        mergedOptions.integrations.contractSafeOptions,
      );
    }
  } catch (e) {
    // TODO: log to sentry when we have it set up
    // tracking errors should not break core customer functionality
    console.error(`Error tracking event. eventName: "${eventName}"`, e);
  }
};

/**
 * Hook to provide track event functions that will use nearest context providers.
 *
 * This is useful for testing scenarios where we use a mock relay environment.
 *
 * @see {@link TrackFn}
 * @see {@link TrackSensitiveFn}
 *
 * @example
 * const { track, trackSensitive } = useTrackEvents();
 * track('Song Played', { name: 'What Do You Mean', artist_name: 'Justin Bieber'});
 */
export function useTrackEvents() {
  const relayEnvironment = useRelayEnvironment();

  /**
   * Track an event.
   *
   * @see {@link TrackFn} for naming conventions and more information
   */
  const track_ = (
    eventName: string,
    properties: Record<string, string | boolean> = {},
    context: Record<string, string | boolean> = {},
    options: TrackOptions = {},
  ) => {
    return track(eventName, properties, context, {
      integrations: {
        contractSafeOptions: {
          relayEnvironment,
        },
      },
      ...(options ?? {}),
    });
  };

  /**
   * Track an event that may contain sensitive data.
   *
   * @see {@link TrackSensitiveFn} for naming conventions and more information
   */
  const trackSensitive_: TrackSensitiveFn = (
    eventName: string,
    properties: Record<string, string | boolean> = {},
    context: Record<string, string | boolean> = {},
    options: ContractSafeIntegrationOptions = {},
  ) => {
    return trackSensitive(eventName, properties, context, {
      relayEnvironment,
      ...options,
    });
  };

  return {
    track: track_,
    trackSensitive: trackSensitive_,
  };
}

/**
 * Track an analytics event that contains sensitive data.
 *
 * @param eventName The name of the event to track. Event names should follow proper casing.
 * @param properties Additional metadata to track with the event. Keys should be snake cased and values should be strings or booleans to ensure compatibility with Pendo's group by feature.
 * @param context Context is a dictionary of extra information that provides useful context about a datapoint, for example the user’s ip address or locale. You should only use Context fields for their intended meaning. Keys should be snake cased and values should be strings or booleans to ensure compatibility with Pendo's group by feature.
 *
 * @example
 * track('Song Played', { name: 'What Do You Mean', artist_name: 'Justin Bieber'});
 */
type TrackSensitiveFn = (
  eventName: string,
  properties?: Record<string, string | boolean>,
  context?: Record<string, string | boolean>,
  options?: ContractSafeIntegrationOptions,
) => void;

export const trackSensitive: TrackSensitiveFn = (
  eventName: string,
  properties: Record<string, string | boolean> = {},
  context: Record<string, string | boolean> = {},
  options: ContractSafeIntegrationOptions = {},
) => {
  return createContractSafeTrackEvent(eventName, properties, context, options);
};

function createContractSafeTrackEvent(
  eventName: string,
  properties: Record<string, string | boolean> = {},
  context: Record<string, string | boolean> = {},
  options: ContractSafeIntegrationOptions = {},
) {
  const relayEnvironment = options.relayEnvironment ?? environment;

  return commitMutation(relayEnvironment, {
    mutation: graphql`
      mutation events_CreateTrackEventMutation(
        $trackEvent: CreateTrackEventInputType!
      ) {
        createTrackEvent(input: { trackEvent: $trackEvent }) {
          trackEventUuid
        }
      }
    `,
    variables: {
      trackEvent: {
        name: eventName,
        properties: JSON.stringify(properties),
        context: JSON.stringify(context),
      },
    },
  });
}
