/**
 * @generated SignedSource<<0c8945c69ac0fe32e26206913a687245>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Notes_viewState_Mutation$variables = {
  uuid: string;
  value?: boolean | null | undefined;
};
export type Notes_viewState_Mutation$data = {
  readonly updateViewState: {
    readonly contract: {
      readonly detailViewState: {
        readonly expandnote: boolean | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type Notes_viewState_Mutation = {
  response: Notes_viewState_Mutation$data;
  variables: Notes_viewState_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "uuid"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "value"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "stateboolvalue",
        "variableName": "value"
      },
      {
        "kind": "Literal",
        "name": "statename",
        "value": "expandnote"
      },
      {
        "kind": "Variable",
        "name": "uuid",
        "variableName": "uuid"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "DetailViewStateType",
  "kind": "LinkedField",
  "name": "detailViewState",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "expandnote",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Notes_viewState_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateViewStatePayload",
        "kind": "LinkedField",
        "name": "updateViewState",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractType",
            "kind": "LinkedField",
            "name": "contract",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Notes_viewState_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateViewStatePayload",
        "kind": "LinkedField",
        "name": "updateViewState",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractType",
            "kind": "LinkedField",
            "name": "contract",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "54141d61608211101f257e7d6764c53d",
    "id": null,
    "metadata": {},
    "name": "Notes_viewState_Mutation",
    "operationKind": "mutation",
    "text": "mutation Notes_viewState_Mutation(\n  $uuid: String!\n  $value: Boolean\n) {\n  updateViewState(input: {uuid: $uuid, statename: \"expandnote\", stateboolvalue: $value}) {\n    contract {\n      detailViewState {\n        expandnote\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e050ddeb0269ba83099c8d555403e320";

export default node;
