import React from "react";
import { Skeleton, Typography } from "@mui/material";

type ContractTitleProps = {
  title: string;
};

export function ContractTitle(props: ContractTitleProps) {
  return (
    <Typography
      sx={{
        color: "#000",
        fontSize: "24px",
        fontWeight: 500,
        lineHeight: "normal",
        textWrap: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      {props.title}
    </Typography>
  );
}

export function ContractTitleSkeleton() {
  return <Skeleton variant="text" sx={{ fontSize: "24px" }} />;
}
