/**
 * @generated SignedSource<<0d95f191694c05f10f9d420cf3cb3cc6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type ContractsContractDateRecurSpanChoices = "A_" | "DAY" | "MO" | "NVR" | "SPC" | "YR" | "%future added value";
export type ContractsContractDateRecurTypeChoices = "A_" | "A_1" | "A_2" | "A_3" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type fieldHelpers_contractInlineFragment$data = {
  readonly builtinFieldValues: ReadonlyArray<{
    readonly currencySymbol: string | null | undefined;
    readonly field: {
      readonly id: string;
    } | null | undefined;
    readonly id: string;
    readonly value: string | null | undefined;
  } | null | undefined>;
  readonly contractType: string | null | undefined;
  readonly counterparties: ReadonlyArray<{
    readonly label: string | null | undefined;
    readonly party: {
      readonly companyName: string | null | undefined;
      readonly uuid: string;
    } | null | undefined;
    readonly uuid: string;
  } | null | undefined>;
  readonly customContractType: {
    readonly id: string;
  } | null | undefined;
  readonly customFieldValues: ReadonlyArray<{
    readonly currencySymbol: string | null | undefined;
    readonly field: {
      readonly id: string;
    } | null | undefined;
    readonly id: string;
    readonly value: string | null | undefined;
    readonly valueDate: {
      readonly completions: {
        readonly edges: ReadonlyArray<{
          readonly cursor: string;
          readonly node: {
            readonly __typename: "DateCompletionType";
            readonly completedBy: {
              readonly email: string;
              readonly id: string;
            } | null | undefined;
            readonly completedOn: string;
            readonly id: string;
          } | null | undefined;
        } | null | undefined>;
        readonly pageInfo: {
          readonly endCursor: string | null | undefined;
          readonly hasNextPage: boolean;
        };
      };
      readonly date: any | null | undefined;
      readonly enableReminder: boolean;
      readonly id: string;
      readonly label: string;
      readonly numberOfOccurences: number;
      readonly protected: boolean | null | undefined;
      readonly recurSpan: ContractsContractDateRecurSpanChoices;
      readonly recurType: ContractsContractDateRecurTypeChoices;
      readonly recursUntil: any | null | undefined;
      readonly reminder: {
        readonly addCustomMessage: boolean;
        readonly attachDocument: boolean;
        readonly customMessage: string | null | undefined;
        readonly id: string;
        readonly isactive: boolean | null | undefined;
        readonly recipients: ReadonlyArray<string | null | undefined> | null | undefined;
        readonly remindOn: any | null | undefined;
        readonly remindOnType: string | null | undefined;
        readonly repeatEvery: string;
      } | null | undefined;
      readonly repeat: boolean;
      readonly repeatEveryNum: number | null | undefined;
      readonly trackCompletion: boolean;
      readonly uuid: string;
    } | null | undefined;
    readonly valueList: ReadonlyArray<{
      readonly author: string | null | undefined;
      readonly id: string;
      readonly itemDate: any | null | undefined;
      readonly name: string;
      readonly pairedValue: string | null | undefined;
      readonly sortindex: number;
    } | null | undefined> | null | undefined;
  } | null | undefined>;
  readonly effectiveDate: {
    readonly date: any | null | undefined;
    readonly id: string;
  } | null | undefined;
  readonly id: string;
  readonly legal: {
    readonly executionStatus: string;
    readonly id: string;
  } | null | undefined;
  readonly mycompanies: ReadonlyArray<{
    readonly party: {
      readonly companyName: string | null | undefined;
    } | null | undefined;
  } | null | undefined>;
  readonly notes: string | null | undefined;
  readonly tags: ReadonlyArray<{
    readonly id: string;
  } | null | undefined>;
  readonly terminationDate: {
    readonly date: any | null | undefined;
    readonly id: string;
  } | null | undefined;
  readonly " $fragmentType": "fieldHelpers_contractInlineFragment";
};
export type fieldHelpers_contractInlineFragment$key = {
  readonly " $data"?: fieldHelpers_contractInlineFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"fieldHelpers_contractInlineFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "fieldHelpers_contractInlineFragment"
};

(node as any).hash = "b8e32ab0396ee104bc27837ad7b37fbe";

export default node;
