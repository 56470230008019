import React from "react";
import MuiPaper from "@mui/material/Paper";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

const paperStyled =
  (Component) =>
  ({ sx = {}, ...props }) => (
    <Component
      sx={(theme) => ({
        textAlign: "left",
        padding: theme.spacing(8),
        background: "#FFFFFF",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "#D0D2D4",
        boxSizing: "border-box",
        boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        "&.MuiPaper-rounded": {
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px",
        },
        ...sx,
      })}
      {...props}
    />
  );

export const AccordionPaper = paperStyled(Accordion);

export const AccordionPaperSummary = ({ sx = {}, ...props }) => (
  <AccordionSummary
    sx={{
      minHeight: "0px !important",
      marginTop: "-12px",
      marginBottom: "-12px",
      "& .MuiAccordionSummary-content": {
        marginTop: "12px !important",
        marginBottom: "12px !important",
      },
      ...sx,
    }}
    {...props}
  />
);

const Paper = paperStyled(MuiPaper);

export function PaperProgress(props) {
  return (
    <Paper {...props}>
      <Box width={"100%"} display="flex" justifyContent="center">
        <CircularProgress disableShrink />
      </Box>
    </Paper>
  );
}

export default Paper;
