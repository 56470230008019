import type { Theme } from "@emotion/react";
import { type SxProps, Button, Skeleton } from "@mui/material";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

type SummaryButtonProps = {
  isOpen: boolean;
  onClick: () => void;
  sx?: SxProps<Theme>;
};

export function SummaryButton({ isOpen, onClick, sx }: SummaryButtonProps) {
  return (
    <Button
      variant="text"
      endIcon={isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      onClick={onClick}
      sx={[
        { flexGrow: 0, textTransform: "none" },
        ...(Array.isArray(sx) ? [...sx] : [sx]),
      ]}
    >
      Summary
    </Button>
  );
}

type SummaryButtonSkeletonProps = {
  sx?: SxProps<Theme>;
};

export function SummaryButtonSkeleton(props: SummaryButtonSkeletonProps) {
  return <Skeleton variant="text" width={105} height={50} sx={props.sx} />;
}
