/**
 * @generated SignedSource<<5411db9d70cd6b3764ec7c60cb0be2a0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LegalInput = {
  executionStatus?: string | null | undefined;
  fullyExecuted?: boolean | null | undefined;
  id?: string | null | undefined;
};
export type ContractExecutionStatusChip_executionStatusMutation$variables = {
  legal?: LegalInput | null | undefined;
  uuid: string;
};
export type ContractExecutionStatusChip_executionStatusMutation$data = {
  readonly updateContract: {
    readonly contract: {
      readonly " $fragmentSpreads": FragmentRefs<"ContractExecutionStatusChip_contractFragment">;
    } | null | undefined;
  } | null | undefined;
};
export type ContractExecutionStatusChip_executionStatusMutation$rawResponse = {
  readonly updateContract: {
    readonly contract: {
      readonly id: string;
      readonly legal: {
        readonly executionStatus: string;
        readonly id: string;
      } | null | undefined;
      readonly uuid: string;
    } | null | undefined;
  } | null | undefined;
};
export type ContractExecutionStatusChip_executionStatusMutation = {
  rawResponse: ContractExecutionStatusChip_executionStatusMutation$rawResponse;
  response: ContractExecutionStatusChip_executionStatusMutation$data;
  variables: ContractExecutionStatusChip_executionStatusMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "legal"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "uuid"
},
v2 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "legal",
        "variableName": "legal"
      },
      {
        "kind": "Variable",
        "name": "uuid",
        "variableName": "uuid"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ContractExecutionStatusChip_executionStatusMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "UpdateContractPayload",
        "kind": "LinkedField",
        "name": "updateContract",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractType",
            "kind": "LinkedField",
            "name": "contract",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ContractExecutionStatusChip_contractFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ContractExecutionStatusChip_executionStatusMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "UpdateContractPayload",
        "kind": "LinkedField",
        "name": "updateContract",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractType",
            "kind": "LinkedField",
            "name": "contract",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "uuid",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "LegalType",
                "kind": "LinkedField",
                "name": "legal",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "executionStatus",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8583b61bd16cdd79f0209d4ca4145cec",
    "id": null,
    "metadata": {},
    "name": "ContractExecutionStatusChip_executionStatusMutation",
    "operationKind": "mutation",
    "text": "mutation ContractExecutionStatusChip_executionStatusMutation(\n  $uuid: String!\n  $legal: LegalInput\n) {\n  updateContract(input: {uuid: $uuid, legal: $legal}) {\n    contract {\n      ...ContractExecutionStatusChip_contractFragment\n      id\n    }\n  }\n}\n\nfragment ContractExecutionStatusChip_contractFragment on ContractType {\n  id\n  uuid\n  legal {\n    id\n    executionStatus\n  }\n}\n"
  }
};
})();

(node as any).hash = "ae7b17def692afa8cf38967d088a26ce";

export default node;
