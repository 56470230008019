/**
 * @generated SignedSource<<08ad628be703de74ec2739924af2092d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ContractsContractDateRecurSpanChoices = "A_" | "DAY" | "MO" | "NVR" | "SPC" | "YR" | "%future added value";
export type ContractsContractDateRecurTypeChoices = "A_" | "A_1" | "A_2" | "A_3" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EditDateDialog_dateFragment$data = {
  readonly completions: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly completedBy: {
          readonly email: string;
        } | null | undefined;
        readonly completedOn: string;
      } | null | undefined;
    } | null | undefined>;
  };
  readonly date: any | null | undefined;
  readonly enableReminder: boolean;
  readonly id: string;
  readonly label: string;
  readonly numberOfOccurences: number;
  readonly protected: boolean | null | undefined;
  readonly recurSpan: ContractsContractDateRecurSpanChoices;
  readonly recurType: ContractsContractDateRecurTypeChoices;
  readonly recursUntil: any | null | undefined;
  readonly reminder: {
    readonly addCustomMessage: boolean;
    readonly attachDocument: boolean;
    readonly customMessage: string | null | undefined;
    readonly id: string;
    readonly includeRelatedDocuments: boolean;
    readonly isactive: boolean | null | undefined;
    readonly recipients: ReadonlyArray<string | null | undefined> | null | undefined;
    readonly remindOn: any | null | undefined;
    readonly remindOnType: string | null | undefined;
    readonly repeatEvery: string;
    readonly " $fragmentSpreads": FragmentRefs<"EditDateDialog_reminderFragment">;
  } | null | undefined;
  readonly repeat: boolean;
  readonly repeatEveryNum: number | null | undefined;
  readonly trackCompletion: boolean;
  readonly uuid: string;
  readonly " $fragmentType": "EditDateDialog_dateFragment";
};
export type EditDateDialog_dateFragment$key = {
  readonly " $data"?: EditDateDialog_dateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditDateDialog_dateFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "completions"
        ]
      }
    ]
  },
  "name": "EditDateDialog_dateFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uuid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "repeat",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "repeatEveryNum",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "recurSpan",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "recurType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "recursUntil",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numberOfOccurences",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enableReminder",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "trackCompletion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "protected",
      "storageKey": null
    },
    {
      "alias": "completions",
      "args": null,
      "concreteType": "DateCompletionTypeConnection",
      "kind": "LinkedField",
      "name": "__dateFragment_completions_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DateCompletionTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "DateCompletionType",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "completedOn",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AccountType",
                  "kind": "LinkedField",
                  "name": "completedBy",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "email",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ReminderType",
      "kind": "LinkedField",
      "name": "reminder",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EditDateDialog_reminderFragment"
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "remindOnType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "remindOn",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "repeatEvery",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "addCustomMessage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customMessage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "recipients",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isactive",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "attachDocument",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "includeRelatedDocuments",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ContractDateType",
  "abstractKey": null
};
})();

(node as any).hash = "b6af72581cb089ebd00e24d0cf4dd9eb";

export default node;
