import React, { useRef, useState } from "react";

/**
 * Hook to support focusing on specific comments in our comments section.
 */
export function useFocusableComments() {
  const [focusedCommentNodeId, setFocusedCommentNodeId] = useState<
    string | undefined
  >();

  const commentRefs = useRef<Record<string, HTMLElement>>({});

  function focusComment(
    commentNodeId: string,
    scrollOptions: ScrollIntoViewOptions = {},
  ) {
    setFocusedCommentNodeId(commentNodeId);
    scrollToComment(commentNodeId, scrollOptions);

    setTimeout(() => {
      // user may have focused another comment within the time period
      // also account for initial state
      if (focusedCommentNodeId === commentNodeId || !focusedCommentNodeId) {
        setFocusedCommentNodeId(undefined);
      }
    }, 3000);
  }

  function scrollToComment(
    commentNodeId: string,
    scrollOptions: ScrollIntoViewOptions = {},
  ) {
    const commentRefs = getCommentRefs();
    const node = commentRefs[commentNodeId];

    node.scrollIntoView({
      behavior: "instant",
      block: "center",
      inline: "center",
      ...scrollOptions,
    });
  }

  /**
   * Register a comment reference node to be scrollable to.
   */
  function setCommentRef(node: HTMLElement | null, commentNodeId: string) {
    const commentRefs = getCommentRefs();
    if (node) {
      commentRefs[commentNodeId] = node;
    } else {
      delete commentRefs[commentNodeId];
    }
  }

  function getCommentRefs() {
    if (!commentRefs.current) {
      // Initialize the Map on first usage.
      commentRefs.current = {};
    }
    return commentRefs.current;
  }

  return {
    focusedCommentNodeId,
    focusComment,
    setCommentRef,
  };
}
