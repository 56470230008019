/**
 * @generated SignedSource<<0561afbea3b5f77d4b4c219a2f394b63>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Parties_removeContractPartyMutation$variables = {
  cpuuid: string;
};
export type Parties_removeContractPartyMutation$data = {
  readonly removeContractParty: {
    readonly contract: {
      readonly counterparties: ReadonlyArray<{
        readonly label: string | null | undefined;
        readonly party: {
          readonly companyName: string | null | undefined;
          readonly uuid: string;
        } | null | undefined;
        readonly uuid: string;
        readonly " $fragmentSpreads": FragmentRefs<"PartyDialog_CTPartyfragment">;
      } | null | undefined>;
      readonly mycompanies: ReadonlyArray<{
        readonly label: string | null | undefined;
        readonly party: {
          readonly companyName: string | null | undefined;
          readonly uuid: string;
        } | null | undefined;
        readonly uuid: string;
        readonly " $fragmentSpreads": FragmentRefs<"PartyDialog_CTPartyfragment">;
      } | null | undefined>;
      readonly uuid: string;
    } | null | undefined;
  } | null | undefined;
};
export type Parties_removeContractPartyMutation = {
  response: Parties_removeContractPartyMutation$data;
  variables: Parties_removeContractPartyMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cpuuid"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "uuid",
        "variableName": "cpuuid"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyName",
  "storageKey": null
},
v5 = [
  (v2/*: any*/),
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "PartyType",
    "kind": "LinkedField",
    "name": "party",
    "plural": false,
    "selections": [
      (v2/*: any*/),
      (v4/*: any*/)
    ],
    "storageKey": null
  },
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "PartyDialog_CTPartyfragment"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "firstName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "lastName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "title",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "phone",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "altPhone",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "email",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "streetName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "streetName2",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "cityName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "stateAbbreviation",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "zipcode",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "province",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "country",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isPrimary",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "fullname",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "initials",
    "storageKey": null
  },
  (v6/*: any*/)
],
v8 = [
  (v2/*: any*/),
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "PartyType",
    "kind": "LinkedField",
    "name": "party",
    "plural": false,
    "selections": [
      (v2/*: any*/),
      (v4/*: any*/),
      (v6/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "ContactType",
        "kind": "LinkedField",
        "name": "contacts",
        "plural": true,
        "selections": (v7/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  (v6/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "ContactType",
    "kind": "LinkedField",
    "name": "contact",
    "plural": false,
    "selections": (v7/*: any*/),
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Parties_removeContractPartyMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemoveContractPartyPayload",
        "kind": "LinkedField",
        "name": "removeContractParty",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractType",
            "kind": "LinkedField",
            "name": "contract",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ContractPartyType",
                "kind": "LinkedField",
                "name": "mycompanies",
                "plural": true,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ContractPartyType",
                "kind": "LinkedField",
                "name": "counterparties",
                "plural": true,
                "selections": (v5/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Parties_removeContractPartyMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemoveContractPartyPayload",
        "kind": "LinkedField",
        "name": "removeContractParty",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractType",
            "kind": "LinkedField",
            "name": "contract",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ContractPartyType",
                "kind": "LinkedField",
                "name": "mycompanies",
                "plural": true,
                "selections": (v8/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ContractPartyType",
                "kind": "LinkedField",
                "name": "counterparties",
                "plural": true,
                "selections": (v8/*: any*/),
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fe928616f7ce39a99e63372871d90d38",
    "id": null,
    "metadata": {},
    "name": "Parties_removeContractPartyMutation",
    "operationKind": "mutation",
    "text": "mutation Parties_removeContractPartyMutation(\n  $cpuuid: String!\n) {\n  removeContractParty(input: {uuid: $cpuuid}) {\n    contract {\n      uuid\n      mycompanies {\n        uuid\n        label\n        party {\n          uuid\n          companyName\n          id\n        }\n        ...PartyDialog_CTPartyfragment\n        id\n      }\n      counterparties {\n        uuid\n        label\n        party {\n          uuid\n          companyName\n          id\n        }\n        ...PartyDialog_CTPartyfragment\n        id\n      }\n      id\n    }\n  }\n}\n\nfragment PartyDialog_CTPartyfragment on ContractPartyType {\n  id\n  uuid\n  label\n  party {\n    id\n    uuid\n    companyName\n    contacts {\n      ...PartyDialog_Contactfragment\n      id\n    }\n  }\n  contact {\n    uuid\n    ...PartyDialog_Contactfragment\n    id\n  }\n}\n\nfragment PartyDialog_Contactfragment on ContactType {\n  uuid\n  firstName\n  lastName\n  title\n  phone\n  altPhone\n  email\n  streetName\n  streetName2\n  cityName\n  stateAbbreviation\n  zipcode\n  province\n  country\n  isPrimary\n  fullname\n  initials\n}\n"
  }
};
})();

(node as any).hash = "477ad2c6c9dcc2a38d9bd9afeed5005f";

export default node;
