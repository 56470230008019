import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import { Formik, type FormikErrors } from "formik";
import TextField from "@mui/material/TextField";
import { useAddContractFileViaLinkMutation } from "./useAddContractFileViaLinkMutation";
import { useSnackbar } from "~/components/Snackbar";
import { LoadingButton } from "@mui/lab";
import { z } from "zod";
import { withZodSchema } from "formik-validator-zod";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

type AddContractFileViaLinkDialogProps = {
  contractUuid: string;
  open: boolean;
  handleClose: () => void;
};

type FormValues = {
  contractUuid: string;
  url: string;
  otherError: string | null;
};

const FormSchema = z.object({
  url: z.string().url(),
});

export function AddContractFileViaLinkDialog(
  props: AddContractFileViaLinkDialogProps,
) {
  const { openSnackbarWithProps, closeSnackbar } = useSnackbar();
  const { handleSubmit } = useAddContractFileViaLinkMutation();

  return (
    <Formik<FormValues>
      initialValues={{
        contractUuid: props.contractUuid,
        url: "",
        otherError: null,
      }}
      validate={withZodSchema(FormSchema)}
      onSubmit={(values, { setSubmitting, setFieldError, resetForm }) => {
        handleSubmit(values.contractUuid, values.url, {
          onResultError: (result) => {
            const setFieldErrors = () => {
              switch (result.reasonCode) {
                case "RETRIEVE_FILE_FROM_URL_ERROR":
                  setFieldError(
                    "otherError",
                    "We had an issue retrieving the file from the URL. Please try again or check the URL.",
                  );
                  return;
                case "INVALID_URL":
                  setFieldError(
                    "url",
                    "The URL you entered is invalid. Please try again.",
                  );
                  return;
                case "%future added value":
                  setFieldError(
                    "otherError",
                    "Something went wrong. Please try again.",
                  );
                  return;
                default:
                  return result.reasonCode satisfies never;
              }
            };

            setFieldErrors();
            setSubmitting(false);
          },
          onSuccess: () => {
            resetForm();
            props.handleClose();
            openSnackbarWithProps({
              children: (
                <Alert onClick={closeSnackbar} severity="success">
                  Successfully added contract file.
                </Alert>
              ),
            });
          },
          onError: (error) => {
            setFieldError(
              "otherError",
              "An unexpected error occurred. Please try again later or contact support@contractsafe.com if the problem persists.",
            );
            setSubmitting(false);
          },
        });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        resetForm,
      }) => (
        <Dialog
          open={props.open}
          onClose={() => {
            resetForm();
            props.handleClose();
          }}
          PaperProps={{
            component: "form",
            onSubmit: handleSubmit,
          }}
          data-testid="add-contract-file-via-link-dialog-root"
        >
          <DialogTitle variant="h5">
            Add Contract File Via Online Agreement
          </DialogTitle>
          <DialogContent>
            <Alert severity="info" sx={{ mb: 4 }}>
              Upload online Terms of Use, Privacy Statements, GDPR policies, and
              other agreements you enter into online by using a service. Just
              enter the web page where the info is listed (e.g.
              https://www.contractsafe.com/terms-of-use) and we will convert it
              into a document and upload it for you.
            </Alert>
            <TextField
              placeholder="Add a URL"
              fullWidth={true}
              type="url"
              name="url"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.url}
              error={!!(errors.url && touched.url)}
              helperText={errors.url && touched.url ? errors.url : " "}
              data-testid="add-contract-file-via-link-dialog-url-input"
            />
            <Typography
              color="error"
              visibility={errors.otherError ? "visible" : "hidden"}
            >
              {errors.otherError}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                resetForm();
                props.handleClose();
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              size="medium"
              type="submit"
              loading={isSubmitting}
              loadingPosition="start"
              startIcon={<CloudUploadIcon />}
              variant="contained"
              data-testid="add-contract-file-via-link-dialog-submit-button"
            >
              Upload
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
}
