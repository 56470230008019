/* FormFields are contract-related fields as the appear in a contract form or
   intake form. If you just need a field for user input and it's not part of one
   of those forms, thesre are not what you want; Fields is the right place to
   look.  */

import { List, Set } from "immutable";
import _ from "lodash";
import React, { useState, useEffect, useCallback } from "react";
import { useCookies } from "react-cookie";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import LinkIcon from "@mui/icons-material/Link";
import { TextField, Select } from "~/components/Field";
import Button from "~/components/Button";
import LabeledItem, {
  InputLabel,
  WrappingInputLabel,
  GroupInputLabel,
} from "~/components/LabeledItem";
import VisibleVertScroll from "~/components/VisibleVertScroll";
import { FieldValueSetAutocomplete } from "~/components/ValueSetAutocomplete";
import CurrencyField from "~/components/CurrencyField";
import DateAndReminder from "~/components/DateAndReminder";
import LedgerField from "~/components/LedgerField";
import { StandalonePartySelect } from "~/components/Parties";
import SearchField from "~/components/SearchField";
import { getValueProp } from "../core/contracts/fieldHelpers";

export function AutocompleteFormField({
  label,
  field,
  value,
  required,
  disabled,
  debouncedCommit,
  flushDebouncedCommit,
  icons,
}) {
  return (
    <FieldValueSetAutocomplete
      label={label}
      labelComponent={(props) => (
        <WrappingInputLabel
          required={required}
          error={required && !value}
          {...props}
        />
      )}
      value={value}
      onInputChange={(e, value, reason) => {
        if (reason == "reset") {
          return;
        }
        debouncedCommit(value);
      }}
      onChange={(e, v, r) => {
        debouncedCommit(v);
        flushDebouncedCommit();
      }}
      onBlur={() =>
        setTimeout(() => {
          flushDebouncedCommit();
        }, 1000)
      }
      fieldId={field.id}
      disabled={disabled}
      icons={icons}
    />
  );
}

export function CheckboxFormField({ label, value, disabled, onChange, icons }) {
  return (
    <Box height="100%" mt={2} display="flex" alignItems="center">
      <FormControlLabel
        control={
          <Checkbox
            checked={value == "True"}
            onChange={({ target: { checked } }) => {
              const newValue = checked ? "True" : "False";
              onChange(newValue);
            }}
            size="small"
            disabled={disabled}
          />
        }
        label={<WrappingInputLabel>{label}</WrappingInputLabel>}
      />
      {icons}
    </Box>
  );
}

export function DropdownFormField({
  label,
  field,
  value,
  required,
  disabled,
  onChange,
  icons,
  noNullOption,
}) {
  useEffect(() => {
    if (noNullOption || (field?.noNullOption && !value)) {
      onChange(field.choices[0].id);
    }
  }, []);

  return (
    <Select
      searchable
      label={label}
      labelComponent={(props) => (
        <WrappingInputLabel
          required={required}
          error={required && !value}
          {...props}
        />
      )}
      value={value}
      onChange={({ target: { value } }) => onChange(value)}
      disabled={disabled}
      icons={icons}
    >
      {!noNullOption && !field?.noNullOption && (
        <MenuItem value={null}>&nbsp;</MenuItem>
      )}
      {List(field.choices || []).map((choice) => (
        <MenuItem key={choice.id} value={choice.id}>
          {choice.name}
        </MenuItem>
      ))}
    </Select>
  );
}

export function MultichoiceFormField({
  label,
  field,
  value,
  required,
  disabled,
  onChange,
  icons,
}) {
  const [search, setSearch] = useState("");
  const values = Set(value?.map((x) => x.id) || []);
  const Choice = ({ choice }) => (
    <FormControlLabel
      key={choice.name}
      control={
        <Checkbox
          disableRipple
          checked={values.has(choice.id)}
          onChange={(e) =>
            onChange(
              (e.target.checked
                ? values.add(choice.id)
                : values.delete(choice.id)
              ).map((id) => ({ id })),
            )
          }
          size="small"
          disabled={disabled}
        />
      }
      label={<GroupInputLabel>{choice.name}</GroupInputLabel>}
    />
  );

  return (
    <LabeledItem
      labelComponent={(props) => (
        <WrappingInputLabel
          required={required}
          error={required && !values.size}
          {...props}
        />
      )}
      label={label}
      required={required}
      icons={icons}
    >
      {field.choices?.length > 10 ? (
        <SearchField
          search={search}
          setSearch={setSearch}
          sx={{ width: "inherit", ml: -2, mr: -2, mb: 2 }}
        />
      ) : null}
      <VisibleVertScroll maxHeight={150}>
        <Grid container spacing={0} style={{ paddingBottom: 1 }}>
          {field.choices
            ?.filter((choice) => values.has(choice.id))
            .filter((choice) =>
              choice.name.toLowerCase().includes(search.toLowerCase()),
            )
            .map((choice) => (
              <Grid item key={choice.id} xs={12}>
                <Choice choice={choice} />
              </Grid>
            ))}
          {values.size && values.size < field.choices?.length ? (
            <Grid item xs={12}>
              <Divider />
            </Grid>
          ) : null}
          {field.choices
            ?.filter((choice) => !values.has(choice.id))
            .filter((choice) =>
              choice.name.toLowerCase().includes(search.toLowerCase()),
            )
            .map((choice) => (
              <Grid item key={choice.id} xs={12}>
                <Choice choice={choice} />
              </Grid>
            ))}
        </Grid>
      </VisibleVertScroll>
    </LabeledItem>
  );
}

export function TextFormField({
  label,
  value,
  setValue,
  required,
  disabled,
  debouncedCommit,
  flushDebouncedCommit,
  icons,
}) {
  return (
    <TextField
      label={label}
      labelComponent={(props) => (
        <WrappingInputLabel
          required={required}
          error={required && !value}
          {...props}
        />
      )}
      icons={icons}
      value={value || ""}
      multiline
      minRows={1}
      maxRows={6}
      onChange={({ target: { value } }) => {
        setValue(value);
        debouncedCommit(value);
      }}
      onBlur={flushDebouncedCommit}
      disabled={disabled}
      resizeHorizontal={true}
    />
  );
}

export function NumberFormField({
  label,
  value,
  setValue,
  required,
  disabled,
  debouncedCommit,
  flushDebouncedCommit,
}) {
  const invalid = value && isNaN(value);
  return (
    <>
      <TextField
        label={label}
        labelComponent={(props) => (
          <WrappingInputLabel
            required={required}
            error={required && !value}
            {...props}
          />
        )}
        value={value ?? null}
        onChange={({ target: { value } }) => {
          setValue(value);
          if (!isNaN(value)) {
            debouncedCommit(value);
          }
        }}
        onBlur={flushDebouncedCommit}
        disabled={disabled}
      />
      <FormControl fullWidth error={!!invalid}>
        <FormHelperText style={{ marginTop: 0 }}>
          {invalid ? "Invalid number value" : null}
        </FormHelperText>
      </FormControl>
    </>
  );
}

export function FormField({
  label,
  field,
  value,
  nonDebouncedValueList,
  setValue,
  currencySymbol,
  handleCurrencyChange,
  required,
  disabled,
  handleChange,
  debouncedCommit,
  flushDebouncedCommit,
  userEmail,
  handleLedgerChange,
  contractUuid,
  dateFormat,
  onCommit,
  isInFlight,
  annotations,
  annotation,
  onJumpClick,
  formatCurrencyDuringEdit = true,
  ...props
}) {
  const valueProp = getValueProp(field);

  switch (field.fieldType) {
    case "p":
      return (
        <StandalonePartySelect
          label={label}
          value={value}
          partyType={field.partyType}
          required={required}
          disabled={disabled}
          onChange={(newValue) => {
            setValue(newValue);
            handleChange(newValue);
          }}
          {...props}
        />
      );
    case "a":
      return (
        <AutocompleteFormField
          label={label}
          field={field}
          value={value}
          required={required}
          disabled={disabled}
          debouncedCommit={debouncedCommit}
          flushDebouncedCommit={flushDebouncedCommit}
          icons={
            annotation?.length > 0 ? (
              <Button
                variant="text"
                size="tiny"
                onClick={() => onJumpClick(annotation[0])}
                label={<LinkIcon fontSize="small" color="primary" />}
                // style={{ marginRight: 36 }}
              />
            ) : null
          }
          {...props}
        />
      );
    case "b":
      return (
        <CheckboxFormField
          label={label}
          value={value}
          disabled={disabled}
          onChange={(newValue) => {
            setValue(newValue);
            handleChange(newValue);
          }}
          icons={
            annotation?.length > 0 ? (
              <Button
                variant="text"
                size="tiny"
                onClick={() => onJumpClick(annotation[0])}
                label={<LinkIcon fontSize="small" color="primary" />}
                // style={{ marginRight: 36 }}
              />
            ) : null
          }
          {...props}
        />
      );
    case "c":
      return (
        <CurrencyField
          label={label}
          labelComponent={(props) => (
            <WrappingInputLabel
              required={required}
              error={required && !value}
              {...props}
            />
          )}
          value={value}
          symbol={currencySymbol}
          onValueChange={(x) => debouncedCommit(x, currencySymbol)}
          onCurrencyChange={handleCurrencyChange}
          onBlur={flushDebouncedCommit}
          disabled={disabled}
          formatDuringEdit={formatCurrencyDuringEdit}
          icons={
            annotation?.length > 0 ? (
              <Button
                variant="text"
                size="tiny"
                onClick={() => onJumpClick(annotation[0])}
                label={<LinkIcon fontSize="small" color="primary" />}
                // style={{ marginRight: 36 }}
              />
            ) : null
          }
          {...props}
        />
      );
    case "d":
      return (
        <DateAndReminder
          contractUuid={contractUuid}
          format={dateFormat}
          userEmail={userEmail}
          dateType="custom"
          date={value || { label }}
          field={field}
          setDate={handleChange}
          isInFlight={isInFlight}
          onCommit={onCommit}
          required={required}
          error={required && !value?.date}
          disabled={disabled}
          annotations={annotations}
          onJumpClick={onJumpClick}
          label={label}
          {...props}
        />
      );
    case "m":
      // This is complicated because "m" type CustomFields have only one
      // value, but use valueList. Builtin fields just use value.
      const mValue =
        valueProp == "valueList"
          ? List(value?.map((x) => x.id) || []).first()
          : value;

      return (
        <DropdownFormField
          label={label}
          field={field}
          value={mValue}
          required={required}
          disabled={disabled}
          onChange={(value) =>
            // sometimes we don't pass in a handleChange function (intake form creation)
            handleChange &&
            handleChange(
              valueProp == "valueList" ? (value ? [{ id: value }] : []) : value,
            )
          }
          icons={
            annotation?.length > 0 ? (
              <Button
                variant="text"
                size="tiny"
                onClick={() => onJumpClick(annotation[0])}
                label={<LinkIcon fontSize="small" color="primary" />}
              />
            ) : null
          }
          {...props}
        />
      );
    case "n":
      return (
        <MultichoiceFormField
          label={label}
          field={field}
          value={nonDebouncedValueList}
          required={required}
          disabled={disabled}
          onChange={handleChange}
          icons={
            annotation?.length > 0 ? (
              <Button
                variant="text"
                size="tiny"
                onClick={() => onJumpClick(annotation[0])}
                label={<LinkIcon fontSize="small" color="primary" />}
              />
            ) : null
          }
          {...props}
        />
      );

    case "t":
      return (
        <TextFormField
          label={label}
          value={value}
          required={required}
          disabled={disabled}
          setValue={setValue}
          debouncedCommit={debouncedCommit}
          flushDebouncedCommit={flushDebouncedCommit}
          icons={
            annotation?.length > 0 ? (
              <Button
                variant="text"
                size="tiny"
                onClick={() => onJumpClick(annotation[0])}
                label={<LinkIcon fontSize="small" color="primary" />}
                style={{ marginRight: 36 }}
              />
            ) : null
          }
          {...props}
        />
      );
    case "f":
      return (
        <NumberFormField
          label={label}
          value={value}
          required={required}
          disabled={disabled}
          setValue={setValue}
          debouncedCommit={debouncedCommit}
          flushDebouncedCommit={flushDebouncedCommit}
          {...props}
        />
      );
    case "l":
      return (
        <LedgerField
          label={<WrappingInputLabel>{label}</WrappingInputLabel>}
          symbol={currencySymbol}
          valueList={value}
          onChange={handleLedgerChange}
          userEmail={userEmail}
          disabled={disabled}
          {...props}
        />
      );
  }

  console.log("field has unknown type:", field);
  return null;
}

// This is unfortunately a little hard to explain. Simplified FormField by
// accepting a fieldValue structure that captures different types of field
// values (strings, lists, dates...). Began as a helper component for Intake but
// seems to be more broadly useful.
export function SimpleFormField(allProps) {
  const {
    field,
    fieldValue,
    setFieldValue,
    dateFormat,
    required,
    disabled,
    noDebounce,
    ...props
  } = allProps;

  const [cookies, setCookie] = useCookies(["currency_selected"]);
  const choicesById = Object.fromEntries(
    field.choices?.map((c) => [c.id, c]) || [],
  );

  const valueProp = getValueProp(field);
  const value =
    valueProp == "valueDate"
      ? { date: fieldValue?.valueDate }
      : fieldValue?.[valueProp];

  function handleChange(value) {
    setFieldValue((fieldValue) => ({
      ...fieldValue,
      ...(field.fieldType == "c" && !fieldValue?.currencySymbol
        ? { currencySymbol: cookies.currency_selected }
        : null),
      [valueProp]: valueProp == "valueDate" ? value?.date : value,
    }));
  }

  function handleCurrencyChange(currencySymbol) {
    setFieldValue((fieldValue) => ({ ...fieldValue, currencySymbol }));
    setCookie("currency_selected", currencySymbol);
  }

  const debouncedCommit = useCallback(
    _.debounce(handleChange, DEBOUNCE_DELAY),
    [field.id],
  );

  return (
    <FormField
      field={field}
      value={value}
      nonDebouncedValueList={value}
      setValue={handleChange}
      currencySymbol={fieldValue?.currencySymbol || cookies.currency_selected}
      handleCurrencyChange={handleCurrencyChange}
      required={required}
      disabled={disabled}
      handleChange={handleChange}
      debouncedCommit={noDebounce ? handleChange : debouncedCommit}
      flushDebouncedCommit={noDebounce ? () => {} : debouncedCommit.flush}
      formatCurrencyDuringEdit={!noDebounce}
      dateFormat={dateFormat}
      {...props}
    />
  );
}
