/**
 * @generated SignedSource<<d040a7de4eeb239ab2572d1571c946b9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useTemplateFieldValues_contractInlineFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"fieldHelpers_contractInlineFragment">;
  readonly " $fragmentType": "useTemplateFieldValues_contractInlineFragment";
};
export type useTemplateFieldValues_contractInlineFragment$key = {
  readonly " $data"?: useTemplateFieldValues_contractInlineFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useTemplateFieldValues_contractInlineFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useTemplateFieldValues_contractInlineFragment"
};

(node as any).hash = "1894d02d2ff5e9f86d071db28fa50228";

export default node;
