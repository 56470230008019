import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import LinearProgress from "@mui/material/LinearProgress";
import DropzoneArea from "~/components/DropzoneArea";
import Button from "~/components/Button";
import { track } from "~/analytics/events";
import { getCsrfToken } from "~/utils/auth";

export default function AddAttachmentDialog({
  contractUuid,
  open,
  onClose,
  ...props
}) {
  const [uploading, setUploading] = React.useState(false);

  async function handleDrop(files) {
    if (!files.length) {
      return;
    }

    const fd = new FormData();
    for (const file of files) {
      fd.append("uploadedFile", file);
    }

    setUploading(true);
    try {
      var response = await fetch(
        "/api/v1/contract/uploadrelated/" + contractUuid,
        {
          method: "POST",
          headers: {
            "X-CSRFToken": getCsrfToken(),
          },
          body: fd,
        },
      );
    } catch (e) {
      console.log("error uploading", e);
    }
    setUploading(false);
    if (response?.status == 201) {
      track("Contract Attachment Added");
      onClose(true);
      return;
    }

    try {
      var result = await response.json();
    } catch (e) {
      console.log("error uploading", e);
    }
    onClose(false, result?.message || "Error");
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle id="form-dialog-title" style={{ textAlign: "center" }}>
        Add Attachments
      </DialogTitle>
      {uploading ? (
        <DialogContent>
          <LinearProgress />
        </DialogContent>
      ) : (
        <>
          <DialogContent dividers>
            <DropzoneArea
              useChipsForPreview
              onChange={handleDrop}
              filesLimit={25}
              dropzoneText="Drag and drop a file here or click to browse."
            />
          </DialogContent>
          <DialogActions>
            <Button size="small" onClick={onClose}>
              Cancel
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
