/**
 * @generated SignedSource<<e84848c1a4f106e8108d0112d7a30500>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SendContractDialog_Mutation$variables = {
  body: string;
  recipients: ReadonlyArray<string | null | undefined>;
  relatedDocs: ReadonlyArray<string | null | undefined>;
  subject: string;
  uuid: string;
  withFile?: boolean | null | undefined;
  withSummary?: boolean | null | undefined;
};
export type SendContractDialog_Mutation$data = {
  readonly shareContract: {
    readonly clientMutationId: string | null | undefined;
  } | null | undefined;
};
export type SendContractDialog_Mutation = {
  response: SendContractDialog_Mutation$data;
  variables: SendContractDialog_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "body"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "recipients"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "relatedDocs"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "subject"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "uuid"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "withFile"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "withSummary"
},
v7 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "body",
            "variableName": "body"
          },
          {
            "kind": "Variable",
            "name": "recipients",
            "variableName": "recipients"
          },
          {
            "kind": "Variable",
            "name": "relatedDocs",
            "variableName": "relatedDocs"
          },
          {
            "kind": "Variable",
            "name": "subject",
            "variableName": "subject"
          },
          {
            "kind": "Variable",
            "name": "uuid",
            "variableName": "uuid"
          },
          {
            "kind": "Variable",
            "name": "withFile",
            "variableName": "withFile"
          },
          {
            "kind": "Variable",
            "name": "withSummary",
            "variableName": "withSummary"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "ShareContractPayload",
    "kind": "LinkedField",
    "name": "shareContract",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SendContractDialog_Mutation",
    "selections": (v7/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Operation",
    "name": "SendContractDialog_Mutation",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "f18a0ca7769be04386005c444d6cdf7d",
    "id": null,
    "metadata": {},
    "name": "SendContractDialog_Mutation",
    "operationKind": "mutation",
    "text": "mutation SendContractDialog_Mutation(\n  $uuid: String!\n  $subject: String!\n  $body: String!\n  $recipients: [String]!\n  $relatedDocs: [String]!\n  $withFile: Boolean\n  $withSummary: Boolean\n) {\n  shareContract(input: {uuid: $uuid, subject: $subject, body: $body, recipients: $recipients, relatedDocs: $relatedDocs, withFile: $withFile, withSummary: $withSummary}) {\n    clientMutationId\n  }\n}\n"
  }
};
})();

(node as any).hash = "bcb02879da9e42123d35f23502d4cbb0";

export default node;
