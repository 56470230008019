/**
 * @generated SignedSource<<0baf6de8b025c449c352cd5ce77787e0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PDFViewer_accountFragment$data = {
  readonly invitedRole: string | null | undefined;
  readonly organization: {
    readonly annotationsEnabled: boolean | null | undefined;
  } | null | undefined;
  readonly readOnly: boolean;
  readonly " $fragmentType": "PDFViewer_accountFragment";
};
export type PDFViewer_accountFragment$key = {
  readonly " $data"?: PDFViewer_accountFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PDFViewer_accountFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PDFViewer_accountFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "invitedRole",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "readOnly",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationType",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "annotationsEnabled",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AccountType",
  "abstractKey": null
};

(node as any).hash = "86593b5eadce00ef7218ca8bc0318c35";

export default node;
