/**
 * @generated SignedSource<<95f1587947920be9b6454a4380e951bc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PDFViewer_createContractPartyMutation$variables = {
  companyName: string;
  contractUuid: string;
  label: string;
};
export type PDFViewer_createContractPartyMutation$data = {
  readonly createContractParty: {
    readonly contract: {
      readonly " $fragmentSpreads": FragmentRefs<"Parties_ContractPartiesFragment">;
    } | null | undefined;
  } | null | undefined;
};
export type PDFViewer_createContractPartyMutation = {
  response: PDFViewer_createContractPartyMutation$data;
  variables: PDFViewer_createContractPartyMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyName"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contractUuid"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "label"
},
v3 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "companyName",
        "variableName": "companyName"
      },
      {
        "kind": "Variable",
        "name": "contractuuid",
        "variableName": "contractUuid"
      },
      {
        "kind": "Variable",
        "name": "label",
        "variableName": "label"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = [
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "firstName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "lastName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "title",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "phone",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "altPhone",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "email",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "streetName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "streetName2",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "cityName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "stateAbbreviation",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "zipcode",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "province",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "country",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isPrimary",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "fullname",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "initials",
    "storageKey": null
  },
  (v5/*: any*/)
],
v7 = [
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "label",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PartyType",
    "kind": "LinkedField",
    "name": "party",
    "plural": false,
    "selections": [
      (v4/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "companyName",
        "storageKey": null
      },
      (v5/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "ContactType",
        "kind": "LinkedField",
        "name": "contacts",
        "plural": true,
        "selections": (v6/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  (v5/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "ContactType",
    "kind": "LinkedField",
    "name": "contact",
    "plural": false,
    "selections": (v6/*: any*/),
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PDFViewer_createContractPartyMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "CreateContractPartyPayload",
        "kind": "LinkedField",
        "name": "createContractParty",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractType",
            "kind": "LinkedField",
            "name": "contract",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Parties_ContractPartiesFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "PDFViewer_createContractPartyMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "CreateContractPartyPayload",
        "kind": "LinkedField",
        "name": "createContractParty",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractType",
            "kind": "LinkedField",
            "name": "contract",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ContractPartyType",
                "kind": "LinkedField",
                "name": "mycompanies",
                "plural": true,
                "selections": (v7/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ContractPartyType",
                "kind": "LinkedField",
                "name": "counterparties",
                "plural": true,
                "selections": (v7/*: any*/),
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0d0b66935e7dee16b3dd657a09163532",
    "id": null,
    "metadata": {},
    "name": "PDFViewer_createContractPartyMutation",
    "operationKind": "mutation",
    "text": "mutation PDFViewer_createContractPartyMutation(\n  $contractUuid: String!\n  $label: String!\n  $companyName: String!\n) {\n  createContractParty(input: {contractuuid: $contractUuid, label: $label, companyName: $companyName}) {\n    contract {\n      ...Parties_ContractPartiesFragment\n      id\n    }\n  }\n}\n\nfragment Parties_ContractPartiesFragment on ContractType {\n  uuid\n  mycompanies {\n    uuid\n    label\n    party {\n      uuid\n      companyName\n      id\n    }\n    ...PartyDialog_CTPartyfragment\n    id\n  }\n  counterparties {\n    uuid\n    label\n    party {\n      uuid\n      companyName\n      id\n    }\n    ...PartyDialog_CTPartyfragment\n    id\n  }\n}\n\nfragment PartyDialog_CTPartyfragment on ContractPartyType {\n  id\n  uuid\n  label\n  party {\n    id\n    uuid\n    companyName\n    contacts {\n      ...PartyDialog_Contactfragment\n      id\n    }\n  }\n  contact {\n    uuid\n    ...PartyDialog_Contactfragment\n    id\n  }\n}\n\nfragment PartyDialog_Contactfragment on ContactType {\n  uuid\n  firstName\n  lastName\n  title\n  phone\n  altPhone\n  email\n  streetName\n  streetName2\n  cityName\n  stateAbbreviation\n  zipcode\n  province\n  country\n  isPrimary\n  fullname\n  initials\n}\n"
  }
};
})();

(node as any).hash = "a8c815fb68287192807d38203608e070";

export default node;
