import { graphql, useFragment } from "react-relay";
import type { useContractAttachments_contractFragment$key } from "./__generated__/useContractAttachments_contractFragment.graphql";

export function useContractAttachments(
  contractFragRef: useContractAttachments_contractFragment$key,
) {
  const contract = useFragment(
    useContractAttachments_contractFragment,
    contractFragRef,
  );

  const attachments = contract.relatedDocs.filter(
    (doc) => !doc?.internalContract,
  );
  const hasAttachments = attachments.length > 0;

  return { hasAttachments, attachments };
}

const useContractAttachments_contractFragment = graphql`
  fragment useContractAttachments_contractFragment on ContractType {
    relatedDocs {
      uuid
      internalContract {
        uuid
      }
    }
  }
`;
