/**
 * @generated SignedSource<<8a29438560473283b90d9f39e5985de7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TemplatesDropdown_organizationFragment$data = {
  readonly templates: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }> | null | undefined;
  readonly " $fragmentType": "TemplatesDropdown_organizationFragment";
};
export type TemplatesDropdown_organizationFragment$key = {
  readonly " $data"?: TemplatesDropdown_organizationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"TemplatesDropdown_organizationFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TemplatesDropdown_organizationFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TemplateType",
      "kind": "LinkedField",
      "name": "templates",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "OrganizationType",
  "abstractKey": null
};

(node as any).hash = "3aa2ad0e3a1076ac54989b6199a8fc5c";

export default node;
