import React from "react";
import Grid, { type GridProps } from "~/components/GridV2OrV1";
import { useSlots } from "~/utils/UseSlots";
import { CommentHeader } from "./CommentHeader";
import { CommentContent } from "./CommentContent";
import { CommentSecondaryHeader } from "./CommentSecondaryHeader";
import { CommentAvatar, CommentAvatarSkeleton } from "./CommentAvatar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";

type CommentBaseProps = {
  children?: React.ReactNode;
  showCommentConnectorLine?: boolean;
  isFocused?: boolean;
};

type CommentProps = GridProps & CommentBaseProps;

export function Comment(props: CommentProps) {
  const {
    children,
    showCommentConnectorLine = true,
    isFocused = false,
    ...otherProps
  } = props;

  const [slots, childrenWithoutSlots] = useSlots(children, {
    avatar: CommentAvatar,
    avatarSkeleton: CommentAvatarSkeleton,
    header: CommentHeader,
    secondaryHeader: CommentSecondaryHeader,
    content: CommentContent,
  });

  return (
    <Grid container xs={12} {...otherProps}>
      <Grid xs="auto" sx={{ pr: "8px" }}>
        <Stack sx={{ height: "100%", width: "fit-content" }}>
          {slots.avatarSkeleton ?? slots.avatar}
          {showCommentConnectorLine && (
            <Box
              sx={{
                height: "100%",
                border: "0.5px solid lightgray",
                width: "1px",
                marginLeft: "50%",
                marginTop: 1,
                marginBottom: 1,
                fontWeight: 500,
              }}
            ></Box>
          )}
        </Stack>
      </Grid>
      <Grid
        container
        xs
        sx={{
          pl: 2,
          pb: 8,
          backgroundColor: isFocused ? "#dde2ee" : "inherit",
          transition: isFocused ? "none" : "background-color 2s ease",
        }}
      >
        <Grid container xs={12} sx={{ mb: 4 }}>
          <Grid xs={12}>{slots.header}</Grid>
          <Grid xs={12}>{slots.secondaryHeader}</Grid>
        </Grid>
        <Grid xs={12}>{slots.content}</Grid>
      </Grid>
    </Grid>
  );
}

export function CommentSkeleton() {
  return (
    <Comment isFocused={false} showCommentConnectorLine={false} height="100%">
      <CommentAvatarSkeleton />
      <CommentHeader
        startIcons={null}
        endIcons={null}
        title={<Skeleton variant="text" sx={{ width: 200 }} />}
      />
      <CommentContent>
        <Skeleton
          variant="rectangular"
          width="100%"
          height={220}
          animation="wave"
        />
      </CommentContent>
    </Comment>
  );
}
