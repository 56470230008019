import React, { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import { add, isValid, parseISO, format } from "date-fns";
import { DateField, Select } from "~/components/Field";
import Button from "~/components/Button";
import SubInputCheckbox from "~/components/SubInputCheckbox";
import { getAutoRenewTermText } from "~/utils/terminationDate";

export function AutoRenewField({
  renewEvery,
  setRenewEvery,
  renewDate,
  setRenewDate,
  minRenewDate,
  dateFormat,
}) {
  const [initialRenewDate] = useState(renewDate);

  return (
    <Grid container spacing={1} alignItems="center" style={{ width: 325 }}>
      <Grid item xs={8}>
        <Select
          label="Renewal term"
          value={renewEvery}
          onChange={({ target: { value } }) => {
            setRenewEvery(value);
            if (value == "SPC" && renewDate == "Invalid Date") {
              setRenewDate(initialRenewDate);
            }
          }}
        >
          <MenuItem value="MO">Monthly</MenuItem>
          <MenuItem value="QTR">Quarterly</MenuItem>
          <MenuItem value="SMA">Semi-Annually</MenuItem>
          <MenuItem value="YR">Annually</MenuItem>
          <MenuItem value="YR2">Every 2 Years</MenuItem>
          <MenuItem value="YR3">Every 3 Years</MenuItem>
          <MenuItem value="YR4">Every 4 Years</MenuItem>
          <MenuItem value="YR5">Every 5 Years</MenuItem>
          <MenuItem value="YR7">Every 7 Years</MenuItem>
          <MenuItem value="YR10">Every 10 Years</MenuItem>
          <MenuItem value="SPC">Specific Date</MenuItem>
        </Select>
      </Grid>
      {renewEvery == "SPC" ? (
        <Grid item xs={8}>
          <DateField
            label="Date"
            format={dateFormat}
            minDate={minRenewDate}
            minDateMessage="Future date required for autorenewal"
            value={renewDate}
            onChange={setRenewDate}
          />
        </Grid>
      ) : null}
    </Grid>
  );
}

function AutoRenewDialog({
  open,
  setOpen,
  terminationDate,
  dateFormat,
  setDate,
}) {
  const parsedRenewDate = terminationDate.renewDate
    ? parseISO(terminationDate.renewDate)
    : null;
  const [renewEvery, setRenewEvery] = useState(terminationDate.renewEvery);
  const [renewDate, setRenewDate] = useState(parsedRenewDate);

  function handleClose() {
    setOpen(false);
  }

  function handleSet() {
    setOpen(false);
    setDate({
      uuid: terminationDate.uuid,
      autoRenew: !!renewEvery,
      renewEvery: renewEvery || "MO",
      renewDate: renewEvery == "SPC" ? graphqlDate(renewDate) : null,
    });
  }

  const minRenewDate = add(parseISO(terminationDate.date), { days: 1 });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Set Autorenew</DialogTitle>
      <DialogContent dividers>
        <AutoRenewField
          {...{
            renewEvery,
            setRenewEvery,
            renewDate,
            setRenewDate,
            minRenewDate,
            dateFormat,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button size="small" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          primary
          size="small"
          onClick={handleSet}
          id="set-autorenew-button"
          disabled={renewEvery == "SPC" && !isValid(renewDate)}
        >
          Set
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default function AutoRenew({
  terminationDate,
  isAutoRenewSet,
  setDate,
  dateFormat,
  disabled,
}) {
  const [open, setOpen] = useState(false);

  const term = getAutoRenewTermText(terminationDate, dateFormat);

  return (
    <>
      <SubInputCheckbox
        name="autoRenew"
        label={"Autorenew" + term}
        labelProps={{ "data-testid": "autoRenewButton" }}
        checked={isAutoRenewSet}
        onChange={(e) => {
          e.target.checked
            ? setOpen(true)
            : setDate({
                uuid: terminationDate.uuid,
                autoRenew: e.target.checked,
                renewEvery: terminationDate.renewEvery || "MO",
              });
        }}
        onClick={() => setOpen(true)}
        disabled={disabled}
      />
      <AutoRenewDialog
        open={open}
        setOpen={setOpen}
        terminationDate={terminationDate}
        setDate={setDate}
        dateFormat={dateFormat}
      />
    </>
  );
}
