/**
 * @generated SignedSource<<7b1ccbb1d3149243869c1e506f02a196>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContractForms_StandardDates_ContractFragment$data = {
  readonly deadlineToNonrenew: {
    readonly " $fragmentSpreads": FragmentRefs<"ContractForms_DeadlineToNonrenewFragment">;
  } | null | undefined;
  readonly effectiveDate: {
    readonly " $fragmentSpreads": FragmentRefs<"ContractForms_EffectiveDateFragment">;
  } | null | undefined;
  readonly terminationDate: {
    readonly " $fragmentSpreads": FragmentRefs<"ContractForms_TerminationDateFragment">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"TerminationDateAndReminder_ContractFragment">;
  readonly " $fragmentType": "ContractForms_StandardDates_ContractFragment";
};
export type ContractForms_StandardDates_ContractFragment$key = {
  readonly " $data"?: ContractForms_StandardDates_ContractFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContractForms_StandardDates_ContractFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContractForms_StandardDates_ContractFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ContractDateType",
      "kind": "LinkedField",
      "name": "effectiveDate",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ContractForms_EffectiveDateFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ContractDateType",
      "kind": "LinkedField",
      "name": "terminationDate",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ContractForms_TerminationDateFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ContractDateType",
      "kind": "LinkedField",
      "name": "deadlineToNonrenew",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ContractForms_DeadlineToNonrenewFragment"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TerminationDateAndReminder_ContractFragment"
    }
  ],
  "type": "ContractType",
  "abstractKey": null
};

(node as any).hash = "904cb51f1cb154fd6fe024e75ab71bb3";

export default node;
