/**
 * @generated SignedSource<<37ac029b67daf3c28d8c1c518c4b9ec8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContractsContractDateRecurSpanChoices = "A_" | "DAY" | "MO" | "NVR" | "SPC" | "YR" | "%future added value";
export type ContractsContractDateRecurTypeChoices = "A_" | "A_1" | "A_2" | "A_3" | "%future added value";
export type ContractsContractDateRenewEveryChoices = "MO" | "NVR" | "QTR" | "SMA" | "SPC" | "YR" | "YR10" | "YR2" | "YR3" | "YR4" | "YR5" | "YR7" | "%future added value";
export type ContractDateInput = {
  autoRenew?: boolean | null | undefined;
  date?: any | null | undefined;
  enableReminder?: boolean | null | undefined;
  label?: string | null | undefined;
  numberOfOccurences?: number | null | undefined;
  recurSpan?: ContractsContractDateRecurSpanChoices | null | undefined;
  recurType?: ContractsContractDateRecurTypeChoices | null | undefined;
  recursUntil?: any | null | undefined;
  reminder?: ReminderInput | null | undefined;
  renewDate?: any | null | undefined;
  renewEvery?: ContractsContractDateRenewEveryChoices | null | undefined;
  repeat?: boolean | null | undefined;
  repeatEveryNum?: number | null | undefined;
  trackCompletion?: boolean | null | undefined;
  uuid?: string | null | undefined;
};
export type ReminderInput = {
  addCustomMessage: boolean;
  attachDocument: boolean;
  customMessage?: string | null | undefined;
  delete?: boolean | null | undefined;
  includeRelatedDocuments?: boolean | null | undefined;
  keepExistingRecipients?: boolean | null | undefined;
  recipients?: ReadonlyArray<string | null | undefined> | null | undefined;
  remindOn?: any | null | undefined;
  remindOnType?: string | null | undefined;
  repeatEvery?: string | null | undefined;
};
export type EditDateDialog_AddDateMutation$variables = {
  contractUuid: string;
  date: ContractDateInput;
};
export type EditDateDialog_AddDateMutation$data = {
  readonly addContractDate: {
    readonly contract: {
      readonly " $fragmentSpreads": FragmentRefs<"EditDateDialog_contractFragment">;
    } | null | undefined;
    readonly date: {
      readonly " $fragmentSpreads": FragmentRefs<"EditDateDialog_dateFragment">;
    } | null | undefined;
  } | null | undefined;
};
export type EditDateDialog_AddDateMutation = {
  response: EditDateDialog_AddDateMutation$data;
  variables: EditDateDialog_AddDateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "contractUuid"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "date"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "contractUuid",
        "variableName": "contractUuid"
      },
      {
        "kind": "Variable",
        "name": "date",
        "variableName": "date"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  }
],
v5 = [
  (v3/*: any*/),
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "label",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "date",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "repeat",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "repeatEveryNum",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "recurSpan",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "recurType",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "recursUntil",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "numberOfOccurences",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "enableReminder",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "trackCompletion",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "protected",
    "storageKey": null
  },
  {
    "alias": null,
    "args": (v4/*: any*/),
    "concreteType": "DateCompletionTypeConnection",
    "kind": "LinkedField",
    "name": "completions",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DateCompletionTypeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DateCompletionType",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "completedOn",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AccountType",
                "kind": "LinkedField",
                "name": "completedBy",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cursor",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "PageInfo",
        "kind": "LinkedField",
        "name": "pageInfo",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endCursor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasNextPage",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": "completions(first:1)"
  },
  {
    "alias": null,
    "args": (v4/*: any*/),
    "filters": null,
    "handle": "connection",
    "key": "dateFragment_completions",
    "kind": "LinkedHandle",
    "name": "completions"
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "ReminderType",
    "kind": "LinkedField",
    "name": "reminder",
    "plural": false,
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "remindOnType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "remindOn",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "repeatEvery",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "addCustomMessage",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "customMessage",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "recipients",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isactive",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "attachDocument",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "includeRelatedDocuments",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditDateDialog_AddDateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddContractDatePayload",
        "kind": "LinkedField",
        "name": "addContractDate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractType",
            "kind": "LinkedField",
            "name": "contract",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EditDateDialog_contractFragment"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractDateType",
            "kind": "LinkedField",
            "name": "date",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EditDateDialog_dateFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditDateDialog_AddDateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddContractDatePayload",
        "kind": "LinkedField",
        "name": "addContractDate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractType",
            "kind": "LinkedField",
            "name": "contract",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ContractDateType",
                "kind": "LinkedField",
                "name": "effectiveDate",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ContractDateType",
                "kind": "LinkedField",
                "name": "terminationDate",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ContractDateType",
                "kind": "LinkedField",
                "name": "deadlineToNonrenew",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CustomFieldValueType",
                "kind": "LinkedField",
                "name": "customFieldValues",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContractDateType",
                    "kind": "LinkedField",
                    "name": "valueDate",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ContractDateType",
                "kind": "LinkedField",
                "name": "dates",
                "plural": true,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractDateType",
            "kind": "LinkedField",
            "name": "date",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "031ccc814e7dd7c2cf55088a387720f8",
    "id": null,
    "metadata": {},
    "name": "EditDateDialog_AddDateMutation",
    "operationKind": "mutation",
    "text": "mutation EditDateDialog_AddDateMutation(\n  $contractUuid: String!\n  $date: ContractDateInput!\n) {\n  addContractDate(input: {contractUuid: $contractUuid, date: $date}) {\n    contract {\n      ...EditDateDialog_contractFragment\n      id\n    }\n    date {\n      ...EditDateDialog_dateFragment\n      id\n    }\n  }\n}\n\nfragment EditDateDialog_contractFragment on ContractType {\n  uuid\n  effectiveDate {\n    ...EditDateDialog_dateFragment\n    id\n  }\n  terminationDate {\n    ...EditDateDialog_dateFragment\n    id\n  }\n  deadlineToNonrenew {\n    ...EditDateDialog_dateFragment\n    id\n  }\n  customFieldValues {\n    valueDate {\n      ...EditDateDialog_dateFragment\n      id\n    }\n    id\n  }\n  dates {\n    ...EditDateDialog_dateFragment\n    id\n  }\n}\n\nfragment EditDateDialog_dateFragment on ContractDateType {\n  id\n  uuid\n  label\n  date\n  repeat\n  repeatEveryNum\n  recurSpan\n  recurType\n  recursUntil\n  numberOfOccurences\n  enableReminder\n  trackCompletion\n  protected\n  completions(first: 1) {\n    edges {\n      node {\n        completedOn\n        completedBy {\n          email\n          id\n        }\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  reminder {\n    ...EditDateDialog_reminderFragment\n    id\n    remindOnType\n    remindOn\n    repeatEvery\n    addCustomMessage\n    customMessage\n    recipients\n    isactive\n    attachDocument\n    includeRelatedDocuments\n  }\n}\n\nfragment EditDateDialog_reminderFragment on ReminderType {\n  id\n  remindOnType\n  remindOn\n  repeatEvery\n  addCustomMessage\n  customMessage\n  recipients\n  isactive\n  attachDocument\n  includeRelatedDocuments\n}\n"
  }
};
})();

(node as any).hash = "47e58fba3808a6ed76acb7bd824f9d58";

export default node;
