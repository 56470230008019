import React, { useState } from "react";
import { graphql, useFragment } from "react-relay";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import LinearProgress from "@mui/material/LinearProgress";
import DropzoneArea from "~/components/DropzoneArea";
import Button from "~/components/Button";
import acceptedFiles from "~/AcceptedFileTypes";
import { getCsrfToken } from "~/utils/auth";

export function UploadContractFileDialog({
  contractUuid,
  open,
  handleClose,
  ...props
}) {
  const [uploading, setUploading] = useState(false);

  async function handleDrop(files) {
    if (files?.length !== 1) {
      return;
    }

    const file = files[0];

    let formData = new FormData();
    formData.append("uploadedFile", file);

    try {
      setUploading(true);
      await uploadFile(contractUuid, formData);
    } catch (e) {
      console.log("error uploading", e);
    }
    setUploading(false);
    handleClose();
    window.location.reload();
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" style={{ textAlign: "center" }}>
        {uploading ? "Uploading" : "Choose your contract document to upload"}
      </DialogTitle>
      {uploading ? (
        <DialogContent>
          <LinearProgress />
        </DialogContent>
      ) : (
        <>
          <DialogContent dividers>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <DropzoneArea
                  useChipsForPreview
                  showAlerts={false}
                  onChange={handleDrop}
                  filesLimit={1}
                  dropzoneText="Drag and drop a file here or click to browse."
                  acceptedFiles={acceptedFiles}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button size="small" onClick={handleClose}>
              Cancel
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}

async function uploadFile(contractUuid, formData) {
  const response = await fetch(`/api/v1/contract/reparse/${contractUuid}`, {
    method: "POST",
    headers: {
      "X-CSRFToken": getCsrfToken(),
    },
    body: formData,
  });

  if (response.status === 201) {
    const data = response.json();
    return { error: false, data: data };
  }

  console.error("error uploading contract file: ", response);
  return { error: true };
}
