import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router";
import { getFeatureFlags } from "~/utils/featureFlags";

export function initializeSentry() {
  Sentry.init({
    dsn: document.getElementsByTagName("meta").namedItem("sentry-dsn")?.content,
    tunnel: "/sentry", // Implemented as an ASGI middleware, not a route
    release: document.getElementsByTagName("meta").namedItem("version")
      ?.content,
    environment: document.getElementsByTagName("meta").namedItem("environment")
      ?.content,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.metrics.metricsAggregatorIntegration(),
      Sentry.extraErrorDataIntegration({
        depth: 8,
      }),
    ],
    tracesSampleRate: 1,
    tracePropagationTargets: ["localhost:8000", "localhost:8443", /^\//],
  });

  const featureFlags = getFeatureFlags();
  Object.entries(featureFlags).forEach(([flagName, isEnabled]) => {
    /*
     * Sentry's documentation states this about tags:
     * Tag keys have a maximum length of 32 characters and can contain only letters (a-zA-Z), numbers (0-9), underscores (_), periods (.), colons (:), and dashes (-). However, from testing, that appears to not be true.
     *
     * If there are issues with this code in the future, try truncating the flag name.
     */
    Sentry.setTag(`feature_flags.${flagName}`, isEnabled);
  });
  Sentry.setContext("feature_flags", featureFlags);
}
