import { Box, Stack } from "@mui/material";
import React, { Suspense } from "react";
import { graphql, loadQuery, usePreloadedQuery } from "react-relay";
import { useLoaderData } from "react-router";
import Notes from "~/components/Notes";
import { useSnackbar } from "~/components/Snackbar";
import environment from "~/RelayEnvironment";
import type { comments_Query as comments_QueryType } from "./__generated__/comments_Query.graphql";
import { CommentsSection } from "~/components/Comments/CommentsSection";
import { PDFViewerArea } from "./components/PDFViewerArea";

export async function clientLoader(args: any) {
  const queryRef = loadQuery<comments_QueryType>(environment, comments_Query, {
    uuid: args.params.uuid,
  });
  return { queryRef };
}

export function Comments(props: unknown) {
  const { queryRef } = useLoaderData<typeof clientLoader>();
  const queryData = usePreloadedQuery<comments_QueryType>(
    comments_Query,
    queryRef,
  );
  const contract = queryData.contract;
  const account = queryData.account;
  const organization = queryData.organization;
  const { openSnackbar } = useSnackbar();

  if (!account || !organization) {
    throw new Error("Unexpected error. Account not found.");
  }

  // TODO: fix this to show actual error component
  if (!contract) {
    throw new Error("Contract not found");
  }

  function handleCommit() {
    openSnackbar("Notes saved.");
  }

  const { contractPageLayoutSetting, commentingEnabled, notesOverViewer } =
    organization;
  const shouldShowCommentsSection =
    commentingEnabled &&
    (contractPageLayoutSetting === "SHOW_NOTES_AND_COMMENTS" ||
      contractPageLayoutSetting === "SHOW_COMMENTS_ONLY");

  const shouldShowNotesSection =
    contractPageLayoutSetting === "SHOW_NOTES_AND_COMMENTS" ||
    contractPageLayoutSetting === "SHOW_NOTES_ONLY";
  const readOnly = account.readOnly;

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "grid",
        gridTemplateAreas: `"file-viewer notes-comments"`,
        gridTemplateColumns: "1fr 1fr",
      }}
      columnGap={5}
    >
      <Box sx={{ gridArea: "file-viewer", backgroundColor: "red" }}>
        <PDFViewerArea
          contractFragRef={contract}
          accountFragRef={account}
          organizationFragRef={organization}
        />
      </Box>
      <Stack sx={{ gridArea: "notes-comments" }} rowGap={5}>
        {shouldShowCommentsSection &&
          shouldShowNotesSection &&
          notesOverViewer && (
            <>
              <Notes
                contract={contract}
                onCommit={handleCommit}
                disabled={readOnly}
              />
              <CommentsSection currentUser={account} contract={contract} />
            </>
          )}
        {shouldShowCommentsSection &&
          shouldShowNotesSection &&
          !notesOverViewer && (
            <>
              <CommentsSection currentUser={account} contract={contract} />
              <Notes
                contract={contract}
                onCommit={handleCommit}
                disabled={readOnly}
              />
            </>
          )}
        {shouldShowCommentsSection && !shouldShowNotesSection && (
          <CommentsSection currentUser={account} contract={contract} />
        )}
        {!shouldShowCommentsSection && shouldShowNotesSection && (
          <Notes
            contract={contract}
            onCommit={handleCommit}
            disabled={readOnly}
          />
        )}
      </Stack>
    </Box>
  );
}

export function CommentsWithFallback() {
  return (
    <Suspense fallback={<div>Loading</div>}>
      <Comments />
    </Suspense>
  );
}

const comments_Query = graphql`
  query comments_Query($uuid: String!) {
    account {
      readOnly
      ...CommentsSectionContent_accountFragment
      ...PDFViewerArea_accountFragment
    }
    organization {
      contractPageLayoutSetting
      commentingEnabled
      notesOverViewer
      ...PDFViewerArea_organizationFragment
    }
    contract(uuid: $uuid) {
      uuid
      ...CommentsSectionContent_contractFragment
      ...Notes_fragment
      ...PDFViewerArea_contractFragment
    }
  }
`;
