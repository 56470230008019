/**
 * @generated SignedSource<<23e95e901db8dc587623adf92b5272ff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContractForms_DeadlineToNonrenewFragment$data = {
  readonly autoRenew: boolean;
  readonly date: any | null | undefined;
  readonly enableReminder: boolean;
  readonly id: string;
  readonly label: string;
  readonly protected: boolean | null | undefined;
  readonly reminder: {
    readonly " $fragmentSpreads": FragmentRefs<"EditDateDialog_reminderFragment">;
  } | null | undefined;
  readonly uuid: string;
  readonly " $fragmentType": "ContractForms_DeadlineToNonrenewFragment";
};
export type ContractForms_DeadlineToNonrenewFragment$key = {
  readonly " $data"?: ContractForms_DeadlineToNonrenewFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContractForms_DeadlineToNonrenewFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContractForms_DeadlineToNonrenewFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uuid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "autoRenew",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enableReminder",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "protected",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ReminderType",
      "kind": "LinkedField",
      "name": "reminder",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EditDateDialog_reminderFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ContractDateType",
  "abstractKey": null
};

(node as any).hash = "25304ff4e47cdb483c8c2926aeff164f";

export default node;
