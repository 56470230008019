/**
 * @generated SignedSource<<b456088c7573ff5e8046479796e008ed>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PDFViewer_SetContractValueMutation$variables = {
  currencySymbol: string;
  totalValue?: number | null | undefined;
  uuid: string;
};
export type PDFViewer_SetContractValueMutation$data = {
  readonly updateContract: {
    readonly contract: {
      readonly currencySymbol: string | null | undefined;
      readonly id: string;
      readonly totalValue: any | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type PDFViewer_SetContractValueMutation = {
  response: PDFViewer_SetContractValueMutation$data;
  variables: PDFViewer_SetContractValueMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "currencySymbol"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "totalValue"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "uuid"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "currencySymbol",
            "variableName": "currencySymbol"
          },
          {
            "kind": "Variable",
            "name": "totalValue",
            "variableName": "totalValue"
          },
          {
            "kind": "Variable",
            "name": "uuid",
            "variableName": "uuid"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "UpdateContractPayload",
    "kind": "LinkedField",
    "name": "updateContract",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ContractType",
        "kind": "LinkedField",
        "name": "contract",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalValue",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currencySymbol",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PDFViewer_SetContractValueMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "PDFViewer_SetContractValueMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "a2727030dfdfc3e7be4563b956a6c3d1",
    "id": null,
    "metadata": {},
    "name": "PDFViewer_SetContractValueMutation",
    "operationKind": "mutation",
    "text": "mutation PDFViewer_SetContractValueMutation(\n  $uuid: String!\n  $currencySymbol: String!\n  $totalValue: Float\n) {\n  updateContract(input: {uuid: $uuid, currencySymbol: $currencySymbol, totalValue: $totalValue}) {\n    contract {\n      id\n      totalValue\n      currencySymbol\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1c58ab22d128ac5d630cfc8943784f55";

export default node;
