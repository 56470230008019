/**
 * Throws an error if the given value is falsy.
 *
 * @param value The value to check
 * @param message The error message to throw
 * @param capture Whether to capture the error in our error reporting service (not implemented yet)
 */

export default function invariant(
  value: boolean,
  message?: string,
  capture?: boolean,
): asserts value;

export default function invariant<T>(
  value: T | null | undefined,
  message?: string,
  capture?: boolean,
): asserts value is T;

export default function invariant(
  value: any,
  message?: string,
  capture: boolean = true,
) {
  if (value === false || value === null || typeof value === "undefined") {
    // TODO: log to Sentry when we have that implemented on the frontend if capture is true
    console.error(
      `Invariant violation. Expected given value to be truthy. value: ${value}`,
    );
    throw new Error(message);
  }
}
