import {
  ConnectionHandler,
  type RecordSourceSelectorProxy,
} from "relay-runtime";
import invariant from "~/utils/invariant";
import { nonNullablePrimitiveInvariant } from "~/utils/relayStore";
import * as Sentry from "@sentry/react";

/**
 * Resorts the comment edges in the Relay store for our CommentList_contractFragment_comments connection.
 *
 * Comments are sorted in the following order:
 * - Pinned comments sorted with the most recently pinned comments first
 * - Unpinned comments sorted with the most recently created comments first
 *
 * @param store - The Relay store.
 * @param contractId - The ID of the contract.
 */
export function resortCommentEdges(
  store: RecordSourceSelectorProxy,
  contractId: string,
) {
  const connectionRecord = ConnectionHandler.getConnection(
    store.get(contractId)!,
    "CommentList_contractFragment_comments",
  );

  if (!connectionRecord) {
    const errorMessage =
      "Error updating comment in Relay store. No connection record could be found. Please make sure you have a connection with the key `CommentList_contractFragment_comments` in your fragment.";
    console.error(errorMessage);
    Sentry.captureException(new Error(errorMessage));
    return;
  }

  const edges = connectionRecord.getLinkedRecords("edges") ?? [];

  const pinnedCommentEdges = edges.filter((edge) => {
    const node = edge?.getLinkedRecord("node");
    invariant(node, "Expected comment node to exist");
    return node.getValue("pinnedAt");
  });
  const unpinnedCommentEdges = edges.filter((edge) => {
    const node = edge?.getLinkedRecord("node");
    invariant(node, "Expected comment node to exist");
    return !node.getValue("pinnedAt");
  });

  const sortedPinnedCommentEdges = pinnedCommentEdges.sort((a, b) => {
    const aPinnedAt = a.getLinkedRecord("node")?.getValue("pinnedAt");
    const bPinnedAt = b.getLinkedRecord("node")?.getValue("pinnedAt");

    nonNullablePrimitiveInvariant<string>(aPinnedAt);
    nonNullablePrimitiveInvariant<string>(bPinnedAt);

    return new Date(bPinnedAt).getTime() - new Date(aPinnedAt).getTime();
  });

  const sortedUnpinnedCommentEdges = unpinnedCommentEdges.sort((a, b) => {
    const aDateCreated = a.getLinkedRecord("node")?.getValue("dateCreated");
    const bDateCreated = b.getLinkedRecord("node")?.getValue("dateCreated");

    nonNullablePrimitiveInvariant<string>(aDateCreated);
    nonNullablePrimitiveInvariant<string>(bDateCreated);

    return new Date(bDateCreated).getTime() - new Date(aDateCreated).getTime();
  });

  const sortedCommentEdges = [
    ...sortedPinnedCommentEdges,
    ...sortedUnpinnedCommentEdges,
  ];

  // Update the connection record with the new edges
  connectionRecord.setLinkedRecords(sortedCommentEdges, "edges");
}
