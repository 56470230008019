import type { CommentTextEditorContent } from "./CommentTextEditor/CommentTextEditor";
import type { JSONContent } from "@tiptap/core";

type MentionEmail = string;

type ParseMentionsResult =
  | { isValid: true; mentionEmails: MentionEmail[] }
  | {
      isValid: false;
      mentionEmails: MentionEmail[];
      invalidMentionEmails: MentionEmail[];
    };

/**
 * Check if the comment content contains non-organization mentions.
 */
export function parseMentions(
  content: CommentTextEditorContent,
): ParseMentionsResult {
  // we use the json content because they could have manually typed out an organization
  const validMentionEmails = getMentionEmails(content.jsonContent);
  const potentialMentions = content.textContent.matchAll(/(^|\s)@(\S+)/g) ?? [];

  let invalidMentionEmails = [];
  for (const potentialMention of potentialMentions) {
    if (
      potentialMention[2] &&
      !validMentionEmails.includes(potentialMention[2])
    ) {
      invalidMentionEmails.push(potentialMention[2]);
    }
  }

  if (invalidMentionEmails.length > 0) {
    return {
      isValid: false,
      mentionEmails: validMentionEmails,
      invalidMentionEmails,
    };
  }

  return {
    isValid: true,
    mentionEmails: validMentionEmails,
  };
}

function getMentionEmails(
  node: JSONContent,
  mentionEmails: MentionEmail[] = [],
): MentionEmail[] {
  if (node.type === "mention" && node.attrs?.label) {
    mentionEmails.push(node.attrs.label);
  }

  if (node.content) {
    node.content.forEach((childNode: any) => {
      getMentionEmails(childNode, mentionEmails);
    });
  }

  return mentionEmails;
}
