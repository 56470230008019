/**
 * @generated SignedSource<<6ea1fcde68790749964103ff1a21721f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserCommentRevisionsDialog_commentQuery$variables = {
  commentUuid: string;
};
export type UserCommentRevisionsDialog_commentQuery$data = {
  readonly comment: {
    readonly " $fragmentSpreads": FragmentRefs<"UserCommentRevisionsDialog_commentFragment">;
  } | null | undefined;
};
export type UserCommentRevisionsDialog_commentQuery = {
  response: UserCommentRevisionsDialog_commentQuery$data;
  variables: UserCommentRevisionsDialog_commentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "commentUuid"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "uuid",
    "variableName": "commentUuid"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "email",
    "storageKey": null
  },
  (v2/*: any*/),
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dateCreated",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserCommentRevisionsDialog_commentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "comment",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserCommentRevisionsDialog_commentFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserCommentRevisionsDialog_commentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "comment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isCommentResultType"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AccountType",
                "kind": "LinkedField",
                "name": "author",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CommentContentEditRevisionType",
                "kind": "LinkedField",
                "name": "contentEditRevisions",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AccountType",
                    "kind": "LinkedField",
                    "name": "actor",
                    "plural": false,
                    "selections": (v4/*: any*/),
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "updatedHtmlContent",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "type": "CommentType",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "debugMessage",
                "storageKey": null
              }
            ],
            "type": "ResultError",
            "abstractKey": "__isResultError"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/)
            ],
            "type": "Node",
            "abstractKey": "__isNode"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "200283c9162820c86d1db8181787ad66",
    "id": null,
    "metadata": {},
    "name": "UserCommentRevisionsDialog_commentQuery",
    "operationKind": "query",
    "text": "query UserCommentRevisionsDialog_commentQuery(\n  $commentUuid: String!\n) {\n  comment(uuid: $commentUuid) {\n    __typename\n    ...UserCommentRevisionsDialog_commentFragment\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment UserCommentRevisionsDialog_commentFragment on CommentResultType {\n  __isCommentResultType: __typename\n  __typename\n  ... on CommentType {\n    author {\n      email\n      uuid\n      id\n    }\n    contentEditRevisions {\n      ...UserCommentRevisionsDialog_contentEditRevisionFragment\n    }\n    dateCreated\n  }\n  ... on ResultError {\n    __isResultError: __typename\n    debugMessage\n  }\n}\n\nfragment UserCommentRevisionsDialog_contentEditRevisionFragment on CommentContentEditRevisionType {\n  actor {\n    email\n    uuid\n    id\n  }\n  dateCreated\n  updatedHtmlContent\n  uuid\n}\n"
  }
};
})();

(node as any).hash = "70919dd1a9be132a0dd1624ed5aada92";

export default node;
