/**
 * @generated SignedSource<<61bab7672df78370777d99d384ea987c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommentList_commentEdgesFragment$data = ReadonlyArray<{
  readonly node: {
    readonly author: {
      readonly uuid: string;
    };
    readonly id: string;
    readonly pinnedAt: string | null | undefined;
    readonly uuid: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"UserComment_commentEdgeFragment">;
  readonly " $fragmentType": "CommentList_commentEdgesFragment";
}>;
export type CommentList_commentEdgesFragment$key = ReadonlyArray<{
  readonly " $data"?: CommentList_commentEdgesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommentList_commentEdgesFragment">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "CommentList_commentEdgesFragment",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": "CommentType",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          (v0/*: any*/),
          {
            "kind": "RequiredField",
            "field": {
              "alias": null,
              "args": null,
              "concreteType": "AccountType",
              "kind": "LinkedField",
              "name": "author",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            },
            "action": "THROW",
            "path": "node.author"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pinnedAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      "action": "THROW",
      "path": "node"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserComment_commentEdgeFragment"
    }
  ],
  "type": "CommentTypeEdge",
  "abstractKey": null
};
})();

(node as any).hash = "c931a9b447dd8dac57081a03aed7bfcf";

export default node;
