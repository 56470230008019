/**
 * @generated SignedSource<<fdd38283deb1e1a0a6da6b9d9615682e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PartyDialog_UpdatePartyMutation$variables = {
  companyName: string;
  partyUuid: string;
};
export type PartyDialog_UpdatePartyMutation$data = {
  readonly updateParty: {
    readonly party: {
      readonly companyName: string | null | undefined;
      readonly contacts: ReadonlyArray<{
        readonly " $fragmentSpreads": FragmentRefs<"PartyDialog_Contactfragment">;
      } | null | undefined> | null | undefined;
      readonly id: string;
      readonly uuid: string;
    } | null | undefined;
  } | null | undefined;
};
export type PartyDialog_UpdatePartyMutation = {
  response: PartyDialog_UpdatePartyMutation$data;
  variables: PartyDialog_UpdatePartyMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyName"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "partyUuid"
  }
],
v1 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "companyName",
            "variableName": "companyName"
          },
          {
            "kind": "Variable",
            "name": "uuid",
            "variableName": "partyUuid"
          }
        ],
        "kind": "ObjectValue",
        "name": "party"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PartyDialog_UpdatePartyMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdatePartyPayload",
        "kind": "LinkedField",
        "name": "updateParty",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PartyType",
            "kind": "LinkedField",
            "name": "party",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ContactType",
                "kind": "LinkedField",
                "name": "contacts",
                "plural": true,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "PartyDialog_Contactfragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PartyDialog_UpdatePartyMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdatePartyPayload",
        "kind": "LinkedField",
        "name": "updateParty",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PartyType",
            "kind": "LinkedField",
            "name": "party",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ContactType",
                "kind": "LinkedField",
                "name": "contacts",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "phone",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "altPhone",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "streetName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "streetName2",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cityName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "stateAbbreviation",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "zipcode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "province",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "country",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isPrimary",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fullname",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "initials",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "327fa61f11bf56d6147dcedf4d5edd35",
    "id": null,
    "metadata": {},
    "name": "PartyDialog_UpdatePartyMutation",
    "operationKind": "mutation",
    "text": "mutation PartyDialog_UpdatePartyMutation(\n  $companyName: String!\n  $partyUuid: String!\n) {\n  updateParty(input: {party: {uuid: $partyUuid, companyName: $companyName}}) {\n    party {\n      id\n      uuid\n      companyName\n      contacts {\n        ...PartyDialog_Contactfragment\n        id\n      }\n    }\n  }\n}\n\nfragment PartyDialog_Contactfragment on ContactType {\n  uuid\n  firstName\n  lastName\n  title\n  phone\n  altPhone\n  email\n  streetName\n  streetName2\n  cityName\n  stateAbbreviation\n  zipcode\n  province\n  country\n  isPrimary\n  fullname\n  initials\n}\n"
  }
};
})();

(node as any).hash = "1c04f5d1acdf48bfc81bd562b80f564b";

export default node;
