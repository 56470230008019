import { commitLocalUpdate, fetchQuery, commitMutation } from "relay-runtime";
import environment from "~/RelayEnvironment";

export function invalidateRelayStore() {
  return commitLocalUpdate(environment, (store) => store.invalidateStore());
}

export function preloadQuery(query, variables, options) {
  fetchQuery(environment, query, variables, {
    fetchPolicy: "store-or-network",
    ...options,
    networkCacheConfig: { force: false, ...options?.networkCacheConfig },
  }).subscribe({});
}

export function loadQuery(...args) {
  var result = null;

  return new Promise((resolve, reject) => {
    fetchQuery(environment, ...args).subscribe({
      next: (data) => {
        result = data;
      },
      complete: () => resolve(result),
    });
  });
}

export function makeMutation(mutation, variables) {
  return new Promise((resolve, reject) => {
    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (response, errors) => {
        if (errors) {
          reject(errors);
        } else {
          resolve(response);
        }
      },
      onError: (error) => {
        reject(error);
      },
    });
  });
}
