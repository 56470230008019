import { Extension } from "@tiptap/core";
import { Fragment as ProseMirrorFragment, type Node } from "@tiptap/pm/model";
import { PluginKey } from "@tiptap/pm/state";
import type { EditorView } from "@tiptap/pm/view";
import { Plugin } from "@tiptap/pm/state";
import { Decoration, DecorationSet } from "@tiptap/pm/view";
import * as Sentry from "@sentry/react";

type PluginState =
  | {
      state: "EXPANDED";
      decorations: DecorationSet;
    }
  | {
      state: "TRUNCATED";
      decorations: DecorationSet;
      fullContent: ProseMirrorFragment;
    }
  | { state: "INITIALIZED" };

type TransactionMeta =
  | { type: "INIT" }
  | { type: "EXPAND_CONTENT" }
  | { type: "TRUNCATE_CONTENT"; fullContent: ProseMirrorFragment };

const pluginKey = new PluginKey<PluginState>("contentToggle");

export const TruncateContentExtension = Extension.create({
  name: "truncateContent",

  addOptions() {
    return {
      truncateLength: 300,
    };
  },

  addProseMirrorPlugins() {
    const extension = this;

    return [
      new Plugin<PluginState>({
        key: pluginKey,
        appendTransaction: (transactions, _oldState, _newState) => {
          try {
            for (const transaction of transactions) {
              const meta = transaction.getMeta(pluginKey);
              const isInternalTransaction = !!meta;

              if (!isInternalTransaction) {
                continue;
              }

              const transactionMeta = meta as TransactionMeta;

              if (transactionMeta.type === "INIT") {
                const isFullContentTooLong =
                  transaction.doc.content.size >
                  (extension.options.truncateLength as number);

                if (isFullContentTooLong) {
                  const truncateContentTransaction =
                    getTruncateContentTransaction(
                      extension.editor.view,
                      extension.options.truncateLength,
                      false,
                    );
                  return truncateContentTransaction;
                }
              }
            }
          } catch (error) {
            Sentry.captureException(error);
          }

          return null;
        },
        state: {
          init(_config, _editorState) {
            return {
              state: "INITIALIZED",
            };
          },
          apply(
            tr,
            currentPluginState,
            _oldEditorState,
            _newEditorState,
          ): PluginState {
            try {
              const meta = tr.getMeta(pluginKey);
              const isInternalTransaction = !!meta;

              if (!isInternalTransaction) {
                return currentPluginState;
              }

              const transactionMeta = meta as TransactionMeta;

              switch (transactionMeta.type) {
                case "INIT":
                  return currentPluginState;
                case "TRUNCATE_CONTENT": {
                  const handleButtonClick = () => {
                    const newTransaction = getExpandContentTransaction(
                      extension.editor.view,
                      transactionMeta.fullContent,
                    );
                    extension.editor.view.dispatch(newTransaction);
                  };

                  return {
                    state: "TRUNCATED",
                    decorations: getDecorations(
                      tr.doc,
                      extension.editor.view,
                      "TRUNCATED",
                      handleButtonClick,
                    ),
                    fullContent: transactionMeta.fullContent,
                  };
                }
                case "EXPAND_CONTENT":
                  const handleButtonClick = () => {
                    const newTransaction = getTruncateContentTransaction(
                      extension.editor.view,
                      extension.options.truncateLength,
                      true,
                    );
                    extension.editor.view.dispatch(newTransaction);
                  };

                  return {
                    state: "EXPANDED",
                    decorations: getDecorations(
                      tr.doc,
                      extension.editor.view,
                      "EXPANDED",
                      handleButtonClick,
                    ),
                  };
              }
            } catch (error) {
              Sentry.captureException(error);
              return currentPluginState;
            }
          },
        },
        props: {
          decorations(editorState) {
            const pluginState = pluginKey.getState(editorState)!;
            const state = pluginState?.state;
            if (state === "EXPANDED" || state === "TRUNCATED") {
              return pluginState.decorations;
            }

            return DecorationSet.empty;
          },
        },
      }),
    ];
  },
});

function getDecorations(
  doc: Node,
  view: EditorView,
  state: "TRUNCATED" | "EXPANDED",
  onToggle: () => void,
) {
  const decoration = Decoration.widget(
    doc.content.size - 1,
    createButton(view, state, onToggle),
    { side: 1 },
  );

  return DecorationSet.create(doc, [decoration]);
}

function createButton(
  view: EditorView,
  state: "TRUNCATED" | "EXPANDED",
  onToggle: () => void,
) {
  const button = document.createElement("button");
  button.textContent = state === "TRUNCATED" ? "...Show more" : "...Show less";
  button.setAttribute("data-testid", "comment-content-toggle-button");
  button.addEventListener("click", (event) => {
    onToggle();
  });
  button.addEventListener("mouseover", () => {
    button.style.textDecoration = "underline";
  });
  button.addEventListener("mouseleave", () => {
    button.style.textDecoration = "none";
  });

  button.style.border = "none";
  button.style.cursor = "pointer";
  button.style.color = "#0d4eb5";
  button.style.background = "0 0";
  button.style.margin = "0 4px";
  button.style.padding = "0";
  button.style.display = "inline";

  return button;
}

export function getInitialTransaction(view: EditorView) {
  const transaction = view.state.tr;
  transaction.setMeta(pluginKey, {
    type: "INIT",
  });
  return transaction;
}

function getTruncateContentTransaction(
  view: EditorView,
  truncateLength: number,
  enableScrollTo: boolean,
) {
  const pos = Math.min(truncateLength, view.state.doc.content.size);

  const transaction = view.state.tr.replaceWith(
    pos,
    view.state.doc.content.size,
    ProseMirrorFragment.empty,
  );
  transaction.setMeta(pluginKey, {
    type: "TRUNCATE_CONTENT",
    fullContent: view.state.doc.content,
  });

  if (enableScrollTo) {
    // scrolls back to the top of the comment instead of the next comment after truncation
    transaction.scrollIntoView();
  }

  return transaction;
}

function getExpandContentTransaction(
  view: EditorView,
  fullContent: ProseMirrorFragment,
) {
  const transaction = view.state.tr.replaceWith(
    0,
    view.state.doc.content.size,
    fullContent,
  );
  transaction.setMeta(pluginKey, {
    type: "EXPAND_CONTENT",
  });
  return transaction;
}
