/**
 * @generated SignedSource<<6898cfb0dd9338bdfd06158e7daea970>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TemplateVariableRegularField_organizationFragment$data = {
  readonly dateFormat: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"fieldHelpers_organizationInlineFragment">;
  readonly " $fragmentType": "TemplateVariableRegularField_organizationFragment";
};
export type TemplateVariableRegularField_organizationFragment$key = {
  readonly " $data"?: TemplateVariableRegularField_organizationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"TemplateVariableRegularField_organizationFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dateFormat",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  (v1/*: any*/),
  (v2/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TemplateVariableRegularField_organizationFragment",
  "selections": [
    (v0/*: any*/),
    {
      "kind": "InlineDataFragmentSpread",
      "name": "fieldHelpers_organizationInlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "TagType",
          "kind": "LinkedField",
          "name": "tags",
          "plural": true,
          "selections": (v3/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "OrgContractTypeType",
          "kind": "LinkedField",
          "name": "orgContractTypes",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "customName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isActive",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CustomFieldType",
          "kind": "LinkedField",
          "name": "customFields",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "fieldType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ValueListItemType",
              "kind": "LinkedField",
              "name": "choices",
              "plural": true,
              "selections": (v3/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "OrganizationType",
  "abstractKey": null
};
})();

(node as any).hash = "7e2942f78087a498973bbe77b6292caf";

export default node;
