/**
 * @generated SignedSource<<8646b43660dd526ac75448033382401d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TemplateFields_builtinFieldsFragment$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"fieldHelpers_builtinFieldInlineFragment">;
  readonly " $fragmentType": "TemplateFields_builtinFieldsFragment";
}>;
export type TemplateFields_builtinFieldsFragment$key = ReadonlyArray<{
  readonly " $data"?: TemplateFields_builtinFieldsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"TemplateFields_builtinFieldsFragment">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "TemplateFields_builtinFieldsFragment",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "fieldHelpers_builtinFieldInlineFragment",
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fieldType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "choices",
          "plural": true,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/)
              ],
              "type": "StringChoiceType",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "BuiltinFieldType",
  "abstractKey": null
};
})();

(node as any).hash = "adff52696e03a601a1499073d7fe2922";

export default node;
