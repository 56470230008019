import React, { useRef, useState, useEffect } from "react";
import MuiTextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Edit from "@mui/icons-material/Edit";
import useDebouncedCommit from "~/utils/UseDebouncedCommit";
import ModeEditOutlinedIcon from "~Icons/ModeEditOutlinedIcon.svg";

function getCaretOffset(target) {
  let sel = window.getSelection();
  if (sel.rangeCount === 0) {
    return null;
  }
  return sel.getRangeAt(0).endOffset;
}

function setCaretOffset(target, offset) {
  const sel = window.getSelection();
  const node = target.firstChild;
  try {
    const clampedOffset = Math.min(Math.max(offset, 0), node.length ?? 0);
    const range = new Range();
    range.setStart(node, clampedOffset);
    range.setEnd(node, clampedOffset);
    sel.removeAllRanges();
    sel.addRange(range);
    target.focus();
    range.detach();
  } catch (e) {
    console.log("couldn't set range", e);
  }
}

function setCaretOffsetToEnd(target) {
  const sel = window.getSelection();
  const range = document.createRange();
  range.selectNodeContents(target);
  range.collapse(false);
  sel.removeAllRanges();
  sel.addRange(range);
  target.focus();
  range.detach();
}

const EditableTypography = React.forwardRef(
  (
    { value, onChange, onBlur, inputRef, color, variant, disabled, ...props },
    ref,
  ) => {
    const [offset, setOffset] = useState(null);

    useEffect(() => {
      if (inputRef.current && inputRef.current.firstChild && offset != null) {
        setCaretOffset(inputRef.current, offset);
      }
    }, [offset, value]);

    return (
      <Typography
        contentEditable={!disabled}
        suppressContentEditableWarning
        onInput={(e) => {
          setOffset(getCaretOffset(e.target));
          onChange(e.target.textContent);
        }}
        onBlur={onBlur}
        onKeyPress={(e) => {
          if (e.charCode === 13) {
            e.preventDefault();
            e.target.blur();
          }
        }}
        ref={inputRef}
        variant={variant}
        sx={{
          color,
          width: "initial",
          paddingTop: 0,
          paddingBottom: 0,
          maxWidth: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          "&:focus": {
            textOverflow: "wrap",
            whiteSpace: "normal",
            textAlign: "left",
            outline: "none",
          },
          cursor: `url("${ModeEditOutlinedIcon}") 0 24, auto`,
        }}
      >
        {value}
      </Typography>
    );
  },
);

export function BaseEditableText({
  color,
  variant,
  value,
  onChange,
  onBlur,
  disabled,
  ...restProps
}) {
  const inputRef = useRef(null);
  const InputPropsSx = restProps.InputProps?.sx ?? {};

  return (
    <MuiTextField
      sx={{ display: "flex" }}
      onChange={onChange}
      value={value || ""}
      disabled={disabled}
      inputProps={{
        inputRef,
        color,
        variant,
        disabled,
      }}
      data-testid={restProps["data-testid"] ?? "editable-text"}
      InputProps={{
        inputComponent: EditableTypography,
        onBlur: onBlur,
        endAdornment: disabled ? null : (
          <InputAdornment position="end">
            <IconButton
              disableRipple
              disableFocusRipple
              onClick={() => setCaretOffsetToEnd(inputRef.current)}
              size="small"
              aria-label="edit"
            >
              <Edit style={{ width: 20, height: 20, color: color }} />
            </IconButton>
          </InputAdornment>
        ),
        sx: [
          {
            whiteSpace: "nowrap",
            "& fieldset": {
              border: 0,
            },
          },
          ...(Array.isArray(InputPropsSx) ? InputPropsSx : [InputPropsSx]),
        ],
      }}
    />
  );
}

export default function EditableText({ value, commit, isInFlight, ...props }) {
  const [curValue, setCurValue, debouncedCommit, flushDebouncedCommit] =
    useDebouncedCommit(commit, isInFlight, value, [commit]);

  function handleChange(value) {
    setCurValue(value);
    debouncedCommit(value);
  }

  return (
    <BaseEditableText
      {...props}
      value={curValue}
      onChange={handleChange}
      onBlur={flushDebouncedCommit}
    />
  );
}
