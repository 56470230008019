import React, { useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "~/components/Button";

export default function SubInputCheckbox({
  name,
  label,
  labelProps,
  checked,
  onChange,
  onClick,
  disabled,
  ...props
}) {
  return (
    <FormControlLabel
      control={
        <Checkbox
          size="small"
          style={{ marginLeft: -4, paddingLeft: 4, paddingRight: 4 }}
          checked={checked}
          name={name}
          onChange={onChange}
          disabled={disabled}
        />
      }
      label={
        <Button
          variant="text"
          onClick={onClick}
          disabled={disabled}
          style={{
            textAlign: "start",
            fontSize: 13,
          }}
          {...labelProps}
        >
          {label}
        </Button>
      }
      sx={{ marginLeft: 0, marginRight: 0 }}
      {...props}
    />
  );
}
