/**
 * @generated SignedSource<<5fd2409c6118828649f069c06da51cc8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PartyDialog__setPartyContactMutation$variables = {
  contactuuid: string;
  ctpartyuuid: string;
};
export type PartyDialog__setPartyContactMutation$data = {
  readonly setPartyContact: {
    readonly ctparty: {
      readonly " $fragmentSpreads": FragmentRefs<"PartyDialog_CTPartyfragment">;
    } | null | undefined;
  } | null | undefined;
};
export type PartyDialog__setPartyContactMutation = {
  response: PartyDialog__setPartyContactMutation$data;
  variables: PartyDialog__setPartyContactMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contactuuid"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ctpartyuuid"
},
v2 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "contactuuid",
        "variableName": "contactuuid"
      },
      {
        "kind": "Variable",
        "name": "ctpartyuuid",
        "variableName": "ctpartyuuid"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v5 = [
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "firstName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "lastName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "title",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "phone",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "altPhone",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "email",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "streetName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "streetName2",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "cityName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "stateAbbreviation",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "zipcode",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "province",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "country",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isPrimary",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "fullname",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "initials",
    "storageKey": null
  },
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PartyDialog__setPartyContactMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "SetPartyContactPayload",
        "kind": "LinkedField",
        "name": "setPartyContact",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractPartyType",
            "kind": "LinkedField",
            "name": "ctparty",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "PartyDialog_CTPartyfragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "PartyDialog__setPartyContactMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "SetPartyContactPayload",
        "kind": "LinkedField",
        "name": "setPartyContact",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractPartyType",
            "kind": "LinkedField",
            "name": "ctparty",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "label",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PartyType",
                "kind": "LinkedField",
                "name": "party",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "companyName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContactType",
                    "kind": "LinkedField",
                    "name": "contacts",
                    "plural": true,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ContactType",
                "kind": "LinkedField",
                "name": "contact",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bafe4f7731543bd25df777922f9569ea",
    "id": null,
    "metadata": {},
    "name": "PartyDialog__setPartyContactMutation",
    "operationKind": "mutation",
    "text": "mutation PartyDialog__setPartyContactMutation(\n  $ctpartyuuid: String!\n  $contactuuid: String!\n) {\n  setPartyContact(input: {ctpartyuuid: $ctpartyuuid, contactuuid: $contactuuid}) {\n    ctparty {\n      ...PartyDialog_CTPartyfragment\n      id\n    }\n  }\n}\n\nfragment PartyDialog_CTPartyfragment on ContractPartyType {\n  id\n  uuid\n  label\n  party {\n    id\n    uuid\n    companyName\n    contacts {\n      ...PartyDialog_Contactfragment\n      id\n    }\n  }\n  contact {\n    uuid\n    ...PartyDialog_Contactfragment\n    id\n  }\n}\n\nfragment PartyDialog_Contactfragment on ContactType {\n  uuid\n  firstName\n  lastName\n  title\n  phone\n  altPhone\n  email\n  streetName\n  streetName2\n  cityName\n  stateAbbreviation\n  zipcode\n  province\n  country\n  isPrimary\n  fullname\n  initials\n}\n"
  }
};
})();

(node as any).hash = "8fa71fa3e2362daff6d89719acbd3503";

export default node;
