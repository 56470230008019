import React from "react";
import ButtonGroup from "@mui/material/ButtonGroup";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Button from "@mui/material/Button";
import LinkIcon from "@mui/icons-material/Link";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import Tooltip from "@mui/material/Tooltip";
import { graphql, useFragment } from "react-relay";
import type { AddContractButton_organizationFragment$key } from "./__generated__/AddContractButton_organizationFragment.graphql";
import Box from "@mui/material/Box";

type AddContractButtonProps = {
  contractUuid: string;
  organizationFragRef: AddContractButton_organizationFragment$key;
  onSelect: (value: "upload" | "link" | "template") => void;
};

export function AddContractButton(props: AddContractButtonProps) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const organization = useFragment(
    ORGANIZATION_FRAGMENT,
    props.organizationFragRef,
  );
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const showDropdownButton =
    waffle.flag_is_active(
      "contract_empty_state_v2_add_contract_file_via_link_enabled",
    ) ||
    waffle.flag_is_active(
      "contract_empty_state_v2_add_contract_file_from_template_enabled",
    );

  function renderTemplateButton() {
    if (
      !waffle.flag_is_active(
        "contract_empty_state_v2_add_contract_file_from_template_enabled",
      )
    ) {
      return null;
    }

    if (organization.termsTemplatesActive) {
      return (
        <MenuItem
          key="template"
          value="template"
          onClick={() => props.onSelect("template")}
          data-testid="add-contract-button-template-button"
        >
          <FileOpenIcon sx={{ mr: 2 }} />
          From Template
        </MenuItem>
      );
    }

    if (organization.termsTemplatesEnabled) {
      return (
        <Tooltip title="Templates must be enabled in your organization's settings to use this feature.">
          <Box>
            <MenuItem
              disabled={true}
              key="template"
              value="template"
              data-testid="add-contract-button-template-button"
            >
              <FileOpenIcon sx={{ mr: 2 }} />
              From Template
            </MenuItem>
          </Box>
        </Tooltip>
      );
    }

    return (
      <Tooltip title="Upgrade your plan to use this feature.">
        <Box>
          <MenuItem
            disabled={true}
            key="template"
            value="template"
            data-testid="add-contract-button-template-button"
          >
            <FileOpenIcon sx={{ mr: 2 }} />
            From Template
          </MenuItem>
        </Box>
      </Tooltip>
    );
  }

  return (
    <>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="Button group with a nested menu"
        sx={{ minWidth: "225px" }}
      >
        <Button
          sx={{ fontWeight: "bold", flexGrow: 1 }}
          data-testid="add-contract-button-upload-contract-file-button"
          onClick={() => props.onSelect("upload")}
        >
          Upload a File
        </Button>
        {showDropdownButton && (
          <Button
            size="small"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="add contract strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
            data-testid="add-contract-button-select"
          >
            <ArrowDropDownIcon />
          </Button>
        )}
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-start"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "top-end" : "bottom-end",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {waffle.flag_is_active(
                    "contract_empty_state_v2_add_contract_file_via_link_enabled",
                  ) && (
                    <MenuItem
                      key="url"
                      value="url"
                      onClick={() => props.onSelect("link")}
                      data-testid="add-contract-button-link-button"
                    >
                      <LinkIcon sx={{ mr: 2 }} />
                      Link an Online Agreement
                    </MenuItem>
                  )}
                  {renderTemplateButton()}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

const ORGANIZATION_FRAGMENT = graphql`
  fragment AddContractButton_organizationFragment on OrganizationType {
    termsTemplatesActive
    termsTemplatesEnabled
  }
`;
