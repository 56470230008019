/**
 * @generated SignedSource<<32cbbd01b0bd0c0177c43b503990f5fe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Summary_contractFragment$data = {
  readonly id: string;
  readonly isactive: boolean;
  readonly legal: {
    readonly executionStatus: string;
    readonly id: string;
  } | null | undefined;
  readonly uuid: string;
  readonly " $fragmentSpreads": FragmentRefs<"ContractActiveStatusChip_contractFragment" | "ContractExecutionStatusChip_contractFragment">;
  readonly " $fragmentType": "Summary_contractFragment";
};
export type Summary_contractFragment$key = {
  readonly " $data"?: Summary_contractFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"Summary_contractFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Summary_contractFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uuid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isactive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LegalType",
      "kind": "LinkedField",
      "name": "legal",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "executionStatus",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContractActiveStatusChip_contractFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContractExecutionStatusChip_contractFragment"
    }
  ],
  "type": "ContractType",
  "abstractKey": null
};
})();

(node as any).hash = "8700876ce5d216ae8d07ee67840efae3";

export default node;
