import { graphql, useFragment } from "react-relay";
import type { useContractFileState_contractFragment$key } from "./__generated__/useContractFileState_contractFragment.graphql";

export type ContractFileState = "NO_FILE" | "FILE" | "FILE_VIA_URL";

export function useContractFileState(
  contractFragRef: useContractFileState_contractFragment$key,
): ContractFileState {
  const contract = useFragment(
    useContractFileState_contractFragment,
    contractFragRef,
  );

  const hasExistingFile = !!contract.filename;
  let fileState: "NO_FILE" | "FILE" | "FILE_VIA_URL" = "NO_FILE";

  if (!hasExistingFile) {
    fileState = "NO_FILE";
  }

  if (contract.originurl?.url) {
    fileState = "FILE_VIA_URL";
  }

  return fileState;
}

const useContractFileState_contractFragment = graphql`
  fragment useContractFileState_contractFragment on ContractType {
    filename
    originurl {
      url
    }
  }
`;
