/**
 * @generated SignedSource<<b72ac1ebdbf7029703b0d47e6cd0daca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PartyDialog_CTPartyfragment$data = {
  readonly contact: {
    readonly uuid: string;
    readonly " $fragmentSpreads": FragmentRefs<"PartyDialog_Contactfragment">;
  } | null | undefined;
  readonly id: string;
  readonly label: string | null | undefined;
  readonly party: {
    readonly companyName: string | null | undefined;
    readonly contacts: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"PartyDialog_Contactfragment">;
    } | null | undefined> | null | undefined;
    readonly id: string;
    readonly uuid: string;
  } | null | undefined;
  readonly uuid: string;
  readonly " $fragmentType": "PartyDialog_CTPartyfragment";
};
export type PartyDialog_CTPartyfragment$key = {
  readonly " $data"?: PartyDialog_CTPartyfragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PartyDialog_CTPartyfragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v2 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "PartyDialog_Contactfragment"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PartyDialog_CTPartyfragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PartyType",
      "kind": "LinkedField",
      "name": "party",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "companyName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ContactType",
          "kind": "LinkedField",
          "name": "contacts",
          "plural": true,
          "selections": [
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ContactType",
      "kind": "LinkedField",
      "name": "contact",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "ContractPartyType",
  "abstractKey": null
};
})();

(node as any).hash = "377a1b519668c9e768bfec60b33576f7";

export default node;
