/**
 * @generated SignedSource<<1c749c07962c1ae6614c596daea47569>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RelatedDocs_viewState_Mutation$variables = {
  uuid: string;
  value?: boolean | null | undefined;
};
export type RelatedDocs_viewState_Mutation$data = {
  readonly updateViewState: {
    readonly contract: {
      readonly detailViewState: {
        readonly expandrelated: boolean | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type RelatedDocs_viewState_Mutation = {
  response: RelatedDocs_viewState_Mutation$data;
  variables: RelatedDocs_viewState_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "uuid"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "value"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "stateboolvalue",
        "variableName": "value"
      },
      {
        "kind": "Literal",
        "name": "statename",
        "value": "expandrelated"
      },
      {
        "kind": "Variable",
        "name": "uuid",
        "variableName": "uuid"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "DetailViewStateType",
  "kind": "LinkedField",
  "name": "detailViewState",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "expandrelated",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RelatedDocs_viewState_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateViewStatePayload",
        "kind": "LinkedField",
        "name": "updateViewState",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractType",
            "kind": "LinkedField",
            "name": "contract",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RelatedDocs_viewState_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateViewStatePayload",
        "kind": "LinkedField",
        "name": "updateViewState",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractType",
            "kind": "LinkedField",
            "name": "contract",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e3fa0a62261b0676ab8a55748939d98b",
    "id": null,
    "metadata": {},
    "name": "RelatedDocs_viewState_Mutation",
    "operationKind": "mutation",
    "text": "mutation RelatedDocs_viewState_Mutation(\n  $uuid: String!\n  $value: Boolean\n) {\n  updateViewState(input: {uuid: $uuid, statename: \"expandrelated\", stateboolvalue: $value}) {\n    contract {\n      detailViewState {\n        expandrelated\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "eb181939a56c6901900dd6f7473568a7";

export default node;
