/**
 * @generated SignedSource<<778f8161eeea7e52f506159b8707290a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TemplateFields_organizationFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"TemplateVariableRegularField_organizationFragment" | "fieldHelpers_organizationInlineFragment">;
  readonly " $fragmentType": "TemplateFields_organizationFragment";
};
export type TemplateFields_organizationFragment$key = {
  readonly " $data"?: TemplateFields_organizationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"TemplateFields_organizationFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TemplateFields_organizationFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TemplateVariableRegularField_organizationFragment"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "fieldHelpers_organizationInlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dateFormat",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TagType",
          "kind": "LinkedField",
          "name": "tags",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "OrgContractTypeType",
          "kind": "LinkedField",
          "name": "orgContractTypes",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "customName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isActive",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CustomFieldType",
          "kind": "LinkedField",
          "name": "customFields",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "fieldType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ValueListItemType",
              "kind": "LinkedField",
              "name": "choices",
              "plural": true,
              "selections": (v2/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "OrganizationType",
  "abstractKey": null
};
})();

(node as any).hash = "78c67afe0c6ccc50bc8451c03a6e9dc2";

export default node;
