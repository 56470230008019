/**
 * @generated SignedSource<<8f061755b22856a90e8cefa081ba2012>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CommentContentInputType = {
  htmlContent: string;
  jsonContent: string;
  textContent: string;
};
export type useEditCommentContent_EditCommentContentMutation$variables = {
  commentUuid: string;
  updatedContent: CommentContentInputType;
};
export type useEditCommentContent_EditCommentContentMutation$data = {
  readonly editCommentContent: {
    readonly __typename: "CommentNotFoundErrorType";
    readonly debugMessage: string | null | undefined;
  } | {
    readonly __typename: "EditCommentContentSuccessResultType";
    readonly commentEdge: {
      readonly node: {
        readonly hasContentEditRevisions: boolean;
        readonly htmlContent: string;
        readonly jsonContent: string;
        readonly textContent: string;
        readonly uuid: string;
      } | null | undefined;
    };
    readonly notificationErrors: ReadonlyArray<{
      readonly __typename: "CommentNotificationAccountUnauthorizedErrorType";
      readonly accountUuid: string;
      readonly debugMessage: string | null | undefined;
    } | {
      readonly __typename: "CommentNotificationUnexpectedErrorType";
      readonly accountUuid: string;
      readonly debugMessage: string | null | undefined;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    }> | null | undefined;
  } | {
    readonly __typename: "EditCommentContentValidationResultType";
    readonly errors: ReadonlyArray<{
      readonly __typename: "CommentMentionAccountUnauthorizedValidationErrorType";
      readonly accountUnauthorizedEmail: string;
      readonly accountUuid: string;
      readonly debugMessage: string | null | undefined;
    } | {
      readonly __typename: "ValidationError";
      readonly debugMessage: string | null | undefined;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    }>;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined;
};
export type useEditCommentContent_EditCommentContentMutation = {
  response: useEditCommentContent_EditCommentContentMutation$data;
  variables: useEditCommentContent_EditCommentContentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "commentUuid"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "updatedContent"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "commentUuid",
        "variableName": "commentUuid"
      },
      {
        "kind": "Variable",
        "name": "updatedContent",
        "variableName": "updatedContent"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasContentEditRevisions",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "htmlContent",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jsonContent",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "textContent",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "debugMessage",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountUuid",
  "storageKey": null
},
v10 = [
  (v8/*: any*/),
  (v9/*: any*/)
],
v11 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "notificationErrors",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": (v10/*: any*/),
      "type": "CommentNotificationAccountUnauthorizedErrorType",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v10/*: any*/),
      "type": "CommentNotificationUnexpectedErrorType",
      "abstractKey": null
    }
  ],
  "storageKey": null
},
v12 = [
  (v8/*: any*/)
],
v13 = {
  "kind": "InlineFragment",
  "selections": (v12/*: any*/),
  "type": "CommentNotFoundErrorType",
  "abstractKey": null
},
v14 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "errors",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            (v8/*: any*/),
            (v9/*: any*/),
            {
              "alias": "accountUnauthorizedEmail",
              "args": null,
              "kind": "ScalarField",
              "name": "mentionedEmail",
              "storageKey": null
            }
          ],
          "type": "CommentMentionAccountUnauthorizedValidationErrorType",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v12/*: any*/),
          "type": "ValidationError",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "EditCommentContentValidationResultType",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useEditCommentContent_EditCommentContentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "editCommentContent",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CommentTypeEdge",
                "kind": "LinkedField",
                "name": "commentEdge",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CommentType",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v11/*: any*/)
            ],
            "type": "EditCommentContentSuccessResultType",
            "abstractKey": null
          },
          (v13/*: any*/),
          (v14/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useEditCommentContent_EditCommentContentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "editCommentContent",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CommentTypeEdge",
                "kind": "LinkedField",
                "name": "commentEdge",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CommentType",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v11/*: any*/)
            ],
            "type": "EditCommentContentSuccessResultType",
            "abstractKey": null
          },
          (v13/*: any*/),
          (v14/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f87732149d184e8b6b997c4a6573aa8e",
    "id": null,
    "metadata": {},
    "name": "useEditCommentContent_EditCommentContentMutation",
    "operationKind": "mutation",
    "text": "mutation useEditCommentContent_EditCommentContentMutation(\n  $commentUuid: String!\n  $updatedContent: CommentContentInputType!\n) {\n  editCommentContent(input: {commentUuid: $commentUuid, updatedContent: $updatedContent}) {\n    __typename\n    ... on EditCommentContentSuccessResultType {\n      commentEdge {\n        node {\n          hasContentEditRevisions\n          uuid\n          htmlContent\n          jsonContent\n          textContent\n          id\n        }\n      }\n      notificationErrors {\n        __typename\n        ... on CommentNotificationAccountUnauthorizedErrorType {\n          debugMessage\n          accountUuid\n        }\n        ... on CommentNotificationUnexpectedErrorType {\n          debugMessage\n          accountUuid\n        }\n      }\n    }\n    ... on CommentNotFoundErrorType {\n      debugMessage\n    }\n    ... on EditCommentContentValidationResultType {\n      errors {\n        __typename\n        ... on CommentMentionAccountUnauthorizedValidationErrorType {\n          debugMessage\n          accountUuid\n          accountUnauthorizedEmail: mentionedEmail\n        }\n        ... on ValidationError {\n          debugMessage\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e47cfdb48a73e24fd754b97b344a1557";

export default node;
