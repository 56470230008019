/**
 * @generated SignedSource<<bd85722ee2d5b877dcda79db1ba72905>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContractPageHeaderActions_organizationFragment$data = {
  readonly dateFormat: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"useContractPageHeaderActionsPermissions_organizationFragment">;
  readonly " $fragmentType": "ContractPageHeaderActions_organizationFragment";
};
export type ContractPageHeaderActions_organizationFragment$key = {
  readonly " $data"?: ContractPageHeaderActions_organizationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContractPageHeaderActions_organizationFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContractPageHeaderActions_organizationFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dateFormat",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useContractPageHeaderActionsPermissions_organizationFragment"
    }
  ],
  "type": "OrganizationType",
  "abstractKey": null
};

(node as any).hash = "dce9d28773e874eafa4b0b22e9491ffb";

export default node;
