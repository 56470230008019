import { z } from "zod";
import type { TemplateVariableElementTypeSchema } from "./templateVariableSchema";
import { graphql, readInlineData } from "relay-runtime";
import { TemplateVariableSchema } from "./templateVariableSchema";
import * as Sentry from "@sentry/react";
import { ZodError } from "zod";
import type { templateVariableHelpers_templateFragment$key } from "./__generated__/templateVariableHelpers_templateFragment.graphql";
import { type FieldInfo } from "~/core/contracts/fieldHelpers";

export type TemplateVariable = ReturnType<
  typeof getTemplateVariables
>["templateVariables"][0];

export const getTemplateVariables = (
  templateFragRef: templateVariableHelpers_templateFragment$key,
) => {
  const template = readInlineData(TEMPLATE_FRAGMENT, templateFragRef);
  const templateVariables = (template.variables ?? []).flatMap(
    (templateVariableData) => {
      if (!templateVariableData) {
        return [];
      }

      try {
        const templateVariableDataWithParsedField = {
          ...templateVariableData,
          field: JSON.parse(templateVariableData.field),
        };
        const templateVariableParseResult = TemplateVariableSchema.parse(
          templateVariableDataWithParsedField,
        );
        return [templateVariableParseResult];
      } catch (exception) {
        Sentry.setContext("Template Variable", { id: templateVariableData.id });
        Sentry.getCurrentScope().setTransactionName(
          "Could not parse template variable.",
        );

        if (exception instanceof ZodError) {
          console.error(
            "Could not parse template variable. exception: ",
            exception.message,
          );
          Sentry.setContext("Zod Error", {
            issues: JSON.stringify(exception.issues),
          });
        } else {
          console.error(
            "Could not parse template variable. exception: ",
            exception,
          );
        }
        Sentry.captureException(exception);

        return [];
      }
    },
  );

  const documentVariables = templateVariables.filter(
    (tv) => getTemplateVariableType(tv) === "document",
  );

  const metadataVariables = templateVariables.filter(
    (tv) => getTemplateVariableType(tv) === "metadata",
  );

  return {
    templateVariables,
    documentVariables,
    metadataVariables,
  };
};

export const getTemplateVariableType = (a: {
  elementType: z.infer<typeof TemplateVariableElementTypeSchema>;
}) => {
  switch (a.elementType) {
    case "header-document":
    case "text-document":
    case "regular-document":
      return "document";
    case "header-metadata":
    case "regular-metadata":
    case "text-metadata":
      return "metadata";
    case "hidden":
      return "hidden";
    default:
      return a.elementType satisfies never;
  }
};

export const isFormField = (a: {
  elementType: z.infer<typeof TemplateVariableElementTypeSchema>;
}) => {
  switch (a.elementType) {
    case "header-document":
    case "text-document":
    case "header-metadata":
    case "text-metadata":
      return false;
    case "regular-metadata":
    case "regular-document":
    case "hidden":
      return true;
    default:
      return a.elementType satisfies never;
  }
};

export const getIsTemplateVariableRequired = (
  templateVariable: TemplateVariable,
  fieldInfo: FieldInfo,
) => {
  if (fieldInfo?.fieldType === "b") {
    // checkboxes can never be required
    return false;
  }

  const isRequired =
    getTemplateVariableType(templateVariable) === "document" ||
    ("required" in templateVariable.field && templateVariable.field.required);
  return isRequired;
};

const TEMPLATE_FRAGMENT = graphql`
  fragment templateVariableHelpers_templateFragment on TemplateType @inline {
    variables {
      id
      field
      elementType
    }
  }
`;
