import React, { Suspense } from "react";
import { useLocation } from "react-router";
import { ErrorBoundary } from "react-error-boundary";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";

export default function ErrorHandler({ suppressFallBack, children }) {
  const location = useLocation();
  return (
    <ErrorBoundary
      FallbackComponent={({ error }) => {
        if (error.message == "Not Logged In") {
          window.location.href = "/login?next=" + location.pathname;
        }

        return (
          <div>
            <Grid container>
              <Grid item xs={12} pt={5} sx={{ color: "error.main" }}>
                <Container maxWidth="sm">{error.message}</Container>
              </Grid>
              <Grid item xs={12} pt={2} pb={10}>
                <Container maxWidth="sm">
                  <Link
                    onClick={() => window.location.reload()}
                    underline="hover"
                    href="#"
                  >
                    Reload
                  </Link>
                </Container>
              </Grid>
            </Grid>
          </div>
        );
      }}
      onError={(err) => console.log("Error:", err, err?.graphqlErrors)}
    >
      {!suppressFallBack ? (
        <>
          <Suspense fallback={<CircularProgress disableShrink />}>
            {children}
          </Suspense>
        </>
      ) : (
        <>{children}</>
      )}
    </ErrorBoundary>
  );
}
