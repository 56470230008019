import React, { useState } from "react";
import { graphql, useFragment } from "react-relay";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import LinearProgress from "@mui/material/LinearProgress";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import DropzoneArea from "~/components/DropzoneArea";
import Button from "~/components/Button";
import acceptedFiles from "~/AcceptedFileTypes";
import { track } from "~/analytics/events";
import { getCsrfToken } from "~/utils/auth";
import { useFeatures } from "~/features";

export default function ReplaceContractDialog({
  account,
  contractUuid,
  open,
  handleClose,
  setPermErrorDialog,
  ...props
}) {
  const { canDelete } = useFragment(
    graphql`
      fragment ReplaceContractDialog_accountFragment on AccountType {
        canDelete
      }
    `,
    account,
  );
  const features = useFeatures();
  const [uploading, setUploading] = useState(false);
  const [prevDocAction, setPrevDocAction] = useState("attach");

  async function handleDrop(files) {
    if (!files?.length) {
      return;
    }

    const fd = new FormData();
    for (const file of files) {
      fd.append("uploadedFile", file);
    }
    if (prevDocAction == "attach") {
      fd.append("movetorelated", true);
    } else if (prevDocAction == "redline") {
      if (!features.redlines) {
        setPermErrorDialog();
        return;
      }

      fd.append("movetorelated", true);
      fd.append("generate_redline", true);
    }

    try {
      setUploading(true);
      const response = await fetch("/api/v1/contract/reparse/" + contractUuid, {
        method: "POST",
        headers: {
          "X-CSRFToken": getCsrfToken(),
        },
        body: fd,
      });

      track("Contract Replaced");
    } catch (e) {
      console.log("error uploading", e);
    }
    setUploading(false);
    handleClose();
    window.location.reload();
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" style={{ textAlign: "center" }}>
        {uploading ? "Uploading" : "Choose the replacement document to upload"}
      </DialogTitle>
      {uploading ? (
        <DialogContent>
          <LinearProgress />
        </DialogContent>
      ) : (
        <>
          <DialogContent dividers>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    What would you like to do with the previous document?
                  </FormLabel>
                  <Box ml={4} mt={2}>
                    <RadioGroup
                      aria-label="action"
                      name="action"
                      value={prevDocAction}
                      onChange={(e) => setPrevDocAction(e.target.value)}
                    >
                      {canDelete ? (
                        <FormControlLabel
                          value="delete"
                          control={<Radio />}
                          label="Delete document"
                        />
                      ) : null}
                      <FormControlLabel
                        value="attach"
                        control={<Radio />}
                        label="Save as Attachment"
                      />
                      <FormControlLabel
                        value="redline"
                        control={<Radio />}
                        label="Save as Attachment and Generate Redline"
                      />
                    </RadioGroup>
                  </Box>
                </FormControl>
              </Grid>
              <Grid item>
                <div>
                  This does not change any of the dates, reminders or other data
                  entered about this document.
                </div>
              </Grid>
              <Grid item>
                <DropzoneArea
                  useChipsForPreview
                  showAlerts={false}
                  onChange={handleDrop}
                  filesLimit={1}
                  dropzoneText="Drag and drop a file here or click to browse."
                  acceptedFiles={acceptedFiles}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button size="small" onClick={handleClose}>
              Cancel
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
