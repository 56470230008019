/**
 * @generated SignedSource<<b0676ef04c2d964edeb53c126682511a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContractPageHeaderActions_contractFragment$data = {
  readonly fileDownloadName: string | null | undefined;
  readonly filename: string;
  readonly id: string;
  readonly originurl: {
    readonly url: string | null | undefined;
  } | null | undefined;
  readonly relatedDocs: ReadonlyArray<{
    readonly internalContract: {
      readonly uuid: string;
    } | null | undefined;
    readonly uuid: string;
  } | null | undefined>;
  readonly title: string | null | undefined;
  readonly uuid: string;
  readonly " $fragmentSpreads": FragmentRefs<"RedlineDialog_fragment" | "SendContractDialog_fragment" | "UpdateContractDialog_fragment" | "UpdateContractFromTemplateDialogContent_contractFragment" | "useContractAttachments_contractFragment" | "useContractFileState_contractFragment" | "useContractPageHeaderActionsPermissions_contractFragment">;
  readonly " $fragmentType": "ContractPageHeaderActions_contractFragment";
};
export type ContractPageHeaderActions_contractFragment$key = {
  readonly " $data"?: ContractPageHeaderActions_contractFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContractPageHeaderActions_contractFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContractPageHeaderActions_contractFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "filename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fileDownloadName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OriginUrlType",
      "kind": "LinkedField",
      "name": "originurl",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RelatedDocType",
      "kind": "LinkedField",
      "name": "relatedDocs",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ContractType",
          "kind": "LinkedField",
          "name": "internalContract",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useContractAttachments_contractFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useContractFileState_contractFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SendContractDialog_fragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RedlineDialog_fragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UpdateContractFromTemplateDialogContent_contractFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UpdateContractDialog_fragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useContractPageHeaderActionsPermissions_contractFragment"
    }
  ],
  "type": "ContractType",
  "abstractKey": null
};
})();

(node as any).hash = "f30ee403bfa6a9968cb90565fa00520e";

export default node;
