import React, { useState, useCallback, useEffect } from "react";
import classNames from "classnames";
import { graphql, useFragment, useLazyLoadQuery } from "react-relay";
import useAsyncMutation from "~/utils/UseAsyncMutation";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Checkbox from "@mui/material/Checkbox";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { InputLabel, TextField, Select } from "~/components/Field";
import Button from "~/components/Button";
import Paper from "~/components/Paper";
import MenuItem from "@mui/material/MenuItem";
import EditableText from "./EditableText";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { styled } from "@mui/material/styles";
import ConfirmDialog from "./ConfirmDialog";
import us_states from "./data/us_states.json";

const WrappingInputLabel = styled(InputLabel)({
  height: "initial !important",
  width: "initial !important",
  whiteSpace: "normal",
  overflowWrap: "anywhere",
  wordBreak: "break-word",
});

const styles = {
  avatar: {
    width: "32px",
    height: "32px",
    float: "left",
    color: "#02162A",
    fontSize: "16px",
    cursor: "pointer",
    fontWeight: "700",
    backgroundColor: "#676B72",
  },
  avatarSelected: {
    backgroundColor: "#0C4EB5",
    color: "#fff",
  },
  link: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "19px",
    color: "#0C4EB5",
  },
  avatarname: {
    fontWeight: "700",
    fontSize: "16px",
    width: "79%",
    marginTop: "5px",
    lineHeight: "21px",
    cursor: "pointer",
    float: "left",
  },
  avatarnameSelected: {
    color: "#0C4EB5",
    backgroundColor: "#fff",
  },
};

const CONTACT_FRAGMENT = graphql`
  fragment PartyDialog_Contactfragment on ContactType {
    uuid
    firstName
    lastName
    title
    phone
    altPhone
    email
    streetName
    streetName2
    cityName
    stateAbbreviation
    zipcode
    province
    country
    isPrimary
    fullname
    initials
  }
`;
const CTPARTY_FRAGMENT = graphql`
  fragment PartyDialog_CTPartyfragment on ContractPartyType {
    id
    uuid
    label
    party {
      id
      uuid
      companyName
      contacts {
        ...PartyDialog_Contactfragment
      }
    }
    contact {
      uuid
      ...PartyDialog_Contactfragment
    }
  }
`;

export default function PartyDialog({
  openFor,
  setOpenFor,
  disabled,
  onCommit,
  contractUuid,
  ...props
}) {
  const ctparty = useFragment(CTPARTY_FRAGMENT, openFor);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const queriedContact = useFragment(
    CONTACT_FRAGMENT,
    ctparty.contact || ctparty.party.contacts[0] || null,
  );

  const [selectedcontact, setSelectedContact] = useState(queriedContact);
  // when a mutation changes the result in queriedContact, reset state to match
  useEffect(() => setSelectedContact(queriedContact), [queriedContact?.uuid]);

  const [commitContact] = useAsyncMutation(graphql`
    mutation PartyDialog_updateContactMutation($contact: ContactInput!) {
      updateContact(input: { contact: $contact }) {
        selectedcontact {
          uuid
          ...PartyDialog_Contactfragment
        }
      }
    }
  `);

  const [commitAddContact] = useAsyncMutation(graphql`
    mutation PartyDialog__createPartyContactMutation($ctpartyuuid: String!) {
      createPartyContact(input: { ctpartyuuid: $ctpartyuuid }) {
        ctparty {
          ...PartyDialog_CTPartyfragment
        }
      }
    }
  `);

  const [commitSetPartyContact] = useAsyncMutation(graphql`
    mutation PartyDialog__setPartyContactMutation(
      $ctpartyuuid: String!
      $contactuuid: String!
    ) {
      setPartyContact(
        input: { ctpartyuuid: $ctpartyuuid, contactuuid: $contactuuid }
      ) {
        ctparty {
          ...PartyDialog_CTPartyfragment
        }
      }
    }
  `);

  const [commitPartyUpdate, isPartyUpdateInFlight] = useAsyncMutation(graphql`
    mutation PartyDialog_UpdatePartyMutation(
      $companyName: String!
      $partyUuid: String!
    ) {
      updateParty(
        input: { party: { uuid: $partyUuid, companyName: $companyName } }
      ) {
        party {
          id
          uuid
          companyName
          contacts {
            ...PartyDialog_Contactfragment
          }
        }
      }
    }
  `);

  async function handleCreateContact(uuid) {
    try {
      await commitAddContact({
        variables: {
          ctpartyuuid: uuid,
        },
      });
      onCommit("Contact Added");
    } catch (e) {
      onCommit(e.message);
    }
  }

  function ContactList({ forcontact, selectedcontact, options, ...props }) {
    const contact = useFragment(CONTACT_FRAGMENT, forcontact);

    return (
      <Box
        display="block"
        style={{
          height: "24px",
          width: "100%",
          marginTop: "2px",
          display: "inline-block",
        }}
        onClick={() => setSelectedContact(contact)}
        mb={2}
      >
        <Grid
          style={{
            float: "left",
            width: "20%",
          }}
        >
          <Avatar
            sx={{
              ...styles.avatar,
              ...(selectedcontact
                ? contact.uuid == selectedcontact.uuid
                  ? styles.avatarSelected
                  : {}
                : {}),
            }}
          >
            {contact.initials}
          </Avatar>
        </Grid>
        <Grid
          sx={{
            ...styles.avatarname,
            ...(selectedcontact
              ? contact.uuid == selectedcontact.uuid
                ? styles.avatarnameSelected
                : {}
              : {}),
          }}
        >
          {contact.fullname}
        </Grid>
      </Box>
    );
  }

  function DeleteContactDialog({
    ctPartyuuid,
    ContactUuid,
    open,
    handleClose,
  }) {
    const [commitDelete] = useAsyncMutation(graphql`
      mutation PartyDialog_removeContactMutation(
        $ctpartyuuid: String!
        $contactUuid: String!
      ) {
        deletePartyContact(
          input: { ctpartyuuid: $ctpartyuuid, contactUuid: $contactUuid }
        ) {
          ctparty {
            ...PartyDialog_CTPartyfragment
          }
        }
      }
    `);

    async function handleConfirm() {
      handleClose();
      try {
        await commitDelete({
          variables: { ctpartyuuid: ctPartyuuid, contactUuid: ContactUuid },
        });
        onCommit("Contact Deleted");
        setSelectedContact(null);
      } catch (e) {
        onCommit(e.message);
      }
    }
    return (
      <ConfirmDialog
        title="Delete Contact?"
        open={open}
        onClose={handleClose}
        onConfirm={handleConfirm}
        noText="Cancel"
        yesText="Delete"
      >
        Are you sure you want to delete this contact? Deleted contacts cannot be
        restored.
      </ConfirmDialog>
    );
  }

  const debouncedCommit = useCallback(
    _.debounce(async (variables) => {
      try {
        await commitContact({ variables });
        onCommit();
      } catch (e) {
        onCommit(e.message);
      }
    }, DEBOUNCE_DELAY),
    [],
  );
  function handleChange(event, field, reason) {
    const newSelectedContact = {
      ...selectedcontact,
      [field]: event.target.value,
    };
    setSelectedContact(newSelectedContact);
    const { fullname, initials, ...contactForMutation } = newSelectedContact;
    contactForMutation.companyName = ctparty.party.companyName;
    debouncedCommit({ contact: contactForMutation });
  }

  function handleClose() {
    setOpenFor(null);
  }

  async function handlePartyUpdate(value) {
    try {
      await commitPartyUpdate({
        variables: { partyUuid: ctparty.party.uuid, companyName: value },
      });
      onCommit();
    } catch (e) {
      onCommit(e.message);
    }
  }

  async function handleSave() {
    handleClose();
    try {
      await commitSetPartyContact({
        variables: {
          ctpartyuuid: ctparty.uuid,
          contactuuid: selectedcontact.uuid,
        },
      });
      onCommit();
    } catch (e) {
      onCommit(e.message);
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      height="983px"
      open={Boolean(openFor)}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        <EditableText
          variant="h4"
          color="#02162A"
          disabled={disabled}
          value={ctparty.party.companyName}
          commit={handlePartyUpdate}
          isInFlight={isPartyUpdateInFlight}
          {...props}
        />
      </DialogTitle>

      <DialogContent dividers>
        <Grid
          item
          mb={2}
          style={{
            height: "auto",
            float: "left",
            width: "19%",
            postion: "absolute",
          }}
        >
          <Box
            display="block"
            style={{
              height: "24px",
              width: "100%",
              display: "inline-block",
            }}
            disabled={disabled}
            onClick={() => handleCreateContact(ctparty.uuid)}
            mb={2}
          >
            <Grid
              style={{
                float: "left",
                width: "20%",
              }}
            >
              <Avatar sx={{ ...styles.avatar, ...styles.avatarSelected }}>
                <Box
                  style={{
                    fontSize: "24px",
                  }}
                >
                  +
                </Box>
              </Avatar>
            </Grid>
            <Grid
              sx={{
                ...styles.avatarname,
                ...styles.avatarnameSelected,
              }}
            >
              Add contact
            </Grid>
          </Box>
          {ctparty.party.contacts?.map((ct, i, a) => (
            <ContactList
              key={i}
              forcontact={ct}
              selectedcontact={selectedcontact}
            />
          ))}
        </Grid>
        <Grid
          item
          mb={6}
          style={{
            float: "left",
            width: "80%",
            height: "auto",
          }}
        >
          <Box width={"100%"} mb={2}>
            <Typography variant="h5">Name</Typography>
          </Box>
          <Grid container spacing={8}>
            {[
              ["First Name", "firstName"],
              ["Last Name", "lastName"],
              ["Title", "title"],
            ].map(([label, field]) => (
              <Grid item key={field} sm={4} xs={6}>
                <TextField
                  label={label}
                  value={selectedcontact ? selectedcontact[field] || "" : " "}
                  onChange={(e) => handleChange(e, field)}
                  disabled={!selectedcontact || disabled}
                />
              </Grid>
            ))}
          </Grid>
          <Box width={"100%"} mb={2} mt={4}>
            <Typography variant="h5">Contact Info</Typography>
          </Box>
          <Grid container spacing={8}>
            {[
              ["Phone", "phone"],
              ["Alt Phone", "altPhone"],
              ["Email", "email"],
            ].map(([label, field]) => (
              <Grid item key={field} sm={4} xs={6}>
                <TextField
                  label={label}
                  value={selectedcontact ? selectedcontact[field] || "" : ""}
                  onChange={(e) => handleChange(e, field)}
                  disabled={!selectedcontact || disabled}
                />
              </Grid>
            ))}
          </Grid>
          <Box width={"100%"} mb={2} mt={4}>
            <Typography variant="h5">Address</Typography>
          </Box>
          <Grid container spacing={8}>
            {[
              ["Street 1", "streetName", 4, , 4, 6],
              ["Street 2", "streetName2", 4, 4, 6],
              ["City", "cityName", 4, 4, 6],
              ["State", "stateAbbreviation", 2, 3, 4],
              ["Zip", "zipcode", 2, 3, 3],
              ["International State/Province", "province", 4, 6, 6],
              ["Country", "country", 4, 6, 6],
            ].map(([label, field, md, sm, xs]) => (
              <Grid item key={field} md={md} sm={sm} xs={xs}>
                {field == "stateAbbreviation" ? (
                  <Select
                    label="U.S. State"
                    key="stateAbbreviation"
                    labelComponent={WrappingInputLabel}
                    value={selectedcontact?.stateAbbreviation || ""}
                    onChange={(e) => handleChange(e, "stateAbbreviation")}
                    disabled={!selectedcontact || disabled}
                  >
                    {us_states.map((st, i, a) => (
                      <MenuItem key={st.abbreviation} value={st.abbreviation}>
                        {st.name}
                      </MenuItem>
                    ))}
                  </Select>
                ) : (
                  <TextField
                    label={label}
                    value={selectedcontact ? selectedcontact[field] || "" : ""}
                    onChange={(e) => handleChange(e, field)}
                    disabled={!selectedcontact || disabled}
                  />
                )}
              </Grid>
            ))}
          </Grid>
          <Box width={"100%"} mt={10}>
            <Button
              variant="text"
              size="small"
              style={{
                float: "right",
                fontSize: "14px",
              }}
              onClick={() => setDeleteOpen(true)}
              disabled={!selectedcontact || disabled}
            >
              <DeleteForeverOutlinedIcon />
              Delete Contact
            </Button>
          </Box>
        </Grid>
        <DeleteContactDialog
          ctPartyuuid={ctparty.uuid}
          ContactUuid={selectedcontact ? selectedcontact.uuid : null}
          open={deleteOpen}
          handleClose={() => setDeleteOpen(false)}
        />
      </DialogContent>
      <DialogActions>
        <Button size="small" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          size="small"
          disabled={!selectedcontact || disabled}
          onClick={handleSave}
          primary
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
