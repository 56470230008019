/**
 * @generated SignedSource<<41f00b8f69f8d227c9f9ce942ccf339a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContractExecutionStatusChip_contractFragment$data = {
  readonly id: string;
  readonly legal: {
    readonly executionStatus: string;
    readonly id: string;
  } | null | undefined;
  readonly uuid: string;
  readonly " $fragmentType": "ContractExecutionStatusChip_contractFragment";
};
export type ContractExecutionStatusChip_contractFragment$key = {
  readonly " $data"?: ContractExecutionStatusChip_contractFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContractExecutionStatusChip_contractFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContractExecutionStatusChip_contractFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uuid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LegalType",
      "kind": "LinkedField",
      "name": "legal",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "executionStatus",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ContractType",
  "abstractKey": null
};
})();

(node as any).hash = "473dc84371f59d191d21fe5c31515c2c";

export default node;
