import Box from "@mui/material/Box";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import React from "react";

type UpdateContractFromTemplateDialogHeaderProps = {
  mode: "CREATE_FILE" | "REPLACE_FILE";
  endComponent?: React.ReactNode;
};

export function UpdateContractFromTemplateDialogHeader(
  props: UpdateContractFromTemplateDialogHeaderProps,
) {
  return (
    <>
      <DialogTitle
        variant="h4"
        sx={{ paddingX: 8, paddingTop: 8, paddingBottom: 4 }}
      >
        <Stack direction="row" justifyContent="space-between">
          {props.mode === "CREATE_FILE"
            ? "Add Contract File"
            : "Replace Contract File"}
          {props.endComponent}
        </Stack>
      </DialogTitle>

      <Box sx={{ paddingX: 8, paddingBottom: 4 }}>
        {props.mode === "CREATE_FILE" ? (
          <>
            Create a new contract file from one of your available templates.
            Fill out any remaining fields and then preview your document.
          </>
        ) : (
          <>
            Replace your contract file from one of your available templates.
            Fill out any remaining fields and then preview your document.
          </>
        )}
      </Box>
      <Divider />
    </>
  );
}
