/**
 * @generated SignedSource<<3efdf2cd84a9c497045f5c5a93bac038>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserComment_currentUserAccountFragment$data = {
  readonly uuid: string;
  readonly " $fragmentSpreads": FragmentRefs<"UserCommentActionsMenu_currentUserAccountFragment" | "UserCommentContent_currentUserAccountFragment">;
  readonly " $fragmentType": "UserComment_currentUserAccountFragment";
};
export type UserComment_currentUserAccountFragment$key = {
  readonly " $data"?: UserComment_currentUserAccountFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserComment_currentUserAccountFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserComment_currentUserAccountFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uuid",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserCommentActionsMenu_currentUserAccountFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserCommentContent_currentUserAccountFragment"
    }
  ],
  "type": "AccountType",
  "abstractKey": null
};

(node as any).hash = "ef932f1c2f596612420bd2e5ed87ef2e";

export default node;
