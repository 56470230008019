import React from "react";
import SvgIcon, { type SvgIconProps } from "@mui/material/SvgIcon";

export function MenuIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M8 12C8 11.1716 8.67157 10.5 9.5 10.5C10.3284 10.5 11 11.1716 11 12C11 12.8284 10.3284 13.5 9.5 13.5C8.67157 13.5 8 12.8284 8 12Z"
        fill="#111213"
        fillOpacity="0.5"
      />
      <path
        d="M14 12C14 11.1716 14.6716 10.5 15.5 10.5C16.3284 10.5 17 11.1716 17 12C17 12.8284 16.3284 13.5 15.5 13.5C14.6716 13.5 14 12.8284 14 12Z"
        fill="#111213"
        fillOpacity="0.5"
      />
    </SvgIcon>
  );
}
