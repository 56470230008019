import React from "react";
import Button from "~/components/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useFragment } from "react-relay";
import { getHasTerminationDatePassed } from "../utils/terminationDate";

const TERMINATION_DATE_AND_REMINDER_CONFIRMATION_DIALOG_ORGANIZATION_FRAGMENT = graphql`
  fragment TerminationDateAndReminderConfirmationDialog_OrganizationFragment on OrganizationType {
    tzName
    dateFormat
  }
`;

export function TerminationDateAndReminderConfirmationDialog({
  selectedDate,
  open,
  isContractActive,
  onConfirmation,
  onCancel,
  ...props
}) {
  const organization = useFragment(
    TERMINATION_DATE_AND_REMINDER_CONFIRMATION_DIALOG_ORGANIZATION_FRAGMENT,
    props.organization,
  );

  const hasTerminationDatePassed = selectedDate
    ? getHasTerminationDatePassed(selectedDate.date, organization.tzName)
    : false;

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      maxWidth="lg"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-testid="terminationDateDialog"
    >
      <DialogTitle id="alert-dialog-title">Please Confirm</DialogTitle>
      <DialogContent id="alert-dialog-description">
        {hasTerminationDatePassed && (
          <DialogContentText sx={{ my: 2 }}>
            You've selected a termination date in the past. This will disable
            Autorenew if it is currently enabled. This will also remove any
            reminders associated with the termination date.
          </DialogContentText>
        )}
        <DialogContentText sx={{ my: 2 }}>
          {isContractActive ? (
            <>
              This contract is currently active. Please select whether you would
              like to change this contract to inactive.
            </>
          ) : (
            <>
              This contract is currently inactive. Please select whether you
              would like to change this contract to inactive.
            </>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button size="small" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          size="small"
          primary
          variant={"contained"}
          onClick={() => {
            onConfirmation(isContractActive);
          }}
        >
          Change termination date and keep contract{" "}
          {isContractActive ? "active" : "inactive"}
        </Button>
        <Button
          size="small"
          primary
          variant={"contained"}
          onClick={() => {
            onConfirmation(!isContractActive);
          }}
        >
          Change termination date and set contract to{" "}
          {isContractActive ? "inactive" : "active"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
