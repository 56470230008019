import React, { useState } from "react";
import Measure from "react-measure";
import Tooltip from "@mui/material/Tooltip";

export default function TruncateWithTooltip({
  component,
  children,
  sx,
  ...props
}) {
  const Component = component; // Capital letter matters for styling, somehow!
  const [title, setTitle] = useState("");

  return (
    <Measure
      scroll
      offset
      onResize={(contentRect) => {
        if (
          contentRect.offset.width < contentRect.scroll.width ||
          contentRect.offset.height < contentRect.scroll.height
        ) {
          const childrenToString = (x) =>
            typeof x === "string"
              ? x
              : x?.props?.children
                ? React.Children.map(x.props.children, childrenToString).join(
                    "",
                  )
                : "";

          setTitle(childrenToString({ props: { children } }));
        } else {
          setTitle("");
        }
      }}
    >
      {({ measureRef }) => (
        <Tooltip title={title} enterDelay={500} enterNextDelay={500}>
          <Component
            sx={{
              height: "initial !important",
              width: "initial !important",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              ...sx,
            }}
            ref={measureRef}
            {...props}
          >
            {children}
          </Component>
        </Tooltip>
      )}
    </Measure>
  );
}
