import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "~/components/Button";
import LinearProgress from "@mui/material/LinearProgress";

export function YesNoDialog(props) {
  const {
    open,
    onYes,
    onNo,
    onClose,
    title,
    yesText,
    noText,
    children,
    loading,
  } = props;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
      fullWidth={true}
    >
      {loading ? (
        <DialogContent>
          <LinearProgress />
        </DialogContent>
      ) : (
        <>
          {title ? (
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
          ) : null}
          {children ? (
            <DialogContent>
              <DialogContentText id="alert-dialog-description" sx={{ my: 2 }}>
                {children}
              </DialogContentText>
            </DialogContent>
          ) : null}
          <DialogActions>
            <Button size="small" onClick={onNo}>
              {noText || "No"}
            </Button>
            <Button
              size="small"
              primary
              variant={"contained"}
              autoFocus
              onClick={onYes}
            >
              {yesText || "Yes"}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}

export default function ConfirmDialog(props) {
  const {
    open,
    onConfirm,
    onClose,
    title,
    yesText,
    noText,
    children,
    DialogProps,
  } = props;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...DialogProps}
    >
      {title ? (
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      ) : null}
      {children ? (
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{ my: 2 }}>
            {children}
          </DialogContentText>
        </DialogContent>
      ) : null}
      <DialogActions>
        <Button
          size="small"
          onClick={onClose}
          data-testid="confirm-dialog-no-button"
        >
          {noText || "No"}
        </Button>
        <Button
          size="small"
          primary
          variant={"contained"}
          autoFocus
          onClick={onConfirm}
          data-testid="confirm-dialog-yes-button"
        >
          {yesText || "Yes"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
