import React, { useState, useCallback } from "react";
import { graphql, useFragment, useLazyLoadQuery } from "react-relay";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import Checkbox from "@mui/material/Checkbox";
import { InputLabel, TextField } from "~/components/Field";
import Button from "~/components/Button";
import { styled } from "@mui/material/styles";
import RelatedDocs_fragment from "./RelatedDocs";
import SearchIcon from "@mui/icons-material/Search";
import CircularProgress from "@mui/material/CircularProgress";
import { track } from "~/analytics/events";
const WrappingInputLabel = styled(InputLabel)({
  height: "initial !important",
  width: "initial !important",
  whiteSpace: "normal",
  overflowWrap: "anywhere",
  wordBreak: "break-word",
});
const GroupInputLabel = styled(WrappingInputLabel)({
  fontWeight: "normal",
});

function ContractSearch({
  refetch,
  queryArgs,
  loadalldocs,
  handleChange,
  amendedToUuid,
  dateFormat,
}) {
  const { relatedcontracts } = useLazyLoadQuery(
    graphql`
      query AmendedToDialog_Query(
        $contractUUID: String!
        $SearchParams: String!
        $LoadAll: Boolean!
      ) {
        relatedcontracts(
          ctUuid: $contractUUID
          searchParams: $SearchParams
          loadall: $LoadAll
        ) {
          id
          uuid
          title
          counterparty
          effectiveDate {
            date
          }
        }
      }
    `,
    queryArgs.variables,
    queryArgs.options,
    {
      notifyOnNetworkStatusChange: true,
    },
  );

  return (
    <>
      {relatedcontracts?.length == 0 ? (
        <p style={{ textAlign: "center", marginTop: "14px" }}>
          No documents found.
        </p>
      ) : (
        relatedcontracts?.map((ct, i) => (
          <Grid item key={i} xs={12}>
            <FormControlLabel
              key={ct?.id}
              control={
                <Radio
                  disableRipple
                  id={"amend-result-" + ct?.id}
                  checked={ct?.uuid == amendedToUuid}
                  onChange={() => handleChange(ct?.uuid)}
                  size="small"
                />
              }
              label={
                <GroupInputLabel>
                  {ct?.title}
                  {ct.counterparty ? " - ".concat(ct.counterparty) : null}
                  {ct.effectiveDate?.date
                    ? " - ".concat(
                        format_date_string(ct.effectiveDate.date, dateFormat),
                      )
                    : ""}
                </GroupInputLabel>
              }
            />
          </Grid>
        ))
      )}
      {!queryArgs.variables.LoadAll ? (
        <Grid item key="kall" xs={12} style={{ textAlign: "center" }}>
          <Button size="small" primary onClick={loadalldocs}>
            Show All
          </Button>
        </Grid>
      ) : (
        ""
      )}
    </>
  );
}

export default function AmendedToDialog({
  open,
  setOpen,
  contractUuid,
  dateFormat,
  updateAmendedTo,
  onCommit,
  ...props
}) {
  const [searchparams, setsearchParams] = useState(props.searchParam);
  const [amendedToUuid, setAmendedToUuid] = useState(props.amendedtoUuid);
  const [amendedToNamed, setAmendedToNamed] = useState(props.amendedToNamed);
  const [loadAll, setloadAll] = useState(false);
  const [isAmendedToNamed, setIsAmendedToNamed] = useState(
    !!amendedToNamed?.length,
  );

  const [queryArgs, setQueryArgs] = useState({
    options: { fetchKey: 0 },
    variables: {
      contractUUID: contractUuid,
      SearchParams: props.searchParam ?? "",
      LoadAll: false,
    },
  });

  const refetch = useCallback(
    () => {
      // Trigger a re-render of useLazyLoadQuery with new variables,
      // *and* an updated fetchKey.
      // The new fetchKey will ensure that the query is fully
      // re-evaluated and refetched.
      setQueryArgs((prev) => ({
        options: {
          fetchKey: (prev?.fetchKey ?? 0) + 1,
        },
        variables: {
          contractUUID: contractUuid,
          SearchParams: String(searchparams),
          LoadAll: Boolean(loadAll),
        },
      }));
    },
    [
      /* ... */
    ],
  );

  async function handleSave() {
    handleClose();
    if (props.handleSave) {
      props.handleSave(amendedToUuid, amendedToNamed);
    } else {
      try {
        await updateAmendedTo({
          contractUuid,
          agreementAmendment: true,
          amendedToUuid: amendedToUuid,
          amendedToNamed: amendedToNamed,
        });
        track("Contract Amendment To Set");

        onCommit();
      } catch (e) {
        onCommit(e.message);
      }
    }
  }

  function handleClose() {
    setOpen(false);
  }

  function searchRelated(getall) {
    setQueryArgs((prev) => ({
      options: {
        fetchKey: (prev?.fetchKey ?? 0) + 1,
      },
      variables: {
        contractUUID: contractUuid,
        SearchParams: String(searchparams),
        LoadAll: getall || false,
      },
    }));
  }

  function load_all_docs() {
    setsearchParams("");
    setloadAll(true);
    searchRelated(true);
  }
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      height="983px"
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Amendment to</DialogTitle>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={3}
        style={{ paddingLeft: 14 }}
      >
        <Grid item xs={10}>
          <TextField
            name="searchtext"
            id="searchtext"
            value={searchparams || ""}
            onChange={({ target: { value } }) => setsearchParams(value)}
            onKeyDown={(e) => {
              if (e.keyCode == 13) {
                searchRelated();
              }
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="text"
            size="medium"
            label={<SearchIcon />}
            onClick={() => searchRelated()}
          />
        </Grid>
      </Grid>
      <DialogContent style={{ height: "683px" }}>
        <Grid container direction="column" justifyContent="flex-start">
          <FormControlLabel
            control={
              <Checkbox
                checked={isAmendedToNamed}
                onChange={(e) => {
                  if (!e.target.checked) {
                    setAmendedToNamed(null);
                  }
                  setIsAmendedToNamed(e.target.checked);
                }}
                name="Amended to named"
              />
            }
            label="Document not in ContractSafe"
          />
          {isAmendedToNamed ? (
            <TextField
              style={{
                width: "90%",
                marginLeft: "10px",
                display: "inline-block",
              }}
              name="amended to name"
              id="idx_named"
              value={amendedToNamed}
              onChange={(e) => setAmendedToNamed(e.target.value)}
              label="Amended to"
            />
          ) : (
            <React.Suspense
              fallback={
                <Box style={{ textAlign: "center", width: "100%" }}>
                  <CircularProgress style={{ marginTop: "20px" }} />
                </Box>
              }
            >
              <ContractSearch
                refetch={refetch}
                queryArgs={queryArgs}
                loadalldocs={load_all_docs}
                handleChange={setAmendedToUuid}
                amendedToUuid={amendedToUuid}
                dateFormat={dateFormat}
              />
            </React.Suspense>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button size="small" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          size="small"
          disabled={
            (isAmendedToNamed && !amendedToNamed?.length) ||
            (!isAmendedToNamed && !amendedToUuid)
          }
          onClick={handleSave}
          primary
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
