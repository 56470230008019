/**
 * @generated SignedSource<<c401d567eb5315fda1a7fd111083db1f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Parties_updateContractPartyMutation$variables = {
  companyName: string;
  contractUuid: string;
  label: string;
  partyUuid: string;
};
export type Parties_updateContractPartyMutation$data = {
  readonly updateContractParty: {
    readonly contract: {
      readonly counterparties: ReadonlyArray<{
        readonly party: {
          readonly companyName: string | null | undefined;
          readonly uuid: string;
        } | null | undefined;
        readonly uuid: string;
        readonly " $fragmentSpreads": FragmentRefs<"PartyDialog_CTPartyfragment">;
      } | null | undefined>;
      readonly currentFormMissingRequiredFields: boolean | null | undefined;
      readonly mycompanies: ReadonlyArray<{
        readonly label: string | null | undefined;
        readonly party: {
          readonly companyName: string | null | undefined;
          readonly uuid: string;
        } | null | undefined;
        readonly uuid: string;
        readonly " $fragmentSpreads": FragmentRefs<"PartyDialog_CTPartyfragment">;
      } | null | undefined>;
      readonly uuid: string;
    } | null | undefined;
  } | null | undefined;
};
export type Parties_updateContractPartyMutation = {
  response: Parties_updateContractPartyMutation$data;
  variables: Parties_updateContractPartyMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyName"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contractUuid"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "label"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "partyUuid"
},
v4 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "contractuuid",
            "variableName": "contractUuid"
          },
          {
            "kind": "Variable",
            "name": "label",
            "variableName": "label"
          },
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "companyName",
                "variableName": "companyName"
              }
            ],
            "kind": "ObjectValue",
            "name": "party"
          },
          {
            "kind": "Variable",
            "name": "uuid",
            "variableName": "partyUuid"
          }
        ],
        "kind": "ObjectValue",
        "name": "contractparty"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currentFormMissingRequiredFields",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "PartyType",
  "kind": "LinkedField",
  "name": "party",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    (v7/*: any*/)
  ],
  "storageKey": null
},
v9 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "PartyDialog_CTPartyfragment"
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v12 = [
  (v5/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "firstName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "lastName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "title",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "phone",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "altPhone",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "email",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "streetName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "streetName2",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "cityName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "stateAbbreviation",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "zipcode",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "province",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "country",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isPrimary",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "fullname",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "initials",
    "storageKey": null
  },
  (v11/*: any*/)
],
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "PartyType",
  "kind": "LinkedField",
  "name": "party",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    (v7/*: any*/),
    (v11/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ContactType",
      "kind": "LinkedField",
      "name": "contacts",
      "plural": true,
      "selections": (v12/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "ContactType",
  "kind": "LinkedField",
  "name": "contact",
  "plural": false,
  "selections": (v12/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "Parties_updateContractPartyMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "UpdateContractPartyPayload",
        "kind": "LinkedField",
        "name": "updateContractParty",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractType",
            "kind": "LinkedField",
            "name": "contract",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ContractPartyType",
                "kind": "LinkedField",
                "name": "counterparties",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ContractPartyType",
                "kind": "LinkedField",
                "name": "mycompanies",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v10/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "Parties_updateContractPartyMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "UpdateContractPartyPayload",
        "kind": "LinkedField",
        "name": "updateContractParty",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractType",
            "kind": "LinkedField",
            "name": "contract",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ContractPartyType",
                "kind": "LinkedField",
                "name": "counterparties",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v13/*: any*/),
                  (v11/*: any*/),
                  (v10/*: any*/),
                  (v14/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ContractPartyType",
                "kind": "LinkedField",
                "name": "mycompanies",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v10/*: any*/),
                  (v13/*: any*/),
                  (v11/*: any*/),
                  (v14/*: any*/)
                ],
                "storageKey": null
              },
              (v11/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "10adfb825a3385c67ab75adb9791b456",
    "id": null,
    "metadata": {},
    "name": "Parties_updateContractPartyMutation",
    "operationKind": "mutation",
    "text": "mutation Parties_updateContractPartyMutation(\n  $label: String!\n  $companyName: String!\n  $contractUuid: String!\n  $partyUuid: String!\n) {\n  updateContractParty(input: {contractparty: {uuid: $partyUuid, contractuuid: $contractUuid, label: $label, party: {companyName: $companyName}}}) {\n    contract {\n      uuid\n      currentFormMissingRequiredFields\n      counterparties {\n        uuid\n        party {\n          uuid\n          companyName\n          id\n        }\n        ...PartyDialog_CTPartyfragment\n        id\n      }\n      mycompanies {\n        uuid\n        label\n        party {\n          uuid\n          companyName\n          id\n        }\n        ...PartyDialog_CTPartyfragment\n        id\n      }\n      id\n    }\n  }\n}\n\nfragment PartyDialog_CTPartyfragment on ContractPartyType {\n  id\n  uuid\n  label\n  party {\n    id\n    uuid\n    companyName\n    contacts {\n      ...PartyDialog_Contactfragment\n      id\n    }\n  }\n  contact {\n    uuid\n    ...PartyDialog_Contactfragment\n    id\n  }\n}\n\nfragment PartyDialog_Contactfragment on ContactType {\n  uuid\n  firstName\n  lastName\n  title\n  phone\n  altPhone\n  email\n  streetName\n  streetName2\n  cityName\n  stateAbbreviation\n  zipcode\n  province\n  country\n  isPrimary\n  fullname\n  initials\n}\n"
  }
};
})();

(node as any).hash = "752d3eacd1525c829e824abe33a039df";

export default node;
