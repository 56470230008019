import Avatar from "@mui/material/Avatar";
import React from "react";
import type { UserAvatarColor } from "./avatarColors";
import type { SxProps, Theme } from "@mui/material/styles";
import Skeleton from "@mui/material/Skeleton";

type CommentAvatarProps = {
  color: {
    backgroundColor: string;
    textColor: string;
  };
  children: React.ReactNode;
  sx?: SxProps<Theme>;
};

export function CommentAvatar(props: CommentAvatarProps) {
  return (
    <Avatar
      sx={[
        {
          width: "44px",
          height: "44px",
          aspectRatio: "1 / 1",
          fontWeight: "500",
          color: props.color.textColor,
          backgroundColor: props.color.backgroundColor,
        },
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      ]}
    >
      {props.children}
    </Avatar>
  );
}

export function CommentAvatarSkeleton() {
  return (
    <Skeleton variant="circular" width={44} height={44} animation="wave" />
  );
}
