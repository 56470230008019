import React from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  useMediaQuery,
  useTheme,
  type SxProps,
  type Theme,
} from "@mui/material";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import { CONTRACT_PAGE_ACTIONS } from "./actions";
import { Link, useLocation } from "react-router";

type ContractPageDrawerNavigationProps = {
  sx?: SxProps<Theme>;
};

export function ContractPageDrawerNavigation(
  props: ContractPageDrawerNavigationProps,
) {
  const theme = useTheme();
  const location = useLocation();
  const currentPath = location.pathname.split("/").pop() || "properties";
  const defaultIsOpen = useMediaQuery(theme.breakpoints.up("md"));

  const [open, setOpen] = React.useState(defaultIsOpen);

  const handleDrawerToggle = () => {
    setOpen((value) => !value);
  };

  return (
    <Drawer
      variant="permanent"
      open={open}
      sx={[
        {
          "& .MuiDrawer-paper": {
            position: "relative",
            zIndex: 0,
          },
        },
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      ]}
    >
      <List>
        <ListItem
          key="toggle"
          disablePadding
          sx={{ display: "block" }}
          onClick={handleDrawerToggle}
        >
          <ListItemButton
            sx={[
              {
                minHeight: 48,
                px: 2.5,
                justifyContent: "flex-end",
              },
            ]}
          >
            <ListItemIcon
              sx={[
                {
                  minWidth: 0,
                },
              ]}
            >
              {open ? (
                <ArrowBackIosOutlinedIcon />
              ) : (
                <ArrowForwardIosOutlinedIcon />
              )}
            </ListItemIcon>
          </ListItemButton>
        </ListItem>

        {CONTRACT_PAGE_ACTIONS.map((action) => (
          <ListItem key={action.name} disablePadding sx={{ display: "block" }}>
            <Tooltip title={!open ? action.name : ""} placement="right" arrow>
              <ListItemButton
                component={Link}
                to={action.routePath}
                selected={currentPath === action.routePath}
                sx={[
                  {
                    minHeight: 48,
                    paddingX: 6,
                    "&.Mui-selected": {
                      "& .MuiListItemIcon-root": {
                        color: "primary.main",
                      },
                      "& .MuiListItemText-root": {
                        "& .MuiTypography-root": {
                          color: "primary.main",
                        },
                      },
                    },
                  },
                ]}
              >
                <ListItemIcon
                  sx={[
                    {
                      color: "black",
                      minWidth: 0,
                      justifyContent: "center",
                    },
                  ]}
                >
                  {action.icon}
                </ListItemIcon>
                <ListItemText
                  primary={action.name}
                  sx={[
                    open
                      ? {
                          display: "block",
                          marginLeft: 3,
                        }
                      : {
                          display: "none",
                        },
                  ]}
                  primaryTypographyProps={{
                    sx: {
                      color: "black",
                      fontWeight: 500,
                    },
                  }}
                />
              </ListItemButton>
            </Tooltip>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
}
