import { Stack } from "@mui/material";
import React, { Suspense } from "react";
import { graphql, loadQuery, usePreloadedQuery } from "react-relay";
import { useLoaderData } from "react-router";
import environment from "~/RelayEnvironment";

export async function clientLoader(args: any) {
  const queryRef = loadQuery(environment, approve_Query, {
    uuid: args.params.uuid,
  });
  return { queryRef };
}

export function Approve(props: any) {
  const { queryRef } = useLoaderData<typeof clientLoader>();
  const queryData: any = usePreloadedQuery(approve_Query, queryRef);

  return (
    <Stack>
      <div>Approve</div>
    </Stack>
  );
}

export function ApproveWithFallback() {
  return (
    <Suspense fallback={<div>Loading</div>}>
      <Approve />
    </Suspense>
  );
}

const approve_Query = graphql`
  query approve_Query($uuid: String!) {
    contract(uuid: $uuid) {
      uuid
    }
  }
`;
