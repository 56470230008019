import React from "react";
import {
  graphql,
  useFragment,
  usePreloadedQuery,
  type PreloadedQuery,
} from "react-relay";
import { ErrorBoundary } from "@sentry/react";
import type { UserCommentRevisionsDialog_commentQuery } from "./__generated__/UserCommentRevisionsDialog_commentQuery.graphql";
import type { UserCommentRevisionsDialog_commentFragment$key } from "./__generated__/UserCommentRevisionsDialog_commentFragment.graphql";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Comment, CommentSkeleton } from "../Comment/Comment";
import { CommentAvatar, CommentAvatarSkeleton } from "../Comment/CommentAvatar";
import { CommentContent } from "../Comment/CommentContent";
import { CommentHeader } from "../Comment/CommentHeader";
import { CURRENT_USER_AVATAR_COLOR } from "../Comment/avatarColors";
import { CommentTextEditorReadOnly } from "../CommentTextEditor/CommentTextEditor";
import type { UserCommentRevisionsDialog_contentEditRevisionFragment$data } from "./__generated__/UserCommentRevisionsDialog_contentEditRevisionFragment.graphql";
import { CommentSecondaryHeader } from "../Comment/CommentSecondaryHeader";
import format from "date-fns/format";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import formatDistanceToNow from "date-fns/formatDistanceToNow";

export const USER_COMMENT_REVISIONS_DIALOG_COMMENT_QUERY = graphql`
  query UserCommentRevisionsDialog_commentQuery($commentUuid: String!) {
    comment(uuid: $commentUuid) {
      ...UserCommentRevisionsDialog_commentFragment
    }
  }
`;

const COMMENT_FRAGMENT = graphql`
  fragment UserCommentRevisionsDialog_commentFragment on CommentResultType {
    __typename
    ... on CommentType {
      author @required(action: THROW) {
        email
        uuid
      }
      contentEditRevisions {
        ...UserCommentRevisionsDialog_contentEditRevisionFragment
      }
      dateCreated
    }
    ... on ResultError {
      debugMessage
    }
  }
`;

const CONTENT_EDIT_REVISION_FRAGMENT = graphql`
  fragment UserCommentRevisionsDialog_contentEditRevisionFragment on CommentContentEditRevisionType {
    actor @required(action: THROW) {
      email
      uuid
    }
    dateCreated
    updatedHtmlContent @required(action: THROW)
    uuid
  }
`;

export type LoadedUserCommentRevisionsDialogProps = {
  initialActiveRevisionUuid?: string;
  queryRef: PreloadedQuery<UserCommentRevisionsDialog_commentQuery>;
};

export function LoadedUserCommentRevisionsDialog(
  props: LoadedUserCommentRevisionsDialogProps,
) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [activeRevisionUuid, setActiveRevisionUuid] = React.useState<
    string | undefined
  >(props.initialActiveRevisionUuid);
  const data = usePreloadedQuery(
    USER_COMMENT_REVISIONS_DIALOG_COMMENT_QUERY,
    props.queryRef,
  );
  const comment = useFragment<UserCommentRevisionsDialog_commentFragment$key>(
    COMMENT_FRAGMENT,
    data.comment,
  );
  const handleRevisionsMenuButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handleRevisionsMenuClose = () => {
    setAnchorEl(null);
  };

  const handleRevisionsMenuItemClick = (revisionUuid: string) => {
    setActiveRevisionUuid(revisionUuid);
    setAnchorEl(null);
  };

  if (
    !comment ||
    comment.__typename !== "CommentType" ||
    !comment.contentEditRevisions
  ) {
    throw new Error(
      "Unexpected data for USER_COMMENT_REVISIONS_DIALOG_COMMENT_QUERY",
    );
  }

  const contentEditRevisions = (comment.contentEditRevisions ?? []).flatMap(
    (revisionFragRef) => {
      const revision = useFragment(
        CONTENT_EDIT_REVISION_FRAGMENT,
        revisionFragRef,
      ) as UserCommentRevisionsDialog_contentEditRevisionFragment$data;
      return [revision];
    },
  );
  const activeRevision = contentEditRevisions.find((revision) => {
    return revision.uuid == activeRevisionUuid;
  });

  if (!activeRevision) {
    throw new Error("No active comment content edit revision found.");
  }

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <DialogTitle>Comment Edit History</DialogTitle>
        <Button onClick={handleRevisionsMenuButtonClick}>Edits</Button>
        <Menu
          anchorEl={anchorEl}
          open={open}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          onClose={handleRevisionsMenuClose}
        >
          {contentEditRevisions.map((revision) => {
            const actorEmail = revision.actor.email;
            return (
              <MenuItem
                key={revision.uuid}
                sx={{ py: 2, px: 4, width: "100%" }}
                onClick={() => handleRevisionsMenuItemClick(revision.uuid)}
              >
                <CommentAvatar
                  color={CURRENT_USER_AVATAR_COLOR}
                  sx={{ width: 24, height: 24, fontSize: "1rem", p: "4px" }}
                >
                  {actorEmail[0].toUpperCase()}
                </CommentAvatar>
                <Typography
                  sx={{ fontWeight: 600, ml: 2, minWidth: 0 }}
                  noWrap
                  component="span"
                >
                  {actorEmail}
                </Typography>
                <Typography sx={{ fontWeight: 400, ml: 2 }} component="span">
                  edited {formatDistanceToNow(new Date(revision.dateCreated))}{" "}
                  ago
                </Typography>
              </MenuItem>
            );
          })}
        </Menu>
      </Stack>
      <DialogContent sx={{ minHeight: 270 }}>
        <Comment
          data-testid="user-comment-revisions-dialog-comment"
          isFocused={false}
          showCommentConnectorLine={false}
        >
          <CommentAvatar
            color={CURRENT_USER_AVATAR_COLOR}
            sx={{ width: 44, height: 44 }}
          >
            {comment.author?.email[0].toUpperCase()}
          </CommentAvatar>
          <CommentHeader
            startIcons={null}
            endIcons={null}
            title={comment.author?.email ?? "Unknown author"}
          />
          <CommentSecondaryHeader>
            <Stack direction="column">
              {comment.dateCreated && (
                <Typography sx={{ fontWeight: 400 }}>
                  Created on{" "}
                  {format(new Date(comment.dateCreated), "MMM d, yyyy h:mm a")}
                </Typography>
              )}
              <Stack direction="row" sx={{ mt: 1 }}>
                <Typography
                  sx={{ fontWeight: 400, minWidth: 0 }}
                  component="span"
                >
                  Edited by {activeRevision.actor.email} on{" "}
                  {format(
                    new Date(activeRevision.dateCreated),
                    "MMM d, yyyy h:mm a",
                  )}
                </Typography>
              </Stack>
            </Stack>
          </CommentSecondaryHeader>
          <CommentContent>
            <CommentTextEditorReadOnly
              content={activeRevision.updatedHtmlContent}
            />
          </CommentContent>
        </Comment>
      </DialogContent>
    </>
  );
}

export type UserCommentRevisionsDialogSkeletonProps = {};

export function UserCommentRevisionsDialogSkeleton(
  props: UserCommentRevisionsDialogSkeletonProps,
) {
  return (
    <>
      <DialogTitle>Comment Edit History</DialogTitle>
      <DialogContent sx={{ minHeight: 270 }}>
        <CommentSkeleton />
      </DialogContent>
    </>
  );
}

export type UserCommentRevisionsDialogErrorBoundaryProps = {};

export function UserCommentRevisionsDialogErrorBoundary(
  props: UserCommentRevisionsDialogErrorBoundaryProps,
) {
  return (
    <>
      <DialogTitle>Comment Edit History</DialogTitle>
      <DialogContent sx={{ minHeight: 270 }}>
        <Comment isFocused={false} showCommentConnectorLine={false}>
          <CommentAvatar color={{ backgroundColor: "red", textColor: "white" }}>
            {""}
          </CommentAvatar>
          <CommentHeader
            startIcons={null}
            endIcons={null}
            title={"Error rendering comment"}
          />
          <CommentContent>
            <Typography variant="body2">
              Something went wrong. Please reload reload the page and try again.
              If the problem persists, please contact{" "}
              <a>support@contractsafe.com</a>.
            </Typography>
          </CommentContent>
        </Comment>
      </DialogContent>
    </>
  );
}

export type UserCommentRevisionsDialogProps = {
  initialActiveRevisionUuid: string;
  open: boolean;
  queryRef: PreloadedQuery<UserCommentRevisionsDialog_commentQuery> | undefined;
  onClose: () => void;
};

export function UserCommentRevisionsDialog(
  props: UserCommentRevisionsDialogProps,
) {
  return (
    <Dialog
      open={props.open}
      fullWidth={true}
      maxWidth="sm"
      onClose={props.onClose}
    >
      <ErrorBoundary fallback={<UserCommentRevisionsDialogErrorBoundary />}>
        <React.Suspense fallback={<UserCommentRevisionsDialogSkeleton />}>
          {props.queryRef && (
            <LoadedUserCommentRevisionsDialog
              queryRef={props.queryRef}
              initialActiveRevisionUuid={props.initialActiveRevisionUuid}
            />
          )}
        </React.Suspense>
      </ErrorBoundary>
    </Dialog>
  );
}
