/**
 * @generated SignedSource<<487fd4e2e5b343e3d95a8229b32d2045>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserComment_commentEdgeFragment$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"UserComment_commentFragment">;
  };
  readonly " $fragmentType": "UserComment_commentEdgeFragment";
};
export type UserComment_commentEdgeFragment$key = {
  readonly " $data"?: UserComment_commentEdgeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserComment_commentEdgeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserComment_commentEdgeFragment",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": "CommentType",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserComment_commentFragment"
          }
        ],
        "storageKey": null
      },
      "action": "THROW",
      "path": "node"
    }
  ],
  "type": "CommentTypeEdge",
  "abstractKey": null
};

(node as any).hash = "781cba8f4360c36fd4ffc2c43ef2d584";

export default node;
