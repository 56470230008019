/**
 * @generated SignedSource<<3a6f6db735b28a8a42c21b599c169432>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteDialog_DeleteMutation$variables = {
  uuid: string;
};
export type DeleteDialog_DeleteMutation$data = {
  readonly deleteContract: {
    readonly clientMutationId: string | null | undefined;
  } | null | undefined;
};
export type DeleteDialog_DeleteMutation = {
  response: DeleteDialog_DeleteMutation$data;
  variables: DeleteDialog_DeleteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "uuid"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "uuid",
            "variableName": "uuid"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "DeleteContractPayload",
    "kind": "LinkedField",
    "name": "deleteContract",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteDialog_DeleteMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteDialog_DeleteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5a6a055fd1c5dc7a9bdf087168a0be8c",
    "id": null,
    "metadata": {},
    "name": "DeleteDialog_DeleteMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteDialog_DeleteMutation(\n  $uuid: String!\n) {\n  deleteContract(input: {uuid: $uuid}) {\n    clientMutationId\n  }\n}\n"
  }
};
})();

(node as any).hash = "e16b9a947df8afec668aa2df3d904219";

export default node;
