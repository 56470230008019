/**
 * @generated SignedSource<<da95d4437458fd495153bce6fe9a1d37>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContractActiveStatusChip_contractFragment$data = {
  readonly id: string;
  readonly isactive: boolean;
  readonly uuid: string;
  readonly " $fragmentType": "ContractActiveStatusChip_contractFragment";
};
export type ContractActiveStatusChip_contractFragment$key = {
  readonly " $data"?: ContractActiveStatusChip_contractFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContractActiveStatusChip_contractFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContractActiveStatusChip_contractFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uuid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isactive",
      "storageKey": null
    }
  ],
  "type": "ContractType",
  "abstractKey": null
};

(node as any).hash = "d3a15524e364d47c3220b4e52d039cb6";

export default node;
