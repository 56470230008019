/**
 * @generated SignedSource<<386c8f6554be8d5adf27ad94e7db738c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateCommentPinnedAtActionType = "PIN" | "UNPIN" | "%future added value";
export type useCommentPinnedAt_UpdateCommentPinnedAtMutation$variables = {
  action: UpdateCommentPinnedAtActionType;
  commentUuid: string;
};
export type useCommentPinnedAt_UpdateCommentPinnedAtMutation$data = {
  readonly updateCommentPinnedAt: {
    readonly __typename: "CommentNotFoundErrorType";
    readonly debugMessage: string | null | undefined;
  } | {
    readonly __typename: "UnauthorizedError";
    readonly debugMessage: string | null | undefined;
  } | {
    readonly __typename: "UpdateCommentPinnedAtSuccessResultType";
    readonly comment: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"UserComment_commentFragment">;
    };
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined;
};
export type useCommentPinnedAt_UpdateCommentPinnedAtMutation = {
  response: useCommentPinnedAt_UpdateCommentPinnedAtMutation$data;
  variables: useCommentPinnedAt_UpdateCommentPinnedAtMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "action"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "commentUuid"
},
v2 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "action",
        "variableName": "action"
      },
      {
        "kind": "Variable",
        "name": "commentUuid",
        "variableName": "commentUuid"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "debugMessage",
    "storageKey": null
  }
],
v6 = {
  "kind": "InlineFragment",
  "selections": (v5/*: any*/),
  "type": "CommentNotFoundErrorType",
  "abstractKey": null
},
v7 = {
  "kind": "InlineFragment",
  "selections": (v5/*: any*/),
  "type": "UnauthorizedError",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useCommentPinnedAt_UpdateCommentPinnedAtMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "updateCommentPinnedAt",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CommentType",
                "kind": "LinkedField",
                "name": "comment",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "UserComment_commentFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "UpdateCommentPinnedAtSuccessResultType",
            "abstractKey": null
          },
          (v6/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "useCommentPinnedAt_UpdateCommentPinnedAtMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "updateCommentPinnedAt",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CommentType",
                "kind": "LinkedField",
                "name": "comment",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "uuid",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AccountType",
                    "kind": "LinkedField",
                    "name": "author",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "email",
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "dateCreated",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pinnedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isAuthor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "htmlContent",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasContentEditRevisions",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "UpdateCommentPinnedAtSuccessResultType",
            "abstractKey": null
          },
          (v6/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "161cad902edb2f9a33b393d205d0fa9e",
    "id": null,
    "metadata": {},
    "name": "useCommentPinnedAt_UpdateCommentPinnedAtMutation",
    "operationKind": "mutation",
    "text": "mutation useCommentPinnedAt_UpdateCommentPinnedAtMutation(\n  $commentUuid: String!\n  $action: UpdateCommentPinnedAtActionType!\n) {\n  updateCommentPinnedAt(input: {commentUuid: $commentUuid, action: $action}) {\n    __typename\n    ... on UpdateCommentPinnedAtSuccessResultType {\n      comment {\n        id\n        ...UserComment_commentFragment\n      }\n    }\n    ... on CommentNotFoundErrorType {\n      debugMessage\n    }\n    ... on UnauthorizedError {\n      debugMessage\n    }\n  }\n}\n\nfragment UserCommentActionsMenu_commentFragment on CommentType {\n  id\n  uuid\n  isAuthor\n  pinnedAt\n  ...useCommentPinnedAt_commentFragment\n}\n\nfragment UserCommentContent_commentFragment on CommentType {\n  id\n  uuid\n  htmlContent\n}\n\nfragment UserComment_commentFragment on CommentType {\n  id\n  uuid\n  author {\n    email\n    id\n  }\n  dateCreated\n  pinnedAt\n  ...UserCommentActionsMenu_commentFragment\n  ...UserCommentContent_commentFragment\n  hasContentEditRevisions\n}\n\nfragment useCommentPinnedAt_commentFragment on CommentType {\n  id\n  uuid\n  isAuthor\n  pinnedAt\n}\n"
  }
};
})();

(node as any).hash = "6a81adfa367f9cbf262fab2ebb8f6495";

export default node;
