import React from "react";
import { Menu, Collapse, Divider, styled } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import FormatStrikethroughIcon from "@mui/icons-material/FormatStrikethrough";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CachedIcon from "@mui/icons-material/Cached";
import ArchiveIcon from "@mui/icons-material/Archive";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import { ContractPageMenuItemWithTooltip } from "./ContractPageMenuItemWithTooltip";

import type { ContractPageHeaderHeaderActionsPermissions } from "./useContractPageHeaderActionsPermissions";

const SubMenuItem = styled(ContractPageMenuItemWithTooltip)(({ theme }) => ({
  paddingLeft: theme.spacing(12),
}));

type ContractPageMoreMenuProps = {
  fileState: "NO_FILE" | "FILE" | "FILE_VIA_URL";
  permissions: ContractPageHeaderHeaderActionsPermissions;
  actionHandlers: {
    handleDownloadContractFile: () => void;
    handleDownloadContractFileWord: () => void;
    handleRedline: () => void;
    handleDuplicate: () => void;
    handleDelete: () => void;
    handleArchive: () => void;
    handleUploadContractFile: () => void;
    handleUpdateContractFromTemplate: () => void;
    handleReplaceContractFileViaUrl: () => void;
    handleReplaceContractFile: () => void;
  };
  anchorEl: null | HTMLElement;
  onClose: () => void;
};

export function ContractPageMoreMenu(props: ContractPageMoreMenuProps) {
  const { permissions } = props;
  const [downloadMenuOpen, setDownloadMenuOpen] =
    React.useState<boolean>(false);

  function handleMenuItemClick(callback?: () => void) {
    props.onClose();
    callback?.();
  }

  return (
    <>
      <Menu
        id="more-actions-menu"
        MenuListProps={{
          "aria-labelledby": "more-actions-button",
        }}
        anchorEl={props.anchorEl}
        open={Boolean(props.anchorEl)}
        onClose={props.onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <ContractPageMenuItemWithTooltip
          onClick={() => setDownloadMenuOpen(!downloadMenuOpen)}
          disabled={!permissions.downloadContractFile.isAuthorized}
          tooltipTitle={permissions.downloadContractFile.authorizationMessage}
        >
          <CloudDownloadIcon sx={{ mr: 2 }} />
          Download
          {downloadMenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ContractPageMenuItemWithTooltip>
        <Collapse in={downloadMenuOpen} timeout="auto" unmountOnExit>
          <Divider />
          <SubMenuItem
            onClick={() =>
              handleMenuItemClick(
                props.actionHandlers.handleDownloadContractFile,
              )
            }
            disabled={!permissions.downloadContractFile.isAuthorized}
            tooltipTitle={permissions.downloadContractFile.authorizationMessage}
          >
            Original Contract
          </SubMenuItem>
          <Divider />
          <SubMenuItem
            onClick={() =>
              handleMenuItemClick(
                props.actionHandlers.handleDownloadContractFileWord,
              )
            }
            disabled={!permissions.downloadContractFileWord.isAuthorized}
            tooltipTitle={
              permissions.downloadContractFileWord.authorizationMessage
            }
          >
            Download as Word
          </SubMenuItem>
        </Collapse>
        <Divider />
        <ContractPageMenuItemWithTooltip
          onClick={() =>
            handleMenuItemClick(props.actionHandlers.handleRedline)
          }
          disabled={!permissions.redline.isAuthorized}
          tooltipTitle={permissions.redline.authorizationMessage}
        >
          <FormatStrikethroughIcon sx={{ mr: 2 }} />
          Redline
        </ContractPageMenuItemWithTooltip>
        <Divider />
        <ContractPageMenuItemWithTooltip
          onClick={() =>
            handleMenuItemClick(props.actionHandlers.handleDuplicate)
          }
          disabled={!permissions.duplicate.isAuthorized}
          tooltipTitle={permissions.duplicate.authorizationMessage}
        >
          <ContentCopyIcon sx={{ mr: 2 }} />
          Duplicate
        </ContractPageMenuItemWithTooltip>
        <Divider />
        {props.fileState === "NO_FILE" && (
          <ContractPageMenuItemWithTooltip
            onClick={() =>
              handleMenuItemClick(props.actionHandlers.handleUploadContractFile)
            }
            disabled={!permissions.uploadContractFile.isAuthorized}
            tooltipTitle={permissions.uploadContractFile.authorizationMessage}
            data-testid="more-menu-upload-contract-file-button"
          >
            <CloudUploadIcon style={{ marginRight: 12 }} />
            Upload Contract
          </ContractPageMenuItemWithTooltip>
        )}
        {props.fileState === "FILE" && (
          <ContractPageMenuItemWithTooltip
            onClick={() =>
              handleMenuItemClick(
                props.actionHandlers.handleReplaceContractFile,
              )
            }
            disabled={!permissions.replaceContractFile.isAuthorized}
            tooltipTitle={permissions.replaceContractFile.authorizationMessage}
            data-testid="more-menu-replace-contract-file-button"
          >
            <CachedIcon style={{ marginRight: 12 }} />
            Replace Contract
          </ContractPageMenuItemWithTooltip>
        )}
        {props.fileState === "FILE_VIA_URL" && (
          <ContractPageMenuItemWithTooltip
            onClick={() =>
              handleMenuItemClick(
                props.actionHandlers.handleReplaceContractFileViaUrl,
              )
            }
            disabled={!permissions.replaceContractFileViaUrl.isAuthorized}
            tooltipTitle={
              permissions.replaceContractFileViaUrl.authorizationMessage
            }
            data-testid="more-menu-replace-contract-file-button"
          >
            <CachedIcon style={{ marginRight: 12 }} />
            Update Contract
          </ContractPageMenuItemWithTooltip>
        )}
        <Divider />
        <ContractPageMenuItemWithTooltip
          onClick={() =>
            handleMenuItemClick(
              props.actionHandlers.handleUpdateContractFromTemplate,
            )
          }
          disabled={!permissions.updateContractFromTemplate.isAuthorized}
          tooltipTitle={
            permissions.updateContractFromTemplate.authorizationMessage
          }
          data-testid="add-contract-button-template-button"
        >
          <FileOpenIcon sx={{ marginRight: "12px" }} />
          Replace Using Template
        </ContractPageMenuItemWithTooltip>
        <Divider />
        <ContractPageMenuItemWithTooltip
          onClick={() =>
            handleMenuItemClick(props.actionHandlers.handleArchive)
          }
          disabled={!permissions.archive.isAuthorized}
          tooltipTitle={permissions.archive.authorizationMessage}
        >
          <ArchiveIcon sx={{ mr: 2 }} />
          Archive Contract
        </ContractPageMenuItemWithTooltip>
        <Divider />
        <ContractPageMenuItemWithTooltip
          onClick={() => handleMenuItemClick(props.actionHandlers.handleDelete)}
          disabled={!permissions.deleteContract.isAuthorized}
          tooltipTitle={permissions.deleteContract.authorizationMessage}
        >
          <DeleteForeverIcon sx={{ mr: 2 }} />
          Delete Contract
        </ContractPageMenuItemWithTooltip>
      </Menu>
    </>
  );
}
