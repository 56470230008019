import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import type { SxProps, Theme } from "@mui/material/styles";

type CommentContentProps = {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
};

export function CommentContent(props: CommentContentProps) {
  return (
    <Box data-testid="comment-content" sx={props.sx}>
      {props.children}
    </Box>
  );
}
