import { getContractTypeField } from "~/utils/contractTypes";

export default function getIntakeCoreFields(orgContractTypes: any, tags: any) {
  const baseCoreFields = [
    { id: "core:effectiveDate", name: "Effective Date", fieldType: "d" },
    { id: "core:terminationDate", name: "Termination Date", fieldType: "d" },
    { id: "core:notes", name: "Notes", fieldType: "t" },
    {
      id: "core:myCompany",
      name: "My Company",
      fieldType: "p",
      partyType: "mycompany",
    },
    {
      id: "core:counterparty",
      name: "Counterparty",
      fieldType: "p",
      partyType: "counterparty",
    },
    { id: "core:mtags", name: "Tags", fieldType: "n", choices: tags },
    {
      id: "core:executionStatus",
      name: "Execution Status",
      fieldType: "m",
      choices: [
        { id: "UNKNOWN", name: "Unknown Execution Status" },
        { id: "FULLY_EXECUTED", name: "Fully Executed" },
        { id: "PARTIALLY_EXECUTED", name: "Partially Executed" },
        { id: "NOT_EXECUTED", name: "Not Executed" },
        { id: "NOT_APPLICABLE", name: "Not Applicable" },
      ],
      // noNullOption: true,
    },
  ] as const;

  if (!orgContractTypes) {
    return baseCoreFields;
  }

  const contractTypeField = getContractTypeField(orgContractTypes);
  const coreFields = [...baseCoreFields, contractTypeField] as const;

  return coreFields;
}
