/**
 * @generated SignedSource<<774864eb0ee81e2ae4164eb5ecbb2c31>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PartyDialog_Contactfragment$data = {
  readonly altPhone: string | null | undefined;
  readonly cityName: string;
  readonly country: string;
  readonly email: string;
  readonly firstName: string | null | undefined;
  readonly fullname: string | null | undefined;
  readonly initials: string | null | undefined;
  readonly isPrimary: boolean | null | undefined;
  readonly lastName: string | null | undefined;
  readonly phone: string | null | undefined;
  readonly province: string | null | undefined;
  readonly stateAbbreviation: string;
  readonly streetName: string;
  readonly streetName2: string | null | undefined;
  readonly title: string;
  readonly uuid: string;
  readonly zipcode: string;
  readonly " $fragmentType": "PartyDialog_Contactfragment";
};
export type PartyDialog_Contactfragment$key = {
  readonly " $data"?: PartyDialog_Contactfragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PartyDialog_Contactfragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PartyDialog_Contactfragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uuid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phone",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "altPhone",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "streetName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "streetName2",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cityName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "stateAbbreviation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "zipcode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "province",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "country",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPrimary",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullname",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "initials",
      "storageKey": null
    }
  ],
  "type": "ContactType",
  "abstractKey": null
};

(node as any).hash = "2502f9e649438cd296055a7e14c29e58";

export default node;
