import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "~/components/GridV2OrV1";
import Stack from "@mui/material/Stack";

export type CommentHeaderProps = {
  /** The title of the header, usually the author's name or email. */
  title: React.ReactNode;
  /** Icons that will show up to the left of the title */
  startIcons?: React.ReactNode;
  /** Icons that will show up to the right of the title */
  endIcons?: React.ReactNode;
};

export function CommentHeader(props: CommentHeaderProps) {
  const { startIcons, endIcons, title, ...restProps } = props;
  return (
    <Grid
      container
      xs={12}
      justifyContent="space-between"
      data-testid="comment-header"
      {...restProps}
    >
      <Stack direction="row">
        {startIcons && <Box sx={{ mr: 2 }}>{startIcons}</Box>}
        <Typography
          variant="h6"
          component="h6"
          sx={{ fontWeight: 600, lineHeight: 1.5 }}
        >
          {title}
        </Typography>
      </Stack>
      {endIcons && <Box>{endIcons}</Box>}
    </Grid>
  );
}
