import { z } from "zod";

export const TemplateVariableElementTypeSchema = z.union([
  z.literal("header-metadata"),
  z.literal("regular-metadata"),
  z.literal("text-metadata"),
  z.literal("regular-document"),
  z.literal("header-document"),
  z.literal("text-document"),
  z.literal("hidden"),
]);

export const TemplateVariableSchema = z.union([
  z.object({
    id: z.string(),
    field: z.object({
      id: z.string(),
      value: z.any(),
    }),
    elementType: z.union([
      z.literal("header-metadata"),
      z.literal("text-metadata"),
      z.literal("header-document"),
      z.literal("text-document"),
    ]),
  }),
  z.object({
    id: z.string(),
    field: z.object({
      id: z.string(),
      required: z.boolean(),
    }),
    elementType: z.literal("regular-metadata"),
  }),
  z.object({
    id: z.string(),
    field: z.object({
      id: z.string(),
    }),
    elementType: z.literal("regular-document"),
  }),
  z.object({
    id: z.string(),
    field: z.object({
      id: z.string(),
      value: z.any(),
    }),
    elementType: z.literal("hidden"),
  }),
]);

export type TemplateVariable = z.infer<typeof TemplateVariableSchema>;
