/**
 * @generated SignedSource<<8a498b6af74b6ff1cd1577258dbb3e43>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RelatedDocs_ContractMutation$variables = {
  agreementAmendment?: boolean | null | undefined;
  amendedAndRestated?: boolean | null | undefined;
  amendedToNamed?: string | null | undefined;
  amendedToUuid?: string | null | undefined;
  contractUuid: string;
};
export type RelatedDocs_ContractMutation$data = {
  readonly updateContract: {
    readonly contract: {
      readonly " $fragmentSpreads": FragmentRefs<"RelatedDocs_fragment">;
    } | null | undefined;
  } | null | undefined;
};
export type RelatedDocs_ContractMutation = {
  response: RelatedDocs_ContractMutation$data;
  variables: RelatedDocs_ContractMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "agreementAmendment"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "amendedAndRestated"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "amendedToNamed"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "amendedToUuid"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contractUuid"
},
v5 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "agreementAmendment",
        "variableName": "agreementAmendment"
      },
      {
        "kind": "Variable",
        "name": "amendedAndRestated",
        "variableName": "amendedAndRestated"
      },
      {
        "kind": "Variable",
        "name": "amendedToNamed",
        "variableName": "amendedToNamed"
      },
      {
        "kind": "Variable",
        "name": "amendedToUuid",
        "variableName": "amendedToUuid"
      },
      {
        "kind": "Variable",
        "name": "uuid",
        "variableName": "contractUuid"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isarchived",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "ContractPartyType",
  "kind": "LinkedField",
  "name": "counterparties",
  "plural": true,
  "selections": [
    (v8/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PartyType",
      "kind": "LinkedField",
      "name": "party",
      "plural": false,
      "selections": [
        (v8/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "companyName",
          "storageKey": null
        },
        (v6/*: any*/)
      ],
      "storageKey": null
    },
    (v6/*: any*/)
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "ContractDateType",
  "kind": "LinkedField",
  "name": "effectiveDate",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    },
    (v6/*: any*/)
  ],
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dateCreated",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "RelatedDocs_ContractMutation",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "UpdateContractPayload",
        "kind": "LinkedField",
        "name": "updateContract",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractType",
            "kind": "LinkedField",
            "name": "contract",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "RelatedDocs_fragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "RelatedDocs_ContractMutation",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "UpdateContractPayload",
        "kind": "LinkedField",
        "name": "updateContract",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractType",
            "kind": "LinkedField",
            "name": "contract",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "amendedAndRestated",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ContractType",
                "kind": "LinkedField",
                "name": "amendedTo",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v7/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "amendedToNamed",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "RelatedDocType",
                "kind": "LinkedField",
                "name": "relatedDocs",
                "plural": true,
                "selections": [
                  (v8/*: any*/),
                  (v7/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "filename",
                    "storageKey": null
                  },
                  (v12/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "relationType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContractType",
                    "kind": "LinkedField",
                    "name": "internalContract",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v8/*: any*/),
                      (v7/*: any*/),
                      (v9/*: any*/),
                      (v11/*: any*/),
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isGeneratedRedlineDocument",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ContractType",
                "kind": "LinkedField",
                "name": "amendments",
                "plural": true,
                "selections": [
                  (v8/*: any*/),
                  (v7/*: any*/),
                  (v9/*: any*/),
                  (v12/*: any*/),
                  (v11/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContractType",
                    "kind": "LinkedField",
                    "name": "amendments",
                    "plural": true,
                    "selections": [
                      (v8/*: any*/),
                      (v7/*: any*/),
                      (v9/*: any*/),
                      (v12/*: any*/),
                      (v11/*: any*/),
                      (v10/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d9e28501cf2bd4f565398ee4c7825c3a",
    "id": null,
    "metadata": {},
    "name": "RelatedDocs_ContractMutation",
    "operationKind": "mutation",
    "text": "mutation RelatedDocs_ContractMutation(\n  $contractUuid: String!\n  $agreementAmendment: Boolean\n  $amendedAndRestated: Boolean\n  $amendedToNamed: String\n  $amendedToUuid: String\n) {\n  updateContract(input: {uuid: $contractUuid, agreementAmendment: $agreementAmendment, amendedAndRestated: $amendedAndRestated, amendedToNamed: $amendedToNamed, amendedToUuid: $amendedToUuid}) {\n    contract {\n      ...RelatedDocs_fragment\n      id\n    }\n  }\n}\n\nfragment RelatedDocTitle_fragment on RelatedDocType {\n  id\n  uuid\n  title\n}\n\nfragment RelatedDocs_fragment on ContractType {\n  id\n  title\n  amendedAndRestated\n  amendedTo {\n    uuid\n    title\n    isarchived\n    counterparties {\n      uuid\n      label\n      party {\n        uuid\n        companyName\n        id\n      }\n      id\n    }\n    effectiveDate {\n      date\n      id\n    }\n    id\n  }\n  amendedToNamed\n  relatedDocs {\n    uuid\n    title\n    ...RelatedDocTitle_fragment\n    filename\n    dateCreated\n    relationType\n    internalContract {\n      id\n      uuid\n      title\n      isarchived\n      effectiveDate {\n        date\n        id\n      }\n      counterparties {\n        uuid\n        label\n        party {\n          uuid\n          companyName\n          id\n        }\n        id\n      }\n    }\n    isGeneratedRedlineDocument\n    id\n  }\n  amendments {\n    uuid\n    title\n    isarchived\n    dateCreated\n    effectiveDate {\n      date\n      id\n    }\n    counterparties {\n      uuid\n      label\n      party {\n        uuid\n        companyName\n        id\n      }\n      id\n    }\n    amendments {\n      uuid\n      title\n      isarchived\n      dateCreated\n      effectiveDate {\n        date\n        id\n      }\n      counterparties {\n        uuid\n        label\n        party {\n          uuid\n          companyName\n          id\n        }\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "7c458098801a2d29fc654f3cbcbc6b6b";

export default node;
