import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

export function ContractViewerSkeleton(props) {
  return (
    <Paper sx={{ width: 1, height: 1 }}>
      <Box
        sx={{ width: 1, height: 1, p: 3 }}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        {props.children}
      </Box>
    </Paper>
  );
}

export function ContractViewerLoadingSkeleton() {
  return (
    <ContractViewerSkeleton>
      <section>Loading...</section>
    </ContractViewerSkeleton>
  );
}
