import React, { Suspense, useState } from "react";
import { graphql, useFragment } from "react-relay";
import useAsyncMutation from "~/utils/UseAsyncMutation";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import ToggleButton from "@mui/material/ToggleButton";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import Add from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { TextField, Select } from "~/components/Field";
import Button from "~/components/Button";
import LoadingDialog from "~/components/LoadingDialog";
import EditFormDialog from "~/components/EditFormDialog";

export const FORM_SECTION_FRAGMENT = graphql`
  fragment FormSelect_formSectionFragment on FormSectionType {
    name
    fields {
      __typename
      ... on CustomFieldType {
        id
        name
        fieldType
        choices {
          ... on ValueListItemType {
            id
            name
          }
        }
      }
      ... on BuiltinFieldType {
        id
        name
        fieldType
        choices {
          ... on StringChoiceType {
            id
            name
          }
        }
      }
      ... on FieldSetType {
        id
        name
      }
    }
  }
`;

export const FORM_FRAGMENT = graphql`
  fragment FormSelect_formFragment on FormType @relay(plural: true) {
    id
    uuid
    name
    sections {
      ...FormSelect_formSectionFragment @relay(mask: false)
    }
    requiredFields {
      __typename
      ... on CustomFieldType {
        id
        name
        fieldType
      }
      ... on BuiltinFieldType {
        id
        name
        fieldType
      }
      ... on FieldSetType {
        id
        name
      }
    }
  }
`;

export const FORM_MUTATION = graphql`
  mutation FormSelect_formMutation($contractUuid: String!, $form: ID) {
    updateContract(input: { uuid: $contractUuid, form: $form }) {
      contract {
        currentFormMissingRequiredFields
        form {
          ...FormSelect_formFragment
        }
        ...FormSelect_contractFragment
      }
    }
  }
`;

function AddFormDialog({
  open,
  setOpen,
  onAdd,
  account,
  currentForm,
  ...props
}) {
  const [formName, setFormName] = useState("");
  const [shouldDupe, setShouldDupe] = useState(false);
  const [dupeForm, setDupeForm] = useState(currentForm);

  const [commitAddForm] = useAsyncMutation(graphql`
    mutation FormSelect_addFormMutation($name: String!, $dupeFormId: ID) {
      addForm(input: { name: $name, dupeFormId: $dupeFormId }) {
        form {
          id
        }
        organization {
          forms {
            ...FormSelect_formFragment
          }
        }
      }
    }
  `);

  function handleClose() {
    setOpen(false);
  }

  async function handleAddForm() {
    setOpen(false);
    const {
      addForm: { form },
    } = await commitAddForm({
      variables: {
        name: formName,
        dupeFormId: shouldDupe ? dupeForm : undefined,
      },
    });
    onAdd(form.id);
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleAddForm();
        }}
      >
        <DialogTitle id="form-dialog-title">Add Form</DialogTitle>
        <DialogContent dividers>
          <TextField
            autoFocus
            id="form"
            onChange={(e) => setFormName(e.target.value)}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={shouldDupe}
                onChange={({ target: { checked } }) => setShouldDupe(checked)}
                name="dupe"
                size="small"
              />
            }
            label="Duplicate Form"
          />
          {shouldDupe ? (
            <FormSelect
              account={account}
              value={dupeForm}
              onChange={setDupeForm}
            />
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button size="small" onClick={handleClose}>
            Cancel
          </Button>
          <Button size="small" onClick={handleAddForm} primary>
            Add
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

const ACCOUNT_FRAGMENT = graphql`
  fragment FormSelect_accountFragment on AccountType {
    organization {
      defaultForm {
        id
      }
      forms {
        id
        name
      }
      requiredFieldsEnabled
    }
    isAdmin
    isAcmanager
  }
`;

export function ContractFormSelect({
  account,
  contract,
  value,
  onChange,
  onCommit,
  ...props
}) {
  const {
    organization: { defaultForm, forms, requiredFieldsEnabled },
    isAdmin,
    isAcmanager,
  } = useFragment(ACCOUNT_FRAGMENT, account);

  const [editFormOpen, setEditFormOpen] = useState(false);
  function handleEditForm() {
    setEditFormOpen(true);
  }

  const { uuid: contractUuid, requiredForms } = useFragment(
    graphql`
      fragment FormSelect_contractFragment on ContractType {
        uuid
        currentFormMissingRequiredFields
        requiredForms {
          id
        }
      }
    `,
    contract,
  );

  const [commitAddRequiredForm] = useAsyncMutation(graphql`
    mutation FormSelect_addRequiredFormMutation(
      $contractUuid: String!
      $formId: ID
    ) {
      addRequiredFormToContract(
        input: { contractUuid: $contractUuid, formId: $formId }
      ) {
        contract {
          ...FormSelect_contractFragment
        }
      }
    }
  `);

  async function addRequiredForm(formId) {
    await commitAddRequiredForm({
      variables: {
        contractUuid,
        formId,
      },
    });
    onCommit();
  }

  const [commitRemoveRequiredForm] = useAsyncMutation(graphql`
    mutation FormSelect_removeRequiredFormMutation(
      $contractUuid: String!
      $formId: ID
    ) {
      removeRequiredFormFromContract(
        input: { contractUuid: $contractUuid, formId: $formId }
      ) {
        contract {
          ...FormSelect_contractFragment
        }
      }
    }
  `);

  async function removeRequiredForm(formId) {
    await commitRemoveRequiredForm({
      variables: {
        contractUuid,
        formId,
      },
    });
    onCommit();
  }

  const isRequired = (formOption) =>
    requiredForms.map((x) => x.id).includes(formOption.id);

  return (
    <>
      <Select
        size="small"
        value={value || defaultForm?.id || "Default"}
        onChange={({ target: { value } }) => {
          if (value != "edit form") {
            onChange(value == "Default" ? null : value);
          }
        }}
        SelectProps={{
          // render just the name in the select box, without the req button
          renderValue: (option) =>
            option == (defaultForm?.id || "Default")
              ? "Default"
              : forms.find((x) => x.id == option)?.name,
        }}
        {...props}
      >
        {[
          defaultForm || {},
          ...forms.filter((x) => x.id != defaultForm?.id),
        ].map((formOption) => (
          <MenuItem
            key={formOption.id || "Default"}
            value={formOption.id || "Default"}
          >
            <Grid container justifyContent="space-between">
              <Grid item>
                {formOption.id == defaultForm?.id ? "Default" : formOption.name}
                {isRequired(formOption) && " *"}
              </Grid>
              <Grid item>
                {requiredFieldsEnabled &&
                (isAdmin || isAcmanager) &&
                formOption.name?.toLowerCase() != "all fields" ? (
                  <ToggleButton
                    value="checked"
                    selected={isRequired(formOption)}
                    onChange={(e) => {
                      !requiredForms.map((x) => x.id).includes(formOption.id)
                        ? addRequiredForm(formOption.id)
                        : removeRequiredForm(formOption.id);
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    size="small"
                    sx={{
                      marginLeft: 2,
                      paddingTop: 0,
                      paddingBottom: 0,
                      textTransform: "none",
                    }}
                  >
                    Req
                  </ToggleButton>
                ) : null}
              </Grid>
            </Grid>
          </MenuItem>
        ))}

        {isAdmin || isAcmanager
          ? [
              <Divider key="d1" variant="middle" />,
              <MenuItem
                key="edit form"
                value="edit form"
                onClick={handleEditForm}
              >
                <EditIcon fontSize="small" style={{ marginRight: 12 }} />
                Edit Form
              </MenuItem>,
            ]
          : null}
      </Select>
      {editFormOpen ? (
        <Suspense fallback={<LoadingDialog title={"Edit Form"} />}>
          <EditFormDialog
            currentFormId={value}
            open={editFormOpen}
            handleClose={() => setEditFormOpen(false)}
            onCommit={onCommit}
          />
        </Suspense>
      ) : null}
    </>
  );
}

export function FormMenu({ account, onCommit, ...props }) {
  const [addFormOpen, setAddFormOpen] = useState(false);
  const [editFormOpenFor, setEditFormOpenFor] = useState(null);
  const {
    organization: { defaultForm, forms },
    isAdmin,
    isAcmanager,
  } = useFragment(ACCOUNT_FRAGMENT, account);

  return (
    <Grid container spacing={4} sx={{ margin: 2, width: "273px" }}>
      <Grid item xs={10}>
        Default
      </Grid>
      <Grid item xs={2}>
        <Button
          size="tiny"
          onClick={() => setEditFormOpenFor(defaultForm?.id || "Default")}
        >
          Edit
        </Button>
      </Grid>
      {forms
        .filter((x) => x.id != defaultForm?.id)
        .map((formOption) => (
          <>
            <Grid item xs={10}>
              {formOption.name}
            </Grid>
            <Grid item xs={2}>
              <Button
                size="tiny"
                onClick={() => setEditFormOpenFor(formOption.id)}
              >
                Edit
              </Button>
            </Grid>
          </>
        ))}
      <Grid item xs={12}>
        <Button size="small" onClick={() => setAddFormOpen(true)}>
          <Add fontSize="small" style={{ marginRight: 12 }} />
          Add Form
        </Button>
      </Grid>
      {addFormOpen ? (
        <AddFormDialog
          open={addFormOpen}
          setOpen={setAddFormOpen}
          account={account}
        />
      ) : null}
      {editFormOpenFor ? (
        <Suspense fallback={<LoadingDialog title={"Edit Form"} />}>
          <EditFormDialog
            currentFormId={editFormOpenFor}
            open={!!editFormOpenFor}
            handleClose={() => setEditFormOpenFor(null)}
            onCommit={onCommit}
          />
        </Suspense>
      ) : null}
    </Grid>
  );
}

export default function FormSelect({
  account,
  value,
  onChange,
  allowAdd,
  ...props
}) {
  const [addFormOpen, setAddFormOpen] = useState(false);
  const {
    organization: { defaultForm, forms },
    isAdmin,
    isAcmanager,
  } = useFragment(ACCOUNT_FRAGMENT, account);

  return (
    <>
      <Select
        size="small"
        value={!value || value == defaultForm?.id ? "Default" : value}
        onChange={({ target: { value } }) => {
          if (value != "add form") {
            onChange(value == "Default" ? defaultForm?.id : value);
          }
        }}
        {...props}
      >
        <MenuItem value={"Default"}>Default</MenuItem>
        {forms
          .filter((x) => x.id != defaultForm?.id)
          .map((formOption) => (
            <MenuItem key={formOption.id} value={formOption.id}>
              {formOption.name}
            </MenuItem>
          ))}

        {allowAdd
          ? [
              <Divider key="d2" variant="middle" />,
              <MenuItem
                key="add form"
                value="add form"
                onClick={() => {
                  setAddFormOpen(true);
                }}
              >
                <Add fontSize="small" style={{ marginRight: 12 }} />
                Add Form
              </MenuItem>,
            ]
          : null}
      </Select>
      {addFormOpen ? (
        <AddFormDialog
          open={addFormOpen}
          setOpen={setAddFormOpen}
          onAdd={onChange}
          account={account}
          currentForm={value}
        />
      ) : null}
    </>
  );
}
