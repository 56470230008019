import { graphql, usePreloadedQuery, type PreloadedQuery } from "react-relay";
import { type useDeferredLayoutQuery_rootQuery as TuseDeferredLayoutQuery_rootQuery } from "./__generated__/useDeferredLayoutQuery_rootQuery.graphql";
import React, {
  createContext,
  useContext,
  type PropsWithChildren,
} from "react";

type DeferredLayoutQueryContextType = {
  queryRef: PreloadedQuery<TuseDeferredLayoutQuery_rootQuery, {}>;
} | null;

const DeferredLayoutQueryContext =
  createContext<DeferredLayoutQueryContextType>(null);

type DeferredLayoutQueryProviderProps = PropsWithChildren<{
  useDeferredLayoutQuery_rootQueryRef: PreloadedQuery<
    TuseDeferredLayoutQuery_rootQuery,
    {}
  >;
}>;

export function DeferredLayoutQueryProvider({
  children,
  useDeferredLayoutQuery_rootQueryRef,
}: DeferredLayoutQueryProviderProps) {
  return (
    <DeferredLayoutQueryContext.Provider
      value={{ queryRef: useDeferredLayoutQuery_rootQueryRef }}
    >
      {children}
    </DeferredLayoutQueryContext.Provider>
  );
}

export function useDeferredLayoutQuery() {
  const context = useContext(DeferredLayoutQueryContext);

  if (context === null) {
    throw new Error(
      "useDeferredLayoutQuery must be used within a DeferredLayoutQueryProvider",
    );
  }

  const queryData = usePreloadedQuery(
    useDeferredLayoutQuery_rootQuery,
    context.queryRef,
  );

  return queryData;
}

export const useDeferredLayoutQuery_rootQuery = graphql`
  query useDeferredLayoutQuery_rootQuery($contractUuid: String!) {
    account @required(action: THROW) {
      ...ContractPageHeaderActions_accountFragment
        @arguments(contractUuid: $contractUuid)
    }
    contract(uuid: $contractUuid) @required(action: THROW) {
      ...ContractPageHeaderActions_contractFragment
      ...Summary_contractFragment
    }
    organization @required(action: THROW) {
      ...ContractPageHeaderActions_organizationFragment
    }
  }
`;

export { type TuseDeferredLayoutQuery_rootQuery };
