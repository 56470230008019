import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "~/components/Button";
import alertTypeToColor from "~/utils/AlertTypeToColor";

export default function MessageDialog({
  open,
  onClose,
  title,
  alertType,
  children,
}) {
  const confirmButtonColor = alertTypeToColor(alertType);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {title ? (
        <DialogTitle id="alert-dialog-title" style={{ whiteSpace: "pre-line" }}>
          {title}
        </DialogTitle>
      ) : null}
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {children}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          variant={"contained"}
          color={confirmButtonColor}
          autoFocus
          onClick={onClose}
          primary
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
