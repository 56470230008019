import React from "react";
import {
  type FabProps,
  IconButton,
  Paper,
  Skeleton,
  Tooltip,
} from "@mui/material";

export type ContractPageHeaderActionButtonProps = FabProps & {
  icon: React.ReactNode;
  tooltipTitle?: string | null | undefined;
} & Required<Pick<FabProps, "aria-label">>;

export function ContractPageHeaderActionButton(
  props: ContractPageHeaderActionButtonProps,
) {
  const { icon, sx, tooltipTitle, ...restProps } = props;
  return (
    <Tooltip title={tooltipTitle} enterDelay={500} placement="left">
      <IconButton size="small" sx={sx} {...restProps}>
        <Paper
          sx={{
            padding: 2,
            borderRadius: "50%",
            color: "primary.main",
          }}
        >
          {icon}
        </Paper>
      </IconButton>
    </Tooltip>
  );
}

type ContractPageHeaderActionButtonSkeletonProps = Pick<
  ContractPageHeaderActionButtonProps,
  "sx"
>;

export function ContractPageHeaderActionButtonSkeleton(
  props: ContractPageHeaderActionButtonSkeletonProps,
) {
  return (
    <Skeleton
      variant="circular"
      width={50}
      height={50}
      sx={[...(Array.isArray(props.sx) ? props.sx : [props.sx])]}
    />
  );
}
