import { graphql, useMutation } from "react-relay";
import type {
  useUpdateContractFromTemplateMutation_updateContractFromTemplateMutation,
  useUpdateContractFromTemplateMutation_updateContractFromTemplateMutation$data,
  useUpdateContractFromTemplateMutation_updateContractFromTemplateMutation$variables,
} from "./__generated__/useUpdateContractFromTemplateMutation_updateContractFromTemplateMutation.graphql";

export function useUpdateContractFromTemplateMutation() {
  const [commit, isInProgress] =
    useMutation<useUpdateContractFromTemplateMutation_updateContractFromTemplateMutation>(
      UPDATE_CONTRACT_FROM_TEMPLATE_MUTATION,
    );

  const handleUpdateContractFromTemplateMutation = (
    variables: useUpdateContractFromTemplateMutation_updateContractFromTemplateMutation$variables,
    handlers?: {
      onSuccess?: (
        response: useUpdateContractFromTemplateMutation_updateContractFromTemplateMutation$data,
      ) => void;
      onResultError?: (
        response: useUpdateContractFromTemplateMutation_updateContractFromTemplateMutation$data,
      ) => void;
      onError?: (error: Error) => void;
    },
  ) => {
    commit({
      variables,
      onCompleted: (response) => {
        if (
          response.updateContractFromTemplate?.__typename !== "ContractType"
        ) {
          handlers?.onResultError?.(response);
          return;
        }

        handlers?.onSuccess?.(response);
        return;
      },

      onError: (error) => {
        console.error(
          "Unexpected error updating contract from template. error: ",
          error,
        );

        handlers?.onError?.(error);
      },
    });
  };

  return {
    handleUpdateContractFromTemplateMutation,
    isUpdateContractFromTemplateMutationInProgress: isInProgress,
  };
}

const UPDATE_CONTRACT_FROM_TEMPLATE_MUTATION = graphql`
  mutation useUpdateContractFromTemplateMutation_updateContractFromTemplateMutation(
    $contractUuid: String!
    $templateUuid: String!
    $updatedFieldValues: [FieldValueInput!]!
  ) {
    updateContractFromTemplate(
      input: {
        contractUuid: $contractUuid
        templateUuid: $templateUuid
        updatedFieldValues: $updatedFieldValues
      }
    ) {
      __typename
      ... on ContractType {
        id
        filename
        ...UpdateContractFromTemplateDialogContent_contractFragment
      }
      ... on ContractNotFoundErrorType {
        debugMessage
      }
      ... on UnauthorizedError {
        debugMessage
        userMessage
      }
      ... on UnexpectedError {
        debugMessage
      }
    }
  }
`;
