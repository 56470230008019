/**
 * @generated SignedSource<<df0c7d26b509d0a56810bf65a434bf0f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateContractFromTemplateDialogTemplatesDropdown_organizationFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"TemplatesDropdown_organizationFragment">;
  readonly " $fragmentType": "UpdateContractFromTemplateDialogTemplatesDropdown_organizationFragment";
};
export type UpdateContractFromTemplateDialogTemplatesDropdown_organizationFragment$key = {
  readonly " $data"?: UpdateContractFromTemplateDialogTemplatesDropdown_organizationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UpdateContractFromTemplateDialogTemplatesDropdown_organizationFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpdateContractFromTemplateDialogTemplatesDropdown_organizationFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TemplatesDropdown_organizationFragment"
    }
  ],
  "type": "OrganizationType",
  "abstractKey": null
};

(node as any).hash = "ea1f7dfcb06849c62d15601d53566205";

export default node;
