import React, { useState } from "react";
import MuiToggleButton from "@mui/material/ToggleButton";
import MuiToggleButtonGroup from "@mui/material/ToggleButtonGroup";

export function ToggleButtonGroup({ sx, ...props }) {
  return (
    <MuiToggleButtonGroup
      sx={{
        background: "#FFFFFF",
        height: 32,
        border: "0.5px solid #D0D2D4",
        boxSizing: "border-box",
        ...sx,
      }}
      {...props}
    />
  );
}

export function ToggleButton({ sx, ...props }) {
  return (
    <MuiToggleButton
      sx={{
        color: "#676B72",
        border: "none",
        padding: "2px 8px",
        height: 23,
        marginTop: "4px",
        marginLeft: "4px",
        marginRight: "4px",
        marginBottom: 0,
        textTransform: "none",
        fontWeight: "normal",
        fontSize: 14,
        lineHeight: "19px",
        "&.Mui-selected": {
          background: "#E5EFFF !important",
          color: "#0C4EB5 !important",
          fontWeight: "bold",
        },
        ...sx,
      }}
      disableRipple
      {...props}
    />
  );
}
