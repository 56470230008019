/**
 * @generated SignedSource<<b5f12e91f2e0a76ee6f760695abab43f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FormSelect_removeRequiredFormMutation$variables = {
  contractUuid: string;
  formId?: string | null | undefined;
};
export type FormSelect_removeRequiredFormMutation$data = {
  readonly removeRequiredFormFromContract: {
    readonly contract: {
      readonly " $fragmentSpreads": FragmentRefs<"FormSelect_contractFragment">;
    } | null | undefined;
  } | null | undefined;
};
export type FormSelect_removeRequiredFormMutation = {
  response: FormSelect_removeRequiredFormMutation$data;
  variables: FormSelect_removeRequiredFormMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "contractUuid"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "formId"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "contractUuid",
        "variableName": "contractUuid"
      },
      {
        "kind": "Variable",
        "name": "formId",
        "variableName": "formId"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FormSelect_removeRequiredFormMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemoveRequiredFormPayload",
        "kind": "LinkedField",
        "name": "removeRequiredFormFromContract",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractType",
            "kind": "LinkedField",
            "name": "contract",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "FormSelect_contractFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FormSelect_removeRequiredFormMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemoveRequiredFormPayload",
        "kind": "LinkedField",
        "name": "removeRequiredFormFromContract",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractType",
            "kind": "LinkedField",
            "name": "contract",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "uuid",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "currentFormMissingRequiredFields",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "FormType",
                "kind": "LinkedField",
                "name": "requiredForms",
                "plural": true,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c386743448238cfb5e255e0d79cb77fb",
    "id": null,
    "metadata": {},
    "name": "FormSelect_removeRequiredFormMutation",
    "operationKind": "mutation",
    "text": "mutation FormSelect_removeRequiredFormMutation(\n  $contractUuid: String!\n  $formId: ID\n) {\n  removeRequiredFormFromContract(input: {contractUuid: $contractUuid, formId: $formId}) {\n    contract {\n      ...FormSelect_contractFragment\n      id\n    }\n  }\n}\n\nfragment FormSelect_contractFragment on ContractType {\n  uuid\n  currentFormMissingRequiredFields\n  requiredForms {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "8205ef09211d7d9aebdde697fd189a76";

export default node;
