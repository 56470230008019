/**
 * @generated SignedSource<<18d9bf3a5158c887f4e4b59c87a3e411>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditDateDialog_reminderFragment$data = {
  readonly addCustomMessage: boolean;
  readonly attachDocument: boolean;
  readonly customMessage: string | null | undefined;
  readonly id: string;
  readonly includeRelatedDocuments: boolean;
  readonly isactive: boolean | null | undefined;
  readonly recipients: ReadonlyArray<string | null | undefined> | null | undefined;
  readonly remindOn: any | null | undefined;
  readonly remindOnType: string | null | undefined;
  readonly repeatEvery: string;
  readonly " $fragmentType": "EditDateDialog_reminderFragment";
};
export type EditDateDialog_reminderFragment$key = {
  readonly " $data"?: EditDateDialog_reminderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditDateDialog_reminderFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditDateDialog_reminderFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "remindOnType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "remindOn",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "repeatEvery",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "addCustomMessage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customMessage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "recipients",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isactive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "attachDocument",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "includeRelatedDocuments",
      "storageKey": null
    }
  ],
  "type": "ReminderType",
  "abstractKey": null
};

(node as any).hash = "276a58bbc0e9277c3c8a4192cbf22d12";

export default node;
