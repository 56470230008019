/**
 * @generated SignedSource<<9b4f52d902ac013e5a46751a574db817>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useExtensions_organizationFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"mentionSuggestionOptions_organizationFragment">;
  readonly " $fragmentType": "useExtensions_organizationFragment";
};
export type useExtensions_organizationFragment$key = {
  readonly " $data"?: useExtensions_organizationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useExtensions_organizationFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useExtensions_organizationFragment",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "mentionSuggestionOptions_organizationFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AccountType",
          "kind": "LinkedField",
          "name": "accounts",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "email",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "uuid",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "OrganizationType",
  "abstractKey": null
};

(node as any).hash = "83ff147d3fb0c38618502d6d425faf8d";

export default node;
