/**
 * @generated SignedSource<<e88836a6fd5030670265098212bf36b6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useTemplateFieldValues_organizationInlineFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"fieldHelpers_organizationInlineFragment">;
  readonly " $fragmentType": "useTemplateFieldValues_organizationInlineFragment";
};
export type useTemplateFieldValues_organizationInlineFragment$key = {
  readonly " $data"?: useTemplateFieldValues_organizationInlineFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useTemplateFieldValues_organizationInlineFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useTemplateFieldValues_organizationInlineFragment"
};

(node as any).hash = "71a329871c6ed6ae5e21f2082454b08e";

export default node;
