import { Set } from "immutable";
import { v4 as uuid4 } from "uuid";
import React, { useState, useCallback, useEffect } from "react";
import { graphql, useFragment } from "react-relay";
import useAsyncMutation from "~/utils/UseAsyncMutation";
import _ from "lodash";

/* This is a safe way to have a debounced field that can flush local changes
periodically, but which will stay in sync with the remote value when local
changes cease, for example if it is updated by other mutations done by other
components.

If you have a component that needs a debounced commit, pass in the commit and
isInFlight you got from useAsyncMutation, the value that will be updated by the
mutation (from the result of a query), and a list of dependencies that identify
the field the commit operates on. This function returns a local state value
(initially set to remoteValue), a setter function to use to set it, a debounced
commit function, and a flush function to immediately flush debounces.

Remote changes will be reflected in the local state, but only after all local
changes have been committed.

The debounced function will be replaced entirely when the given dependencies
change. These should typically be identifying info for the underlying field, so
that when a component is changed to operate on a different field, debounced
commits for the old field don't interact with the new one.

Example usage:

  const { someRemoteValue } = useLazyLoadQuery(SOME_QUERY); // or useFragment...
  const [commit, isInFlight] = useAsyncMutation(graphql`
    mutation SomeMutation($fieldId: ...) {
      mutate(...) {
        ...
        someRemoteValue
        ...
      }
    }
  `);

  const commitCallback = useCallback(
    () => commit({ variables: { fieldId } }),
    [fieldId]
  );

  const [localValue, setLocalValue, debouncedCommit, flushDebouncedCommit] =
    useDebouncedCommit(commitCallback, isInFlight, someRemoteValue, [fieldId]);

  return (
    <TextField
      value={localValue}
      onChange={(x) => {
        setLocalValue(x); // reflect changes immediately
        debouncedCommit({ ...: x }); // commit after a timeout
      }}
      onBlur={flushDebouncedCommit} // flush commits immediately on blur
    />
  );
*/

export default function useDebouncedCommit(
  commit,
  isInFlight,
  remoteValue,
  dependencies,
  debounceDelay = DEBOUNCE_DELAY,
) {
  const [commitsInFlight, setCommitsInFlight] = useState(Set());
  const [localValue, setLocalValue] = useState(remoteValue);
  const [hasLocalChanges, setHasLocalChanges] = useState(false);

  // Dependencies changing means this is tracking a different field. Update the
  // displayed value immediately.
  useEffect(() => setLocalValue(remoteValue), dependencies);

  useEffect(() => {
    /* Update the local value to reflect remote changes, but only when there are
       no local changes waiting for debounce and no commit is in flight. Because
       we trigger on changes to isInFlight, we will eventually pick up any
       remote changes, after local changes cease and the last commit
       completes. */
    if (!hasLocalChanges && !isInFlight && commitsInFlight.isEmpty()) {
      setLocalValue(remoteValue);
    }
  }, [remoteValue, isInFlight, commitsInFlight]);

  // cache this callback so we don't replace the debounce every time a prop
  // changes; this is important to prevent old debounces from timing out and
  // overwriting a newer value that was flushed more recently
  const debouncedCommit = useCallback(
    _.debounce(async (commit, ...args) => {
      const commitId = uuid4();
      setCommitsInFlight((cifs) => cifs.add(commitId));
      const p = commit(...args);
      // now that a commit is in flight, clear the local changes flag
      setHasLocalChanges(false);
      await p;
      setCommitsInFlight((cifs) => cifs.delete(commitId));
    }, debounceDelay),
    dependencies,
  );

  return [
    localValue,
    (value) => {
      // set the local changes flag while we wait for a commit
      setHasLocalChanges(true);
      setLocalValue(value);
    },
    (...args) => {
      /* set the local changes flag while we wait for debounce timeout; this
         prevents some but not all possible errors when users have their own
         local caches that also watch remoteValue, which they shouldn't do, but
         CurrencyField does at the time this is being written */
      setHasLocalChanges(true);
      debouncedCommit(commit, ...args);
    },
    debouncedCommit.flush,
  ];
}
