/**
 * @generated SignedSource<<45c74258eefc767ec35ed5ae185ba365>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserCommentContent_commentFragment$data = {
  readonly htmlContent: string;
  readonly id: string;
  readonly uuid: string;
  readonly " $fragmentType": "UserCommentContent_commentFragment";
};
export type UserCommentContent_commentFragment$key = {
  readonly " $data"?: UserCommentContent_commentFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserCommentContent_commentFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserCommentContent_commentFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uuid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "htmlContent",
      "storageKey": null
    }
  ],
  "type": "CommentType",
  "abstractKey": null
};

(node as any).hash = "28ea240ab2e64715348c1ab2f12c5231";

export default node;
