/**
 * @generated SignedSource<<cbf86e5721bf9e6ea73d0b4ac647db22>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContactInput = {
  altPhone?: string | null | undefined;
  cityName?: string | null | undefined;
  companyName?: string | null | undefined;
  country?: string | null | undefined;
  email?: string | null | undefined;
  firstName?: string | null | undefined;
  isPrimary?: boolean | null | undefined;
  lastName?: string | null | undefined;
  phone?: string | null | undefined;
  province?: string | null | undefined;
  stateAbbreviation?: string | null | undefined;
  streetName?: string | null | undefined;
  streetName2?: string | null | undefined;
  title?: string | null | undefined;
  uuid?: string | null | undefined;
  zipcode?: string | null | undefined;
};
export type PartyDialog_updateContactMutation$variables = {
  contact: ContactInput;
};
export type PartyDialog_updateContactMutation$data = {
  readonly updateContact: {
    readonly selectedcontact: {
      readonly uuid: string;
      readonly " $fragmentSpreads": FragmentRefs<"PartyDialog_Contactfragment">;
    } | null | undefined;
  } | null | undefined;
};
export type PartyDialog_updateContactMutation = {
  response: PartyDialog_updateContactMutation$data;
  variables: PartyDialog_updateContactMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "contact"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "contact",
        "variableName": "contact"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PartyDialog_updateContactMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateContactPayload",
        "kind": "LinkedField",
        "name": "updateContact",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContactType",
            "kind": "LinkedField",
            "name": "selectedcontact",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "PartyDialog_Contactfragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PartyDialog_updateContactMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateContactPayload",
        "kind": "LinkedField",
        "name": "updateContact",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContactType",
            "kind": "LinkedField",
            "name": "selectedcontact",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "phone",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "altPhone",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "streetName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "streetName2",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cityName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "stateAbbreviation",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "zipcode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "province",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "country",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isPrimary",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fullname",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "initials",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c603b6dee90c9936075fd588b2542d39",
    "id": null,
    "metadata": {},
    "name": "PartyDialog_updateContactMutation",
    "operationKind": "mutation",
    "text": "mutation PartyDialog_updateContactMutation(\n  $contact: ContactInput!\n) {\n  updateContact(input: {contact: $contact}) {\n    selectedcontact {\n      uuid\n      ...PartyDialog_Contactfragment\n      id\n    }\n  }\n}\n\nfragment PartyDialog_Contactfragment on ContactType {\n  uuid\n  firstName\n  lastName\n  title\n  phone\n  altPhone\n  email\n  streetName\n  streetName2\n  cityName\n  stateAbbreviation\n  zipcode\n  province\n  country\n  isPrimary\n  fullname\n  initials\n}\n"
  }
};
})();

(node as any).hash = "37531f944458caaecf942f2ca8f2d05f";

export default node;
