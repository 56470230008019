import Divider from "@mui/material/Divider";
import Menu, { type MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { CommentAvatar } from "~/components/Comments/Comment/CommentAvatar";
import React from "react";
import { CURRENT_USER_AVATAR_COLOR } from "~/components/Comments/Comment/avatarColors";
import {
  graphql,
  useFragment,
  usePreloadedQuery,
  type PreloadedQuery,
} from "react-relay";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import type { UserCommentRevisionsMenu_contentEditRevisionFragment$data } from "./__generated__/UserCommentRevisionsMenu_contentEditRevisionFragment.graphql";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import Tooltip from "@mui/material/Tooltip";
import { ErrorBoundary } from "@sentry/react";
import type { UserCommentRevisionsMenu_commentQuery } from "./__generated__/UserCommentRevisionsMenu_commentQuery.graphql";
import type { UserCommentRevisionsMenu_commentFragment$key } from "./__generated__/UserCommentRevisionsMenu_commentFragment.graphql";

export const USER_COMMENT_REVISIONS_MENU_COMMENT_QUERY = graphql`
  query UserCommentRevisionsMenu_commentQuery($commentUuid: String!) {
    comment(uuid: $commentUuid) {
      ...UserCommentRevisionsMenu_commentFragment
    }
  }
`;

const COMMENT_FRAGMENT = graphql`
  fragment UserCommentRevisionsMenu_commentFragment on CommentResultType {
    __typename
    ... on CommentType {
      contentEditRevisions {
        ...UserCommentRevisionsMenu_contentEditRevisionFragment
      }
    }
    ... on ResultError {
      debugMessage
    }
  }
`;

const CONTENT_EDIT_REVISION_FRAGMENT = graphql`
  fragment UserCommentRevisionsMenu_contentEditRevisionFragment on CommentContentEditRevisionType {
    actor @required(action: THROW) {
      email
    }
    dateCreated
    uuid
  }
`;

export type LoadedUserCommentRevisionsMenuProps = {
  anchorEl: MenuProps["anchorEl"];
  queryRef: PreloadedQuery<UserCommentRevisionsMenu_commentQuery>;
  onClose: () => void;
  onRevisionClick: (revisionUuid: string) => void;
};

export function LoadedUserCommentRevisionsMenu(
  props: LoadedUserCommentRevisionsMenuProps,
) {
  const data = usePreloadedQuery(
    USER_COMMENT_REVISIONS_MENU_COMMENT_QUERY,
    props.queryRef,
  );
  const comment = useFragment<UserCommentRevisionsMenu_commentFragment$key>(
    COMMENT_FRAGMENT,
    data.comment,
  );

  if (
    !comment ||
    comment.__typename !== "CommentType" ||
    !comment.contentEditRevisions
  ) {
    throw new Error(
      "Unexpected data for USER_COMMENT_REVISIONS_MENU_COMMENT_QUERY",
    );
  }

  const contentEditRevisions = comment.contentEditRevisions ?? [];

  return (
    <>
      <Typography sx={{ fontWeight: 400, px: 4, py: 2 }} component="h6">
        {contentEditRevisions?.length &&
          `Edited ${contentEditRevisions?.length} time`}
        {(contentEditRevisions ?? []).length > 1 ? "s" : ""}
      </Typography>
      <Divider key="divider" />
      {contentEditRevisions.map((contentEditRevision) => {
        const revision = useFragment(
          CONTENT_EDIT_REVISION_FRAGMENT,
          contentEditRevision,
        ) as UserCommentRevisionsMenu_contentEditRevisionFragment$data;
        const actorEmail = revision.actor.email;
        return (
          <MenuItem
            key={revision.uuid}
            sx={{ py: 2, px: 4, width: "100%" }}
            onClick={() => props.onRevisionClick(revision.uuid)}
          >
            <CommentAvatar
              color={CURRENT_USER_AVATAR_COLOR}
              sx={{ width: 24, height: 24, fontSize: "1rem", p: "4px" }}
            >
              {actorEmail[0].toUpperCase()}
            </CommentAvatar>
            <Typography
              sx={{ fontWeight: 600, ml: 2, minWidth: 0 }}
              noWrap
              component="span"
            >
              {actorEmail}
            </Typography>
            <Tooltip title={revision.dateCreated}>
              <Typography sx={{ fontWeight: 400, ml: 2 }} component="span">
                edited {formatDistanceToNow(new Date(revision.dateCreated))} ago
              </Typography>
            </Tooltip>
          </MenuItem>
        );
      })}
    </>
  );
}

export type UserCommentRevisionsMenuSkeletonProps = {
  anchorEl: MenuProps["anchorEl"];
  onClose: () => void;
};

export function UserCommentRevisionsMenuSkeleton(
  props: UserCommentRevisionsMenuSkeletonProps,
) {
  return (
    <>
      <Typography component="h6" sx={{ fontWeight: 400, px: 4, py: 2 }}>
        Loading comment edit history...
      </Typography>
      <Divider key="divider" />
      <MenuItem sx={{ py: 2, px: 4 }}>
        <CommentAvatar
          color={CURRENT_USER_AVATAR_COLOR}
          sx={{ width: 24, height: 24, fontSize: "1rem", p: "4px" }}
        >
          <></>
        </CommentAvatar>
        <Skeleton
          variant="text"
          sx={{ fontSize: "1rem", width: "100%", ml: 2 }}
          animation="wave"
        />
      </MenuItem>
    </>
  );
}

export type UserCommentRevisionsMenuErrorBoundaryProps = {
  anchorEl: MenuProps["anchorEl"];
  onClose: () => void;
};

export function UserCommentRevisionsMenuErrorBoundary(
  props: UserCommentRevisionsMenuSkeletonProps,
) {
  return (
    <Box sx={{ px: 4, py: 2 }}>
      <Typography
        variant="h6"
        component="h6"
        sx={{ fontWeight: 600, lineHeight: 1.5 }}
      >
        Something went wrong.
      </Typography>
      <Typography sx={{ fontWeight: 400, mt: 3 }}>
        Something went wrong loading your comment edit history. Please reload
        reload the page and try again. If the problem persists, please contact{" "}
        <a>support@contractsafe.com</a>.
      </Typography>
    </Box>
  );
}

export type UserCommentRevisionsMenuProps = {
  anchorEl: MenuProps["anchorEl"];
  queryRef: PreloadedQuery<UserCommentRevisionsMenu_commentQuery>;
  onClose: () => void;
  onRevisionClick: (revisionUuid: string) => void;
};

export function UserCommentRevisionsMenu(props: UserCommentRevisionsMenuProps) {
  return (
    <Menu
      anchorEl={props.anchorEl}
      slotProps={{
        paper: { sx: { width: { sm: "300px", md: "400px" } } },
      }}
      open={Boolean(props.anchorEl)}
      onClose={props.onClose}
    >
      <ErrorBoundary
        fallback={
          <UserCommentRevisionsMenuErrorBoundary
            anchorEl={props.anchorEl}
            onClose={props.onClose}
          />
        }
      >
        <React.Suspense
          fallback={
            <UserCommentRevisionsMenuSkeleton
              anchorEl={props.anchorEl}
              onClose={props.onClose}
            />
          }
        >
          <LoadedUserCommentRevisionsMenu
            anchorEl={props.anchorEl}
            queryRef={props.queryRef}
            onClose={props.onClose}
            onRevisionClick={props.onRevisionClick}
          />
        </React.Suspense>
      </ErrorBoundary>
    </Menu>
  );
}
