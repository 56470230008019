import React, { useState } from "react";
import { graphql } from "relay-runtime";
import useAsyncMutation from "~/utils/UseAsyncMutation";
import { track } from "~/analytics/events";
import { YesNoDialog } from "~/components/ConfirmDialog";
import ConfirmAndWorkDialog from "~/components/ConfirmAndWorkDialog";
import type { DuplicateDialog_DuplicateMutation } from "./__generated__/DuplicateDialog_DuplicateMutation.graphql";

interface DuplicateDialogProps {
  contractUuid: string;
  open: boolean;
  handleClose: () => void;
  hasAttachments: boolean;
}

export function DuplicateDialog({
  contractUuid,
  open,
  handleClose,
  hasAttachments,
}: DuplicateDialogProps) {
  const [loading, setLoading] = useState(false);
  const [commitDuplicate] = useAsyncMutation<DuplicateDialog_DuplicateMutation>(
    graphql`
      mutation DuplicateDialog_DuplicateMutation(
        $uuid: String!
        $attch: Boolean
      ) {
        duplicateContract(input: { uuid: $uuid, attch: $attch }) {
          clientMutationId
        }
      }
    `,
  );

  async function handleConfirm(includeAttachments: boolean) {
    setLoading(true);
    try {
      await commitDuplicate({
        variables: { uuid: contractUuid, attch: includeAttachments },
      });
      track("Contract Duplicated");
      handleClose();
      window.location.href = "/contracts";
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      {hasAttachments ? (
        <YesNoDialog
          open={open}
          onClose={handleClose}
          onNo={() => handleConfirm(false)}
          onYes={() => handleConfirm(true)}
          loading={loading}
        >
          Would you like to include attachments?
        </YesNoDialog>
      ) : (
        <ConfirmAndWorkDialog
          open={open}
          onClose={handleClose}
          onConfirm={() => handleConfirm(false)}
          loading={loading}
        >
          Are you sure you want to duplicate this contract?
        </ConfirmAndWorkDialog>
      )}
    </>
  );
}
