import { Alert, Chip, Menu, MenuItem } from "@mui/material";
import React, { useState, useRef } from "react";
import type { KeyboardEvent } from "react";
import { graphql, useFragment } from "react-relay";
import useAsyncMutation from "~/utils/UseAsyncMutation";
import type { ContractExecutionStatusChip_contractFragment$key } from "./__generated__/ContractExecutionStatusChip_contractFragment.graphql";
import { type ContractExecutionStatusChip_executionStatusMutation } from "./__generated__/ContractExecutionStatusChip_executionStatusMutation.graphql";
import { useSnackbar } from "~/components/Snackbar";

type ContractExecutionStatusChipProps = {
  contractFragRef: ContractExecutionStatusChip_contractFragment$key;
};

// Map of execution status values to display labels
const EXECUTION_STATUS_LABELS: Record<string, string> = {
  FULLY_EXECUTED: "Fully Executed",
  PARTIALLY_EXECUTED: "Partially Executed",
  NOT_EXECUTED: "Not Executed",
  UNKNOWN: "Unknown Execution Status",
  NOT_APPLICABLE: "Not Applicable",
};

export function ContractExecutionStatusChip(
  props: ContractExecutionStatusChipProps,
) {
  const { openSnackbarWithProps } = useSnackbar();
  const contract = useFragment(
    ContractExecutionStatusChip_contractFragment,
    props.contractFragRef,
  );
  const [commitExecutionStatus] =
    useAsyncMutation<ContractExecutionStatusChip_executionStatusMutation>(
      ContractExecutionStatusChip_executionStatusMutation,
    );

  // State for the menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  // Get the current execution status or default to UNKNOWN
  const currentStatus = contract.legal?.executionStatus || "UNKNOWN";

  // Define the status options for the menu
  const statusOptions = [
    {
      value: "FULLY_EXECUTED",
      label: EXECUTION_STATUS_LABELS["FULLY_EXECUTED"],
    },
    {
      value: "PARTIALLY_EXECUTED",
      label: EXECUTION_STATUS_LABELS["PARTIALLY_EXECUTED"],
    },
    { value: "NOT_EXECUTED", label: EXECUTION_STATUS_LABELS["NOT_EXECUTED"] },
    { value: "UNKNOWN", label: EXECUTION_STATUS_LABELS["UNKNOWN"] },
    {
      value: "NOT_APPLICABLE",
      label: EXECUTION_STATUS_LABELS["NOT_APPLICABLE"],
    },
  ];

  function handleClick(event: React.MouseEvent<HTMLDivElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  async function handleStatusChange(newStatus: string) {
    handleClose();

    if (!contract.legal?.id) {
      console.error("Legal ID is missing");
      return;
    }

    try {
      await commitExecutionStatus({
        variables: {
          uuid: contract.uuid,
          legal: {
            id: contract.legal.id,
            executionStatus: newStatus,
          },
        },
        optimisticResponse: {
          updateContract: {
            contract: {
              id: contract.id,
              uuid: contract.uuid,
              legal: {
                id: contract.legal.id,
                executionStatus: newStatus,
              },
            },
          },
        },
      });
    } catch (error) {
      console.error("Failed to update execution status:", error);
      openSnackbarWithProps({
        children: (
          <Alert
            severity="error"
            sx={{
              whiteSpace: "pre-line",
            }}
          >
            Failed to update execution status
          </Alert>
        ),
      });
    }
  }

  const isExecuted = currentStatus === "FULLY_EXECUTED";
  const isPartiallyExecuted = currentStatus === "PARTIALLY_EXECUTED";

  if (!contract.legal) {
    return null;
  }

  return (
    <>
      <Chip
        label={EXECUTION_STATUS_LABELS[currentStatus]}
        onClick={handleClick}
        tabIndex={0}
        role="button"
        aria-haspopup="listbox"
        aria-expanded={open}
        aria-label="Select execution status"
        sx={[
          {
            borderRadius: "8px",
            cursor: "pointer",
          },
          (isExecuted || isPartiallyExecuted) && {
            backgroundColor: "rgba(13, 78, 181, 1)",
            border: "0.5px solid rgba(13, 78, 181, 1)",
            color: "#fff",
            "&:hover": {
              backgroundColor: "rgba(13, 78, 181, 0.7)",
            },
          },
          !isExecuted &&
            !isPartiallyExecuted && {
              backgroundColor: "rgba(217, 217, 217, 1)",
              color: "rgba(102, 108, 121, 1)",
              border: "0.5px solid rgba(102, 108, 121, 1)",
              "&:hover": {
                backgroundColor: "rgba(217, 217, 217, 0.7)",
              },
            },
        ]}
      />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          role: "listbox",
          "aria-labelledby": "execution-status-selector",
          id: "execution-status-menu",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {statusOptions.map((option) => (
          <MenuItem
            key={option.value}
            onClick={() => handleStatusChange(option.value)}
            selected={option.value === currentStatus}
            role="option"
            aria-selected={option.value === currentStatus}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

const ContractExecutionStatusChip_contractFragment = graphql`
  fragment ContractExecutionStatusChip_contractFragment on ContractType {
    id
    uuid
    legal {
      id
      executionStatus
    }
  }
`;

const ContractExecutionStatusChip_executionStatusMutation = graphql`
  mutation ContractExecutionStatusChip_executionStatusMutation(
    $uuid: String!
    $legal: LegalInput
  ) @raw_response_type {
    updateContract(input: { uuid: $uuid, legal: $legal }) {
      contract {
        ...ContractExecutionStatusChip_contractFragment
      }
    }
  }
`;
