/**
 * @generated SignedSource<<0c645b5516c3f1aa784eee66288d3b7a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useTemplateFieldValues_builtinFieldInlineFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"fieldHelpers_builtinFieldInlineFragment">;
  readonly " $fragmentType": "useTemplateFieldValues_builtinFieldInlineFragment";
};
export type useTemplateFieldValues_builtinFieldInlineFragment$key = {
  readonly " $data"?: useTemplateFieldValues_builtinFieldInlineFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useTemplateFieldValues_builtinFieldInlineFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useTemplateFieldValues_builtinFieldInlineFragment"
};

(node as any).hash = "27a991d22411f23c105d865d8fb7a1ee";

export default node;
