/**
 * @generated SignedSource<<9979bb78ac8ab358a23974981a8c9235>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PDFViewerArea_contractFragment$data = {
  readonly filename: string;
  readonly uuid: string;
  readonly " $fragmentSpreads": FragmentRefs<"PDFViewer_fragment">;
  readonly " $fragmentType": "PDFViewerArea_contractFragment";
};
export type PDFViewerArea_contractFragment$key = {
  readonly " $data"?: PDFViewerArea_contractFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PDFViewerArea_contractFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PDFViewerArea_contractFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "filename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uuid",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PDFViewer_fragment"
    }
  ],
  "type": "ContractType",
  "abstractKey": null
};

(node as any).hash = "6b8ce7a1c964ab60f304bac36f21a671";

export default node;
