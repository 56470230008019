/**
 * @generated SignedSource<<df1dbf3aee04611b821164abc1b1d364>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TerminationDateAndReminder_ContractFragment$data = {
  readonly id: string;
  readonly isactive: boolean;
  readonly terminationDate: {
    readonly " $fragmentSpreads": FragmentRefs<"TerminationDateAndReminder_ContractDateFragment">;
  } | null | undefined;
  readonly uuid: string;
  readonly " $fragmentType": "TerminationDateAndReminder_ContractFragment";
};
export type TerminationDateAndReminder_ContractFragment$key = {
  readonly " $data"?: TerminationDateAndReminder_ContractFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"TerminationDateAndReminder_ContractFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TerminationDateAndReminder_ContractFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uuid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isactive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ContractDateType",
      "kind": "LinkedField",
      "name": "terminationDate",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TerminationDateAndReminder_ContractDateFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ContractType",
  "abstractKey": null
};

(node as any).hash = "291233895428fb00abae684600fdd138";

export default node;
