import React, { useState, useMemo, useEffect } from "react";
import "@fontsource/open-sans";
import _ from "lodash";
import MuiTextField from "@mui/material/TextField";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import DateFnsUtils from "@date-io/date-fns";
import startOfToday from "date-fns/startOfToday";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import MuiAutocomplete from "@mui/material/Autocomplete";
import calcWidth from "text-width";
import Measure from "react-measure";
import { valueIfString } from "~/utils/global";
import LabeledItem, { InputLabel } from "~/components/LabeledItem";
import MuiInputLabel from "@mui/material/InputLabel";

const lineHeight = 22;

export { InputLabel };

const getSizeHeight = (size) => ({ large: 42, small: 27 })[size] ?? 37;

function BareTextField({
  InputProps,
  inputProps,
  select,
  SelectProps,
  value,
  size,
  "aria-label": ariaLabel,
  ...props
}) {
  return (
    <MuiTextField
      fullWidth
      onKeyDown={(e) => {
        if (e.keyCode == 13) {
          e.target.blur();
        }
      }}
      select={select}
      inputProps={{
        "aria-label": ariaLabel,
        ...(select
          ? inputProps
          : {
              sx: {
                lineHeight: `${lineHeight}px`,
                paddingBottom: "0px",
                paddingLeft: "8px",
                paddingRight: "8px",
                paddingTop: "0px",
                height: getSizeHeight(size),
              },
              ...inputProps,
            }),
      }}
      InputProps={{
        sx: {
          background: "#ffffff",
          boxSizing: "border-box",
          lineHeight: `${lineHeight}px`,
          fontSize: size == "small" ? 14 : 16,
          fontWeight: "normal",
          "&.MuiInputBase-adornedEnd": { paddingRight: "0px" },
        },
        ...InputProps,
      }}
      SelectProps={{
        sx:
          size == "small"
            ? {
                background: "#e5efff",
                color: "#0c4eb5",
                boxSizing: "border-box",
                height: getSizeHeight(size),
                lineHeight: "19px",
                paddingBottom: "9px",
                paddingLeft: "8px",
                paddingRight: "8px",
                paddingTop: "9px",
                width: "100%",
              }
            : {
                background: "#ffffff",
                boxSizing: "border-box",
                height: getSizeHeight(size),
                lineHeight: `${lineHeight}px`,
                paddingBottom: "9px",
                paddingLeft: "8px",
                paddingRight: "8px",
                paddingTop: "9px",
                width: "100%",
              },
        inputProps: {
          sx:
            size == "small"
              ? {
                  paddingLeft: "0px",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                  "&:focus": { background: "initial" },
                  width: "100%",
                  fontSize: 14,
                  fontWeight: "bold",
                }
              : {
                  height: getSizeHeight(size),
                  lineHeight: "36px",
                  paddingLeft: "0px",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                  "&:focus": { background: "initial" },
                  width: "100%",
                  fontSize: 16,
                  fontWeight: "normal",
                },
        },
        ...SelectProps,
      }}
      value={value ?? undefined}
      {...props}
    />
  );
}

function MultilineBareTextField({
  minRows,
  maxRows,
  InputProps,
  value,
  resizeHorizontal,
  "aria-label": ariaLabel,
  ...props
}) {
  const [width, setWidth] = useState(0);
  const lineWidths = useMemo(
    () =>
      (value || "").split("\n").map((line) =>
        calcWidth(line, {
          size: 16,
          weight: "normal",
          family: "Open Sans",
        }),
      ),
    [value],
  );
  const totalWrappedLines = useMemo(
    () =>
      lineWidths.reduce(
        (acc, lineWidth) =>
          acc + Math.max(1, Math.ceil(lineWidth / Math.max(1, width - 22))),
        0,
      ),
    [lineWidths, width],
  );
  const rows = Math.max(
    minRows || 1,
    Math.min(totalWrappedLines, maxRows || Infinity),
  );

  return (
    <Measure
      bounds
      onResize={(contentRect) => setWidth(contentRect.bounds.width)}
    >
      {({ measureRef }) => (
        <BareTextField
          value={value}
          InputProps={{
            ...{
              inputComponent: "textarea",
              inputProps: {
                ref: measureRef,
                rows,
                style: {
                  height: rows * lineHeight + 15,
                  marginBottom: 3,
                  marginRight: 3,
                  paddingLeft: 8,
                  paddingRight: 14,
                  paddingTop: 8,
                  paddingBottom: 4,
                  resize: resizeHorizontal ? "both" : undefined,
                },
                "aria-label": ariaLabel,
              },
              style: resizeHorizontal
                ? { maxInlineSize: "fit-content" }
                : undefined,
            },
            ...InputProps,
          }}
          onKeyDown={() => {}}
          {...props}
        />
      )}
    </Measure>
  );
}

export function TextField(props) {
  const {
    required,
    label,
    labelComponent,
    icons,
    multiline,
    value,
    style,
    "aria-label": ariaLabel,
    ...slimProps
  } = props;
  const autoAriaLabel = ariaLabel || valueIfString(label) || props.placeholder;
  return (
    <LabeledItem
      {...{ required, label, labelComponent, icons, style, ...slimProps }}
    >
      {multiline ? (
        <MultilineBareTextField
          aria-label={autoAriaLabel}
          variant="outlined"
          value={value}
          {...slimProps}
        />
      ) : (
        <BareTextField
          aria-label={autoAriaLabel}
          variant="outlined"
          value={value}
          {...slimProps}
        />
      )}
    </LabeledItem>
  );
}

export function DateField({
  required,
  label,
  labelComponent,
  format,
  onChange,
  dateid,
  onBlur,
  name,
  "aria-label": ariaLabel,
  ...props
}) {
  function handleChange(d) {
    console.log(d);
    if (!!d && d != "Invalid Date") {
      if (d.getFullYear() < 100) {
        d.setFullYear(d.getFullYear() + 2000);
      }
    }
    onChange(d);
  }

  const data_testid_value = (
    typeof label == "string"
      ? label
      : typeof name == "string"
        ? name
        : "unlabeled"
  )
    .toLowerCase()
    .replace(/\s/g, "");

  return (
    <LabeledItem {...{ required, label, labelComponent, ...props }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          format={format || "MM/dd/yyyy"}
          slots={{
            textField: TextField,
          }}
          slotProps={{
            textField: {
              id: "datefield-" + dateid,
              onBlur: onBlur,
              name: name,
              "data-testid": "datefield-" + data_testid_value,
              "aria-label": ariaLabel || valueIfString(label),
              variant: "outlined",
            },
            openPickerButton: {
              "aria-label": "change date",
            },
          }}
          onChange={handleChange}
          {...props}
        />
      </LocalizationProvider>
    </LabeledItem>
  );
}

export function Autocomplete({
  required,
  label,
  labelComponent,
  multiple,
  value,
  placeholder,
  size,
  "aria-label": ariaLabel,
  ...props
}) {
  const [title, setTitle] = useState(""); // this is the tooltip
  return (
    <LabeledItem {...{ required, label, labelComponent, ...props }}>
      <Measure
        scroll
        offset
        onResize={(contentRect) => {
          if (contentRect.offset.width < contentRect.scroll.width) {
            setTitle(value);
          }
        }}
      >
        {({ measureRef }) => (
          <MuiAutocomplete
            autoComplete
            disableClearable
            forcePopupIcon
            freeSolo
            multiple={multiple}
            renderInput={({ inputProps, ...riProps }) => (
              <BareTextField
                variant="outlined"
                inputRef={measureRef}
                inputProps={{
                  title,
                  "aria-label":
                    ariaLabel || valueIfString(label) || placeholder,
                  ...inputProps,
                }}
                placeholder={placeholder}
                {...riProps}
              />
            )}
            value={value}
            {...props}
            sx={
              multiple
                ? props.sx
                : {
                    "& .MuiOutlinedInput-root": {
                      lineHeight: `${lineHeight}px`,
                      background: "#ffffff",
                      fontSize: 16,
                      fontWeight: "normal",
                      paddingBottom: "0px !important",
                      paddingLeft: "8px !important",
                      paddingRight: "34px !important",
                      paddingTop: "0px !important",
                      "& .MuiOutlinedInput-input": {
                        paddingBottom: "0px !important",
                        paddingLeft: "0px !important",
                        paddingRight: "0px !important",
                        paddingTop: "0px !important",
                        height: ({ size }) => getSizeHeight(size),
                      },
                      "& .MuiAutocomplete-clearIndicator": {
                        visibility: "visible",
                      },
                    },
                    ...props.sx,
                  }
            }
          />
        )}
      </Measure>
    </LabeledItem>
  );
}

function BasicSelect({
  required,
  label,
  labelComponent,
  value,
  SelectProps,
  children,
  "aria-label": ariaLabel,
  // LabeledItemProps is the preferred way to pass props to LabeledItem but we will also spread the props due to backwards compatibility
  LabeledItemProps = {},
  ...props
}) {
  const [title, setTitle] = useState(""); // this is the tooltip
  const [refCaptured, setRefCaptured] = useState(false);

  useEffect(() => {
    setTitle("");
    setRefCaptured(false);
  }, [value]);

  return (
    <LabeledItem
      {...{ required, label, labelComponent, ...LabeledItemProps, ...props }}
    >
      <Measure
        scroll
        offset
        onResize={(contentRect) => {
          if (contentRect.offset.width < contentRect.scroll.width) {
            // set the tooltip to the current value's text
            const valueToText = Object.fromEntries(
              React.Children.toArray(children).map((x) => [
                x.props.value,
                x.props.children,
              ]),
            );
            setTitle(valueToText[value]);
          }
        }}
      >
        {({ measureRef }) => (
          <BareTextField
            select
            aria-label={ariaLabel || valueIfString(label)}
            SelectProps={{
              ref: (el) => {
                // reach in and capture the ref we need to measure
                if (el) {
                  if (!refCaptured) {
                    measureRef(el.children[0]);
                    setRefCaptured(true);
                  }
                }
              },
              ...SelectProps,
              value: value || "",
              SelectDisplayProps: {
                title,
                ...SelectProps?.SelectDisplayProps,
              },
            }}
            variant="outlined"
            {...props}
          >
            {children}
          </BareTextField>
        )}
      </Measure>
    </LabeledItem>
  );
}

export function SearchableSelect({
  required,
  label,
  labelComponent,
  value,
  SelectProps,
  onChange,
  children,
  ...props
}) {
  const [search, setSearch] = useState("");
  const nullVal = "__NULL__";

  return (
    <Autocomplete
      freeSolo={false}
      inputValue={search}
      onOpen={() => setSearch("")}
      onFocus={() => setSearch("")} // Preserves the same onOpen case when the user clicks on the input field.
      onInputChange={(e, v, r) => setSearch(v)}
      onChange={(e, v, r) =>
        onChange({ ...e, target: { value: v == nullVal ? null : v } })
      }
      getOptionLabel={(option) =>
        React.Children.toArray(children).filter(
          (x) => (x?.props.value || "__NULL__") == option,
        )[0]?.props.children
      }
      placeholder="search"
      required={required}
      label={label}
      labelComponent={labelComponent}
      value={value || nullVal}
      options={React.Children.map(children, (x) => x?.props.value || nullVal)}
      {...props}
    />
  );
}

export function Select({ searchable, children, ...props }) {
  return searchable && React.Children.count(children) > 10 ? (
    <SearchableSelect {...{ ...props }}>{children}</SearchableSelect>
  ) : (
    <BasicSelect {...{ ...props }}>{children}</BasicSelect>
  );
}

export default function Field({
  type,
  required,
  label,
  labelComponent,
  value,
  ...props
}) {
  switch (type) {
    case "select":
      return (
        <Select {...{ required, label, labelComponent, value, ...props }} />
      );
    case "text":
      return (
        <TextField {...{ required, label, labelComponent, value, ...props }} />
      );
    case "date":
      return (
        <DateField {...{ required, label, labelComponent, value, ...props }} />
      );
  }
  return <LabeledItem {...{ label, ...props }} />;
}
