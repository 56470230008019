/**
 * @generated SignedSource<<e85b376538c81ad2c1df612cb7c09ece>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommentsSectionContent_accountFragment$data = {
  readonly email: string;
  readonly uuid: string;
  readonly " $fragmentSpreads": FragmentRefs<"CommentComposer_accountFragment" | "CommentList_currentUserAccountFragment">;
  readonly " $fragmentType": "CommentsSectionContent_accountFragment";
};
export type CommentsSectionContent_accountFragment$key = {
  readonly " $data"?: CommentsSectionContent_accountFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommentsSectionContent_accountFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommentsSectionContent_accountFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uuid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommentComposer_accountFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommentList_currentUserAccountFragment"
    }
  ],
  "type": "AccountType",
  "abstractKey": null
};

(node as any).hash = "5b6a9010e65af27f9f0ce2d70e20e231";

export default node;
