/**
 * @generated SignedSource<<7b9c92ef65c6b8d40c3607ca2ffda0b1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CurrencyField_Query$variables = Record<PropertyKey, never>;
export type CurrencyField_Query$data = {
  readonly currencies: ReadonlyArray<string | null | undefined> | null | undefined;
};
export type CurrencyField_Query = {
  response: CurrencyField_Query$data;
  variables: CurrencyField_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currencies",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CurrencyField_Query",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CurrencyField_Query",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "9609feba727c61a9ca215c139b882972",
    "id": null,
    "metadata": {},
    "name": "CurrencyField_Query",
    "operationKind": "query",
    "text": "query CurrencyField_Query {\n  currencies\n}\n"
  }
};
})();

(node as any).hash = "398d13c13bd720111ff32d28ddc65ca3";

export default node;
