/**
 * @generated SignedSource<<493e2179ea1e763ef1cd53dd3d5542da>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateContractFromTemplateDialog_contractFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"UpdateContractFromTemplateDialogContent_contractFragment">;
  readonly " $fragmentType": "UpdateContractFromTemplateDialog_contractFragment";
};
export type UpdateContractFromTemplateDialog_contractFragment$key = {
  readonly " $data"?: UpdateContractFromTemplateDialog_contractFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UpdateContractFromTemplateDialog_contractFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpdateContractFromTemplateDialog_contractFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UpdateContractFromTemplateDialogContent_contractFragment"
    }
  ],
  "type": "ContractType",
  "abstractKey": null
};

(node as any).hash = "e6212e6fcda586396c452dbdd711aad5";

export default node;
