import React, { useEffect } from "react";
import { RichTextEditorProvider, RichTextField } from "mui-tiptap";
import { type Editor } from "@tiptap/core";
import {
  CHARACTER_COUNT_LIMIT,
  useExtensions,
  useReadOnlyExtensions,
} from "./useExtensions";
import Box from "@mui/material/Box";
import type { Content, JSONContent } from "@tiptap/core";
import { graphql } from "relay-runtime";
import type { CommentTextEditor_organizationFragment$key } from "./__generated__/CommentTextEditor_organizationFragment.graphql";
import { useFragment } from "react-relay";
import { RichTextReadOnly, useReadOnlyEditor } from "./RichTextReadOnly";
import { useEditor } from "@tiptap/react";
import { Typography, type Theme } from "@mui/material";
import { getInitialTransaction } from "./TruncateContentExtension";

const ORGANIZATION_FRAGMENT = graphql`
  fragment CommentTextEditor_organizationFragment on OrganizationType {
    ...useExtensions_organizationFragment
  }
`;

export { type Editor as Editor };

export type CommentTextEditorContent = {
  jsonContent: JSONContent;
  htmlContent: string;
  textContent: string;
};
type CommentTextEditorProps = {
  currentUserUuid: string;
  organizationFragRef: CommentTextEditor_organizationFragment$key;
  content?: Content;
  editable: boolean;
  renderFooter: (renderProps: {
    handleSubmit: () => void;
    editor: Editor | null;
  }) => React.ReactNode;
  placeholder?: string;
  onSubmit?: (content: CommentTextEditorContent, editor: Editor) => void;
};

export function CommentTextEditor(props: CommentTextEditorProps) {
  const organization = useFragment(
    ORGANIZATION_FRAGMENT,
    props.organizationFragRef,
  );
  const extensions = useExtensions(
    props.currentUserUuid,
    organization,
    props.placeholder,
  );
  const editor = useEditor({
    extensions,
    content: props.content,
  });

  const handleSubmit = () => {
    if (!editor) {
      return;
    }
    const content = {
      jsonContent: editor.getJSON() ?? "",
      htmlContent: editor.getHTML() ?? "",
      textContent: editor.getText() ?? "",
    };

    props.onSubmit?.(content, editor);
  };

  return (
    <Box
      sx={(theme: any) => ({
        "& .MuiTiptap-RichTextField-content": {
          minHeight: "120px",
          maxHeight: "200px",
          padding: "6px",
          overflow: "auto",
          // mui-tiptap uses our body1 theme for the editor content, which is bold so we override it
          "& .ProseMirror": {
            fontWeight: 400,
          },
          // keeps placeholder content message bolded
          "& .ProseMirror .is-editor-empty": {
            fontWeight: 700,
            minHeight: "100px",
          },
          '& .ProseMirror a[href^="mailto:"]': {
            color: theme.typography.body1.color,
            textDecoration: "none",

            "&:hover": {
              textDecoration: "underline",
            },
          },
        },
      })}
    >
      <RichTextEditorProvider editor={editor}>
        <RichTextField
          disabled={!props.editable}
          footer={props.renderFooter({
            handleSubmit,
            editor,
          })}
        />
      </RichTextEditorProvider>
    </Box>
  );
}

type CommentTextEditorReadOnlyProps = {
  content: Content;
};

export function CommentTextEditorReadOnly(
  props: CommentTextEditorReadOnlyProps,
) {
  const extensions = useReadOnlyExtensions();
  const editor = useReadOnlyEditor({
    extensions,
    content: props.content,
  });

  useEffect(() => {
    if (!editor) {
      return;
    }

    editor.view.dispatch(getInitialTransaction(editor.view));
  }, [editor]);

  if (!editor) {
    return null;
  }

  return (
    <Box
      sx={{
        "& .MuiTiptap-RichTextContent-readonly": {
          // mui-tiptap uses our body1 theme for the editor content, which is bold so we override it
          "& .ProseMirror": {
            fontWeight: 400,
          },
        },
      }}
    >
      <RichTextReadOnly
        editor={editor}
        data-testid="comment-text-editor-read-only-root"
      />
    </Box>
  );
}

type CharacterCountProps = { editor: Editor | null };
export function CharacterCount(props: CharacterCountProps) {
  const editor = props.editor;
  if (!editor) {
    return null;
  }

  const numCharacters = editor.storage.characterCount.characters();
  const remainingCharacters = CHARACTER_COUNT_LIMIT - numCharacters;
  if (remainingCharacters > 50) {
    return null;
  }

  return (
    <Typography
      sx={{ color: remainingCharacters < 15 ? "error.main" : "warning.main" }}
    >
      {numCharacters}/{CHARACTER_COUNT_LIMIT}
    </Typography>
  );
}
