type UserEmail = string;
export type UserAvatarColor = { backgroundColor: string; textColor: string };

const AVATAR_COLOR_OPTIONS = [
  {
    backgroundColor: "rgba(244, 67, 54, 0.6)",
    textColor: "rgba(244, 67, 54, 1)",
  },
  {
    backgroundColor: "rgba(233, 30, 99, 0.6)",
    textColor: "rgba(233, 30, 99, 1)",
  },
  {
    backgroundColor: "rgba(156, 39, 176, 0.6)",
    textColor: "rgba(156, 39, 176, 1)",
  },
  {
    backgroundColor: "rgba(103, 58, 183, 0.6)",
    textColor: "rgba(103, 58, 183, 1)",
  },
  {
    backgroundColor: "rgba(63, 81, 181, 0.6)",
    textColor: "rgba(63, 81, 181, 1)",
  },
  {
    backgroundColor: "rgba(0, 188, 212, 0.6)",
    textColor: "rgba(0, 188, 212, 1)",
  },
  {
    backgroundColor: "rgba(0, 150, 136, 0.6)",
    textColor: "rgba(0, 150, 136, 1)",
  },
  {
    backgroundColor: "rgba(76, 175, 80, 0.6)",
    textColor: "rgba(76, 175, 80, 1)",
  },
  {
    backgroundColor: "rgba(139, 195, 74, 0.6)",
    textColor: "rgba(139, 195, 74, 1)",
  },
  {
    backgroundColor: "rgba(205, 220, 57, 0.6)",
    textColor: "rgba(205, 220, 57, 1)",
  },
  {
    backgroundColor: "rgba(255, 235, 59, 0.6)",
    textColor: "rgba(255, 235, 59, 1)",
  },
  {
    backgroundColor: "rgba(255, 193, 7, 0.6)",
    textColor: "rgba(255, 193, 7, 1)",
  },
  {
    backgroundColor: "rgba(255, 152, 0, 0.6)",
    textColor: "rgba(255, 152, 0, 1)",
  },
  {
    backgroundColor: "rgba(255, 87, 34, 0.6)",
    textColor: "rgba(255, 87, 34, 1)",
  },
  {
    backgroundColor: "rgba(121, 85, 72, 0.6)",
    textColor: "rgba(121, 85, 72, 1)",
  },
  {
    backgroundColor: "rgba(158, 158, 158, 0.6)",
    textColor: "rgba(158, 158, 158, 1)",
  },
  {
    backgroundColor: "rgba(96, 125, 139, 0.6)",
    textColor: "rgba(96, 125, 139, 1)",
  },
] as const;

type CommentAuthor = { uuid: string };
type Comment = { author: CommentAuthor };

export function getUniqueAvatarColorsForCommentAuthors(
  comments: Comment[],
  currentUserUuid: string,
): AvatarColors {
  let avatarColors: AvatarColors = {};

  const commentAuthorUuids = [...new Set(comments.map((c) => c.author.uuid))];
  commentAuthorUuids.forEach((uuid, idx) => {
    if (uuid && uuid === currentUserUuid) {
      avatarColors[uuid] = CURRENT_USER_AVATAR_COLOR;
    } else {
      const color = AVATAR_COLOR_OPTIONS[idx % AVATAR_COLOR_OPTIONS.length];
      avatarColors[uuid] = color;
    }
  });
  return avatarColors;
}

export const CURRENT_USER_AVATAR_COLOR: UserAvatarColor = {
  backgroundColor: "rgba(33, 150, 243, 0.6)",
  textColor: "rgba(33, 150, 243, 1)",
};

export type AvatarColors = Record<UserEmail, UserAvatarColor>;

function toRGB(color: string) {
  const { style } = new Option();
  style.color = color;
  return style.color;
}
