/**
 * @generated SignedSource<<8e71b56b078847009876a22d2a533a4b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RelatedDocTitle_Mutation$variables = {
  title?: string | null | undefined;
  uuid: string;
};
export type RelatedDocTitle_Mutation$data = {
  readonly updateRelatedDoc: {
    readonly relatedDoc: {
      readonly " $fragmentSpreads": FragmentRefs<"RelatedDocTitle_fragment">;
    } | null | undefined;
  } | null | undefined;
};
export type RelatedDocTitle_Mutation = {
  response: RelatedDocTitle_Mutation$data;
  variables: RelatedDocTitle_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "title"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "uuid"
},
v2 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "title",
        "variableName": "title"
      },
      {
        "kind": "Variable",
        "name": "uuid",
        "variableName": "uuid"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "RelatedDocTitle_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "UpdateRelatedDocPayload",
        "kind": "LinkedField",
        "name": "updateRelatedDoc",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RelatedDocType",
            "kind": "LinkedField",
            "name": "relatedDoc",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "RelatedDocTitle_fragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "RelatedDocTitle_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "UpdateRelatedDocPayload",
        "kind": "LinkedField",
        "name": "updateRelatedDoc",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RelatedDocType",
            "kind": "LinkedField",
            "name": "relatedDoc",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "uuid",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "452d8f7af26e0f25561d89c344ca7594",
    "id": null,
    "metadata": {},
    "name": "RelatedDocTitle_Mutation",
    "operationKind": "mutation",
    "text": "mutation RelatedDocTitle_Mutation(\n  $uuid: String!\n  $title: String\n) {\n  updateRelatedDoc(input: {uuid: $uuid, title: $title}) {\n    relatedDoc {\n      ...RelatedDocTitle_fragment\n      id\n    }\n  }\n}\n\nfragment RelatedDocTitle_fragment on RelatedDocType {\n  id\n  uuid\n  title\n}\n"
  }
};
})();

(node as any).hash = "0f53c97b566d3cf4f32fa182ae26938f";

export default node;
