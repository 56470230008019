import React, { useState } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { TextField } from "~/components/Field";

export default React.forwardRef(
  ({ search, setSearch, style, ...props }, ref) => {
    return (
      <TextField
        placeholder="Search"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        value={search}
        onKeyDown={(e) => e.stopPropagation()}
        onClick={(e) => e.stopPropagation()}
        onChange={({ target: { value } }) => setSearch(value)}
        style={{ paddingLeft: "8px", paddingRight: "8px", ...style }}
        {...props}
      />
    );
  },
);
