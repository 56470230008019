/**
 * @generated SignedSource<<4b114352798952f7e175aa1301cce9a8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PDFViewerArea_accountFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PDFViewer_accountFragment">;
  readonly " $fragmentType": "PDFViewerArea_accountFragment";
};
export type PDFViewerArea_accountFragment$key = {
  readonly " $data"?: PDFViewerArea_accountFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PDFViewerArea_accountFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PDFViewerArea_accountFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PDFViewer_accountFragment"
    }
  ],
  "type": "AccountType",
  "abstractKey": null
};

(node as any).hash = "efa72ee3f74fd50f2799ddfb4a0a76b8";

export default node;
