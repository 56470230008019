/**
 * @generated SignedSource<<cf6ddb7fcbb8960b4e699140ed698491>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserCommentRevisionsDialog_contentEditRevisionFragment$data = {
  readonly actor: {
    readonly email: string;
    readonly uuid: string;
  };
  readonly dateCreated: string;
  readonly updatedHtmlContent: string;
  readonly uuid: string;
  readonly " $fragmentType": "UserCommentRevisionsDialog_contentEditRevisionFragment";
};
export type UserCommentRevisionsDialog_contentEditRevisionFragment$key = {
  readonly " $data"?: UserCommentRevisionsDialog_contentEditRevisionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserCommentRevisionsDialog_contentEditRevisionFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserCommentRevisionsDialog_contentEditRevisionFragment",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": "AccountType",
        "kind": "LinkedField",
        "name": "actor",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      },
      "action": "THROW",
      "path": "actor"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dateCreated",
      "storageKey": null
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "updatedHtmlContent",
        "storageKey": null
      },
      "action": "THROW",
      "path": "updatedHtmlContent"
    },
    (v0/*: any*/)
  ],
  "type": "CommentContentEditRevisionType",
  "abstractKey": null
};
})();

(node as any).hash = "74b237fb63ae4c8a99cf4b05fda2cec6";

export default node;
