import React from "react";
import { SvgIcon, type SvgIconProps } from "@mui/material";

export function ApproveIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        stroke="currentColor"
      >
        <path
          d="M20.337 3H5.35186C4.16956 3 3.21112 3.89543 3.21112 5V19C3.21112 20.1046 4.16956 21 5.35186 21H20.337C21.5193 21 22.4778 20.1046 22.4778 19V5C22.4778 3.89543 21.5193 3 20.337 3Z"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M9.63336 12L11.7741 14L16.0556 10"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </svg>
    </SvgIcon>
  );
}
