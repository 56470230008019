import add from "date-fns/add";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import startOfDay from "date-fns/startOfDay";
import { toOrganizationDate } from "../utils/organization";

export function getHasTerminationDatePassed(date, organizationTzName) {
  const terminationDateWithOrganizationTimezone = startOfDay(
    toOrganizationDate(date, organizationTzName ?? "US/Pacific"),
  );

  // Our termination date should be AT LEAST the next day because jobs are ran ~4 AM PT. We don't need this to be exact so one day gives us a good buffer to avoid issues. The customer should likely just set the renewal to their future date.
  const nextDay = startOfDay(add(new Date(), { days: 1 }));
  const hasTerminationDatePassed =
    terminationDateWithOrganizationTimezone < nextDay;
  return hasTerminationDatePassed;
}

/**
 * Checks if autoRenew is set for a given termination date.
 *
 * @param {Object} terminationDate - The termination date to check. Refer to our GraphQL ContractDateType.
 * @param {string|undefined} organizationTzName - The name of the organization's timezone, if it is set.
 * @return {type} A boolean indicating if the autoRenew flag is set.
 */
export function getIsAutoRenewSet(terminationDate, organizationTzName) {
  // TODO: remove this logic when CS-2811 is resolved
  // We override terminationDate.autoRenew value because of a bug with existing data. We allowed corrupt data to incorrectly set autoRenew to true even if the date was in the past. This can be confusing for customers because expired contracts are ignored during auto renewal processing. See [CS-2818](https://contractsafe.atlassian.net/browse/CS-2818) for more details
  const hasTerminationDatePassed = getHasTerminationDatePassed(
    terminationDate.date,
    organizationTzName,
  );
  const isAutoRenewSet = terminationDate.autoRenew && !hasTerminationDatePassed;
  return isAutoRenewSet;
}

/*
 * Returns the text for the autoRenew term.
 */
export const AUTORENEW_MAP = [
  { id: "MO", label: "Monthly" },
  { id: "QTR", label: "Quarterly" },
  { id: "SMA", label: "Semi-Annually" },
  { id: "YR", label: "Annually" },
  { id: "YR2", label: "Every 2 Years" },
  { id: "YR3", label: "Every 3 Years" },
  { id: "YR4", label: "Every 4 Years" },
  { id: "YR5", label: "Every 5 Years" },
  { id: "YR7", label: "Every 7 Years" },
  { id: "YR10", label: "Every 10 Years" },
  { id: "SPC", label: "Specific Date" },
];

/**
 * Generates auto-renewal term text based on the termination date and format.
 * @param {Object} terminationDate - The termination date object. See ContractDateType.
 * @param {string} dateFormat - The date format used for the organization.
 * @returns {string} The auto-renewal term text.
 */
export function getAutoRenewTermText(terminationDate, dateFormat) {
  const renewEveryText = {
    MO: "Monthly",
    QTR: "Quarterly",
    SMA: "Semi-Annually",
    YR: "Annually",
    YR2: "Every 2 Years",
    YR3: "Every 3 Years",
    YR4: "Every 4 Years",
    YR5: "Every 5 Years",
    YR7: "Every 7 Years",
    YR10: "Every 10 Years",
    SPC: terminationDate.renewDate
      ? format(parseISO(terminationDate.renewDate), dateFormat)
      : "",
  };
  return terminationDate.autoRenew
    ? ": " + renewEveryText[terminationDate.renewEvery]
    : "";
}
