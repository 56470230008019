import { List } from "immutable";
import React, { Suspense, useState, useEffect, useCallback } from "react";
import { commitLocalUpdate, graphql } from "react-relay";
import {
  useLazyLoadQuery,
  useFragment,
  fetchQuery,
  useRelayEnvironment,
} from "react-relay";
import useAsyncMutation from "~/utils/UseAsyncMutation";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import InputLabel from "@mui/material/InputLabel";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FileCopy from "@mui/icons-material/FileCopy";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import EditIcon from "@mui/icons-material/Edit";
import { AccordionPaper, AccordionPaperSummary, PaperProgress } from "./Paper";
import Pill from "./Pill";
import Button from "./Button";
import AddAttachmentDialog from "./AddAttachmentDialog";
import ConfirmDialog from "./ConfirmDialog";
import RelatedDocTitle from "./RelatedDocTitle";
import RelateInternalDialog from "./RelateInternalDialog";
import AmendedToDialog from "./AmendedToDialog";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import { dateToOrganizationFormat } from "~/core/contracts/fieldHelpers";
const titleLowercase = (doc) => (doc.title || "").toLowerCase();

function DeleteDialog({ open, handleClose, options }) {
  return (
    <ConfirmDialog
      title={options?.title}
      open={open}
      onClose={handleClose}
      onConfirm={options?.onConfirm}
      noText={options?.noText}
      yesText={options?.yesText}
    >
      {options?.message}
    </ConfirmDialog>
  );
}

export function RelatedDocSelection({
  selections,
  setSelections,
  contractUuid,
}) {
  const {
    contract: { amendedTo, relatedDocs, amendments },
  } = useLazyLoadQuery(
    graphql`
      query RelatedDocs_selectionQuery($contractUuid: String!) {
        contract(uuid: $contractUuid) {
          title
          amendedTo {
            uuid
            title
          }
          relatedDocs {
            uuid
            title
            internalContract {
              uuid
              title
            }
          }
          amendments {
            uuid
            title
          }
        }
      }
    `,
    { contractUuid },
  );

  const attachments = relatedDocs.filter((doc) => !doc.internalContract);

  const linkedDocs = relatedDocs.filter((doc) => doc.internalContract);

  function setSelection(uuid, selected) {
    if (selected) {
      setSelections(selections.add(uuid));
    } else {
      setSelections(selections.remove(uuid));
    }
  }

  return (
    <FormControl>
      {attachments.length ? (
        <>
          <FormLabel>Attachments</FormLabel>
          <FormGroup>
            {List(attachments)
              .sortBy(titleLowercase)
              .map((doc) => (
                <FormControlLabel
                  key={doc.uuid}
                  control={
                    <Checkbox
                      checked={selections.includes(doc.uuid)}
                      onChange={(e) => setSelection(doc.uuid, e.target.checked)}
                      name={doc.title}
                    />
                  }
                  label={
                    <Typography sx={{ fontWeight: "normal" }}>
                      {doc.title}
                    </Typography>
                  }
                />
              ))}
          </FormGroup>
        </>
      ) : null}

      {linkedDocs.length ? (
        <>
          <FormLabel>Linked Documents</FormLabel>
          <FormGroup>
            {List(linkedDocs)
              .sortBy(({ internalContract }) =>
                titleLowercase(internalContract),
              )
              .map(({ internalContract: doc }) => (
                <FormControlLabel
                  key={doc.uuid}
                  control={
                    <Checkbox
                      checked={selections.includes(doc.uuid)}
                      onChange={(e) => setSelection(doc.uuid, e.target.checked)}
                      name={doc.title}
                    />
                  }
                  label={
                    <Typography sx={{ fontWeight: "normal" }}>
                      {doc.title}
                    </Typography>
                  }
                />
              ))}
          </FormGroup>
        </>
      ) : null}

      {amendments.length ? (
        <>
          <FormLabel>Amendments</FormLabel>
          <FormGroup>
            {List(amendments)
              .sortBy(titleLowercase)
              .map((doc) => (
                <FormControlLabel
                  key={doc.uuid}
                  control={
                    <Checkbox
                      checked={selections.includes(doc.uuid)}
                      onChange={(e) => setSelection(doc.uuid, e.target.checked)}
                      name={doc.title}
                    />
                  }
                  label={
                    <Typography sx={{ fontWeight: "normal" }}>
                      {doc.title}
                    </Typography>
                  }
                />
              ))}
          </FormGroup>
        </>
      ) : null}

      {amendedTo ? (
        <>
          <FormLabel>Amended To</FormLabel>
          <FormGroup>
            <FormControlLabel
              key={amendedTo.uuid}
              control={
                <Checkbox
                  checked={selections.includes(amendedTo.uuid)}
                  onChange={(e) =>
                    setSelection(amendedTo.uuid, e.target.checked)
                  }
                  name={amendedTo.title}
                />
              }
              label={
                <Typography sx={{ fontWeight: "normal" }}>
                  {amendedTo.title}
                </Typography>
              }
            />
          </FormGroup>
        </>
      ) : null}
    </FormControl>
  );
}

function DocLink({ doc, dateFormat }) {
  const Component = doc.isarchived
    ? styled(Typography)({ color: "#d0d2d4", fontWeight: "normal" })
    : Link;
  return (
    <Component href={"/v3/contracts/" + doc.uuid} underline="hover">
      {doc?.title}

      {doc.counterparties[0]
        ? " - ".concat(doc.counterparties[0]?.party.companyName)
        : null}
      {doc.effectiveDate?.date
        ? " - ".concat(format_date_string(doc.effectiveDate.date, dateFormat))
        : ""}
    </Component>
  );
}

function LoadedRelatedDocs({
  contractUuid,
  onCommit,
  readOnly,
  dateFormat,
  canDownload,
  handleHasAttachments,
}) {
  const environment = useRelayEnvironment();
  const [deleteOpenFor, setDeleteOpenFor] = useState(null);
  const [internalRelatedOpenFor, setInternalRelatedOpenFor] = useState(false);
  const [amendmentOpen, setAmendmentOpen] = useState(false);
  const [dialogOptions, setDialogOptions] = useState(null);

  const { contract } = useLazyLoadQuery(
    graphql`
      query RelatedDocs_Query($contractUuid: String!) {
        contract(uuid: $contractUuid) {
          ...RelatedDocs_fragment
        }
      }
    `,
    { contractUuid },
  );

  const {
    title,
    amendedAndRestated,
    amendedTo,
    amendedToNamed,
    amendments,
    relatedDocs,
  } = useFragment(
    graphql`
      fragment RelatedDocs_fragment on ContractType {
        id
        title
        amendedAndRestated
        amendedTo {
          uuid
          title
          isarchived
          counterparties {
            uuid
            label
            party {
              uuid
              companyName
            }
          }
          effectiveDate {
            date
          }
        }
        amendedToNamed
        relatedDocs {
          uuid
          title
          ...RelatedDocTitle_fragment
          filename
          dateCreated
          relationType
          internalContract {
            id
            uuid
            title
            isarchived
            effectiveDate {
              date
            }
            counterparties {
              uuid
              label
              party {
                uuid
                companyName
              }
            }
          }
          isGeneratedRedlineDocument
        }
        amendments {
          uuid
          title
          isarchived
          dateCreated
          effectiveDate {
            date
          }
          counterparties {
            uuid
            label
            party {
              uuid
              companyName
            }
          }
          amendments {
            uuid
            title
            isarchived
            dateCreated
            effectiveDate {
              date
            }
            counterparties {
              uuid
              label
              party {
                uuid
                companyName
              }
            }
          }
        }
      }
    `,
    contract,
  );

  const [commitContract] = useAsyncMutation(graphql`
    mutation RelatedDocs_ContractMutation(
      $contractUuid: String!
      $agreementAmendment: Boolean
      $amendedAndRestated: Boolean
      $amendedToNamed: String
      $amendedToUuid: String
    ) {
      updateContract(
        input: {
          uuid: $contractUuid
          agreementAmendment: $agreementAmendment
          amendedAndRestated: $amendedAndRestated
          amendedToNamed: $amendedToNamed
          amendedToUuid: $amendedToUuid
        }
      ) {
        contract {
          ...RelatedDocs_fragment
        }
      }
    }
  `);

  const [commitAddAuditEntry] = useAsyncMutation(graphql`
    mutation RelatedDocs_AddAuditEntryMutation(
      $contractUuid: String!
      $objectType: String!
      $fieldName: String!
      $oldValue: String!
      $newValue: String!
    ) {
      addAuditEntry(
        input: {
          contractUuid: $contractUuid
          objectType: $objectType
          fieldName: $fieldName
          oldValue: $oldValue
          newValue: $newValue
        }
      ) {
        clientMutationId
      }
    }
  `);

  const [commitLinkedDelete] = useAsyncMutation(graphql`
    mutation RelatedDocs_DeleteMutation(
      $contractUuid: String!
      $relatedDocUuid: String!
    ) {
      removeRelatedDoc(
        input: { contractUuid: $contractUuid, relatedDocUuid: $relatedDocUuid }
      ) {
        contract {
          ...RelatedDocs_fragment
        }
        internalContract {
          uuid
          relatedDocs {
            uuid
          }
        }
      }
    }
  `);

  async function updateAmendedTo(variables) {
    await commitContract({ variables });
    commitLocalUpdate(environment, (store) => store.invalidateStore());
  }

  async function addDlAuditEntry() {
    await commitAddAuditEntry({
      variables: {
        contractUuid,
        objectType: "Download",
        fieldName: "Contract downloaded",
        oldValue: "",
        newValue: "",
      },
    });
  }

  const [addAttachmentOpen, setAddAttachmentOpen] = React.useState(false);

  const attachments = relatedDocs.filter((doc) => !doc.internalContract);
  const linkedDocs = relatedDocs.filter((doc) => doc.internalContract);

  async function handleDownload(relatedDoc, view) {
    var dlurl;
    const office = ["doc", "docx", "xls", "xlsx", "ppt", "pptx"];
    if (
      office.includes(relatedDoc.filename.split(".").pop().toLowerCase()) &&
      view
    ) {
      dlurl = "/v3/attch/" + relatedDoc.uuid + "/document";
    } else {
      dlurl =
        "/api/v1/filestream/" +
        relatedDoc.uuid +
        "/?file_role_type=attch" +
        (view ? "&view=1" : "&view=0");

      await addDlAuditEntry();
    }
    const a = document.createElement("a");
    console.log(dlurl);
    a.href = dlurl;
    a.target = "_blank";
    a.click();
  }

  async function removeRelated(relatedDocUuid) {
    setDeleteOpenFor(null);
    try {
      await commitLinkedDelete({
        variables: {
          contractUuid,
          relatedDocUuid,
        },
      });
      onCommit("Removed.");
    } catch (e) {
      onCommit(e.message);
    }
  }

  async function removeAmendement() {
    setDeleteOpenFor(null);
    try {
      await updateAmendedTo({
        contractUuid,
        agreementAmendment: false,
        amendedToUuid: null,
        amendedToNamed: null,
      });
      onCommit("Amendment removed.");
    } catch (e) {
      onCommit(e.message);
    }
  }

  const isAmendment = Boolean(amendedTo || amendedToNamed);
  const [expanded, setExpanded] = useState(
    !!(relatedDocs.length + amendments.length + isAmendment),
  );
  useEffect(() => {
    setExpanded(!!(relatedDocs.length + amendments.length + isAmendment));
    handleHasAttachments(attachments.length > 0 || false);
  }, [contractUuid, attachments.length]);

  const [commitExpand] = useAsyncMutation(graphql`
    mutation RelatedDocs_viewState_Mutation($uuid: String!, $value: Boolean) {
      updateViewState(
        input: {
          uuid: $uuid
          statename: "expandrelated"
          stateboolvalue: $value
        }
      ) {
        contract {
          detailViewState {
            expandrelated
          }
        }
      }
    }
  `);

  const handleExpanded = useCallback(
    async (value) => {
      setExpanded(value);
      await commitExpand({ variables: { uuid: contractUuid, value } });
    },
    [contractUuid],
  );

  function disableView(relatedDoc) {
    // get file extension (hopefully it's the last element if split by '.')
    var fileExt = relatedDoc.filename.split(".").pop();

    const viewables = [
      "pdf",
      "jpg",
      "jpeg",
      "png",
      "txt",
      "doc",
      "docx",
      "xls",
      "xlsx",
      "ppt",
      "pptx",
    ];

    // tiff is supported by safari, so don't disable if user is using Safari
    const uaStr = navigator.userAgent;
    if (
      fileExt == "tiff" &&
      uaStr.includes("Safari") &&
      !uaStr.includes("Chrome")
    ) {
      return false;
    }

    return !viewables.includes(fileExt);
  }

  function getPreviewInfo(doc) {
    if (doc.isGeneratedRedlineDocument) {
      return {
        isPreviewDisabled: true,
        previewTooltipTitle:
          "Please download your redline document to view the changes. Chat with us if you're interested in future support for viewing redline documents from your browser.",
      };
    } else if (disableView(doc)) {
      return {
        isPreviewDisabled: true,
        previewTooltipTitle:
          "You cannot preview this document in your current browser.",
      };
    } else if (readOnly && !canDownload) {
      return {
        isPreviewDisabled: true,
        previewTooltipTitle:
          "You do not have permission to preview this document.",
      };
    }
    return {
      isPreviewDisabled: false,
      previewTooltipTitle: "Preview your document in your current browser.",
    };
  }

  return (
    <AccordionPaper
      expanded={expanded}
      onChange={() => handleExpanded(!expanded)}
      id="related-docs-paper"
    >
      <AccordionPaperSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h5">
          <FileCopy /> Related Documents (
          {relatedDocs.length + amendments.length + isAmendment})
        </Typography>
      </AccordionPaperSummary>
      <AccordionDetails>
        {waffle.flag_is_active("related_docs_ui_v2") ? (
          <Grid container spacing={8}>
            <Grid item xs={12} style={{ marginTop: 12 }}>
              <Divider />
            </Grid>
            <Grid container item xs={12} rowSpacing={4}>
              <Grid
                container
                item
                xs={12}
                columnSpacing={4}
                alignItems="center"
              >
                <Grid item>
                  <Typography variant="h5">Attachments</Typography>
                </Grid>
                <Grid item>
                  <Button
                    primary
                    size="tiny"
                    onClick={() => setAddAttachmentOpen(true)}
                    disabled={readOnly}
                    id="add-attachment-button"
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                {attachments.length ? (
                  List(attachments)
                    .sortBy(titleLowercase)
                    .map((doc, idx) => {
                      const { isPreviewDisabled, previewTooltipTitle } =
                        getPreviewInfo(doc);

                      return (
                        <Grid container item key={doc.uuid} xs={12}>
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            width="100%"
                            sx={{
                              paddingY: 1,
                              borderBottom: "0.5px solid #d3d5d9",
                              maxWidth: { xs: "100%", lg: 700 },
                            }}
                          >
                            <Box sx={{ flexGrow: 1 }}>
                              <Stack>
                                <RelatedDocTitle
                                  relatedDoc={doc}
                                  onCommit={onCommit}
                                  disabled={readOnly}
                                />
                                <Typography
                                  marginRight={4}
                                  variant="body2"
                                  component="span"
                                  fontSize={12}
                                >
                                  Added{" "}
                                  {dateToOrganizationFormat(
                                    doc.dateCreated,
                                    dateFormat,
                                  )}
                                </Typography>
                              </Stack>
                            </Box>
                            <Stack
                              direction="row"
                              alignItems="center"
                              flexGrow={0}
                            >
                              <Button
                                variant="text"
                                size="medium"
                                label={
                                  <CloudDownloadIcon sx={{ fontSize: 30 }} />
                                }
                                onClick={() => handleDownload(doc, false)}
                                disabled={readOnly && !canDownload}
                                data-feature-id="download-attachment-button"
                                data-attachment-type={
                                  doc.isGeneratedRedlineDocument
                                    ? "redline"
                                    : "user-uploaded"
                                }
                              />
                              <Tooltip
                                title={previewTooltipTitle}
                                enterDelay={500}
                              >
                                <span>
                                  <Button
                                    variant="text"
                                    size="medium"
                                    label={
                                      <VisibilityIcon sx={{ fontSize: 30 }} />
                                    }
                                    onClick={() => handleDownload(doc, true)}
                                    disabled={isPreviewDisabled}
                                    style={{ marginLeft: 5 }}
                                    data-feature-id="preview-attachment-button"
                                    data-attachment-type={
                                      doc.isGeneratedRedlineDocument
                                        ? "redline"
                                        : "user-uploaded"
                                    }
                                  />
                                </span>
                              </Tooltip>
                              <Button
                                variant="text"
                                size="medium"
                                label={
                                  <DeleteForeverIcon sx={{ fontSize: 30 }} />
                                }
                                onClick={() => {
                                  setDialogOptions({
                                    title: "Delete Attachment?",
                                    message:
                                      "Are you sure you want to delete this attachment? Deleted attachments cannot be restored.",
                                    noText: "Cancel",
                                    yesText: "Delete",
                                    onConfirm: () => removeRelated(doc.uuid),
                                  });
                                  setDeleteOpenFor(doc);
                                }}
                                disabled={readOnly}
                                id="delete-attachment-button"
                              />
                            </Stack>
                          </Stack>
                        </Grid>
                      );
                    })
                ) : (
                  <Grid item xs={12}>
                    <Box>This contract doesn’t have any attachments.</Box>
                  </Grid>
                )}
              </Grid>
              <Grid
                container
                item
                xs={12}
                columnSpacing={4}
                alignItems="center"
              >
                <Grid item>
                  <Typography variant="h5">Linked Documents</Typography>
                </Grid>
                <Grid item>
                  <Button
                    primary={true}
                    size="tiny"
                    onClick={() => setInternalRelatedOpenFor(linkedDocs)}
                    disabled={readOnly}
                    id="add-linked-doc-button"
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                {linkedDocs.length ? (
                  List(linkedDocs)
                    .sortBy(({ internalContract }) =>
                      titleLowercase(internalContract),
                    )
                    .map((doc) => (
                      <Grid container item key={doc.uuid} xs={12}>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          width="100%"
                          sx={{
                            paddingY: 1,
                            borderBottom: "0.5px solid #d3d5d9",
                            maxWidth: { xs: "100%", lg: 700 },
                          }}
                        >
                          <Box sx={{ flexGrow: 1 }}>
                            <Stack>
                              <DocLink
                                doc={doc.internalContract}
                                dateFormat={dateFormat}
                              />
                              <Typography
                                marginRight={4}
                                variant="body2"
                                component="span"
                                fontSize={12}
                              >
                                Added{" "}
                                {dateToOrganizationFormat(
                                  doc.dateCreated,
                                  dateFormat,
                                )}
                              </Typography>
                            </Stack>
                          </Box>
                          <Stack
                            direction="row"
                            alignItems="center"
                            flexGrow={0}
                          >
                            <Button
                              variant="text"
                              size="medium"
                              label={<LinkOffIcon sx={{ fontSize: 30 }} />}
                              onClick={() => removeRelated(doc.uuid)}
                              disabled={readOnly}
                              style={{ marginLeft: 0 }}
                              id="unlink-doc-button"
                              aria-label="Unlink Document"
                            />
                          </Stack>
                        </Stack>
                      </Grid>
                    ))
                ) : (
                  <Grid item xs={12}>
                    <Box>This contract doesn’t have any linked documents.</Box>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={4} alignItems="center">
                  <Grid item xs={12}>
                    <Typography variant="h5">Amendments</Typography>
                  </Grid>
                  {isAmendment ? (
                    <Grid item xs={12}>
                      <Box>
                        Amendment to:{" "}
                        {amendedToNamed || (
                          <DocLink doc={amendedTo} dateFormat={dateFormat} />
                        )}
                        <IconButton
                          disableRipple
                          disableFocusRipple
                          onClick={() => setAmendmentOpen(true)}
                          size="small"
                          color="primary"
                          disabled={readOnly}
                          style={{ paddingTop: 0, paddingLeft: 6 }}
                        >
                          <EditIcon style={{ width: 20, height: 20 }} />
                        </IconButton>
                        <IconButton
                          variant="text"
                          size="small"
                          style={{ paddingTop: 0, paddingLeft: 6 }}
                          onClick={() => {
                            setDialogOptions({
                              message:
                                "Are you sure you want to remove this amendment?",
                              onConfirm: removeAmendement,
                            });
                            setDeleteOpenFor(amendedToNamed || amendedTo);
                          }}
                          disabled={readOnly}
                          id="remove-amendment-button"
                        >
                          <LinkOffIcon style={{ width: 20, height: 20 }} />
                        </IconButton>
                      </Box>
                    </Grid>
                  ) : null}
                  <Grid item xs={12}>
                    <Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isAmendment}
                            onChange={(e) =>
                              e.target.checked
                                ? setAmendmentOpen(true)
                                : updateAmendedTo({
                                    contractUuid,
                                    agreementAmendment: e.target.checked,
                                  })
                            }
                            name="Amendment"
                            disabled={readOnly}
                          />
                        }
                        label="Amendment"
                      />
                      {isAmendment ? (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={amendedAndRestated}
                              onChange={(e) =>
                                updateAmendedTo({
                                  contractUuid,
                                  amendedAndRestated: e.target.checked,
                                })
                              }
                              name="Amended and restated"
                              disabled={readOnly}
                            />
                          }
                          label="Amended and restated"
                        />
                      ) : null}
                    </Box>
                  </Grid>
                  {amendments.length ? (
                    <>
                      <Grid item xs={12}>
                        <Box>Amendments to this document</Box>
                      </Grid>
                      {List(amendments)
                        .sortBy(titleLowercase)
                        .map((doc) => (
                          <>
                            <Grid item key={doc.uuid} xs={12}>
                              <Box pl={7}>
                                <DocLink doc={doc} dateFormat={dateFormat} />
                              </Box>
                              {doc.amendments.length
                                ? doc.amendments.map((subdoc) => (
                                    <Box key={subdoc.uuid} pl={10}>
                                      <DocLink
                                        doc={subdoc}
                                        dateFormat={dateFormat}
                                      />
                                    </Box>
                                  ))
                                : null}
                            </Grid>
                          </>
                        ))}
                    </>
                  ) : null}
                </Grid>

                {internalRelatedOpenFor ? (
                  <RelateInternalDialog
                    openFor={internalRelatedOpenFor}
                    setOpenFor={setInternalRelatedOpenFor}
                    onCommit={onCommit}
                    contractUuid={contractUuid}
                    dateFormat={dateFormat}
                  />
                ) : null}
                {amendmentOpen ? (
                  <AmendedToDialog
                    open={amendmentOpen}
                    setOpen={setAmendmentOpen}
                    amendedToNamed={amendedToNamed}
                    updateAmendedTo={updateAmendedTo}
                    onCommit={onCommit}
                    contractUuid={contractUuid}
                    dateFormat={dateFormat}
                    amendedtoUuid={amendedTo?.uuid}
                  />
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={8}>
            <Grid item xs={12} style={{ marginTop: 12 }}>
              <Divider />
            </Grid>
            <Grid container item xs={12} spacing={4}>
              <Grid container item xs={12} spacing={4} alignItems="center">
                <Grid item>
                  <Typography variant="h5">Attachments</Typography>
                </Grid>
                <Grid item>
                  <Button
                    primary
                    size="tiny"
                    onClick={() => setAddAttachmentOpen(true)}
                    disabled={readOnly}
                    id="add-attachment-button"
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
              {attachments.length ? (
                List(attachments)
                  .sortBy(titleLowercase)
                  .map((doc) => {
                    const { isPreviewDisabled, previewTooltipTitle } =
                      getPreviewInfo(doc);

                    return (
                      <Grid item key={doc.uuid} xs={12}>
                        <Grid container spacing={3}>
                          <Grid item xs={6}>
                            <Box>
                              <RelatedDocTitle
                                relatedDoc={doc}
                                onCommit={onCommit}
                                disabled={readOnly}
                              />
                            </Box>
                          </Grid>
                          <Grid item style={{ flexGrow: "1" }}>
                            Added{" "}
                            {dateToOrganizationFormat(
                              doc.dateCreated,
                              dateFormat,
                            )}
                          </Grid>
                          <Grid
                            container
                            item
                            xs="auto"
                            style={{ marginTop: -2 }}
                            alignItems="flex-start"
                            justifyContent="flex-end"
                          >
                            <Button
                              variant="text"
                              size="tiny"
                              label={<CloudDownloadIcon />}
                              onClick={() => handleDownload(doc, false)}
                              disabled={readOnly && !canDownload}
                              data-feature-id="download-attachment-button"
                              data-attachment-type={
                                doc.isGeneratedRedlineDocument
                                  ? "redline"
                                  : "user-uploaded"
                              }
                              aria-label="Download Attachment"
                            />
                            <Tooltip
                              title={previewTooltipTitle}
                              enterDelay={500}
                            >
                              <span>
                                <Button
                                  variant="text"
                                  size="tiny"
                                  label={<VisibilityIcon />}
                                  onClick={() => handleDownload(doc, true)}
                                  disabled={isPreviewDisabled}
                                  style={{ marginLeft: 5 }}
                                  data-feature-id="preview-attachment-button"
                                  data-attachment-type={
                                    doc.isGeneratedRedlineDocument
                                      ? "redline"
                                      : "user-uploaded"
                                  }
                                  aria-label="Preview Attachment"
                                />
                              </span>
                            </Tooltip>
                            <Button
                              variant="text"
                              size="tiny"
                              label={<DeleteForeverIcon />}
                              onClick={() => {
                                setDialogOptions({
                                  title: "Delete Attachment?",
                                  message:
                                    "Are you sure you want to delete this attachment? Deleted attachments cannot be restored.",
                                  noText: "Cancel",
                                  yesText: "Delete",
                                  onConfirm: () => removeRelated(doc.uuid),
                                });
                                setDeleteOpenFor(doc);
                              }}
                              disabled={readOnly}
                              style={{ marginLeft: 5 }}
                              id="delete-attachment-button"
                              aria-label="Delete Attachment"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })
              ) : (
                <Grid item xs={12}>
                  <Box>This contract doesn’t have any attachments.</Box>
                </Grid>
              )}
              <Grid container item xs={12} spacing={4} alignItems="center">
                <Grid item>
                  <Typography variant="h5">Linked Documents</Typography>
                </Grid>
                <Grid item>
                  <Button
                    primary={true}
                    size="tiny"
                    onClick={() => setInternalRelatedOpenFor(linkedDocs)}
                    disabled={readOnly}
                    id="add-linked-doc-button"
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
              {linkedDocs.length ? (
                List(linkedDocs)
                  .sortBy(({ internalContract }) =>
                    titleLowercase(internalContract),
                  )
                  .map((doc) => (
                    <Grid item key={doc.uuid} xs={12}>
                      <Grid container spacing={3}>
                        <Grid item xs={6}>
                          <Box>
                            <DocLink
                              doc={doc.internalContract}
                              dateFormat={dateFormat}
                            />
                          </Box>
                        </Grid>
                        <Grid item style={{ flexGrow: "1" }}>
                          Added{" "}
                          {dateToOrganizationFormat(
                            doc.dateCreated,
                            dateFormat,
                          )}
                        </Grid>
                        <Grid
                          container
                          item
                          xs="auto"
                          style={{ marginTop: -2 }}
                          alignItems="flex-start"
                          justifyContent="flex-end"
                        >
                          <Button
                            variant="text"
                            size="tiny"
                            label={<LinkOffIcon />}
                            onClick={() => removeRelated(doc.uuid)}
                            disabled={readOnly}
                            style={{ marginLeft: 0 }}
                            id="unlink-doc-button"
                            aria-label="Unlink Document"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  ))
              ) : (
                <Grid item xs={12}>
                  <Box>This contract doesn’t have any linked documents.</Box>
                </Grid>
              )}
              <Grid item xs={12}>
                <Grid container spacing={4} alignItems="center">
                  <Grid item xs={12}>
                    <Typography variant="h5">Amendments</Typography>
                  </Grid>
                  {isAmendment ? (
                    <Grid item xs={12}>
                      <Box>
                        Amendment to:{" "}
                        {amendedToNamed || (
                          <DocLink doc={amendedTo} dateFormat={dateFormat} />
                        )}
                        <IconButton
                          disableRipple
                          disableFocusRipple
                          onClick={() => setAmendmentOpen(true)}
                          size="small"
                          color="primary"
                          disabled={readOnly}
                          style={{ paddingTop: 0, paddingLeft: 6 }}
                        >
                          <EditIcon style={{ width: 20, height: 20 }} />
                        </IconButton>
                        <IconButton
                          variant="text"
                          size="small"
                          style={{ paddingTop: 0, paddingLeft: 6 }}
                          onClick={() => {
                            setDialogOptions({
                              message:
                                "Are you sure you want to remove this amendment?",
                              onConfirm: removeAmendement,
                            });
                            setDeleteOpenFor(amendedToNamed || amendedTo);
                          }}
                          disabled={readOnly}
                          id="remove-amendment-button"
                        >
                          <LinkOffIcon style={{ width: 20, height: 20 }} />
                        </IconButton>
                      </Box>
                    </Grid>
                  ) : null}
                  <Grid item xs={12}>
                    <Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isAmendment}
                            onChange={(e) =>
                              e.target.checked
                                ? setAmendmentOpen(true)
                                : updateAmendedTo({
                                    contractUuid,
                                    agreementAmendment: e.target.checked,
                                  })
                            }
                            name="Amendment"
                            disabled={readOnly}
                          />
                        }
                        label="Amendment"
                      />
                      {isAmendment ? (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={amendedAndRestated}
                              onChange={(e) =>
                                updateAmendedTo({
                                  contractUuid,
                                  amendedAndRestated: e.target.checked,
                                })
                              }
                              name="Amended and restated"
                              disabled={readOnly}
                            />
                          }
                          label="Amended and restated"
                        />
                      ) : null}
                    </Box>
                  </Grid>
                  {amendments.length ? (
                    <>
                      <Grid item xs={12}>
                        <Box>Amendments to this document</Box>
                      </Grid>
                      {List(amendments)
                        .sortBy(titleLowercase)
                        .map((doc) => (
                          <>
                            <Grid item key={doc.uuid} xs={12}>
                              <Box pl={7}>
                                <DocLink doc={doc} dateFormat={dateFormat} />
                              </Box>
                              {doc.amendments.length
                                ? doc.amendments.map((subdoc) => (
                                    <Box key={subdoc.uuid} pl={10}>
                                      <DocLink
                                        doc={subdoc}
                                        dateFormat={dateFormat}
                                      />
                                    </Box>
                                  ))
                                : null}
                            </Grid>
                          </>
                        ))}
                    </>
                  ) : null}
                </Grid>

                {internalRelatedOpenFor ? (
                  <RelateInternalDialog
                    openFor={internalRelatedOpenFor}
                    setOpenFor={setInternalRelatedOpenFor}
                    onCommit={onCommit}
                    contractUuid={contractUuid}
                    dateFormat={dateFormat}
                  />
                ) : null}
                {amendmentOpen ? (
                  <AmendedToDialog
                    open={amendmentOpen}
                    setOpen={setAmendmentOpen}
                    amendedToNamed={amendedToNamed}
                    updateAmendedTo={updateAmendedTo}
                    onCommit={onCommit}
                    contractUuid={contractUuid}
                    dateFormat={dateFormat}
                    amendedtoUuid={amendedTo?.uuid}
                  />
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        )}
      </AccordionDetails>
      <AddAttachmentDialog
        open={addAttachmentOpen}
        onClose={(success, message) => {
          setAddAttachmentOpen(false);
          if (success) {
            // The new attachment was added using the REST API, so Relay's data
            // was not updated. Doing a no-op contract mutation reloads the
            // contract to find the new attachment.
            updateAmendedTo({ contractUuid });
            onCommit();
          } else if (message) {
            onCommit(message);
          }
        }}
        contractUuid={contractUuid}
      />
      <DeleteDialog
        contractUuid={contractUuid}
        relatedDoc={deleteOpenFor}
        options={dialogOptions}
        open={Boolean(deleteOpenFor)}
        handleClose={() => setDeleteOpenFor(null)}
      />
    </AccordionPaper>
  );
}

export default function RelatedDocs(props) {
  return (
    <Suspense fallback={<PaperProgress />}>
      <LoadedRelatedDocs {...props} />
    </Suspense>
  );
}
