import React, { Suspense, useState } from "react";
import { graphql, useFragment, useLazyLoadQuery } from "react-relay";
import useAsyncMutation from "~/utils/UseAsyncMutation";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import ClearIcon from "@mui/icons-material/Clear";
import LoopIcon from "@mui/icons-material/Loop";
import SearchIcon from "@mui/icons-material/Search";
import {
  IconButton,
  InputAdornment,
  Popover,
  Radio,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Button from "~/components/Button";
import { InputLabel } from "~/components/Field";
import MessageDialog from "./MessageDialog";
import { track } from "~/analytics/events";

const SUCCESS_MESSAGE = `We are generating your redline.`;
const SUCCESS_MESSAGE_DESCRIPTION = `When it is complete you'll receive it as an email, and it will be stored for reference as an Attachment on this Contract Page.`;

const WrappingInputLabel = styled(InputLabel)({
  height: "initial !important",
  width: "initial !important",
  whiteSpace: "normal",
  overflowWrap: "anywhere",
  wordBreak: "break-word",
});

const GroupInputLabel = styled(WrappingInputLabel)({
  fontWeight: "normal",
});

const StyledPopover = styled(Popover)`
  ul {
    padding: 16px;
  }

  li {
    margin-left: 8px;
  }
`;

const Selector = ({
  hasRelated,
  relatedDoc,
  relatedDocs,
  amendments,
  amendedTo,
  handleSelect,
  setIsSelectMode,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const onSelect = (val) => {
    handleSelect(val);
    handlePopoverClose();
  };

  const relatedDocsExt = relatedDocs.filter(
    ({ relationType }) => relationType === "ext",
  );
  const relatedDocsInt = relatedDocs.filter(
    ({ relationType }) => relationType === "int",
  );

  return !hasRelated ? (
    <Button
      variant="text"
      color="primary"
      style={{ marginLeft: "4px" }}
      onClick={() => setIsSelectMode(true)}
    >
      {relatedDoc ? relatedDoc.title : "Select"}
    </Button>
  ) : (
    <>
      <Button
        variant="text"
        color="primary"
        style={{ marginLeft: "4px" }}
        onClick={handlePopoverClick}
      >
        {relatedDoc ? (
          relatedDoc.title
        ) : (
          <>
            Select
            <ArrowDropDown />
          </>
        )}
      </Button>
      <StyledPopover
        style={{ padding: "16px" }}
        id={"redline-id"}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <ul>
          {!!relatedDocsExt.length && <div>Attachments:</div>}
          {relatedDocsExt.map((doc) => (
            <li key={doc.id}>
              <Button
                variant="text"
                color="primary"
                onClick={() => onSelect(doc)}
              >
                {doc.title}
              </Button>
            </li>
          ))}
          {!!relatedDocsInt.length && <div>Linked Docs:</div>}
          {relatedDocsInt.map((doc) => (
            <li key={doc.id}>
              <Button
                variant="text"
                color="primary"
                onClick={() => onSelect(doc.internalContract)}
              >
                {doc.internalContract.title}
              </Button>
            </li>
          ))}
          {!!amendments.length && <div>Amendments:</div>}
          {amendments.map((doc) => (
            <li key={doc.id}>
              <Button
                variant="text"
                color="primary"
                onClick={() => onSelect(doc)}
              >
                {doc.title}
              </Button>
            </li>
          ))}
          {amendedTo && <div>Amended To:</div>}
          {amendedTo && (
            <li>
              <Button
                variant="text"
                color="primary"
                onClick={() => onSelect(amendedTo)}
              >
                {amendedTo.title}
              </Button>
            </li>
          )}
          <div>Other contracts in my account:</div>
          <li>
            <Button
              variant="text"
              color="primary"
              onClick={() => setIsSelectMode(true)}
            >
              Select
            </Button>
          </li>
        </ul>
      </StyledPopover>
    </>
  );
};

function ContractSearch({ queryArgs, handleChange, dateFormat, relatedDoc }) {
  const { relatedcontracts } = useLazyLoadQuery(
    graphql`
      query RedlineDialog_Query(
        $contractUuid: String!
        $searchParams: String!
        $loadAll: Boolean!
      ) {
        relatedcontracts(
          ctUuid: $contractUuid
          searchParams: $searchParams
          loadall: $loadAll
        ) {
          id
          uuid
          title
          counterparty
          effectiveDate {
            date
          }
        }
      }
    `,
    queryArgs.variables,
    queryArgs.options,
    {
      notifyOnNetworkStatusChange: true,
    },
  );

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        id="relate-search-results"
      >
        {!relatedcontracts?.length ? (
          <p style={{ textAlign: "center", marginTop: "14px" }}>
            No documents found.
          </p>
        ) : (
          relatedcontracts?.map((ct) => (
            <Grid item key={ct.uuid} xs={12}>
              <FormControlLabel
                control={
                  <Radio
                    disableRipple
                    id="testchecks"
                    checked={relatedDoc && relatedDoc.uuid === ct.uuid}
                    onChange={(e) => handleChange(e, ct)}
                    size="small"
                  />
                }
                label={
                  <GroupInputLabel>
                    {ct.title}
                    {ct.counterparty ? " - ".concat(ct.counterparty) : null}
                    {ct.effectiveDate?.date
                      ? " - ".concat(
                          format_date_string(ct.effectiveDate.date, dateFormat),
                        )
                      : ""}
                  </GroupInputLabel>
                }
              />
            </Grid>
          ))
        )}
      </Grid>
    </>
  );
}

export default function RedlineDialog({ open, onClose, contract, dateFormat }) {
  const [isSelectMode, setIsSelectMode] = useState(false);
  const [isSwapped, setIsSwapped] = useState(false);
  const [relatedDoc, setRelatedDoc] = useState(null);
  const [searchParams, setSearchParams] = useState(null);
  const [loadAll, setLoadAll] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const { uuid, title, amendedTo, relatedDocs, amendments } = useFragment(
    graphql`
      fragment RedlineDialog_fragment on ContractType {
        uuid
        id
        title
        amendedTo {
          id
          title
          uuid
        }
        relatedDocs {
          id
          title
          dateCreated
          internalContract {
            uuid
            title
          }
          relationType
          uuid
        }
        amendments {
          id
          uuid
          title
        }
      }
    `,
    contract,
  );

  const hasRelated = relatedDocs.length || amendments.length || amendedTo;
  const contractUuid = uuid;

  const [queryArgs, setQueryArgs] = useState({
    options: { fetchKey: 0 },
    variables: {
      contractUuid,
      searchParams: "",
      loadAll: false,
    },
  });

  async function handleSave() {
    try {
      const res = await prepareRedline({
        variables: !isSwapped
          ? {
              initialUuid: uuid,
              updateUuid: relatedDoc.uuid,
            }
          : {
              initialUuid: relatedDoc.uuid,
              updateUuid: uuid,
            },
      });

      track("Contract Redline Created");

      setShowSuccess(true);
    } catch (e) {
      console.log(e);
    }
  }

  function handleChange(event, value) {
    if (event.target.checked) {
      setIsSelectMode(false);
      setRelatedDoc(value);
    }
  }

  function searchRelated(getall, reset = false) {
    setQueryArgs((prev) => ({
      options: {
        fetchKey: (prev?.fetchKey ?? 0) + 1,
      },
      variables: {
        contractUuid,
        searchParams: reset ? "" : searchParams,
        loadAll: getall || false,
      },
    }));
  }

  const [prepareRedline] = useAsyncMutation(graphql`
    mutation RedlineDialog_prepareRedlineMutation(
      $initialUuid: String!
      $updateUuid: String!
    ) {
      prepareRedline(
        input: { initialUuid: $initialUuid, updateUuid: $updateUuid }
      ) {
        taskId
      }
    }
  `);

  const handleClose = () => {
    onClose();
    setIsSelectMode(false);
    setIsSwapped(false);
    setRelatedDoc(null);
    setSearchParams("");
    setLoadAll(false);
    setShowSuccess(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {isSelectMode ? "Select file" : "Generate redline"}
      </DialogTitle>
      {isSelectMode && (
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          style={{ paddingLeft: 14, paddingRightt: 14 }}
        >
          <Grid item xs={10}>
            <TextField
              name="searchtext"
              id="searchtext"
              fullWidth
              value={searchParams || ""}
              onChange={({ target: { value } }) => setSearchParams(value)}
              onKeyDown={(e) => {
                if (e.keyCode == 13) {
                  searchRelated();
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton
                      onClick={() => {
                        setSearchParams("");
                        searchRelated(false, true);
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                    <IconButton onClick={() => searchRelated()}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      )}
      <DialogContent style={{ minHeight: "126px", maxHeight: "390px" }}>
        {!isSelectMode ? (
          <>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={3}
            >
              <Grid item xs={9}>
                {!isSwapped ? (
                  <>
                    <Box>
                      <b>Original:</b> {title}
                    </Box>
                    <Box display={"flex"} alignItems={"center"}>
                      <b>Comparison doc:</b>{" "}
                      <Selector
                        hasRelated={hasRelated}
                        relatedDocs={relatedDocs}
                        amendments={amendments}
                        amendedTo={amendedTo}
                        setIsSelectMode={setIsSelectMode}
                        handleSelect={setRelatedDoc}
                        contract={contract}
                        relatedDoc={relatedDoc}
                      />
                    </Box>
                  </>
                ) : (
                  <>
                    <Box>
                      <b>Original:</b>{" "}
                      <Selector
                        hasRelated={hasRelated}
                        relatedDocs={relatedDocs}
                        amendments={amendments}
                        amendedTo={amendedTo}
                        setIsSelectMode={setIsSelectMode}
                        handleSelect={setRelatedDoc}
                        contract={contract}
                        relatedDoc={relatedDoc}
                      />
                    </Box>
                    <Box>
                      <b>Comparison doc:</b> {title}
                    </Box>
                  </>
                )}
              </Grid>
              <Grid item xs={3}>
                <Button onClick={() => setIsSwapped(!isSwapped)}>
                  <LoopIcon />
                  Swap
                </Button>
              </Grid>
            </Grid>
          </>
        ) : (
          <Suspense
            fallback={
              <Box style={{ textAlign: "center", width: "100%" }}>
                <CircularProgress style={{ marginTop: "20px" }} />
              </Box>
            }
          >
            <ContractSearch
              queryArgs={queryArgs}
              contract={contract}
              handleChange={handleChange}
              relatedDoc={relatedDoc}
              dateFormat={dateFormat}
            />
          </Suspense>
        )}
      </DialogContent>
      <DialogActions>
        <Button size="small" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          size="small"
          disabled={!relatedDoc}
          onClick={handleSave}
          primary
        >
          Generate redline
        </Button>
      </DialogActions>
      <MessageDialog
        open={showSuccess}
        title={SUCCESS_MESSAGE}
        onClose={handleClose}
      >
        {SUCCESS_MESSAGE_DESCRIPTION}
      </MessageDialog>
    </Dialog>
  );
}
