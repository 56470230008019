import React, { Suspense, useState, useLayoutEffect, useEffect } from "react";
import { useLocation, Outlet } from "react-router";
import { commitLocalUpdate, fetchQuery, commitMutation } from "react-relay";
import * as Sentry from "@sentry/react";
import { useIdleTimer } from "react-idle-timer";

import environment from "~/RelayEnvironment";
import { ViewContextProvider } from "~/ViewContext";
import { logout } from "~/utils/auth";
import { SnackbarProvider } from "~/components/Snackbar";
import { useAuthentication } from "~/components/Authentication";
import BaselineThemed from "./BaselineThemed";

const NavBar = React.lazy(() => import("~/components/NavBar"));

function ScrollBodyToTop() {
  const { pathname } = useLocation();
  useLayoutEffect(() => {
    document.body.scrollTop = 0;
  }, [pathname]);

  return null;
}

export default function Main() {
  const { account, organization } = useAuthentication();

  useEffect(() => {
    // Set organization and user info in the sentry tags.
    // Also include breadcrumbs in case the user or organization change,
    // so that we can see what else it has been in the transaction.
    Sentry.setUser(
      account?.email ? { email: account.email, ip_address: "{{auto}}" } : null,
    );
    Sentry.setTag("invitation.role", account?.role);
    Sentry.setTag("organization.uuid", organization?.uuid);

    Sentry.setContext(
      "invitation",
      account?.email ? { email: account.email } : null,
    );
    Sentry.setContext(
      "organization",
      organization?.uuid ? { uuid: organization.uuid } : null,
    );

    Sentry.addBreadcrumb({
      category: "auth",
      message: "Account scope set",
      level: "info",
      data: {
        account: account?.email ? { email: account.email } : null,
        organization: organization?.uuid
          ? { uuid: organization.uuid }
          : undefined,
      },
    });
  }, [account?.email, account?.role, organization?.uuid]);

  const logoutInMinutes = Math.min(account?.logoutInMinutes || 45, 35791);

  useIdleTimer({
    onIdle: () => {
      Sentry.addBreadcrumb({
        category: "auth",
        message: "Inactivity timeout",
        level: "info",
        data: {
          account: account?.email ? { email: account.email } : undefined,
          organization: organization?.uuid
            ? { uuid: organization?.uuid }
            : undefined,
        },
      });
      console.log("inactivity timeout:", logoutInMinutes);
      console.log("isloggedin: " + !!account?.email);
      if (account?.isAuthenticated) {
        logout(false);
      }
    },
    timeout: logoutInMinutes * 60 * 1000,
  });

  if (
    account?.role == "intk_only" &&
    window.location?.pathname !== "/login" &&
    !window.location.pathname.includes("/intake") &&
    !window.location.pathname.includes("/template")
  ) {
    window.location = "/intake";
    return null;
  }

  return (
    <ViewContextProvider organization={organization}>
      <SnackbarProvider>
        <BaselineThemed>
          <NavBar />
        </BaselineThemed>
        <Suspense fallback={<div />}>
          <Outlet />
        </Suspense>
        <ScrollBodyToTop />
      </SnackbarProvider>
    </ViewContextProvider>
  );
}
