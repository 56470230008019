import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { propsToClassKey } from "@mui/styles";
import React from "react";

type UpdateContractFromTemplateDialogActionsProps = {
  errorMessage?: string | null;
  children?: React.ReactNode;
};

export function UpdateContractFromTemplateDialogActions(
  props: UpdateContractFromTemplateDialogActionsProps,
) {
  return (
    <DialogActions sx={{ paddingX: 8, paddingY: 4 }}>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        gap={2}
      >
        <Box
          sx={{
            flexShrink: 1,
            alignSelf: { xs: "flex-start", sm: "center" },
            paddingBottom: 4,
          }}
        >
          {props.errorMessage && (
            <Typography color="error">{props.errorMessage}</Typography>
          )}
        </Box>
        <Stack
          gap={2}
          direction={{
            xs: "row-reverse",
            sm: "row",
          }}
          sx={{
            flexShrink: 0,
            alignSelf: { xs: "flex-end", sm: "center" },
          }}
        >
          {props.children}
        </Stack>
      </Stack>
    </DialogActions>
  );
}
