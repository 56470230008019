/**
 * @generated SignedSource<<4a436b25a4947816ea5465d7be283c62>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RelatedDocTitle_fragment$data = {
  readonly id: string;
  readonly title: string | null | undefined;
  readonly uuid: string;
  readonly " $fragmentType": "RelatedDocTitle_fragment";
};
export type RelatedDocTitle_fragment$key = {
  readonly " $data"?: RelatedDocTitle_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"RelatedDocTitle_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RelatedDocTitle_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uuid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "type": "RelatedDocType",
  "abstractKey": null
};

(node as any).hash = "1cf36b2070443c2411d438ff70bc92c2";

export default node;
