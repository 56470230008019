/**
 * @generated SignedSource<<d4ed3a66211e5ffc8b4269bed7e50ee6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type mentionSuggestionOptions_organizationFragment$data = {
  readonly accounts: ReadonlyArray<{
    readonly email: string;
    readonly uuid: string;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "mentionSuggestionOptions_organizationFragment";
};
export type mentionSuggestionOptions_organizationFragment$key = {
  readonly " $data"?: mentionSuggestionOptions_organizationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"mentionSuggestionOptions_organizationFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "mentionSuggestionOptions_organizationFragment"
};

(node as any).hash = "a5960187df3c677e65d4d8e6a9b95641";

export default node;
