/**
 * @generated SignedSource<<9b9d3cb20c17b8b8a7f78d60b1d81f85>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ContractForms_changeCurrencyMutation$variables = {
  currencySymbol: string;
  uuid: string;
};
export type ContractForms_changeCurrencyMutation$data = {
  readonly updateContract: {
    readonly contract: {
      readonly currencySymbol: string | null | undefined;
      readonly id: string;
    } | null | undefined;
  } | null | undefined;
};
export type ContractForms_changeCurrencyMutation = {
  response: ContractForms_changeCurrencyMutation$data;
  variables: ContractForms_changeCurrencyMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "currencySymbol"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "uuid"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "currencySymbol",
            "variableName": "currencySymbol"
          },
          {
            "kind": "Variable",
            "name": "uuid",
            "variableName": "uuid"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "UpdateContractPayload",
    "kind": "LinkedField",
    "name": "updateContract",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ContractType",
        "kind": "LinkedField",
        "name": "contract",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currencySymbol",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ContractForms_changeCurrencyMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ContractForms_changeCurrencyMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "8060f8b80f08615cce16afc3bcee7a11",
    "id": null,
    "metadata": {},
    "name": "ContractForms_changeCurrencyMutation",
    "operationKind": "mutation",
    "text": "mutation ContractForms_changeCurrencyMutation(\n  $uuid: String!\n  $currencySymbol: String!\n) {\n  updateContract(input: {uuid: $uuid, currencySymbol: $currencySymbol}) {\n    contract {\n      id\n      currencySymbol\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b985d4d0a953908bb23223fd6523043e";

export default node;
