import { Set } from "immutable";
import React, { useState, useCallback, Suspense } from "react";
import { graphql, useFragment } from "react-relay";
import useAsyncMutation from "~/utils/UseAsyncMutation";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import LinearProgress from "@mui/material/LinearProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { InputLabel, TextField } from "~/components/Field";
import ValueSetAutocomplete from "~/components/ValueSetAutocomplete";
import Button from "~/components/Button";
import Pill from "~/components/Pill";
import { RelatedDocSelection } from "~/components/RelatedDocs";
import MessageDialog from "~/components/MessageDialog";
import { track } from "~/analytics/events";
import { getCsrfToken } from "~/utils/auth";
export default function SendContractDialog({
  open,
  setOpen,
  account,
  contract,
  openSnackbar,
  ...props
}) {
  const { email, canSendToDocusign } = useFragment(
    graphql`
      fragment SendContractDialog_accountFragment on AccountType {
        email
        canSendToDocusign
      }
    `,
    account,
  );
  const { uuid, title, amendedTo, relatedDocs, amendments, docusignStatus } =
    useFragment(
      graphql`
        fragment SendContractDialog_fragment on ContractType {
          uuid
          title
          docusignStatus
          amendedTo {
            id
          }
          relatedDocs {
            id
          }
          amendments {
            id
          }
        }
      `,
      contract,
    );
  const [error, setError] = useState(null);
  const [recipients, setRecipients] = useState([]);
  const [selectedDocs, setSelectedDocs] = React.useState(Set());
  const [withFile, setWithFile] = useState(true);
  const [withSummary, setWithSummary] = useState(false);
  const [sending, setSending] = useState(false);
  const [docusignInProcMessage, setDocusignInProcMessage] = useState(null);
  const [subject, setSubject] = useState("Document sent from ContractSafe");
  const [body, setBody] = useState(
    email + " wanted you to have the attached document titled: " + title,
  );
  const [commitSendContract] = useAsyncMutation(graphql`
    mutation SendContractDialog_Mutation(
      $uuid: String!
      $subject: String!
      $body: String!
      $recipients: [String]!
      $relatedDocs: [String]!
      $withFile: Boolean
      $withSummary: Boolean
    ) {
      shareContract(
        input: {
          uuid: $uuid
          subject: $subject
          body: $body
          recipients: $recipients
          relatedDocs: $relatedDocs
          withFile: $withFile
          withSummary: $withSummary
        }
      ) {
        clientMutationId
      }
    }
  `);

  const [commitCancelDocusign] = useAsyncMutation(graphql`
    mutation SendContractDialog_CaneclDocusignMutation($uuid: String!) {
      cancelDocusignContract(input: { uuid: $uuid }) {
        contract {
          docusignStatus
        }
      }
    }
  `);

  function handleClose() {
    setOpen(false);
  }

  async function handleSendContract() {
    setSending(true);
    try {
      await commitSendContract({
        variables: {
          uuid,
          subject,
          body,
          recipients,
          relatedDocs: selectedDocs,
          withFile,
          withSummary,
        },
      });
      track("Contract Sent");
      setOpen(false);
      setSending(false);
      openSnackbar("Your contract has been shared.");
    } catch (e) {
      setError(e.message);
      setSending(false);
    }
  }

  async function sendToDocusign(uuid) {
    return await fetch(`/docusign/contract/${uuid}/`, {
      method: "POST",
      headers: {
        "X-CSRFToken": getCsrfToken(),
      },
    });
  }

  async function handleSendContractToDocusign() {
    setSending(true);
    track("Contract Send To Docusign Initiated");
    const response = await sendToDocusign(uuid);
    const obj = await response.json();

    setSending(false);

    if (response.status == 401) {
      // redirect user to ds to obtain consent and receive code
      var prefix =
        window.location.hostname.startsWith("app.contractsafe.") ||
        window.location.hostname.startsWith("au.contractsafe.")
          ? "account"
          : "account-d";
      var url =
        "https://" +
        prefix +
        ".docusign.com/oauth/auth?response_type=code" +
        "&scope=signature" +
        "&client_id=a03fcedc-7d5a-4a3b-8472-25a156d638f0" +
        "&state=" +
        uuid +
        "&redirect_uri=" +
        window.location.origin +
        "/docusign";
      // send code to backend

      console.log("redirecting to", url);
      window.location.href = url;
    } else if (response.status != 200) {
      setOpen(false);
      if (obj?.error?.message) {
        setError(obj.error.message);
      } else {
        console.log("error", obj);
        setError(parseerrordata(obj));
      }
      return;
    }

    if (obj.status == "inproc") {
      setDocusignInProcMessage(obj.message);
    } else {
      alert(obj.message);
      setOpen(false);
    }
  }

  async function cancelDocusign(uuid) {
    var data = { action: "cancel" };
    return await fetch(`/docusign/contract/${uuid}/`, {
      method: "PUT",
      headers: {
        "X-CSRFToken": getCsrfToken(),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }

  async function handleCancelDocusign() {
    try {
      await commitCancelDocusign({ variables: { uuid } });
      setOpen(false);
    } catch (e) {
      alert(e.message);
    }
  }

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <>
          <DialogTitle id="form-dialog-title">
            <Box
              width="100%"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              {sending ? (
                "Sending..."
              ) : (
                <>
                  Send Contract
                  {canSendToDocusign ? (
                    docusignStatus != "out" ? (
                      <Button
                        size="small"
                        onClick={handleSendContractToDocusign}
                        primary
                      >
                        Send To Docusign
                      </Button>
                    ) : (
                      <Button
                        size="small"
                        onClick={handleCancelDocusign}
                        primary
                      >
                        Cancel Docusign Request
                      </Button>
                    )
                  ) : null}
                </>
              )}
            </Box>
          </DialogTitle>
          {sending ? (
            <DialogContent>
              <LinearProgress />
            </DialogContent>
          ) : (
            <>
              <DialogContent dividers>
                <Grid
                  container
                  spacing={3}
                  direction="column"
                  justifyContent="flex-start"
                >
                  <Grid item id="email-to-address">
                    <InputLabel>Send To</InputLabel>
                    <ValueSetAutocomplete
                      multiple
                      value={recipients}
                      onChange={(e, v) => setRecipients(v)}
                      valueSetName="orgemailto"
                      defaultOptions={[email]}
                    />
                  </Grid>
                  <Grid item>
                    <InputLabel>Contract</InputLabel>
                    <Box mt={4} mx={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={withFile}
                            onChange={() => setWithFile(!withFile)}
                            name="withFile"
                            size="small"
                          />
                        }
                        label="Contract File"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={withSummary}
                            onChange={() => setWithSummary(!withSummary)}
                            name="withSummary"
                            size="small"
                          />
                        }
                        label="Summary"
                      />
                    </Box>
                  </Grid>
                  {relatedDocs.length + amendments.length + !!amendedTo ? (
                    <>
                      {" "}
                      <Grid item>
                        <InputLabel>Attach Related Documents</InputLabel>
                        <Box mt={4} mx={4}>
                          <Suspense fallback={<LinearProgress />}>
                            <RelatedDocSelection
                              selections={selectedDocs}
                              setSelections={setSelectedDocs}
                              contractUuid={uuid}
                            />
                          </Suspense>
                        </Box>
                      </Grid>
                    </>
                  ) : null}
                  <Grid item>
                    <Divider />
                  </Grid>
                  <Grid item>
                    <TextField
                      id="subject"
                      label="Subject"
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      multiline
                      id="body"
                      label="Message"
                      value={body}
                      onChange={(e) => setBody(e.target.value)}
                      inputProps={{ style: { height: 110 } }}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button size="small" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  size="small"
                  onClick={handleSendContract}
                  disabled={!recipients.length}
                  primary
                >
                  Send
                </Button>
              </DialogActions>
            </>
          )}
        </>
      </Dialog>
      {error && (
        <MessageDialog
          open={!!error}
          title={"Error"}
          onClose={() => setError(null)}
        >
          {error}
        </MessageDialog>
      )}
    </>
  );
}
